//@ts-nocheck
import React from "react";
import {
    withStyles, Button, createStyles, Typography,
    Card, CardActionArea, CardContent, Grid
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import {
    Props,
} from "./Articleview.web";
import ImageIcon from '@material-ui/icons/Image';
//@ts-ignore
import insane from 'insane';
//@ts-ignore
import * as AudioPlayer from 'react-responsive-audio-player'
import { epub } from "./assets";
import { LiveStream } from './Utility.web'
import { categoryArticalList } from './CategoriesCommonutility'
const Title = ({ type, caption, action, ItemList, classes }) => {
    return (
        <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi} style={{ marginTop: '8px', marginBottom: '15px' }}>
            {ItemList && ItemList.length > 0 &&
                <Typography align="center" className={classes.title} variant="h4" >{caption}</Typography>}
            {ItemList.length > 0 && <Button
                className={classes.viewAll}
                onClick={() => action(type)}
                color="primary"
                variant="outlined"
            >
                <Typography variant="body2" className="font-bold">See all</Typography>
            </Button>}
        </Grid>);
};
const PodcastContentType = ({ classes, e, match }) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Card className={classes.rootpodcast}>
                    <CardActionArea>
                        <CardContent style={{ backgroundColor: '#060606' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" style={{ color: '#fff' }} >
                                        {e?.attributes?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ color: '#fff' }} >
                                    {e?.attributes?.audio ? (<AudioPlayer playlist={[{
                                        url: e?.attributes?.audio,
                                        title: ''
                                    }]} controls={['playpause', 'progressdisplay']} />) : (<AudioPlayer playlist={[{
                                        url: 'https://parse-server-ff.s3.amazonaws.com/ae5992f0f5bb1f259bafa41b3771e3bb_call12565815456dwwwwww795896232www-01b59bd3.mp3',
                                        title: ''
                                    }]} controls={['playpause', 'progressdisplay']} />)}
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardContent>
                            {e?.attributes?.description?.length > 300 ?
                                <div className="mt-1.5">
                                    <Typography variant="subtitle2" color="primary" >
                                        <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                                            __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href= ${match.params.type == 'blogs' ? '/home/blogsdetails/' + e.id : '/home/articaldetails/' + match.params.type + '/' + e.id}>[Read more]</a></button>`)
                                        }}></span>
                                    </Typography>
                                </div>
                                : (
                                    <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                                        <span dangerouslySetInnerHTML={{
                                            __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href= ${match.params.type == 'blogs' ? '/home/blogsdetails/' + e.id : '/home/articaldetails/' + match.params.type + '/' + e.id}>[Read more]</a></button>`)
                                        }}></span>
                                    </Typography>
                                    </div>
                                )
                            }
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </React.Fragment>
    );
};
const OtherContentTypes = ({ classes, e, match }) => {
    return (
        <React.Fragment>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={2} key={e.id}>
                <Grid className={classes.categoryHight}>
                    {e?.attributes?.hasOwnProperty('content_type') ? (<>
                        {(e?.attributes?.content_type?.type == "Text" || e?.attributes?.content_type?.type == "AudioPodcast") &&
                            (<>
                                {e?.attributes?.image ? (<img alt="article" className="rounded-lg w-48 h-full" src={e?.attributes?.image} style={{ borderRadius: '20px' }} />) : (
                                    <ImageIcon className="rounded-lg h-full w-48" color="primary" />
                                )}
                            </>)}
                        {e?.attributes?.content_type.type == "Epub" &&
                            (<>
                                <img alt="article" className="rounded-lg w-48 h-full" src={epub} style={{ borderRadius: '20px' }} />
                            </>)}
                        {e?.attributes?.content_type?.type == "Live Stream" && (<>{e?.attributes?.contentable?.data?.attributes?.url ? (
                            <LiveStream {...{ classes, e }} />
                        )
                            : (
                                <ImageIcon className="rounded-lg w-48" color="primary" />
                            )}</>)}
                        {e?.attributes?.content_type?.type == "Videos" && (<>{e?.attributes?.video ? (
                            <video className="rounded-lg w-48 h-full" controls >
                                <source src={e?.attributes?.video} type="video/mp4" />
                            </video>
                        )
                            : (
                                <ImageIcon className="rounded-lg w-48" color="primary" />
                            )}</>)}
                    </>) : (<>
                        {e?.type == "epub" &&
                            (<>
                                <img alt="article" className="rounded-lg w-48 h-full" src={epub} style={{ borderRadius: '20px' }} />
                            </>)}
                    </>)}
                </Grid>
            </Grid>
            <Articledetail {...{ classes, e, match }} />
        </React.Fragment>
    )
};
const Articledetail = ({ classes, e, match }) => {
    return (
        <Grid item xs={6} sm={6} md={9} lg={9} xl={10}>
            <Grid container direction="column" className={classes.categoryHight1}>
                <Grid item>
                    <Typography variant="h3" className={classes.categoryHeading}>{e?.attributes?.name}</Typography>
                </Grid>
                <Grid item>
                    {e?.attributes?.description.length > 300 ?
                        <div className="mt-1.5">
                            <Typography variant="subtitle2" color="primary" >
                                <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                                    __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href= ${match?.params?.type == 'blogs' ? '/home/blogsdetails/' + e?.id : '/home/articaldetails/' + match?.params?.type + '/' + e?.id}>[Read more1]</a></button>`)
                                }}></span>
                            </Typography>
                        </div>
                        :
                        <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                            <span dangerouslySetInnerHTML={{
                                __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href= ${match?.params?.type == 'blogs' ? '/home/blogsdetails/' + e?.id : '/home/articaldetails/' + match?.params?.type + '/' + e?.id}>[Read more1]</a></button>`)
                            }}></span>
                        </Typography>
                        </div>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
const NoData = ({ classes, recommendArticle, trendingArticle, featureArticle }) => {
    return (<>
        {recommendArticle && recommendArticle.length == 0 && trendingArticle && trendingArticle.length == 0 && featureArticle && featureArticle.length == 0 &&
            <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
                <Grid item>
                    <Typography variant="body2">No results to display, Refine your search</Typography>
                </Grid>
            </Grid>
        }
    </>
    )
}
const FeatureBlock = ({ classes, contentTypeInfo, match, featureArticle, handleViewAll }) => {
    return (
        <Grid container direction="row" alignItems="center" spacing={4} style={{ marginBottom: '2rem' }}>
            <Title {...{ type: 'featured', caption: `Featured Articles`, action: handleViewAll, ItemList: featureArticle, classes }} />
            <br />
            <br />
            {featureArticle && featureArticle.length > 0 ? featureArticle.map((e: any) => (
                <React.Fragment key={e.id}>
                    {match.params.type == 'podcast' ? (
                        <PodcastContentType {...{ classes, e, match }} />
                    ) :
                        (
                            <OtherContentTypes  {...{ classes, e, match }} />
                        )}
                </React.Fragment>
            )) : null
            }
        </Grid>
    )
}
const TrendingBlock = ({ classes, contentTypeInfo, trendingArticle, match, handleViewAll }) => {
    return (
        <Grid container direction="row" alignItems="center" spacing={4} style={{ marginBottom: '2rem' }} >
            <Title {...{ type: 'trending', caption: `Trending Articles`, action: handleViewAll, ItemList: trendingArticle, classes }} />
            <br />
            <br />
            {trendingArticle && trendingArticle.length > 0 ? trendingArticle.map((e: any) => (
                <React.Fragment key={e.id}>
                    {match.params.type == 'podcast' ? (
                        <PodcastContentType {...{ classes, e, match }} />
                    ) :
                        (
                            <OtherContentTypes  {...{ classes, e, match }} />
                        )}
                </React.Fragment>
            )) : null
            }
        </Grid>
    )
}
const RecommendBlock = ({ classes, recommendArticle, match, handleViewAll }) => {
    return (
        <Grid container direction="row" alignItems="center" spacing={4} style={{ marginBottom: "2rem" }} >
            <Title {...{ type: 'recommended', caption: `Recommended for You`, action: handleViewAll, ItemList: recommendArticle, classes }} />
            <br />
            <br />
            {recommendArticle && recommendArticle.length > 0 ? recommendArticle.map((e: any) => (
                <React.Fragment key={e.id}>
                    {match.params.type == 'podcast' ? (
                        <PodcastContentType {...{ classes, e, match }} />
                    ) :
                        (<OtherContentTypes  {...{ classes, e, match }} />
                        )}
                </React.Fragment>
            )) : null
            }
        </Grid>
    )
}
export class ArticleList extends React.Component {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes, featureArticle, match, recommendArticle, trendingArticle, contentTypeInfo } = this.props;
        return (
            <Grid container direction="column" alignItems="center" justify="flex-start">
                {featureArticle && featureArticle.length > 0 && <FeatureBlock data-testId="FeatureBlock" {...{ classes, contentTypeInfo, match, featureArticle, handleViewAll: this.props.handleViewAll }} />
                }
                {trendingArticle && trendingArticle.length > 0 && <TrendingBlock data-testId="TrendingBlock" {...{ classes, contentTypeInfo, trendingArticle, match, handleViewAll: this.props.handleViewAll }} />}
                {localStorage.getItem('token') && recommendArticle && recommendArticle.length > 0 && <RecommendBlock data-testId="RecommendBlock" {...{ classes, recommendArticle, match, handleViewAll: this.props.handleViewAll }} />}
                <NoData data-testId="NoData" {...{ classes, recommendArticle, trendingArticle, featureArticle }} />
            </Grid>
        )
    }
}
//@ts-ignore
const ArticleListWebWithRouter = withRouter(ArticleList);
export default withStyles((theme) =>
    createStyles({
        ...categoryArticalList(theme),
        title: {
            paddingLeft: "16px",
            color: '#f48b1b',
            [theme.breakpoints.down('md')]: {
                fontSize: '18px !important',
            },
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100% - 7rem)',
                textAlign: 'left',
            },
        },
        viewAll: {
            border: '2px solid #f48b1b !important',
            backgroundColor: '#ffffff',
            borderRadius: '20px !important',
            width: '7rem',
            height: '2rem',
            marginRight: '15px',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important'
            },
            [theme.breakpoints.down('xs')]: {
                width: '6rem',
            },
        }
    })
)(ArticleListWebWithRouter);