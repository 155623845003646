//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Divider,
  Card, CardActions, CardContent,
  createStyles,
  Container,
  Typography,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { acheivementLogo } from './assets';
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
export class Achievement extends React.Component {

  render() {
    const { classes, mobileView, certificateList } = this.props;
    return (
      <Container maxWidth="xl" className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
        <Grid container key={"maincontainer"} direction="column" alignItems="flex-start" justify="center">
          <Grid container key={"subcontainer"} direction="row" alignItems="flex-start" style={{ marginTop: '1rem' }}>
            <Grid item xs={11} >
              <Grid container spacing={1}>
                {certificateList && certificateList.length > 0 ? certificateList.map((e, index) =>
                  <Grid item xs={12} sm={12} md={4} xl={3} key={index}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                          <Grid item><img src={acheivementLogo} className={classes.achievementLogo} /></Grid>
                          <Grid item><Typography variant="subtitle1">{e.certificationName}</Typography></Grid>
                          <Grid item><Typography variant="h3" className="text-center">{e.courseName}</Typography></Grid>
                          <Grid item><Typography variant="h3" className={classes.orangeTxt}>{e.courseSite}</Typography></Grid>
                        </Grid>
                        <Divider variant="middle" style={{ marginTop: '1rem' }} />
                      </CardContent>

                      <CardActions >
                        <Grid container direction="row" justify="space-between">
                          <Grid item xs={4}>
                            <Typography variant="h3" className={classes.purpleTxt}>Share</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h3" className={classes.purpleTxt}><a href={e.url} download="proposed_file_name">Download</a></Typography>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                ) :
                  <Grid item xs={12}>
                    <Typography data-testid="noachievement" variant="body2" align="center">
                      No Achievements Found
                    </Typography>
                  </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const achievementWithRouter = withRouter(Achievement);
const achievementWithLoader = withLoader(achievementWithRouter);
const achievementToast = withSnackbar(achievementWithLoader);
export const achievementWithStyle = withStyles((theme) =>
  createStyles({
    addPadding: {
      padding: "0px",
      paddingLeft: "5rem"
    },
    noPadding: {
      padding: "0px",
    },
    root: {
      marginTop: '1rem',
      width: '17rem',
      height: '18rem',
      border: 'solid 1px #f8e9e9',
      backgroundColor: '#fff',
    },
    achievementLogo: {
      marginTop: '-1rem',
      width: '38px',
      height: '85px'
    },
    purpleTxt: {
      color: '#7764db'
    },
    orangeTxt: {
      color: '#f48b1b'
    },
  })
)(achievementToast);
export default withTranslation()(achievementWithStyle);
