import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
import { logo } from "./assets";
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/Utility";
export const resuableFun = require("./resuableFunction")


export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  jobDetails: any[];
  userDetails: any;
  boughtCourseList: any;
  cartProfile: any;
  getAllBookmarked: any;
  statusPayment: any




}

interface SS {
  id: any;
}
export default class BookNowController extends BlockComponent<Props, S, SS> {

  getUserDetailApiCallId: string = "";
  getSuggestedCourseApiCallId: string = "";
  getcartProfileApiCallId: string = "";
  OrderExpertApiCallId: string = "";
  PaymentExpertApiCallId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  BookmarkedCourseApiCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      jobDetails: [],
      userDetails: [],
      boughtCourseList: [],
      cartProfile: [],
      getAllBookmarked: [],
      statusPayment: []
    };

    // Customizable Area End

  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getUserDetailApiCallId:
            this.handleUserDetailApiResponce(responseJson);
            break;
          case this.getSuggestedCourseApiCallId:
            this.handleSuggestedCourseApiResponce(responseJson);
            break;
          case this.OrderExpertApiCallId:
            this.handleOrderExpertApiResponce(responseJson);
            break;
          case this.PaymentExpertApiCallId:
            this.handlePaymentExpertApiResponce(responseJson);
            break;
          case this.addBookmarkApiCallId:
            this.handleAddBookmarkApiResponce(responseJson);
            break;
          case this.removeBookmarkApiCallId:
            this.handleRemoveBookmarkApiResponce(responseJson);
            break;
          case this.BookmarkedCourseApiCallId:
            this.handleBookmarkedCourseApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)

      }
    }
  }
  // Customizable Area Start
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };

  handleUserDetailApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ userDetails: responseJson.data.attributes });
    }
  };
  handleSuggestedCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ boughtCourseList: responseJson.data });
    }
  };
  handleCartAddCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.getBoughtCourses();
    }
  };
  handleOrderExpertApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      const options = {
        key: 'rzp_test_avu7SyAGQdRNDC',
        amount: responseJson?.data?.attributes?.price,
        currency: "INR",
        name: "Career Hunt",
        description: responseJson?.data?.type,
        image: logo,
        order_id: responseJson?.data?.attributes?.razorpay_order_id,
        handler: async (res: any) => {
          this.payment(res, responseJson)
        },
        prefill: {
          name: this.state.userDetails?.first_name,
          email: this.state.userDetails?.email,
          contact: this.state.userDetails?.phone_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      //@ts-ignore
      const rzp1 = new window.Razorpay(options);
      rzp1.open();

    }
  };
  handlePaymentExpertApiResponce = (responseJson: any) => {
    if (responseJson) {
      this.setState({ statusPayment: responseJson?.order })
      if (this.state.statusPayment?.status === "paid") {
        this.props.history.push(`/home/careerexpert/${this.state.statusPayment?.career_expert_id}`)
      }
    }
  };
  handleAddBookmarkApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.data?.attributes?.errors && responseJson.data) {
      this.getAllBookmarkedCourses()
      this.props.openToastHandler("success", 'Bookmarked'!);
    }
  };
  handleRemoveBookmarkApiResponce = (responseJson: any) => {
    if (responseJson && responseJson.success) {
      this.getAllBookmarkedCourses();
    }
  };
  handleBookmarkedCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.data?.attributes?.errors && responseJson.data) {
      this.setState({ getAllBookmarked: responseJson?.data });
    }
  };
  userDetails = async () => {
    const lang = localStorage.getItem("lang") || "en";
    const apiEndPoint = configJSON.getUserDetailApiEndPoint + `?language=${lang} `
    const token = await localStorage.getItem("token");
    const method = configJSON.validationApiMethodType
    const header = {
      "token": token
    };
    this.getUserDetailApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  getBoughtCourses = () => {

    const apiEndPoint = configJSON.getBoughtCourseUrl + `/bought_courses_list`
    const token = localStorage.getItem('token');
    const method = configJSON.validationApiMethodType
    const header = {
      "token": token
    };

    this.getSuggestedCourseApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  // payment gateway
  continuePay = async (order: any) => {
    this.props.showLoader();
    const data = {
      "amount": order?.attributes?.price,
      "currency": "INR",
      "receipt": order?.id,
      "notes": {
        "notes_key_1": "Tea, Earl Grey, Hot",
        "notes_key_2": "Tea, Earl Grey… decaf."
      }
    }

    const apiEndPoint = `${configJSON.getExpertOrderIdUrl}=${order?.id}`;
    const method = configJSON.exampleAPiMethod;
    const token = localStorage.getItem('token');
    const headers = { "token": token };
    this.OrderExpertApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      body: { ...data },
      headers: headers
    })

  }
  payment = (response: any, orderDetail: any) => {
    try {
      const paymentId = response.razorpay_payment_id;
      const apiEndPoint = `${configJSON.getExpertPaymentIdUrl}?razorpay_order_id=${orderDetail?.data?.attributes?.razorpay_order_id}&razorpay_payment_id=${paymentId}&razorpay_signature=${response.razorpay_signature}`;
      const method = configJSON.exampleAPiMethod;
      const token = localStorage.getItem('token');
      const headers = { "token": token };
      this.PaymentExpertApiCallId = sendAPIRequest(apiEndPoint, {
        method,
        headers: headers
      })
    } catch (err) {
      console.log(err);
    }
  }
  // bookMark

  getAllBookmarkedCourses = () => {
    const apiEndPoint = configJSON.getBookmarkCourseUrl
    const token = localStorage.getItem('token');
    const method = configJSON.httpGetType
    const header = { "token": token };
    this.BookmarkedCourseApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })

  };

  addBookmark = (id: any, event: any) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    if (token) {
      const headers = {
        "token": token,
        "Content-Type": configJSON.validationApiContentType
      }
      const data = {
        "bookmarkable_id": id,
        "bookmarkable_type": "course"
      }
      const apiEndPoint = configJSON.addBookmarkUrl;
      const method = configJSON.httpPostType;
      this.addBookmarkApiCallId = sendAPIRequest(apiEndPoint, {
        method,
        body: { ...data },
        headers: headers
      })
    }
    else {
      this.props.openToastHandler("warning", 'Please login to mark thic course as bookmark'!);
    }
  };

  removeBookmark = (id: any, event: any) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.validationApiContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const method = configJSON.httpPostType;
    this.removeBookmarkApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      body: { ...data },
      headers: headers
    })
  }
  // Customizable Area End
}