//@ts-nocheck
import React from "react";
import { Image, StyleSheet, View } from "react-native";
// uncomment for making mobile build -->
//@ts-ignore
// import Swiper from 'react-native-swiper'
// <-- uncomment for making mobile build 
import { verticalScale, scale } from "../../framework/src/Helpers";

interface myProps {
  bannerData: any;
}

export default function Banner(props: myProps) {
  return (
    <View style={styles.swiperViewStyle}>
      {/* uncomment for making mobile build --> */}
      {/* <Swiper style={styles.swiperView}
        autoplay
        activeDotColor="#7764DB"
        dotStyle={[styles.dotStyles, { backgroundColor: 'rgb(181,181,181)' }]}
        activeDotStyle={styles.dotStyles}>
        {props.bannerData.map((item: any, index: number) => (
          <View style={styles.innerSwiperStyle} key={index}>
            <Image
              source={{ uri: item.attributes?.image }}
              resizeMode='cover'
              style={styles.swiperImgStyle}
            />
          </View>
        ))
        }
      </Swiper> */}
      {/* <-- uncomment for making mobile build  */}
    </View>
  );
}


const styles = StyleSheet.create({

  swiperView: {
    height: verticalScale(159),
    overflow: 'hidden'
  },

  swiperViewStyle: {
    paddingHorizontal: 14,
    paddingTop: 14,
    paddingBottom: 25
  },

  innerSwiperStyle: {
    flex: 1,
    marginHorizontal: 6,
    borderRadius: 12,
    overflow: 'hidden'
  },

  swiperImgStyle: {
    width: '100%',
    height: "100%",
  },

  dotStyles: {
    // bottom: HEIGHT(-7),
    bottom: -47,
    height: scale(7),
    width: scale(7)
  },
});
