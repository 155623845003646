//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
//@ts-ignore
import { withRouter } from "react-router";
import QuizCategoryViewAllWebController, {
  Props,
} from "./QuizCategoryViewAllController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { groupImg, bannerInImg } from "./assets";
//@ts-ignore
import CategorysearchWeb from "../../categoriessubcategories/src/Categorysearch.web";
import { quizAssessmentcss, clearFilterOptions, getCaption } from './QuizAssessmentCommonutility'
import { QuizAssessmentSearchBlock, HeaderTitle, RowTextContainer } from './QuizAssesmentsUtility.web'


const ShowQuizRowContainer = ({ classes, gotoQuiz, quizList, isLogin, currentPage, totalPage, onQuizPageChange }) => {
  return (
    <Grid container xs={12} className={classes.paddingExamArVi}>
      <Grid container className={classes.paddingExamArVi}>
        <Grid container direction="row" xs={12} className="w-full">
          {quizList &&
            quizList.map((e: any, idx: number) => (
              <Grid item key={idx} >
                <Grid className={classes.quizBox} container direction="column" alignItems="center" justify="space-between">
                  <Grid className={classes.quizBox2} container direction="column" justify="space-between">
                    {e.attributes.heading && <Typography className={classes.quizHeading}>{e.attributes.heading}</Typography>}
                    {isLogin() &&
                      <>
                        <RowTextContainer classes={classes} text1="Attempts" text2={e?.attributes?.attempt_count || 0} />
                      </>}
                    <RowTextContainer classes={classes} text1="Questions" text2={e?.attributes?.test_questions?.data?.length || 0} />
                    <RowTextContainer classes={classes} text1="Time" text2={`${e?.attributes?.timer.indexOf(':') == -1 ? e?.attributes?.timer : parseInt(e?.attributes?.timer.split(':')[0] * 60) + parseInt(e?.attributes?.timer.split(':')[1])} mins`} />
                  </Grid>
                  <Grid item className={classes.gridone}>
                    <Button
                      className={classes.quizBtn}
                      data-testId="gotoQuiz"
                      onClick={() => gotoQuiz(e.id)}
                    >
                      <Typography className={classes.quizBtnTxt} variant="body2">Start Quiz</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
        {totalPage > 1 &&
          <Grid container>
            <Grid xs={11} className={classes.paginator}>
              <Pagination page={currentPage} data-testId="onQuizPageChange" onChange={onQuizPageChange} count={totalPage} variant="outlined" shape="rounded" />
            </Grid>
          </Grid >
        }
      </Grid>
      {!(quizList && quizList.length > 0) &&
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item>
            <Typography variant="body2" className="text-center">Result not found</Typography>
          </Grid>
        </Grid>}
    </Grid>);
};

export class QuizCategoryViewAllWeb extends QuizCategoryViewAllWebController {
  constructor(props: Props) {
    super(props);
  }

  setQuizCategorySearcdata = (e: any) => {
    this.setState({ searchValue: e.target.value }, () => this.loadData())
  }

  gotoQuiz = (qId: any) => {
    this.props.history.push(`/home/quiz/${qId}`);
  }

  onQuizPageChange = (e, page) => {
    this.setState({ currentPage: page }, () => this.loadData());
  }

  handleQuizToggle = (ele: any, filterinfo: any, index: any, id: any) => {
    if (filterinfo[index].value.indexOf(ele.id) == -1) {
      if (id == 3) {
        filterinfo[index].value = [ele.id]
      } else {
        filterinfo[index].value.push(ele.id)
      }
    } else {
      filterinfo[index].value.splice(filterinfo[index].value.indexOf(ele.id), 1)
    }
    this.setState({ filterdata: filterinfo })
  }
  applyQuizFilter = (data: any) => {
    this.setState({ pageNo: 0 }, () => this.getContentsBysearchCriteria(data))
  }
  clearQuizFilters = (filterinfo: any) => {
    filterinfo = clearFilterOptions(filterinfo)
    this.setState({ filterdata: filterinfo, pageNo: 0, ratingValue: null, selectedType: 0 }, () => {
      this.getFilterOptions('content_type', this.state.filterdata)
      this.getContentsBysearchCriteria(this.state.filterdata)
    })
  }

  handleQuizDateChange = (e: any, filterinfo: any, index: any) => {
    filterinfo[index][e?.target?.id] = e.target?.value
    this.setState({ filterdata: filterinfo })
  }

  handleQuizClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        if (data.key != 'date') {
          this.getFilterOptions(data.key, this.state.filterdata)
        }
      })
    }
  }
  handleQuizRatingChange = (e: any, newValue: any) => this.setState({ ratingValue: newValue });


  render() {
    const { classes } = this.props;
    const { quizList, currentPage, totalPage } = this.state;
    const { gotoQuiz, onQuizPageChange } = this;
    const isLogin = () => localStorage.getItem('token');
    return (
      <React.Fragment>
        <Container maxWidth="xl" disableGutters={true}>
          <Grid item xs={12} className={classes.gridtwo}>
            <img alt="" className="h-full" src={bannerInImg} />
          </Grid>
          <Container className={classes.videoCont2}>
            <Grid container direction="row" alignItems="center" justify="center" className={classes.gridcont}>
              <QuizAssessmentSearchBlock {...{
                classes,
                setSearcdata: this.setQuizCategorySearcdata,
                placeholderetext: "Search quiz here"
              }}
              />
            </Grid>
            <br />
            <br />
            {/* quiz */}
            <Grid container direction="row" >
              <Grid className={classes.lhsPanel} item >
                <Paper>
                  {/* @ts-ignore */}
                  <CategorysearchWeb
                    onclick={this.handleQuizClick}
                    handleToggle={this.handleQuizToggle}
                    applyFilter={this.applyQuizFilter}
                    clearFilter={this.clearQuizFilters}
                    handleDateChange={this.handleQuizDateChange}
                    selectedType={this.state.selectedType}
                    filterdata={this.state.filterdata}
                    valuetext={this.valuetext}
                    ratingValue={this.state.ratingValue}
                    handleRatingChange={this.handleQuizRatingChange}
                  />
                </Paper>
              </Grid>
              <Grid className={classes.rhsPanel} item >
                <HeaderTitle {...{ caption: getCaption(this.props?.match?.params?.heading, "Quiz"), classes, quizList }} />
                <ShowQuizRowContainer data-testId="ShowQuizRowContainer" {...{ classes, gotoQuiz, quizList, isLogin, currentPage, totalPage, onQuizPageChange }} />
              </Grid>
            </Grid>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

// @ts-ignore
const QuizCategoryViewAllWebWithRouter = withRouter(QuizCategoryViewAllWeb);
const QuizCategoryViewAllWebWithLoader = withLoader(QuizCategoryViewAllWebWithRouter);
const QuizCategoryViewAllWebToast = withSnackbar(QuizCategoryViewAllWebWithLoader);
const QuizCategoryViewAllWebWithStyle = withStyles((theme) =>
  createStyles({
    ...quizAssessmentcss(theme, groupImg),
    paginator: {
      marginTop: '2rem',
      marginBottom: '4rem',
      justifyContent: 'center',
      display: 'flex',
    },
    gridone: {
      height: '25%',
      margin: 'auto'
    },
    gridtwo: {
      marginTop: '-0.25rem',
    },
    gridcont: {
      height: '100%',
      width: '100%',
    },
    cardItem: {
      marginTop: '0.5rem'
    },
    lhsPanel: {
      width: '25%',
      padding: '1rem',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    rhsPanel: {
      width: '75%',
      padding: '1rem',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    titleroot: {
      marginBottom: '1.5rem',
    }
  })
)((QuizCategoryViewAllWebToast))
export default withTranslation()(QuizCategoryViewAllWebWithStyle);