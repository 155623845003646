import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    match: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  aboutUs: any
  aboutUsContent: any
  getLeaders: any
  // Customizable Area End
}

interface SS {
  id: any;
}
export default class AboutUsController extends BlockComponent<Props, S, SS> {
  aboutUSDetailsApiCallId: string = "";
  leadersDetailsApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      aboutUs: {},
      aboutUsContent: {},
      getLeaders: []
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.aboutUSDetailsApiCallId:
            this.aboutUSDetailsApiResponse(responseJson);
            break;
          case this.leadersDetailsApiCallId:
            this.leadersDetailsApiResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  openToastHandler = (responseJson: any) => {
    if (responseJson.errors[0].token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
  }
  aboutUSDetailsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson?.data) {
      this.setState({
        aboutUs: responseJson,
        aboutUsContent: responseJson?.data
      });
    } else if (responseJson && responseJson.errors) {
      this.openToastHandler(responseJson);
    }
  }
  leadersDetailsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson?.data) {
      this.setState({ getLeaders: responseJson?.data });
    } else if (responseJson && responseJson.errors) {
      this.openToastHandler(responseJson);
    }
  }
  getAboutUsDetails = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.aboutUSDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAboutUs}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getLeadersDetails = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.leadersDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLeaders}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
