Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.methodTypeApiUpdateUserProfile = "PUT";
exports.exampleApiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reviews";
exports.labelBodyText = "Reviews Body";
exports.bannerAPIEndPoint = "bx_block_contentmanagement/banners";
exports.jobTypesAndExperienceAPIEndPoint = "bx_block_jobs/jobs/job_type_and_experience";
exports.jobTypesAPIEndPoint = "bx_block_jobs/job_categories";
exports.locationAPIEndPoint = "bx_block_jobs/jobs/locations";
exports.companiesAPIEndPoint = "bx_block_companies/companies";
exports.getProfile = "bx_block_profile/profiles/id?language=en";
exports.jobSearch = "bx_block_jobs/jobs";
exports.btnExampleTitle = "CLICK ME";
exports.categoryAPIEndPoint = "categories/categories?show_on_landing_page=true";
exports.bookmarkAPIEndPoint = "bx_block_contentmanagement/bookmarks";
exports.bookmarkUnfollowAPIEndPoint = "bx_block_contentmanagement/bookmarks/unfollow";
exports.subCategoryAPIEndPoint = "bx_block_categories/sub_categories";
exports.endPointApiGetUserProfiledetails = "/bx_block_profile/profiles/id";
exports.getUserDetailApiEndPoint = "bx_block_profile/profiles/";

exports.validationApiMethodType = "GET";
exports.apiUpdateUserType = "PUT";
exports.getCompanyIndexApiEndPoint = "bx_block_companies/companies";
exports.getJobCategoriesApiEndPoint = "bx_block_jobs/job_categories";
exports.getJobTypeAndExperienceApiEndPoint = "bx_block_jobs/jobs/job_type_and_experience";
exports.getbannerImage = "bx_block_contentmanagement/banners?bannerable_type=job";
exports.getLocationEndPoint = "bx_block_filter_items/content_management/filters?filter_type=job&current_page=location";
exports.getAllJobsApiEndPoint = "bx_block_jobs/jobs";
exports.addBookmarkUrl = "bx_block_contentmanagement/bookmarks";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";
exports.dynamicFilterApiEndPoint = "/bx_block_filter_items/content_management/filters";
exports.getUserDetailApiEndPoint = "bx_block_profile/profiles/";
exports.getProfileDetailsApiEndPoint = 'bx_block_profile/profiles'
exports.getLeaders = "/bx_block_profile/leaders"
exports.getAboutUs = "/bx_block_profile/abouts"

// Customizable Area End