
//@ts-ignore
//@ts-nocheck
import React from "react";
import { ScrollView } from "react-native";
import { translate } from "../../../../components/src/i18n/translate";
import Banner from "../components/banner/Banner";
import Featured from "../components/featured/Featured";
import Location from "../components/location/Location";
import Popular from "../components/popular/Popular";
import SearchResult from "../components/searchResults/SearchResults";
import {
  featured_schools,
  Popular_schools,
  search_results,
} from "../constants";
interface Props {
  featuredSchools: any;
  popularSchools: any;
  searchSchools: any;
  globalTag: any;
  locations?: any; // list of location from API /---
  selectedLocation?: any; // list of selected location from user
  onLocationChanged: (id: any, type: any, name: any) => void; // location changed by user callback
  navigation: (type: any) => void;
  activeTab: any;
}

export default class SchoolsTab extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ScrollView
        style={{ flex: 1, width: "95%", alignSelf: "center" }}
        contentContainerStyle={{ flex: 1, paddingBottom: 100 }}
      >
        {/* Featured Schools */}

        <Featured
          title={featured_schools}
          onPress={() => {
            this.props?.navigation(featured_schools);
          }}
          featuredSchools={this.props.featuredSchools}
        />

        <Popular
          title={Popular_schools}
          onPress={() => {
            this.props.navigation(Popular_schools);
          }}
          popularSchools={this.props.popularSchools}
        />

        <Location
          title={translate("TOP10location")}
          locations={this.props.locations}
          selectedLocations={this.props.selectedLocation}
          globalTag={this.props.globalTag}
          onLocationChanged={(id, type, name) => {
            this.props.onLocationChanged(id, type, name);
          }}
        />

        <SearchResult
          title={search_results}
          activeTab={this.props.activeTab}
          globalTag={this.props.globalTag}
          onPress={() => {
            this.props.navigation(search_results);
          }}
          searchResults={this.props.searchSchools}
        />

        <Banner />
      </ScrollView>
    );
  }
}
