//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Paper,
  InputBase
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import ArticleviewWebController, {
  Props,
} from "./ArticleviewController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import ArticleList from './ArticleList.web';
import SearchIcon from '@material-ui/icons/Search';
import { categoryArticalViewAll } from './CategoriesCommonutility'
import { categoryArticalExamViewAllSearch } from '../../../components/src/CommonWebCSSUtility'


export class ArticleviewWeb extends ArticleviewWebController {
  constructor(props: Props) {
    super(props);

  }
  searchData = (e: any) => {
    this.setState({ searchInfo: e.target.value }, () => {
      this.getContentsBysearchCriteria(this.state.filterdata)
      this.getTrendingContentsBysearchCriteria(this.state.filterdata)
      this.getRcommendContentsBysearchCriteria(this.state.filterdata)
    })
  }
  handleViewAll = (value: any) => {
    this.props.history.push({
      pathname: `/home/categoryviewall/${this.props.match?.params?.type}/${value}/${this.props.match?.params?.categoryId}`,
      state: {
        filterinfo: this.state.filterdata,
        contentTypeId: this.state.selectedChip
      }
    })
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>

        <Grid item xs={12} className={classes.categoryViewall}>
          <Grid container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">

            <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
              <Grid item xs={1}>
                <SearchIcon color="secondary" />
              </Grid>
              <Grid item xs={11}>
                <InputBase
                  placeholder="Search Articles, Exam, Courses here"
                  data-testId="searchData"
                  classes={{
                    input: classes.inputInput,
                  }}
                  onChange={(e) => this.searchData(e)}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container>
          <Grid container justify="center" spacing={3} style={{ marginTop: '4rem' }}>
            <Grid item xs={12}>
              <Paper className={classes.paperserch}>
                <ArticleList
                  handleViewAll={this.handleViewAll}
                  {...this.state}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

// @ts-ignore
const ArticleviewWebWithRouter = withRouter(ArticleviewWeb);
const ArticleviewWebWithLoader = withLoader(ArticleviewWebWithRouter);
const ArticleviewWebToast = withSnackbar(ArticleviewWebWithLoader);
const ArticleviewWebWithStyle = withStyles((theme) =>
  createStyles({
    ...categoryArticalViewAll(theme),
    ...categoryArticalExamViewAllSearch(theme)
  })
)((ArticleviewWebToast))
export default withTranslation()(ArticleviewWebWithStyle);