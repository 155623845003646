
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  open: boolean;
  openDiscussionDialog: boolean;
  tagVal: any[],
  tagsList: any[],
  question: any,
  getDraft: any[],
  categories: any[],
  openDialogAtIndex: number,
  openDeleteDialog: boolean,
  showTagSearch: boolean,
  anchorEl: any
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DraftController extends BlockComponent<Props, S, SS> {
  getTagListApiCallId: string = "";
  getCategoriesApiCallId: string = "";
  postQuestionApiCallId: string = "";
  getDraftApiCallId: string = "";
  deleteQuestionApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      open: false,
      openDiscussionDialog: false,
      tagVal: [],
      tagsList: [],
      question: {},
      getDraft: [],
      categories: [],
      openDialogAtIndex: 0,
      openDeleteDialog: false,
      showTagSearch: false,
      anchorEl: null
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getDraftApiCallId:
          this.draftApi(responseJson);
          break;
        case this.getTagListApiCallId:
          this.tagList(responseJson);
          break;
        case this.getCategoriesApiCallId:
          this.categories(responseJson);
          break;
        case this.postQuestionApiCallId:
          this.postQues(responseJson);
          break;
        case this.deleteQuestionApiCallId:
          this.delQues(responseJson);
          break;
      }
    }
  }
  // Customizable Area Start
  delQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.message) {
      this.handleClose();
      this.props.openToastHandler("success", `Draft Deleted Successfully`!);
      this.getDraftQuestions();
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  postQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ openDiscussionDialog: false });
      this.getDraftQuestions();
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  categories = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ categories: responseJson.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  tagList = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      const temp = responseJson?.data.map((e: any) => e?.attributes?.name);
      this.setState({ tagsList: temp });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  draftApi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.props.hideLoader();
      this.setState({ getDraft: responseJson.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  openToastHandler = (responseJson: any) => {
    if (responseJson?.errors[0]?.token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
  }
  postQuestion = (status: any) => {
    const { question } = this.state;
    const a = this.state.getDraft.map((et) => {
      if (et?.attributes?.id === question?.id) {
        return et?.attributes?.title === question?.title
          && et?.attributes?.sub_category?.id === question?.categories
          && et?.attributes?.description === question?.description
          && et?.attributes?.status === status
          && et?.attributes?.tags &&
          et?.attributes?.tags.map((e: any) =>
            e?.attributes?.name) === question?.tagVals.map((e: any) => e?.name)
      }
    })
    if (a && a.find(e => e === true)) {
      this.setState({ openDiscussionDialog: false });
    } else {
      const headers = { "token": localStorage.getItem("token") };
      const apiEndPoint = configJSON.postQuestion + `/${question?.id}`;
      const methodType = configJSON.updateAPiMethod;
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.postQuestionApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      let formdata = new FormData();
      this.state.question?.title && formdata.append(`question[title]`, this.state.question.title);
      this.state.question?.categories && formdata.append(`question[sub_category_id]`, this.state.question.categories);
      this.state.question?.description && formdata.append(`question[description]`, this.state.question.description);
      formdata.append(`question[status]`, status);
      formdata.append(`question[closed]`, '0');
      this.state.question?.tagVals && formdata.append(`question[tag_list]`, this.state.question.tagVals.map((e: any) => e));
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
  deleteDraft = (id: number) => {
    const headers = { "token": localStorage.getItem("token") };
    const apiEndPoint = configJSON.postQuestion + `/${id}`;
    const methodType = configJSON.deleteAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        const { openDialogAtIndex } = this.state;
        if (openDialogAtIndex !== 0)
          this.openPostDialog(openDialogAtIndex);
        this.setState({ open: val });
        this.getDraftQuestions()
      } else {
        this.setState({ open: val });
        this.props.history.push("/dashboard/discussionforum");
        localStorage.clear();
      }
    }
  }
  createPost = (value: any) => {
    this.setState({ question: value });
    const token = localStorage.getItem('token');
    let status = "draft";
    if (token) {
      status = "publish";
      this.postQuestion(status);
    } else {
      this.openDialog(true);
    }
  }
  handleClose = () => {
    this.setState({ openDiscussionDialog: false, openDeleteDialog: false })
  }
  openPostDialog = (i: number) => {
    this.setState({ openDiscussionDialog: true, openDialogAtIndex: i });
  }
  getTagList = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTagListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTagApiUrl
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesAPIEndPoint
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getDraftQuestions = (searchInfo?: any) => {
    this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getDraftApiCallId = requestMessage.messageId;
    const search = searchInfo ? `?search=${searchInfo}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDraftApiEndPoint + search
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}