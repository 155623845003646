Object.defineProperty(exports, "__esModule", {
  value: true,
});
 
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.methodTypeApiUpdateUserProfile = "PUT";
exports.formDataApiContentType = "multipart/form-data";
 
exports.getProfile = "bx_block_profile/profiles/id?language=en";
exports.contentTypeApiEndPoint = "bx_block_contentmanagement/content_types";
exports.dynamicFilterApiEndPoint =
  "/bx_block_filter_items/content_management/filters";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleDeleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
 
exports.btnExampleTitle = "CLICK ME";
 
exports.getContents = "bx_block_contentmanagement/contents?page=1&per_page=6"
exports.getContentslist = "bx_block_contentmanagement/contents"
exports.getContent = "bx_block_contentmanagement/contents/1?language=en"
exports.getExperts = "bx_block_experts/career_experts"
exports.getAccountExperts = "bx_block_experts/account_experts"
exports.getRequestExperts = "bx_block_experts/request_career_experts"
exports.getMyProfile = "bx_block_profile/profiles/id?language=en"
exports.getPopularSchools = "bx_block_profile/schools"
exports.getStandards = "bx_block_profile/standards"
exports.getEducationLevels = "bx_block_profile/education_levels"
exports.getToplocations = "bx_block_profile/locations"
exports.getLanguages = "bx_block_languageoptions/languages"
exports.getFeeLists = "bx_block_profile/schools/total_fees_list"
exports.getSpecializations = "bx_block_profile/specializations"
exports.getDegrees = "bx_block_profile/degrees"
exports.getBoards = "bx_block_profile/boards"
exports.getPopularColleges = "bx_block_profile/colleges"
exports.getSchoolsCollegeCtas = "bx_block_categories/cta"
exports.categoriesAPIEndPoint = "bx_block_categories/categories";
exports.providerslistAPIEndPoint =
  "bx_block_contentmanagement/content_providers";
exports.contentTypelistAPIEndPoint = "bx_block_contentmanagement/content_types";
exports.getBookmarkExpertUrl =
  "bx_block_contentmanagement/bookmarks?bookmarkable=expert";
exports.getUserDetailApiEndPoint = "bx_block_profile/profiles/id";
exports.getBoughtCourseUrl = "bx_block_contentmanagement/";
exports.addGetCartCourse = "bx_block_contentmanagement/course_carts";
exports.getOrderIdUrl = "bx_block_payments/razorpays";
exports.getPaymentIdUrl = "bx_block_payments/razorpays/varify_signature";
exports.getExpertOrderIdUrl = "/bx_block_payments/razorpays/single_expert?career_expert_id";
exports.getExpertPaymentIdUrl = "bx_block_payments/razorpays/varify_signature"
exports.getCareerExperts = "bx_block_experts/career_experts"
exports.expertTabs = "bx_block_contentmanagement/contents";
exports.bannerAPIEndPoint = "bx_block_contentmanagement/banners";
exports.expertVideos = "bx_block_experts/career_experts/get_videos";
exports.expertArticles = "bx_block_experts/career_experts/get_articles";
exports.expertBlogs = "bx_block_experts/career_experts/get_blogs";
exports.expertCategories = "bx_block_categories/categories";
exports.expertSelection = "bx_block_experts/request_career_experts";
exports.requestedExperts = "bx_block_profile/profiles/id?language=en";
exports.forStudents = "bx_block_categories/categories?cat_name[]=Students";
exports.forCarrer =
  "bx_block_categories/categories?cat_name[]=Career Professional";
exports.forinstitution =
  "bx_block_categories/categories?cat_name[]=Institution";
exports.getFilterOptions = "bx_block_filter_items/content_management/filters";
exports.addBookmarkUrl = "bx_block_contentmanagement/bookmarks";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";
exports.getBookmarkCourseUrl =
  "bx_block_contentmanagement/bookmarks?bookmarkable=course";
exports.createReport = "bx_block_contentmanagement/reports";
exports.blogFollow = "bx_block_contentmanagement/follows/follow"
exports.blogUnfollow = "bx_block_contentmanagement/follows/unfollow"
exports.categoryApi = "bx_block_categories/categories?cat_name[]="

//Razorpay apis for expert
exports.createSingleExpertAPIEndPoint = "bx_block_payments/razorpays";
exports.verifyPaymentAPIEndPoint =
  "bx_block_payments/razorpays/varify_signature";

// Customizable Area End
