import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  Platform,
  SafeAreaView,
  FlatList,
} from "react-native";
import Scale from "../../../components/src/Scale";
import { scale } from "../../../framework/src/Helpers";
import { Notification, bookmark, Home } from "./assets";

// Customizable Area End

import DesktopNotificationsController, {
  Props,
} from "./DesktopNotificationsController";

export default class DesktopNotifications extends DesktopNotificationsController {
  renderheader() {
    return (
      <View>
        <View style={styles.mainview}>
          <View />
          <Text style={styles.textNotification}>Notification</Text>

          <TouchableOpacity>
            <Image
              resizeMode={"contain"}
              style={{ tintColor: "gray" }}
              source={require("../assets/dark_filter.png")}
            />
          </TouchableOpacity>
        </View>

        <View style={styles.textline}>
          <TouchableOpacity
            style={{
              width: "45%",
              borderBottomWidth: this.state.isNotification ? 2 : 1,
              borderColor: this.state.isNotification ? "#7764db" : "gray",
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 15,
            }}
            onPress={() => this.toggle(0)}
          >
            {/* <Text style={[styles.text, { color: this.state.isNotification ? '#7764db' : 'black'  fontSize:this.state.isNotification? '400':'800'}]}> */}
            <Text
              style={[
                styles.text,
                this.state.isNotification
                  ? { color: "#7764db", fontSize: 16, fontWeight: "500" }
                  : { color: "black", fontSize: 14 },
              ]}
            >
              Notification
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: "45%",
              borderBottomWidth: this.state.isInbox ? 2 : 1,
              borderColor: this.state.isInbox ? "#7764db" : "gray",
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 15,
            }}
            onPress={() => this.toggle(1)}
          >
            <Text
              style={[
                styles.text,
                this.state.isNotification
                  ? { color: "black", fontSize: 14 }
                  : { color: "#7764db", fontSize: 16, fontWeight: "500" },
              ]}
            >
              Inbox (2)
            </Text>
          </TouchableOpacity>
        </View>

        <Text style={styles.today}>Today</Text>
      </View>
    );
  }

  rednerContentFlatlist = (item: any) => {
    return (
      <View>
        <View style={styles.mainview}>
          <View style={{ flexDirection: "row" }}>
            <Image
              style={styles.imgbox}
              source={require("../assets/home_image.png")}
            />
            <View style={styles.tagline}>
              <View style={styles.days}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    style={styles.img}
                    source={require("../assets/volicity-9.png")}
                  />
                  <Text style={styles.tagText}>Content Provider</Text>
                </View>
                <Text style={styles.daytext}>{item.name}</Text>
              </View>
              <Text style={styles.list} numberOfLines={6}>
                Mining the Moon for Rocket Fuel to Get Us to Mars{" "}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.line} />
      </View>
    );
  };

  renderListView = () => {
    return (
      <View style={{ marginBottom: 150 }}>
        <FlatList
          data={this.state.data}
          renderItem={({ item, index }) => this.rednerContentFlatlist(item)}
        />
      </View>
    );
  };

  rednerisNotification = () => {
    return (
      <View>
        <View style={styles.mainview}>
          <View style={{ flexDirection: "row" }}>
            <View>
              <Image
                style={styles.imgbox1}
                source={require("../assets/home_image.png")}
              />
              <View style={styles.notification} />
            </View>
            <View style={styles.tagline}>
              <View style={styles.days}>
                {/* <Image style={styles.img}
                    source={require('../assets/volicity-9.png')} /> */}
                <Text style={styles.tagText1}>Content Provider</Text>

                <Text style={styles.daytext}>1 day ago</Text>
              </View>
              <Text style={styles.list1} numberOfLines={6}>
                You have successfully purchased This course. I am looking
                forward to hearing from you.{" "}
              </Text>
              <TouchableOpacity style={styles.pdfbox}>
                <Text style={{ fontSize: 11 }}>Introduction.pdf</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={styles.line} />
      </View>
    );
  };

  render() {
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={{ flex: 1 }}>
        {this.renderheader()}
        {this.state.isNotification && this.renderListView()}
        {this.state.isInbox && this.rednerisNotification()}
        <View style={styles.exploreView}>
          <TouchableOpacity onPress={() => this.props.navigation.navigate("Explore")}>
            <Image
              resizeMode="contain"
              style={styles.greyTint}
              source={Home}
              height={30}
              width={30}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.props.navigation.navigate("DesktopBookmark")}>
            <Image
              style={styles.greyTint}
              resizeMode="contain"
              source={bookmark}
              height={25}
              width={25}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.props.navigation.navigate("DesktopNotifications")}>
            <Image
              style={styles.orangeTint}
              resizeMode="contain"
              source={Notification}
              height={30}
              width={30}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.props.navigation.navigate("MyProfileList")}>
            <Image
              style={styles.profileIconStyle}
              source={{uri: "https://d2v9ipibika81v.cloudfront.net/uploads/sites/210/Profile-Icon.png"}}
            />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  view1: {
    flex: 1,
  },
  mainview: {
    flexDirection: "row",
    marginTop: 20,
    marginHorizontal: 20,
    justifyContent: "space-between",
  },

  textNotification: {
    fontWeight: "800",
    fontSize: scale(18),
    marginLeft: scale(30),
  },

  text: {
    fontSize: scale(16),
    color: "gray",
  },

  tagText: {
    marginLeft: 5,
    fontSize: 11,
  },

  tagText1: {
    fontSize: 13,
  },

  days: {
    width: scale(251),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  inbox: {
    fontSize: scale(16),
    color: "#7764db",
  },

  textline: {
    flexDirection: "row",
    marginTop: scale(30),
    alignSelf: "center",
  },

  line: {
    backgroundColor: "#ddd",
    height: 2,
    alignSelf: "center",
    width: "90%",
    marginTop: scale(20),
  },
  today: {
    marginLeft: scale(20),
    fontSize: scale(16),
    fontWeight: "700",
    marginTop: scale(20),
  },

  imgbox: {
    height: scale(66),
    width: scale(71),
    //resizeMode: 'contain',
    borderRadius: scale(12),
  },

  imgbox1: {
    height: scale(66),
    width: scale(71),
    //resizeMode: 'contain',
    borderRadius: scale(12),
    //overflow:'hidden'
  },

  img: {
    height: scale(18),
    width: scale(18),
    backgroundColor: "orange",
    borderRadius: scale(4),
    resizeMode: "contain",
  },

  boxs: {
    flexDirection: "row",
    marginTop: 12,
    marginHorizontal: Scale(10),
  },

  ContentProvider: {
    fontSize: 11,
    marginLeft: 5,
  },

  daytext: {
    color: "#959595",
    fontSize: scale(11),
    //right:scale(20)
  },

  lientext: {
    marginTop: scale(10),
    marginLeft: scale(17),
    fontSize: scale(17),
  },

  tagline: {
    width: 220,
    marginLeft: 17,
    // backgroundColor:'red'
  },
  list: {
    marginTop: 10,
    fontSize: 16,
  },

  list1: {
    marginTop: 10,
    fontSize: 12,
  },

  notification: {
    height: scale(15),
    width: scale(15),
    position: "absolute",
    backgroundColor: "#7764db",
    top: scale(-5),
    alignSelf: "flex-end",
    borderRadius: scale(12),
  },

  pdfbox: {
    marginTop: scale(10),
    backgroundColor: "#b5b5b5",
    borderRadius: 9,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
    width: 120,
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  exploreView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: 20,
    height: 50,
    backgroundColor: "white",
    position: "absolute",
    width: "74%",
    right: "13%",
    bottom: 50,
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  greyTint: {
    tintColor: "grey",
  },
  orangeTint: {
    tintColor: "#f2840c",
  },
  profileIconStyle: { height: 27, width: 27, resizeMode: "contain" }
});
// Customizable Area End
