
//@ts-nocheck
import React from "react";
import {
  withStyles, Card, CardContent, Menu, MenuItem, Hidden,
  Container, Grid, Typography, Button, TextField, Divider, createStyles
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import DFDetailViewController, { Props } from "./DFDetailViewController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import {
  userImage
} from "./assets";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TreeView from '@material-ui/lab/TreeView';
import ReplyIcon from '@material-ui/icons/Reply';
import TreeItem from '@material-ui/lab/TreeItem';
import SendIcon from '@material-ui/icons/Send';
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e1e1e1"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e1e1e1"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e1e1e1"
    },
    "& .MuiOutlinedInput-input": {
      color: "#000000"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#000000"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000000"
    },
    "& .MuiInputLabel-outlined": {
      color: "#000000"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#e1e1e1"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#e1e1e1"
    }
  },
})(TextField);
const ReportMenuItem = withStyles({
  root: { backgroundColor: '#ffffff', color: '#000000 !important' },
  MuiMenuItem: {
    root: { backgroundColor: '#ffffff', color: '#000000 !important', }
  },
})(MenuItem);
export class DFDetailView extends DFDetailViewController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ detailId: this.props?.location?.state?.postDetails?.attributes?.id }, () => this.getUpdatedQuestions(this.state.detailId));
    this.getLikes();
    this.getQuestionDetail(this.props?.location?.state?.postDetails?.attributes?.account?.id);
    TimeAgo.addDefaultLocale(en);
    TimeAgo.locale(en);
  }
  openReplyBox = (id: any, i: any) => {
    this.setState({ openReply: true, commentableIdForAns: id, currentReplyIndex: i });
  }
  openSubComment = (id: any, i: any) => {
    this.setState({ openSubComment: true, commentableIdForAns: id, currentCommentIndex: i });
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  openCommentSection = () => {
    this.setState({ commentSection: true });
  }
  openMenu = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget });
  }
  likeListUi = (nodes, likeList, classes) => {
    return (<Grid container direction="row" alignItems="center">
      {localStorage.getItem('token')
        && nodes?.attributes?.is_like === true &&
        likeList?.findIndex((et) => { return et?.attributes?.likeable_id === nodes?.attributes.id }) > -1
        ? <>
          <ThumbUpIcon color="light" onClick={(ev) => {
            ev.preventDefault();
            if (localStorage.getItem('token')) {
              this.handleDisLike(nodes?.attributes?.id, false, "comment")
            }
            else { this.openDialog(true) }
          }} className={classes.dfIcon} />
          <Typography variant="body1">{nodes?.attributes?.likes_count}</Typography>
        </>
        :
        <>
          <ThumbUpAltOutlinedIcon color="light" onClick={(ev) => {
            ev.preventDefault();
            if (localStorage.getItem('token')) {
              this.handleLike(nodes?.attributes?.id, true, "comment")
            }
            else { this.openDialog(true) }
          }} className={classes.dfIcon} />
          {nodes && nodes.attributes && nodes.attributes.likes_count && <Typography variant="body1">{nodes.attributes.likes_count}</Typography>}
        </>
      }
    </Grid>)
  }
  replyTextBlock = (nodes, classes) => {
    return (<Grid container direction="row" alignItems="center">
      <ReplyIcon color="light" className={classes.dfIcon}
        onClick={(ev) => {
          if (localStorage.getItem('token')) {
            ev.preventDefault();
            this.openReplyBox(nodes?.attributes?.id, nodes?.id)
          }
          else { this.openDialog(true) }
        }} />
      <Typography className={classes.purpleTxt} data-testId="openReplyBox" onClick={(ev) => {
        if (localStorage.getItem('token')) {
          ev?.preventDefault();
          this.openReplyBox(nodes?.attributes?.id, nodes?.id)
        }
        else { this.openDialog(true) }
      }} variant="body1">Reply</Typography>
    </Grid>)
  }
  replyUiBlock = (openReply, currentReplyIndex, nodes, classes) => {
    return (
      <Card className={classes.commentSection}>
        <CardContent className={classes.replyTxtBox}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item xs={9} sm={9} md={9} lg={9} xl={10}>
              <PinkTextField
                data-testId="replyUiBlock"
                variant="outlined"
                name="reply"
                value={this.state.reply}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    this.setState({ reply: ev?.target?.value })
                    this.commentOnAnswer();
                  }
                }}
                onChange={(ev: any) => {
                  this.setState({ reply: ev?.target?.value })
                }}
                InputProps={{ classes: { input: classes.txtProps } }}
                placeholder="Enter Your Answer"
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
              <Hidden smDown>
                <Button variant="contained" name="confirm" disabled={this.state.reply === ""}
                  data-testId="commentOnAnswer"
                  onClick={() => {
                    if (localStorage.getItem('token')) {
                      this.commentOnAnswer();
                    }
                    else { this.openDialog(true) }
                  }} type="button" className={classes.replyBtn}>
                  <Typography variant="body2">Reply</Typography>
                </Button>
              </Hidden>
              <Hidden mdUp>
                <SendIcon color="light" data-testId="commentOnAnswer2" onClick={() => {
                  if (localStorage.getItem('token')) {
                    this.commentOnAnswer()
                  }
                  else { this.openDialog(true) }
                }} className={classes.addIcon} />
              </Hidden>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
  headerCardBlockUi = (nodes, classes, postDetails) => {
    return (<Grid item container direction="row" alignItems="flex-start" justify="space-between">
      <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
        <img src={nodes?.attributes?.user_image ? nodes?.attributes?.user_image : userImage} className={classes.userImg} />
      </Grid>
      <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
        {postDetails && postDetails.attributes && postDetails.attributes.updated_at && <Grid container direction="column">
          {nodes && nodes.attributes && nodes.attributes.account && nodes.attributes.account.first_name && <Typography variant="body2">{nodes.attributes.account.first_name}</Typography>}
          <Typography variant="body1">{nodes && nodes.attributes && nodes.attributes.updated_at && <ReactTimeAgo date={nodes.attributes.updated_at} locale="en-US" />}</Typography>
        </Grid>}
      </Grid>
      <Grid item xs={1} />
      <br />
    </Grid>
    )
  }
  renderComment = (nodes: any, ...args) => {
    const { classes } = this.props;
    const [postDetails, currentHideShowId, currentHideShowIndex, i, openSubComment, likeList,
      currentCommentIndex, openReply, currentReplyIndex, reply] = args;
    return (
      <TreeView
        classes={{ root: classes.treeView }}
        defaultExpanded={['root']}
        data-testId="renderComment"
      >
        <TreeItem classes={{ content: classes.rootTree, group: classes.treeGroup }} key={nodes?.id} nodeId={nodes?.id}
          label={
            <Card className={classes.commentSection}>
              <CardContent className={classes.commentContent}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  {this.headerCardBlockUi(nodes, classes, postDetails)}
                  <Grid item container direction="row"><Grid item xs={2}></Grid></Grid>
                  <Grid item container direction="row">
                    {nodes && nodes.attributes && nodes.attributes.description && <Typography variant="subtitle2" className={classes.desc}>{nodes.attributes.description}</Typography>}
                    <br />
                    <br />
                  </Grid>
                  <br />
                  <br />
                  <Grid container item direction="row" justify="space-between">
                    <Grid item xs={7}>
                      <Grid container direction="row" >
                        <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
                          <Grid container direction="row" alignItems="center">
                            <ChatBubbleIcon color="light" className={classes.dfIcon}
                              data-testId="showAllReplies"
                              onClick={() => { this.showAllReplies(nodes?.attributes?.id, i) }} />
                            {nodes && nodes.attributes && nodes.attributes.comments_count && <Typography variant="body1">{nodes.attributes.comments_count}</Typography>}
                          </Grid>
                        </Grid>
                        <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
                          {this.likeListUi(nodes, likeList, classes)}
                        </Grid>
                        <Grid item xs={3} sm={2} md={3} lg={2} xl={1}>
                          {this.replyTextBlock(nodes, classes)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {openReply && currentReplyIndex == nodes?.id && this.replyUiBlock(openReply, currentReplyIndex, nodes, classes)}
                  <br />
                </Grid>
              </CardContent>
            </Card>
          }>
          {Array.isArray(nodes?.attributes?.comments) ? nodes?.attributes?.comments.map((node) =>
            this.renderComment(node, postDetails, currentHideShowId,
              currentHideShowIndex, i, openSubComment, likeList,
              currentCommentIndex, openReply, currentReplyIndex, reply)) : null}
        </TreeItem>
      </TreeView>
    )
  };
  likeUi = (postDetails, likeList, classes) => {
    return (
      localStorage.getItem('token')
        && postDetails?.attributes?.is_like === true &&
        likeList?.findIndex((et) => { return et?.attributes?.likeable_id === postDetails?.attributes.id }) > -1
        ? <>
          <ThumbUpIcon color="light" onClick={() => {
            if (localStorage.getItem('token')) {
              this.handleDisLike(postDetails?.attributes?.id, false, "question")
            }
            else { this.openDialog(true) }
          }} className={classes.dfIcon} />
          <Typography variant="body1">{postDetails?.attributes?.likes_count}</Typography>
        </>
        :
        <>
          <ThumbUpAltOutlinedIcon color="light" data-testId="handleLike" onClick={() => {
            if (localStorage.getItem('token')) {
              this.handleLike(postDetails?.attributes?.id, true, "question")
            }
            else { this.openDialog(true) }
          }} className={classes.dfIcon} />
          <Typography variant="body1">{postDetails?.attributes?.likes_count}</Typography>
        </>
    )
  }
  commentUi = (postDetails, ...args) => {
    const [currentHideShowId, currentHideShowIndex, openSubComment, likeList, currentCommentIndex,
      openReply, currentReplyIndex, reply] = args;
    return (
      postDetails && postDetails?.attributes?.comments.map((cl, i: any) => {
        if (cl.attributes.commentable_id === postDetails?.attributes?.id) {
          return (
            <>
              <Divider style={{ width: '100%' }} />
              <br />
              <br />
              {this.renderComment(cl, postDetails, currentHideShowId,
                currentHideShowIndex, i, openSubComment, likeList, currentCommentIndex,
                openReply, currentReplyIndex, reply)}
              <br />
              <br />
            </>
          )
        }
      })
    )
  }
  forumLowerBlock = (postDetails, likeList, classes) => {
    return (
      <Grid item xs={8}>
        <Grid container direction="row" >
          <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
            <Grid container direction="row" alignItems="center">
              <RemoveRedEyeIcon color="light" className={classes.dfIcon} />
              {postDetails && postDetails.attributes && postDetails.attributes.view && <Typography variant="body1">{postDetails.attributes.view}</Typography>}
            </Grid>
          </Grid>
          <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
            <Grid container direction="row" alignItems="center">
              <ChatBubbleIcon color="light" className={classes.dfIcon}
                data-testId="openCommentSection"
                onClick={() => {
                  if (localStorage.getItem('token')) {
                    this.openCommentSection()
                  }
                  else { this.openDialog(true) }
                }} />
              {postDetails && postDetails.attributes && postDetails.attributes.comments_count && <Typography variant="body1">{postDetails.attributes.comments_count}</Typography>}
            </Grid>
          </Grid>
          <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
            <Grid container direction="row" alignItems="center">
              {this.likeUi(postDetails, likeList, classes)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  dFHeaderUiBlock = (postDetails, classes, anchorEl) => {
    return (<Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
      <Grid item xs={10}>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item >
            <img src={postDetails?.attributes?.user_image ? postDetails?.attributes?.user_image : userImage} className={classes.userImg} />
          </Grid>
          <Grid item xs={10}>
            {postDetails && postDetails.attributes && postDetails.attributes.created_at &&
              <Grid container direction="column" >
                {postDetails.attributes && postDetails.attributes.account && postDetails.attributes.account.first_name && <Typography variant="caption">{postDetails.attributes.account.first_name}</Typography>}
                <Typography variant="body1">{postDetails.attributes && postDetails.attributes.created_at && <ReactTimeAgo date={postDetails.attributes.created_at} locale="en-US" />}</Typography>
              </Grid>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Grid container direction="row" alignItems="center" justify="flex-end">
          <MoreVertIcon color="light" data-testId="openMenu" onClick={(e) => this.openMenu(e)} className={classes.addIcon} />
          <Menu
            classes={{ paper: classes.reportMenu }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            data-testId="handleMenuClose"
            onClose={this.handleMenuClose}
          >
            <ReportMenuItem className={classes.menuColor} onClick={this.handleRecommendedMenuClose}>Report</ReportMenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
    )
  }
  descBlockUi = (postDetails, comment, classes) => {
    return (<>
      <Grid item >
        <img src={postDetails?.attributes?.user_image ? postDetails?.attributes?.user_image : userImage}
          className={classes.userImg}>
        </img>
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
        <PinkTextField
          data-testId="descBlockUi"
          variant="outlined"
          name="comment"
          value={comment}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev?.preventDefault();
              this.commentOnQuestion();
            }
          }}
          onChange={(ev: any) => { this.setState({ comment: ev?.target?.value }) }}
          InputProps={{ classes: { input: classes.txtProps } }}
          placeholder="Enter Your Answer"
        />
      </Grid>
      <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
        <Hidden smDown>
          <Button variant="contained" name="confirm" disabled={comment === ""}
            data-testId="commentOnQuestion" onClick={this.commentOnQuestion} type="button" className={classes.publishBtn}>
            <Typography variant="body2">Add Comment</Typography>
          </Button>
        </Hidden>
        <Hidden mdUp>
          <SendIcon color="light" onClick={this.commentOnQuestion} className={classes.addIcon} />
        </Hidden>
      </Grid>
    </>
    )
  }
  discussionCard = (postDetails, ...args) => {
    const [likeList, classes, anchorEl, currentHideShowId, currentHideShowIndex, openSubComment, currentCommentIndex,
      openReply, currentReplyIndex, reply, comment] = args;
    return (
      <Card className={classes.rootCard}>
        <CardContent>
          {this.dFHeaderUiBlock(postDetails, classes, anchorEl)}
          <br />
          <Grid container direction="row">
            {postDetails && postDetails.attributes && postDetails.attributes.title && <Typography variant="h3">{postDetails.attributes.title}</Typography>}
            <Grid item>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row">
            <br />
            {postDetails && postDetails.attributes && postDetails.attributes.description && <Typography variant="subtitle2" className={classes.desc}>{postDetails.attributes.description}</Typography>}
          </Grid>
          {postDetails?.attributes?.tags.length > 0 && <> <br />
            <Grid container direction="row" justify="flex-start">
              {postDetails?.attributes?.tags && postDetails?.attributes?.tags.map((el,index) =>{
                return(
                  <Button key={index} variant="contained" className={classes.tagChips}>
                    {el && el.name && <Typography variant="body1">{el?.name}</Typography>}
                  </Button>
                )
              })}
            </Grid></>}
          <br />
          <Grid container direction="row" justify="space-between">
            {this.forumLowerBlock(postDetails, likeList, classes)}
          </Grid>
          <br />
          <Grid container direction="row" justify="space-between" alignItems="center">
            {this.descBlockUi(postDetails, comment, classes)}
            <br />
            {this.commentUi(postDetails, currentHideShowId, currentHideShowIndex, openSubComment, likeList, currentCommentIndex,
              openReply, currentReplyIndex, reply)}
          </Grid>
        </CardContent>
      </Card>
    )
  }
  rightBlock = (postDetails, questionDetail, classes) => {
    return (<Grid item xs={12} sm={3} md={3} lg={3} xl={3} className={classes.rightBlock}>
      <Grid container direction='column' alignItems="center" spacing={1}>
        <Grid item>
          <img src={postDetails?.attributes?.user_image ? postDetails?.attributes?.user_image : userImage}
            className={classes.uploadedImage}>
          </img>
        </Grid>
        <Grid item>
          {postDetails && postDetails.attributes && postDetails.attributes.account && postDetails.attributes.account.first_name && <Typography variant='h3' style={{ wordBreak: 'break-word' }}>{postDetails.attributes.account.first_name}</Typography>}
        </Grid>
        <Divider style={{ width: '100%' }} />
        <br />
      </Grid>
      <br />
      <Grid container direction="row">
        <Grid item xs={1}>
        </Grid>
        <Grid item>
          <Grid container direction='column' alignItems="flex-start" spacing={2}>
            <Grid item>
              <Typography variant='body2'> Question Asked{" "}:{" "}<span className={classes.orangeTxt}>{questionDetail.question_asked}</span></Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2'> Question Drafted{" "}:{" "}<span className={classes.orangeTxt}>{questionDetail.question_drafted}</span></Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2'> Question Answered{" "}:{" "}<span className={classes.orangeTxt}>{questionDetail.answered_questions}</span></Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2'> Question Pending{" "}:{" "}<span className={classes.orangeTxt}>{questionDetail.question_pending}</span></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider style={{ width: '100%' }} />
    </Grid>
    )
  }
  render() {
    const { classes } = this.props;
    const { openReply, anchorEl, openSubComment, currentHideShowId, questionDetail, currentCommentIndex, currentReplyIndex, postDetails,
      likeList, currentHideShowIndex, reply, comment } = this.state;
    return (
      <Container maxWidth="xl" className={classes.container} >
        <Grid item xs={12} container direction="row" justify="space-between">
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Grid container direction="row" >
              <Grid item xs={12}>
                {this.discussionCard(postDetails, likeList, classes, anchorEl, currentHideShowId, currentHideShowIndex, openSubComment, currentCommentIndex,
                  openReply, currentReplyIndex, reply, comment)}
              </Grid>
            </Grid>
          </Grid>
          {this.rightBlock(postDetails, questionDetail, classes)}
        </Grid>
        {!localStorage.getItem("token") && <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />}
      </Container>
    );
  }
}
// @ts-ignore
const DFDetailViewWithRouter = withRouter(DFDetailView);
const DFDetailViewWithLoader = withLoader(DFDetailViewWithRouter);
const DFDetailViewToast = withSnackbar(DFDetailViewWithLoader);
const DFDetailViewWithStyle = withStyles((theme) =>
  createStyles({
    replyTxtBox: {
      paddingLeft: '1px'
    },
    treeView: {
      width: '100% !important'
    },
    rootTree: {
      display: 'block !important'
    },
    treeGroup: { marginLeft: '0px' },
    commentContent: {
      marginTop: '.5rem'
    },
    container: {
      paddingLeft: '4rem', paddingRight: '4rem',
      [theme.breakpoints.down("xs")]: {
        padding: '1rem'
      }
    },
    userImg: {
      width: '2.5rem',
      height: '2rem',
      borderRadius: '50%'
    },
    rightBlock: {
      padding: '.5rem',
      borderRadius: '4.5px',
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #eaeaea',
      backgroundColor: '#fffefe',
      height: '22rem'
    },
    uploadedImage: {
      width: '86px',
      height: '86px',
      marginTop: '15px',
      objectFit: 'cover',
      borderRadius: '50%'
    },
    txtAlign: { textAlign: 'end' },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#ffffff'
      }
    },
    orangeTxt: {
      color: '#f48023'
    },
    purpleTxt: { color: '#7764db' },
    publishBtn: {
      backgroundColor: '#fa6400',
      height: '3rem',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff',
        borderRadius: '10px',
      },
      [theme.breakpoints.down('md')]: {
        width: '5rem',
        height: '3rem',
        borderRadius: '10px',
      },
    },
    replyBtn: {
      backgroundColor: '#fa6400',
      height: '3rem',
      width: '-webkit-fill-available',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff',
        borderRadius: '10px',
        width: '-webkit-fill-available'
      },
      [theme.breakpoints.down('md')]: {
        height: '3rem',
        borderRadius: '10px',
        width: '-webkit-fill-available'
      },
    },
    replydesc: {
      color: '#808080'
    },
    desc: {
      wordBreak: "break-all"
    },
    dotIcon: {
      color: '#1682fd',
      height: '1rem'
    },
    rightBlueTxt: {
      color: '#1682fd',
      textAlign: 'center',
      cursor: 'pointer'
    },
    rightContainer: {
      marginTop: '3rem',
      borderRadius: '4.5px',
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #eaeaea',
      backgroundColor: '#fffefe'
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    reportColor: { color: 'red' },
    txtColor: {
      color: ' #f48b1b'
    },
    reportMenu: {
      backgroundColor: 'white !important',
      borderRadius: '4px !important',
      boxShadow: '0px 1px 3px 0 rgba(0, 0, 0, 0.15) !important',
    },
    addIcon: {
      height: '1.2rem'
    },
    addShowIcon: {
      height: '.8rem',
      transform: 'rotate(180deg)'
    },
    addHideIcon: {
      height: '.8rem',
    },
    addReplyIcon: {
      height: '1rem',
      transform: 'scaleX(-1)',
      fill: '#1682fd'
    },
    dfIcon: {
      height: '1.2rem',
      fill: '#7764db'
    },
    rootCard: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
    },
    commentSection: {
      boxShadow: 'none',
      width: '100%'
    }
  })
)((DFDetailViewToast))
export default withTranslation()(DFDetailViewWithStyle);