import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Chip
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import CategoriessubcategoriesWebController, {
  Props,
} from "./CategoriessubcategoriesController.web";

export class Categoriessubcategories extends CategoriessubcategoriesWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="flex items-center flex-wrap mt-4">
        <Typography>Sub Categories</Typography>
        {this.state.subCategoriesData && this.state.subCategoriesData.map(subCategory => (
          <div className="px-2 py-2" key={String(subCategory.id)}>
            <Link to={`/sub-categories/${subCategory.id}`}>
              <Chip color="primary" label={subCategory?.attributes?.name} />
            </Link>
          </div>
        ))}
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(Categoriessubcategories);