import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { daysObject, queryobj, filterdata, setDate, getTypeOfListQparam, setOptions, getTypeOfFilter } from "./QuizAssessmentCommonutility"

export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    openToastHandler: any;


    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  assessmentList: [];
  selectedType: any;
  searchValue: any;
  currentPage: any
  totalPage: any;
  filterdata: any;
  queryobj: any;
  ratingValue: any;
  days: any;
  // Customizable Area End
}
interface SS {
  id: any;
}


export default class AssessmentViewAllWebController extends BlockComponent<
  Props,
  S,
  SS
> {


  getAssessmentApiCallId: string = "";
  getAssessmentFilterOptionsId: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      assessmentList: [],
      selectedType: 0,
      searchValue: '',
      currentPage: 1,
      totalPage: 0,
      filterdata: filterdata,
      queryobj: queryobj,
      days: daysObject,
      ratingValue: null,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getAssessmentFilterOptions('content_type', this.state.filterdata)
    this.loadData();
  }

  loadData = () => {
    this.getAssessmentContentsBysearchCriteria(this.state.filterdata)
  };

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getAssessmentApiCallId:
            this.handlegetAssessmentApiCallIdApiResponce(responseJson);
            break;
          case this.getAssessmentFilterOptionsId:
            this.handlegetAssessmentFilterOptionsIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearAssessmentLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }
  handlegetAssessmentApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ assessmentList: responseJson.data, totalPage: responseJson?.total_pages || 1 });
    }
  };
  handlegetAssessmentFilterOptionsIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        info[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: info });
    }
  };
  clearAssessmentLocalstorage = (responseJson: any) => {
    if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
    }
  };

  getAssessmentContentsBysearchCriteria = (filterObj: any) => {
    let queryStr = ''
    filterObj.forEach((item: any) => {
      if (item.id != 3 && item.value.length > 0) {
        queryStr += setOptions(item, queryStr, this.state.queryobj);
      }
      else if (item.hasOwnProperty('value') && item.value.length > 0 && item.type == 'Date') {
        queryStr += '&' + setDate(item, this.state.days)
      }
    })
    if (this.state.ratingValue) {
      queryStr += '&rating=' + this.state.ratingValue
    }
    this.getAssessment(queryStr);
  }
  getAssessment = (query: any) => {
    const { searchValue, currentPage } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const heading = getTypeOfListQparam(this.props?.match?.params?.heading)
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getAssessmentApiCallId = sendAPIRequest(`${configJSON.userAssessmentUrl}?page=${currentPage}&per_page=10${heading}${search}${query}`,
      { headers: header })
  }
  getAssessmentFilterOptions = (filterby: any, filterinfo: any) => {


    let header: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.id != 3 && ele.value.length > 0) {
        query += setOptions(ele, query, this.state.queryobj);
      }
      else if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.type == 'Date') {
        let param = '';
        param = setDate(ele, this.state.days);
        if (param) {
          query += '&' + param
        }
      }
    })
    const typeofList = getTypeOfFilter(this.props?.match?.params?.heading, false)
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=${typeofList}${query}`;
    this.getAssessmentFilterOptionsId = sendAPIRequest(apiEndPoint,
      { headers: header })
  }


}
