export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const playButton = require("../assets/play.png");
export const volicityNine = require("../assets/volicity-9.png");
export const visibleEye = require("../assets/eye.png");
export const bgImage = require('../assets/placeholder_audiovideo.png')

export const Group = require("../assets/Group.png");
export const share = require("../assets/share.png");
export const Star = require("../assets/Star.png");
export const filter = require("../assets/filter.png");
export const dark_filter = require("../assets/dark_filter.png");
export const courseImg = require("../assets/cousres-all.png");
export const downArrow = require("../assets/arrow-down.png");
export const next = require("../assets/next.png");
export const prev = require("../assets/prev.png");
export const fbImg = require("../assets/facebook.png");
export const linkedInImg = require("../assets/Linkedin.png");
export const bannerInImg = require("../assets/banner-image.png");
export const groupImg = require("../assets/Group2202.png");

export const homeImage = require("../assets/home_image.png");
export const volicity = require("../assets/volicity-9.png");

export const eye = require("../assets/eye.png");
export const defaultImage = require("../assets/placeholder_all.png")
export const defaultImageVideo = require("../assets/placeholder_audiovideo.png")


export const mockUp = require("../assets/Mockup.png");
export const play = require("../assets/play.png");
export const epub = require("../assets/epub_logo_color.jpg");
export const dummyImage = require("../assets/image.png");
export const playweb = require("../assets/playweb.png");
