//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Avatar,
  Button,
  IconButton,
  createStyles,
  Container,
  Typography,
  Drawer,
} from "@material-ui/core";
import DashboardWebController, { Props } from "./DashboardController.web";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { NavLink, Route, Redirect, Switch } from "react-router-dom";
import UserProfileBlockWeb from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import DashboardHeaderWeb from "../../../components/src/Header/DashboardHeader.web";
import DiscussionForumHeader from "../../../components/src/Header/DiscussionForumHeader.web";
import TrialsDashboard from "./TrialsDashboard.web";
import UserDashboardLearning from "./UserLearningDashboard.web";
import ActivityFeedLog from '../../ActivityFeed/src/ActivityFeedLog.web';
import Faq from "./Faq.web";
import Transaction from "./Transaction.web";
import Support from "./Support.web";
import MyResults from "./MyResults.web";
import Achievement from "./Achievement.web";
import createHistory from "history/createBrowserHistory";
import { defaultImg } from "./assets";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import MySubscription from "./MySubscription.web";
import MyBookmark from "./mybookmark.web";
import DiscussionForum from "../../CommunityForum/src/DiscussionForum.web";
import HomeIcon from "@material-ui/icons/Home";
import ForumIcon from "@material-ui/icons/Forum";
import MyThreads from "../../CommunityForum/src/MyThreads.web";
import ViewAll from "../../CommunityForum/src/ViewAll.web";
import DFDetailView from "../../CommunityForum/src/DFDetailView.web";
import DraftWeb from "../../CommunityForum/src/Draft.web";
import CartWeb from "../../categoriessubcategories/src/Cart.web";
const history = createHistory();
//@ts-ignore

const UserDetailsComp = ({ classes, userDetails }) => {
  return (
    <>
      <Grid item container direction="column" alignItems="flex-start">
        <Grid item>
          {userDetails && userDetails.first_name && (
            <Typography
              className={classes.firstName}
              color="secondary"
              variant="h3"
            >
              {userDetails.first_name || localStorage.getItem("firstName")}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {userDetails && userDetails.phone_number && userDetails.email ? (
            <Typography color="secondary" variant="subtitle1">
              {userDetails.phone_number || localStorage.getItem("phoneNumber")}
            </Typography>
          ) : (
            <Typography
              className={classes.firstName}
              color="secondary"
              variant="h3"
            >
              {userDetails.email || localStorage.getItem("email")
                ? localStorage.getItem("email")
                : localStorage.getItem("phoneNumber")}
            </Typography>
          )}
        </Grid>
        <Grid item />
        <Grid item>
          <NavLink
            to={"/dashboard/editprofile"}
            activeStyle={{
              fontWeight: 700,
              color: "white",
            }}
            className={classes.hoverLink}
            exact
          >
            <Typography
              className={classes.editpadding}
              color="secondary"
              variant="body2"
            >
              Edit
            </Typography>
          </NavLink>
        </Grid>
      </Grid>
    </>
  );
};
export class DashboardWeb extends DashboardWebController {
  constructor(props: Props) {
    super(props);
  }
  setResponsiveness = () => {
    return window.innerWidth < 1100
      ? this.setState((prevState) => ({ ...prevState, mobileView: true }))
      : this.setState((prevState) => ({ ...prevState, mobileView: false }));
  };
  async componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.showUSerData();
      this.getAllRunningCourses();
      this.getAllShortlistedCourses();
      this.getFaq();
      this.getHelpnSupport();
      this.getTransaction();
      this.getachievementslists();
      this.getAllShortlistedJobs();
    }
    if (token && this.props.location.pathname !== "/dashboard/mylearnings") {
      this.getSuggestedCourses();
    }
    this.setResponsiveness();
    window.addEventListener("resize", () => this.setResponsiveness());
    if (localStorage.getItem("token")) {
      this.getAssessments();
      this.getQuizzes();
    }
  }
  handleSideDrawerOpen = () =>
    this.setState((prevState) => ({
      ...prevState,
      sideDrawerOpen: !prevState.sideDrawerOpen,
    }));

  handleSearchInfo = (e) => this.setState({ searchInfo: e.target.value });

  handleSideDrawerClose = () =>
    this.setState((prevState) => ({
      ...prevState,
      sideDrawerOpen: !prevState.sideDrawerOpen,
    }));
  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ tabValue: newValue });
  };
  handleFilterChange = () => { };
  handleSubsFilterChange = () => { };
  handleBookmarkFilterChange = () => { };
  handleSubsTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.props.history.push(
      `/dashboard/mysubscription/${newValue === 0 ? "courses" : "shortlist"}`
    );
    this.setState({
      subsTabValue: newValue,
      tabName: newValue === 0 ? "courses" : "shortlist",
    });
  };
  handlebookmarkTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.props.history.push(
      `/dashboard/mybookmark/${newValue === 0 ? "courseshortlist" : "jobshortlist"
      }`
    );
    this.setState({
      subsTabValue: newValue,
      tabName: newValue === 0 ? "courseshortlist" : "jobshortlist",
    });
  };
  shortlistSeeAll = () => {
    this.props.history.push("/dashboard/mybookmark/courseshortlist");
    this.setState({ subsTabValue: 0, tabName: "courseshortlist" });
  };

  render() {
    //@ts-ignore
    const { classes, t } = this.props;
    const { userDetails, tabName } = this.state;
    return (
      <>
        <Grid item>
          {this.props.location.pathname !== "/dashboard/discussionforum" &&
            this.props.location.pathname !==
            "/dashboard/discussionforum/mythreads" &&
            this.props.location.pathname !== "/dashboard/viewallpost" &&
            this.props.location.pathname !==
            "/dashboard/discussionforum/draft" ? (
            <DashboardHeaderWeb
              //@ts-ignore
              dashboardCallback={this.state.sideDrawerOpen}
              mobileView={this.state.mobileView}
              handleSideDrawerOpen={this.handleSideDrawerOpen}
            />
          ) : (
            <DiscussionForumHeader
              //@ts-ignore
              data-testId="DiscussionForumHeader"
              dashboardCallback={this.state.sideDrawerOpen}
              mobileView={this.state.mobileView}
              handleSideDrawerOpen={this.handleSideDrawerOpen}
              handleSearchInfo={this.handleSearchInfo}
            />
          )}
        </Grid>
        <Container maxWidth="xl" style={{ padding: "0px" }}>
          <Grid direction="column" alignItems="center" justify="center">
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={2}>
                  <div className="pl-4 pr-4">
                    {this.props.location.pathname !==
                      "/dashboard/discussionforum" &&
                      this.props.location.pathname !==
                      "/dashboard/discussionforum/mythreads" &&
                      this.props.location.pathname !== "/dashboard/viewallpost" &&
                      this.props.location.pathname !== "/dashboard/detailview" &&
                      this.props.location.pathname !==
                      "/dashboard/discussionforum/draft" ? (
                      <Drawer
                        anchor="left"
                        open={
                          this.state.mobileView
                            ? this.state.sideDrawerOpen
                            : true
                        }
                        data-testId="handleSideDrawerOpen"
                        onClose={this.handleSideDrawerOpen}
                        variant={"persistent"}
                        BackdropProps={{ invisible: true }}
                        classes={{ paper: classes.drawerPaper }}
                      >
                        <>
                          <br />
                          <br />
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Grid item xs={3}>
                              {userDetails && userDetails.user_pic ? (
                                <Avatar
                                  alt="profileImg"
                                  src={userDetails.user_pic}
                                />
                              ) : (
                                <Avatar
                                  alt="profileImg"
                                  src={defaultImg}
                                  className={classes.userImg}
                                />
                              )}
                            </Grid>
                            <Grid item xs={7}>
                              <UserDetailsComp data-testId="UserDetailsComp" {...{ classes, userDetails }} />
                            </Grid>
                          </Grid>
                          <br />
                          <br />
                          <br />
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            justify="space-between"
                            className={classes.dbMenu}
                          >
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/mylearnings`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Learning / Dashboard ")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/mytrials`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Trials")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/mysubscription/${tabName}`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Subscriptions")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/mybookmark/${tabName}`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Bookmark")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/results`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Results")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/home/checkout`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Cart")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/achievements`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Achievements")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/transactions`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Transactions")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/discussionforum/mythreads`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                >
                                  {t("My Discussions")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/myactivity`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                >
                                  {t("My Activity")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/faqs`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("FAQs")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1" color="secondary">
                                <NavLink
                                  to={`/dashboard/support`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "white",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("Support")}
                                </NavLink>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                data-testId="logout"
                                onClick={() => {
                                  this.props.showLoader();
                                  localStorage.clear();
                                  sessionStorage.clear();
                                  window.location.href = "/";
                                }}
                                color="secondary"
                                style={{
                                  backgroundColor: "transparent",
                                  padding: "0px",
                                  paddingRight: "10px",
                                }}
                              >
                                <Typography variant="subtitle1">
                                  {t("Logout")}{" "}
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      </Drawer>
                    ) : (
                      <Drawer
                        anchor="left"
                        open={
                          this.state.mobileView
                            ? this.state.sideDrawerOpen
                            : true
                        }
                        data-testId="handleSideDrawerOpen"
                        onClose={this.handleSideDrawerOpen}
                        variant={"persistent"}
                        BackdropProps={{ invisible: true }}
                        classes={{ paper: classes.discussionDrawer }}
                      >
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-start"
                          justify="flex-start"
                          className={classes.dbMenu}
                        >
                          <br />
                          <br />
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-haspopup={true}
                                onClick={() =>
                                  this.props.history.push(
                                    "/dashboard/discussionforum"
                                  )
                                }
                                className="z-10"
                              >
                                <HomeIcon className={classes.iconColor} />
                              </IconButton>
                              <Typography variant="subtitle1" color="primary">
                                <NavLink
                                  to={`/dashboard/discussionforum`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "#222222",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("Home")}
                                </NavLink>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-haspopup={true}
                                onClick={() =>
                                  this.props.history.push(
                                    "/dashboard/discussionforum/mythreads"
                                  )
                                }
                                className="z-10"
                              >
                                <ForumIcon className={classes.iconColor} />
                              </IconButton>
                              <Typography variant="subtitle1" color="primary">
                                <NavLink
                                  to={`/dashboard/discussionforum/mythreads`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "#222222",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("My Threads")}
                                </NavLink>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-haspopup={true}
                                onClick={() =>
                                  this.props.history.push(
                                    "/dashboard/discussionforum"
                                  )
                                }
                                className="z-10"
                              >
                                <ForumIcon className={classes.iconColor} />
                              </IconButton>
                              <Typography variant="subtitle1" color="primary">
                                <NavLink
                                  to={`/dashboard/discussionforum/draft`}
                                  activeStyle={{
                                    fontWeight: 700,
                                    color: "#222222",
                                  }}
                                  className={classes.hoverLink}
                                >
                                  {t("Drafted")}
                                </NavLink>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Drawer>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <div className="mt-24">
                    <Switch>
                      <Redirect
                        exact
                        to="/dashboard/mylearnings"
                        from="/dashboard"
                      />
                      <Route path="/dashboard/editprofile">
                        <UserProfileBlockWeb
                          showUSerData={this.showUSerData}
                        />
                      </Route>
                      <Route path="/dashboard/mylearnings">
                        <UserDashboardLearning
                          shortlistSeeAll={this.shortlistSeeAll}
                        />
                      </Route>
                      <Route path="/dashboard/support">
                        <Support supportList={this.state.supportList} />
                      </Route>
                      <Route path="/dashboard/myactivity">
                        <ActivityFeedLog />
                      </Route>
                      <Route path="/dashboard/faqs">
                        <Faq faqList={this.state.faqList} />
                      </Route>
                      <Route path="/dashboard/transactions">
                        <Transaction
                          transactionHistory={this.state.transactionHistory}
                        />
                      </Route>
                      <Route path="/dashboard/mytrials">
                        <TrialsDashboard />
                      </Route>
                      <Route path="/dashboard/results" exact>
                        <MyResults
                          data-testId="handleTabChange"
                          handleTabChange={this.handleTabChange}
                          tabValue={this.state.tabValue}
                          mobileView={this.state.mobileView}
                          quizList={this.state.quizList}
                          assessmentList={this.state.assessmentList}
                        />
                      </Route>
                      <Route path="/dashboard/achievements" exact>
                        <Achievement
                          certificateList={this.state.certificateList}
                          handleFilterChange={this.handleFilterChange}
                          mobileView={this.state.mobileView}
                        />
                      </Route>
                      <Route path="/dashboard/mysubscription/:name" exact>
                        <MySubscription
                          subsTabValue={this.state.subsTabValue}
                          handleSubsFilterChange={this.handleSubsFilterChange}
                          mobileView={this.state.mobileView}
                          handleSubsTabChange={this.handleSubsTabChange}
                          allRunningCourseList={this.state.allRunningCourseList}
                          allShortlistedCourseList={
                            this.state.allShortlistedCourseList
                          }
                          removeBookmark={this.removeBookmark}
                        />
                      </Route>
                      <Route path="/dashboard/mybookmark/:name" exact>
                        <MyBookmark
                          subsTabValue={this.state.subsTabValue}
                          handleBookmarkFilterChange={
                            this.handleBookmarkFilterChange
                          }
                          mobileView={this.state.mobileView}
                          handlebookmarkTabChange={this.handlebookmarkTabChange}
                          allShortlistedJobList={
                            this.state.allShortlistedJobList
                          }
                          allShortlistedCourseList={
                            this.state.allShortlistedCourseList
                          }
                          suggestedCourseList={this.state.suggestedCourseList}
                          removeBookmark={this.removeBookmark}
                          removeJobsBookmark={this.removeJobsBoomark}
                        />
                      </Route>
                      <Route path="/dashboard/discussionforum" exact>
                        <DiscussionForum searchInfo={this.state.searchInfo} />
                      </Route>
                      <Route path="/dashboard/discussionforum/mythreads" exact>
                        <MyThreads searchInfo={this.state.searchInfo} />
                      </Route>
                      <Route path="/dashboard/viewallpost" exact>
                        <ViewAll searchInfo={this.state.searchInfo} />
                      </Route>
                      <Route path="/dashboard/detailview" exact>
                        <DFDetailView />
                      </Route>
                      <Route path="/dashboard/discussionforum/draft" exact>
                        <DraftWeb searchInfo={this.state.searchInfo} />
                      </Route>
                      <Route path="/dashboard/Cart" exact>
                        <CartWeb />
                      </Route>
                    </Switch>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const DashboardWebWithRouter = withRouter(DashboardWeb);
const DashboardWebWithLoader = withLoader(DashboardWebWithRouter);
const DashboardWebToast = withSnackbar(DashboardWebWithLoader);
const DashboardWithStyle = withStyles((theme) =>
  createStyles({
    hoverLink: {
      "&:hover": {
        fontWeight: 700,
      },
    },
    iconColor: {
      fill: "#7764db",
    },
    discussionDrawer: {
      marginTop: "4rem",
      width: "250px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "white",
      padding: theme.spacing(1),
      height: "calc(100% - 64px)",
      paddingLeft: "3rem",
      paddingRight: "1rem",
    },
    drawerPaper: {
      marginTop: "4rem",
      width: "250px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: `${theme.palette.primary.light}`,
      padding: theme.spacing(1),
      height: "calc(100% - 64px)",
      borderBottomRightRadius: "53px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    userImg: {
      height: "3.5rem",
      width: "3.5rem",
      border: "1px solid grey",
    },
    dbMenu: {
      height: "calc(80% - 16px)",
    },
    firstName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "8rem",
    },
    editpadding: {
      paddingTop: ".3rem",
      cursor: "pointer",
    }
  })
)(DashboardWebToast);
export default withTranslation()(DashboardWithStyle);