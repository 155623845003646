//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Paper,
  Button,
  InputBase,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import ExamViewallWebController, {
  Props,
} from "./ExamViewallController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import ExamsList from './ExamsList.web';
import Categorysearch from '../../categoriessubcategories/src/Categorysearch.web';
import { courseImg } from "./assets";
import { categoryArticalExamViewAllSearch } from '../../../components/src/CommonWebCSSUtility';
import Pagination from '@material-ui/lab/Pagination';
//@ts-ignore
export class ExamViewallWeb extends ExamViewallWebController {
  constructor(props: Props) {
    super(props);
  }
  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        if (data.key != 'date') {
          this.getFilterOptions(data.key, this.state.filterdata)
        }
      })
    }
  }
  handleToggle = (ele: any, filterinfo: any, index: any) => {
    if (filterinfo[index].value.indexOf(ele.id) == -1) {
      filterinfo[index].value.push(ele.id)
    } else {
      filterinfo[index].value.splice(filterinfo[index].value.indexOf(ele.id), 1)
    }
    this.setState({ filterdata: filterinfo })
  }
  applyFilter = (data: any) => {
    this.getExamsBysearchCriteria(data)
  }
  clearFilter = (filterinfo: any) => {
    filterinfo = filterinfo.map((ele: any) => {
      if (ele.hasOwnProperty('value')) {
        ele.value = []
        if (ele.hasOwnProperty('names')) {
          ele.names = []
        }
        if (ele.hasOwnProperty('fromDate') && ele.hasOwnProperty('toDate')) {
          ele.fromDate = ''
          ele.toDate = ''
        }
        return ele
      }
    })
    filterinfo[0].value.push(this.props?.match?.params?.id)
    this.setState({ filterdata: filterinfo, searchValue: '', selectedType: 0 }, () => {
      this.getFilterOptions('category', filterinfo)
      this.getExamsBysearchCriteria(filterinfo)
    })
  }
  handleDateChange = (e: any, filterinfo: any, index: any) => {
    filterinfo[index][e?.target?.id] = e.target?.value
    this.setState({ filterdata: filterinfo })
  }
  handleSearchCriteria = (e: any) => {
    this.getExamsBysearchCriteria(this.state.filterdata)
  }
  setSearcdata = (e: any) => {
    this.setState({ searchValue: e.target.value })
  }
  setPage = (value: any) => {
    this.setState({ pageNo: value }, () => {
      this.getExamsBysearchCriteria(this.state.filterdata)
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginTop: '-0.25rem' }}>
          <img alt="" className="h-full" src={courseImg} />
        </Grid>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={12} sm={8} className="w-full" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" justify="center" style={{ marginBottom: '4rem' }}>
            <Paper component="form" className={classes.searchroot}>
              <InputBase
                className={classes.searchinput}
                placeholder="Search for Exams"
                data-testId="setSearcdata"
                onChange={(e) => this.setSearcdata(e)}
                value={this.state.searchValue}
                inputProps={{ "aria-label": "Search for Courses" }}
              />
              <Button variant="contained" className={classes.searchBtn} data-testId="handleSearchCriteria" onClick={(e) => this.handleSearchCriteria(e)} >Search</Button>
            </Paper>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '15px' }}>
            <Grid item xs={12} md={3} sm={12}>
              <Paper className={classes.paper}>
                {/* @ts-ignore */}
                <Categorysearch
                  onclick={this.handleClick}
                  handleToggle={this.handleToggle}
                  applyFilter={this.applyFilter}
                  clearFilter={this.clearFilter}
                  handleDateChange={this.handleDateChange}
                  selectedType={this.state.selectedType}
                  filterdata={this.state.filterdata}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} sm={12}>
              <Paper className={classes.paperserch}>
                <ExamsList
                  examsList={this.state.examsList}
                />
              </Paper>
              {this.state.totalPages > 1 &&
                <Grid container>
                  <Grid item xs={9} className={classes.paginator} >
                    <Pagination count={this.state.totalPages} variant="outlined" shape="rounded" data-testId="setPage" onChange={(val) => this.setPage(val)} />
                  </Grid>
                </Grid>}
            </Grid>

          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
// @ts-ignore
const ExamViewallWebWithRouter = withRouter(ExamViewallWeb);
const CourseViewallWebWithLoader = withLoader(ExamViewallWebWithRouter);
const ExamViewallWebToast = withSnackbar(CourseViewallWebWithLoader);
const ExamViewallWebWithStyle = withStyles((theme) =>
  createStyles({
    ...categoryArticalExamViewAllSearch(theme),
    searchroot: {
      padding: "0px 0px",
      display: "flex",
      alignItems: "center",
      width: '60%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    searchinput: {
      marginLeft: theme.spacing(1),
      flex: 1,
      '& input': {
        color: "gray",
      }
    },
    searchBtn: {
      color: "#ffffff !important",
      border: "2px solid #f48b1b !important",
      padding: "6px 37px",
      backgroundColor: "#f48b1b",
      '&:hover': {
        color: "#ffffff !important",
        backgroundColor: "#f48b1b",
      }
    },
    paginator: {
      marginTop: '2rem',
      marginBottom: '4rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
  })
)((ExamViewallWebToast))
export default withTranslation()(ExamViewallWebWithStyle);