import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import i18n from "i18next";
import { Trans } from "react-i18next";
export type Props = {
  // Customizable Area Start
  id: string;
  navigation: any;
  match: any;
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class FooterWebController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        // console.log("responseJson", responseJson);
        // Get All Categories
      } else {
        //Check Error Response
        console.log(responseJson);
        // this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
