//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Button, createStyles, Container, Typography, CardMedia,
  Card, CardContent, InputBase, Tabs, Tab, Hidden
} from "@material-ui/core";
import ScrollMenu from "react-horizontal-scrolling-menu";
//@ts-ignore
import { withRouter } from "react-router";
import { prev, next, collegeImg, schoolImg, pearlImg, degreeImg } from './assets';
import { withTranslation, } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import CollegeController, { props } from "./CollegeController.web";
import SearchIcon from '@material-ui/icons/Search';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
function TabPanel(props) {
  const { children, value, index, classes, list, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Grid container direction="row" alignItems="center" style={{ padding: '2.5rem' }} >
        <Grid item xs={12} className="w-full">
          {list && list.length > 0 && list.map((sp: any) =>
            <Button className={classes.customBtn}
              variant="outlined" key={sp.id} style={{ marginRight: '1rem', marginBottom: '1.5rem' }}
            >
              {sp?.attributes?.logo ? <img src={sp?.attributes?.logo} className={classes.locationimg} /> :
                <img src={degreeImg} className={classes.locationimg} />}
              <Tooltip title={sp?.attributes?.name} aria-label={sp?.attributes?.name}>
                <Typography variant="body2" className={classes.btnTypo}>{sp?.attributes?.name.substring(0, 15).concat("...")}</Typography>
              </Tooltip>
            </Button>
          )}
          {list && list.length == 0 && <Typography variant="body2" className={classes.btnTypo}>No
            Data</Typography>}
        </Grid>
      </Grid>
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const CollegeScrollmenu = ({ classes, collegeList, numFormatter, gotoWebsite }) => {
  return (
    <ScrollMenu
      clickWhenDrag={true}
      dragging={true}
      hideArrows={true}
      hideSingleArrow={true}
      scrollToSelected={true}
      alignCenter={false}
      transition={0.3}
      translate={0}
      wheel={false}
      arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
      arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
      //@ts-ignore
      data={collegeList && collegeList.map((_data: any, idx: any) => (
        <Grid item key={'pc_' + idx}>
          <Card className={classes.popularSchoolCard}>
            <CardContent >
              <Grid item className={classes.popularcardImg} style={{ marginBottom: "0.85rem" }}>
                {_data?.attributes?.logo && _data?.attributes?.logo?.url ? <img src={_data?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}
                <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                  <Typography variant="h3" className={classes.popularUnivarsityTitle}>{_data?.attributes?.name}</Typography>
                  <Typography variant="body" style={{ color: '#fff' }}>{_data?.attributes?.location?.data?.attributes?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Total fee</Typography>
                    <Typography variant="body2">{numFormatter(_data?.attributes?.total_fees_min)} - {numFormatter(_data?.attributes?.total_fees_max)}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Median salary</Typography>
                    <Typography variant="body2">{numFormatter(_data?.attributes?.median_salary)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ margin: '0.5rem 0' }} />
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Course Rating</Typography>
                    <Typography variant="body2">{_data?.attributes?.course_rating ? _data?.attributes?.course_rating : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Ranked</Typography>
                    <Tooltip title={_data?.attributes?.location?.data?.attributes?.name}><Typography variant="body2">{_data?.attributes?.rank} Ranked in {_data?.attributes?.location?.data?.attributes?.name}</Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
                <Button className={classes.visiteWebsiteBtn} style={{ marginRight: '0.5rem' }} disabled={!_data?.attributes?.website_url}>
                  <Typography variant="body2" className={classes.btnTypo} onClick={(e) => gotoWebsite(_data)}>Visite website</Typography>
                </Button>
                <a href={_data?.attributes?.brochure} target="_blank" download disabled={!_data?.attributes?.brochure}>	<Button disabled={!_data?.attributes?.brochure} className={classes.downloadBrochureBtn}>
                  <Typography variant="body2" className={classes.btnTypo}>Download Brochure</Typography>
                </Button></a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))
      } />
  )
}
const CollegeScrollmenuSmall = ({ classes, collegeList, numFormatter, gotoWebsite }) => {
  return (
    <ScrollMenu
      clickWhenDrag={true}
      dragging={true}
      hideArrows={true}
      hideSingleArrow={true}
      scrollToSelected={true}
      alignCenter={false}
      transition={0.3}
      translate={0}
      wheel={false}
      arrowLeft={<Grid item ><img className="w-32 arrow-prev" src={prev} /></Grid>}
      arrowRight={<Grid item><img className="w-32 arrow-next" src={next} /></Grid>}
      //@ts-ignore
      data={collegeList && collegeList.map((_data: any, idx: any) => (
        <Grid item key={'pc_' + idx}>
          <Card className={classes.popularSchoolCard}>
            <CardContent >
              <Grid item className={classes.popularcardImg} style={{ marginBottom: "0.85rem" }}>
                {_data?.attributes?.logo && _data?.attributes?.logo?.url ? <img src={_data?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}
                <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                  <Typography variant="h3" className={classes.popularUnivarsityTitle}>{_data?.attributes?.name}</Typography>
                  <Typography variant="body" style={{ color: '#fff' }}>{_data?.attributes?.location?.data?.attributes?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Total fee</Typography>
                    <Typography variant="body2">{numFormatter(_data?.attributes?.total_fees_min)} - {numFormatter(_data?.attributes?.total_fees_max)}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Median salary</Typography>
                    <Typography variant="body2">{numFormatter(_data?.attributes?.median_salary)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <hr style={{ margin: '0.5rem 0' }} />
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Course Rating</Typography>
                    <Typography variant="body2">{_data?.attributes?.course_rating ? _data?.attributes?.course_rating : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2">Ranked</Typography>
                    <Tooltip title={_data?.attributes?.location?.data?.attributes?.name}>
                      <Typography className={classes.ranks} variant="body2">{_data?.attributes?.rank} Ranked in {_data?.attributes?.location?.data?.attributes?.name}</Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
                <Button className={classes.visiteWebsiteBtn} style={{ marginRight: '0.5rem' }} disabled={!_data?.attributes?.website_url}>
                  <Typography variant="body2" className={classes.btnTypo} onClick={(e) => gotoWebsite(_data)}>Visite website</Typography>
                </Button>
                <a href={_data?.attributes?.brochure} target="_blank" download disabled={!_data?.attributes?.brochure}>	<Button disabled={!_data?.attributes?.brochure} className={classes.downloadBrochureBtn}>
                  <Typography variant="body2" className={classes.btnTypo}>Download Brochure</Typography>
                </Button></a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))
      } />
  )
}
export class College extends CollegeController {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.getFeatureColleges()
    this.getPopularColleges()
    this.getTopLocations()
    this.getCollegeByLocation('Near You', {})
    this.getCollegeFeaturedCtas()
    this.getDegrees()
    this.getCollegeCtas()
  }
  clickSeeMore = () => {
    this.setState(prevState => ({ seeMore: !prevState.seeMore }));
  }
  render() {
    const self = this
    const { numFormatter, gotoWebsite } = self
    const { classes } = this.props;
    const { featuredCollegeList, topLocations, collegeListByLocation, selectedLocation,
      specializationsList, colegesCtasList, tabdegreeList } = this.state;
    return (<>
      <Container maxWidth="xl" style={{ padding: "1rem" }}>
        <Grid item xs={12} className={classes.categoryViewall}>
          <img alt="" src={collegeImg} className={classes.collegeImage} style={{ width: "100%" }} />
          <Grid container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">
            <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
              {/* <SearchWeb /> */}
              <Grid item xs={2} sm={1}>
                <SearchIcon color="secondary" />
              </Grid>
              <Grid item xs={10} sm={11}>
                <InputBase
                  placeholder="Search colleges here"
                  classes={{
                    input: classes.inputInput,
                  }}
                  data-testId="searchData"
                  onChange={(e) => this.searchData(e)}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" style={{ padding: "0.8rem 0.5rem", backgroundColor: "#eeeeee" }} className={classes.featuredBlock}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2" className={classes.featuredText}>Featured</Typography>
          </Grid>
          <Grid item style={{ width: "calc(100% - 25px)" }}>
            <Grid container direction="row" alignItems="center" style={{ flexWrap: "inherit", overflowX: "auto" }}>
              {colegesCtasList && colegesCtasList.length > 0 && colegesCtasList.map((fc: any) =>
                <Grid item xs={6} md={3} sm={4} lg={3} xl={2} key={'cta_' + fc.id} style={{ padding: "0 0.15rem", minWidth: "20rem" }}>
                  <Card className={classes.featuredCard} data-testId="navigateCta" onClick={(e) => this.navigateCta(fc?.attributes?.href_link)}>
                    <CardMedia
                      className={classes.cover}
                      component='img'
                      image={fc?.attributes?.square_background_image}
                      title="Live from space album cover"
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br /><br />
      <Container style={{ padding: "1rem" }} className={classes.popularSchoolBlock}>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" className={classes.paddingExamArVi}>
          <Grid container direction="row" >
            <Grid item xs={12} md={8} lg={9} className={classes.collegeleftBlock}>
              <Paper className={classes.tabsroot}>
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleTabChange}
                  // indicatorColor="primary" 
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f48b1b",
                      height: '6px',
                    }
                  }}
                  textColor="primary"
                  centered
                >
                  {tabdegreeList && tabdegreeList.map((degree: any, i: any) =>
                    <Tab key={degree.id} label={degree?.attributes?.name} className={classes.collegeTab} />
                  )}
                  <Tab label={'More'} style={{ textTransform: 'none' }} className={classes.collegeTab} />
                </Tabs>
                <TabPanel data-testId="tabPanel" value={this.state.tabValue} list={specializationsList} index={this.state.tabValue} classes={classes} />
              </Paper>
              <br /><br />
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                  <Hidden smDown><Typography align="center" style={{ color: "#f48b1b" }} variant="h4">Featured Colleges</Typography></Hidden>
                  <Hidden mdUp><Typography align="center" style={{ color: "#f48b1b" }} variant="h3">Featured Colleges</Typography></Hidden>
                  {this.state.featuredCollegeList && this.state.featuredCollegeList.length > 4 &&
                    <Button
                      className={classes.showAllBtn}
                      color="primary"
                      onClick={() => this.props.history.push(
                        {
                          pathname: `/home/collegesearch`,
                          state: {
                            featured: true
                          }
                        })}
                      variant="outlined">
                      <Hidden smDown><Typography variant="body2" className="font-bold">Show all</Typography></Hidden>
                      <Hidden mdUp><Typography variant="caption">Show all</Typography></Hidden>
                    </Button>
                  }
                </Grid>
                {/* <Typography variant="h4"  style={{ color: "#f48b1b" }}>Featured Colleges</Typography> */}
                <br />
                <Grid container Direction="row" alignItems="center">
                  {featuredCollegeList && featuredCollegeList.length > 0 && featuredCollegeList.map((fc: any) =>
                    <Grid item xs={12} sm={4} md={3} key={'fc_' + fc.id} className={classes.faturedschoolBlk}>
                      <Card className={classes.featuredSchoolCard} data-testId="navigateCta2" onClick={(e) => this.navigateCta(fc?.attributes?.website_url)}>
                        <CardContent style={{ height: "14rem" }}>
                          <Grid item className={classes.faturedleftReview}>
                            {fc?.attributes?.logo && fc?.attributes?.logo?.url ? <img src={fc?.attributes?.logo?.url} className={classes.schoolreviewerImg} /> : <img src={schoolImg} className={classes.schoolreviewerImg} />}
                          </Grid>
                          <Grid item style={{ padding: '8px' }}>
                            <Grid container direction="column">
                              <Typography variant="h4" style={{ marginBottom: '0.3rem', color: '#7764db' }} className={classes.rightReviewTitle}>{fc.attributes.name}</Typography>
                              <Tooltip title={fc?.attributes?.location?.data?.attributes?.name}>
                                <Typography classeName={classes.ranks} variant="body2">Ranked {fc?.attributes?.rank ? fc?.attributes?.rank : 0}  in {fc?.attributes?.location?.data?.attributes?.name} location</Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {featuredCollegeList && featuredCollegeList.length === 0 &&
                    <Grid item xs={12} sm={4} md={3} className={classes.faturedschoolBlk}>
                      No colleges to display
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={3} className="w-full">
              <Card className={classes.addsCard}>
                <CardContent style={{ padding: "8px" }}>
                  <Grid container direction="row" alignItems="flex-start" justify="space-between">
                    <Grid item className={classes.addsCardLeft}>
                      <img src={schoolImg} className={classes.collegeCdkImg} style={{ width: "3.2rem" }} />
                    </Grid>
                    <Grid item className={classes.addsCardRight}>
                      <img src={pearlImg} className={classes.collegeLogoImg} style={{ width: "9.2rem" }} />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid item style={{ padding: '8px' }}>
                    <Grid container direction="column">
                      <Typography variant="h4" style={{ marginBottom: '0.5rem', color: '#007bff', textAlign: 'center' }} >YOUR JOURNEY TO A GREAT CAREER BEGINS NOW</Typography>
                      <Typography variant="body2" style={{ marginBottom: '1rem', textAlign: 'center' }}>96% Placements | 500+ Recruiters | 150+ Entrepreneurs</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Button className={classes.adsApplyBtn}>
                        <Typography variant="body2" className={classes.adsApplyBtnText}>APPLY NOW</Typography>
                      </Button>
                    </Grid>
                    <br />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
      <Container >
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.popularCollageText}>
            <Grid item> <Hidden smDown><Typography align="center" style={{ color: "#f48b1b" }} variant="h4">Popular Colleges</Typography></Hidden>
              <Hidden mdUp><Typography align="center" style={{ color: "#f48b1b" }} variant="h3">Popular Colleges</Typography></Hidden>
            </Grid>
            {this.state.popularCollegeList && this.state.popularCollegeList.length > 4 &&
              <Grid item> <Button
                className={classes.showAllBtn}
                color="primary"
                onClick={() => this.props.history.push(
                  {
                    pathname: `/home/collegesearch`,
                    state: {
                      popular: true
                    }
                  })}
                variant="outlined">
                <Hidden smDown><Typography variant="body2" className="font-bold">Show all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption">Show all</Typography></Hidden>
              </Button></Grid>
            }
          </Grid>
          {/* <Typography variant="h4"  style={{ color: "#f48b1b" }}>Popular Colleges</Typography> */}
          <br />
          <Grid container Direction="row" alignItems="center">
            <Grid container className={classes.paddingExamAr}>
              <Grid item xs={12} className="w-full">
                <Hidden smDown>
                  <CollegeScrollmenu data-testId="collegeScrollmenu" {...{ classes, collegeList: this.state.popularCollegeList, numFormatter, gotoWebsite }} />
                </Hidden>
                <Hidden mdUp>
                  <CollegeScrollmenuSmall data-testId="collegeScrollmenuSmall" {...{ classes, collegeList: this.state.popularCollegeList, numFormatter, gotoWebsite }} />
                </Hidden>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
          {this.state.popularCollegeList && this.state.popularCollegeList.length == 0 &&
            <Grid item xs={12} sm={4} md={3} className={classes.faturedschoolBlk}>
              No colleges to display
            </Grid>
          }
        </Grid>
      </Container>
      <br />
      <Container className={classes.featuredBtns}>
        <Grid container direction="column" alignItems="flex-start" spacing={2} justifyContent="space-between" className={classes.paddingExamArVi}>
          <Grid item>
            <Typography variant="h4" style={{ color: "#f48b1b" }}>Top 10 Locations</Typography>
          </Grid>
          <Grid container item direction="row" alignItems="center"  >
            <Grid item xs={12} className="w-full">
              <Button className={classes.customBtn} style={{ marginRight: "1rem" }}
                variant="outlined" onClick={() => this.setSelectLocationCollege("Near You", {})}
              >
                <LocationOnOutlinedIcon style={{ fontSize: '2rem', color: '#7764db' }} />
                <Typography variant="body2" className={classes.btnTypo}>Near You</Typography>
                {selectedLocation && selectedLocation == "Near You" && <CheckCircleIcon className={classes.activeCheck} />}
              </Button>
              {topLocations && topLocations.length > 0 && topLocations.map((tp: any) =>
                <Button className={classes.customBtn} style={{ marginRight: "1rem" }}
                  variant="outlined" key={'toplocation_' + tp.id} onClick={() => this.setSelectLocationCollege(tp?.attributes?.name, tp)}
                >
                  <img src={tp?.attributes?.logo} className={classes.locationimg} />
                  <Typography variant="body2" className={classes.btnTypo}>{tp?.attributes?.name}</Typography>
                  {selectedLocation && selectedLocation == tp?.attributes?.name && <CheckCircleIcon className={classes.activeCheck} />}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <br /><br />
        <Grid item xs={12}>
          <Grid container Direction="row" alignItems="center">
            {collegeListByLocation && collegeListByLocation.length > 0 && collegeListByLocation.map((ele: any) =>
              <Grid item xs={12} sm={6} md={4} key={'collegebylocaton_' + ele.id}>
                <Card className={classes.popularSchoolCard}>
                  <CardContent style={{ padding: "10px" }}>
                    <Grid item className={classes.popularcardImg} style={{ marginBottom: "0.85rem" }}>
                      {ele?.attributes?.logo && ele?.attributes?.logo?.url ? <img src={ele?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}
                      <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                        <Typography variant="h3" className={classes.popularUnivarsityTitle}>{ele?.attributes?.name}</Typography>
                        <Typography variant="body" style={{ color: '#fff' }}>{ele?.attributes?.location?.data?.attributes?.name}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Total fee</Typography>
                          <Typography variant="body2">{this.numFormatter(ele?.attributes?.total_fees_min)} - {this.numFormatter(ele?.attributes?.total_fees_min)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Median salary</Typography>
                          <Typography variant="body2">{this.numFormatter(ele?.attributes?.median_salary)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <hr style={{ margin: '0.5rem 0' }} />
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Course Rating</Typography>
                          <Typography variant="body2">{ele?.attributes?.course_rating ? ele?.attributes?.course_rating : '-'}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Ranked</Typography>
                          <Tooltip title={ele?.attributes?.location?.data?.attributes?.name}>
                            <Typography variant="body2" className={classes.ranks}>{ele?.attributes?.rank} Ranked in {ele?.attributes?.location?.data?.attributes?.name}</Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
                      <Button className={classes.visiteWebsiteBtn} style={{ marginRight: '0.5rem' }} disabled={!ele?.attributes?.website_url}>
                        <Typography variant="body2" className={classes.btnTypo} onClick={(e) => this.gotoWebsite(ele)}>Visite website</Typography>
                      </Button>
                      <a href={ele?.attributes?.brochure} target="_blank" download disabled={!ele?.attributes?.brochure}> <Button disabled={!ele?.attributes?.brochure} className={classes.downloadBrochureBtn}>
                        <Typography variant="body2" className={classes.btnTypo}>Download Brochure</Typography>
                      </Button></a>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {collegeListByLocation && collegeListByLocation.length === 0 &&
              <Grid item xs={12} sm={6} md={4} >
                No colleges to display
              </Grid>
            }
          </Grid>
        </Grid>
      </Container>
    </>
    );
  }
}
//@ts-ignore
const CollegeWithRouter = withRouter(College);
const CollegeWithLoader = withLoader(CollegeWithRouter);
const CollegeToast = withSnackbar(CollegeWithLoader);
const CollegeWithStyle = withStyles((theme) =>
  createStyles({
    categoryViewall: {
      margin: '3rem 3rem 1rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      // height: '25rem',
      [theme.breakpoints.down('lg')]: {
        //height: '15rem',
        margin: '1.5rem 2rem 0rem',
      },
      [theme.breakpoints.down('md')]: {
        margin: '0.5rem 0rem 0rem',
      },
    },
    featuredBtns: {
    },
    popularSchoolBlock: {
    },
    uParallax: {
      position: 'absolute',
      backgroundColor: 'rgb(6 55 106 / 53%)',
      width: '100%',
      height: '100%',
    },
    categorySearch: {
      width: '50%',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      borderRadius: '17px',
      padding: '12px 15px 8px',
      color: '#fff',
      position: 'absolute',
      left: '25%',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        left: '19%',
      },
    },
    faturedschoolBlk: {
      padding: '0.5rem'
    },
    featuredText: {
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      color: ' #222',
      fontSize: '1rem',
    },
    leftReview: {
      width: '80px',
      minWidth: '80px',
      height: '70px',
      marginRight: '10px',
    },
    rightReview: {
      width: 'calc(100% - 90px)',
      minWidth: 'calc(100% - 90px)',
    },
    rightReviewTitle: {
      fontSize: '18px',
      color: '#f48b1b',
    },
    reviewerImg: {
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    schoolreviewerImg: {
      height: '100px',
      objectFit: 'cover',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      width: '100%',
    },
    customBtn: {
      height: '90px',
      width: '90px',
      borderColor: 'rgba(112, 112, 112, 0.13) !important',
      boxShadow: '0 3px 9px 0 rgb(167 167 167 / 13%)',
      padding: '5px !important',
      marginRight: '2.3rem',
      marginBottom: '0.5rem',
      borderRadius: '8px',
      '&:hover': {
        borderColor: '#7764db !important',
        color: '#7764db !important',
        backgroundColor: '#fff !important',
      },
      [theme.breakpoints.down('lg')]: {
        marginRight: '1.5rem',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '1rem',
      },
      '& > span': {
        display: 'flex',
        flexDirection: 'column'
      },
    },
    locationimg: {
      width: '3rem',
      height: '3rem',
      objectFit: 'contain'
    },
    popularSchoolCard: {
      boxShadow: '0 3px 6px 0 rgba(131, 124, 124, 0.6)',
      border: 'solid 1px #bfb4b4',
      backgroundColor: '#fff',
      marginRight: '1.5rem',
      marginBottom: '1.5rem',
      width: '20rem',
      [theme.breakpoints.down('xs')]: {
        marginRight: '0rem',
        marginBottom: '0.5rem',
      },
    },
    popularcardImg: {
      position: 'relative',
    },
    popularSchoolImg: {
      height: '130px',
      objectFit: 'cover',
      width: '100%',
    },
    popularUnivarsityBlock: {
      position: 'absolute',
      top: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
      padding: '10px',
    },
    popularUnivarsityTitle: {
      fontSize: '1.2rem',
      color: '#fff',
    },
    visiteWebsiteBtn: {
      backgroundColor: '#7764db',
      padding: '7px 22px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      '&:hover': {
        backgroundColor: '#5840d7 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.6,
      },
    },
    downloadBrochureBtn: {
      backgroundColor: '#fa6400',
      padding: '7px 4px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      '&:hover': {
        backgroundColor: '#dd5a03 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.7,
      },
    },
    activeCheck: {
      color: '#fa6400',
      position: 'absolute',
      top: '-8px',
      right: '-8px',
    },
    collegeTab: {
      color: '#7764db !important',
      fontWeight: 'bold',
      minWidth: '100px !important',
    },
    collegeleftBlock: {
      paddingRight: '3rem',
      [theme.breakpoints.down('lg')]: {
        paddingRight: '2rem',
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: '0rem',
      },
    },
    addsCard: {
      border: '1px solid #e1e1e1',
      borderRadius: '0px',
    },
    adsApplyBtn: {
      backgroundColor: '#000',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#333 !important',
      },
    },
    tabsroot: {
      flexGrow: 1,
      boxShadow: 'none !important',
      '& >.MuiTabs-root': {
        borderBottom: '4px solid #e1e1e1',
        paddingBottom: '2px'
      }
    },
    selectedBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      width: '173px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    btnTypo: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflow: 'hidden',
      fontSize: '10px',
    },
    ranks: {
      whiteSpace: 'nowrap',
      width: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    catBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '173px',
      height: '3rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    inputInput: {
      '&::placeholder': {
        color: '#fff !important',
        opacity: '1 !important',
      }
    },
    cover: {
      height: '6.1rem',
      cursor: 'pointer',
    },
    featuredSchoolCard: {
      cursor: 'pointer',
    },
    showAllBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '8px !important',
      color: '#ffffff !important',
      width: '150px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        color: '#ffffff !important',
        height: '1rem',
        padding: '.7rem !important',
        width: '5.5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    popularCollageText: {
      justifyContent: 'space-between'
    }
  })
)(CollegeToast);
export default withTranslation()(CollegeWithStyle);