//@ts-nocheck
import React from "react";
import {
  Avatar,
  Button, createStyles,
  Container,
  Grid,
  Hidden,
  Typography, withStyles,
  Paper, IconButton, Divider, Menu, MenuItem, TextField, Dialog, DialogContent, List, ListItemText, ListItem, ListItemSecondaryAction
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import insane from 'insane';
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json';
//@ts-ignore
import { withRouter } from "react-router";
import { withSnackbar } from "../../../components/src/toast.web";
import withLoader from "../../../components/src/withLoader.web";
import { dummyImage } from "./assets";
import CareerExpertProfileWebController, { Props } from "./CareerExpertProfileController.web";
import { Formik, Form } from "formik";
import { DiscussionforumReportSchema } from "../../CommunityForum/src/Discussionforum.web.validation";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
const imgUrl = "https://minio.b54423.dev.centralindia.az.svc.builder.cafe/sbucket/uploads/bx_block_contentmanagement/course/thumbnail/4/Thumbnail1.jpg";
const courseImg = 'https://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/static/media/cousres-all.aed0df2b.png'
const ReportMenuItem = withStyles({
  root: { backgroundColor: '#ffffff', color: '#000000 !important' },
  MuiMenuItem: {
    root: { backgroundColor: '#ffffff', color: '#000000 !important', }
  },
})(MenuItem);
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const Title = ({ type, caption, classes, handleViewAll, ItemList, expertid }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
      <Hidden smDown> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
      <Hidden mdUp> <Typography align="center" variant="h3">{caption}</Typography></Hidden>
      {ItemList && ItemList.length > 1 &&
        <Button
          className={classes.viewAll}
          color="primary"
          onClick={() => handleViewAll(type, expertid, caption)}
          variant="outlined">
          <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
          <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
        </Button>
      }
    </Grid>);
};
const RenderDiscussion = ({ classes, itemList, anchorEl, handleMenuClose, openMenu }) => {
  return (
    <>
      <Grid container direction="column" alignItems="center" justify="flex-start" className={classes.RenderBlogAndArticals}>
        <>
          {itemList && itemList.length > 0 ? itemList.map((e: any) => (
            <>
              <Grid container direction="row" xs={10} sm={10} md={10} lg={10} xl={10} alignItems="flex-start" spacing={2} className={classes.RenderBlogAndArticalsContent}>
                <Paper className={classes.paperHeight}>
                  <Grid container direction="column">
                    <Grid className={classes.conatiner} item container direction="row" justify="space-between" alignItems="center">
                      <Grid className={classes.conatinerwidth} container direction="row">
                        <Grid item>
                          <Avatar alt={e?.attributes?.account?.first_name} src={e?.attributes?.user_image} />
                        </Grid>
                        <Grid className={classes.girdinfo} item>
                          <Typography variant="subtitle1" className={classes.authname}>{e?.attributes?.account?.first_name}</Typography>
                          <Typography variant="subtitle2"><ReactTimeAgo date={e?.attributes?.updated_at} locale="en-US" /></Typography>
                        </Grid>
                      </Grid>
                      <Grid item >
                        {/* <Grid container direction="row" alignItems="center" justify="flex-end"> */}
                        <IconButton color="primary" component="span">
                          <MoreVertIcon color="light" onClick={(e) => { openMenu(e) }} className={classes.addIcon} />
                          <Menu
                            classes={{ paper: classes.reportMenu }}
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <ReportMenuItem className={classes.menuColor} onClick={handleMenuClose}>Report</ReportMenuItem>
                          </Menu>
                          {/* </Grid> */}
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="h3">{e?.attributes?.title}</Typography>
                    </Grid>
                    <Grid item>
                      <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" className={classes.renderDiscussion} >
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '')
                          }}></span>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid className={classes.taginfo} item>
                      <Grid container direction="row">
                        {(e?.attributes?.tags || []).map(x => x.name).filter(y => y).map(tag => (<Grid item>
                          <Button className={classes.proTag} disabled color="primary" >
                            <Typography variant="caption">{tag}</Typography>
                          </Button>
                        </Grid>))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )) :
            <Grid item>
              <Typography variant="body2">Will Update You Soon</Typography>
            </Grid>
          }
        </>
      </Grid>
      <div className={classes.spacer} />
    </>
  )
};
const RenderBlogAndArticals = ({ type, classes, listItems, expertId }) => {
  return (
    <>
      <Grid container direction="column" alignItems="center" justify="flex-start" className={classes.RenderBlogAndArticals}>
        <>
          {listItems && listItems.length > 0 ? listItems.map((e: any) => (
            <>
              <Grid container direction="row" xs={12} sm={12} md={10} lg={10} xl={10} alignItems="flex-start" spacing={2} className={classes.RenderBlogAndArticalsContent}>
                <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
                  {e?.attributes?.images && e?.attributes?.images?.length > 0 ?
                    <img alt="" className="rounded-lg h-40 w-48" src={e?.attributes?.images[0]?.image?.url} />
                    :
                    <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
                  }
                </Grid>
                <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h3">{e.attributes.name || e.attributes.headline}</Typography>
                    </Grid>
                    <Grid item>
                      {(e?.attributes?.content || e?.attributes?.description)?.length > 300 ?
                        <div className="mt-1.5">
                          <Typography variant="subtitle2" color="primary" className={classes.description} >
                            <span dangerouslySetInnerHTML={{
                              __html: insane(`${e?.attributes?.content || e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/careerexpert/expert/${expertId}/article/${e.id}'>[Read more]</a></button>`)
                            }}></span>
                          </Typography></div>
                        : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" className={classes.description}>
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e?.attributes?.content || e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/careerexpert/expert/${expertId}/article/${e.id}'>[Read more]</a></button>`)
                          }}></span>
                        </Typography></div>
                      }
                    </Grid>
                    {e?.attributes?.content_provider?.data?.attributes?.partner_name &&
                      <Grid item>
                        <Typography className={classes.authnameBlog} variant="subtitle2">By - {e?.attributes?.content_provider?.data?.attributes?.partner_name}</Typography>
                      </Grid>}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )) :
            <Grid item>
              <Typography variant="body2">Will Update You Soon</Typography>
            </Grid>
          }
        </>
      </Grid>
      <div className={classes.spacer} />
    </>
  )
};
const RenderVideos = ({ classes, listItems }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid item xs={12} sm={11} md={10} lg={10} xl={10} className={classes.RenderVideos}>
        <Grid container direction="row" spacing={4} >
          {listItems && listItems.length > 0 && listItems.map((e: any) => (
            <Grid item key={e.id} lg={4} md={6} sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  {e.attributes.video ?
                    <div className="relative ">
                      <video
                        autoPlay={true}
                        muted
                        loop
                        className={classes.fvPlayer}
                        key={e.attributes.video}
                      >
                        <source src={e.attributes.video}
                          type="video/mp4" />
                      </video>
                    </div>
                    :
                    <Typography variant="body1">No video </Typography>}
                </Grid>
                <Grid item className={classes.headlinewi}>
                  <Typography variant="h3">{e.attributes.name || e.attributes.headline}</Typography>
                </Grid>
                <Grid item className={classes.headlinewi}>
                  {e.attributes.description.length > 100 ?
                    <div>
                      <Typography variant="subtitle2" color="primary" className={classes.description}>
                        <span dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.attributes?.contentable_id + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>
                    : <div className="mt-1.5"> <Typography variant="subtitle2" color="primary" className={classes.description}>
                      <span dangerouslySetInnerHTML={{
                        __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.attributes.contentable_id + "'>[Read more]</a></button>")
                      }}></span>
                    </Typography></div>}
                </Grid>
              </Grid>
            </Grid>
          ))
          }
        </Grid>
        {listItems && listItems.length === 0 &&
          <Grid item>
            <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}
const ExpertProfileDetails = ({ classes, expertProfilePopular, unBookNow, bookNow, continueExpertPay }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center">
      <Grid className={classes.gridwidth} item xs={12}>
        <Typography className={classes.Desicription} variant="subtitle2">{expertProfilePopular?.attributes?.description}</Typography>
      </Grid>
      <Grid item>
        <Grid className={classes.bookUnbook} direction="row" container alignItems="center" >
          <Button
            className={expertProfilePopular?.attributes?.is_purchased ? classes.unBookBtn : classes.bookBtn}
            color="primary"
            data-testId="continueExpertPay"
            onClick={() => expertProfilePopular?.attributes?.is_purchased ? null : continueExpertPay(expertProfilePopular)}
            variant="outlined"
          >
            <Typography variant="caption">{expertProfilePopular?.attributes?.is_purchased ? "Booked" : "Book"}</Typography>
          </Button>
          <Typography className={classes.persess} variant="subtitle2">
            <span>� </span><u>{expertProfilePopular?.attributes?.price || 0} Per Session</u>
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.lineGrid}>
        <Divider className={classes.underline} />
      </Grid>
    </Grid>
  )
}
export class CareerExpertProfileWeb extends CareerExpertProfileWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.loadData();
    TimeAgo.addDefaultLocale(en);
    TimeAgo.locale(en);
  }
  handleViewAll = (type, expertid, caption) => {
    if (type) {
      const { history } = this.props;
      history.push({ pathname: type, state: { expertid, caption } })
    }
  };
  openMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null, openReportDialog: true });
  };
  openFile = (evt: any) => {
    document.getElementById("preview-1").click()
  }
  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { handleViewAll, bookNow, unBookNow, openMenu, handleMenuClose, continueExpertPay } = self;
    const { expertProfilePopular, expertProfileFeatured, anchorEl } = this.state;
    let expertId = this.props.match.params?.expertId
    return (
      <>
        <Helmet
          title="Career expert profile"
          titleTemplate="%s - DailyHuntWIP"
          defaultTitle="DailyHuntWIP"
        />
        <Grid
          item
          className={classes.imgBg}
          xs={12}
          style={{ backgroundImage: `url(${expertProfilePopular?.attributes?.image || courseImg})` }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            className={classes.profileContainer}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Avatar alt="Remy Sharp" src={expertProfilePopular?.attributes?.image || imgUrl} className={classes.profilePic} />
              <Typography className={classes.nameDesignsation} variant="subtitle1">{expertProfilePopular?.attributes?.name}</Typography>
              <Typography className={classes.nameDesignsation} variant="subtitle2">{expertProfilePopular?.attributes?.designation}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Container className={classes.videoCont}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <ExpertProfileDetails data-testId="expertProfileDetails" {...{ classes, expertProfilePopular, bookNow, unBookNow, continueExpertPay }} />
            {/* Featured Blog */}
            <Title data-testId="title" {...{ type: '/home/carrerexpert/expertBlogsviewall', caption: 'Featured Blogs', classes, handleViewAll, ItemList: expertProfileFeatured?.attributes?.content_blogs?.data, expertid: expertId }} />
            <RenderBlogAndArticals {...{ type: 'blogs', classes, listItems: expertProfileFeatured?.attributes?.content_blogs?.data, expertId: expertProfileFeatured?.attributes?.id }} />
            {/* Featured Article */}
            <Title {...{ type: '/home/carrerexpert/expertArticlesviewall', caption: 'Featured Articles', classes, handleViewAll, ItemList: expertProfileFeatured?.attributes?.content_articles?.data, expertid: expertId }} />
            <RenderBlogAndArticals data-testId="renderBlogAndArticalsProfile"{...{ type: 'articles', classes, listItems: expertProfileFeatured?.attributes?.content_articles?.data, expertId: expertProfileFeatured?.attributes?.id }} />
            {/* Featured Video */}
            <Title {...{ type: '/home/carrerexpert/expertVideosviewall', caption: 'Featured Videos', classes, handleViewAll, ItemList: expertProfileFeatured?.attributes?.content_videos?.data, expertid: expertId }} />
            <RenderVideos data-testId="renderVideosProfile"{...{ type: 'videos', classes, listItems: expertProfileFeatured?.attributes?.content_videos?.data }} />
            {/* Popular Blogs */}
            <Title {...{ type: '/home/carrerexpert/expertpopularBlogsviewall', caption: 'Popular Blogs', classes, handleViewAll, ItemList: expertProfilePopular?.attributes?.content_blogs?.data, expertid: expertId }} />
            <RenderBlogAndArticals {...{ type: 'blogs', classes, listItems: expertProfilePopular?.attributes?.content_blogs?.data, expertId: expertProfilePopular?.attributes?.id }} />
            {/* Popular Discussions */}
            <Title {...{ type: '/home/dashboard/viewallpost', caption: 'Popular Discussions', classes, handleViewAll, itemList: expertProfilePopular?.attributes?.discussions?.data, expertid: expertId }} />
            <RenderDiscussion data-testId="renderDiscussion" {...{ key: `discussion-${expertProfilePopular?.attributes?.discussions?.length}`, classes, itemList: expertProfilePopular?.attributes?.discussions?.data, anchorEl, openMenu, handleMenuClose }} />
          </Grid>
          <Dialog open={this.state.openReportDialog} onClose={this.handleClose}
            PaperProps={{
              style: {
                minHeight: "500px",
                width: "720px",
                backgroundColor: "#ffffff",
                boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
              },
            }}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                title: "",
                categories: this.state.selectedProblem,
                description: "",
                tagVal: [],
                tagVals: [],
              }}
              validationSchema={DiscussionforumReportSchema}
              onSubmit={(values: any) => {
                this.createReport(values);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  isValid,
                  errors,
                  touched,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  isSubmitting,
                } = formikProps;
                return (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Container maxWidth='xl'>
                      <Grid container direction="row" className={classes.reportHead} justify="space-between" alignItems="center">
                        <Grid item>
                          <Typography variant="body2" >
                            Report
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton aria-label="close" className={classes.closeButton} data-testId="closeButton" onClick={() => this.setState({ openReportDialog: false, problemBlock: false, selectedProblem: '' })}>
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />e
                    </Container>
                    <DialogContent>
                      {!this.state.problmBlock &&
                        <Grid container direction="row" spacing={2} justify="center">
                          <Grid item xs={12}>
                            <Typography variant="h3" style={{ marginBottom: '.5rem' }}  >
                              Please select a problem
                            </Typography>
                            <Typography variant="subtitle2" >
                              If someone is in immediate danger, get help before reporting to Us. Don't wait.
                            </Typography>
                            <List dense={false}>
                              {this.state.problemType.map((prob: any) =>
                                <ListItem key={prob} className={classes.problemItem}>
                                  <ListItemText
                                    data-testId="selectProblemType"
                                    onClick={() => this.selectProblemType(prob)}
                                    secondary={prob}
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" data-testId="selectProblemType2" onClick={() => this.selectProblemType(prob)}>
                                      <ArrowForwardIosIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )}
                            </List>
                          </Grid>
                        </Grid>
                      }
                      {this.state.problemBlock &&
                        <Grid container direction="row" spacing={2} justify="center">
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" >
                              Shared Details{values.categories}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <PinkTextField
                              variant="outlined"
                              name="categories"
                              defaultValue="none"
                              onChange={handleChange}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.categories && errors.categories ? true : false}
                              InputProps={{ classes: { input: classes.txtProps }, readOnly: true, }}
                              helperText={
                                touched.categories && errors.categories ? errors.categories : ""
                              }
                              value={values.categories}
                              placeholder="Enter type of report"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PinkTextField
                              variant="outlined"
                              name="title"
                              defaultValue="none"
                              onChange={handleChange}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.title && errors.title ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.title && errors.title ? errors.title : ""
                              }
                              value={values.title}
                              placeholder="Enter Title"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PinkTextField
                              variant="outlined"
                              name="description"
                              multiline
                              rows={10}
                              onChange={handleChange}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              //error={touched.description && errors.description ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.description && errors.description ? errors.description : ""
                              }
                              value={values.description}
                              placeholder="Enter Description"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <input
                              style={{ display: 'none' }}
                              accept="image/*"
                              id={`preview-1`}
                              type="file"
                              onChange={(e, value) => {
                                setFieldValue("tagVal", [...new Set(values.tagVal.concat(e.target.files))]);
                              }}
                            />
                            <Button variant="text" onClick={(event) => this.openFile(event)}><Typography variant="body2" className={classes.addtagTxt}   >+ Add Screen Short
                            </Typography></Button>
                            {values.tagVal.length > 0 &&
                              <Box sx={{ width: 500 }}>
                                <Box
                                  mt={3}
                                  sx={{
                                    '& > :not(:last-child)': { mr: 1 },
                                    '& > *': { mr: 1 },
                                  }}>
                                  {values?.tagVal && values?.tagVal.map((v, i) => (
                                    <Chip className={classes.chip} key={i} label={v[0].name} onDelete={() => {
                                      setFieldValue("tagVal", values.tagVal.filter(x => x[0].name !== v[0].name));
                                    }} />
                                  ))}
                                </Box>
                              </Box>}
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container direction="row" justify="flex-end">
                              <Grid item xs={3}>
                                {<Button variant="contained" type="submit" onClick={() => this.setState({ openReportDialog: false, problemBlock: false, selectedProblem: '' })} className={classes.cancelBtn} >Cancel </Button>}
                              </Grid>
                              <Grid item xs={3}>
                                {<Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.publishBtn} >Report Now</Button>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </DialogContent>
                  </Form>);
              }}
            </Formik>
          </Dialog>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const CareerExpertProfileWebWithRouter = withRouter(CareerExpertProfileWeb);
const CareerExpertProfileWebWithLoader = withLoader(CareerExpertProfileWebWithRouter);
const CareerExpertProfileWebToast = withSnackbar(CareerExpertProfileWebWithLoader);
const CareerExpertProfileWebWithStyle = withStyles((theme) =>
  createStyles({
    container: {
      // marginTop: "-66px",
    },
    inputRoot: {
      width: "100%",
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    profileContainer: {
      height: '100%',
      backdropFilter: 'blur(11.2px)',
    },
    imgBg: {
      // backgroundImage: `url(${courseImg})`,
      width: "100%",
      height: "505px",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      backgroundSize: "cover",
      justifyContent: "center",
      backgroundPosition: "center",
      marginTop: "-0.25rem",
    },
    videoCont: {
      // marginTop: '-66px',
      // padding: '0px',
      maxWidth: '100%'
    },
    profilePic: {
      width: '10rem',
      height: '10rem',
      border: '6px solid #fff',
    },
    bookBtn: {
      border: "2px solid #f48b1b !important",
      backgroundColor: "#f48b1b",
      borderRadius: "20px !important",
      color: "#ffffff !important",
      marginRight: "15px",
      height: "2rem",
      "&:hover": {
        backgroundColor: "#f48b1b !important",
        color: "#ffffff !important",
      },
    },
    unBookBtn: {
      border: "2px solid #7764db !important",
      backgroundColor: "#7764db",
      borderRadius: "20px !important",
      color: "#ffffff !important",
      marginRight: "15px",
      height: "2rem",
      "&:hover": {
        backgroundColor: "#7764db !important",
        color: "#ffffff !important",
      },
    },
    paperHeight: {
      minHeight: '8rem',
      padding: '1.5rem',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      border: '1px solid #eaeaea',
      width: '100%',
    },
    proTag: {
      color: 'black !important',
      background: 'lightgray',
      margin: '0.2rem'
    },
    conatiner: {
      padding: '1rem 0rem',
    },
    conatinerwidth: {
      width: '90%',
    },
    girdinfo: {
      marginLeft: '1rem',
    },
    authname: {
      fontWeight: "bold",
    },
    mIcon: {
      width: '5%',
    },
    taginfo: {
      padding: '1rem 0rem',
    },
    authnameBlog: {
      color: '#f48b1b',
    },
    headlinewi: {
      width: '18rem',
      marginTop: '10px',
    },
    description: {
      display: 'inline',
    },
    nameDesignsation: {
      color: '#fff',
    },
    persess: {
      color: '#f48b1b',
    },
    gridwidth: {
      width: '60%',
      paddingTop: '2rem'
    },
    Desicription: {
      textAlign: 'center',
    },
    bookUnbook: {
      marginTop: "2rem",
    },
    RenderBlogAndArticals: {
      marginBottom: '2rem'
    },
    RenderBlogAndArticalsContent: {
      marginBottom: '1.5rem'
    },
    paddingExamArVi: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingBottom: '1.5rem',
      [theme.breakpoints.down("xs")]: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
      },
    },
    RenderVideos: {
      marginBottom: '1.5rem'
    },
    renderDiscussion: {
      display: 'inline',
      wordBreak: 'break-word'
    },
    lineGrid: {
      width: '100%',
      marginTop: '2rem',
      marginBottom: '2rem',
      [theme.breakpoints.down("xs")]: {
        marginTop: '1rem',
        marginBottom: '1rem',
      },
    },
    underline: {
      width: '80%',
      margin: 'auto',
      [theme.breakpoints.down("xs")]: {
        width: '80%',
        margin: 'auto'
      },
    },
    reportMenu: {
      backgroundColor: 'white !important',
      borderRadius: '4px !important',
      boxShadow: '0px 1px 3px 0 rgba(0, 0, 0, 0.15) !important',
    },
    addIcon: {
      height: '1.2rem'
    },
    addtagTxt: {
      color: '#7764db'
    },
    fvPlayer: {
      display: 'block',
      width: '20rem',
      height: '10rem',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
  })
)(CareerExpertProfileWebToast);
export default withTranslation()(CareerExpertProfileWebWithStyle);