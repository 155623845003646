import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { logo } from './assets';
import { sendAPIRequest } from "../../../components/src/Utility";
import { displayTimeInHoursminutes } from "./CommonFunctions"

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  purchased: boolean;
  boughtCourseList: any;
  courseId: any;
  courseDetail: any;
  getAllBookmarked: any;
  cartCourses: any;
  totalAmount: any;
  userDetail: any;
  openDeleteDialog: boolean;
  paymentStatus: any

}
interface SS {
  id: any;
}

export default class CartController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSuggestedCourseApiCallId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  BookmarkedCourseApiCallId: string = "";
  getCartAddCourseApiCallId: string = "";
  OrderCourseApiCallId: string = "";
  PaymentCourseApiCallId: string = "";
  getUserDetailApiCallId: string = "";
  deleteCartCourseId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      purchased: false,
      boughtCourseList: [],
      courseId: '',
      courseDetail: {},
      getAllBookmarked: [],
      cartCourses: [],
      totalAmount: 0,
      userDetail: [],
      openDeleteDialog: false,
      paymentStatus: []

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getSuggestedCourseApiCallId:
            this.handleSuggestedCourseApiResponce(responseJson);
            break;
          case this.getCartAddCourseApiCallId:
            this.handleCartAddCoursApiResponce(responseJson);
            break;
          case this.OrderCourseApiCallId:
            this.handleOrderCourseApiCallIdApiResponce(responseJson);
            break;
          case this.PaymentCourseApiCallId:
            this.handlePaymentCourseApiCallIdApiResponce(responseJson);
            break;
          case this.getUserDetailApiCallId:
            this.handleUserDetailApiCallIdApiResponce(responseJson);
            break;
          case this.deleteCartCourseId:
            this.handledeleteCartCourseIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handleSuggestedCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ boughtCourseList: responseJson.data });
    }
    this.props.hideLoader();
  };
  handleCartAddCoursApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.getBoughtCourses();
      this.setState({ cartCourses: responseJson.data });
    }
  };
  handleOrderCourseApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson.errors) {
      const options = {
        key: 'rzp_test_avu7SyAGQdRNDC',
        amount: responseJson?.data?.attributes?.price,
        currency: "INR",
        name: "Career Hunt",
        description: responseJson?.data?.type,
        image: logo,
        order_id: responseJson?.data?.attributes?.razorpay_order_id,
        handler: async (res: any) => {
          this.payment(res, responseJson)
        },
        prefill: {
          name: this.state.userDetail?.first_name,
          email: this.state.userDetail?.email,
          contact: this.state.userDetail?.phone_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      //@ts-ignore
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };
  handlePaymentCourseApiCallIdApiResponce = (responseJson: any) => {
    this.setState({ cartCourses: [] });
    if (responseJson) {
      this.setState({ paymentStatus: responseJson?.order })
      if (this.state.paymentStatus?.status === "paid") {
        this.props.history.push("/dashboard/mysubscription/courses")
      }
    }
  };
  handleUserDetailApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson.errors) {
      this.setState({ userDetail: responseJson?.data?.attributes })
    }
  };
  handledeleteCartCourseIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson.errors) {
      this.getAllCartCourses();
      this.setState({ openDeleteDialog: false, cartCourses: responseJson.data });
      this.props.openToastHandler("success", 'Course removed from Cart'!);
      this.props.history.push("/home/checkout")
    }
  };
  clearLocalstorage = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      if (responseJson?.errors?.order_courses && responseJson?.errors?.order_courses[0]) {
        this.props.openToastHandler("error", responseJson.errors.order_courses[0]);
      }
      else if (Array.isArray(responseJson?.errors) && responseJson?.errors[0]?.token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
        setTimeout(() => {
          localStorage.clear();
          this.props.history.push('/home');
        }, 2000)
      } else if (responseJson?.errors) {
        this.props.openToastHandler("error", responseJson?.errors);

      }
    }
  };

  deleteCourseFromCart = (cartId: any, courseId: any) => {
    const apiEndPoint = configJSON.deleteCourseFromCart + `/${cartId}?course_id=${courseId}`;
    let method = configJSON.httpDeleteType
    this.deleteCartCourseId = sendAPIRequest(apiEndPoint,
      { method, headers: { "Content-Type": configJSON.apiContentType, "token": localStorage.getItem("token") } })
  }
  handleClose = () => {
    this.setState({ openDeleteDialog: false })
  }
  userDetail = async () => {
    const token = await localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    this.getUserDetailApiCallId = sendAPIRequest(configJSON.getUserDetailApiEndPoint + userId,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  payment = (response: any, orderDetail: any) => {
    try {
      const paymentId = response.razorpay_payment_id;
      const apiEndPoint = `${configJSON.getPaymentIdUrl}?razorpay_order_id=${orderDetail?.data?.attributes?.razorpay_order_id}&razorpay_payment_id=${paymentId}&razorpay_signature=${response.razorpay_signature}`;
      const method = configJSON.httpPostType;
      const token = localStorage.getItem('token');
      const headers = { "token": token };
      this.PaymentCourseApiCallId = sendAPIRequest(
        apiEndPoint,
        {
          method,
          body: {},
          headers: headers
        },
      );
    } catch (err) {
    }
  }
  getBoughtCourses = () => {
    this.props.showLoader();
    const token = localStorage.getItem('token');
    this.getSuggestedCourseApiCallId = sendAPIRequest(configJSON.getBoughtCourseUrl + `/bought_courses_list`,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  getAllCartCourses = () => {
    const token = localStorage.getItem('token');
    this.getCartAddCourseApiCallId = sendAPIRequest(configJSON.addGetCartCourse,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } });
  }
  checkOut = async (order: any) => {
    const data = {
      "amount": order?.attributes?.price,
      "currency": "INR",
      "receipt": order?.id,
      "notes": {
        "notes_key_1": "Tea, Earl Grey, Hot",
        "notes_key_2": "Tea, Earl Grey… decaf."
      }
    }
    const apiEndPoint = `${configJSON.getOrderIdUrl}?cart_id=${order?.id}`;
    const method = configJSON.httpPostType;
    const token = localStorage.getItem('token');
    const headers = { "token": token };
    this.OrderCourseApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...data },
        headers: headers
      },
    );
  }
  totalAmount = (list: any) => {
    let amount: any = 0
    if (list && list.length > 0) {
      amount = list.reduce(
        (prev: any, current: any) => prev + current?.attributes?.price,
        0)
    }
    return amount
  }

  showTime = (time: any) => displayTimeInHoursminutes(time)
}
