Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LanguageOptions";
exports.labelBodyText = "LanguageOptions Body";
exports.btnExampleTitle = "CLICK ME";
exports.selectLanguage = "bx_block_languageoptions/languages?type=app_languages"
// Customizable Area End