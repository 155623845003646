//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Typography,
  Paper,
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import { withRouter } from "react-router";
import QuizControllerWeb, { Props } from "./QuizControllerWeb.web";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import withLoader from "../../../components/src/withLoader.web";
import Dialog from '@material-ui/core/Dialog';

import {
  isEmpty, secondsToTime, notEmpty, getAsciiToChar, quizAssessmentTestcss,
  getClassName, getCheckBox, checkImage
} from './QuizAssessmentCommonutility';
import {
  QuizCards, StatusIndicator,
  ShareSocialContent, ShareBlock, AnswerSbmit, ObtainedMarks, StartPage, AnswerBlock
} from "./QuizAssesmentsUtility.web"


const QuestionRender = ({ classes, value = null, handleChange, handleNext, question: { id: qId, attributes }, submit, onSubmit, questionList, index }) => {
  const hasSubmit = submit[qId];
  const { question: qTitle, options, correct_answer, image } = attributes;

  return (
    <>
      <Grid
        container
        item
        direction="column"
        className={classes.qGrid}
      >
        <AnswerBlock {...{ classes, options, hasSubmit, correct_answer, value, image, qTitle, qId, isQuiz: true, getClassName, getCheckBox, checkImage, handleChange }} />
        <AnswerSbmit {...{ classes, hasSubmit, correct_answer, options, isQuiz: true, value, qId, handleNext, notEmpty, onSubmit, getAsciiToChar }} />
      </Grid>
      <Grid item>
        {isEmpty(value) && questionList.length > 1 && index < questionList.length - 1 &&
          <>
            <Grid item xs={11}>
              <Typography data-testId="handleNext" onClick={() => handleNext()} className={classes.skipBtn}>
                Skip Question
              </Typography>
            </Grid>
            <Grid item xs={1}>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
};
const ResultPage = ({ classes, retryQuiz, gotoQuiz, userQuizData, quizResultData, shareResult, image }) => {
  if (isEmpty(quizResultData)) return null;
  const { obtained_marks, total_marks, quiz: { quiz_description } } = quizResultData?.attributes;
  return (
    <div className={classes.startRoot}>
      <Grid container justify="center">
        <Paper className={classes.paper}>
          <ObtainedMarks {...{ classes, image, obtained_marks, total_marks, description: quiz_description, isQuiz: true }} />
          <ShareBlock {...{
            classes,
            isQuiz: true,
            shareResult,
            retryQuiz
          }} />
        </Paper>
        <QuizCards {...{ classes, gotoQuiz, userQuizData, isQuiz: true, quizAssessmentAttrName: "quiz" }} />
      </Grid>
    </div>
  );
}


export class QuizWeb extends QuizControllerWeb {
  categoryData: any;
  constructor(props: Props) {
    super(props);
  }
  timer: NodeJS.Timeout;
  //@ts-ignore
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getQuizData();
  }

  handleNext = () => {
    const { currentPage, quizData } = this.state;
    const questions = quizData?.attributes?.test_questions?.data || [];
    if (questions.length === currentPage + 1) {
      this.finishQuiz();
    } else {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  setQuizCurrentPage = (i: number) => this.setState({ currentPage: i });
  handleChange = (e: any, qid: any) => {
    const { values } = this.state;
    const updatedValues = { ...values, [qid]: e.target.value };
    this.setState({ values: updatedValues });
  };

  onSubmit = (id: any) => {
    const { submit, values } = this.state;
    this.saveQuiz({ questionId: id, optionId: values[id] });
    this.setState({ submit: { ...submit, [id]: true } });
  }

  retryQuiz = () => this.setState({ showNow: 'start', currentPage: 0, values: {}, submit: {} });
  closeSocialDialog = () => this.setState({ show: false });
  manageTimer = () => {
    const { quizTimeOut } = this.state;
    if (quizTimeOut === 0) {
      this.finishQuiz();
      clearInterval(this.timer);
    } else {
      this.setState({ quizTimeOut: quizTimeOut - 1 });
    }
  };

  startQuiz = () => {
    if (localStorage.getItem('token')) {
      this.postStartQuiz();
      const { timer } = this.state?.quizData?.attributes;
      const totaltime = timer.indexOf(':') == -1 ? timer : timer.split(':')[0] * 60 * 60 + timer.split(':')[1] * 60
      this.setState({ showNow: 'quiz', currentPage: 0, values: {}, submit: {}, quizTimeOut: totaltime });
      this.timer = setInterval(this.manageTimer, 1000);
    }
    else {
      this.props.openToastHandler("error", `Please login to start test`!);
    }
  };

  finishQuiz = () => {
    this.getQuizResult();
    this.getUserQuizData();
    this.setState({ showNow: 'result' });
    clearInterval(this.timer);
  };

  gotoQuiz = (qId: any) => {
    this.props.history.push(`/home/quiz/${qId}`);
    location.reload();
  };
  shareResult = () => this.setState({ show: true });

  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { handleNext, handleChange, setQuizCurrentPage, retryQuiz, gotoQuiz, onSubmit, startQuiz, shareResult, closeSocialDialog } = self;
    const { showNow = 'start', values = {}, submit = {}, currentPage, quizData, quizTimeOut = 0, userQuizData, quizResultData } = this.state;
    const { test_questions, quiz_description, heading, image } = quizData?.attributes || {};
    const questions = test_questions?.data || [];
    return (
      <>
        <Container className={classes.container} maxWidth="xl">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                xs={12}
                lg={12}
                xl={12}
                alignItems="center"
                className={classes.uParallax}
                justify="center"
              >
                {showNow === 'start' && <StartPage  {...{ classes, description: quiz_description, heading, startQuiz, image, isQuiz: true }} />}
                {showNow === 'result' && <ResultPage data-testId="ResultPage" {...{ classes, retryQuiz, gotoQuiz, userQuizData, quizResultData, shareResult, image }} />}
                {showNow === 'quiz' &&
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="center"
                    className={classes.topContent}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <Grid className={classes.content}>
                        <Paper className={classes.quizPaper}>
                          <StatusIndicator
                            {...{
                              classes,
                              questions,
                              setCurrentPage: setQuizCurrentPage,
                              values,
                              submit,
                              quizTimeOut,
                              secondsToTime
                            }}
                          />
                          <Carousel
                            className={classes.carouselDots}
                            autoPlay={false}
                            useKeyboardArrows={false}
                            infiniteLoop={false}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            swipeable={false}
                            showArrows={false}
                            selectedItem={currentPage}
                          >
                            {questions.map((question, index) => (
                              <div className={classes.qContent} key={'QuestionRender_' + question.id}>
                                <QuestionRender
                                  data-testId="QuestionRender"
                                  {...{
                                    classes,
                                    handleNext,
                                    handleChange,
                                    value: values[question.id],
                                    question,
                                    submit,
                                    onSubmit,
                                    questionList: questions,
                                    index

                                  }}
                                />
                              </div>
                            ))}
                          </Carousel>
                          {!(questions && questions.length > 0) &&
                            <Grid container direction="column" alignItems="center" justify="flex-start">
                              <Grid item>
                                <Typography variant="body2" className="text-center">There are no questions available for this Quiz</Typography>
                              </Grid>
                            </Grid>}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            open={this.state.show}
            fullWidth={true}
            maxWidth={'xs'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ShareSocialContent {...{ classes, isQuiz: true, closeShareDialog: closeSocialDialog }} />
          </Dialog>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const QuizWebWithRouter = withRouter(QuizWeb);
const QuizWebWithLoader = withLoader(QuizWebWithRouter);
const QuizWebToast = withSnackbar(QuizWebWithLoader);
const QuizWebWithStyle = withStyles((theme) =>
  createStyles({
    ...quizAssessmentTestcss(theme),
    quizStartBtn: {
      width: '7rem',
      height: '2rem',
      marginTop: '2.5rem',
      alignSelf: 'center',
      borderRadius: 5,
      backgroundColor: '#f48b1b',
      color: "#ffffff !important",
      position: 'absolute',
      bottom: '11px',
      "&:hover": {
        backgroundColor: "#f48b1b !important",
        color: "#ffffff !important",
      },
    },
    selectedOne: {
      fontWeight: "bold",
    },
    subOne: {
      fontWeight: "bold",
      marginTop: "1rem",
    },
    subTwo: {
      textAlign: "initial",
      marginTop: "1rem",
    },
    grid: {
      alignSelf: 'center',
    },
    gridsty: {
      marginBottom: '2rem',
      maxWidth: '80%',
    },
    gridtwo: {
      alignSelf: 'center',
    },
    socialMediaButton: {
      height: '30px',
      width: '30px',
      margin: 'auto'
    },
    socialBtn: {
      marginRight: '1rem'
    },
    shareCourse: {
      padding: '2rem',
      backgroundColor: '#f9f5f6'
    },
    sharecontent: {
      overflow: 'hidden'
    },
    marks: {
      color: "#000",
      marginRight: '4px'
    },
    iconclose: {
      float: 'right',
      top: '-6px',
    }
  })
)(QuizWebToast);
export default withTranslation()(QuizWebWithStyle);
