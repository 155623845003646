import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData} from "../../../framework/src/Utilities";
import FilterOptionsToActivityFeedAdapter from '../../../blocks/adapters/src/FilterOptionsToActivityFeedAdapter'
import moment from 'moment'
import { GetDateFilterOptions } from "../../CommunityForum/src/components/Helper/common";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  outOfStock: boolean;
  contentType: boolean;
  contentProvider: boolean;
  date: boolean;
  tag: boolean;
  checkedStock: boolean;
  checkedDiscounted: boolean;
  checkedBrand: any;
  rangeLow: any;
  rangeHigh: any;
  value: any;
  token: string;
  data: any;
  checkedCategory: boolean;
  checkedTag: boolean;
  GetAllBrand: any;
  GetAllTags:any;
  GetSubCategorybyCategorywithCount:any;
  GetAllContentTypes:any;
  GetAllFilterDate:any;
  BrandList: any;
  selectedItems: any;
  selectedTags:any;
  selectedContentType:any;
  selectedCategory:any;
  scrollEnabled:boolean;
  minValue:any;
  maxValue:any;
  priceMin:any;
  priceMax:any;
  price:any;
  arrayHolder:any;
  categoryArray:any;
  catHolder:any
  GetAllContentProvider:any;
  page:any,
  per_page:any,
  language:string,
  selectedDate:any;
  dateIndex:any;
  showDatePicker:boolean;
  toDate:any;
  isFilterApply:boolean;
  isClearAll:boolean;
  isLoading:boolean;
  filterString:any;
  categories:boolean;
  subcategories:boolean;
  getAllCategories:any;
  getAllSubCategories:any;
  showSubCategories:boolean;
  selectedCategoriesData:any;
  CategoriesSelected:boolean;
  showSbucategoryData:any;
  MasterCategory:any;
  filterScreenName:any;
  isSelectCategory:boolean;
  setScreenName:any;
  customFromDate: any;
  customToDate: any;
  isCustomDateError: boolean;
  //isFromLocalStorage:boolean;
  

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteroptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getBrandApiCallId: any;
  applyAllApiCallId:any;
  contentTypeApiCallId:any;
  contentProviderApiCallId:any;
  tagsApiCallid:any;
  getAllCategoriesApiCallId:any;
  getAllSubCategoriesApiCallId:any;
  GetAllcontentsId:any;
  getCategoryFilterWithCountCallId:any;
  getContentTypeTagsAndContentProviderFilterDatawithCount:any;
  _unsubscribe: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.contentTypeApiCallId="";
    this.contentProviderApiCallId="";
    this.tagsApiCallid="";
    this.GetAllcontentsId="";
    this.getAllCategoriesApiCallId="",
    this.getAllSubCategoriesApiCallId="",
    this.getCategoryFilterWithCountCallId=""
    this.getContentTypeTagsAndContentProviderFilterDatawithCount=""
  
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      outOfStock: false,
      contentType: false,
      contentProvider: false,
      date: false,
      tag: false,
      checkedStock: false,
      checkedDiscounted: false,
      rangeLow: "",
      rangeHigh: "",
      value: 10,
      token: "",
      data: [],
      checkedBrand: null,
      checkedCategory: false,
      checkedTag: false,
      GetAllBrand: [],
      GetAllTags:[],
      GetAllContentTypes:[],
      BrandList:[],
      selectedItems: [],
      selectedTags:[],
      selectedContentType:[],
      selectedCategory:[],
      scrollEnabled:false,
      minValue:10,
      maxValue:200,
      priceMin:0,
      priceMax:0,
      price:[],
      arrayHolder:[],
      categoryArray:[],
      catHolder:[],
      GetAllFilterDate:GetDateFilterOptions,
      GetAllContentProvider:[],
      page:1,
      per_page:20,
      language:'en',
      selectedDate:"",
      dateIndex:'',
      showDatePicker:false,
      toDate:moment().format('DD/MM/YYYY'),
      isFilterApply:false,
      isClearAll:false,
      isLoading:false,
      filterString:'',
      categories:true,
      subcategories:false,
      getAllCategories:[],
      getAllSubCategories:[],
      showSubCategories:false,
      selectedCategoriesData:[],
      CategoriesSelected:false,
      showSbucategoryData:[],
      MasterCategory:[],
      filterScreenName:'',
      GetSubCategorybyCategorywithCount:[],
      isSelectCategory:false,
      setScreenName:'',
      customFromDate:'',
      customToDate:'',
      isCustomDateError: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleDate=(event:any, date:any)=>{
    this.setState({selectedDate:date, showDatePicker:false})
  }
  handlecustomeFromDate = ( date: any) => {
    this.setState({ customFromDate: `${moment(date, "YYYY/MM/DD").format("YYYY/MM/DD")}` })
  }
  handlecustomeToDate = ( date: any) => {
    this.setState({ customToDate: `${moment(date, "YYYY/MM/DD").format("YYYY/MM/DD")}` })
  }
  valueChange(value: any) {
    this.setState({ value: value });
  }
  // showSubcategories=()=>{
  //   console.log(`yes calling............................`, this.state.selectedCategoriesData)
  //   this.state.selectedCategoriesData.map((item:any)=>{
  //     if(item.checked){
  //       this.setState({showSubCategories:true})
  //     }
  //     else{
  //       this.setState({showSubCategories:false})
  //     }
  //   })
  // }
  openFilter(value: any) {
    if (value === "stock") {
      this.setState({
        tag: false,
        outOfStock: true,
        contentType: false,
        contentProvider: false,
        date: false
      });
    }
    if (value === "contentType") {
      this.setState({
        tag: false,
        outOfStock: false,
        contentType: true,
        contentProvider: false,
        date: false,
        categories:false,
        subcategories:false,
        
      }
      ,()=>this.getContentTypeTagsContentProviderWithCount('content_type')
      );
    }
    if (value === "contentProvider") {
      this.setState({
        tag: false,
        outOfStock: false,
        contentType: false,
        contentProvider: true,
        date: false,
        categories:false,
        subcategories:false
      },()=>this.getContentTypeTagsContentProviderWithCount('content_provider'));
    }
    if (value === "date") {
      this.setState({
        tag: false,
        outOfStock: false,
        contentType: false,
        contentProvider: false,
        date: true,
        categories:false,
        subcategories:false
      });
    }
    if (value === "tags") {
      this.setState({
        tag: true,
        outOfStock: false,
        contentType: false,
        contentProvider: false,
        date: false,
        categories:false,
        subcategories:false
      },()=>this.getContentTypeTagsContentProviderWithCount('tag'));
    }

   if(value==="categories"){
     this.setState({
      tag: false,
      outOfStock: false,
      contentType: false,
      contentProvider: false,
      date: false,
      subcategories:false,
      categories:true,
      CategoriesSelected:false,
      //showSbucategoryData:[]
     },()=>this.getContentTypeTagsContentProviderWithCount('category'))
   } 
   if(value==="subcategories"){
    this.setState({
      tag: false,
      outOfStock: false,
      contentType: false,
      contentProvider: false,
      date: false,
      categories:false,
      subcategories:true
     },()=>this.getContentTypeTagsContentProviderWithCount('sub_category'))
   }
  }



// clearAllContentType=()=>{
//   let newarray:any=[];
//   this.state.selectedContentType.map((item:any) => {
//     let data = {
//       id: item.id,
//       checked: false,
//       name: item.name
//     }
//     newarray.push(data);     
// })
// if(newarray){
// this.setState({selectedContentType:[newarray]})
// }
// }

// clearAllContentProvider=()=>{
//   let newarray:any=[];
//   this.state.selectedItems.map((item:any) => {
//     let data = {
//       id: item.id,
//       checked: false,
//       name: item.name
//     }
//     newarray.push(data);     
// })
// if(newarray){
// this.setState({selectedItems:[newarray]})
// }
// }
//   clearAllTags=()=>{
//     let newarray:any=[];
//     this.state.selectedTags.map((item:any) => {
//       let data = {
//         id: item.id,
//         checked: false,
//         name: item.name
//       }
//       newarray.push(data);     
// })
// if(newarray){
//   this.setState({selectedTags:[newarray]})
// }
//   }
  clearAllFilterData=()=>{
   // alert('yes')
  //this.setContentType()
  //this.setContentProvider()
  //this.setTags()
  this.getAllFilterListAccordingToScreenName()
  this.clearCategories()
  this.clearSubCategories()
  this.clearContentType()
  this.clearContentProvider()
  this.clearTags()
  setStorageData('tagList', '')
  setStorageData('contentProvider', '')
  setStorageData('selectedDate', '')
  setStorageData('indexOfSelectedDate', '')
  setStorageData('@subcategory', '')
  setStorageData('@selectedCategory', '')
  setStorageData('filterString','')
  setStorageData('setisfilterapply', JSON.stringify(false))
  setStorageData('customFromDate','')
  setStorageData('customToDate','')
  this.setState({selectedDate:'', dateIndex:'', filterString:"",
  isClearAll:true, isFilterApply:false, customFromDate:'', customToDate:'',
  showDatePicker:false, isCustomDateError:false})
  
}
  async componentDidMount() {
  this.setState({isClearAll:false})
  let token = (await getStorageData('token')) || '';
  this.setState({token:token})
  let tagList = (await getStorageData('tagList'))|| '';
  let categoriesList = (await getStorageData('@selectedCategory'))|| '';
  let subCategoriesList=(await getStorageData('setsubcategories'))||'';
  let contentType= (await getStorageData('contentType'))||'';
  let contentProvider = (await getStorageData('contentProvider'))||'';
  let selectedDate = (await getStorageData('selectedDate'))||'';
  //console.log(`SelectedDate>>>>>>>>>>>>>>>>>`, selectedDate)
  let selectedDateIndex=(await getStorageData('indexOfSelectedDate'))||'';
  let customeFromDate = (await getStorageData('customFromDate')) || '';
  let customToDate = (await getStorageData('customToDate')) || '';
  let filterApply=(await getStorageData('setisfilterapply'));
  let SelectedCategoryFromCategorySubcategory=(await getStorageData('@selectedCategory'))||'';
 console.log('SelectedCategoryFromCategorySubcategory>>>>>111',SelectedCategoryFromCategorySubcategory)
  let SelectedCategoryFromCategoryandSubCategoryScreen=(await getStorageData('@subcategory'))||'';
  console.log('SelectedCategoryFromCategoryandSubCategoryScreen222',SelectedCategoryFromCategoryandSubCategoryScreen)
  console.log(`filterApply>>>>>>>>>>>>>>>>>>`, typeof(filterApply))
 
  if(filterApply){  
    this.setState({isFilterApply:JSON.parse(filterApply)})
  }
  
  if(SelectedCategoryFromCategoryandSubCategoryScreen && JSON.parse(SelectedCategoryFromCategoryandSubCategoryScreen)?.length>0){
    this.setState({isFilterApply:true})
  }
  // if(tagList){
  //   this.setState({GetAllTags:JSON.parse(tagList)})
  // }
  if(subCategoriesList){
    this.setState({GetSubCategorybyCategorywithCount:JSON.parse(subCategoriesList)})
  }
  if(categoriesList){
    console.log(`categoriesList??calling>>>>>>>>>>>>>>`, categoriesList)
    this.setState({getAllCategories:JSON.parse(categoriesList)})

  }
   if(contentType){
    this.setState({GetAllContentTypes:JSON.parse(contentType)})
   }
  if(contentProvider){
    this.setState({GetAllContentProvider:JSON.parse(contentProvider)})
  }
  if(selectedDate){
     const Selecteddate=JSON.parse(selectedDate)
  console.log(`SelectedDate>>>>>>>>>>>>>>>>>Parse`, Selecteddate)

    this.setState({selectedDate:Selecteddate})
  }
  console.log('cdm selectedDateIndex ... ', selectedDateIndex);
  
  if(selectedDateIndex!=undefined){    
    this.setState({dateIndex:parseInt(selectedDateIndex)})
    if(selectedDateIndex == '5'){
      this.setState({showDatePicker:true})
    }
  }
  console.log('cdm selectedDateIndex ... dateIndex', this.state.dateIndex);

  if (customeFromDate){
    this.setState({customFromDate:customeFromDate});
  }
  if (customToDate){
    this.setState({customToDate:customToDate});
  }
  // this.setState({token:token})
 /* if(!tagList){
    this.getListTags()
  }
  if(!contentType){
    this.getListContentType()
  }
  if(!contentProvider){
    this.getListContentProvider()
  }
  //if(!categoriesList){
    this.getAllCategoryWithCount()*/
  this.getContentTypeTagsContentProviderWithCount('category')

     
 //}
  // if(SelectedCategoryFromCategorySubcategory){
  //   console.log(`calling....************`)
  //   let category=JSON.parse(SelectedCategoryFromCategorySubcategory)
  //   console.log(`category....************`,category)
  //   console.log(`MasterCategory....************`,this.state.getAllCategories)
  //   let localCategories = this.state.getAllCategories;
  //   let localFilterCategories:any = [];
  //   localCategories.map((item:any) => {
  //     let category_Id=category.filter((cat:any)=>cat==item.id)
  //       let   data = {
  //         id: item.id,
  //         checked: category_Id==item.id?true:false,
  //         name: item?.attributes?.name?item.attributes.name:item.name
  //        }
  //     localFilterCategories.push(data);
  //     console.log(`finalData>>>>>>>>>>>>>>>>>>>>>>>>>>>>`,localFilterCategories )
  //   this.setState({getAllCategories: localFilterCategories});
     
  //   });
  // }
  // if(!subCategoriesList){
  //   this.getAllSubCategories()
  // }
  //this.getAllCategories();
  this.getAllSubCategories()

    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("focus", () => {
        this.setState({isClearAll:false, categories:true, contentType:false,
        tag:false,date:false, contentProvider:false,subcategories:false})
        this.getToken();
      });
    }
    
    // if (!this.isPlatformWeb()) {
    //   let params = this.props.navigation.state.params ;
    //   const min = params && params.min ? params.min : 0 ;
    //   const max = params && params.max? params.max : 100 ;
    //   if(params.priceSelectedMin != undefined &&  params.priceSelectedMax != undefined){
    //     this.setState({
    //       minValue:params.priceSelectedMin,
    //       maxValue:params.priceSelectedMax
    //     })
    //   }else{
    //     this.setState({
    //       minValue:min,
    //       maxValue:max
    //     })
    //   }
    // }
  }
  async componentWillUnmount() {
    if(this._unsubscribe)
    this._unsubscribe();
}
//navigateToHomeScreen
navigateToHomeScreen=(data:any)=>{
  const navigateToHomeScreen = new FilterOptionsToActivityFeedAdapter();
      const msg: Message = new Message(getName(MessageEnum.FilterOptionsToActivityFeedMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(
        getName(MessageEnum.NavigationFilterOptionsToHomePayloadMessage),
        { data:data,
         isFilterApply:this.state.isFilterApply,
         filterString:this.state.filterString,
         isFromFilterScreen:true
        }
      );
      this.send(msg);
  }
  

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setContentType=async()=>{
 let contentTypeDataFromLocalStoreage=await(getStorageData('contentType'))||''
 console.log(`inside if ..... contentTypeFromLocalStorage`, contentTypeDataFromLocalStoreage);
 
 if(contentTypeDataFromLocalStoreage){
   console.log(`contentTypeDataFromLocalStoreage>>>>`,contentTypeDataFromLocalStoreage.length);
   
  let getSelectedContentId=JSON.parse(contentTypeDataFromLocalStoreage)
   if(getSelectedContentId && getSelectedContentId?.length!=0){
     let localContentType = this.state.GetAllContentTypes;
     let localFilterCategories:any = [];
     localContentType.map((item:any) => {
       let selected_contentType=getSelectedContentId.filter((content_id:any)=>content_id==item?.attributes?.id)
       let data = {
         id: item?.attributes?.id,
         checked: selected_contentType==item?.attributes?.id?true:false,
         count:item?.attributes?.count?item.attributes.count:item.count,
         name: item?.attributes?.name?item.attributes.name:item.name
       }
       localFilterCategories.push(data);
     });
     this.setState({GetAllContentTypes: localFilterCategories},()=>{console.log(`GetAllContentTypes`, this.state.GetAllContentTypes);
     });
   }
   else{
    console.log(`else if part contentTypeDataFromAPI >>>`);
  
    let localContentType = this.state.GetAllContentTypes;
    let localFilterContentType:any = [];
    localContentType.map((item:any) => {
      let data = {
        id: item?.attributes?.id,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        checked: false,
        name: item?.attributes?.name?item.attributes.name:item.name
      }
      localFilterContentType.push(data);
    });
    this.setState({GetAllContentTypes: localFilterContentType});
  }
}
else{
  console.log(`else if part contentTypeDataFromAPI >>>`);

  let localContentType = this.state.GetAllContentTypes;
  let localFilterContentType:any = [];
  localContentType.map((item:any) => {
    let data = {
      id: item?.attributes?.id,
      count:item?.attributes?.count?item?.attributes?.count:item.count,
      checked: false,
      name: item?.attributes?.name?item.attributes.name:item.name
    }
    localFilterContentType.push(data);
  });
  this.setState({GetAllContentTypes: localFilterContentType});
}
  }

  setContentProvider=async()=>{
    let getContentProvider=(await getStorageData('contentProvider'))||'';
    if(getContentProvider){
      let getSelectedContentProvider=JSON.parse(getContentProvider)
       if(getSelectedContentProvider && getSelectedContentProvider?.length!=0){
         let localContentType = this.state.GetAllContentProvider;
         let localContentProvider:any = [];
         localContentType.map((item:any) => {
           let selected_contentType=getSelectedContentProvider.filter((content_id:any)=>content_id==item?.attributes?.id)
           let data = {
             id: item?.attributes?.id,
             checked: selected_contentType==item?.attributes?.id?true:false,
             count:item?.attributes?.count?item.attributes.count:item.count,
             name: item?.attributes?.partner_name?item.attributes.partner_name:item.name
           }
           localContentProvider.push(data);
         });
         this.setState({GetAllContentProvider: localContentProvider});
       }
       else{
        console.log(`ElsePart****************************`);
  
        let localContentProvider = this.state.GetAllContentProvider;
        let localFilterContentProvider:any = [];
        localContentProvider.map((item:any) => {
          let data = {
            id: item?.attributes?.id,
            count:item?.attributes?.count?item?.attributes?.count:item.count,
            checked: false,
            name: item?.attributes?.partner_name? item.attributes.partner_name:item.name
          }
          localFilterContentProvider.push(data);
        });
        this.setState({ 
          GetAllContentProvider: localFilterContentProvider});
     }
    }
    else{
      console.log(`ElsePart****************************`);
      let localContentProvider = this.state.GetAllContentProvider;
      let localFilterContentProvider:any = [];
      localContentProvider.map((item:any) => {
        let data = {
          id: item?.attributes?.id,
          count:item?.attributes?.count?item?.attributes?.count:item.count,
          checked: false,
          name: item?.attributes?.partner_name? item.attributes.partner_name:item.name
        }
        localFilterContentProvider.push(data);
      });
      this.setState({ 
        GetAllContentProvider: localFilterContentProvider});
   }
  }

  setTags=async()=>{
    
    let TagListData=(await getStorageData('tagList'))||'';
    console.log(`TagListData>>>>>>>>>`, TagListData);
    if(TagListData){
      let getSelectedContentProvider=JSON.parse(TagListData)
       if(getSelectedContentProvider && getSelectedContentProvider?.length!=0){
         let localContentType = this.state.GetAllTags;
         let localContentProvider:any = [];
         localContentType.map((item:any) => {
           let selected_contentType=getSelectedContentProvider.filter((content_id:any)=>content_id==item?.attributes?.name)
           let data = {
             id: item?.attributes?.id,
             checked: selected_contentType==item?.attributes?.name?true:false,
             count:item?.attributes?.count?item.attributes.count:item.count,
             name: item?.attributes?.name?item.attributes.name:item.name
           }
           localContentProvider.push(data);
         });
         this.setState({GetAllTags: localContentProvider});
       }
       else{
        console.log(`Tag Else part>>>>>>>>>>>>>>>>>`);
    
        let localTags = this.state.GetAllTags;
        let localFilterTags:any = [];
        localTags.map((item:any) => {
          let data = {
            id: item?.attributes?.id,
            count:item?.attributes?.count?item?.attributes?.count:item.count,
            checked: false,
            name: item?.attributes?.name?item.attributes.name:item.name
          }
          localFilterTags.push(data);
        });
        this.setState({GetAllTags: localFilterTags});
      }
    }
  else{
    console.log(`Tag Else part>>>>>>>>>>>>>>>>>`);

    let localTags = this.state.GetAllTags;
    let localFilterTags:any = [];
    localTags.map((item:any) => {
      let data = {
        id: item?.attributes?.id,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        checked: false,
        name: item?.attributes?.name?item.attributes.name:item.name
      }
      localFilterTags.push(data);
    });
    this.setState({GetAllTags: localFilterTags});
  }
  }
  clearCategories=()=>{
    
    let localcategories = this.state.getAllCategories;
    console.log(`clearCategories>>>>>>>`, localcategories);
    
    let localFiltercategories:any = [];
    localcategories.map((item:any) => {
      let data = {
        id: item?.attributes?.id?item?.attributes?.id:item?.id,
        checked: false,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        name: item?.attributes?.name?item.attributes.name:item.name
      }
      localFiltercategories.push(data);
    });
    this.setState({getAllCategories: localFiltercategories,
      showSbucategoryData:[], selectedCategoriesData:[]});
  }
  clearContentType=()=>{
    let localContentType = this.state.GetAllContentTypes;
    console.log(`clearContentType>>>>>>>>>>>`, localContentType);
    
    let localFilterContentType:any = [];
    localContentType.map((item:any) => {
      let data = {
        id: item?.attributes?.id?item?.attributes?.id:item?.id,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        checked: false,
        name: item?.attributes?.name?item.attributes.name:item.name
      }
      localFilterContentType.push(data);
    });
    this.setState({GetAllContentTypes: localFilterContentType},()=>{
    setStorageData('contentType', '')
    });
  }
  clearContentProvider=()=>{
    
    let localContentProvider = this.state.GetAllContentProvider;
    console.log(`clearContentProvider>>>>`, localContentProvider);

    let localFilterContentProvider:any = [];
    localContentProvider.map((item:any) => {
      let data = {
        id: item?.attributes?.id?item?.attributes?.id:item?.id,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        checked: false,
        name: item?.attributes?.partner_name? item.attributes.partner_name:item.name
      }
      localFilterContentProvider.push(data);
    });
    this.setState({ 
      GetAllContentProvider: localFilterContentProvider});
  }
  clearTags=()=>{
    let localTags = this.state.GetAllTags;
    console.log(`clear Tags>>>>>>>>>>>>>>`, localTags);
    
    let localFilterTags:any = [];
    localTags.map((item:any) => {
      let data = {
        id: item?.attributes?.id?item?.attributes?.id:item?.id,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        checked: false,
        name: item?.attributes?.name?item.attributes.name:item.name
      }
      localFilterTags.push(data);
    });
    this.setState({GetAllTags: localFilterTags});
  }
  clearSubCategories=()=>{
    let localsubcategories = this.state.GetSubCategorybyCategorywithCount;
    console.log(`clearSubCategories>>>>>>>>>>`, localsubcategories);
    
    let localFiltersubcategories:any = [];
    localsubcategories.map((item:any) => {
      let data = {
        id: item?.attributes?.id?item?.attributes?.id:item?.id,
        checked: false,
        count:item?.attributes?.count?item?.attributes?.count:item.count,
        name: item?.attributes?.name?item.attributes.name:item.name
      }
      localFiltersubcategories.push(data);
    });
    this.setState({GetSubCategorybyCategorywithCount: localFiltersubcategories});
  }
  setCategories=async()=>{
   // alert('calling...')
    console.log(`setCategories is calling....`);
    
    let SelectedCategoryFromCategorySubcategory=(await getStorageData('@selectedCategory'))||'';
    if(SelectedCategoryFromCategorySubcategory){
      console.log(`If part is calling...SelectedCategoryFromCategorySubcategory************`)
      let category=JSON.parse(SelectedCategoryFromCategorySubcategory)
      if(category && category?.length!=0){
        console.log(`category....************`,category)
        let localCategories = this.state.getAllCategories;
        let localFilterCategories:any = [];
        localCategories.map((item:any) => {
          let category_Id=category.filter((cat:any)=>cat==item.id)
            let   data = {
              id: item.id,
              count:item?.attributes?.count?item.attributes.count:item.count,
              checked: category_Id==item.id?true:false,
              name: item?.attributes?.name?item.attributes.name:item.name
             }
          localFilterCategories.push(data);
          console.log(`finalData>>>>>>>>>>>>>>>>>>>>>>>>>>>>`,localFilterCategories )
        this.setState({getAllCategories: localFilterCategories},
          //()=>this.setSubcategoriesFromLocalStoreage()
          );
         
        });
      }
      else{
        console.log(`else part is calling>>>>>>>>>`);
        
           let localCategories = this.state.getAllCategories;
           let localFilterCategories:any = [];
           localCategories.map((item:any) => {
             let data = {
               id: item?.attributes?.id,
               checked: false,
               count:item?.attributes?.count?item.attributes.count:item.count,
               name: item?.attributes?.name?item.attributes.name:item.name
             }
             localFilterCategories.push(data);
           });
           this.setState({getAllCategories: localFilterCategories});
         }


      //this.setCategoriesandSubCategories()
    }
 else{
   console.log(`else part is calling>>>>>>>>>`);
   
      let localCategories = this.state.getAllCategories;
      let localFilterCategories:any = [];
      localCategories.map((item:any) => {
        let data = {
          id: item?.attributes?.id,
          checked: false,
          count:item?.attributes?.count?item.attributes.count:item.count,
          name: item?.attributes?.name?item.attributes.name:item.name
        }
        localFilterCategories.push(data);
      });
      this.setState({getAllCategories: localFilterCategories});
    }
  
  }
  setSubCategories=async()=>{
    // let getSubCategoryFromLocalStorage=(await getStorageData('setsubcategories'))||'';
    // console.log(`getSubCategoryFromLocalStorage>>>>>>>>>111111`, getSubCategoryFromLocalStorage);
    
    let getSubCategoryFromCategoryandSubcategory=(await getStorageData('@subcategory'))||'';
    console.log(`getSubCategoryFromCategoryandSubcategory>>>22222`, getSubCategoryFromCategoryandSubcategory);

     if(getSubCategoryFromCategoryandSubcategory){
      console.log(`else if part getSubCategoryFromCategoryandSubcategory >>>`, getSubCategoryFromCategoryandSubcategory);
      
      let getSubCategoryIds=JSON.parse(getSubCategoryFromCategoryandSubcategory)

       if(getSubCategoryIds && getSubCategoryIds?.length!=0){
         console.log(`IF PART...getSubCategoryFromCategoryandSubcategory??`, getSubCategoryFromCategoryandSubcategory);
         
         let localSubCategory = this.state.GetSubCategorybyCategorywithCount;
         console.log(`localSubCategory>>>>>>>>>>>>`, localSubCategory);
 
         let localFilterCategories:any = [];
         localSubCategory.map((item:any) => {
           let selected_subCatId=getSubCategoryIds.filter((subCate_id:any)=>subCate_id==item?.attributes?.id)
           let data = {
             id: item?.attributes?.id,
             checked: selected_subCatId==item?.attributes?.id?true:false,
             count:item?.attributes?.count?item.attributes.count:item.count,
             name: item?.attributes?.name?item.attributes.name:item.name
           }
           localFilterCategories.push(data);
         });
         this.setState({GetSubCategorybyCategorywithCount: localFilterCategories});
       }
       else{
        console.log(`ELSE PART...`);
        
          let localCategories = this.state.GetSubCategorybyCategorywithCount;
          let localFilterCategories:any = [];
          localCategories.map((item:any) => {
            let data = {
              id: item?.attributes?.id,
              checked: false,
              count:item?.attributes?.count?item.attributes.count:item.count,
              name: item?.attributes?.name?item.attributes.name:item.name
            }
            localFilterCategories.push(data);
          });
          this.setState({GetSubCategorybyCategorywithCount: localFilterCategories});
        }
    }
 
// if(getSubCategoryFromLocalStorage){
// let localSubCategorydataFromLocalStorage=JSON.parse(getSubCategoryFromLocalStorage)


// }
else{
console.log(`ELSE PART...`);

  let localCategories = this.state.GetSubCategorybyCategorywithCount;
  let localFilterCategories:any = [];
  localCategories.map((item:any) => {
    let data = {
      id: item?.attributes?.id,
      checked: false,
      count:item?.attributes?.count?item.attributes.count:item.count,
      name: item?.attributes?.name?item.attributes.name:item.name
    }
    localFilterCategories.push(data);
  });
  this.setState({GetSubCategorybyCategorywithCount: localFilterCategories});
}
  }
  setSubcategoriesFromLocalStoreage=async()=>{
    let SelectedCategoryFromCategorySubcategory=(await getStorageData('@selectedCategory'))||'';
    let selectedCategory=JSON.parse(SelectedCategoryFromCategorySubcategory)
    let SelectedCategoryFromCategoryandSubCategoryScreen=(await getStorageData('@subcategory'))||'';
    let SelectedSubcat=JSON.parse(SelectedCategoryFromCategoryandSubCategoryScreen)
   let localFilterCategories:any = [];
    this.state.getAllSubCategories.map((sub:any)=>{
      let categoryId=selectedCategory.filter((catId:any)=>catId==sub.id)
      if(sub.id==categoryId){
       sub?.attributes?.sub_categories.map((subCat:any)=>{
         let subCategoryId=SelectedSubcat.filter((subCatId:any)=>subCatId==subCat.id)
           let data = {
             id: subCat.id,
             checked:subCategoryId==subCat.id?true:false,
             name: subCat.name,
             categoriesId:categoryId
           }
       localFilterCategories.push(data);

         })
         console.log(`data>>>>>>>>>>>>>>>>Final`, localFilterCategories)
         this.setState({
         showSbucategoryData: localFilterCategories,
           });
      }
    })
  }
  showSubcategories=(categoryId:any, isSelected:any)=>{
console.log(`calling>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`, isSelected)
   let localFilterCategories:any = [];
     this.setState({CategoriesSelected:true})
     if(isSelected =='true'){
      this.state.getAllSubCategories.map((sub:any)=>{
        if(sub.id==categoryId){
         sub?.attributes?.sub_categories.map((subCat:any)=>{
             let data = {
               id: subCat.id,
               checked: false,
               name: subCat.name,
               categoriesId:categoryId
             }
         localFilterCategories.push(data);
 
           })
           console.log(`data>>>>>>>>>>>>>>>>Final`, localFilterCategories)
           this.setState({
           showSbucategoryData: this.state.showSbucategoryData.concat(localFilterCategories),
             });
        }
      })
     }
 else{
   console.log(`calling........else`)
   let filteredData=this.state.showSbucategoryData.filter((item:any)=>  {
     return item.categoriesId!=categoryId
   })
   console.log(`data>>>>>>>>>>>>>>>>filteredData`, filteredData)
   this.setState({showSbucategoryData:filteredData})
 }   
    
//  this.state.selectedCategoriesData.map((item:any)=>{
//    if(item.checked){
//     this.setState({CategoriesSelected:true})
//      this.state.getAllSubCategories.map((sub:any)=>{
//        if(sub.id==item.id){
//         sub?.attributes?.sub_categories.map((subCat:any)=>{
//             let data = {
//               id: subCat.id,
//               checked: false,
//               name: subCat.name,
//               categoriesId:item.id
//             }
//         localFilterCategories.push(data);

//           })
//           console.log(`data>>>>>>>>>>>>>>>>Final`, localFilterCategories)

//           this.setState({showSbucategoryData: localFilterCategories,
//             });
//        }
//      })
//    }
  
//  })
   
    
  }
//****************contentType List ApiCall here***************
getListContentType=()=>{
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.contentTypeApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.contentTypeApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

//****************contentProvider List ApiCall here***************
getListContentProvider=()=>{
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.contentProviderApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.contentProviderApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
//****************tags List ApiCall here***************
getListTags=()=>{
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.tagsApiCallid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.tagsApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

//*******************getAllCategories here************/
getAllCategories=()=>{
  console.log(`getAllCategoriesAPI Callin>>>>>>>>>>>>>>`)
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAllCategoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getCategoriesEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

//*****************getAllSubcategories********* */
getAllSubCategories=()=>{
  console.log(`getAllSubcategories>>>>>>>>>>>>>>`)
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAllSubCategoriesApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getCategoriesEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

//Get CategoryFilterDatawith count
getAllCategoryWithCount=async()=>{
  this.setState({setScreenName:'category'})

  let queryParams:any = [];
//let filterString=await getStorageData('filterString')||''
let selectedSubCategory=await getStorageData('@subcategory')||''
let selectedTagList=await getStorageData('tagList')||''
let selectedcontentProvider=await getStorageData('contentProvider')||''
let selectedcontentType=await getStorageData('contentType')||''
let selectedDate = (await getStorageData('selectedDate'))||'';
// let selectedDateIndex=(await getStorageData('indexOfSelectedDate'))||'';


console.log(`selectedCategory1111122222>>>>>>>>>`, selectedSubCategory);

if(selectedSubCategory){

JSON.parse(selectedSubCategory).map((element:any)=>{
  queryParams.push(`sub_category[]=${element}`);
})
}
if(selectedTagList){
  JSON.parse(selectedTagList).map((element:any)=>{
    queryParams.push(`tag[]=${element}`);
  })
}

if(selectedcontentProvider){
  JSON.parse(selectedcontentProvider).map((element:any)=>{
    queryParams.push(`content_provider[]=${element}`);
  })
}
if(selectedcontentType){
  JSON.parse(selectedcontentType).map((element:any)=>{
    queryParams.push(`content_type[]=${element}`);
  })
}

if(selectedDate&&selectedDate!='Invalid date'){
  queryParams.push(`date[from]=${selectedDate}`);
  queryParams.push(`date[to]=${moment().format('DD/MM/YYYY')}`);
}

let finalQueryparamsString= queryParams.join('&');
console.log(`finalQueryparamsString>>>>>>22222`, finalQueryparamsString);

//return;
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCategoryFilterWithCountCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.dynamicFilterApiEndPoint+`?current_page=category&${finalQueryparamsString}&filter_type=content`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
//get ContentType Tags and ContentProvider 
getContentTypeTagsContentProviderWithCount=async(screenName:any)=>{
  if(screenName){
    this.setState({setScreenName:screenName})
  }
  if(this.state.selectedDate){
    await setStorageData('selectedDate', JSON.stringify(this.state.selectedDate))
  }
  let SelectedCategoryFromCategorySubcategory=(await getStorageData('@selectedCategory'))||'';
  let selectedSubCategory=await getStorageData('@subcategory')||''
  let selectedTagList=await getStorageData('tagList')||''
  let selectedcontentProvider=await getStorageData('contentProvider')||''
  let selectedcontentType=await getStorageData('contentType')||''
  
  

  //alert('tags')
  this.setState({isLoading:true})
  let queryParams:any = [];
  if(screenName!='tag'){
    if(selectedTagList){
      JSON.parse(selectedTagList).map((element:any)=>{
        queryParams.push(`tag[]=${element}`);
      })
    }
    
    // if(this.state.GetAllTags){
    //   this.state.GetAllTags.map((element:any, index:any) => {
    //     if(element.checked) {
    //       queryParams.push(`tag[]=${element.name}`);
    //     }
    //   })
    // }
  }

  if(screenName!='content_type'){
    if(selectedcontentType){
      JSON.parse(selectedcontentType).map((element:any)=>{
        queryParams.push(`content_type[]=${element}`);
      })
    }
    // if(this.state.GetAllContentTypes){
    //   this.state.GetAllContentTypes.map((element:any, index:any) => {
    //     if(element.checked) {
    //       queryParams.push(`content_type[]=${element.id}`);
    //     }
    //   })
    // }
  }
if(screenName!='content_provider'){
  if(selectedcontentProvider){
    JSON.parse(selectedcontentProvider).map((element:any)=>{
      queryParams.push(`content_provider[]=${element}`);
    })
  }
  // if(this.state.GetAllContentProvider){
  //   this.state.GetAllContentProvider.map((element:any, index:any) => {
  //     if(element.checked) {
  //       queryParams.push(`content_provider[]=${element.id}`);
        
  //     }
  //   })
  // }
}
  // if(this.state.selectedDate&&this.state.selectedDate!='Invalid date'){
  //   queryParams.push(`date[from]=${this.state.selectedDate}`);
  //   queryParams.push(`date[to]=${this.state.toDate}`);
  // }
  if (this.state.dateIndex != '' || this.state.dateIndex != null) {
    if (this.state.dateIndex == 5) {
      if (this.state.customFromDate && this.state.customToDate) {
        queryParams.push(`date[from]=${this.state.customFromDate}`);
        queryParams.push(`date[to]=${this.state.customToDate}`);
      }
    } else if (this.state.selectedDate) {
        queryParams.push(`date[from]=${this.state.selectedDate}`);
        queryParams.push(`date[to]=${moment().format('DD/MM/YYYY')}`);
    }
  }

   
  if(screenName!='category'){
    if(SelectedCategoryFromCategorySubcategory){

      JSON.parse(SelectedCategoryFromCategorySubcategory).map((element:any)=>{
        queryParams.push(`category[]=${element}`);
      })
      }
    // if(this.state.getAllCategories){
    //   this.state.getAllCategories.map((element:any, index:any) => {
    //     if(element.checked) {
    //       queryParams.push(`category[]=${element.id}`);
          
    //     }
    //   })
    // }
   
  }

  if(screenName!='sub_category'){
    if(selectedSubCategory){

      JSON.parse(selectedSubCategory).map((element:any)=>{
        queryParams.push(`sub_category[]=${element}`);
      })
      }
    // if(this.state.GetSubCategorybyCategorywithCount){
    //   this.state.GetSubCategorybyCategorywithCount.map((element:any, index:any) => {
    //     if(element.checked) {
    //       queryParams.push(`sub_category[]=${element.id}`);
          
    //     }
    //   })
    // }
  }
  let finalQueryparamsString= queryParams.join('&');
console.log(`finalQueryparamsString>>>>>>>>>>>>>>>>>`,finalQueryparamsString);
//return;
  const header = {
    "Content-Type": configJSON.productApiContentType,
    token: this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getContentTypeTagsAndContentProviderFilterDatawithCount = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.dynamicFilterApiEndPoint+`?current_page=${screenName}&${finalQueryparamsString}&filter_type=content`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
//GetCategorySubCategoryTagsContentTypeAndContentProviderAfterClearAll
getAllFilterListAccordingToScreenName=async()=>{
  console.log(`calling......>>>>>>>>>>>>>>>>`);
  
  //return;
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContentTypeTagsAndContentProviderFilterDatawithCount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dynamicFilterApiEndPoint+`?current_page=${this.state.setScreenName}&filter_type=content`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

filterApicall = () => {
  if (this.state.dateIndex == 5 ) {
    if ( this.state.customFromDate && this.state.customFromDate != "Invalid date" && this.state.customToDate && this.state.customToDate != "Invalid date"){
      this.onApply();
    } else {
      this.setState({isCustomDateError:true,contentProvider: false, contentType: false,tag:false,date:true})
    }
  } else {
    this.onApply();
  }
}

//Filter APi call 
onApply = () => {
  if (this.state.dateIndex) {
    setStorageData('indexOfSelectedDate', JSON.stringify(this.state.dateIndex))
  }
    this.setState({isLoading:true})
      let queryParams:any = [];
  
  if(this.state.GetAllTags){
    this.state.GetAllTags.map((element:any, index:any) => {
      if(element.checked) {
        queryParams.push(`tag[]=${element.name}`);
      }
    })
  }

  if(this.state.GetAllContentTypes){
    this.state.GetAllContentTypes.map((element:any, index:any) => {
      if(element.checked) {
        queryParams.push(`content_type[]=${element.id}`);
      }
    })
  }

  if(this.state.GetAllContentProvider){
    this.state.GetAllContentProvider.map((element:any, index:any) => {
      if(element.checked) {
        queryParams.push(`content_provider[]=${element.id}`);
        
      }
    })
  }
  
  if (this.state.dateIndex === 5) {
    queryParams.push(`date[from]=${this.state.customFromDate}`);
    queryParams.push(`date[to]=${ this.state.customToDate}`);
    setStorageData('customFromDate', this.state.customFromDate);
    setStorageData('customToDate', this.state.customToDate);
    setStorageData('selectedDate', '');
  } else {
    if (this.state.selectedDate && this.state.toDate && this.state.selectedDate !== 'Invalid date') {
      queryParams.push(`date[from]=${this.state.selectedDate}`);
      queryParams.push(`date[to]=${this.state.dateIndex === 1 ? this.state.selectedDate : this.state.toDate}`);
      setStorageData('selectedDate', this.state.selectedDate)
    }
    setStorageData('customFromDate', '');
    setStorageData('customToDate', '');
  }
  if(this.state.getAllCategories){
    this.state.getAllCategories.map((element:any, index:any) => {
      if(element.checked) {
        queryParams.push(`category[]=${element.id}`);
        
      }
    })
  }
  if(this.state.GetSubCategorybyCategorywithCount){
    this.state.GetSubCategorybyCategorywithCount.map((element:any, index:any) => {
      if(element.checked) {
        queryParams.push(`sub_category[]=${element.id}`);
        
      }
    })
  }

  let finalQueryparamsString= queryParams.join('&');
  this.setState({filterString:finalQueryparamsString})
  console.log(`filterApicall>>>finalString>>>>>>>>>>>>>>>>>>`, finalQueryparamsString)
  //return false;

  const header = {
    "Content-Type": configJSON.validationApiContentType,
     token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.GetAllcontentsId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getContentEndPoint+`?page=${this.state.page}
    &per_page=${this.state.per_page}&${finalQueryparamsString}&language=${this.state.language}&with_cta=true
    `
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
//**************receive******************
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(errorReponse){
        this.setState({isLoading:false})

      }
        if(apiRequestCallId&&responseJson){
          // this.setState({isLoading:false})
          // if(apiRequestCallId==this.contentTypeApiCallId){
            
          //   this.setState({GetAllContentTypes:responseJson.data}, ()=>this.setContentType())
          // }
          // if(apiRequestCallId==this.contentProviderApiCallId){
          //   this.setState({GetAllContentProvider:responseJson.data}, ()=>this.setContentProvider())
          // }
          // if(apiRequestCallId==this.tagsApiCallid){
          //   this.setState({GetAllTags:responseJson.data}, ()=>this.setTags())
            
          // }
          // if(apiRequestCallId==this.getAllCategoriesApiCallId){
          //   console.log(`getAllCategoriesApiCallId>>>>>>>>`, responseJson.data)
          //   if(responseJson?.data?.length>0){
          //     this.setState({getAllCategories:responseJson.data,
          //       },()=>{this.setCategories()})
          //   }
          // }
          // if(apiRequestCallId==this.getAllSubCategoriesApiCallId){
          //   console.log(`getAllSubCategoriesApiCallId>>>>>>>>`, responseJson.data)
          //   if(responseJson?.data?.length>0){
          //     this.setState({getAllSubCategories:responseJson.data,
          //       MasterCategory:responseJson.data
              
          //     })
          //   }
          // }
          if(apiRequestCallId==this.getContentTypeTagsAndContentProviderFilterDatawithCount){
            console.log('>>>>>>>> responseJson.data?.content_types?.data?.length > 0', responseJson.data);
            
              if(responseJson.data?.content_types){
                if(responseJson.data?.content_types?.data?.length > 0){
                  console.log('inside if ..... ');
                  this.setState({GetAllContentTypes:responseJson.data?.content_types?.data},
                    ()=>this.setContentType())
                  }
                else{
                  console.log('inside if ..... elsee');
                  this.setState({GetAllContentTypes:[]},
                    // ()=>this.setContentType()
                    )
                  }
              }
            
              if(responseJson.data?.content_providers){
                if(responseJson.data?.content_providers?.data?.length > 0){
                  this.setState({GetAllContentProvider:responseJson.data?.content_providers?.data}, ()=>this.setContentProvider())
              }else{
                this.setState({GetAllContentProvider:[]}
                  // ,()=>this.setContentProvider()
                  )
              }
              }

               if(responseJson.data?.tags){
                 if(responseJson.data?.tags?.data?.length > 0){
                   this.setState({GetAllTags:responseJson.data?.tags?.data},()=>this.setTags())
                  }else{
                    this.setState({GetAllTags:[]}
                      // ,()=>this.setTags()
                      )
                  }
                }

               if(responseJson.data?.sub_categories){
                 if(responseJson.data?.sub_categories?.data?.length > 0){
                   this.setState({GetSubCategorybyCategorywithCount:responseJson.data?.sub_categories?.data},()=>this.setSubCategories())
                  }else{
                    this.setState({GetSubCategorybyCategorywithCount:[]}
                      // ,()=>this.setSubCategories()
                      )
                  }
                }

               if(responseJson.data?.categories){
                 if(responseJson.data?.categories?.data?.length > 0){
                   this.setState({getAllCategories:responseJson.data?.categories?.data,
                   }, ()=>{this.setCategories()})
                  }else{
                    this.setState({getAllCategories:[]}
                      // , ()=>{this.setCategories()}
                      )
                  }
                }
                this.setState({isLoading:false})
          }
          if(apiRequestCallId==this.getCategoryFilterWithCountCallId){
            console.log('responseJson.data?.categories?.data111' ,responseJson);
            
            if(responseJson.data){
              this.setState({getAllCategories:responseJson.data?.categories?.data?responseJson.data?.categories?.data:[],
              }, ()=>{this.setCategories()})
            }
        }
          if(apiRequestCallId==this.GetAllcontentsId){
            if(responseJson.data){
              
              if(this.state.filterString!=''){
                this.setState({isFilterApply: true})
              }
               let contentType:any = [];
               if(this.state.GetAllContentTypes){
                this.state.GetAllContentTypes.forEach((element:any, index:any) => {
                  if(element.checked) {
                    contentType.push(element.id);
                  }
                })
                console.log('inside if ..... on apply filter contetnt type', contentType);
                
                setStorageData('contentType', JSON.stringify(contentType?contentType:''))                
              }
              let contentProvider:any = [];
              if(this.state.GetAllContentProvider){
                this.state.GetAllContentProvider.map((element:any, index:any) => {
                  if(element.checked) {
                    contentProvider.push(element.id);
                    
                  }
                })
                setStorageData('contentProvider', JSON.stringify(contentProvider?contentProvider:''))
              }
              let tagList:any = [];
              if(this.state.GetAllTags){
                this.state.GetAllTags.map((element:any, index:any) => {
                  if(element.checked) {
                    tagList.push(element.name);
                  }
                })
                setStorageData('tagList', JSON.stringify(tagList?tagList:''))

              }
              let setSubcategory:any = [];

              if(this.state.GetSubCategorybyCategorywithCount){
                this.state.GetSubCategorybyCategorywithCount.map((element:any, index:any) => {
                  if(element.checked) {
                    setSubcategory.push(element.id);
                    
                  }
                })
                setStorageData('@subcategory', JSON.stringify(setSubcategory?setSubcategory:''))

              }
              let selectedCategory:any=[];
              if(this.state.getAllCategories){
                this.state.getAllCategories.map((element:any, index:any) => {
                  if(element.checked) {
                    selectedCategory.push(element.id);
                    
                  }
                })
                setStorageData('@selectedCategory', JSON.stringify(selectedCategory?selectedCategory:''))

              }
                setStorageData('selectedCategory', '')
                setStorageData('filterString', JSON.stringify(this.state.filterString))

               // setStorageData('@subcategory', '')
                setStorageData('setisfilterapply', JSON.stringify(this.state.isFilterApply))
                setStorageData('setcategories', JSON.stringify(this.state.getAllCategories))
              //  setStorageData('setsubcategories', JSON.stringify(this.state.GetSubCategorybyCategorywithCount?this.state.GetSubCategorybyCategorywithCount:""))
                setStorageData('setselectedCategoriesData', JSON.stringify(this.state.selectedCategoriesData))
                if(this.state.selectedDate){
                  setStorageData('selectedDate', JSON.stringify(this.state.selectedDate))
                }
                if(this.state.dateIndex!=undefined){
                  console.log('indexOfSelectedDate ', this.state.dateIndex);
                  
                  setStorageData('indexOfSelectedDate', JSON.stringify(this.state.dateIndex))
                }

                this.navigateToHomeScreen(responseJson.data)


            }
             
           
          }
        }

        
    }

    // Customizable Area End
  }
}
