
//@ts-nocheck
//@ts-ignore
import React from 'react';
import { View, Text, FlatList, TouchableOpacity, Linking, Alert } from 'react-native';
import Button from '../Button/Button';
import CardImage from '../CardImages/CardImage';
import Card from '../cards/Cards';
import CardTitle from '../cards/CardTitle';
import DownloadBrochureTitle from '../cards/DownloadBrowchureTitle';
import LocationOnCard from '../cards/LocationOnCard';
import { bg_white, blue, Bold, orange_title_text, size_14pt } from '../../Utils';
import ThemeColorContext from "../../../../../components/src/themeColorManagement/themeColorContext";
import DownloadingController from '../Helper/DownloadingController';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import { translate } from "../../../../../components/src/i18n/translate";


interface Props {
    title: any;
    featuredSchools?: any;
    onPress: () => void;
}

export default class Featured extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    FeaturedDataView(themeColorContext) {
        return (
            <View style={{ marginTop: 17 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text style={{ color: themeColorContext.themeColor === 'dark' ? orange_title_text : blue, fontSize: size_14pt, fontFamily: Bold }}>{this.props.title}</Text>
                    {
                        this.props?.featuredSchools.length > 3 ?
                            <TouchableOpacity
                                testID='FeaturedBtn'
                                style={{ marginRight: 5 }}
                                onPress={() => this.props.onPress()} >
                                <Text style={{ color: themeColorContext.themeColor === 'dark' ? 'rgb(255,255,255)' : 'rgb(105,105,105)', borderColor: themeColorContext.themeColor === 'dark' ? 'rgb(255,255,255)' : 'rgb(105,105,105)', borderBottomWidth: 1, fontSize: 12, fontFamily: Bold, marginStart: 15 }}>{translate("SeeAll")}</Text>
                            </TouchableOpacity>
                            :
                            null
                    }

                </View>

                <FlatList
                    data={this.props?.featuredSchools.slice(0, 3)}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    ListEmptyComponent={() => {
                        return <View style={{ justifyContent: 'center', width: widthPercentageToDP('100%'), padding: heightPercentageToDP('2%') }}>
                            <Text style={{ color: themeColorContext.themeColor === 'dark' ? bg_white : '#000', alignSelf: 'center' }}>{translate('noResultFound')}</Text>
                        </View>
                    }}
                    renderItem={({ item }: { item: any }) => {
                        return <Card borderEnabled
                            onPress={() => null}
                            //  onPress={()=>{ item?.attributes?.website_url?
                            //     Linking.openURL(item?.attributes?.website_url) .then(foo => {

                            //     })
                            //     .catch(error => {
                            //         Alert.alert('URl not found')
                            //     })
                            //     : Alert.alert('URl not found')}}
                            activeTheme={themeColorContext.themeColor}>

                            <CardImage types={'Features'} rating={item?.attributes?.course_rating} logo={item?.attributes?.logo} />

                            <CardTitle
                                name={item?.attributes?.name}
                                activeTheme={themeColorContext.themeColor} />

                            <LocationOnCard
                                locationName={item?.attributes?.city?.data?.attributes?.name}
                                rating={item?.attributes?.course_rating}
                            />

                            <Button onPress={() => {
                                item?.attributes?.website_url ?
                                    Linking.openURL(item?.attributes?.website_url).then(foo => {
                                        console.log('yesss ', foo)
                                    })
                                        .catch(error => {
                                            Alert.alert(translate("URlnotfound"))
                                        })
                                    : Alert.alert(translate("URlnotfound")
                                    );
                            }}
                                types={'Features'}
                            />

                            <DownloadBrochureTitle onPress={() => {
                                if (item?.attributes?.brochure) {
                                    const dc = new DownloadingController
                                    dc._checkPermission(item.attributes.brochure)
                                }
                                else {
                                    Alert.alert(translate('DataAvailable'))
                                }
                            }} />

                        </Card>
                    }}
                />
            </View>
        )
    }

    render() {
        return (
            <ThemeColorContext.Consumer>
                {(themeColorContext: any) => (
                    <>
                        {this.FeaturedDataView(themeColorContext)}
                    </>
                )}
            </ThemeColorContext.Consumer>
        );
    }
}