//@ts-nocheck
//@ts-ignore
import React, { Component } from "react";
import { View, Image } from "react-native";
import { scale } from "../../framework/src/Helpers";

export class CustomLoader extends Component {
  render() {
    return (
      <View
        style={{
          zIndex: 9999999,
          height: "100%",
          width: "100%",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          alignSelf: "center",
        }}
      >
        <Image
          source={require("./customLoader.gif")}
          style={{ height: scale(35), width: scale(35) }}
        />
      </View>
    );
  }
}

export default CustomLoader;
