//@ts-nocheck
import React from "react";
import {
    withStyles,
    Grid,
    createStyles,
    Container,
    Typography,
    InputBase,
    Hidden,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { courseImg, dummyImage } from "./assets";
import insane from 'insane';
import Pagination from '@material-ui/lab/Pagination';
import { Helmet } from "react-helmet";
import SearchIcon from "@material-ui/icons/Search";
import CarrerExpertArticleViewAllController from "./CarrerExpertArticleViewAllController";
const Title = ({ caption, classes }) => {
    return (
        <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
            <Hidden smDown> <Typography align="center" variant="h4" className={classes.titleColor}>{caption}</Typography></Hidden>
            <Hidden mdUp> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
        </Grid>);
};

const RenderBlogAndArticals = ({ type, classes, ViewAllItems, history }) => {
    return (
        <>
            <Grid container direction="column" alignItems="center" justify="flex-start" className={classes.RenderBlogAndArticals} >
                <>
                    {ViewAllItems && ViewAllItems.length > 0 ? ViewAllItems.map((e: any) => (
                        <>
                            <Grid container direction="row" xs={12} sm={12} md={10} lg={10} xl={10} alignItems="flex-start" spacing={2} className={classes.RenderBlogAndArticalsContent}>

                                <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
                                    {e.attributes.image || e?.attributes?.images && e?.attributes?.images?.length > 0 ?
                                        <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image || e?.attributes?.images[0]?.image?.url} />
                                        :
                                        <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
                                    }
                                </Grid>
                                <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                                    <Grid container direction="column" spacing={2} >
                                        <Grid item>
                                            <Typography variant="h3">{e.attributes.name || e.attributes.headline}</Typography>
                                        </Grid>
                                        <Grid item>
                                            {(e?.attributes?.content || e.attributes.description.length) > 300 ?
                                                <div className="mt-1.5">
                                                    <Typography variant="subtitle2" color="primary" >
                                                        <span className={classes.description} dangerouslySetInnerHTML={{
                                                            __html: insane(`${e?.attributes?.content || e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/articaldetails/${type}/${e.id}'>[Read more]</a></button>`)
                                                        }}></span>
                                                    </Typography></div>
                                                : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" className={classes.description}>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: insane(`${e?.attributes?.content || e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/articaldetails/${type}/${e.id}'>[Read more]</a></button>`)
                                                    }}></span>
                                                </Typography></div>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {e?.attributes?.author ?
                                                <Typography className={classes.authname} variant="subtitle2"
                                                    onClick={() => history.push('/home/blogprofile/' + e?.attributes?.author_id + '/' + e.id)}>
                                                    By - {e?.attributes?.author}</Typography> :
                                                <Typography className={classes.authname} variant="subtitle2"></Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )) :
                        <Grid item>
                            <Typography variant="body2">Will Update You Soon</Typography>
                        </Grid>
                    }
                </>
            </Grid>
            <div className={classes.spacer} />
        </>
    )
};


//@ts-ignore
export class CarrerExpertViewAllPageWeb extends CarrerExpertArticleViewAllController {
    async componentDidMount() {
        window.scrollTo(0, 0, { behavior: 'smooth' });
        this.loadData()
    }

    onPageChange = (e, page) => {
        this.setState({ currentPage: page }, () => {
            this.loadData();
        });
    };
    setSearchdata = (e: any) => {
        this.setState({ searchinfo: e.target?.value }, () => {
            this.loadData(this.state.searchinfo);
        })
    }
    render() {
        //@ts-ignore
        const self = this
        const { classes } = this.props;
        const { onPageChange, setSearchdata } = self
        const { currentPage = 1, totalPage = 1, featureArticle, expertFeatured, popularBlogs, expertPopular } = this.state;
        let ViewAllItems = [];
        let { pathname } = this.props.location;
        if (pathname?.includes('articlesviewall')) {
            ViewAllItems = featureArticle
        } else if (pathname?.includes('expertArticlesviewall')) {
            ViewAllItems = expertFeatured?.attributes?.content_articles?.data
        }
        else if (pathname?.includes('blogsviewall')) {
            ViewAllItems = popularBlogs
        }
        else if (pathname?.includes('expertBlogsviewall')) {
            ViewAllItems = expertFeatured?.attributes?.content_blogs?.data
        }
        else if (pathname?.includes('expertpopularBlogsviewall')) {
            ViewAllItems = expertPopular?.attributes?.content_blogs
        }
        return (
            <>
                <Helmet
                    title="Career expert"
                    titleTemplate="%s - DailyHuntWIP"
                    defaultTitle="DailyHuntWIP"
                />
                <Grid
                    item
                    className={classes.imgBg}
                    xs={12}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.search}>
                        <Grid item xs={1}>
                            <SearchIcon color="secondary" />
                        </Grid>
                        <Grid item xs={11}>
                            <InputBase
                                placeholder="Search Here Experts, Articles, Exam, Courses"
                                data-testId="setSearchdata"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={setSearchdata}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Container className={classes.container}>
                    <Title {...{ caption: this.props?.location?.state?.caption, classes }} />
                    <RenderBlogAndArticals data-testId="RenderBlogAndArticals" {...{ classes, ViewAllItems, type: 'articles', history: this.props.history }} />
                    {totalPage > 1 && <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
                        <Pagination page={currentPage} data-testId="onPageChange" onChange={onPageChange} count={totalPage} variant="outlined" shape="rounded" />
                    </Grid>}
                </Container>
            </>
        );
    }
}
//@ts-ignore
const CarrerExpertViewAllPageWebWithRouter = withRouter(CarrerExpertViewAllPageWeb);
const CarrerExpertViewAllPageWebWithLoader = withLoader(CarrerExpertViewAllPageWebWithRouter);
const CarrerExpertViewAllPageWebToast = withSnackbar(CarrerExpertViewAllPageWebWithLoader);
const CarrerExpertViewAllPageWebWithStyle = withStyles((theme) =>
    createStyles({
        container: {
            marginTop: '3rem'
        },
        search: {
            width: "44rem",
            height: "3.5rem",
            borderRadius: "1.5rem",
            webkitBackdropFilter: "blur(50px)",
            backdropFilter: "blur(50px)",
            backgroundColor: "rgba(207, 207, 207, 0.13)",
            marginTop: "0rem",
            paddingLeft: "1rem",
            [theme.breakpoints.down("md")]: {
                width: '22rem'
            }

        },
        inputRoot: {
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                width: 'max-content'
            }
        },
        imgBg: {
            backgroundImage: `url(${courseImg})`,
            width: "100%",
            height: "505px",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexFlow: "column wrap",
            alignItems: "center",
            backgroundSize: "cover",
            justifyContent: "center",
            backgroundPosition: "center",
            marginTop: "-0.25rem"
        },
        videoCont: {
            // marginTop: '-66px',
            // padding: '0px',
            maxWidth: '100%'
        },
        tabs: {
            padding: '5rem 10rem',
        },
        category: {
            width: '100%',
            minHeight: '12rem',
        },
        categoryItem: {
            width: '260px',
            // width: '32%',
            marginTop: '1rem',
            border: '1px solid lightgray',
            cursor: 'pointer',
            padding: '0.6rem',
        },
        categoryItemActive: {
            // width: '32%',
            width: '260px',
            marginTop: '1rem',
            border: '1px solid lightgray',
            borderTop: '2px solid',
            cursor: 'pointer',
            padding: '0.6rem',
        },
        categoryItemDiv: {
            width: '2%',
        },
        viewAll: {
            border: '2px solid #f48b1b !important',
            backgroundColor: '#ffffff',
            borderRadius: '20px !important',
            width: '7rem',
            height: '2rem',
            marginRight: '15px',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important'
            },
            [theme.breakpoints.down("xs")]: {
                border: '1px solid #f48b1b !important',
                backgroundColor: '#ffffff',
                height: '1rem',
                padding: '.7rem !important',
                width: '5rem',
                '&:hover': {
                    backgroundColor: '#f48b1b !important',
                    color: '#ffffff !important'
                }
            }
        },
        paddingExamArVi: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
            paddingBottom: '2rem',
            [theme.breakpoints.down("xs")]: {
                paddingLeft: '0rem',
                paddingRight: '0rem',

            },
        },
        btn: {
            // width: 105,
            // height: 35,
            marginTop: '20px',
            // margin: '17px 84px 0 85px',
            padding: '6px 9px 6px 10px',
            // borderRadius: 8,
            backgroundColor: '#ff9322',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important'
            },
            [theme.breakpoints.down("md")]: {
                margin: '7px 0px 0 55px',
                width: 80,
                height: 25,
            },
        },
        btnTxt: {
            letterSpacing: 'normal',
            color: '#fff',
        },
        selectedTab: {
            borderRadius: 4,
            backgroundColor: '#fff',
            borderLeft: '1px solid #d0caca',
            borderRight: '1px solid #d0caca',
        },
        tabRoot: {
            border: '1px solid #d0caca',
            borderRadius: 4,
            backgroundColor: '#f2f2f2',
        },
        indicator: {
            background: 'transparent',
        },
        proContainer: {
            width: 220,
            height: 294,
            border: '1px solid',
            margin: '1rem',
        },
        profilePic: {
            width: '12rem',
            height: '12rem'
        },
        proBox: {
            margin: '1rem'
        },
        fvPlayer: {
            height: '16rem',
            display: 'block',
            width: '18rem',
            opacity: '0.51',
            borderRadius: '10px',
            objectFit: 'fill',
            backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
            backgroundAttachment: 'fixed',
        },
        overlay: {
            position: 'absolute',
            top: '6rem',
            bottom: '0',
            left: '8rem',
            right: '0',
            height: '100%',
            width: '100%',
        },
        tabinfo: {
            textTransform: 'none',
            /*fontSize: '20px'*/
        },
        belowline: {
            paddingTop: '1rem',
        },
        belowlinetext: {
            borderBottom: '1px solid',
            width: '20%',
            margin: 'auto',
        },
        expertname: {
            color: '#f48b1b',
            marginTop: '1rem'
        },
        authname: {
            color: '#f48b1b',
            cursor: 'pointer'
        },
        nameinfo: {
            width: '18rem',
        },
        headname: {
            color: 'black',
            marginTop: '2rem'
        },
        RenderVideos: {
            marginBottom: '2rem'
        },
        RenderBlogAndArticals: {
            marginBottom: '3rem'
        },
        RenderBlogAndArticalsContent: {
            marginBottom: '2rem'
        },
        subText: {
            marginTop: '2.5rem'
        },
        description: {
            display: 'inline'
        },
        title: {
            marginBottom: '1.5rem'
        },
        tabsWrapper: {
            justifyContent: 'space-between'
        },
        cardSpace: {
            marginRight: '1rem'
        },
        tabsDescription: {
            whiteSpace: 'normal'
        },
        selectsubCatButtons: {
            // '&:hover': {
            backgroundColor: '#f48b1b !important',
            color: '#ffffff !important'
            // }

        },
        unselectsubCatButtons: {
            border: '1px solid #d0caca',
            borderRadius: 4,
            backgroundColor: 'blue',
        },
        subBtn: {
            marginTop: '20px',
            border: '1px solid #f48b1b',
            backgroundColor: '#ffffff !important',
            color: '#f48b1b !important',
            '&:hover': {
                backgroundColor: '#ffffff !important',
                color: '#f48b1b !important',
            }
        },
        subBtnTxt: {
            color: 'f48b1b'
        },
        activeSubBtn: {
            marginTop: '20px',
            backgroundColor: '#f48b1b !important',
            color: '#ffffff !important',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important',
            }

        },
        catData: {
            marginTop: '3rem'
        },
        // vertical: {
        //   position: 'fixed',
        //   // textTransform:'rotate(90deg)',
        //   top: '50%',
        //   right: '0px',
        // },
        verticalBtn: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',

            margin: '0px',
            cursor: 'pointer',
            lineHeight: 1.75,
            border: '1px solid rgba(255, 198, 117, 0.5)',
            padding: '0.6rem 1.5rem',
            fontSize: '15px',
            // minWidth: '84px',
            top: '40%',
            right: '-40px',
            // height: '70px',
            position: 'fixed',
            transform: 'rotate(90deg)',
            backgroundColor: '#f48b1b !important',
            color: '#ffffff !important',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important',
            }


        },
        paginator: {
            marginTop: '2rem',
            marginBottom: '4rem',
            justifyContent: 'center',
        },


    })
)(CarrerExpertViewAllPageWebToast);
export default withTranslation()(CarrerExpertViewAllPageWebWithStyle);