//@ts-nocheck
import React from "react";
import {
    withStyles,
    createStyles,
    Container,
    Typography,
    Paper,
    Grid,
    Button,
    IconButton
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import BookNowController, { Props } from "./BookNowController.web";
import { prev, next } from './assets'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ScrollMenu from "react-horizontal-scrolling-menu";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import BookmarkIcon from '@material-ui/icons/Bookmark';

//@ts-ignore
export class BookNowWeb extends BookNowController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        if (localStorage.getItem('token')) {
            this.userDetails();
            this.getBoughtCourses();
            this.getAllBookmarkedCourses()
        }

    }

    render() {
        //@ts-ignore
        const { classes } = this.props;
        const { userDetails, boughtCourseList } = this.state;
        const expertData = this.props?.location?.state?.profile;
        return (
            <>
                <Container maxWidth='Lg'>
                    <Typography variant="h4" className={classes.title}>Your Details</Typography>
                    <Grid item xs={12} sm={12} md={12} lg={9}>
                        <Paper elevation={2} className={classes.paper}>
                            {Object.keys(userDetails).length ?
                                <Grid container direction='row' justify="flex-start" className={classes.detailsGrid} >
                                    <Grid item xs={12} sm={4} md={4} >
                                        <Typography variant="subtitle2">Name</Typography>
                                        <Typography variant="h3">{userDetails.first_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography variant="subtitle2">Phone Number</Typography>
                                        <Typography variant="h3">{userDetails.phone_number}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography variant="subtitle2">Email</Typography>
                                        <Typography variant="h3">{userDetails.email}</Typography>
                                    </Grid>
                                </Grid> :
                                <Grid container direction='row' justify="flex-start" className={classes.detailsGrid} >
                                    <Typography>Please login</Typography>
                                </Grid>
                            }
                        </Paper>
                    </Grid>
                    {/*Your order Section  */}
                    <Typography variant="h4" className={classes.title}>Your orders</Typography>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9} >
                            <Paper elevation={3}>
                                <Grid container direction="row" justify="flex-start">
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} style={{ display: 'flex' }} >
                                        <img src={expertData?.attributes?.image} />
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <Grid container direction="column" spacing={2} style={{ padding: '1rem' }}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" >
                                                <Grid item xs={12}>
                                                    <Typography>0 Enrolled</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body2">{expertData?.attributes?.designation}</Typography>
                                            </Grid>
                                            <Grid item >
                                                {[1, 2, 3, 4, 5].map((_data: any) => {
                                                    if (expertData?.attributes?.avg_rating >= _data) {
                                                        return (<StarIcon className={classes.rating} />);
                                                    } else {
                                                        return (<StarBorderIcon className={classes.Icon} />)
                                                    }
                                                })}
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="subtitle2" className={classes.name}>{expertData?.attributes?.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={6}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item >
                                    <Typography variant="h3">Total</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">{expertData?.attributes?.price}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" className={classes.continueBtn}
                                        data-testId="continuePay"
                                        onClick={() => this.continuePay(expertData)}>
                                        <Typography variant="h3">Continue</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" className={classes.title} >Students Also Bought</Typography>
                </Container >
                <Container>
                    <Grid container direction="row">
                        <Grid item xs={12} className="w-full">
                            <ScrollMenu
                                clickWhenDrag={true}
                                dragging={true}
                                hideArrows={true}
                                hideSingleArrow={true}
                                scrollToSelected={true}
                                alignCenter={false}
                                transition={0.3}
                                translate={0}
                                wheel={false}
                                arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                                //@ts-ignore
                                data={boughtCourseList && boughtCourseList.length > 0 &&
                                    boughtCourseList.map((_data: any, idx: any) => (
                                        <Grid item key={idx}>
                                            <Card className={classes.card} key={idx} onClick={() => this.props.history.push("/home/coursedetail", { courseDetail: _data })}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        component="img"
                                                        className={classes.suggested}
                                                        src={_data?.attributes?.thumbnail}
                                                        title="suggested Card title"
                                                    />
                                                    <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                                                {_data?.attributes?.enrolled_users}{" "}Enrolled
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} />
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                                                                {_data?.attributes?.total_time}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography
                                                                variant="body2"
                                                                className={classes.runningCoursesCardTitle}
                                                            >
                                                                {_data?.attributes?.heading} {':'} </Typography>
                                                            <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>

                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {_data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
                                                                return (_data?.attributes?.user_rating >= rating ? <StarIcon key={rating + idx} style={{ color: "f48b1b" }} /> : <StarBorderIcon key={rating + idx} style={{ color: "f48b1b" }} />);
                                                            })}
                                                            {!_data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
                                                                return (<StarBorderIcon key={ratings + idx} style={{ color: "f48b1b", }} />);
                                                            })}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        variant="caption"
                                                                        className={classes.shortlistCardSubTitle}
                                                                    >
                                                                        {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    {localStorage.getItem('token') && this.state.getAllBookmarked && this.state.getAllBookmarked.map((e: any) =>
                                                                        this.state.getAllBookmarked.findIndex((e) => { return e.attributes.content.id == _data.id }) > -1 ?
                                                                            <IconButton aria-label="bookmark" data-testId="removeBookmark" onClick={(event) => this.removeBookmark(_data?.attributes?.id, event)}>

                                                                                <BookmarkIcon color="primary" />
                                                                            </IconButton>
                                                                            :
                                                                            <IconButton aria-label="bookmark" data-testId="addBookmark" onClick={(event) => this.addBookmark(_data?.attributes?.id, event)}>
                                                                                <BookmarkBorderIcon color="primary" />
                                                                            </IconButton>
                                                                    )}
                                                                    {localStorage.getItem('token') && this.state.getAllBookmarked.length <= 0 &&
                                                                        <IconButton aria-label="bookmark" onClick={(event) => this.addBookmark(_data?.attributes?.id, event)}>
                                                                            <BookmarkBorderIcon color="primary" />
                                                                        </IconButton>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <CardContent />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))
                                } />
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}
//@ts-ignore
const BookNowWebWithRouter = withRouter(BookNowWeb);
const BookNowWebWithLoader = withLoader(BookNowWebWithRouter);
const BookNowWebToast = withSnackbar(BookNowWebWithLoader);
const BookNowWebWithStyle = withStyles((theme) =>
    createStyles({
        root: {
            padding: '0px'
        },
        detailsGrid: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }
        },
        paper: {
            padding: '2rem 2rem 2rem 2rem',
        },
        continueBtn: {
            backgroundColor: '#f48b1b',
            color: '#ffffff',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important'
            }
        },
        orderGrid: {
            padding: '3rem 0rem 3rem 0rem',
        },
        cardGrid: {
            paddingBottom: '2rem'
        },
        title: {
            paddingTop: '2rem',
            paddingBottom: '2rem'
        },
        cardImg: {
            width: '100%',
            height: '100px',
            background: 'black'
        },
        ordersSection: {
            padding: '10rem'

        },
        orderPayment: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }
        },
        suggested: {
            backgroundColor: "#bdb4b4",
            height: "10rem",
            objectFit: 'fill'
        },
        suggestedDetail: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#3b302e",
            textOverflow: "ellipsis",
            width: "18rem"
        },
        suggestedCardEnrolledTime: {
            color: "#1c1c1c",
            paddingTop: "20px",
        },
        runningCoursesCardTitle: {
            color: "#1c1c1c"
        },
        card: {
            borderRadius: "8px",
            width: '15rem',
            margin: '1rem'
        },
        rating: {
            color: '#f8593b'
        },
        expertImage: {
            padding: '0px'
        },
        name: {
            color: '#4a6bd8'
        }
    })
)(BookNowWebToast);
export default withTranslation()(BookNowWebWithStyle);
