import React from "react";
import {
  withStyles, Grid, Card, CardContent, createStyles, Container, Typography,
} from "@material-ui/core";
import ContentManagementController, { Props } from './ContentManagementController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation, } from 'react-i18next';
import ReactPlayer from "react-player";
//@ts-ignore



export class ContentManagementWeb extends ContentManagementController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    return (
      <Container className="m-auto">
        <Grid container direction="column" className="content-start mt-8  md:content-around">
          {this.state.showAllContentsData.length > 0 &&
            this.state.showAllContentsData.map((ele: any) => (
              <Grid item key={ele.attributes.contentable.data.attributes.id}>
                <Card className="w-3/4 max-w-prose mb-16 shadow-2xl transition-shadow duration-500
             ease-in-out hover:shadow-2xl">
                  <ReactPlayer
                    playing={true}
                    controls
                    // Disable download button
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    // Disable right click
                    onContextMenu={(e: any) => e.preventDefault()}
                    volume={1}
                    width="100%"
                    height="100%"
                    onReady={() => console.log("ready now")}
                    url={ele.attributes.contentable.data.attributes.video} />
                  <CardContent className="text-left p-1 h-60 ">
                    <Typography
                      className={"MuiTypography--heading"}
                      variant="h6"
                      gutterBottom
                    >
                      {ele.attributes.contentable.data.attributes.headline}
                    </Typography>
                    <Typography
                      className={"MuiTypography--subheading"}
                      variant="caption"
                    >
                      {ele.attributes.contentable.data.attributes.content &&
                        <div dangerouslySetInnerHTML=
                          {{ __html: ele.attributes.contentable.data.attributes.content }}>
                        </div>}
                      {ele.attributes.contentable.data.attributes.description &&
                        <div dangerouslySetInnerHTML=
                          {{ __html: ele.attributes.contentable.data.attributes.description }}>
                        </div>}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const ContentManagementWebWithRouter = withRouter(ContentManagementWeb);
const ontentManagementWebWithStyle = withStyles((theme) =>
  createStyles({
    profileBtn: {
      display: 'block',
      position: 'absolute',
      opacity: '0'
    },
    uploadedImage: {
      width: '135px',
      height: '120px'
    }
  })
)(ContentManagementWebWithRouter);
export default withTranslation()(ontentManagementWebWithStyle);

