//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, MenuItem, IconButton, Button, createStyles, Typography, TextField
} from "@material-ui/core";
import { validationSchema } from "./UserBasicProfile.web.validation";
//@ts-ignore
import { withRouter } from "react-router";
import { Props } from './UserProfileBasicBlockController.web';
import { Formik, Form } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";

const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);

const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);

export class PersonalInformation extends React.Component {
  constructor(props: Props) {
    super(props);
  };
  render() {
    //@ts-ignore
    const { classes, activeStep, getSteps } = this.props;
    return (
      <Grid item xs={12} >
        <Grid container direction="row" alignItems="center" justify="flex-start" className={classes.heading}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.headTxt}>Personal Information</Typography>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: this.props.userDetailList?.first_name || localStorage.getItem("firstName") || "",
            date: this.props.userDetailList?.dob || "",
            gender: this.props.userDetailList?.gender || "none",
            email: this.props.userDetailList?.email || localStorage.getItem("email") || "",
            phone: this.props.userDetailList?.phone_number || localStorage.getItem("phoneNumber") || "",
            city: this.props.userDetailList?.city || "",
            user_pic: this.props.profileImgBase64Obj || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.props.updateUserDetail(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => {
            const {
              values,
              isValid,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              isSubmitting,
            } = formikProps;
            return (
              // @ts-ignore
              <Form autoComplete='off' onSubmit={handleSubmit}>
                <Grid container direction="row" justify="center" spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container direction="row" justify="space-evenly">
                      <Grid item xs={10}>
                        {this.props.profileImgBase64 || values.user_pic ? (
                          <div className="relative ">
                            <img
                              src={this.props.profileImgBase64}
                              className={classes.uploadedImage}>
                            </img>
                            <div className={classes.overlay}>
                              <input accept="image/*" hidden
                                data-testId="icon-button-file"
                                onChange={(e) => {
                                  this.props.profileImageHandler(e);
                                }}
                                id="icon-button-file" type="file" />
                              <label htmlFor="icon-button-file" className={classes.photoIcon}>
                                <IconButton color="secondary" aria-label="upload picture" component="span">
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                            </div>
                          </div>
                        ) : (
                          <Button component="label" className={classes.profileBtn}>
                            <Typography className={classes.picText} variant="body2">Upload a Picture</Typography>
                            <input
                              accept="image/*"
                              hidden
                              id="chooseProfilePic"
                              type="file"
                              data-testId="chooseProfilePic"
                              onChange={(e) => {
                                this.props.profileImageHandler(e);
                              }}
                            />
                          </Button>
                        )}

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Name*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              name="firstName"
                              value={values.firstName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={
                                touched.firstName && errors.firstName
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.firstName && errors.firstName
                                  ? errors.firstName
                                  : ""
                              }
                              InputProps={{ classes: { input: classes.txtProps } }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              placeholder="Name"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Date Of Birth</Typography>
                          </Grid>
                          <Grid item>
                            <DatePicker
                              name="date"
                              dateFormat="dd-MM-yyyy"
                              className={classes.datePicker}
                              onBlur={handleBlur}
                              selected={(values.date && new Date(values.date))}
                              minDate={new Date("01-01-1947")}
                              maxDate={(new Date()).setMonth(new Date().getMonth() - 36)}
                              onChange={(val: any) => {
                                setFieldValue("date", val);
                              }}
                              error={
                                touched.date && errors.date
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.date && errors.date
                                  ? errors.date
                                  : ""
                              }
                              placeholderText="DD-MM-YYYY"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Gender</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="gender"

                              defaultValue="none"
                              style={{ width: '20rem' }}
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.gender } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.gender && errors.gender ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.gender && errors.gender ? errors.gender : ""
                              }
                              value={values.gender}
                              select
                            >
                              <MyMenuItem disabled value="none">
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Select Gender
                                </Typography>
                              </MyMenuItem>
                              {[{ value: "male", lable: "Male" }, { value: "female", lable: "Female" }].map((option) => (
                                <MyMenuItem key={option.value + 1} value={option.value}>
                                  {option.lable}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">City</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="city"
                              placeholder="City"
                              onChange={handleChange}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.city && errors.city ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.city && errors.city ? errors.city : ""
                              }
                              value={values.city}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Email</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              name="email"
                              value={values.email}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={this.props.userDetailList?.email === null ? false : true}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              error={
                                touched.email && errors.email
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.email && errors.email
                                  ? errors.email
                                  : ""
                              }
                              placeholder="Email"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Mobile*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              name="phone"
                              disabled={this.props.userDetailList?.phone_number === null ? false : true}
                              placeholder="Mobile number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.phone && errors.phone ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={touched.phone && errors.phone ? errors.phone : ""}
                              value={values.phone}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="row" alignItems="center" justify="space-evenly">
                      <Button type="submit" disabled={isSubmitting || !isValid}
                        className={classes.nextBtn}>
                        {getSteps && activeStep === getSteps.length - 1 ? <Typography variant="h3">Finish</Typography> : <Typography variant="h3">Next</Typography>}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    )
  }
}
//@ts-ignore
const PIWebWithRouter = withRouter(PersonalInformation);
const PIWebWithLoader = withLoader(PIWebWithRouter);
const PIWebToast = withSnackbar(PIWebWithLoader);
const PIWebWithStyle = withStyles((theme) =>
  createStyles({
    selectDisColor: {
      color: 'grey'
    },
    overlay: {
      height: '86px',
      width: '86px',
      textAlign: 'center',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0'
    },
    photoIcon: {
      position: 'relative',
      top: '60%'
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    heading: {
      boxShadow: '0 8px 12px 0 rgb(213 210 210 / 16%)',
      border: 'solid 1px #e8e2e2',
      borderRadius: '7px',
      height: '5rem'
    },
    headTxt: {
      color: '#f48b1b',
      marginLeft: '1.5rem'
    },
    picText: {
      color: '#9d9d9d',
      textAlign: 'center'
    },
    profileBtn: {
      width: '86px',
      height: '86px',
      marginTop: '15px',
      borderRadius: '50%',
      backgroundColor: '#fff2f2',
      '&:hover': {
        backgroundColor: '#fff2f2',
        color: '#9d9d9d',
        textAlign: 'center'
      }
    },
    uploadedImage: {
      width: '86px',
      height: '86px',
      marginTop: '15px',
      objectFit: 'cover',
      borderRadius: '50%'
    },
    txtProps: {
      width: '18.5rem',
      color: '#7764db',
      fontWeight: 600
    },
    datePicker: {
      border: '1px solid #ffd0d0',
      height: '3rem',
      width: '20rem',
      borderRadius: '4px',
      color: '#7764db',
      fontWeight: 600,
      paddingLeft: '10px',
      ' &:focus': {
        borderColor: '#ffd0d0'
      }
    },
    helperTxt: {
      margin: '0px',
      textAlign: 'left',
      color: '#f9593a',
      fontWeight: 'bold',
      marginTop: '.5rem'
    },
    txtHead: {
      color: '#4f4f4f',
      paddingBottom: '.5rem'
    },
    gender: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#000000 !important',
      opacity: '10 !important'
    },
    icon: {
      backgroundColor: '#ccc',
      position: 'absolute',
      right: 0,
      bottom: 0
    }
  })
)(PIWebToast);
export default withTranslation()(PIWebWithStyle);