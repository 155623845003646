//@ts-nocheck
import React from "react";
import {
  withStyles, Hidden,
  Container, Grid, Typography, Button, Card, CardActionArea, CardContent, CardMedia, InputBase,
  createStyles
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import ExamlistWebController, { Props } from "./ExamlistController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { next, prev, courseImg, dummyImage } from "./assets";
import ScrollMenu from "react-horizontal-scrolling-menu";
import SearchIcon from "@material-ui/icons/Search";
//@ts-ignore
import insane from 'insane';
export class ExamlistWeb extends ExamlistWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getCategories();
    this.getOngoingExams();
    this.getPopularExams();
  }

  setSearchdata = (e: any) => {
    this.setState({ searchInfo: e.target?.value }, () => {
      this.getOngoingExams();
      this.getPopularExams();
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Container maxWidth="xl" disableGutters>
          <div
            className={classes.imgBg}
            style={{ marginTop: "-0.25rem" }}
          >
            {/* <img alt="" className="h-full" src={courseImg} /> */}
            <Grid container direction="row" alignItems="center" justify="center" className={classes.gridcount}>
              <Grid item xs={12} sm={6} >
                <Grid item container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.search}
                  >
                    <Grid item xs={1}>
                      <SearchIcon color="secondary" />
                    </Grid>
                    <Grid item xs={11}>
                      <InputBase
                        placeholder="Search exam here"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        data-testId="setSearchdata"
                        onChange={(val) => this.setSearchdata(val)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <br />
        <br />
        <Container maxWidth="xl">
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={12} sm={9} className="w-full">
              <ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={false}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                //@ts-ignore
                data={this.state.categories && this.state.categories.length > 0 && this.state.categories.map((item: any, index: any) =>
                  <React.Fragment key={item?.id}>
                    <Hidden smDown>
                      <Button key={item.id}
                        className={this.state.selectedChip && localStorage.getItem("selectedChip") == index ? `${classes.selectedBtn}` : `${classes.catBtn}`}
                        color="primary"
                        onClick={() => this.handleChipsClick(index, item.id)}
                        variant="outlined">
                        <Typography variant="subtitle2" className={classes.btnTypo}>{item?.attributes?.name}</Typography>
                      </Button>
                    </Hidden>
                    <Hidden mdUp>
                      <Button key={item.id}
                        className={this.state.selectedChip && localStorage.getItem("selectedChip") == index ? `${classes.selectedSmallBtn}` : `${classes.catSmallBtn}`}
                        color="primary"
                        onClick={() => this.handleChipsClick(index, item.id)}
                        variant="outlined">
                        <Typography variant="subtitle2" className={classes.btnSmallTypo}>{item?.attributes?.name}</Typography>
                      </Button>
                    </Hidden>
                  </React.Fragment>
                )}
              />
            </Grid>
          </Grid>
          <br />
          <Hidden smDown>
            <br />
            <br />
            <br />
          </Hidden>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
            <Hidden smDown><Typography align="center" variant="h4">Ongoing Exams</Typography></Hidden>
            <Hidden mdUp><Typography align="center" variant="h3">Ongoing Exams</Typography></Hidden>
            {this.state.onGoingexamList && this.state.onGoingexamList.length > 4 &&
              <Button
                className={classes.viewAll}
                color="primary"
                onClick={() => this.props.history.push(
                  {
                    pathname: `/home/examviewall/${this.state.categoryId}`,
                    state: {
                      ongoing: true,
                      filterType: 'ongoing_exams'
                    }
                  })}
                variant="outlined">
                <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
              </Button>
            }
          </Grid>
          <br />
          <br />
          <Grid container className={classes.paddingExamArVi}>
            <Grid item xs={12} className="w-full">
              <Hidden smDown><ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                //@ts-ignore
                data={this.state.onGoingexamList && this.state.onGoingexamList.length > 0 &&
                  this.state.onGoingexamList.map((_data: any, idx: any) => (
                    <Grid item key={idx}>
                      <Card className={classes.root} key={idx} onClick={() => this.props.history.push("/home/exam/" + _data.id)}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            className={classes.suggested}
                            src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                            title="suggested Card title"
                          />
                          <Grid container spacing={2} className={classes.cardText}>
                            <Grid item xs={12} >
                              <Typography
                                variant="h3"
                                className={classes.runningCoursesCardTitle}
                              >
                                {_data?.attributes?.heading}  </Typography>
                              {/* <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography> */}
                              {_data?.attributes?.description?.length > 30 ?
                                <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                  <span dangerouslySetInnerHTML={{
                                    __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                  }}></span>
                                </Typography>
                                :
                                <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                  <span dangerouslySetInnerHTML={{
                                    __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                  }}></span>
                                </Typography>
                              }
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={6}>
                                  <Typography
                                    variant="caption"
                                    className={classes.shortlistCardSubTitle}>
                                    {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <CardContent />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                } />
              </Hidden>
              <Hidden mdUp> <ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                data={this.state.onGoingexamList &&
                  this.state.onGoingexamList.map((_data: any, index: number) => (
                    <Card className={classes.rootSmall} key={index} onClick={() => this.props.history.push("/home/coursedetail", { courseDetail: _data })}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          className={classes.suggestedSmall}
                          src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                          title="suggested Card title"
                        />
                        <Grid container style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginTop: '.5rem' }}>
                          <Grid item xs={12} style={{ marginTop: '.5rem' }} >
                            <Typography
                              variant="h3"
                              className={classes.runningCoursesCardTitleSmall}
                            >
                              {_data?.attributes?.heading}</Typography>
                            <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>
                            {_data?.attributes?.description?.length > 30 ?
                              <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                <span dangerouslySetInnerHTML={{
                                  __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                }}></span>
                              </Typography>
                              :
                              <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                <span dangerouslySetInnerHTML={{
                                  __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                }}></span>
                              </Typography>
                            }
                          </Grid>
                          <Grid item xs={12} container direction="row" alignItems="center" justify="space-between">
                            <Grid item xs={12}>
                              <Typography variant="body1"
                                className={classes.shortlistCardSubTitle}>
                                {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <CardContent />
                      </CardActionArea>
                    </Card>
                  ))
                } />
              </Hidden>
            </Grid>
            <Grid item xs={1} />
            {
              this.state.onGoingexamList && this.state.onGoingexamList.length == 0 &&
              <Grid item xs={12}>
                <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
              </Grid>
            }
          </Grid>
          <br />
          <br />
          <Hidden smDown> <br />
            <br />
            <br /></Hidden>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
            <Hidden smDown> <Typography align="center" variant="h4">Popular Exams</Typography></Hidden>
            <Hidden mdUp> <Typography align="center" variant="h3">Popular Exams</Typography></Hidden>
            {this.state.popularExamsList && this.state.popularExamsList.length > 4 &&
              <Button
                className={classes.viewAll}
                color="primary"
                onClick={() => this.props.history.push(
                  {
                    pathname: `/home/examviewall/${this.state.categoryId}`,
                    state: {
                      is_popular: true,
                      filterType: 'popular_exams'
                    }
                  })}
                variant="outlined">
                <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
              </Button>
            }
          </Grid>
          <br />
          <br />
          <Grid container className={classes.paddingExamArVi}>
            <Grid item xs={12} className="w-full">
              <Hidden smDown> <ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                //@ts-ignore
                data={this.state.popularExamsList && this.state.popularExamsList.length > 0 &&
                  this.state.popularExamsList.map((_data: any, idx: any) => (
                    <Grid item key={idx}>
                      <Card className={classes.root} key={idx} onClick={() => this.props.history.push("/home/exam/" + _data.id)}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            className={classes.suggested}
                            src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                            title="suggested Card title"
                          />
                          <Grid container spacing={2} className={classes.cardText}>
                            <Grid item xs={12} >
                              <Typography
                                variant="h3"
                                className={classes.runningCoursesCardTitle}
                              >
                                {_data?.attributes?.heading}  </Typography>
                              {/* <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography> */}
                              {_data?.attributes?.description?.length > 30 ?
                                <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                  <span dangerouslySetInnerHTML={{
                                    __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                  }}></span>
                                </Typography>
                                :
                                <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                  <span dangerouslySetInnerHTML={{
                                    __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                  }}></span>
                                </Typography>
                              }
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={6}>
                                  <Typography
                                    variant="caption"
                                    className={classes.shortlistCardSubTitle}>
                                    {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <CardContent />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                } />
              </Hidden>
              <Hidden mdUp> <ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                data={this.state.popularExamsList &&
                  this.state.popularExamsList.map((_data: any, index: number) => (
                    <Card className={classes.rootSmall} key={index} onClick={() => this.props.history.push("/home/coursedetail", { courseDetail: _data })}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          className={classes.suggestedSmall}
                          src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                          title="suggested Card title"
                        />
                        <Grid container style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginTop: '.5rem' }}>
                          <Grid item xs={12} style={{ marginTop: '.5rem' }} >
                            <Typography
                              variant="h3"
                              className={classes.runningCoursesCardTitleSmall}
                            >
                              {_data?.attributes?.heading}</Typography>
                            {/* <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography> */}
                            {_data?.attributes?.description?.length > 30 ?
                              <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                <span dangerouslySetInnerHTML={{
                                  __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                }}></span>
                              </Typography>
                              :
                              <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                <span dangerouslySetInnerHTML={{
                                  __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                }}></span>
                              </Typography>
                            }
                          </Grid>
                          <Grid item xs={12} container direction="row" alignItems="center" justify="space-between">
                            <Grid item xs={12}>
                              <Typography variant="body1"
                                className={classes.shortlistCardSubTitle}>
                                {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <CardContent />
                      </CardActionArea>
                    </Card>
                  ))
                } />
              </Hidden>
            </Grid>
            <Grid item xs={1} />
            {
              this.state.popularExamsList && this.state.popularExamsList.length == 0 &&
              <Grid item xs={12}>
                <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
              </Grid>
            }
          </Grid>
          <br />
          <br />
          <Hidden smDown>  <br />
            <br />
            <br />
          </Hidden>
        </Container>
      </>
    )
  }
}
// @ts-ignore
const ExamlistWebWithRouter = withRouter(ExamlistWeb);
const ExamlistWebWithLoader = withLoader(ExamlistWebWithRouter);
const ExamlistWebToast = withSnackbar(ExamlistWebWithLoader);
export const ExamlistWebWithStyle = withStyles((theme) =>
  createStyles({
    btnSmallTypo: {
      whiteSpace: 'pre-wrap',
      width: '4rem',
      textAlign: 'center',
      fontSize: '9px',
      fontWeight: 100,
      lineHeight: '1'
    },
    catSmallBtn: {
      border: '1.5px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '4.5rem',
      height: '1.8rem',
      marginRight: '.2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      padding: '0.5rem !important'
    },
    selectedSmallBtn: {
      border: '1.5px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '.2rem',
      width: '4.5rem',
      height: '1.8rem',
      padding: '0.5rem !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    runningCoursesCardTitleSmall: {
      fontSize: '10px !important',
      color: "#1c1c1c",
      whiteSpace: 'pre-wrap'
    },
    rootSmall: {
      borderRadius: "8px",
      width: '9rem',
      margin: '.3rem',
      height: '11.5rem'
    },
    suggestedSmall: {
      backgroundColor: "#bdb4b4",
      height: "5rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTimeSmall: {
      color: "#1c1c1c"
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c",
      marginBottom: '0.5rem'
    },
    root: {
      borderRadius: "8px",
      width: '20rem',
      margin: '1rem'
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    paddingExamArVi: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      }
    },
    btnTypo: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflow: 'hidden'
    },
    catBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '173px',
      height: '3rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    colDiv: {
      width: '12rem',
      height: '6rem',
      position: 'absolute',
      textAlign: 'center',
      top: '26rem',
      borderRadius: '.5rem',
      background: 'white',
      right: '30rem'
    },
    greenDiv: {
      left: '5rem',
      position: 'relative',
      height: '3rem',
      width: '6rem',
      backgroundColor: '#ecd551',
      borderRadius: '.5rem',
      textAlign: 'center',
      padding: '.3rem'
    },
    whiteDiv: {
      height: '4rem',
      width: '7rem',
      textAlign: 'center',
      position: 'relative',
      right: '4rem'
    },
    certified: {
      height: '2rem',
      margin: 'auto'
    },
    title: {
      fontFamily: 'Montserrat-Regular'
    },
    titleImg: {
      height: '25rem',
      width: 'fit-content'
    },
    titleBlock: {
      display: 'inline-block'
    },
    titleHeader: {
      marginTop: '4rem'
    },
    register: {
      marginTop: '1rem',
      height: '2rem',
      width: '7rem',
      borderRadius: '9px',
      color: '#ffffff',
      backgroundColor: '#f48b1b',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    selectedBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      width: '173px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    showAllBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '8px !important',
      color: '#ffffff !important',
      width: '150px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        color: '#ffffff !important',
        height: '1rem',
        padding: '.7rem !important',
        width: '5.5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    imgBg: {
      backgroundImage: `url(${courseImg})`,
      width: "100%",
      height: "20rem",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      backgroundSize: "cover",
      justifyContent: "center",
      backgroundPosition: "center",
      [theme.breakpoints.down('md')]: {
        height: "8rem",
      },
      [theme.breakpoints.down('sm')]: {
        height: "6rem",
      }
    },
    search: {
      // width: "44rem",
      height: "3.5rem",
      borderRadius: "1.5rem",
      webkitBackdropFilter: "blur(50px)",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      marginTop: "0rem",
      paddingLeft: "1rem",
    },
    inputRoot: {
      width: "100%",
    },
    cardText: {
      paddingLeft: '1rem',
      paddingRight: '.5rem',
      marginTop: '0.5rem'
    },
    examDes: {
      display: 'inline-flex',
      "& > span": {
        display: 'flex !important'
      }
    },
    fvPlayer: {
      height: '16rem',
      display: 'block',
      width: '18rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
    fvSmallPlayer: {
      height: '12rem',
      display: 'block',
      width: '12rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed'
    },
  })
)((ExamlistWebToast))
export default withTranslation()(ExamlistWebWithStyle);