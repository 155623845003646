import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
export const resuableFun = require("../../ContentManagement/src/resuableFunction")
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
  };
interface S {
  // Customizable Area Start
  categories: any;
  selectedChip: any;
  featuredBlogs: any;
  popularBlogs: any;
  trendingBlogs: any;
  recommendedBlogs: any;
  searchInfo: any;
  categoryId: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class BlogPostsManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  categoryData: any;
  getPopularBlogsApiCallId: string = "";
  getFeaturedBlogssApiCallId: string = "";
  getTrendingBlogsApiCallId: string = "";
  getRecommendedBlogsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];
    this.state = {
      categories: [],
      selectedChip: localStorage.getItem("selectedChip") !== null && localStorage.getItem("selectedChip") !== undefined ? localStorage.getItem("selectedChip") : 0,
      featuredBlogs: [],
      popularBlogs: [],
      trendingBlogs: [],
      recommendedBlogs: [],
      searchInfo: "",
      categoryId: localStorage.getItem("categoryId") !== null && localStorage.getItem("categoryId") !== undefined ? localStorage.getItem("categoryId") : 4,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.categoryData = localStorage.getItem("categories");
      this.setState({
        categories: JSON.parse(this.categoryData),
      });
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getFeaturedBlogssApiCallId:
            this.handleFeaturedBlogApiResponce(responseJson);
            break;
          case this.getPopularBlogsApiCallId:
            this.handlePopularApiResponce(responseJson);
            break;
          case this.getTrendingBlogsApiCallId:
            this.handleTrendingApiResponce(responseJson);
            break;
          case this.getRecommendedBlogsApiCallId:
            this.handleRecommendedBlogsApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleFeaturedBlogApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        featuredBlogs: responseJson.data,
      });
    }
  };
  handlePopularApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        popularBlogs: responseJson.data,
      });
    }
  };
  handleTrendingApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        trendingBlogs: responseJson.data,
      });
    }
  };
  handleRecommendedBlogsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson?.data) {
      this.setState({
        recommendedBlogs: responseJson.data,
      });
    }
  }
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };
  handleChipsClick = (index: any, catIndex: any) => {
    localStorage.setItem("categoryId", catIndex);
    localStorage.setItem("selectedChip", index);
    this.setState(
      (state: any) => ({
        ...state,
        selectedChip: index,
        categoryId: catIndex,
      }),
      () => {
        this.featuredBlogs();
        this.popularBlogs();
        this.trendingBlogs();
        this.recommendedBlogs();
      }
    );
  };
  featuredBlogs = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeaturedBlogssApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    const search = this.state.searchInfo
      ? `&search=${this.state.searchInfo}`
      : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContentsBlogs +
      `&page=1&per_page=3&category[]=${this.state.categoryId
      }${search}&is_featured=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  popularBlogs = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPopularBlogsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    const search = this.state.searchInfo
      ? `&search=${this.state.searchInfo}`
      : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContentsBlogs +
      `&page=1&per_page=3&category[]=${this.state.categoryId
      }${search}&is_popular=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  trendingBlogs = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrendingBlogsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    const search = this.state.searchInfo
      ? `&search=${this.state.searchInfo}`
      : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContentsBlogs +
      `&page=1&per_page=3&is_trending=true&category[]=${this.state.categoryId
      }${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  recommendedBlogs = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecommendedBlogsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    const search = this.state.searchInfo
      ? `&search=${this.state.searchInfo}`
      : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContentsBlogs +
      `&page=1&per_page=3&category[]=${this.state.categoryId}${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
