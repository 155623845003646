//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Container, Card,
  Typography, Tabs, Tab, Box
} from "@material-ui/core";
import { resultLogo } from "./assets";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { TabPanel, a11yProps, styles } from "./TabPanelComp.web";

export const QuizGridOne = ({ classes, quizList, mobileView }) => {
  return (
    <Grid item xs={1}>
      {!mobileView ? <img src={resultLogo} width="42px" /> : <img src={resultLogo} width="30px" />}
    </Grid>
  )
}

export const QuizGridOTwo = ({ classes, quizList, mobileView, ele }) => {
  return (
    <Grid item xs={6}>
      <Grid container direction="column" justify="space-between" spacing={1}>
        <Grid item>
          <Typography variant={!mobileView ? 'h3' : 'body2'} className={!mobileView ? `${classes.purpleTxt}` : `${classes.purpleSmallTxt}`}>{ele?.attributes?.quiz?.heading}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify='space-between' alignItem="center">
            <Grid item xs={8}>
              <Typography variant={!mobileView ? 'h3' : 'body2'}>
                {/* Digital Skills:  */}
                {ele?.attributes?.quiz?.description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const QuizGridThree = ({ classes, quizList, mobileView, ele }) => {
  return (
    <Grid item xs={2}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant={!mobileView ? 'h3' : 'body2'} className={!mobileView ? `${classes.orangeTxt}` : `${classes.orangeSmallTxt}`}>{ele?.attributes?.attempt_count}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.greyTxt}>Attempt</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const QuizGridFour = ({ classes, quizList, mobileView, ele }) => {
  return (
    <Grid item xs={2}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant={!mobileView ? 'h3' : 'subtitle2'} className={!mobileView ? `${classes.orangeTxt}` : `${classes.orangeSmallTxt}`}>{ele?.attributes?.obtained_marks}/{ele?.attributes?.total_marks}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.greyTxt}>Score</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const QuizListComp = ({ classes, quizList, mobileView }) => {
  return (<>
    {quizList && quizList.length > 0 ? quizList.map(ele => (
      <React.Fragment key={ele.id}>
        <Card variant="outlined" className={!mobileView ? `${classes.resultBigCard}` : `${classes.resultSmallCard}`}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <QuizGridOne data-testId="QuizGridOne" {...{ classes, quizList, mobileView }} />
            <QuizGridOTwo {...{ classes, quizList, mobileView, ele }} />
            <QuizGridThree {...{ classes, quizList, mobileView, ele }} />
            <QuizGridFour {...{ classes, quizList, mobileView, ele }} />
          </Grid>
        </Card>
        <br />
      </React.Fragment>)) :
      <Grid item xs={12}>
        <Typography variant="body2" align="center">No Quiz results found</Typography>
      </Grid>}
  </>)
};

export const ScoreComp = ({ classes, ele, mobileView }) => {
  return (
    <>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant={!mobileView ? 'h3' : 'subtitle2'} className={!mobileView ? `${classes.orangeTxt}` : `${classes.orangeSmallTxt}`}>{ele?.attributes?.attempt_count}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={classes.greyTxt}>Attempt</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant={!mobileView ? 'h3' : 'subtitle2'} className={!mobileView ? `${classes.orangeTxt}` : `${classes.orangeSmallTxt}`}>{ele?.attributes?.obtained_marks}/{ele?.attributes?.total_marks}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={classes.greyTxt}>Score</Typography>
          </Grid>
        </Grid>
      </Grid></>
  )
}

export const AssessmentListComp = ({ classes, assessmentList, mobileView }) => {
  return (<>
    {assessmentList && assessmentList.length > 0 ? assessmentList.map(ele => (
      <React.Fragment key={ele.id}>
        <Card variant="outlined" className={!mobileView ? `${classes.resultBigCard}` : `${classes.resultSmallCard}`}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item xs={1}>
              {!mobileView ? <img src={resultLogo} width="42px" /> : <img src={resultLogo} width="30px" />}
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" justify="space-between" spacing={1}>
                <Grid item>
                  <Typography variant={!mobileView ? 'h3' : 'subtitle2'} className={!mobileView ? `${classes.purpleTxt}` : `${classes.purpleSmallTxt}`}>{ele?.attributes?.assessment?.heading}</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justify='flex-start' alignItem="center">
                    <Grid item xs={8}>
                      <Typography variant={!mobileView ? 'h3' : 'subtitle2'}>
                        {/* Digital Skills: */}
                        {ele?.attributes?.assessment?.description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ScoreComp {...{ classes, ele, mobileView }} />
          </Grid>
        </Card>
        <br />
      </React.Fragment>
    )) :
      <Grid item xs={12}>
        <Typography variant="body2" align="center">No Assessment results found</Typography>
      </Grid>
    }
  </>)
};
export class MyResults extends React.Component {
  render() {
    const { classes, mobileView, quizList, assessmentList } = this.props;
    return (
      <Container maxWidth="xl" >
        <Grid container direction="column" alignItems="flex-start" justify="center" className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
          <Grid item xs={12} md={6}>
            <Box >
              <Tabs
                data-testId="tabClick"
                value={this.props.tabValue}
                onChange={this.props.handleTabChange}
                classes={{
                  indicator: classes.indicator
                }}
                aria-label="secondary tabs example"
              >
                <Tab style={this.props.tabValue === 0 ? styles.tab : styles.inActiveTab} label="Quiz Results" {...a11yProps(0)} />
                <Tab style={this.props.tabValue === 1 ? styles.tab : styles.inActiveTab} label="Assessment Results" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" style={{ marginTop: '1rem' }}>
            <TabPanel value={this.props.tabValue} index={0}>
              <Grid item xs={12} >
                <div className="mt-8">
                  <QuizListComp {...{ classes, quizList, mobileView }} />
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={this.props.tabValue} index={1}>
              <Grid item xs={12} >
                <div className="mt-8">
                  <AssessmentListComp {...{ classes, assessmentList, mobileView }} />
                </div>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const MyResultsWithRouter = withRouter(MyResults);
const MyResultsWithLoader = withLoader(MyResultsWithRouter);
const MyResultsToast = withSnackbar(MyResultsWithLoader);
const MyResultsWithStyle = withStyles((theme) =>
  createStyles({
    addPadding: {
      padding: "0px",
      paddingLeft: "5rem"
    },
    noPadding: {
      padding: "0px",
    },
    indicator: {
      backgroundColor: '#f48b1b',
      width: '2px'
    },
    resultBigCard: {
      padding: '2rem',
      // width: '54rem',
      // height: '7rem',
      borderRadius: '10px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: '#fff'
    },
    resultSmallCard: {
      padding: '1rem',
      //   height: '7rem',
      borderRadius: '10px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: '#fff'
    },
    purpleTxt: {
      color: '#7764db',
      fontSize: '17px'
    },
    purpleSmallTxt: {
      color: '#7764db'
    },
    orangeTxt: {
      color: '#f48b1b',
      fontWeight: 600,
      fontSize: '19px'
    },
    orangeSmallTxt: {
      color: '#f48b1b'
    },
    greyTxt: {
      color: '#121212',
      fontWeight: 500,
      opacity: '.54'
    }
  })
)(MyResultsToast);
export default withTranslation()(MyResultsWithStyle);
