import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
export type Props = RouterProps &
    StyledProps & {
        id: string;
        showAlert: any;
        showLoader: any;
        hideLoader: any;
        openToastHandler: any;
        match: any;
        // showToast : any;
        // Customizable Area Start
        // Customizable Area End
    };
interface S {
    // Customizable Area Start
    url: string;
    mimeType: string;
    myProfile: any;
    featureBlogs: any;
    authorInfo: any;
    authoDetails: any;
    recentInfo: any
    recentblogInfo: any
    authblogInfo: any
    authorDetails: any
    // Customizable Area End
}
interface SS {
    id: any;
}
export default class BlogProfileController extends BlockComponent<
    Props,
    S,
    SS> {
    // Customizable Area Start
    getFeaturedBlogssApiCallId: string = "";
    getAuthorsApiCallId: string = "";
    getProfileApiCallId: string = "";
    getRecentBlogApiCallId: string = "";
    getAuthBlogApiCallId: string = "";
    unBookNowApiCallId: string = "";
    followApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ];
        this.state = {
            url: "",
            mimeType: "",
            myProfile: {},
            featureBlogs: [],
            authorInfo: [],
            authoDetails: {},
            recentInfo: [],
            recentblogInfo: [],
            authblogInfo: [],
            authorDetails: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    loadData = () => {
        this.featuredBlog();
        this.getRecentBlog();
        this.getAutherDetails();
    }
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (responseJson && !responseJson.errors) {
                switch (apiRequestCallId) {
                    case this.getFeaturedBlogssApiCallId:
                        this.handleFeaturedBlogsApiResponce(responseJson);
                        break;
                    case this.getRecentBlogApiCallId:
                        this.handleRecentBlogApiResponce(responseJson);
                        break;
                    case this.getAuthBlogApiCallId:
                        this.handleAuthorApiResponce(responseJson);
                        break;
                    case this.followApiCallId:
                        this.handleFollowApiResponce(responseJson);
                        break;
                    case this.unBookNowApiCallId:
                        this.handleUnBookApiResponce(responseJson);
                        break;
                    default:
                        break;
                }
            } else if (responseJson && responseJson.errors) {
                this.clearLocalstorage(responseJson)
                // we use parseApiErrorResponse method to handle errors by default provide by builder
            }
        }
    }
    // Customizable Area Start
    handleFeaturedBlogsApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors && responseJson.data) {
            this.setState({ featureBlogs: responseJson.data });
        }
    };
    handleRecentBlogApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors && responseJson.data) {
            this.setState({ recentblogInfo: responseJson.data });
        }
        this.props.hideLoader();
    };
    handleAuthorApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors && responseJson.data) {
            this.setState({ authorDetails: responseJson.data });
        }
    };
    handleFollowApiResponce = (responseJson: any) => {
        this.props.hideLoader();
        if (responseJson && !responseJson.errors && responseJson.data) {
            this.getAutherDetails();
        }
    };
    handleUnBookApiResponce = (responseJson: any) => {
        this.props.hideLoader();
        if (responseJson && !responseJson.errors && responseJson?.success) {
            this.getAutherDetails();
        }
    };
    clearLocalstorage = (responseJson: any) => {
        if (responseJson && responseJson.errors) {
            if (responseJson.errors.order_courses?.length) {
                this.props.openToastHandler("error", responseJson.errors.order_courses[0]);
            }
            else if (Array.isArray(responseJson?.errors) && responseJson?.errors[0]?.token) {
                this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
                setTimeout(() => {
                    localStorage.clear();
                    this.props.history.push('/home');
                }, 2000)
            }
            else if (responseJson?.errors) {
                this.props.openToastHandler("error", responseJson?.errors);
            }
        }
    };
    featuredBlog = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFeaturedBlogssApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContentsBlogs +
            `&page=1&per_page=5&is_featured=true`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getRecentBlog = () => {
        this.props.showLoader();
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRecentBlogApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRecentBlogs
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getAutherDetails = () => {
        const token = localStorage.getItem('token');
        const headers: any = {
            "Content-Type": configJSON.validationApiContentType,
        };
        if (token) {
            headers['token'] = token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAuthBlogApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAuthorBs}/${this.props.match?.params?.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    follow = (id: any) => {
        this.props.showLoader();
        const token = localStorage.getItem('token');
        let headers: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        if (token) {
            headers['token'] = token
        }
        const data = {
            "bookmarkable_id": id,
            "bookmarkable_type": "author"
        }
        const apiEndPoint = configJSON.addBookmarkUrl
        const methodType = configJSON.exampleAPiMethod;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.followApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    unfollow = (id: any) => {
        this.props.showLoader();
        const token = localStorage.getItem('token');
        let headers: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        if (token) {
            headers.token = token
        }
        const data = {
            "bookmarkable_id": id,
            "bookmarkable_type": "author"
        }
        const apiEndPoint = configJSON.removeBookmarkUrl
        const methodType = configJSON.exampleAPiMethod;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.unBookNowApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End