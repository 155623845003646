//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, MenuItem, Button, createStyles, Typography, TextField
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import { withTranslation } from 'react-i18next';
import withLoader from "../../../../components/src/withLoader.web";
import { withSnackbar } from "../../../../components/src/toast.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CompetitiveExamvalidationSchema } from "../UserBasicProfile.web.validation";

const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);

const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);

export class CompetitiveExam extends React.Component {
  render() {
    const { classes, activeStep, getSteps, openNewComField, setComCollege, setOtherComCollege, educationLevel, boards, standards, subjects, degree, colleges, courses, specialization } = this.props;
    return (
      <Grid item xs={12} >
        <Grid container direction="row" alignItems="center" justify="flex-start" className={classes.heading}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.headTxt}>Competitive Exam</Typography>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            selectEducationLevel: this.props.profileDetail?.higher_education_level?.data?.id ? JSON.stringify(this.props.profileDetail?.higher_education_level?.data) : "none",
            board: this.props.profileDetail?.competitive_exam_board?.data?.id || "none",
            standard: this.props.profileDetail?.competitive_exam_standard?.data && this.props.profileDetail?.competitive_exam_standard?.data?.id || "none",
            subject: this.props.profileDetail?.competitive_exam_subjects?.data &&
              this.props.profileDetail?.competitive_exam_subjects?.data.map(e => e.id)
              || [],
            schoolName: this.props.profileDetail?.competitive_exam_school_name || "",
            degrees: this.props.profileDetail?.competitive_exam_degree?.data?.id || "none",
            specialization: this.props.profileDetail?.competitive_exam_specialization?.data?.id || "none",
            course: this.props.profileDetail?.competitive_exam_course?.data?.id || "none",
            college: this.props.profileDetail?.competitive_exam_college?.data?.id || "none",
            passingCYear: this.props.profileDetail?.competitive_exam_passing_year || "",
            otherCollege: this.props.profileDetail?.competitive_exam_college_name || ""
          }}
          validationSchema={CompetitiveExamvalidationSchema}
          // @ts-ignore
          onSubmit={(values: any, { setSubmitting }) => {
            this.props.updateCompetitiveExam(values);
            setSubmitting(false)
          }}
        >
          {(formikProps) => {
            const {
              values,
              isValid,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              isSubmitting,
            } = formikProps;
            let selectedEducationLevel = values?.selectEducationLevel && values?.selectEducationLevel !== "none" && JSON.parse(values.selectEducationLevel)?.attributes?.above_12 === true
            return (
              // @ts-ignore
              <Form autoComplete='off' onSubmit={handleSubmit}>
                <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Choose Education Level*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="selectEducationLevel"
                              defaultValue="none"
                              style={{ width: '20rem' }}
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.selectEducationLevel && errors.selectEducationLevel ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.selectEducationLevel && errors.selectEducationLevel ? errors.selectEducationLevel : ""
                              }
                              value={values?.selectEducationLevel}
                              select
                            >
                              <MyMenuItem disabled value="none">
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Select Education Level*
                                </Typography>
                              </MyMenuItem>
                              {educationLevel && educationLevel.map((option) => (
                                <MyMenuItem key={option.id} value={JSON.stringify(option)}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      {selectedEducationLevel === false && values.selectEducationLevel !== "none" &&
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Select Board*</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="board"
                                defaultValue="none"
                                style={{ width: '20rem' }}
                                onChange={handleChange}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.board && errors.board ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={
                                  touched.board && errors.board ? errors.board : ""
                                }
                                value={values.board}
                                select
                              >
                                <MyMenuItem disabled value="none">
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Select Board*
                                  </Typography></MyMenuItem>
                                {boards && boards.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                      {selectedEducationLevel &&
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Degree*</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="degrees"
                                style={{ width: '20rem' }}
                                onChange={handleChange}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.degrees && errors.degrees ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={touched.degrees && errors.degrees ? errors.degrees : ""}
                                value={values.degrees}
                                select
                              >
                                <MyMenuItem key={""} value="none" disabled>
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Choose degree*
                                  </Typography>
                                </MyMenuItem>
                                {degree && degree.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  {selectedEducationLevel === false && values.selectEducationLevel !== "none" &&
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="flex-start" justify="space-around">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">School Name</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                name="schoolName"
                                value={values.schoolName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={
                                  touched.schoolName && errors.schoolName
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.schoolName && errors.schoolName
                                    ? errors.schoolName
                                    : ""
                                }
                                InputProps={{ classes: { input: classes.txtProps } }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                placeholder="School Name"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Select Class*</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="standard"
                                style={{ width: '20rem' }}
                                defaultValue="none"
                                onChange={handleChange}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.standard && errors.standard ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={
                                  touched.standard && errors.standard ? errors.standard : ""
                                }
                                value={values.standard}
                                select
                              >
                                <MyMenuItem disabled value="none">
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Select Classes*
                                  </Typography>
                                </MyMenuItem>
                                {standards && standards.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {selectedEducationLevel === false && values.selectEducationLevel !== "none" &&
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="flex-start" justify="space-around">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Subjects</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="subject"
                                style={{ width: '20rem' }}
                                onChange={(e: any) => {
                                  return setFieldValue('subject', Array.from(e?.target?.value, option => option))
                                }}
                                SelectProps={{
                                  multiple: true,
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.subject && errors.subject ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={
                                  touched.subject && errors.subject ? errors.subject : ""
                                }
                                value={values.subject.map(e => e)}
                                select
                              >
                                <MyMenuItem disabled value={[]}>
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Select Subject
                                  </Typography></MyMenuItem>
                                {subjects && subjects.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                            </Grid>
                            <Grid item style={{ width: '20rem' }}>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {selectedEducationLevel &&
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="flex-start" justify="space-around">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Specialization </Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="specialization"
                                style={{ width: '20rem' }}
                                placeholder="Select Specialization"
                                onChange={handleChange}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.specialization && errors.specialization ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={
                                  touched.specialization && errors.specialization ? errors.specialization : ""
                                }
                                value={values.specialization}
                                select
                              >
                                <MyMenuItem key={""} value="none" disabled>
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Choose Specialization
                                  </Typography>
                                </MyMenuItem>
                                {specialization && specialization.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Course*</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="course"
                                style={{ width: '20rem' }}
                                placeholder="Select Course"
                                onChange={handleChange}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.course && errors.course ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={
                                  touched.course && errors.course ? errors.course : ""
                                }
                                value={values.course}
                                select
                              >
                                <MyMenuItem key={""} value="none" disabled>
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Choose Course*
                                  </Typography>
                                </MyMenuItem>
                                {courses && courses.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {selectedEducationLevel &&
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="flex-start" justify="space-around">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">College / University</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="college"
                                style={{ width: '20rem' }}
                                data-testId="college"
                                onChange={(val: any) => {
                                  colleges && colleges.map((option) => {
                                    if (val.target.value === option.id && option.attributes.name === "Other") {
                                      setOtherComCollege();
                                    } else {
                                      setComCollege();
                                      setFieldValue("college", val.target.value);
                                      setFieldValue("otherCollege", "");
                                    }
                                  })
                                }}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                                onBlur={handleBlur}
                                error={touched.college && errors.college ? true : false}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                helperText={
                                  touched.college && errors.college ? errors.college : ""
                                }
                                value={values.college}
                                select
                              >
                                <MyMenuItem key={""} value="none" disabled>
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Choose College
                                  </Typography>
                                </MyMenuItem>
                                {colleges && colleges.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Passing Year</Typography>
                            </Grid>
                            <Grid item>
                              <DatePicker
                                name="passingCYear"
                                dateFormat="yyyy"
                                className={classes.datePicker}
                                onBlur={handleBlur}
                                showYearPicker
                                selected={(values.passingCYear && new Date(values.passingCYear))}
                                minDate={new Date("1900")}
                                maxDate={new Date()}
                                onChange={(val: any) => {
                                  setFieldValue("passingCYear", val);
                                }}
                                error={
                                  touched.passingCYear && errors.passingCYear
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.passingCYear && errors.passingCYear
                                    ? errors.passingCYear
                                    : ""
                                }
                                placeholderText="YYYY"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {selectedEducationLevel &&
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="flex-start" justify="space-around">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            {openNewComField ? <>
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">College / University Name</Typography>
                              </Grid>
                              <Grid item>
                                <PinkTextField
                                  variant="outlined"
                                  name="otherCollege"
                                  placeholder="Enter College"
                                  onChange={handleChange}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={values.otherCollege}
                                >
                                </PinkTextField>
                              </Grid></> : <Grid item style={{ width: '20rem' }}></Grid>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  <Grid item xs={7}>
                    <Grid container direction="row" alignItems="center" justify="space-evenly">
                      <Button type="submit" disabled={isSubmitting || !isValid || values.selectEducationLevel === "none"}
                        className={classes.nextBtn}>
                        {activeStep === getSteps.length - 1 ? <Typography variant="h3">Finish</Typography> : <Typography variant="h3">Next</Typography>}
                      </Button>
                      <Button
                        onClick={this.props.handleReset} className={classes.cancelBtn}>
                        <Typography variant="h3">Cancel</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    )
  }
}
//@ts-ignore
const CompetitiveExamWithRouter = withRouter(CompetitiveExam);
const CompetitiveExamWebWithLoader = withLoader(CompetitiveExamWithRouter);
const CompetitiveExamWebToast = withSnackbar(CompetitiveExamWebWithLoader);
const CompetitiveExamWebWithStyle = withStyles((theme) =>
  createStyles({
    selectDisColor: { color: 'grey' },
    heading: {
      boxShadow: '0 8px 12px 0 rgb(213 210 210 / 16%)',
      border: 'solid 1px #e8e2e2',
      borderRadius: '7px',
      height: '5rem'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    txtHead: {
      color: '#4f4f4f',
      paddingBottom: '.5rem'
    },
    headTxt: {
      color: '#f48b1b',
      marginLeft: '1.5rem'
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      width: '120px',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#000000'
      }
    },
    txtProps: {
      width: '18.5rem',
      color: '#7764db',
      fontWeight: 600
    },
    helperTxt: {
      margin: '0px',
      textAlign: 'left',
      color: '#f9593a',
      fontWeight: 'bold',
      marginTop: '.5rem'
    },
    persInfo: {
      marginTop: '20px'
    },
    datePicker: {
      border: '1px solid #ffd0d0',
      height: '3rem',
      width: '20rem',
      borderRadius: '4px',
      color: '#7764db',
      fontWeight: 600,
      paddingLeft: '10px',
      ' &:focus': {
        borderColor: '#ffd0d0'
      }
    }
  })
)(CompetitiveExamWebToast);
export default withTranslation()(CompetitiveExamWebWithStyle);