
//@ts-nocheck
//@ts-ignore
import React from 'react';
import {View,Text, StyleSheet} from 'react-native';
import { Bold, grey_text_color, lineHeight_14, size_12pt, white } from '../../Utils';
interface Props{
    activeTheme?:any;
    name?:any;
}

export default class CardTitle extends React.Component<Props> {
    constructor(props:Props){
        super(props);
    }

     
    
     render(){
       let themeManager = { color: this.props.activeTheme =='dark'? white : grey_text_color}
        return(
            <View>
            <Text numberOfLines={1} style={[styles.cardTitle, themeManager]}>{this.props.name}</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    cardTitle:{
        marginStart:7,
        marginEnd:17,
        fontSize:size_12pt,
        lineHeight:lineHeight_14,
        fontFamily:Bold
    }
})