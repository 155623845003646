//@ts-nocheck
import React from "react";
import {
  withStyles, Card, Menu, CardContent, Box, Chip, TextField, MenuItem, ListSubheader, Dialog, DialogActions, DialogContent,
  Container, Grid, Typography, Button, createStyles
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//@ts-ignore
import { withRouter } from "react-router";
import DraftController, { Props } from "./DraftController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { userImage } from "./assets";
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik, Form } from "formik";
import { DiscussionforumSchema } from "./Discussionforum.web.validation";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import MoreVertIcon from '@material-ui/icons/MoreVert';
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);
const ReportMenuItem = withStyles({
  root: { backgroundColor: '#ffffff', color: '#000000 !important' },
  MuiMenuItem: {
    root: { backgroundColor: '#ffffff', color: '#000000 !important', }
  },
})(MenuItem);
export class Draft extends DraftController {
  constructor(props: Props) {
    super(props);
  }
  saveAsDraft = (values: any, draft: any) => {
    this.setState({ "question": values }, () => this.postQuestion(draft))
  }
  async componentDidMount() {
    const { searchInfo } = this.props;
    this.getTagList();
    this.getCategories();
    if (localStorage.getItem("token")) {
      this.getDraftQuestions(searchInfo);
    } else {
      this.openDialog(true);
    }
    window.scrollTo(0, 0);
    TimeAgo.addDefaultLocale(en);
  }
  async componentWillReceiveProps(nextProps: any) {
    if (nextProps.searchInfo !== this.props.searchInfo) {
      this.getDraftQuestions(nextProps.searchInfo);
    }
  }
  addTag = () => {
    this.setState(prevState => ({ showTagSearch: !prevState.showTagSearch }));
  }
  renderSelectGroup = (t) => {
    const items = t?.attributes?.sub_categories.map(p => {
      return (
        <MyMenuItem key={p.id} value={p.id}>{p?.name}</MyMenuItem>
      );
    });
    return [<ListSubheader>{t.name}</ListSubheader>, items];
  };
  openMenu = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget });
  }
  handleMenuClose = (event: any) => {
    event?.preventDefault();
    this.setState({ anchorEl: null });
  }
  handleDeleteMenu = (event: any) => {
    event?.preventDefault();
    this.setState({ openDeleteDialog: true, anchorEl: null })
  }
  formUi = (classes, ...args) => {
    const [touched, setFieldValue, handleBlur, handleChange, handleSubmit,
      errors, values, categories, showTagSearch, tagsList, isSubmitting, isValid, i] = args
    return (<Form autoComplete="off" onSubmit={handleSubmit}>
      <DialogContent key={i}>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item xs={11}>
            <PinkTextField
              variant="outlined"
              name="categories"
              label="Choose Categories"
              defaultValue="none"
              onChange={handleChange}
              placeholder="choose categories"
              SelectProps={{
                MenuProps: { classes: { paper: classes.selectBoard } }
              }}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              error={touched.categories && errors.categories ? true : false}
              InputProps={{ classes: { input: classes.txtProps } }}
              InputLabelProps={{ style: { color: 'grey' } }}
              helperText={
                touched.categories && errors.categories ? errors.categories : ""
              }
              value={values.categories}
              select
            >
              <MyMenuItem disabled value="none">
                <Typography variant="subtitle1" className={classes.selectDisColor}>
                  Choose Categories*
                </Typography>
              </MyMenuItem>
              {categories && categories.map(p => this.renderSelectGroup(p))}
            </PinkTextField>
          </Grid>
          <Grid item xs={11}>
            <PinkTextField
              variant="outlined"
              name="title"
              defaultValue="none"
              onChange={handleChange}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              error={touched.title && errors.title ? true : false}
              InputProps={{ classes: { input: classes.txtProps } }}
              helperText={
                touched.title && errors.title ? errors.title : ""
              }
              value={values.title}
              placeholder="Enter Title"
            />
          </Grid>
          <Grid item xs={11}>
            <PinkTextField
              variant="outlined"
              name="description"
              multiline
              rows={10}
              onChange={handleChange}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              error={touched.description && errors.description ? true : false}
              InputProps={{ classes: { input: classes.txtProps } }}
              helperText={
                touched.description && errors.description ? errors.description : ""
              }
              value={values.description}
              placeholder="Enter Description"
            />
          </Grid>
          <Grid item xs={11}>
            <Button variant="text" data-testId="addTag" onClick={this.addTag}>
              <Typography variant="body2" className={classes.addtagTxt}>Add tag +</Typography></Button>
            {showTagSearch &&
              <Box sx={{ width: 500 }}>
                <Autocomplete
                  data-testId="tagSearchAutocomplete"
                  multiple
                  freeSolo
                  name="tagVal"
                  id="tagVal"
                  options={tagsList}
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => {
                    setFieldValue("tagVals", [...new Set([].concat(values.tagVals, value))]);
                    setFieldValue("tagVal", []);
                  }}
                  value={values?.tagVal.map(e => e)}
                  renderTags={() => null}
                  renderInput={params => {
                    return (<PinkTextField fullWidth {...params} name="tagVal" variant="outlined"
                      placeholder="Search Tags" />)
                  }}
                /> </Box>}
            <Box
              mt={3}
              sx={{
                '& > :not(:last-child)': { mr: 1 },
                '& > *': { mr: 1 },
              }}>
              {values?.tagVals && values?.tagVals.map((v, i) => (
                <Chip className={classes.chip} key={i} label={v} onDelete={() => {
                  setFieldValue("tagVals", values.tagVals.filter(x => x !== v));
                }} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {this.buttonUi(isSubmitting, isValid, values, classes)}
    </Form>)
  }
  buttonUi = (isSubmitting, isValid, values, classes) => {
    return (
      <DialogActions style={{ paddingRight: "3rem" }}>
        {localStorage.getItem("token") && <> <DeleteIcon data-testId="openDeleteModal" onClick={() => this.setState({ openDeleteDialog: true })} className={classes.dfIcon} /></>}
        {!localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.cancelBtn} >Save as Draft </Button>}
        {!localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.publishBtn} >Publish</Button>}
        {localStorage.getItem("token") && <Button variant="contained" name="draft" disabled={values.title == "" || values.categories === "" || values.description.length < 200 || values.description === ""} data-testId="saveAsDraft" onClick={() => this.saveAsDraft(values, "draft")} className={classes.cancelBtn} >Save as Draft </Button>}
        {localStorage.getItem("token") && <Button variant="contained" name="publish" disabled={!isValid} type="submit" className={classes.publishBtn} >Publish</Button>}
      </DialogActions>
    )
  }
  tagUi = (e, classes) => {
    return (<Grid container direction="row" justify="flex-start">
      {e?.attributes?.tags && e?.attributes?.tags.map((el, index) =>
        <Button key={index} variant="contained" className={classes.tagChips}>
          {el && el.name && <Typography variant="body1">{el.name}</Typography>}
        </Button>)}
    </Grid>
    )
  }
  descriptionUi = (e, classes, i) => {
    return (<Grid container direction="row">
      {e?.attributes?.description.length > 300 ?
        <Typography variant="subtitle2" data-testId="openPostDialogfirst" onClick={() => { this.openPostDialog(i) }} className={classes.desc}>
          {e?.attributes?.description}...</Typography>
        :
        <Typography variant="subtitle2" data-testId="openPostDialog" onClick={() => { this.openPostDialog(i) }} className={classes.desc}>
          {e?.attributes?.description}</Typography>}
    </Grid>)
  }
  cardUi = (e, classes, anchorEl, i) => {
    return (
      <Card className={classes.rootCard} key={i}>
        <CardContent>
          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
            <Grid item xs={10}>
              <Grid container direction="row" alignItems="center" justify="flex-start">
                <Grid item xs={1}>
                  <img src={e?.attributes?.user_image ? e?.attributes?.user_image : userImage} className={classes.userImg} />
                </Grid>
                <Grid item xs={10}>
                  {e?.attributes?.updated_at &&
                    <Grid container direction="column" >
                      {e && e.attributes && e.attributes.account && e.attributes.account.first_name && <Typography variant="caption">{e.attributes.account.first_name}</Typography>}
                    </Grid>}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container direction="row" alignItems="center" justify="flex-end">
                <MoreVertIcon color="light" data-testId="openMenu" onClick={(e) => this.openMenu(e)} className={classes.addIcon} />
                <Menu
                  classes={{ paper: classes.reportMenu }}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  data-testId="handleMenuClose"
                  onClose={this.handleMenuClose}
                >
                  <ReportMenuItem key={"1"} data-testId="handleDeleteMenu" onClick={(e: any) => this.handleDeleteMenu(e)}>Delete</ReportMenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row">
            {e && e.attributes && e.attributes.title && <Typography variant="h3">{e.attributes.title}</Typography>}
            <Grid item>
            </Grid>
          </Grid>
          <br />
          {this.descriptionUi(e, classes, i)}
          {this.tagUi(e, classes)}
          <br />
        </CardContent>
      </Card>
    )
  }
  render() {
    const { classes } = this.props;
    const { anchorEl, getDraft, openDialogAtIndex, openDeleteDialog, categories, openDiscussionDialog, showTagSearch, tagsList } = this.state;
    return (
      <Container maxWidth="xl" style={{ paddingLeft: '4rem', paddingRight: '4rem' }} >
        <Grid item xs={12} container direction="row" justify="flex-start">
          <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
            <Grid container direction="row">
              {getDraft.length > 0 && getDraft.map((e: any, i: any) =>
                <>
                  {this.cardUi(e, classes, anchorEl, i)}
                  {openDialogAtIndex === i && <Dialog key={i} open={openDiscussionDialog} onClose={this.handleClose}
                    PaperProps={{
                      style: {
                        minHeight: "500px",
                        width: "720px",
                        backgroundColor: "#ffffff",
                        boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
                      },
                    }}>
                    <Formik
                      data-testId="createPost"
                      initialValues={{
                        id: e?.attributes?.id || "",
                        title: e?.attributes?.title || "",
                        categories: e?.attributes?.sub_category?.id || "",
                        description: e?.attributes?.description || "",
                        tagVal: [],
                        tagVals: e?.attributes?.tags.map((et: any) => et?.name) || []
                      }}
                      validationSchema={DiscussionforumSchema}
                      onSubmit={(values: any) => {
                        this.createPost(values);
                      }}
                    >
                      {(formikProps) => {
                        const {
                          values,
                          isValid,
                          errors,
                          touched,
                          handleSubmit,
                          handleBlur,
                          handleChange,
                          setFieldValue,
                          isSubmitting,
                        } = formikProps;
                        return (
                          this.formUi(classes, touched, setFieldValue, handleBlur, handleChange, handleSubmit,
                            errors, values, categories, showTagSearch, tagsList, isSubmitting, isValid, i)
                        );
                      }}
                    </Formik>
                  </Dialog>
                  }
                  {localStorage.getItem("token") && openDialogAtIndex === i &&
                    <>
                      <Dialog
                        PaperProps={{
                          style: { boxShadow: 'none' }
                        }}
                        BackdropProps={{ style: { background: 'rgb(0,0,0,.15)' } }}
                        open={openDeleteDialog}
                        onClose={this.handleClose}>
                        <DialogContent>
                          Are you sure you want to delete the post?
                        </DialogContent>
                        <DialogActions>
                          <Button className={classes.cancelBtn} data-testId="deleteDraft" onClick={() => this.deleteDraft(e?.attributes?.id)}>Yes</Button>
                          <Button className={classes.publishBtn} onClick={this.handleClose} autoFocus>No</Button>
                        </DialogActions>
                      </Dialog>
                    </>}
                </>
              )}
              {getDraft.length === 0 && <Typography className="text-center" variant="body2">No Drafts!</Typography>}
            </Grid>
          </Grid>
        </Grid>
        <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
      </Container>
    )
  }
}
// @ts-ignore
const DraftWithRouter = withRouter(Draft);
const DraftWithLoader = withLoader(DraftWithRouter);
const DraftToast = withSnackbar(DraftWithLoader);
const DraftWithStyle = withStyles((theme) =>
  createStyles({
    reportMenu: {
      backgroundColor: 'white !important',
      borderRadius: '4px !important',
      boxShadow: '0px 1px 3px 0 rgba(0, 0, 0, 0.15) !important',
    },
    rootCard: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      width: '100%',
      marginBottom: '20px'
    },
    chip: {
      height: '1.5rem',
      borderRadius: '0.5rem',
      background: '#f48b1b',
      color: '#ffffff',
      "& .MuiChip-deleteIcon": {
        height: "15px"
      },
      "&:hover": {
        "& .MuiChip-deleteIcon": {
          height: "15px"
        }
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#ffffff'
      }
    },
    publishBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      // width:'120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    addtagTxt: {
      color: '#7764db'
    },
    userImg: {
      height: '2rem',
      width: '2.5rem'
    },
    desc: {
      wordBreak: 'break-all',
      cursor: 'pointer'
    },
    dotIcon: {
      color: '#1682fd',
      height: '1rem'
    },
    rightBlueTxt: {
      color: '#1682fd'
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    addIcon: {
      height: '1.2rem'
    },
    dfIcon: {
      cursor: 'pointer',
      height: '1.2rem',
      fill: '#f48b1b'
    },
  })
)((DraftToast))
export default withTranslation()(DraftWithStyle);