//@ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  createStyles,
  withStyles,
  Badge,
  Avatar,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../withLoader.web";
import DashboardHeaderController, {
  Props,
} from "./DashboardHeaderController.web";
//@ts-ignore
import i18n from "i18next";
import { graduate, logo, notification } from "./assets";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "0rem",
    backgroundColor: "#2c77ff",
    right: ".2rem",
    transform: "scale(1) translate(50%, -50%)",
    transformOrigin: " 100% 0%",
    height: "16px",
    width: "26px",
  },
}))(Badge);
class DashboardHeaderWeb extends DashboardHeaderController {
  constructor(props: Props) {
    super(props);
    this.state = {
      ShowHideFlag: false,
    };
  }
  setResponsiveness = () => {
    return window.innerWidth < 1100
      ? this.setState((prevState) => ({ ...prevState, mobileView: true }))
      : this.setState((prevState) => ({ ...prevState, mobileView: false }));
  };
  async componentDidMount() {
    if (localStorage.getItem("token")) {
      this.userDetails();
      this.getNotificationData();
    }

    window.addEventListener("resize", () => this.setResponsiveness());
  }

  handleNotifications = (event: any) => {
    this.setState({
      anchorNEl: event.currentTarget,
      ShowHideFlag: !this.state.ShowHideFlag,
    });
  };

  render() {
    //@ts-ignore
    const { classes, t, i18n } = this.props;
    const { userDetails, NotificationData } = this.state;
    return (
      <AppBar
        position="static"
        color="transparent"
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "none",
          position: "fixed",
          height: "4rem",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.21)",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={7}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <img
                    onClick={() => {
                      this.props.history.push("/home");
                    }}
                    className="left-8 h-10 w-12 relative cursor-pointer"
                    width="68px"
                    src={logo}
                  />
                </Grid>
                <Grid item>
                  {this.props.mobileView && (
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-haspopup={true}
                      onClick={this.props.handleSideDrawerOpen}
                      style={{ marginLeft: "2rem" }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <Grid item xs={3}>
                  <StyledBadge
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={NotificationData?.length}
                    className={classes.badge}
                    onClick={this.handleNotifications}
                  >
                    <img
                      width="30px"
                      className="relative cursor-pointer"
                      src={notification}
                    />
                  </StyledBadge>
                </Grid>
                {this.state.ShowHideFlag && (
                  <ArrowDropUpIcon className={classes.caret} color="primary" />
                )}
                {this.state.ShowHideFlag ? (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={classes.notificationDropDown}
                  >
                    {NotificationData &&
                      NotificationData.map((data, index) => {
                        return (
                          <Grid
                            container
                            className={classes.notificationData}
                            key={index}
                          >
                            <Grid item xs={1} sm={1} md={1} lg={2} xl={2}>
                              <img src={logo} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={9} xl={9}>
                              <Grid container direction="column">
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.notificationText}
                                >
                                  <Typography
                                    color="secondary"
                                    variant="h3"
                                    className={classes.contentProviderTxt}
                                  >
                                    {data?.attributes.title}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    className={classes.contentProviderGrid}
                                  >
                                    <Grid item xs={1}>
                                      <img src={logo} />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography
                                        color="secondary"
                                        variant="body1"
                                        className={classes.contentProviderTxt}
                                      >
                                        Content Provider
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography
                                        color="secondary"
                                        variant="body1"
                                        className={classes.notifTime}
                                      >
                                        1 day ago
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    className={classes.contentProviderGrid}
                                  >
                                    <Grid item xs={9}>
                                      <Typography
                                        color="secondary"
                                        variant="body1"
                                        className={classes.contentTitleTxt}
                                      >
                                        {data?.attributes.message}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                ) : null}
                <Grid item xs={3}>
                  {userDetails ? (
                    <Avatar
                      alt="UserProfile"
                      src={userDetails.attributes?.user_pic}
                      className={classes.large}
                    />
                  ) : (
                    <img
                      className="relative m-auto h-8 w-6 cursor-pointer"
                      src={graduate}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}
// @ts-ignore
const DashboardHeaderWebWithRouter = withRouter(DashboardHeaderWeb);
const DashboardHeaderWebWithLoader = withLoader(DashboardHeaderWebWithRouter);
const DashboardHeaderWebWithStyle = withStyles((theme) =>
  createStyles({
    toolbar: {
      minHeight: "62px",
    },
    uploadBtn: {
      textAlign: "center",
      height: "1rem",
      backgroundColor: "#f48b1b",
      borderRadius: "6px",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#f48b1b",
        color: "#ffffff",
        borderRadius: "6px",
      },
    },
    contentProviderGrid: {
      marginTop: "0.6rem",
      marginBottom: "0.6rem",
      color: "#000",
    },
    contentProviderTxt: {
      marginLeft: "0.5rem",
      color: "#000",
    },
    contentTitleTxt: {
      fontSize: "0.8rem",
      fontWeight: "600",
      color: "#000",
    },
    notificationText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "8rem",
    },
    notificationDropDown: {
      background: "#ffffff",
      width: "20rem",
      borderRadius: "10px",
      padding: "20px",
      position: "absolute",
      top: "4.8rem",
      right: "10rem",
      color: "#000",
      height: "20rem",
      overflowY: "scroll",
    },
    notificationData: {
      borderLeft: "0px",
      borderWidth: "2px",
      borderRight: "0px",
      borderColor: "#cdcdcd",
      borderTop: "0px",
      marginBottom: "1rem",
      direction: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    caret: {
      [theme.breakpoints.down("sm")]: { top: "5.5rem" },
      top: "3.1rem",
      height: "48px",
      // left: '11rem',
      position: "absolute",
      width: "55px",
      color: "#ffffff",
      right: "20rem",
    },
    notifTime: {
      textAlign: "end",
      color: "#000",
    },
  })
)(DashboardHeaderWebWithLoader);
export default withTranslation()(DashboardHeaderWebWithStyle);
