//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Typography,
  Button,
  Paper,
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import { withRouter } from "react-router";
import AssessmentTestControllerWeb, { Props } from "./AssessmentTestController.web";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import withLoader from "../../../components/src/withLoader.web";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isEmpty, secondsToTime, notEmpty, getAsciiToChar, quizAssessmentTestcss, getClassName, checkImage, getCheckBox } from './QuizAssessmentCommonutility';
import { StatusIndicator, QuizCards, AnswerSbmit, ObtainedMarks, StartPage, AnswerBlock, ShareSocialContent, ShareBlock } from "./QuizAssesmentsUtility.web"


const QuestionRender = ({ classes, value = null, handleChange, handleNext, question: { id: qId, attributes }, submit, onSubmit, questions, index }) => {
  const hasSubmit = submit[qId];
  const { question: qTitle, options, correct_answer, image } = attributes;
  return (
    <>
      <Grid
        item
        direction="column"
        className={classes.qGrid}
      >
        <AnswerBlock {...{ classes, options, hasSubmit, correct_answer, value, image, qTitle, qId, isQuiz: false, getClassName, getCheckBox, checkImage, handleChange }} />
        <Grid>
          <AnswerSbmit {...{ classes, hasSubmit, correct_answer, options, isQuiz: false, value, qId, handleNext, notEmpty, onSubmit, getAsciiToChar }} />
        </Grid>
      </Grid>
      <Grid item>
        {isEmpty(value) && questions.length > 1 && index < questions.length - 1 &&
          <>
            <Grid item xs={11}>
              <Typography data-testId="handleNext" onClick={() => handleNext()} className={classes.skipBtn}>
                Skip Question
              </Typography>
            </Grid>
            <Grid item xs={1}>
            </Grid>
          </>}
      </Grid>
    </>
  );
};

export const ResultPage = ({ classes, retryQuiz, gotoQuiz, userQuizData, quizResultData, viewAllAnswers, shareResult, image }) => {
  if (isEmpty(quizResultData)) return null;
  const { obtained_marks, total_marks, assessment: { description } } = quizResultData?.attributes;
  return (
    <div className={classes.startRoot}>
      <Grid container justify="center">
        <Paper className={classes.paper}>
          <ObtainedMarks {...{ classes, image, obtained_marks, total_marks, description, isQuiz: false, viewAllAnswers }} />
          <ShareBlock {...{
            classes,
            isQuiz: false,
            shareResult,
            retryQuiz
          }} />
        </Paper>
        <QuizCards {...{ classes, gotoQuiz, userQuizData, isQuiz: false, quizAssessmentAttrName: "assessment" }} />
      </Grid>
    </div>
  );
}
export class AssessmentTestWeb extends AssessmentTestControllerWeb {
  categoryData: any;
  constructor(props: Props) {
    super(props);
  }
  timer: NodeJS.Timeout;
  //@ts-ignore
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getAssessmentData();
  }

  handleNext = () => {
    const { currentPage, quizData } = this.state;
    const questions = quizData?.attributes?.test_questions?.data || [];
    if (questions.length === currentPage + 1) {
      this.finishQuiz();
    } else {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  setCurrentPage = (i: number) => this.setState({ currentPage: i });
  handleChange = (e: any, qid: any) => {
    const { values } = this.state;
    const updatedValues = { ...values, [qid]: e.target.value };
    this.setState({ values: updatedValues });
  };

  onSubmit = (id: any) => {
    const { submit, values } = this.state;
    this.saveAssessment({ questionId: id, optionId: values[id] });
    this.setState({ submit: { ...submit, [id]: true } });
  }
  retryQuiz = () => this.setState({ showNow: 'start', currentPage: 0, values: {}, submit: {} });
  closeShareSocialDialog = () => this.setState({ show: false });
  manageTimer = () => {
    const { quizTimeOut } = this.state;
    if (quizTimeOut === 0) {
      this.finishQuiz();
      clearInterval(this.timer);
    } else {
      this.setState({ quizTimeOut: quizTimeOut - 1 });
    }
  };

  startQuiz = () => {
    if (localStorage.getItem('token')) {
      this.postStartAssessment();
      const { timer } = this.state?.quizData?.attributes;
      this.setState({ showNow: 'quiz', currentPage: 0, values: {}, submit: {}, quizTimeOut: timer * 60 });
      this.timer = setInterval(this.manageTimer, 1000);
    } else {
      this.props.openToastHandler("error", `Please login to start assessment`!);
    }
  };

  viewAllAnswers = () => {
    this.getAssessmentAnswers()
  }

  finishQuiz = () => {
    this.getAssessmentResult();
    this.getUserAssessmentData();
    this.setState({ showNow: 'result' });
    clearInterval(this.timer);
  };

  gotoQuiz = (qId: any) => {
    this.props.history.push(`/home/assessment/${qId}`);
    location.reload();
  };
  shareResult = () => this.setState({ show: true });

  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { handleNext, handleChange, setCurrentPage, retryQuiz, gotoQuiz, onSubmit, startQuiz, viewAllAnswers, shareResult, closeShareSocialDialog } = self;
    const { showNow = 'start', values = {}, submit = {}, currentPage, quizData, quizTimeOut = 0, userQuizData, quizResultData, assessmentAnswers } = this.state;
    const { test_questions, description, heading, image } = quizData?.attributes || {};
    const Assessmentquestions = test_questions?.data || [];
    return (
      <>
        <Container className={classes.container} maxWidth="xl">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                xs={12}
                lg={12}
                xl={12}
                alignItems="center"
                className={classes.uParallax}
                justify="center"
              >
                {showNow === 'start' && <StartPage  {...{ classes, description, heading, startQuiz, image, isQuiz: false }} />}
                {showNow === 'result' && <ResultPage data-testId="ResultPage"  {...{ classes, retryQuiz, gotoQuiz, userQuizData, quizResultData, viewAllAnswers, shareResult, image }} />}
                {showNow === 'quiz' &&
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="center"
                    className={classes.topContent}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <Grid className={classes.content}>
                        <Paper className={classes.quizPaper}>
                          {Assessmentquestions && Assessmentquestions.length > 0 &&
                            <>
                              <StatusIndicator
                                {...{
                                  classes,
                                  questions: Assessmentquestions,
                                  setCurrentPage,
                                  values,
                                  submit,
                                  quizTimeOut,
                                  secondsToTime
                                }}
                              />
                              <Carousel
                                className={classes.carouselDots}
                                autoPlay={false}
                                useKeyboardArrows={false}
                                infiniteLoop={false}
                                showStatus={false}
                                showIndicators={false}
                                showThumbs={false}
                                swipeable={false}
                                showArrows={false}
                                selectedItem={currentPage}
                              >
                                {Assessmentquestions.map((question, index) => (
                                  <div className={classes.qContent} key={'Assessmentquestions_' + question.id}>
                                    <QuestionRender
                                      data-testId="QuestionRender"
                                      {...{
                                        classes,
                                        handleNext,
                                        handleChange,
                                        value: values[question.id],
                                        question,
                                        submit,
                                        onSubmit,
                                        questions: Assessmentquestions,
                                        index
                                      }}
                                    />
                                  </div>
                                ))}
                              </Carousel>
                            </>
                          }
                          {!(Assessmentquestions && Assessmentquestions.length > 0) &&
                            <Grid container direction="column" alignItems="center" justify="flex-start">
                              <Grid item>
                                <Typography variant="body2" className="text-center">There are no questions available for this assessment</Typography>
                              </Grid>
                            </Grid>}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Assessment Questions"}
              <Button onClick={() => { this.setState({ open: false }) }} className={classes.close} autoFocus>
                Close
              </Button>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {assessmentAnswers && assessmentAnswers.length > 0 && assessmentAnswers.map((ele: any, i: any) =>
                  <Grid item xs={12} key={ele.id}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${i + 1}.${ele?.attributes?.question}?`}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                      {ele?.attributes?.correct_answer?.answer}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.show}
            fullWidth={true}
            maxWidth={'xs'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ShareSocialContent {...{ classes, isQuiz: false, closeShareDialog: closeShareSocialDialog }} />
          </Dialog>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const AssessmentTestWebbWithRouter = withRouter(AssessmentTestWeb);
const AssessmentTestWebWithLoader = withLoader(AssessmentTestWebbWithRouter);
const AssessmentTestWebToast = withSnackbar(AssessmentTestWebWithLoader);
const AssessmentTestWebWithStyle = withStyles((theme) =>
  createStyles({
    ...quizAssessmentTestcss(theme),
    quizStartBtn: {
      height: '2rem',
      marginTop: '2.5rem',
      alignSelf: 'center',
      borderRadius: 5,
      backgroundColor: '#f48b1b',
      color: "#ffffff !important",
      position: 'absolute',
      bottom: '11px',
      "&:hover": {
        backgroundColor: "#f48b1b !important",
        color: "#ffffff !important",
      },
    },
    viewall: {
      color: "#F48B1A !important",
      "&:hover": {
        color: "#F48B1A !important",
      },
    },
    close: {
      float: 'right',
      color: '#f48b1b',
      "&:hover": {
        color: "#f48b1b !important",
      },
    },
    marks: {
      color: "#000",
      marginRight: '4px'
    },
    shareretry: {
      marginTop: '1rem'
    },
    shareCourse: {
      padding: '2rem',
      backgroundColor: '#f9f5f6'
    },
    socialMediaButton: {
      height: '30px',
      width: '30px',
      margin: 'auto'
    },
    correctAns: {
      fontWeight: "bold",
      marginTop: "1rem"
    },
    answerDes: {
      textAlign: "initial", marginTop: "1rem"
    },
    imageDiv: {
      alignSelf: 'center'
    },
    quizCardroot: {
      marginBottom: '2rem',
      maxWidth: '80%'
    },
    result: {
      alignSelf: 'center'
    },
    sharecontent: {
      overflow: 'hidden'
    },
    socialBtn: {
      marginRight: '1rem'
    },
    iconclose: {
      float: 'right',
      top: '-6px',
    }
  })
)(AssessmentTestWebToast);
export default withTranslation()(AssessmentTestWebWithStyle);
