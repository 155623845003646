import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End

export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    location: any;
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  expertProfile: any;
  totalPage: number;
  currentPage: number;
  filterdata: any;
  contentType: any;
  queryobj: any;
  searchinfo: any;
  allBookmarklist: any;
  profileId: any;
  follow: any
  // days: any;
  selectedType: any;
  sliderValue: any[],
  ratingValue: any;
  queryPath: any;
  categoryselected: boolean
  categorynotselected: boolean,
  loader: boolean,
  open: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CareerExpertViewAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getExpertsProfilesApiCallId: string = "";
  followApiCallId: string = "";
  unBookNowApiCallId: string = "";
  onSubmitApiCallId: string = "";
  BookmarkedExpertApiCallId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  quizData: any;
  popularBlogs: any;
  getCategorylistId: any;
  addProfileApiCallId: any;
  followAllApiCallId: any;
  getFilterOptionsId: any;
  getExpertsProfilesCatApiCallId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      expertProfile: [],
      totalPage: 1,
      currentPage: 1,
      contentType: [],
      allBookmarklist: [],
      profileId: [],
      follow: [],
      filterdata: [
        {
          "type": "Content Type",
          "key": "content_type",
          "id": 0,
          "data": [],
          "value": [],
        },
        {
          "type": "Category",
          "key": "category",
          "id": 1,
          "data": [],
          "value": [],
        },
        {
          "type": "Rating",
          "key": "rating",
          "data": [],
          "id": 2,
          "value": []
        },
        {
          "type": "Price",
          "key": "price",
          "fromPrice": "",
          "toPrice": "",
          "id": 3,
          "data": [],
          "value": [],
        }

      ],
      queryobj: {
        '0': 'content_type',
        '1': 'category[]',
        '2': 'rating',
        '3': 'price',
        '4': 'Date',
      },
      searchinfo: '',
      selectedType: 1,
      sliderValue: [0, 0],
      ratingValue: null,
      queryPath: '',
      categoryselected: false,
      categorynotselected: false,
      loader: false,
      open: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getExpertsProfilesApiCallId:
            this.handleExpertProfileApiResponce(responseJson);
            break;
          case this.followApiCallId:
            this.handleFollowApiResponce(responseJson);
            break;
          case this.unBookNowApiCallId:
            this.handleUnFollowApiResponce(responseJson);
            break;
          case this.BookmarkedExpertApiCallId:
            this.handleBookmarkApiResponce(responseJson);
            break;
          case this.addBookmarkApiCallId:
            this.handleAddBookmarkApiResponce(responseJson);
            break;
          case this.removeBookmarkApiCallId:
            this.handleRemoveBookmarkApiResponce(responseJson);
            break;
          case this.getCategorylistId:
            this.handlegetCategoryListApiResponce(responseJson);
            break;
          case this.getFilterOptionsId:
            this.handleGetFilterApiResponce(responseJson);
            break;
          case this.getExpertsProfilesCatApiCallId:
            this.handleGetExpertProfileCatApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by�builder
      }
    }
  }
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)

  };
  handleExpertProfileApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ expertProfile: responseJson.data, totalPage: responseJson.total_pages }, () => { this.setState({ loader: false }) });
    }
  };
  handleFollowApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (this.state.categoryselected === true) {
        this.getExpertsProfilesCtegory(this.state.queryPath)
      } else {
        this.getExpertsProfiles(this.state.queryPath)
      }
    } else if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      this.props.openToastHandler("error", responseJson.errors);

    }
  };
  handleUnFollowApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.message) {
      if (this.state.categorynotselected === true) {
        this.getExpertsProfilesCtegory(this.state.queryPath)
      } else {
        this.getExpertsProfiles(this.state.queryPath)
      }
    } else if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      this.props.openToastHandler("error", responseJson.errors);
    }
  };
  handleBookmarkApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ allBookmarklist: responseJson?.data });
    }
  };
  handleAddBookmarkApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.getAllBookmarkedExperts();
    }
  };
  handleRemoveBookmarkApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.success) {
      this.getAllBookmarkedExperts();
    }
  };
  handlegetCategoryListApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let data = this.state.filterdata
      data[2].data = responseJson.data
      this.setState({
        filterdata: data,
      });
    }
  };
  handleGetFilterApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        info[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: info });
    }
  };
  handleGetExpertProfileCatApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ expertProfile: responseJson.data, totalPage: responseJson.total_pages });
    }
  };
  getExpertsProfilesCtegory = (searchInfo?: any) => {
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getExpertsProfilesCatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}?page=${this.state.currentPage}&per_page=10${searchInfo}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getExpertsProfiles = (searchInfo?: any) => {
    const token = localStorage.getItem("token")
    this.setState({ loader: true })
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getExpertsProfilesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let url = `${configJSON.getExperts}?page=${this.state.currentPage}&per_page=10&${searchInfo}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  follow = (id: any) => {
    this.props.showLoader();
    const token = localStorage.getItem('token');
    if (token) {
      let headers: any = {
        "Content-Type": configJSON.validationApiContentType

      };
      headers['token'] = token
      const data = {
        // "bookmarkable_id": id,
        // "bookmarkable_type": "job"
      }
      const apiEndPoint = `${configJSON.getExperts}/${id}/book_or_follow?mode=Follow`;
      const methodType = configJSON.exampleAPiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.followApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } else {
      this.props.openToastHandler("error", `please login again!`);
    }
  }
  unfollow = (id: any) => {
    this.props.showLoader();
    const token = localStorage.getItem('token');
    if (token) {
      let headers: any = {
        "Content-Type": configJSON.validationApiContentType
      };
      headers.token = token
      const data = {
        // "bookmarkable_id": id,
        // "bookmarkable_type": "job"
      }
      const apiEndPoint = `${configJSON.getAccountExperts}/${id}?mode=Follow`;
      const methodType = configJSON.exampleDeleteAPiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.unBookNowApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.props.openToastHandler("error", `please login again!`);
    }
  }

  getCategories = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiEndPoint = `${configJSON.categoriesAPIEndPoint}?show_on_landing_page=true`;
    const methodType = configJSON.validationApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategorylistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getContentsBysearchCriteria = (filterdata: any) => {
    let query = ''
    filterdata?.forEach((ele: any) => {
      if (ele.id == 0 && ele.value.length > 0) {
        let contentTypeList: any[] = [];
        ele.value.forEach((e: any, i: any) => {
          let data = ele.data.filter((el: any) => el.id == e);
          contentTypeList.push(data[0].attributes.identifier)
        })
        query += `&${this.state.queryobj[ele.id]}=` + contentTypeList
      }
      if (ele.id == 1 && ele.value.length > 0) {
        ele.value.forEach((e: any, i: any) => {
          query += `&${this.state.queryobj[ele.id]}=` + e
        })
      }

      if (ele.id == 3 && ele.fromPrice && ele.toPrice) {
        query += `&price_min=${ele.fromPrice}&price_max=${ele.toPrice}`
      }
    })
    if (this.state.ratingValue) {
      query += '&rating=' + this.state.ratingValue
    }
    if (this.state.searchinfo) {
      query += '&search=' + this.state.searchinfo
    }
    this.setState({ queryPath: query })
    this.getExpertsProfiles(query)
  }
  getAllBookmarkedExperts = () => {
    this.props.showLoader();
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const apiEndPoint = `${configJSON.getBookmarkExpertUrl}`;
    const methodType = configJSON.validationApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.BookmarkedExpertApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addBookmark = (id: any, event: any) => {
    this.props.showLoader();
    event.stopPropagation()
    const token = localStorage.getItem('token');
    if (token) {
      const headers = {
        "token": token,
        "Content-Type": configJSON.validationApiContentType
      }
      const data = {
        "bookmarkable_id": id,
        "bookmarkable_type": "expert"
      }
      const apiEndPoint = configJSON.addBookmarkUrl;
      const methodType = configJSON.exampleAPiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addBookmarkApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    else {
      this.props.openToastHandler("warning", 'Please login to mark this course as bookmark'!);
    }
  }
  removeBookmark = (id: any, event: any) => {
    this.props.showLoader();
    event.stopPropagation()
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.validationApiContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "expert"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const methodType = configJSON.exampleAPiMethod;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getFilterOptions = (filterby: any, filterinfo: any) => {
    let query = ''
    filterinfo?.forEach((ele: any) => {
      if (ele.value.length > 0 && filterby != ele.key) {
        ele.value.forEach((e: any, i: any) => {
          query += `&${this.state.queryobj[ele.id]}=` + e
        })
      }
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=career_expert${query}`;
    const methodType = configJSON.validationApiMethodType;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterOptionsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}