//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export const configJSON = require("./config");

export type Props = RouterProps &
    StyledProps & {
        // Customizable Area Start
        showLoader: () => {};
        hideLoader: () => {};
        openToastHandler: (type: string, message: string) => {};
        // Customizable Area End
    };

interface S {
    // Customizable Area Start
    activityFeedLogList: [],
    totalPage: number,
    currentPage: number
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
let addActivityId: string = "";
// Customizable Area End


export default class ActivityFeedLogController extends BlockComponent<
    Props,
    S,
    SS
> {
    getActivityId: string = "";
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
        this.receive = this.receive.bind(this);
        this.state = {
            activityFeedLogList: [],
            totalPage: 1,
            currentPage: 1,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                  getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson && responseJson.data && !responseJson.errors) {
                if (apiRequestCallId === this.getActivityId) {
                    this.props.hideLoader();
                    this.setState({ activityFeedLogList: responseJson?.data, totalPage: responseJson.total_pages ?? 1 });
                }
            } else if (apiRequestCallId && responseJson && !responseJson.errors) {
                if (apiRequestCallId === addActivityId) {
                    this.getActivityFeedLog();
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getActivityFeedLog = () => {
        const token = localStorage.getItem("token");
        if (token) {
            this.props.showLoader();
            const header = {
                "Content-Type": "application/json",
                "token": token ?? "",
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getActivityId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.getActivityFeedLog}?page=${this.state?.currentPage}&per_page=10`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            this.props.openToastHandler("error", "Please Login First!")
        }
    }

    onPageChange = (event, page) => {
        this.setState({ currentPage: page }, () => {
            this.getActivityFeedLog();
        })
    }
    // Customizable Area End
}

// Customizable Area Start
export const addActivityFeedLog = (moduleName: string, actionName: string, moduleId: number) => {
    const token = localStorage.getItem("token");
    if (token) {
        const header = {
            "Content-Type": "application/json",
            "token": token ?? "",
        };
        const apiBodyData = {
            data: {
                "module_name": moduleName,
                "action": actionName,
                "module_id": moduleId
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        addActivityId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addActivityFeedLog
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(apiBodyData)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    return true;
}
// Customizable Area End
