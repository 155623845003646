//@ts-nocheck
//@ts-ignore
import React from 'react';
import { View, Text, FlatList, Image, StyleSheet } from 'react-native';
import { blue, Bold, grey_text_color, orange_title_text, size_14pt, white } from '../../Utils';
import ThemeColorContext from '../../../../../components/src/themeColorManagement/themeColorContext';
import LocationCard from '../cards/LocationCard';
import LocationController from '../Helper/LocationController';
import { chromaPhobicBlack_transparentcolor, OrangeVampireGreyColor } from '../Helper/common';


interface Props {
    title: any;
    locations?: any;
    globalTag:any;
    selectedLocations?: any;
    onLocationChanged: (id: any, type: any, name: any) => void;
}
interface s {
    selectedIds: any
}

let controller: any;
export default class Location extends React.Component<Props, s> {
    constructor(props: Props) {
        super(props);
        controller = new LocationController();
        this.state = {
            selectedIds: []
        }
    }

    txtColor = (item: any) => {
        if (controller.isSelected(item.id, this.props.selectedLocations) != -1) return blue
        else return 'rgb(123,123,123)'
    }

    viewBGColor = (item: any, themeColorContext: any) => {
        if (controller.isSelected(item.id, this.props.selectedLocations) != -1) return white
        else return chromaPhobicBlack_transparentcolor(themeColorContext)
    }

    viewBorderColor = (item: any, themeColorContext: any) => {
        if (controller.isSelected(item.id, this.props.selectedLocations) != -1) return 'rgb(108,89,214)'
        else return chromaPhobicBlack_transparentcolor(themeColorContext)
    }

    locationNameView = (item: any) => {
        return item.id === -1 
            ? <Text style={[styles.location_name, {color: this.txtColor(item)}]}>{item.attributes?.name}</Text>
            : <View>
                <Image resizeMode='cover'
                    source={item?.attributes?.logo ? { uri: item?.attributes?.logo } : require('../../../assets/Mumbai_inactive.png')}
                    style={[styles.images, { tintColor: item?.attributes?.logo ? 'transprent' : this.txtColor(item) }]}
                />
                <Text style={[styles.location_name, {color: this.txtColor(item)}]}>{item.attributes?.name}</Text>
                <Text style={[styles.location_count, {color: this.txtColor(item)}]}>{`(${item?.attributes?.count==null?item?.attributes?.search_count:item?.attributes?.count})`}</Text>
            </View>
    }

    render() {
       
        return (
            <ThemeColorContext.Consumer>
                {(themeColorContext) => (
                    <>
                        <View>
                            <Text style={{ color: OrangeVampireGreyColor(themeColorContext), fontSize: size_14pt, fontFamily: Bold }}>{this.props.title}</Text>
                            <FlatList
                                data={this.props.locations}
                                horizontal
                                extraData={
                                    this.state.selectedIds    // for multiple items
                                }
                                showsHorizontalScrollIndicator={false}
                                renderItem={({ item }: { item: any }) => {
                                    return <LocationCard activeTheme={themeColorContext.themeColor}
                                        onPress={() => {
                                            this.props.onLocationChanged(item.id, 'location', item.attributes?.name)
                                            if (item?.attributes?.cities) {
                                                item?.attributes?.cities?.data?.map((i: any) => {
                                                    this.props.onLocationChanged(i.id, 'city', i?.attributes?.name)
                                                });
                                            }
                                        }}
                                        >
                                        <View style={[styles.viewStyle,{
                                            backgroundColor: this.viewBGColor(item, themeColorContext), 
                                            borderColor: this.viewBorderColor(item, themeColorContext)
                                        }]}>
                                            {this.locationNameView(item)}
                                        </View>
                                    </LocationCard>
                                }}
                            />
                        </View>
                    </>
                )}
            </ThemeColorContext.Consumer>
        );
    }
}

const styles = StyleSheet.create({
  images: { width: 36, height: 36, resizeMode: 'center', alignSelf: 'center' },
  location_name: {
    alignSelf: 'center',
    fontSize: 9,
    color: blue,
    lineHeight: 10,
    fontFamily: Bold,
    marginTop: 5
  },
  location_count: {
    alignSelf: 'center',
    fontSize: 7,
    lineHeight: 8,
    fontFamily: Bold,
    color: blue
  },
  viewStyle: {
    width: 79,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: 79,
    borderRadius: 10,
    borderWidth: 1
  }
})
