//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
// Customizable Area Start
export type Props = {
  id: string;
  navigation: any;
  ParentCCallback: any;
  openToastHandler: any;
};
// Customizable Area End
interface S {
  // Customizable Area Start
  otpCode: string;
  otpAuthToken?: string;
  type?: string;
  phone?: string;
  showToast: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  counter: number;
  showLoader: any;
  loading: boolean;
  phoneNumber: string;
  email: string;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ConfirmOtpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // inputPhoneAPICallId: string;
  phoneEmailAPICallId: string = "";
  confirmOtpApiCallId: string = "";
  resendAPICallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      otpCode: "",
      showToast: {
        show: false,
      },
      type: 'signup' || 'login' || 'forgot',
      counter: 30,
      showLoader: false,
      phone: '',
      loading: false,
      phoneNumber: '',
      email: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }//@ts-ignore
  timer: NodeJS.Timeout;
  async componentDidMount() {
    this.resendOtpCallBack();
    let tempNo = localStorage.getItem("phoneNumber") || "";
    tempNo = tempNo.replace(/\d(?=\d{4})/g, "*");
    // tempNo.slice(0, 2) + tempNo.slice(2).replace(/.(?=...)/g, '*');
    this.setState({ phoneNumber: tempNo });
    const tempEmail = localStorage.getItem("email") || "";
    this.setState({ email: tempEmail });
  }
  async componentWillUnmount() {
    clearInterval(this.timer);
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  }
  manageTimer = () => {
    var states = this.state;
    if (states.counter === 0) {
      clearInterval(this.timer);
    } else {
      this.setState({
        counter: this.state.counter - 1,
      });
    }
  };
  resendOtpCallBack = () => {
    //@ts-ignore
    this.timer = setInterval(this.manageTimer, 1000);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.resendAPICallId:
          this.resendBlock(responseJson);
          break;
        case this.confirmOtpApiCallId:
          this.confirmOtpBlock(responseJson);
          break;
        case this.phoneEmailAPICallId:
          this.phoneEmailBlock(responseJson);
          break;
        default: this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ loading: false });
          break;
      }
      // Customizable Area End
    }
  }
  phoneEmailBlock = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      //@ts-ignore
      this.props.showLoader();
      this.props.openToastHandler("success", "Successfully Logged in !!", 2000)
      localStorage.setItem("token", responseJson.meta.token);
      setTimeout(() => {
        if (localStorage.getItem("token")) {
          //@ts-ignore
          this.props.history.push(`${this.props.history.location.pathname}`)
          setTimeout(() => {
            this.props.ParentCCallback()
          }, 2000);
        }
      }, 2000)
    }
    else if (responseJson.errors && responseJson.errors[0].full_phone_number) {
      this.props.openToastHandler("error", responseJson.errors[0].full_phone_number);
      setTimeout(() => {
        //@ts-ignore
        this.props.history.replace("/");
      }, 2000);
    }
    else if (!responseJson.errors[0].email && responseJson.errors[2].full_phone_number) {
      this.props.openToastHandler("error", responseJson.errors[2].full_phone_number);
      setTimeout(() => {
        //@ts-ignore
        this.props.history.replace("/");
      }, 2000);
    }
    else if (responseJson && responseJson.errors[0].email) {
      this.props.openToastHandler("error", responseJson.errors[0].email);
      setTimeout(() => {
        //@ts-ignore
        this.props.history.replace("/");
      }, 2000);
    }
  }
  confirmOtpBlock = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.meta?.message) {
      localStorage.setItem("token", responseJson.meta.token);
      localStorage.setItem("isActive", responseJson.data.attributes.activated);
      setTimeout(() => {
        // @ts-ignore
        this.phoneEmailLogin();
      }, 1000);
    } else if (responseJson.errors && responseJson?.errors[0].phone) {
      this.props.openToastHandler("error", responseJson.errors[0].phone);
      setTimeout(() => {
        // @ts-ignore
        this.props.history.push(`${this.props.history.location.pathname}`)
      }, 1000);
      this.setState({ loading: false })
    }
    else if (responseJson.errors && responseJson.errors[0].pin) {
      this.props.openToastHandler("error", responseJson.errors[0].pin);
      this.setState({ loading: false })
    } else {
      this.parseApiErrorResponse(responseJson);
      this.setState({ loading: false });
    }
  }
  resendBlock = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.meta && responseJson.meta.token) {
      localStorage.setItem('otp_token', responseJson.meta.token);
      this.props.openToastHandler("success", 'OTP Sent Successfully');
      this.resendOtpCallBack();
    } else if (responseJson.errors) {
      this.props.openToastHandler("error", responseJson.errors[0].otp);
    } else {
      this.parseApiErrorResponse(responseJson);
      this.setState({ loading: false });
    }
  }
  //phone login
  phoneEmailLogin = () => {
    this.setState({ loading: true });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      'content-type': configJSON.apiContentType
    };
    let httpBody = {};
    // this.props.showLoader();
    const token = localStorage.getItem("token");
    const lang = localStorage.getItem("lang") || "en"
    if (localStorage.getItem("email")) {
      httpBody = {
        "token": token,
        "language": lang,
        "data": {
          "type": "email_account"
        }
      }
    }
    else {
      httpBody =
      {
        "token": token,
        "language": lang,
        "data": {
          "type": "sms_account"
        }
      }
    }
    this.phoneEmailAPICallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.accountsAPiEndPoint}` + `language=${lang}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //handle verify otp
  handleVerifyOtp = async () => {
    localStorage.setItem("confirmDialog", "true");
    const headers = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const data = {
      pin: this.state.otpCode,
      token: localStorage.getItem("otp_token"),
    };
    let apiEndPoint = "";
    const email = localStorage.getItem("email")
    if (email?.includes('@')) {
      apiEndPoint = configJSON.apiEmailVerifyOtpEndPoint;
    } else {
      var lang = localStorage.getItem("lang") || 'en';
      apiEndPoint = configJSON.apiVerifyOtpEndPoint + `language=${lang}`;
    }
    const methodType = configJSON.apiVerifyOtpMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.confirmOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //Resend Otp 
  resendOTP = () => {
    this.setState({ loading: true });
    // this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      'content-type': configJSON.apiVerifyOtpContentType
    };
    const phoneNumber = localStorage.getItem('phoneNumber');
    const email = localStorage.getItem('email');
    let httpBody = {}
    if (email?.includes('@')) {
      httpBody = {
        "data": {
          "attributes": {
            "email": email
          }
        }
      };
    } else {
      httpBody = {
        "data": {
          "attributes": {
            "full_phone_number": `${91}` + phoneNumber
          }
        }
      }
    }
    const lang = localStorage.getItem("lang") || 'en';
    this.setState({ otpCode: '', counter: 30 });
    this.resendAPICallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.resendOtpWebAPIEndPoint}` + `language=${lang}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiVerifyOtpMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
