//@ts-ignore
//@ts-nocheck
import React from "react";
import { StyleSheet, View, TouchableOpacity, TextInput } from "react-native";
import { Icon } from "react-native-elements";
import ThemeColorContext from "./themeColorManagement/themeColorContext";
import { widthPercentageToDP as WIDTH, heightPercentageToDP as HEIGHT } from "react-native-responsive-screen";

interface myProps {
  onChange:(text:any)=>void;
  customTitle?: string;
  text:any;
}

export default function SearchUIInternal(props: myProps) {
  return (
    <ThemeColorContext.Consumer>
      {(themeColorContext) => (
        <>
          <TouchableOpacity
            style={{ paddingVertical: 1, justifyContent: "center", alignItems: 'center' }}>
            <View style={[styles.searchView, {
              backgroundColor: themeColorContext.themeColor == "dark"
                ? "rgba(112,112,112,0.2)"
                : "#fcfcfe",
              borderColor: themeColorContext.themeColor == "dark"
                ? "rgba(112,112,112,0.2)"
                : "rgb(112,112,112)",
            }]} >
              <TextInput
              onChangeText={(text:any)=>{
                  props.onChange(text);
              }}
                style={[styles.textLable, {
                  color: themeColorContext.themeColor == "dark"
                    ? "rgba(255,255,255,0.38)"
                    : "#aaa9ab",
                }
                ]}
                placeholderTextColor={themeColorContext.themeColor == "dark"
                ? "rgba(255,255,255,0.38)"
                : "#aaa9ab"}
                value={props.text}
                placeholder={"Looking for something specific?"}
                numberOfLines={1}/>
              <Icon
                name="search"
                type="fontisto"
                size={HEIGHT(3)}
                color={themeColorContext.themeColor == "dark" ? "#fff" : "#000"}
              />
            </View>
          </TouchableOpacity>
        </>
      )}
    </ThemeColorContext.Consumer>
  );
}


const styles = StyleSheet.create({
  swiperViewStyle: {
    paddingHorizontal: 14,
    paddingTop: 14, paddingBottom: 25
  },

  swiperImgStyle: {
    width: "100%",
    height: "100%",
    borderRadius: 12,
  },

  searchView: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: WIDTH(90),
    alignItems: "center",
    borderRadius: 51,
    borderWidth: 1,
    height:45,
    paddingHorizontal: HEIGHT(2),
    overflow: 'hidden'
  },

  textLable: {
    fontSize: HEIGHT(1.9),
    fontFamily: 'Roboto',
    width: '90%'
  }
});