//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import ContactUsController, { Props } from './ContactusController.web';
import withLoader from "../../../components/src/withLoader.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
export const ContactUsSchema = Yup.object().shape({
  name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').max(25, "Name cannot exceed more than 25 characters").required("*Name is Required"),
  email: Yup.string().matches(/^(([^<>#()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Enter Valid Email").required("*Email is Required"),
  message: Yup.string().required("*Message is Required"),
});
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d9d9d9"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-input": {
      color: "#b2b2b2"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#b2b2b2"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#b2b2b2"
    },
    "& .MuiInputLabel-outlined": {
      color: "#b2b2b2"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#b2b2b2"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#b2b2b2"
    }
  },
})(TextField);
export class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props)
  };
  //@ts-ignore
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (<>
      <Container maxWidth="xl" className={classes.contactroot}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={1}
        >
          <Grid item xs="12" sm={6} >
            <Typography variant="h3" display="block" className={classes.contact} align="center" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="caption" display="block" className={classes.contact} align="center" gutterBottom>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s  standard dummy text ever since the 1500s, when an unknown printer took.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.contactform} >
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          <Grid item xs="12" sm={6} >
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: ""
              }}
              validationSchema={ContactUsSchema}
              onSubmit={(values: any) => {
                this.contactUs(values)
              }}>
              {(formikProps) => {
                const {
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                } = formikProps;
                return (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container direction="column" justifyContent="space-around" spacing={2}>
                      <Grid item>
                        <PinkTextField id="outlined-basic" label="Name"
                          variant="outlined"
                          name="name"
                          defaultValue="none"
                          onChange={handleChange}
                          FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                          onBlur={handleBlur}
                          error={touched.name && errors.name ? true : false}
                          helperText={
                            touched.name && errors.name ? errors.name : ""
                          }
                          value={values.name}
                          placeholder="Name" />
                      </Grid>
                      <Grid item>
                        <PinkTextField id="outlined-basic1" label="Email"
                          variant="outlined"
                          name="email"
                          defaultValue="none"
                          onChange={handleChange}
                          FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                          onBlur={handleBlur}
                          error={touched.email && errors.email ? true : false}
                          helperText={
                            touched.email && errors.email ? errors.email : ""
                          }
                          value={values.email}
                          placeholder="Enter your Email" />
                      </Grid>
                      <Grid item>
                        <PinkTextField
                          multiline
                          rows={6}
                          variant="outlined"
                          name="message"
                          onChange={handleChange}
                          InputProps={{ classes: { input: classes.txtProps } }}
                          FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                          onBlur={handleBlur}
                          error={touched.message && errors.message ? true : false}
                          helperText={
                            touched.message && errors.message ? errors.message : ""
                          }
                          value={values.message}
                          placeholder="Enter your message here"
                        />
                      </Grid>
                      <Grid item xs="12">
                        <Button variant="contained" type="submit" className={classes.publishBtn} fullWidth>
                          Send Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </>
    );
  }
}
//@ts-ignore
const ContactUsRouter = withRouter(ContactUs);
const ContactUsWebWithLoader = withLoader(ContactUsRouter);
const ContactUsWebToast = withSnackbar(ContactUsWebWithLoader);
const ContactUsWebWithStyle = withStyles((theme) =>
  createStyles({
    contactroot: {
      backgroundColor: 'rgb(61, 47, 47)',
      paddingTop: '5rem',
      paddingBottom: '1rem'
    },
    contact: {
      color: '#ffffff'
    },
    contactform: {
      paddingTop: "2rem",
      paddingBottom: '4rem'
    },
    publishBtn: {
      backgroundColor: '#fa6400',
      color: '#ffffff',
      borderRadius: '25px',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    }
  })
)(ContactUsWebToast);
export default withTranslation()(ContactUsWebWithStyle);
