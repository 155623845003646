import React from "react";
import {
  Typography,
  Chip
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import CategoriessubcategoriesWebController, {
  Props,
} from "./CategoriessubcategoriesController.web";

export class CategoryList extends CategoriessubcategoriesWebController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getAllSubCategories();
    this.showCategory(this.props.match?.params?.id);
  }

  render() {
    const { showCategoryData } = this.state;
    return (
      <div className="flex items-center flex-wrap mt-4">
        <Typography>{showCategoryData.attributes?.name}'s Sub Categories</Typography>
        {showCategoryData?.attributes?.sub_categories.map(subCategory => (
          <div className="px-2 py-2" key={String(subCategory.id)}>
            <Chip color="primary" label={subCategory.name} />
          </div>
        ))}
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(CategoryList);
