import * as Yup from "yup";
// Validation Schema for create account
export const DiscussionforumSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid title')
    .max(25, "Title cannot exceed more than 25 characters").required("*Title is Required"),
  description: Yup.string().min(100, "Atleast 100 characters are required").required("*Description is Required").nullable(),
  categories: Yup.string().required("Categories Required!")
});
export const DiscussionforumReportSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid title')
    .max(25, "Title cannot exceed more than 25 characters").required("*Title is Required"),
  description: Yup.string().nullable(),
  categories: Yup.string().required("Type of Report is Required!")
});