//@ts-nocheck
import React from "react";
import {
    withStyles,
    Grid,
    createStyles,
    Container,
    Typography,
    InputBase,
    Hidden,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { courseImg, play } from "./assets";
import insane from 'insane';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from "@material-ui/icons/Search";
import CarrerExpertVideosViewAllPageController from "./CarrerExpertVideosViewAllController.web";

const Title = ({ caption, classes }) => {
    return (
        <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
            <Hidden smDown> <Typography align="center" variant="h4" className={classes.titleColor}>{caption}</Typography></Hidden>
            <Hidden mdUp> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
        </Grid>);
};

const RenderVideos = ({ classes, ViewAllItems }) => {
    return (
        <Grid container direction="row" alignItems="center" justify="center" className={classes.RenderVideos}>
            <Grid item xs={12} sm={11} md={9} lg={10} xl={10} className={classes.RenderVideos}>
                <Grid container direction="row" spacing={4} >
                    {ViewAllItems && ViewAllItems.length > 0 && ViewAllItems.map((e: any) => (
                        <Grid item key={e.id} lg={4} md={6} sm={6} xs={12}>
                            <Grid container direction="column" alignItems="center" spacing={2}>
                                <Grid item>
                                    {e.attributes.video ?
                                        <div className="relative ">
                                            <video
                                                autoPlay={true}
                                                muted
                                                loop
                                                className={classes.fvPlayer}
                                                key={e.attributes.video} >
                                                <source src={e.attributes.video}
                                                    type="video/mp4" />
                                            </video>
                                            <div className={classes.overlay}>
                                                <img alt="playvideo" className={classes.play} src={play} />
                                            </div>
                                        </div>
                                        :
                                        <Typography variant="body1">No video </Typography>}
                                </Grid>
                                <Grid item className={classes.nameinfo}>
                                    <Typography variant="h3">{e.attributes.name || e.attributes.headline}</Typography>
                                </Grid>
                                <Grid item className={classes.nameinfo}>
                                    {e.attributes.description.length > 100 ?
                                        <div>
                                            <Typography variant="subtitle2" color="primary" className={classes.description}>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                                                }}></span>
                                            </Typography></div>
                                        : <div className="mt-1.5"> <Typography variant="subtitle2" color="primary" className={classes.description} >
                                            <span dangerouslySetInnerHTML={{
                                                __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                                            }}></span>
                                        </Typography></div>}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                    }
                </Grid>
                {ViewAllItems && ViewAllItems.length === 0 &&
                    <Grid item>
                        <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}
//@ts-ignore
export class CarrerExpertVideosViewAllWeb extends CarrerExpertVideosViewAllPageController {
    async componentDidMount() {
        window.scrollTo(0, 0, { behavior: 'smooth' });
        this.loadData()
    }

    onPageChange = (e, page) => {
        this.setState({ currentPage: page }, () => {
            this.loadData();
        });
    };
    setSearchdata = (e: any) => {
        this.setState({ searchinfo: e.target?.value }, () => {
            this.loadData(this.state.searchinfo);
        })
    }
    render() {
        //@ts-ignore
        const self = this
        const { classes } = this.props
        const { onPageChange, setSearchdata } = self
        const { currentPage = 1, totalPage = 1, featureVideos, expertVideos } = this.state;
        let ViewAllItems = [];
        let { pathname } = this.props.location;
        if (pathname?.includes('videosviewAll')) {
            ViewAllItems = featureVideos
        } else if (pathname?.includes('expertVideosviewall')) {
            ViewAllItems = expertVideos?.attributes?.content_videos
        }

        return (
            <>
                <Grid
                    item
                    className={classes.imgBg}
                    xs={12}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.search}>
                        <Grid item xs={1}>
                            <SearchIcon color="secondary" />
                        </Grid>
                        <Grid item xs={11}>
                            <InputBase
                                placeholder="Search Here Experts, Articles, Exam, Courses"
                                data-testId="setSearchdata"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={setSearchdata}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Container className={classes.container}>
                    <Title {...{ caption: this.props?.location?.state?.caption, classes }} />
                    <RenderVideos data-testId="RenderVideos" {...{ classes, ViewAllItems, type: 'videos', }} />
                    {totalPage > 1 && <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
                        <Pagination page={currentPage} data-testId="onPageChange" onChange={onPageChange} count={totalPage} variant="outlined" shape="rounded" />
                    </Grid>}
                </Container>
            </>
        );
    }
}
//@ts-ignore
const CarrerExpertVideosViewAllWebWithRouter = withRouter(CarrerExpertVideosViewAllWeb);
const CarrerExpertVideosViewAllWebWithLoader = withLoader(CarrerExpertVideosViewAllWebWithRouter);
const CarrerExpertVideosViewAllWebToast = withSnackbar(CarrerExpertVideosViewAllWebWithLoader);
const CarrerExpertVideosViewAllWebWithStyle = withStyles((theme) =>
    createStyles({
        container: {
            marginTop: '3rem'
        },
        search: {
            width: "44rem",
            height: "3.5rem",
            borderRadius: "1.5rem",
            webkitBackdropFilter: "blur(50px)",
            backdropFilter: "blur(50px)",
            backgroundColor: "rgba(207, 207, 207, 0.13)",
            marginTop: "0rem",
            paddingLeft: "1rem",
            [theme.breakpoints.down("md")]: {
                width: '22rem'
            }

        },
        inputRoot: {
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                width: 'max-content'
            }
        },
        imgBg: {
            backgroundImage: `url(${courseImg})`,
            width: "100%",
            height: "505px",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexFlow: "column wrap",
            alignItems: "center",
            backgroundSize: "cover",
            justifyContent: "center",
            backgroundPosition: "center",
            marginTop: "-0.25rem"
        },

        paddingExamArVi: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
            paddingBottom: '2rem',
            [theme.breakpoints.down("xs")]: {
                paddingLeft: '0rem',
                paddingRight: '0rem',

            },
        },

        fvPlayer: {
            height: '16rem',
            display: 'block',
            width: '18rem',
            opacity: '0.51',
            borderRadius: '10px',
            objectFit: 'fill',
            backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
            backgroundAttachment: 'fixed',
        },
        overlay: {
            position: 'absolute',
            top: '6rem',
            bottom: '0',
            left: '8rem',
            right: '0',
            height: '100%',
            width: '100%',
        },

        nameinfo: {
            width: '18rem',
        },

        RenderVideos: {
            marginBottom: '2rem'
        },
        description: {
            display: 'inline'
        },

        paginator: {
            marginTop: '2rem',
            marginBottom: '4rem',
            justifyContent: 'center',
        },


    })
)(CarrerExpertVideosViewAllWebToast);
export default withTranslation()(CarrerExpertVideosViewAllWebWithStyle);