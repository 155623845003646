class FilterSingleton {

    private static instance: FilterSingleton;
    private _globalFilterData: any;
    private constructor() { 
        
    }

    public static getInstance(): FilterSingleton {
        if (!FilterSingleton.instance) {
            FilterSingleton.instance = new FilterSingleton();
        }

        return FilterSingleton.instance;
    }
    public setGlobalFilterData(data: any) {
        this._globalFilterData = data
    }

    public getGlobalFilterData() {
        if (this._globalFilterData) {
            return this._globalFilterData;
        }
        return [];
    }
}
export default FilterSingleton;