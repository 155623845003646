//@ts-ignore
//@ts-nocheck

import React, { Component } from 'react'
import { View } from 'react-native'
import ThemeColorContext from './themeColorManagement/themeColorContext'
import SearchUI from './SearchUI';
import SearchUIInternal from './SearchUIInternal';
import { AppHeader } from '../../components/src/AppHeader';
interface Props {
  title?: any;
  doNotification?: any;
  dofilter?: any;
  isFromExplore?: any;
  userProfilePic?: any;
  profilePicFromGmail?: any;
  NavigateToMyprofileList?: any;
  // navigateToFilter?: any;
  NavigateToSearchScreen?: any;
  callStoreData?: any;
  callStoreDataCategory?: any;
  paddingForSearch?: any;
  doFilter?:boolean;
  gobackArrow:any;
  navigateToFilter:()=>void;
  searchTextValue?:any;
  doCustom?: boolean;
  customTitle?: string;
  navigateFrombackArrow:()=>void;
  searchText:(text:any)=>void;
  filterHighligter?:any;
  tab?:any;
  isFromFilterScreen?:any;
  doCareerText?:any;
}
export class StickyHeaderForExplores extends Component<Props> {
  render() {
    const { userProfilePic, profilePicFromGmail, title, doNotification,dofilter,
      isFromExplore,navigateToFilter,isFromFilterScreen,
      NavigateToMyprofileList, NavigateToSearchScreen, filterHighligter,tab,
      callStoreData, callStoreDataCategory,doFilter, doCareerText, doCustom, customTitle } = this.props
    return (
      <ThemeColorContext.Consumer>
        {(themeColorContext) => (
          <View style={{
            backgroundColor: themeColorContext.themeColor == "dark"
              ? "#242424"
              : "#fff",
              paddingStart:18,paddingEnd:18,paddingTop:20
          }}>
            <AppHeader
            title={title}
        // lefticonOrtext={"Explore"}
        onPress={this.props.navigateToFilter}
        isFromFilterScreen={isFromFilterScreen}
       // isFromFilterScreen
        //isFromFilterScreen={}
        navigateToProfile={NavigateToMyprofileList}   
        isFromExplore={true}
        profilePicUrl={userProfilePic}
        isHideNotificationIcon
        gobackArrow={this.props.gobackArrow}
        navigateFrombackArrow={()=>this.props.navigateFrombackArrow()}
        highliter={filterHighligter}
        tab={tab}
      />
            {/* <AppExploreHeader
              title={title}
              // lefticonOrtext={"Explore"}
              // onPress={this.NavigateToFilteritem}
              dofilter={dofilter}
              doNotification={doNotification}
              profilePicUrl={
                userProfilePic
                  ? userProfilePic
                  : profilePicFromGmail
              }
              navigateToProfile={NavigateToMyprofileList}
              navigateToFilter={()=>this.props.navigateToFilter()}
              isFromExplore={isFromExplore}
              navigateFrombackArrow={() => this.props.navigateFrombackArrow()}
              doCareerText={doCareerText}
            /> */}

            {this.props.title ==='Featured Schools'||this.props.title ==='Popular Schools'||this.props.title ==='By Location'||this.props.title ==='Featured Colleges'||this.props.title ==='Popular Colleges'||this.props.title ==='By Categories'?
            <SearchUIInternal
            text={this.props.searchTextValue}
            onChange={(text:any)=>this.props.searchText(text)}/>
            :
              <SearchUI onPress={NavigateToSearchScreen} doCustom={doCustom} customTitle={customTitle} />}


          </View>
        )}
      </ThemeColorContext.Consumer>
    )
  }
}

export default StickyHeaderForExplores
