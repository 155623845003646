import * as Yup from "yup";

// Validation Schema for create account
export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .max(25, "Name cannot exceed more than 25 characters").required("Name is required"),
  phone: Yup.string()
    .min(10, "Enter 10 digit phone no.")
    .max(10, "Enter 10 digit phone no.")
    .matches(/^(\+91-|\+91|0)?\d{10}$/, "Invalid phone number")
    .required("*Please enter mobile number").nullable(),
  city: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid city')
    .max(25, " City name cannot exceed more than 25 characters")
});
export const K12validationSchema = Yup.object().shape({
  selectBoard: Yup.string().required("Board is required!")
    .test('test-name', 'Board is required!',
      //@ts-ignore
      function (value) {
        if (value !== "none") {
          return true;
        }
        return false;
      }
    ),
  standards: Yup.string().required("Standards is required!")
    .test('test-name', 'Standards is required!',
      //@ts-ignore
      function (value) {
        if (value !== "none") {
          return true;
        }
        return false;
      }
    ),
});
export const HigherEducationvalidationSchema = Yup.object().shape({
  degree: Yup.string()
    .required("Degree is required")
    .test('test-name', 'Degree is required',
      //@ts-ignore
      function (value) {
        if (value !== "none") {
          return true;
        }
        return false;
      }
    ),
  courses: Yup.string()
    .required("Course is required!")
    .test('test-name', 'Course is required!',
      //@ts-ignore
      function (value) {
        if (value !== "none") {
          return true;
        }
        return false;
      }
    )
});
export const CompetitiveExamvalidationSchema = Yup.object().shape({
  selectEducationLevel: Yup.string().required("Education level required!"),
  board: Yup.string().when("selectEducationLevel", {
    is: val => {
      const tempVal = val !== "none" && val && JSON.parse(val);
      return tempVal && tempVal.attributes && tempVal.attributes.above_12 === false
    },
    then: Yup.string().required("Board is required!")
      .test('test-name', 'Board is required!',
        //@ts-ignore
        function (value) {
          if (value !== "none") {
            return true;
          }
          return false;
        }
      )
  }),
  standard: Yup.string().when("selectEducationLevel", {
    is: val => {
      const tempVal = val !== "none" && val && JSON.parse(val);
      return tempVal && tempVal.attributes && tempVal.attributes.above_12 === false
    },
    then: Yup.string().required("Standard is required!")
      .test('test-name', 'Standard is required!',
        //@ts-ignore
        function (value) {
          if (value !== "none") {
            return true;
          }
          return false;
        }
      )
  }),
  degrees: Yup.string().when("selectEducationLevel", {
    is: val => {
      const tempVal = val !== "none" && val && JSON.parse(val);
      return tempVal && tempVal.attributes && tempVal.attributes.above_12 === true
    },
    then: Yup.string().required("Degree is required!")
      .test('test-name', 'Degree is required!',
        //@ts-ignore
        function (value) {
          if (value !== "none") {
            return true;
          }
          return false;
        }
      )
  }),
  course: Yup.string().when("selectEducationLevel", {
    is: val => {
      const tempVal = val !== "none" && val && JSON.parse(val);
      return tempVal && tempVal.attributes && tempVal.attributes.above_12 === true
    },
    then: Yup.string().required("Course is required!")
      .test('test-name', 'Course is required!',
        //@ts-ignore
        function (value) {
          if (value !== "none") {
            return true;
          }
          return false;
        }
      )
  })
});
export const GovtExamvalidationSchema = Yup.object().shape({
  selectEducationLevel: Yup.string().required("Education level required!")
    .test('test-name', 'Education level is required!',
      //@ts-ignore
      function (value) {
        if (value !== "none") {
          return true;
        }
        return false;
      }
    )
});
  // email: Yup.string()
  //   .email("Email must be in valid format!")
  //   .required("*mandatory"),
  // password: Yup.string()
  //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must include min 8 char. with one upper case,number and special char.')
