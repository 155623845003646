import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  open: boolean;
  showTagSearch: boolean;
  tagsList: any[];
  categories: any[];
  anchorEl: any;
  anchorTrendingEl: any;
  anchorRecommendedEl: any;
  openDiscussionDialog: boolean;
  tagVal: any[],
  question: any,
  dislikeActive: boolean,
  dislike: any,
  like: any,
  likeActive: boolean,
  getPopularQuestions: any[],
  getRecommendedQuestions: any[],
  getTrendingQuestions: any[],
  likeList: any[],
  showLikeId: any[],
  discussionType: string,
  unload: boolean;
  openReportDialog: boolean;
  problemType: any[],
  problemBlock: boolean,
  selectedProblem: any,
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DiscussionForumController extends BlockComponent<Props, S, SS> {
  getTagListApiCallId: string = "";
  getLikeListApiCallId: string = "";
  getCategoriesApiCallId: string = "";
  postQuestionApiCallId: string = "";
  getPopularQuestionsApiCallId: string = "";
  getTrendingQuestionsApiCallId: string = "";
  getRecommendedQuestionsApiCallId: string = "";
  postLikeApiCallId: string = "";
  getShowLikeListApiCallId: string = "";
  postReportApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      anchorEl: null,
      anchorTrendingEl: null,
      anchorRecommendedEl: null,
      open: false,
      showTagSearch: false,
      tagsList: [],
      categories: [],
      openDiscussionDialog: false,
      tagVal: [],
      question: {},
      dislikeActive: false,
      dislike: 0,
      like: 0,
      likeActive: false,
      getPopularQuestions: [],
      getTrendingQuestions: [],
      getRecommendedQuestions: [],
      likeList: [],
      showLikeId: [],
      discussionType: '',
      unload: true,
      openReportDialog: false,
      problemType: ['Voilence', 'Harrasment', 'Self injury sucide', 'False imformation', 'spam', 'Hate speech', 'Sales and advertisment', 'terrorism', 'Something else'],
      problemBlock: false,
      selectedProblem: ''
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getTagListApiCallId:
          this.tagListUi(responseJson);
          break;
        case this.getCategoriesApiCallId:
          this.categoriesUi(responseJson);
          break;
        case this.getPopularQuestionsApiCallId:
          this.popularQues(responseJson);
          break;
        case this.getTrendingQuestionsApiCallId:
          this.trendingQues(responseJson);
          break;
        case this.getRecommendedQuestionsApiCallId:
          this.recommendedQues(responseJson);
          break;
        case this.postQuestionApiCallId:
          this.postQues(responseJson);
          break;
        case this.postLikeApiCallId:
          this.postLike(responseJson);
          break;
        case this.getLikeListApiCallId:
          this.likeList(responseJson);
          break;
        case this.postReportApiCallId:
          this.postReport(responseJson);
          break;
      }
    }
  }
  // Customizable Area Start
  tagListUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      const temp = responseJson?.data.map((e: any) => e?.attributes?.name);
      this.setState({ tagsList: temp });
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  categoriesUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ categories: responseJson.data });
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
    }
  }
  popularQues = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ getPopularQuestions: responseJson.data });
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
    }
  }
  trendingQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ getTrendingQuestions: responseJson.data });
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
    }
  }
  recommendedQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ getRecommendedQuestions: responseJson.data });
    } else if (responseJson && responseJson?.errors) {
      this.toastHandlerFun(responseJson);
    }
  }
  postQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ openDiscussionDialog: false });
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
    }
  }
  postLike = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.getLikes();
      this.setState({ unload: true });
      if (this.state.discussionType === 'Popular Discussion')
        this.getPopularQuestions();
      if (this.state.discussionType === 'Trending Discussion')
        this.getTrendingQuestions();
      if (this.state.discussionType === 'Recommended Discussion')
        this.getRecommendedQuestions();
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);

    }
  }
  likeList = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ likeList: responseJson?.data });
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  postReport = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ openReportDialog: false });
      this.props.openToastHandler("success", 'Report Submitted Successfully'!);
    } else if (responseJson?.errors) {
      this.toastHandlerFun(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  toastHandlerFun = (responseJson: any) => {
    if (responseJson?.errors[0]?.token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
  }

  getTagList = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTagListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTagApiUrl
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesAPIEndPoint
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleClose = () => {
    this.setState({ openDiscussionDialog: false })
  }
  openPostDialog = () => {
    this.setState({ openDiscussionDialog: true });
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        this.props.history.push("/dashboard/discussionforum");
        localStorage.clear();
      }
    }
  }
  postQuestion = (status: any) => {
    const headers = { "token": localStorage.getItem("token") || "" };
    const apiEndPoint = configJSON.postQuestion;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let formdata = new FormData();
    this.state.question?.title && formdata.append(`question[title]`, this.state.question.title);
    this.state.question?.categories && formdata.append(`question[sub_category_id]`, this.state.question.categories);
    this.state.question?.description && formdata.append(`question[description]`, this.state.question.description);
    formdata.append(`question[status]`, status);
    formdata.append(`question[closed]`, '0');
    this.state.question?.tagVals && formdata.append(`question[tag_list]`, this.state.question.tagVals.map((e: any) => e));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  createPost = (value: any) => {
    this.setState({ question: value });
    const token = localStorage.getItem('token');
    let status = "draft";
    if (token) {
      status = "publish";
      this.postQuestion(status);
    } else {
      this.openDialog(true);
    }
  }
  createReport = (value: any) => {
    const headers = { "token": localStorage.getItem("token") || "" };
    const apiEndPoint = configJSON.createReport;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postReportApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let formdata = new FormData();
    value?.title && formdata.append(`data[title]`, value?.title);
    value?.categories && formdata.append(`data[type_problem]`, value?.categories);
    value?.description && formdata.append(`data[description]`, value?.description);
    value?.tagVal?.forEach((ele: any) => {
      formdata.append(`data[images][]`, ele[0]);
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getPopularQuestions = (searchInfo?: any) => {
    this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getPopularQuestionsApiCallId = requestMessage.messageId;
    const search = searchInfo ? `&search=${searchInfo}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showPopularQuestionApiEndPoint}?is_popular=true&page=1&per_page=20${search}`
    );
    const header = {
      "token": localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTrendingQuestions = (searchInfo?: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTrendingQuestionsApiCallId = requestMessage.messageId;
    const search = searchInfo ? `&search=${searchInfo}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showPopularQuestionApiEndPoint}?is_trending=true&page=1&per_page=20${search}`
    );
    const header = {
      "token": localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getRecommendedQuestions = (searchInfo?: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getRecommendedQuestionsApiCallId = requestMessage.messageId;
    const search = searchInfo ? `&search=${searchInfo}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showPopularQuestionApiEndPoint}?is_recommended=true&page=1&per_page=20${search}`
    );
    const header = {
      "token": localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getLikes = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getLikeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLikePost
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDisLike = (id: any, unlike: any, type: any) => {
    this.handleLike(id, unlike, type);
  }
  handleLike = (id: any, like: any, type: any) => {
    if (localStorage.getItem("token")) {
      this.setState({ discussionType: type })
      const headers = {
        "token": localStorage.getItem("token"),
        "Content-Type": configJSON.validationApiContentType
      };
      const apiEndPoint = configJSON.postLikesApiEndPoint;
      const methodType = configJSON.exampleAPiMethod;
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.postLikeApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      const data = {
        "like": {
          "likeable_type": "question",
          "likeable_id": id,
          "is_like": like
        }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    else {
      this.openDialog(true)
    }
  }
  selectProblemType = (prob: any) => {
    this.setState({ problemBlock: true, selectedProblem: prob })
  }
  // Customizable Area End
}