Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.methodTypeApiUpdateUserProfile = "PUT";
exports.formDataApiContentType = "multipart/form-data";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.updateAPiMethod = "PUT";
exports.deleteAPiMethod = "DELETE";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";
exports.getProfile = "bx_block_profile/profiles/id?language=en";
exports.freeTrail = "bx_block_contentmanagement/courses/free_trail";
exports.socialLogin = "bx_block_login/social_login?language=en";
exports.socialAppleLogin =
  "bx_block_login/social_login/apple_login_callback?language=en";
exports.sendOTP = "account/accounts/send_otp?language=en";
exports.confirmationPhone = "account/accounts/sms_confirmation?language=en";
exports.confirmationEmail = "/account_block/accounts/email_confirmations";
exports.loginWithPhone = "login/login?language=en";
exports.feedAPI = "bx_block_communityforum/questions";
exports.likeAPI = "bx_block_communityforum/likes";
exports.dislikeAPI = "bx_block_communityforum/likes/delete_like";
exports.draftedAPI = "bx_block_communityforum/questions/drafted_questions";
exports.publishedAPI = "bx_block_communityforum/questions/published_questions";
exports.getDetails = "bx_block_communityforum/questions/details";
exports.getSubCategories = "bx_block_categories/sub_categories?language=en";
exports.createQuestion = "bx_block_communityforum/questions";
exports.showQuestion = "bx_block_communityforum/questions/";
exports.commentOnQuestion = "bx_block_communityforum/comments";
exports.deleteUserQuestion = "bx_block_communityforum/questions/";
exports.searchDiscussion = "bx_block_communityforum/questions";
exports.bannerAPIEndPoint = "bx_block_contentmanagement/banners";
exports.getProfile = "bx_block_profile/profiles/id?language=en";
exports.getFeaturedSchools = "/bx_block_profile/schools";
exports.getSearchSchools = "/bx_block_profile/schools";
exports.getSearchCollages = "/bx_block_profile/colleges";
exports.getLocation = "/bx_block_profile/locations";
exports.getCategories = "/bx_block_profile/specializations";
exports.getFeaturedColleges = "/bx_block_profile/colleges";
exports.getLocationDetails = "/bx_block_profile/locations";
exports.getLanguages = "/bx_block_languageoptions/languages";
exports.getClasses = "/bx_block_profile/standards";
exports.getTotalFees = "/bx_block_profile/schools/total_fees_list";
exports.getSpecilization = "/bx_block_profile/specializations";
exports.getCourses = "/bx_block_contentmanagement/courses";
exports.searchSchoolsColleges =
  "/bx_block_profile/colleges/college_school_search";
exports.dynamicFilterApiEndPoint =
  "/bx_block_filter_items/content_management/filters";
exports.reports = "bx_block_contentmanagement/reports";
exports.getTagApiUrl = "bx_block_contentmanagement/tags";
exports.categoriesAPIEndPoint = "bx_block_categories/categories";
exports.postQuestion = "bx_block_communityforum/questions";
exports.showPopularQuestionApiEndPoint = "bx_block_communityforum/questions";
exports.commentApiEndPoint = "bx_block_communityforum/comments";
exports.questionDetailApiEndPoint = "bx_block_communityforum/questions/details";
exports.getDraftApiEndPoint =
  "bx_block_communityforum/questions/drafted_questions";
exports.createReport = "bx_block_contentmanagement/reports";
exports.btnExampleTitle = "CLICK ME";
exports.getLikePost = "bx_block_communityforum/likes";
exports.showLikePost = "bx_block_communityforum/likes";
exports.showQuestionApiEndPoint = "bx_block_communityforum/questions";
exports.showMyThreadsApiEndPoint =
  "bx_block_communityforum/questions/my_questions";
exports.postLikesApiEndPoint = "bx_block_communityforum/likes";
// Customizable Area End
