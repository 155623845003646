export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const check = require("../assets/Check.png");
export const expert = require("../assets/CareerExpert.png");
export const Star = require("../assets/Star.png");
export const volicity =  require("../assets/volicity-9.png");
export const eye = require("../assets/eye.png");
export const Group  = require("../assets/Group.png");
export const share =  require("../assets/share.png");
export const placeholder =  require("../assets/placeholder_audiovideo.png");
export const filter =  require("../assets/filter.png");
export const purple_filter =  require("../assets/purple_filter_icon.png");
export const dark_filter =  require("../assets/dark_filter.png");
export const placeholderArticle =  require("../assets/Videos.png");
export const expertTabUser =  require("../assets/expertUser.png");
export const courseImg = require("../assets/cousres-all.png");
export const dummyImage = require("../assets/image.png");
export const play = require("../assets/play.png");
export const collegeImg = require("../assets/college.jpeg");
export const schoolImg = require("../assets/school.png");
export const pearlImg = require("../assets/pearl-logo.png");
export const amityImg = require("../assets/amity.jpg");
export const qsImg = require("../assets/qs.png");
export const globalImg = require("../assets/global.jpg");
export const next = require("../assets/next.png");
export const prev = require("../assets/prev.png");
export const hyderabad = require("../assets/hyderabad.png");
export const degreeImg = require("../assets/manage.png");
export const fbImg = require("../assets/facebook.png");
export const linkedInImg = require("../assets/Linkedin.png");
export const logo = require("../assets/logo.png");
export const requestImgWhite = require("../assets/request_dark.png");
export const requestImg = require("../assets/request_Icon.png");
