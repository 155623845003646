import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
import i18n from 'i18next';
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/Utility";

export type Props = RouterProps &
	StyledProps & {
		id: string;
		showAlert: any;
		showLoader: any;
		hideLoader: any;
		showToast: any;
		// Customizable Area Start
		// Customizable Area End
	};

interface S {
	// Customizable Area Start
	left: boolean;
	loading: boolean;
	showToast: {
		show: boolean;
		message?: string;
		type?: any;
	};
	appLangList:[]
	// Customizable Area End
}

interface SS {
	id: any;
}
export default class LanguageOptionController extends BlockComponent<Props, S, SS> {
	selectLangugeApiCallId: string="";
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		this.state = {
			left: true,
			loading: false,
			showToast: {
				show: false,
				message: '',
				type: "success" || "info" || "warning" || "error"
			},
			appLangList:[]
		};
		this.subScribedMessages = [
			// Customizable Area Start
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.AlertMessage)
		];
		const logged = localStorage.getItem("isActive");
		if (logged === "true") {
			window.addEventListener("popstate", e => {
				this.props.history.go(1);
			});
		}
		// Customizable Area End
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		this.getAppLanguage();
	}

	async receive(from: string, message: Message) {
		if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
			const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
			let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
			if (apiRequestCallId === this.selectLangugeApiCallId &&
				responseJson !== undefined) {
				if (responseJson?.data) {
					this.setState({ appLangList: responseJson?.data });
				} else if (responseJson.errors && responseJson.errors[0].token) {
					console.log("************", responseJson)
				}
			} else {
				this.parseApiErrorResponse(responseJson);
			}
		}
	};
	getAppLanguage = () => {
			const headers = {
			"Content-Type": configJSON.validationApiContentType,
		  };
		this.selectLangugeApiCallId = sendAPIRequest(configJSON.selectLanguage,
		{ headers: headers })
	}
	
	changeLanguage = (code: any) => {
		i18n.changeLanguage(code.attributes.language_code);
	};

	// Open View Details Modal
	openToastHandler = (
		type: "success" | "info" | "warning" | "error" | undefined,
		message: string
	): void => {
		console.log("type,message", type, message);
		this.setState({
			showToast: {
				show: true,
				message: message,
				type,
			},
		});
	};

	// Close View Details Modal
	closeToastHandler = () => {
		this.setState({
			showToast: {
				show: false,
				message: this.state.showToast.message,
				type: this.state.showToast.type,
			},
		});
	};
	// Customizable Area End
}
