//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Avatar,
  Button,
  createStyles,
  Container,
  Typography,
  IconButton
} from "@material-ui/core";
import AboutUsController, { Props } from './AboutUsController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { cylinder, helix, rectangle, team, teambase, } from "./assets";
import EmailAccountLoginBlockWeb from "../../email-account-login/src/EmailAccountLoginBlock.web";

//@ts-ignore
export class AboutUsWeb extends AboutUsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getAboutUsDetails();
    this.getLeadersDetails()
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }


  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { aboutUs, aboutUsContent, getLeaders } = this.state
    const talentedSection = aboutUsContent[0]
    const missionSection = aboutUsContent[1]
    const goalSection = aboutUsContent[2]
    const whySection = aboutUsContent[3]
    const whoSection = aboutUsContent[4]

    return (
      <>
        <Container maxWidth='xl' className={classes.root}>
          <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.aboutUs} >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.aboutUsGrid}>
              <Grid container direction="column" >
                <Typography variant="h1" className={classes.title}>{talentedSection?.attributes?.title}</Typography>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {talentedSection?.attributes?.description}
                </Typography>
              </Grid>
              {!localStorage.getItem('token') && <Button contained size="large" onClick={this.openDialog.bind(this)} className={classes.StartBtn}>Get Started</Button>}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid item className={classes.team}>
                  <img src={team} className={classes.teamImg} />
                </Grid>
                <Grid item>
                  <img src={teambase} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </Container> */}
          {/* orange section */}
          <Grid item xs={12} className={classes.orange}>
            <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.orangetxt} >
              <Grid item xs={12} className={classes.OrangeGrid}>
                <Typography className={classes.txtOrange}>{aboutUs.user_count}<br />
                  <Typography align='center'>Learners</Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.OrangeGrid}>
                <Typography className={classes.txtOrange}>{aboutUs.courses_count}+<br />
                  <Typography align='center'>Courses</Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.OrangeGrid}>
                <Typography className={classes.txtOrange}>{aboutUs.category_count}+<br />
                  <Typography align='center'>Categories</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* layout */}
          <Container maxWidth='xl'>
            <Grid container direction='row' >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.layout}>
                <Grid container direction='column' alignContent="flex-start" className={classes.goalSec} >
                  <Typography variant="h1" className={classes.missionTxt}>{missionSection?.attributes?.title}</Typography>
                  <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1" className={classes.subtitleMissionTxt}>
                      {missionSection?.attributes?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.imageLayout}>
                <Grid container direction="row" spacing={1} className={classes.rowImagesGrid}>
                  <Grid item xs={8} >
                    <img src={missionSection?.attributes?.images[0]?.url} className={classes.rowImages} />
                  </Grid>
                  <Grid item xs={4}>
                    <img src={missionSection?.attributes?.images[1]?.url} className={classes.rowImages} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img src={missionSection?.attributes?.images[2]?.url} className={classes.columnImages} />
                </Grid>
              </Grid>
            </Grid>
            {/* second Layout */} 
            <Grid container direction='row' >
              {
                goalSection?.attributes?.images !== null ?
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.imageLayout}>
                <Grid container direction="row" spacing={1} className={classes.rowImagesGrid}>
                  <Grid item xs={8} >
                    <img src={goalSection?.attributes?.images[0]?.url} className={classes.rowImages} />
                  </Grid>
                  <Grid item xs={4}>
                    <img src={goalSection?.attributes?.images[1]?.url} className={classes.rowImages} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img src={goalSection?.attributes?.images[2]?.url} className={classes.columnImages} />
                </Grid>
              </Grid>:null 
              }
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.layout}>
                <Grid container direction='column' alignContent="flex-end" className={classes.goalSec}>
                  <Typography variant='h1' className={classes.missionTxt} >{goalSection?.attributes?.title}</Typography>
                  <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1" className={classes.subtitleMissionTxt}>
                      {goalSection?.attributes?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          {/* carrer Hunt Section */}
          <Container maxWidth='md'>
            <Grid>
              <Grid item xs={12} className={classes.carrerHuntGrid}>
                <Typography variant="h2" align='center' className={classes.carrerHunt}>{whySection?.attributes?.title}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={6} justify="space-evenly" style={{ marginTop: '2rem' }}>
                {whySection?.attributes?.sections?.map((_data, index) => {
                  return (
                    <Grid item xs={12} sm={4} key={index}>
                      <img src={_data?.data?.attributes?.images[0]?.url} className={classes.carrerHuntImg} />
                      <Typography className={classes.carrerHuntTxt} align='center'>{_data?.data?.attributes?.title}</Typography>
                      <Typography align='center' className={classes.carrerHuntSubTxt}>{_data?.data?.attributes?.description}</Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            {/* josh section */}
            <Grid container>
              <Grid item xs={12} className={classes.joshGrid}  >
                <Typography variant="h2" className={classes.whoSection}>{whoSection?.attributes?.title}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="baseline"  >        
              {whoSection?.attributes?.sections?.map((_data, index) => {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <Grid item>
                      <img src={_data?.data?.attributes?.images[0]?.url} className={classes.joshDailyHuntImg} />
                      <Typography className={classes.joshDailyTxt}>
                        {_data?.data?.attributes?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
            {/* profile Section */}
            <Grid container justify="center" className={classes.profile}>
              <Grid container direction="column" justify="center">
                <Typography variant="h2" align="center" className={classes.meetour}>Meet Our Leaders</Typography>
                <Typography align="center">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</Typography>
              </Grid>
              <Grid container justify="center" item xs={12} sm={12} spacing={2}  >
                {getLeaders.map((_data: any, index: any) => {
                  return <Grid item xs={12} sm={3} key={index}>
                    <Avatar src={_data?.attributes?.images[2]?.url} className={classes.Avatar} />
                    <Typography align='center' className={classes.ProfileName} variant="body2">{_data?.attributes?.name}</Typography>
                    <Typography align='center'>{_data?.attributes?.position}</Typography>
                    <Typography align="center" >
                      <IconButton>
                        <img src={_data?.attributes?.images[1]?.url} className={classes.small} />
                      </IconButton>
                      <IconButton>
                        <img src={_data?.attributes?.images[0]?.url} className={classes.small} />
                      </IconButton>
                    </Typography>
                  </Grid>
                })}
              </Grid>
              <Typography align="center" className={classes.viewTeam}>View The Team</Typography>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={10} className={classes.Rectangle}>
                <img src={rectangle} className={classes.RectangleSize} />
                <Typography className={classes.Rectangletext}>Start Learning For Free
                  <Typography className={classes.RectangleSubtext}>Access over 500+ free courses from 10+ categories for free.</Typography>
                  {!localStorage.getItem('token') && <Button className={classes.RectangleBtn} onClick={this.openDialog.bind(this)}>Sign Up</Button>}
                </Typography>
                <Grid item>
                  <img src={helix} className={classes.helix} />
                </Grid>
                <Grid item>
                  <img src={cylinder} className={classes.cylinder} />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <EmailAccountLoginBlockWeb ParentCallback={this.state.open} openDialog={this.openDialog} />
      </>
    );
  }
}
//@ts-ignore
const AboutUsWebWithRouter = withRouter(AboutUsWeb);
const AboutUsWebWithLoader = withLoader(AboutUsWebWithRouter);
const AboutUsWebToast = withSnackbar(AboutUsWebWithLoader);
const AboutUsWebWithStyle = withStyles((theme) =>
  createStyles({
    root: {
      padding: '0px'
    },
    aboutUs: {
      marginTop: '8rem'
    },
    aboutUsGrid: {
      paddingLeft: '5rem',
      paddingTop: '3rem'
    },
    title: {
      color: '#3b4b75',
    },
    talentedTeam: {
      color: '#3b4b75',
      textAlign: 'left',
      fontSize: '30px'
    },
    team: {
      paddingRight: '2rem',
      position: 'relative'
    },
    teamImg: {
      marginBottom: '-1.8rem'
    },
    subtitle: {
      marginTop: '0.7rem',
      width: '80%'
    },
    StartBtn: {
      margin: '55px 0 171px 0 ',
      background: '#f8593b',
      color: '#ffffff',
      '&:hover': {
        background: '#f8593b !important',
        color: '#ffffff !important'
      }
    },
    orange: {
      background: '#f8593b',
      height: '8rem',
      marginBottom: '4rem'
    },
    orangetxt: {
      height: "100%",
    },
    txtOrange: {
      textAlign: "center",
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    OrangeGrid: {
      flexBasis: '0px'
    },
    layout: {
      padding: '5rem rem 5rem 5rem',
      [theme.breakpoints.down('lg')]: {
        padding: '2rem 2rem 2rem 2rem',
      }
    },
    imageLayout: {
      padding: '5rem 2rem 5rem 5rem',
      [theme.breakpoints.down('lg')]: {
        padding: '2rem 2rem 2rem 2rem',
      }
    },
    rowImages: {
      width: '100%',
      height: '200px'
    },
    columnImages: {
      paddingRight: '2rem',
      paddingLeft: '2rem',
      width: '100%',
      height: '250px',
      marginTop: '0.6rem'
    },
    goal: {
      marginTop: '3rem'
    },
    missionSection: {
      marginTop: '8rem',
      marginBottom: '1.5rem',
    },
    missionTxt: {
      color: '#000',
    },
    subtitleMissionTxt: {
      marginTop: '0.7rem',
      fontSize: "0.7rem",
    },
    goalSec: {
      [theme.breakpoints.down('md')]: {
        alignContent: 'center'
      },
    },
    joshGrid: {
      marginTop: '6rem'
    },
    whoSection: {
      color: '#000000',
      textAlign: 'center'
    },
    joshDailyTxt: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#000000',
      marginTop: '1rem'
    },
    carrerHuntGrid: {
      marginTop: '6rem'
    },
    carrerHunt: {
      color: '#000000'
    },
    carrerHuntImg: {
      margin: 'auto',
    },
    carrerHuntTxt: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      marginTop: '1rem'
    },
    carrerHuntSubTxt: {
      fontSize: '0.7rem',
    },
    joshDailyHuntImg: {
      margin: 'auto',
      height: '250px'
    },
    meetour: {
      color: '#000',
      fontWeight: 'bold'
    },
    profile: {
      marginTop: '6rem'
    },
    Avatar: {
      width: '8rem',
      margin: 'auto',
      height: '8rem',
      marginTop: '1.8rem',
    },
    viewTeam: {
      color: '#000',
      fontWeight: 'bold',
      fontSize: '0.8rem',
      padding: '2rem 0rem 5rem 0rem'
    },
    Rectangle: {
      position: 'relative',
      marginBottom: '300px',
      margin: 'auto'
    },
    RectangleSize: {
      width: '100%'
    },
    Rectangletext: {
      color: "white",
      position: 'absolute',
      fontWeight: 'bold',
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.up('lg')]: {
        top: "20%",
        fontSize: '1.5rem',
      },
      [theme.breakpoints.only('md')]: {
        top: '20%'
      },
      [theme.breakpoints.only('sm')]: {
        top: '20%',
      },
      [theme.breakpoints.only('xs')]: {
        top: '20%',
        fontSize: '1rem'
      }
    },
    RectangleBtn: {
      background: '#f8593b',
      color: '#ffffff',
      '&:hover': {
        background: '#f8593b !important',
        color: '#ffffff !important',
        borderRadius: '4px',
      },
      [theme.breakpoints.up('lg')]: {
        width: "255px",
        height: '48px',
        marginTop: '2rem',
      },
      [theme.breakpoints.only('md')]: {
        width: "255px",
        height: '48px',
        marginTop: '2rem',
      },
      [theme.breakpoints.only('sm')]: {
        width: '150px',
        height: '30px',
        marginTop: '1rem'
      },
      [theme.breakpoints.only('xs')]: {
        width: '150px',
        height: '25px',
        marginTop: '1rem'
      },
    },
    helix: {
      position: 'absolute',
      [theme.breakpoints.up('lg')]: {
        top: '-49px',
        left: '-277px',
      },
      [theme.breakpoints.only('md')]: {
        top: '-1rem',
        left: '-11rem',
        width: '300px',
        height: '300px'
      },
      [theme.breakpoints.only('sm')]: {
        top: '2rem',
        left: '-7rem',
        width: '180px',
        height: '180px'
      },
      [theme.breakpoints.only('xs')]: {
        top: '1rem',
        left: '-4rem',
        width: '100px',
        height: '100px'
      }
    },
    cylinder: {
      position: 'absolute',
      [theme.breakpoints.up('lg')]: {
        right: '-176px',
        bottom: '-211px',
        position: 'absolute'
      },
      [theme.breakpoints.only('md')]: {
        top: '9rem',
        right: '-7rem',
        width: '250px',
        height: '250px'
      },
      [theme.breakpoints.only('sm')]: {
        top: '5rem',
        right: '-5rem',
        width: '180px',
        height: '180px',
      },
      [theme.breakpoints.only('xs')]: {
        top: '4rem',
        right: '-3rem',
        width: '100px',
        height: '100px',
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    ProfileName: {
      padding: '0.5rem',
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    rowImagesGrid: {
      paddingRight: '2rem',
      paddingLeft: '2rem'
    }
  })
)(AboutUsWebToast);
export default withTranslation()(AboutUsWebWithStyle);
