//@ts-nocheck
import React from "react";
import {
  Grid,
  Typography,
  Button,
  Hidden,
  InputBase,
  Radio,
  withStyles,
  Tooltip,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Paper
} from "@material-ui/core";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { next, prev, fbImg, linkedInImg } from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";
import { green, red } from "@material-ui/core/colors";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { FacebookShareButton, LinkedinShareButton } from "react-share";

export const Title = ({ type, caption, classes, handleViewAll, quizList }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
      <Hidden smDown> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
      <Hidden mdUp> <Typography align="center" variant="h3">{caption}</Typography></Hidden>
      {quizList && quizList.length > 4 &&
        <Button
          data-testId="handleViewAll"
          className={classes.viewAll}
          color="primary"
          onClick={() => handleViewAll(type)}
          variant="outlined">
          <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
          <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
        </Button>
      }
    </Grid>);
};

export const RowTextContainer = ({ classes, text1, text2 }) => {
  return (
    <Grid container direction="row" justify="space-between" className={classes.cardItem} >
      <Typography className={classes.quizText}>{text1}</Typography>
      <Typography className={classes.quizText}>{text2}</Typography>
    </Grid>
  );
};

export const CategorysBlock = ({ classes, categoriesData, selectedChip, handleChipsClick }) => {
  return (
    <Grid item xs={12} sm={9} >
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12} className={`overflow-x-auto whitespace-nowrap ${classes.category}`}>
          <ScrollMenu
            clickWhenDrag={true}
            dragging={true}
            hideArrows={true}
            hideSingleArrow={true}
            scrollToSelected={true}
            alignCenter={false}
            transition={0.3}
            translate={0}
            wheel={false}
            arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
            arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
            //@ts-ignore
            data={categoriesData && categoriesData.length > 0 && categoriesData.map((item: any) =>
              <React.Fragment key={'categoriesData_' + item?.id}>
                <Button key={item.id}
                  className={selectedChip === item.id ? `${classes.selectedBtn}` : `${classes.catBtn}`}
                  color="primary"
                  onClick={() => handleChipsClick(item)}
                  variant="outlined"
                >
                  <Typography variant="subtitle2" className={classes.btnTypo}>{item?.attributes?.name}</Typography>
                </Button>
              </React.Fragment>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const QuizAssessmentSearchBlock = ({ classes, setSearcdata, placeholderetext }) => {
  return (
    <Grid item xs={12} sm={6} >
      <Grid container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">
        <Hidden smDown>
          <QuizAssessmentSearch {...{
            classes,
            isSmDown: true, setSearcdata, placeholderetext
          }} />
        </Hidden>
        <Hidden mdUp>
          <QuizAssessmentSearch {...{
            classes,
            isSmDown: false, setSearcdata, placeholderetext
          }} />
        </Hidden>
      </Grid>
    </Grid>
  )
}
export const QuizAssessmentSearch = ({ classes, isSmDown, setSearcdata, placeholderetext }) => {
  return (
    <Grid container direction="row" alignItems='center' className={isSmDown ? classes.search : classes.searchSm}>
      <Grid item xs={1}>
        <SearchIcon color="secondary" />
      </Grid>
      <Grid item xs={11}>
        <InputBase
          placeholder={placeholderetext}
          data-testId="setSearcdata"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setSearcdata(e)}
        />
      </Grid>
    </Grid>
  )
}

export const HeaderTitle = ({ caption, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.titleroot}>
      <Hidden smDown> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
      <Hidden mdUp> <Typography align="center" variant="h3">{caption}</Typography></Hidden>
    </Grid>);
};

export const ScrollableQuiz = ({ classes, gotoPage, quizList, isLogin, isQuiz, textdata }) => {
  return (
    <Grid container xs={12} className={classes.paddingExamArVi}>
      <Grid container className={classes.paddingExamArVi}>
        <Grid item xs={12} className="w-full">
          <ScrollMenu
            clickWhenDrag={true}
            dragging={true}
            hideArrows={true}
            hideSingleArrow={true}
            scrollToSelected={true}
            alignCenter={false}
            transition={0.3}
            translate={0}
            wheel={false}
            arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
            arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
            data={quizList &&
              quizList.map((e: any, index: number) => (
                <Grid item key={index} >
                  <Grid className={classes.quizBox} container direction="column" alignItems="center" justify="space-between">
                    <Grid className={classes.quizBox2} container direction="column" justify="space-between">
                      {e.attributes.heading && <Typography className={classes.quizHeading}>{e.attributes.heading}</Typography>}
                      {isLogin() &&
                        <>
                          <RowTextContainer classes={classes} text1="Attempts" text2={e?.attributes?.attempt_count || 0} />
                        </>}
                      <RowTextContainer classes={classes} text1="Questions" text2={e?.attributes?.test_questions?.data?.length || 0} />
                      {isQuiz && <RowTextContainer classes={classes} text1="Time" text2={`${e?.attributes?.timer.indexOf(':') == -1 ? e?.attributes?.timer : parseInt(e?.attributes?.timer.split(':')[0] * 60) + parseInt(e?.attributes?.timer.split(':')[1])} mins`} />
                      }
                      {!isQuiz && <RowTextContainer classes={classes} text1="Time" text2={`${e.attributes.timer || 0} mins`} />}
                    </Grid>
                    <Grid item className={isQuiz ? classes.gridone : classes.startassessment}>
                      <Button
                        className={classes.quizBtn}
                        onClick={() => gotoPage(e.id)}
                      >
                        <Typography className={classes.quizBtnTxt} variant="body2">Start {textdata}</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            } />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {!(quizList && quizList.length > 0) &&
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item>
            <Typography variant="body2" className="text-center">Result not found</Typography>
          </Grid>
        </Grid>}
    </Grid>);
};

export const GreenRadio = withStyles({
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
//@ts-ignore
export const RedRadio = withStyles({
  root: {
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const StatusIndicator = ({ classes, questions, setCurrentPage, values, submit, quizTimeOut, secondsToTime }) => {
  const getClassNames = ({ id, correct_answer }, i) => {
    const hasSubmit = submit[id];
    let className = classes.dot;
    if (hasSubmit && correct_answer) {
      if (`${values[i]}` === `${correct_answer.id}`) {
        className = classes.dotActive;
      } else {
        className = classes.dotErr;
      }
    } else if (values[i]) {
      className = classes.dotActive;
    }
    return className;
  };

  return (
    <div className={clsx(classes.flexRow, classes.statusIndicator)}>
      <div className={clsx(classes.flexRow, classes.dotsContainer)}>
        {questions.map(({ id, attributes }, index) => (
          <Tooltip title={''} key={'questions_' + id}>
            <div
              data-testId="setCurrentPage"
              onClick={() => setCurrentPage(index)}
              className={clsx(getClassNames(attributes, id), classes.cDot)}
            />
          </Tooltip>
        ))}
      </div>
      {questions && questions.length > 0 &&
        <div className={classes.timer} style={quizTimeOut < 30 ? { color: 'red' } : {}}>{secondsToTime(quizTimeOut)}</div>}
    </div>
  );
};

export const QuizCards = ({ classes, gotoQuiz, userQuizData, isQuiz, quizAssessmentAttrName }) => {
  return (
    <Grid className={isQuiz ? classes.gridsty : classes.quizCardroot} justify="center" item xs={12} spacing={2} container>
      {userQuizData && userQuizData.length > 0 && userQuizData.map(({ attributes }) => (<Grid key={'userQuizData' + attributes[quizAssessmentAttrName].id} className={classes.quizCard} item container direction="column">
        <Typography variant="h3" color="textSecondary">
          {attributes[quizAssessmentAttrName].heading}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Total Question : {attributes.total_marks}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Time : {attributes[quizAssessmentAttrName].timer} mins
        </Typography>
        <Button
          className={classes.quizStartBtn}
          color="primary"
          variant="outlined"
          data-testId="gotoQuiz"
          onClick={() => gotoQuiz(attributes[quizAssessmentAttrName].id)}
        >
          <Typography variant="caption">Start {isQuiz ? "quiz" : "Assessment"}</Typography>
        </Button>
      </Grid>))}
    </Grid>
  )
};

export const StartPage = ({ classes, description, heading, startQuiz, image, isQuiz }) => {
  return (
    <div className={classes.startRoot}>
      <Grid container justify="center">
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item>
              <Box mb={2} >
                <Typography variant="h3">
                  {heading}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography variant="body2" color="textSecondary">
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={isQuiz ? classes.grid : classes.imageDiv} item>
              {image ? <img src={image} className={classes.image} /> : <div className={classes.image}>
              </div>
              }
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                className={classes.startBtn}
                color="primary"
                variant="outlined"
                data-testId="startQuiz"
                onClick={() => startQuiz()}
              >
                <Typography variant="caption">Start Now</Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export const ShareSocialContent = ({ classes, isQuiz, closeShareDialog }) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {`Share this ${isQuiz ? "quiz" : "assessment"} results`}
        <IconButton
          aria-label="close"
          className={classes.iconclose}
          data-testId="closeShareDialog"
          onClick={() => closeShareDialog()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.sharecontent}>
        <Grid container >
          <Grid xs={12}>
            <Grid container direction="column" alignItems="center" justify="center" className={classes.shareCourse}>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item className={classes.socialBtn} >
                    <FacebookShareButton
                      url={window.location.href}
                      quote={`${isQuiz ? "Quiz" : "Assessment"} Results - That you got from test`}
                      hashtag="#CareerHunt"
                      style={{ backgroundColor: '#ffffff', height: '60px', width: '60px', borderRadius: '50%' }}
                    >
                      <img src={fbImg} className={classes.socialMediaButton} />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item className={classes.socialBtn}  >
                    <LinkedinShareButton
                      url={window.location.href}
                      quote={`${isQuiz ? "Quiz" : "Assessment"} Results - That you got from test`}
                      hashtag="#CareerHunt"
                      style={{ backgroundColor: '#ffffff', height: '60px', width: '60px', borderRadius: '50%' }}
                    >
                      <img src={linkedInImg} className={classes.socialMediaButton} />
                    </LinkedinShareButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}
export const ShareBlock = ({ classes, isQuiz, shareResult, retryQuiz }) => {
  return (
    <Grid container justify="center" spacing={2} className={isQuiz ? '' : classes.shareretry}>
      {isQuiz ? null : (<>
        <Grid item> </Grid>
        <Grid item> </Grid>
        <Grid item> </Grid>
      </>)}

      <Grid item>
        <Button
          color="primary"
          variant="outlined"
          className={classes.sharescore}
        >
          <Typography variant="caption" data-testId="shareResult" onClick={() => shareResult()}>Share your score</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.startBtn}
          color="primary"
          variant="outlined"
          data-testId="retryQuiz"
          onClick={() => retryQuiz()}
        >
          <Typography variant="caption">Retry</Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

export const AnswerBlock = ({ classes, options, hasSubmit, correct_answer, value, image, qTitle, qId, isQuiz, getClassName, getCheckBox, checkImage, handleChange }) => {
  return (
    <>
      <Typography className={classes.qtitle} variant="h6">
        {qTitle}
      </Typography>
      <Grid xs={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="baseline"
        >
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="subtitle1" align="left" className={isQuiz ? classes.selectedOne : ''}>Select one answer:</Typography>
            <RadioGroup value={value} name="options">
              {options && options.map((val) => (
                <FormControlLabel
                  className={clsx(getClassName(classes, val?.id, hasSubmit, correct_answer, value), classes.commonRdo)}
                  value={`${val?.id}`}
                  key={'option_' + val?.id}
                  control={getCheckBox(val?.id, hasSubmit, correct_answer, GreenRadio, RedRadio)}
                  label={<><Typography variant="subtitle2">{val?.answer}</Typography> {val?.image?.url && <img className={classes.optionImage} src={val?.image?.url} />}</>}
                  onChange={e => hasSubmit ? () => { } : handleChange(e, qId)}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item className={isQuiz ? classes.grid : classes.imageDiv} xs={12} sm={4} md={3}>
            {image && checkImage(image) && <img src={image} className={classes.image} />
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export const AnswerSbmit = ({ classes, hasSubmit, correct_answer, options, isQuiz, value, qId, handleNext, notEmpty, onSubmit, getAsciiToChar }) => {
  return (
    <>
      {hasSubmit && <div className={classes.flexRow}>
        <Button
          className={classes.nextBtn}
          color="primary"
          onClick={() => handleNext()}
          variant="outlined"
        >
          <Typography variant="caption">Next</Typography>
        </Button>
      </div>}
      {(!hasSubmit && notEmpty(value)) && <div className={classes.flexRow}>
        <Button
          className={classes.nextBtn}
          color="primary"
          data-testId="onSubmit"
          onClick={() => onSubmit(qId)}
          variant="outlined"
        >
          <Typography variant="caption">Submit</Typography>
        </Button>
      </div>}
      {hasSubmit && correct_answer && <>
        <Typography align="left"
          className={isQuiz ? classes.subOne : classes.correctAns}
          variant="subtitle1"
        >
          {`Correct answer is Option ${getAsciiToChar(options.findIndex(x => x.id === correct_answer.id) + 65)}`}
        </Typography>
        <Typography align="left"
          className={isQuiz ? classes.subTwo : classes.answerDes}
          variant="subtitle2"
        >
          {correct_answer?.description}
        </Typography>
      </>}
    </>
  )
}

export const ObtainedMarks = ({ classes, image, obtained_marks, total_marks, description, isQuiz, viewAllAnswers }) => {
  return (
    <Grid container spacing={isQuiz ? 4 : 8}>
      <Grid className={isQuiz ? classes.gridtwo : classes.result} item>
        {image ? <img src={image} className={classes.resultImage} /> : <div className={classes.resultImage}>
        </div>
        }
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={3}>
          <Grid item xs>
            <Typography variant="h2" color="textSecondary">
              <Typography variant="h1" className={classes.marks} component="span">
                {obtained_marks ? obtained_marks : 0}
              </Typography>
              <Typography variant="h6" className={classes.marks} component="span">
                /{total_marks}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h3" color="textSecondary">
              {obtained_marks > 0 ? "Well Done !! You're Doing Great" : 'Oops! better luck next time'}
            </Typography>
          </Grid>
          {!isQuiz && <Grid item xs>
            <Button className={classes.viewall} data-testId="viewAllAnswers" onClick={() => viewAllAnswers()}>View all answers</Button>
          </Grid>}

          <Grid item xs>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}