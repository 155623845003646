import { CommonActions } from "@react-navigation/native";
import { Alert } from "react-native";

export interface Categories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
}
export interface Contenttype {
  id: number,
  type: string;
  created_at: string;
  updated_at: string;
  rank: string
}
export interface Article {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    description: string;
    image: string;
    video: string;
    audio: string;
    files: any[];
    study_material: string;
    content_type: Contenttype;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
    tag_list: string[];
    heading: string;
    content_provider: any;
    contentable: any;
  };
}
export interface Category {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategories[];
  };
}
export interface SubCategory {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
  };
}

export const defaultCategoryValues =
{
  id: "",
  type: "",
  attributes: {
    id: "",
    name: "",
    created_at: "",
    updated_at: "",
    sub_categories: [],
  },
}


export const displayTimeInHoursminutes = (time: any) => {
  let n: any = parseInt(time);
  return `0${n / 60 ^ 0}`.slice(-2) + 'H :' + ('0' + n % 60).slice(-2) + 'M'
}
export const getRandom = () => {
  if (window.crypto) {
    return window.crypto.getRandomValues(new Uint8Array(256));
  }
}

export const goToLogin = (navigation: any) => {
  Alert.alert(
    "",
    "Request you to login first",
    [
      {
        text: "Cancel",
        onPress: () => { return },
        style: "cancel"
      },
      {
        text: "OK", onPress: () => {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                { name: 'EmailAccountLoginBlock' },
              ],
            })
          )
        }
      }
    ]
  );
}









