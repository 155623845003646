import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  sideDrawerOpen: boolean;
  mobileView: boolean;
  userDetails: any;
  tabValue: any;
  tabName: string;
  subsTabValue: any;
  certificateList: any;
  allRunningCourseList: any;
  allShortlistedCourseList: any;
  allShortlistedJobList: any;
  faqList: any;
  supportList: any
  quizList: any
  assessmentList: any
  transactionHistory: any
  suggestedCourseList: any
  myCartList: any,
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DashboardWebController extends BlockComponent<Props, S, SS> {
  getUserDataApiCallId: string = "";
  getAllRunningCourseApiCallId: string = "";
  getAllShortlistedCourseApiCallId: string = "";
  getAllShortlistedJobsApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  removeBookmarkJobsApiCallId: string = "";
  getFaqApiCallId: string = "";
  getTransactionApiCallId: string = "";
  getHelpnSupportApiCallId: string = "";
  getAssessmentsApiCallId: string = "";
  getAchievementApiCallId: string = "";
  getSuggestedCourseApiCallId: string = "";
  getQuizApiCallId: string = "";
  getMyCartListApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      sideDrawerOpen: false,
      mobileView: false,
      userDetails: [],
      supportList: [],
      tabValue: 0,
      subsTabValue: 0,
      tabName: 'courses',
      allRunningCourseList: [],
      allShortlistedCourseList: [],
      allShortlistedJobList: [],
      certificateList: [],
      faqList: [],
      quizList: [],
      assessmentList: [],
      transactionHistory: [],
      suggestedCourseList: [],
      myCartList: [],
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if ((responseJson && !responseJson.errors && responseJson.data) || (responseJson && responseJson?.success)) {
        switch (apiRequestCallId) {
          case this.getUserDataApiCallId:
            this.setState({ userDetails: responseJson.data.attributes });
            if (responseJson?.data?.attributes?.first_name) {
              localStorage.setItem('firstName', responseJson.data.attributes.first_name);
            }
            break;
          case this.getAllRunningCourseApiCallId:
            this.setState({ allRunningCourseList: responseJson.data });
            break;
          case this.getAllShortlistedCourseApiCallId:
            this.setState({ allShortlistedCourseList: responseJson.data });
            break;
          case this.getAllShortlistedJobsApiCallId:
            this.setState({ allShortlistedJobList: responseJson.data });
            break;
          case this.getFaqApiCallId:
            this.setState({ faqList: responseJson.data });
            break;
          case this.getHelpnSupportApiCallId:
            this.setState({ supportList: responseJson.data });
            break;
          case this.removeBookmarkApiCallId:
            this.getAllShortlistedCourses();
            break;
          case this.removeBookmarkJobsApiCallId:
            this.getAllShortlistedJobs();
            break;
          case this.getAssessmentsApiCallId:
            this.setState({ assessmentList: responseJson.data });
            break;
          case this.getQuizApiCallId:
            this.setState({ quizList: responseJson.data });
            break;
          case this.getTransactionApiCallId:
            this.setState({ transactionHistory: responseJson.data });
            break;
          case this.getAchievementApiCallId:
            this.setState({ certificateList: responseJson.data });
            break;
          case this.getSuggestedCourseApiCallId:
            this.setState({ suggestedCourseList: responseJson.data });
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.showError(responseJson);
      }
    }
  }
  // Customizable Area Start
  showError = (responseJson: any) => {
    if (responseJson.errors[0].token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
    setTimeout(() => {
      localStorage.clear();
      this.props.history.push('/home');
    }, 2000)
  }
  showUSerData = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getUserDataApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showUserDataUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllRunningCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllRunningCourseApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRuningCourseUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllShortlistedCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllShortlistedCourseApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShortlistedCourseUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Shortlist Jobs start
  getAllShortlistedJobs = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAllShortlistedJobsApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShortlistedJobUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFaq = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getFaqApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqApiUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // transcation api
  getTransaction = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTransactionApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTransaction + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // ===
  getHelpnSupport = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getHelpnSupportApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHelpnSupportApiUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  removeBookmark = (id: any) => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.dashboarContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const methodType = configJSON.dashboarPostApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // removeJobsBoomark
  removeJobsBoomark = (id: any) => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.dashboarContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "job"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const methodType = configJSON.dashboarPostApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBookmarkJobsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getQuizzes = () => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.dashboarContentType
    }
    const apiEndPoint = configJSON.getQuizzesAPiUrl;
    const methodType = configJSON.dashboarApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuizApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getAssessments = () => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.dashboarContentType
    }
    const apiEndPoint = configJSON.getAssessmentsApUrl;
    const methodType = configJSON.dashboarApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessmentsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // My achievements api function call
  getachievementslists = () => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.dashboarContentType
    }
    const apiEndPoint = configJSON.getachievementslist;
    const methodType = configJSON.dashboarApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAchievementApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  //  sugested cource
  getSuggestedCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSuggestedCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSuggestedCourseUrl
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}