import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/Utility";
import { Article } from "./CommonFunctions"

export type Props = {
  // Customizable Area Start
  id: string;
  navigation: any;
  match: any;
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  showArticledetails: Article;
  contents: any;
  ctalistData: any[];
  show: any
  expertProfileFeatured: any;
  expertProfile: any
  // Customizable Area End

}

interface SS {
  id: any;
}

export default class ArticaldetailsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getArticledetailId: string = "";
  getContentsApiCallId: string = "";
  getctalistId: string = "";
  getExpertProfileFeaturedApiCallId: string = "";
  getExpertProfileApiCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      showArticledetails: {
        id: "",
        type: "",
        attributes: {
          id: "",
          name: "",
          description: "",
          image: "",
          video: "",
          audio: "",
          study_material: "",
          files: [],
          content_type: {
            id: 0,
            type: "",
            created_at: "",
            updated_at: "",
            rank: "",
          },
          created_at: "",
          updated_at: "",
          categories: [],
          sub_categories: [],
          tag_list: [],
          heading: "",
          content_provider: {},
          contentable: {}
        },
      },
      contents: [],
      ctalistData: [],
      show: false,
      expertProfileFeatured: [],
      expertProfile: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getArtcleDetails(this.props.match?.params?.id)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getContentsApiCallId:
            this.handleContentsApiResponce(responseJson);
            break;
          case this.getArticledetailId:
            this.handleArticledetailIdApiResponce(responseJson);
            break;
          case this.getctalistId:
            this.handlectalistIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }

  handleContentsApiResponce = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      let content: any[] = []
      responseJson.data.forEach((ele: any) => {
        if (ele.id != this.props.match?.params?.id && content.length < 2) {
          content.push(ele)
        }
      })
      this.setState({ contents: content });
    }
  };
  handleArticledetailIdApiResponce = (responseJson: any) => {
    this.setState({
      showArticledetails: responseJson?.data,
    });
    if (responseJson?.data) {
      this.handlenextArtcles(responseJson?.data?.attributes?.category?.id)
      this.getCTAs(responseJson?.data?.attributes?.category?.id)
    }
  };
  handlectalistIdApiResponce = (responseJson: any) => {
    this.setState({
      ctalistData: responseJson.data,
    });
  };

  clearLocalstorage = (responseJson: any) => {
    console.log(responseJson.errors);
  };

  handlenextArtcles = (catId: any) => {
    const lang = localStorage.getItem("lang") || 'en';
    let obj: any = this.getUrl()
    let apiEndPoint = ''
    if (obj?.contenttypeId) {
      apiEndPoint = `${obj?.url}?page=1&per_page=4&category[]=${catId}&language=${lang}${obj?.contenttypeId}`;
    }
    else {
      apiEndPoint = `${obj?.url}?page=1&per_page=4&category[]=${catId}&language=${lang}`;
    }
    this.getContentsApiCallId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
  // Customizable Area End

  getArtcleDetails = (artId: any) => {
    const lang = localStorage.getItem("lang") || 'en';
    let apiEndPoint = ''
    let obj: any = this.getUrl()
    if (obj?.contenttypeId) {
      apiEndPoint = `${obj?.url}/${artId}?language=${lang}${obj?.contenttypeId}`;
    }
    else {
      apiEndPoint = `${obj?.url}/${artId}?language=${lang}`;
    }
    this.getArticledetailId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }

  getUrl = () => {
    let url = ''
    let contenttypeId = ''
    if (this.props.match?.params?.type == 'articles') {
      contenttypeId = '&content_type[]=1'
      url = configJSON.getContents
    }
    else if (this.props.match?.params?.type == 'video') {
      contenttypeId = '&content_type[]=4&content_type[]=3'
      url = configJSON.getContents
    }
    if (this.props.match?.params?.type == 'livestream') {
      contenttypeId = '&content_type[]=5'
      url = configJSON.getContents
    }
    else if (this.props.match?.params?.type == 'podcast') {
      // url=configJSON.getPodcastUrl
      url = configJSON.getContents
      contenttypeId = '&content_type[]=6'
    }
    else if (this.props.match?.params?.type == 'epubs') {
      // url=configJSON.getEpubListUrl
      url = configJSON.getContents
      contenttypeId = '&content_type[]=9'
    }
    else if (this.props.match?.params?.type == 'blogs') {
      // url=configJSON.getEpubListUrl
      url = configJSON.getContents
      contenttypeId = '&content_type[]=2'
    }
    return { url, contenttypeId }
  }

  // get ctas 
  getCTAs = (catId: any) => {
    const apiEndPoint = `${configJSON.ctalistAPIEndPoint}?category[]=${catId}&per_page=3&page=1`;
    this.getctalistId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }

  //expert videos

}