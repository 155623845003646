import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class LiveStreamingWebController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    }
    if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
    }
  }
  playVideo = () => {
    // @ts-ignore
    this.myRef.current.play();
  }
  pauseVideo = () => {
    // @ts-ignore
    this.myRef.current.pause();
  }
  toggleControls = () => {
    // @ts-ignore
    this.myRef.current.controls = !this.myRef.current.controls;
  }
  // Open View Details Modal
  openToastHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    console.log("type,message", type, message);
    this.setState({
      showToast: {
        show: true,
        message: message,
        type,
      },
    });
  };
  // Close View Details Modal
  closeToastHandler = () => {
    this.setState({
      showToast: {
        show: false,
        message: this.state.showToast.message,
        type: this.state.showToast.type,
      },
    });
  };
  // Customizable Area End
}
