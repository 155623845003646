import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    openToastHandler: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    ParentCCallback: any;
    openDialog: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  email?: string;
  loginType: string;
  login: boolean;
  fbResponse: any;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  isActive: boolean;
  loading: boolean;
  verifyOtp: boolean;
  isVisible: boolean;
  fbStatus: boolean;
  googleStatus: boolean;
  liStatus: boolean;
  appleStatus: boolean;
  open: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmailAccountLoginController extends BlockComponent<Props, S, SS> {
  loginApiEmailCallId: string = "";
  loginApiFacebookId: string = "";
  loginApiGoogleId: string = "";
  loginApiAppleId: string = "";
  loginApiLinkedInId: string = "";
  linkedinTokenAcessId: string = "";
  constructor(props: Props) {
    super(props);
    this.state = {
      loginType: "email" || "phone" || "facebook",
      fbResponse: null,
      email: '',
      login: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      isActive: false,
      loading: false,
      verifyOtp: false,
      isVisible: false,
      googleStatus: false,
      fbStatus: false,
      liStatus: false,
      appleStatus: false,
      open: false
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.receive = this.receive.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  //@ts-ignore

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.loginApiEmailCallId:
          this.loginApiEmailCall(responseJson);
          break;
        case this.loginApiFacebookId:
          this.loginApiFacebook(responseJson);
          break;
        case this.loginApiGoogleId:
          this.loginApiGoogle(responseJson);
          break;
        case this.loginApiAppleId:
          this.loginApiApple(responseJson);
          break;
        case this.loginApiLinkedInId:
          this.loginApiLinkedIn(responseJson);
          break;
        default: this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ loading: false });
          break;
      }

    }
  }
  loginApiLinkedIn = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data !== null && responseJson.meta !== null) {
      this.setState({ isActive: true, loading: false });
      this.props.openToastHandler("success", "Successfully Logged in!!");
      localStorage.setItem("isActive", JSON.stringify(responseJson.data.attributes.activated));
      localStorage.setItem("userId", responseJson.data.id);
      localStorage.setItem("token", responseJson.meta.token);
      this.props.history.push(`${this.props.history?.location?.pathname}`)
    } else { this.socialError(responseJson) }
  }
  loginApiApple = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && responseJson.data !== null && responseJson.meta !== null) {
      this.setState({ isActive: true, loading: false });
      localStorage.setItem("isActive", JSON.stringify(this.state.isActive));
      localStorage.setItem("userId", responseJson.data.id);
      const appletoken = localStorage.getItem("appletoken");
      if (appletoken) {
        localStorage.setItem("token", responseJson.meta.token);
        this.props.history.push(`${this.props.history?.location?.pathname}`);
        this.props.openToastHandler("success", "Successfully Logged in!!");
      } else { this.socialError(responseJson) }
    }
  }
  loginApiGoogle = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson.data !== null && responseJson.meta !== null) {
      this.setState({ isActive: true, loading: false });
      localStorage.setItem("isActive", JSON.stringify(this.state.isActive));
      localStorage.setItem("userId", responseJson.data.id);
      const googletoken = localStorage.getItem("googletoken");
      if (googletoken) {
        localStorage.setItem("token", responseJson.meta.token);
        this.setState({ loading: false, isActive: true });
        this.props.history.push(`${this.props.history?.location?.pathname}`)
        this.props.openToastHandler("success", "Successfully Logged in!!")
      }
      else { this.socialError(responseJson) }
    }
  }
  loginApiFacebook = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson.data !== null && responseJson.meta !== null) {
      this.setState({ isActive: true, loading: false });
      localStorage.setItem("isActive", JSON.stringify(this.state.isActive));
      const fbtoken = localStorage.getItem("fbtoken")
      if (fbtoken) {
        localStorage.setItem("userId", responseJson.data.id);
        localStorage.setItem("token", responseJson.meta.token);
        this.props.history.push(`${this.props.history?.location?.pathname}`)
        this.props.openToastHandler("success", "Successfully Logged in!!");
        this.setState({ loading: false, isActive: true });
      } else { this.socialError(responseJson) }
    }
  }
  socialError = (responseJson: any) => {
    if (responseJson.errors[0]) {
      if (responseJson.errors[0].failed_login) {
        this.props.openToastHandler("error", responseJson.errors[0].failed_login);
      } else if (responseJson.errors[0].token) {
        this.props.openToastHandler("error", responseJson.errors[0].token);
      } else if (responseJson.errors[0].error) {
        this.props.openToastHandler("error", responseJson.errors[0].error);
      }
    }
  }
  loginApiEmailCall = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      localStorage.setItem("userId", responseJson.data.id);
      const token = responseJson.meta.token;
      localStorage.setItem("otp_token", token);
      this.setState({ isActive: responseJson.data.attributes.activated, loading: false });
    } else {
      this.errorCall(responseJson,);
    }
  }
  errorCall = (responseJson: any) => {
    this.setState({ isActive: false });
    localStorage.setItem("isActive", JSON.stringify(this.state.isActive));
    this.parseApiErrorResponse(responseJson);
    if (responseJson.errors[0]) {
      if (responseJson.errors[0].account) {
        this.props.openToastHandler("error", responseJson.errors[0].account);
      } else if (responseJson.errors[0].token) {
        this.props.openToastHandler("error", responseJson.errors[0].token);
      } else if (responseJson.errors[0].full_phone_number) {
        this.props.openToastHandler("error", responseJson.errors[0].full_phone_number);
      }
    }
    this.props.history.replace("/");
    this.setState({ loading: false })
  }
  handleDrawerClose = () => {
    this.setState({ open: false });
    this.props.openDialog(this.state.open);
  }
  resultFacebookLogin = async (response: any) => {
    if (response.status === "unknown") {
      this.handleDrawerClose();
    } else {
      this.setState({ loading: true });
      this.props.showLoader();
      this.handleDrawerClose();
      const headers = {
        "Content-Type": configJSON.loginApiContentType,
      };
      const data = {
        type: "facebook",
        attributes: {
          access_token: response.accessToken
        }
      };
      localStorage.setItem("fbtoken", response.accessToken);
      localStorage.setItem("firstName", response.name);
      localStorage.setItem("email", response.email);
      const httpBody = {
        data: data,
      };
      const apiEndPoint = configJSON.facebookLoginApiEndPoint;
      const methodType = configJSON.loginApiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loginApiFacebookId = requestMessage.messageId;
      const lang = localStorage.getItem("lang") || "en"
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint + `?language=${lang}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  }
  resultGoogleLogin = async (response: any) => {
    //@ts-ignore
    if (response.error === "idpiframe_initialization_failed") {
      this.props.openToastHandler("error", response.details);
    } else if (response.error === "popup_closed_by_user") {
      this.handleDrawerClose();
    }
    else {
      this.setState({ loading: true });
      this.props.showLoader();
      this.handleDrawerClose();
      const headers = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const data = {
        type: "google",
        attributes: {
          access_token: response.tokenId
        }
      };
      localStorage.setItem("googletoken", response.tokenId);
      const httpBody = {
        data: data,
      };

      const apiEndPoint = configJSON.facebookLoginApiEndPoint;
      const methodType = configJSON.loginApiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loginApiGoogleId = requestMessage.messageId;
      const lang = localStorage.getItem("lang") || "en"
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint + `?language=${lang}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  }
  resultAppleLogin = async (response: any) => {
    if (!response.authorization && response.error || response.errors) {
      if (response.error.error === "popup_closed_by_user") {
        this.handleDrawerClose();
      }
      if (response.error.error === "user_trigger_new_signin_flow") {
        this.handleDrawerClose();
      }
      if (response.errors) {
        this.props.openToastHandler("error", response.errors);
        this.handleDrawerClose();
      }
    } else {
      this.setState({ loading: true });
      this.props.showLoader();
      this.handleDrawerClose();
      let data = {};
      const headers = {
        "Content-Type": configJSON.loginApiContentType,
      };
      const lang = localStorage.getItem("lang") || "en"
      data = {
        "code": response.authorization?.grant_code || response.authorization?.code,
        "id_token": response.authorization.id_token
      }

      localStorage.setItem("appletoken", response.authorization.id_token);

      const apiEndPoint = configJSON.appleLogin;
      const methodType = configJSON.loginApiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loginApiAppleId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint + `?language=${lang}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  }
  resultFailureLinkedLogin = (res: any) => {
    console.log(res)
  }
  resultLinkedLogin = async (res: any) => {
    if (res.error === "popup_closed_by_user") {
      this.handleDrawerClose();
    }
    else {
      this.setState({ loading: true });
      this.props.showLoader();
      this.handleDrawerClose();
      const headers = {
        "Content-Type": configJSON.validationApiContentType
      };
      const data = {
        "data": {
          "type": "linkedin",
          "attributes": {
            "code": res.code
          }
        }
      };
      const apiEndPoint = configJSON.facebookLoginApiEndPoint;
      const methodType = configJSON.loginApiMethod;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loginApiLinkedInId = requestMessage.messageId;
      const lang = localStorage.getItem("lang") || "en"
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint + `?language=${lang}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  }
  handleLogin = (values: any) => {
    this.setState({ loading: true });
    let httpBody = {};
    const headers = {
      "Content-Type": configJSON.loginApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiEmailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    if (values?.email?.includes('@')) {
      localStorage.setItem("email", values?.email);
      httpBody = {
        "data": {
          "attributes": {
            "email": values?.email
          }
        }
      };
    } else {
      localStorage.setItem("phoneNumber", values?.email);
      httpBody = {
        "data": {
          "attributes": {
            "full_phone_number": `${91}` + values?.email
          }
        }
      }
    }
    const lang = localStorage.getItem("lang") || 'en';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.loginApiEndPoint}` + `language=${lang}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
