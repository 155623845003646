import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export type Props = {
  id: string;
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  check: boolean;
  otpAuthToken?: string;
  showToast: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  showPassword: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
  > {
  sendOtpApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      check: false,
      showToast: {
        show: false,
      },
      showPassword: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() { }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson && !responseJson.errors) {
        console.log("responseJson", responseJson);
        // Send OTP Response
        if (
          apiRequestCallId === this.sendOtpApiCallId &&
          responseJson !== undefined
        ) {
          this.setState({
            otpAuthToken: responseJson.meta.token,
          });
          localStorage.setItem("otp_auth_token", responseJson.meta.token);
          localStorage.setItem("userId", responseJson.data.id);
          this.openToastHandler("success", configJSON.successOtpSent);
          setTimeout(() => {
            // @ts-ignore
            this.props.history.push("/verifyOtp", {
              state: {
                type: 'signup',
                ...this.state
              }
            })
          }, 1000);
        }
      } else {
        console.log(responseJson);
        if (!responseJson.errors) {
          console.log(responseJson.errors);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Send OTP Handler
  sendOtp = (values: any): boolean => {
    const userDetails: any = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      // full_phone_number: "+" + values.countryCodeSelected + values.phone
      full_phone_number: "+91" + values.phone,
    };
    // Store User Details in local storage
    localStorage.setItem("user_details", JSON.stringify(userDetails));
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const attrs = {
      // full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
      full_phone_number: "+91" + values.phone,
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const apiEndPoint = configJSON.sendOtpAPiEndPoint;
    const methodType = configJSON.httpPostType;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Open View Details Modal
  openToastHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    console.log("type,message", type, message);
    this.setState({
      showToast: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeToastHandler = () => {
    this.setState({
      showToast: {
        show: false,
        message: this.state.showToast.message,
        type: this.state.showToast.type,
      },
    });
  };

  // Show Password
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  // Customizable Area End
}
