import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/Utility";
import {Category,SubCategory,defaultCategoryValues} from "./CommonFunctions"

export type Props = {
  id: string;
  navigation: any;
  match: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  categoriesData: Category[];
  subCategoriesData: SubCategory[];
  showCategoryData: Category;
  showSubCategoryData: Category;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: string = "";
  getSubCategoriesApiCallId: string = "";
  showCategoryApiCallId: string = "";
  showSubCategoryApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      categoriesData: [],
      subCategoriesData: [],
      showCategoryData: defaultCategoryValues,
      showSubCategoryData: defaultCategoryValues,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getAllCategories();
    this.getAllSubCategories();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCategoriesApiCallId:
              this.handleCategoriesApiCallIdApiResponce(responseJson);
              break;
          case this.getSubCategoriesApiCallId:
              this.handleSubCategoriesApiCallIdApiResponce(responseJson);
              break;     
          case this.showCategoryApiCallId:
              this.handleshowCategoryApiCallIdApiResponce(responseJson);
              break;   
              case this.showSubCategoryApiCallId:
                this.handleshowSubCategoryApiCallIdApiResponce(responseJson);
                break;   
          default:
              break;
      }
      } else if(responseJson && responseJson.errors) { 
          this.clearLocalstorage(responseJson)
          // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }

  handleCategoriesApiCallIdApiResponce=(responseJson:any)=>{
  this.setState({
    categoriesData: responseJson.data,
  });
};
handleSubCategoriesApiCallIdApiResponce=(responseJson:any)=>{
  this.setState({
    subCategoriesData: responseJson.data,
  });
};
handleshowCategoryApiCallIdApiResponce=(responseJson:any)=>{
  this.setState({
    showCategoryData: responseJson.data,
  });
};
handleshowSubCategoryApiCallIdApiResponce=(responseJson:any)=>{
  this.setState({
    showSubCategoryData: responseJson.data,
  });
};
clearLocalstorage=(responseJson:any)=>{
  this.parseApiErrorResponse(responseJson);
};
  // Get All Categories
  getAllCategories = () => {
    this.getCategoriesApiCallId = sendAPIRequest(configJSON.categoriesAPIEndPoint,
      { headers: {"Content-Type": configJSON.apiContentType} })
  };

  // Get All Sub Categories
  getAllSubCategories = () => {
    this.getSubCategoriesApiCallId = sendAPIRequest( configJSON.subCategoriesAPIEndPoint,
      { headers: {"Content-Type": configJSON.apiContentType} })
  };

  // Get Sub Categories of Category
  showCategory = (catId: number) => {
    this.showCategoryApiCallId = sendAPIRequest(`${configJSON.categoriesAPIEndPoint}/${catId}`,
      { headers: {"Content-Type": configJSON.apiContentType} })
  };

  // Show Sub Category
  showSubCategory = (suCatId: number) => {
    this.showSubCategoryApiCallId = sendAPIRequest(`${configJSON.subCategoriesAPIEndPoint}/${suCatId}`,
      { headers: {"Content-Type": configJSON.apiContentType} })
  };
  // Customizable Area End
}
