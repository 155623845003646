
  //@ts-nocheck
  import React from "react";
  import StarBorderIcon from "@material-ui/icons/StarBorder";
  import StarIcon from "@material-ui/icons/Star";
  import ImageIcon from '@material-ui/icons/Image';
  import {getRandom} from './CommonFunctions'

  export const Ratings=({classes,user_rating,idx})=>{
    return(
      <React.Fragment  key={'ratng_'+getRandom()}>
      {user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
        return (user_rating >= rating ? <StarIcon key={'star_'+rating + getRandom()} className={classes.Icons} /> : <StarBorderIcon key={'starB_'+rating + getRandom()} className={classes.Icon} />);
      })}
      {!user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
        return (<StarBorderIcon key={'starBorder_'+ratings + getRandom()} className={classes.Icon} />);
      })}
      </React.Fragment>
    )
  }

  export const LiveStream=({classes,e})=>{
    return(
      <>
      {e?.attributes?.content_type?.type == "Live Stream" && (
      <>
      {e?.attributes?.contentable?.data?.attributes?.url ?
       (
      <div className="relative ">
      <video
        autoPlay={true}
        muted
        loop
        className={classes.fvSmallPlayer}
        key={e?.attributes?.contentable?.data?.attributes?.url}
      >
        <source src={e?.attributes?.contentable?.data?.attributes?.url}
           type="video/mp4"
           />
      </video>
      <div className={classes.overlay}>
        <img alt="playvideo" className={classes.smallPlay} src={play} />
      </div>
    </div>
    ): (
    <ImageIcon className="rounded-lg w-48" color="primary" />
    )}
    </>
    )}
    </>
    )
    }