import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  categories: any;
  selectedChip: any;
  contents: any;
  showRecentiySearch: any;
  searchResult: any;
  finalSearchResult: string;
  showSearchModal: boolean;
  value: any;
  myArray: any;
  apiData: any;
  showRecentiyData: any;
  open: boolean;
  searchValue: string;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class SearchControllerWeb extends BlockComponent<Props, S, SS> {
  apiSearchId: any;
  apiFinalResult: any;
  // Customizable Area Start
  array: any;
  bgImageVideoUrlApiCallId: string = "";
  getCategoriesApiCallId: string = "";
  categoryData: any;
  getContentsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];
    this.state = {
      url: "",
      mimeType: "",
      categories: [],
      selectedChip: 0,
      contents: [],
      showRecentiySearch: [],
      searchResult: [],
      finalSearchResult: "",
      showSearchModal: false,
      value: null,
      myArray: [],
      apiData: [],
      showRecentiyData: [],
      open: false,
      searchValue: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    const recents = await this._getRecentResult();
    this.setState({ showRecentiyData: recents });
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiSearchId) {
        this.handleSearchAPIRes(responseJson);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  handleSearchAPIRes = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      let data: any = localStorage.getItem("RecentlySearch");
      let dataArray = responseJson.data;
      let newArray: Array<any> = [];
      let newArray2: Array<any> = [];
      dataArray.map((item: any) => {
        newArray.push(item.attributes.name);
      });
      if (data) {
        JSON.parse(data).map((item: any) => {
          newArray2.push(item);
          newArray.unshift(item);
        });
      }
      this.setState({ apiData: newArray });
      this.setState({ showRecentiyData: newArray2 });
    }
  }
  // Customizable Area End
  _searchFunction = (e: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSearchId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContents}?search=${e}&language=en`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onChangeText = (e: any) => {
    this._searchFunction(e.target.value);
    this.setState({ searchValue: e.target.value });
  };
  onChange = async (a: any, b?: any, c?: any) => {
    if (c === "select-option") {
      if (b.value === "recents") return;
      const dataR: any = await localStorage.getItem("RecentlySearch");
      const recents: any = JSON.parse(dataR) ? JSON.parse(dataR) : [];
      const updatedRecents = [b.title].concat(...recents).filter(onlyUnique);
      let data = JSON.stringify(updatedRecents);
      localStorage.setItem("RecentlySearch", data);
      this.gotoResultPage(b.title);
    }
  };
  clearRecent = async (params: any) => {
    const dataR: any = await localStorage.getItem("RecentlySearch");
    const recents: any = JSON.parse(dataR) ? JSON.parse(dataR) : [];
    const updatedRecents = [...recents].filter((x: any) => x !== params.title);
    let data = JSON.stringify(updatedRecents);
    localStorage.setItem("RecentlySearch", data);
    this.setState({ showRecentiyData: updatedRecents });
  };
  clearRecentAll = async () => {
    localStorage.removeItem("RecentlySearch");
    this.setState({ showRecentiyData: [] });
  };
  gotoResultPage = (searchVal: string) => {
    //@ts-ignore
    this.props.history.push(`/home/searchdata/${searchVal}`);
  };
  _getRecentResult = async () => {
    try {
      const jsonValue = await localStorage.getItem("RecentlySearch");
      const result: any = jsonValue !== null ? JSON.parse(jsonValue) : [];
      return result;
    } catch (e) {
      // error reading value
      console.log(e, "error async");
      return [];
    }
  };
}
