//@ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Container,
  Grid,
  createStyles,
  withStyles,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import FooterWebController, { Props } from "./FooterController.web";
import { logo } from "./assets";
import i18n from "i18next";

class FooterWeb extends FooterWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {}
  render() {
    //@ts-ignore
    const { classes, t, i18n } = this.props;
    return (
      <Container maxWidth="xl" style={{ padding: "0rem" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <img src={logo} width="100%" alt="footer" />
          {/* <div className="w-full h-60 bg-black pl-8 pr-8">
       <br/>
      <br/>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item xs={3}>
         
          <Typography variant="h4" color="secondary">PRODUCT</Typography>
          <Grid container direction="column">
            <Typography variant="body1" color="secondary">Popular</Typography>
            <Typography variant="body1" color="secondary">Trending</Typography>
            <Typography variant="body1" color="secondary">Blog</Typography>
            <Typography variant="body1" color="secondary">Feature</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
        <Typography variant="h4" color="secondary">COMPANY</Typography>
        <Grid container direction="column">
            <Typography variant="body1" color="secondary">About US</Typography>
            <Typography variant="body1" color="secondary">Dailyhunt</Typography>
            <Typography variant="body1" color="secondary">Careers</Typography>
            <Typography variant="body1" color="secondary">Feature</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          hello
        </Grid>
      </Grid>
      <br/>
      <br/>
      <Grid container direction="row" alignItems="center" justify="space-between">
      <Grid item xs={10}>
          <Typography variant="h4" color="secondary">ABOUT US</Typography>
          <Typography color="secondary" variant="body1">
          LLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </Typography>
        </Grid>
        
      </Grid>
         </div> */}
        </Grid>
      </Container>
    );
  }
}

// @ts-ignore
const FooterWebWithRouter = withRouter(FooterWeb);
const FooterWebWithStyle = withStyles((theme) => createStyles({}))(
  FooterWebWithRouter
);
export default withTranslation()(FooterWebWithStyle);
