//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Button,
  createStyles,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
} from "@material-ui/core";
import TrialsDashboardController, {
  Props,
} from "./TrialsDashboardController.web";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
export class TrialsDashboard extends TrialsDashboardController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" style={{ padding: "0px", paddingLeft: "5rem" }}>
        <Grid direction="column" alignItems="center" justify="center">
          <Grid container>
            <Grid item xs={12}>
              <Card className={classes.headerCard} variant="outlined">
                <CardContent className={classes.cardContent}>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h2">
                        Welcome{" "}
                        {localStorage.getItem("firstName") || "New User"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="secondary"
                      >
                        Education is the passport to the future,So Learn More &
                        More
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        data-testId="courses"
                        variant="contained"
                        className={classes.browseButton}
                        onClick={() => this.props.history.push("/home/courses")}
                      >
                        <Typography variant="h3" style={{ fontSize: "16px" }}>
                          Browse Course
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.containerSpacing}>
            <Grid container alignItems="center" direction="row">
              <Grid item xs={10}>
                <Typography variant="h4" className={classes.runningCoursesText}>
                  Running Trials
                </Typography>
              </Grid>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.containerSpacing}>
            <Grid container>
              <Grid item xs={11}>
                <Grid container spacing={1}>
                  {this.state.cardsArray && this.state.cardsArray.length > 0 ? this.state.cardsArray.map((_data: any,i: number) => {
                    return (
                      <Grid item xs={12} md={3} key={i}>
                          <Card className={classes.root}>
                            <CardContent className={classes.runningCoursesCardContent} >
                              <Typography className={classes.runningCoursesCardTitle} gutterBottom variant="h4">
                                {_data?.attributes?.heading}
                              </Typography>
                              <Grid container direction="row" justify="space-between" alignItems="baseline">
                                <Grid item xs={3}>
                                  <Typography variant="body2" className={classes.cardPercentText} >
                                    70 %
                                  </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography
                                    variant="body1"
                                    className={classes.helperTxt} >
                                    Trial Expire in {_data?.attributes?.free_trail_remaining_days} days
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <BorderLinearProgress
                                    variant="determinate"
                                    value={70}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardActions className={classes.cardAction}>
                              <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={4}>
                                  <Typography variant="h3" className={classes.authorNameText}>
                                    {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button data-testId="coursedetail" style={{ backgroundColor: "#f27d00", height: '1rem', width: '5rem' }} onClick={() => this.props.history.push(`/home/coursedetail`, { courseDetail: _data })}>
                                    <Typography variant="body2" className={classes.btnText}>
                                      Buy Now
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </Card>
                        </Grid>
                    );
                  })
                    : <Grid item>
                      <Typography variant="body2">No Running Trials Found</Typography>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const TrialsDashboardWithRouter = withRouter(TrialsDashboard);
const TrialsDashboardWithLoader = withLoader(TrialsDashboardWithRouter);
const TrialsDashboardToast = withSnackbar(TrialsDashboardWithLoader);
const TrialsDashboardWithStyle = withStyles((theme) =>
  createStyles({
    root: {
      borderRadius: "18px",
      height: "100%",
      // height: '10rem'
    },
    cardAction: {
      padding: '1rem',
      // marginTop: '1rem'
    },
    cardContent: {
      backgroundColor: "#47464c",
      height: "15rem",
      paddingTop: "2rem",
      paddingLeft: "2rem",
    },
    headerCard: {
      borderBottomLeftRadius: "2rem",
      marginTop: '-2rem'
    },
    browseButton: {
      width: "10rem",
      height: "3rem",
      borderRadius: "13px",
      backgroundColor: "#fff",
      color: "#7764db",
    },
    containerSpacing: {
      marginTop: "1rem",
    },
    runningCoursesText: {
      color: "#f8593b"
    },
    seeAllText: {
      color: "#826ce4"
    },
    runningCoursesCardContent: {
      // height: "6.5rem",
      backgroundColor: "#FFF",
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c",
      wordWrap: "break-word",
    },
    runningCoursesCardSubTitle: {
      color: "#f27d00",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    authorNameText: {
      color: "#1c1c1c"
    },
    cardPercentText: {
      color: "#1c1c1c",
      paddingBottom: ".5rem"
    },
    helperTxt: {
      paddingBottom: ".5rem",
      color: 'red',
      textAlign: 'end'
    },
    btnText: {
      color: "#FFF",
      fontSize: '12px'
    },
  })
)(TrialsDashboardToast);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 2,
    marginTop: "5px",
    marginBottom: "10px",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 2,
    backgroundColor: "#7764db",
  },
}))(LinearProgress);
export default withTranslation()(TrialsDashboardWithStyle);
