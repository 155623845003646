import React from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Container,
  Snackbar,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, FormikHelpers, Field } from "formik";
//@ts-ignore
import { withRouter } from "react-router";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import { validationSchema } from "./EmailAccountRegistration.web.validation";
import { signupImg, nameIcon, emailIcon, phoneIcon } from "./assets";

interface FormValues {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  phone: string | null | undefined;
  password: string | null | undefined;
}

class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="h-screen"
        >
          <Grid item xs={12} sm={7} md={7} className="hidden sm:block">
            <img
              src={signupImg}
              alt=""
              className="w-1/2 fixed left-0 bottom-0"
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Grid container direction="column" alignItems="center">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  password: "",
                  check: false,
                }}
                validationSchema={validationSchema}
                // @ts-ignore
                onSubmit={(
                  values: FormValues,
                  { setSubmitting }: FormikHelpers<FormValues>
                ) => {
                  console.log("values", values);
                  this.sendOtp(values);
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 500);
                }}
                enableReinitialize
              >
                {(formikProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                  } = formikProps;
                  return (
                    // @ts-ignore
                    <Form>
                      <div className="mb-8 text-center mt-8 sm:mt-0">
                        <Typography variant="h1">
                          Sign Up to CareerHunt
                        </Typography>
                      </div>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid container item xs={12}>
                          <TextField
                            name="firstName"
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              touched.firstName && errors.firstName
                                ? true
                                : false
                            }
                            helperText={
                              touched.firstName && errors.firstName
                                ? errors.firstName
                                : ""
                            }
                            placeholder="First Name"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="p-3"
                                >
                                  <img src={nameIcon} alt="" className="h-5" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <TextField
                            name="lastName"
                            value={values.lastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              touched.lastName && errors.lastName ? true : false
                            }
                            helperText={
                              touched.lastName && errors.lastName
                                ? errors.lastName
                                : ""
                            }
                            placeholder="Last Name"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="p-3"
                                >
                                  <img src={nameIcon} alt="" className="h-5" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <TextField
                            variant="outlined"
                            name="phone"
                            placeholder="Mobile number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.phone && errors.phone ? true : false}
                            helperText={
                              touched.phone && errors.phone ? errors.phone : ""
                            }
                            value={values.phone}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="p-3"
                                >
                                  <img src={phoneIcon} alt="" className="h-5" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <TextField
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && errors.email ? true : false}
                            helperText={
                              touched.email && errors.email ? errors.email : ""
                            }
                            placeholder="Email"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="p-3"
                                >
                                  <img src={emailIcon} alt="" className="h-5" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <TextField
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.password && errors.password ? true : false
                            }
                            helperText={
                              touched.password && errors.password
                                ? errors.password
                                : ""
                            }
                            placeholder="Password"
                            variant="outlined"
                            // @ts-ignore
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? (
                                      <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                      )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid container xs={12} className="px-4 mt-6">
                          <div className="flex items-center">
                            <Checkbox
                              color="primary"
                              checked={values.check}
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e: any): void => {
                                console.log(e.target.checked);
                                setFieldValue(`check`, e.target.checked);
                              }}
                            />
                            <Typography
                              variant="subtitle1"
                              className="text-xs text-center"
                            >
                              I’m in for emails with exciting discounts and
                              personalized recommendations
                            </Typography>
                          </div>
                        </Grid>
                        <Grid container item xs={12}>
                          <div className="mt-6 w-full">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="mt-6"
                              fullWidth
                              disabled={isSubmitting}
                            >
                              Sign Up
                            </Button>
                          </div>
                        </Grid>
                        <Grid item>
                          <span className="text-sm">
                            Already have an account?
                          </span>
                          <Button
                            type="button"
                            color="primary"
                            // @ts-ignore
                            onClick={() => this.props.history.push("/")}
                          >
                            Sign in
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.showToast.show}
          autoHideDuration={3000}
          onClose={this.closeToastHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeToastHandler}
            severity={this.state.showToast.type}
          >
            {this.state.showToast.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}

// @ts-ignore
export default withRouter(EmailAccountRegistrationWeb);
