//@ts-nocheck
import React from "react";
import {
	withStyles, Grid, Button, createStyles, Container, Typography, CardMedia,
	Card, CardActionArea, CardContent, CardHeader, TextField
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import CourseRatingReviewController, { props } from "./CourseRatingReviewController.web";
import { RatingReviewforumSchema } from "./CourseratingReview.web.validation";
import { Formik, Form } from "formik";
import Rating from '@material-ui/lab/Rating';
import CoursesCard from "./CourseCard.web"


const PinkTextField = withStyles({
	root: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ffd0d0"
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ffd0d0"
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ffd0d0"
		},
		"& .MuiOutlinedInput-input": {
			color: "#7764db"
		},
		"&:hover .MuiOutlinedInput-input": {
			color: "#7764db"
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			color: "#7764db"
		},
		"& .MuiInputLabel-outlined": {
			color: "#7764db"
		},
		"&:hover .MuiInputLabel-outlined": {
			color: "#ffd0d0"
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#ffd0d0"
		}
	},
})(TextField);

const CourseDetailBlock = ({ classes, courseDetail, props, showTime }) => {
	return (
		<Grid item xs={12} sm={6} md={6}>
			<Card onClick={() => props.history.push("/home/coursedetail", { courseDetail: courseDetail })}>
				<CardActionArea>
					<CardMedia
						component="img"
						className={classes.suggestedCRR}
						src={courseDetail?.attributes?.thumbnail}
						title="suggested Card title"
					/>
					<Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
						<Grid item xs={4}>
							<Typography variant="subtitle2" className={classes.suggestedCardEnrolledTimeCRR}>
								{courseDetail?.attributes?.enrolled_users}{" "}Enrolled
							</Typography>
						</Grid>
						<Grid item xs={4} />
						<Grid item xs={4}>
							<Typography variant="subtitle1" className={classes.suggestedCardEnrolledTimeCRR}>
								{showTime(courseDetail?.attributes?.total_time)}
							</Typography>
						</Grid>
						<Grid item xs={12} className={classes.courseHeading}>
							<Typography
								variant="body2"
								className={classes.runningCoursesCardTitleCRR}
							>
								{courseDetail?.attributes?.heading} {':'} </Typography>
							<Typography variant="body2" className={classes.suggestedDetailCRR}>{courseDetail?.attributes?.description}</Typography>

						</Grid>

						<Grid item xs={12}>
							<Grid container direction="row" justify="space-between" alignItems="center">
								<Grid item xs={6}>
									<Typography
										variant="caption"
										className={classes.shortlistCardSubTitleCRR}
									>
										{courseDetail?.attributes?.instructors?.data[0]?.attributes?.name}
									</Typography>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
					<CardContent />
				</CardActionArea>
			</Card>
		</Grid>
	)
}

export class CourseRatingReview extends CourseRatingReviewController {

	componentDidMount = () => {
		window.scrollTo(0, 0);
		this.setState({ courseDetail: this.props?.location?.state?.courseDetail }, () => {
			if (localStorage.getItem('token')) {
				this.getBoughtCourses();
			}
		})
	}
	clickSeeMore = () => {
		this.setState(prevState => ({ seeMore: !prevState.seeMore }));
	}
	render() {
		const { classes } = this.props;
		const { boughtCourseList, courseDetail } = this.state;
		return (
			<Container maxWidth="xl" style={{ padding: "2rem" }}>
				<Grid container direction="row" justify="space-between">
					{courseDetail ?
						<>
							<Grid xs={12} md={7}>
								<br />
								<Card variant="outlined" className={classes.shortlistCard}>
									<CardHeader style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} subheader={
										<Typography variant="h3">Review Course</Typography>
									}>
									</CardHeader>
									<CardContent>
										{courseDetail &&
											<Grid container direction="row" className={classes.courseoutlined}
												justify="space-between" spacing={3} >
												<CourseDetailBlock data-testId="CourseDetailBlock" {...{ classes, courseDetail, props: this.props, showTime: this.showTime }} />
												<Grid item xs={12} sm={6} md={6}>
													<Grid container  >

														<Formik
															initialValues={{
																rating: "",
																review: "",

															}}
															validationSchema={RatingReviewforumSchema}
															onSubmit={(values: any) => {
																this.createReview(values);
															}}
														>
															{(formikProps) => {
																const {
																	values,
																	handleSubmit,
																	handleBlur,
																	handleChange,
																	setFieldValue,
																} = formikProps;
																return (
																	<Form autoComplete="off" onSubmit={handleSubmit}>


																		<Grid container direction="row" spacing={2} justify="center">
																			<Grid item xs={12}>
																				<Typography variant="subtitle2" >
																					Rate  Course
																				</Typography>
																			</Grid>
																			<Grid item xs={12}>
																				<Rating
																					name="rating"
																					value={values.rating}
																					onChange={(event, newValue) => {
																						setFieldValue("rating", newValue)
																					}}
																				/>

																			</Grid>
																			<Grid item xs={12}>
																				<Typography variant="subtitle2" >
																					{'Share your experience & lernings'}
																				</Typography>
																			</Grid>
																			<Grid item xs={12}>
																				<PinkTextField
																					variant="outlined"
																					name="review"
																					multiline
																					rows={8}
																					onChange={handleChange}
																					FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
																					onBlur={handleBlur}

																					InputProps={{ classes: { input: classes.txtProps } }}

																					value={values.review}
																					placeholder="Enter Review"
																				/>
																			</Grid>

																			<Grid item xs={12}>

																				{<Button variant="contained" type="submit" className={classes.cancelBtn} >Submit Review </Button>}

																			</Grid>
																		</Grid>


																	</Form>);
															}}
														</Formik>
													</Grid>
												</Grid>

											</Grid>
										}
									</CardContent>

								</Card>
							</Grid>

						</>
						:
						<Grid item> <Typography className="text-center" variant="h3">
							Cart is Empty!
						</Typography> </Grid>
					}
				</Grid>
				<br />
				<br />
				<br />
				<br />
				<Grid container direction="column" alignItems="flex-start" justify="center">
					<Grid item xs={12} className={classes.containerSpacing}>
						<Typography variant="h4">Students Also Bought</Typography>
					</Grid>
				</Grid>
				<Grid container >
					<Grid item xs={12} className="w-full">
						<CoursesCard
							isSmUp={false}
							courseList={boughtCourseList}
						/>
					</Grid>
					<Grid item xs={1} />
				</Grid>

			</Container>
		);
	}
}
//@ts-ignore
const CourseRatingReviewWithRouter = withRouter(CourseRatingReview);
const CourseRatingReviewWithLoader = withLoader(CourseRatingReviewWithRouter);
const CourseRatingReviewToast = withSnackbar(CourseRatingReviewWithLoader);
const CourseRatingReviewWithStyle = withStyles((theme) =>
	createStyles({
		cancelBtn: {
			backgroundColor: '#ff9322',
			height: '30px',
			width: "100%",
			borderRadius: "16px",
			color: '#ffffff',
			'&:hover': {
				backgroundColor: '#ff9322',
				color: '#ffffff'
			}
		},
		shortlistCard: {
			borderRadius: "0.5rem",
		},
		shortlistCardSubTitleCRR: {
			color: "#4a6bd8",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "3rem",
		},
		root: {
			borderRadius: "8px",
			width: '20rem',
			margin: '1rem'
		},
		suggestedCRR: {
			backgroundColor: "#bdb4b4",
			height: "10rem",
			objectFit: 'fill'
		},
		suggestedDetailCRR: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			color: "#3b302e",
			textOverflow: "ellipsis",
			width: "18rem"
		},
		suggestedCardEnrolledTimeCRR: {
			color: "#1c1c1c",
			paddingTop: "20px",
		},
		runningCoursesCardTitleCRR: {
			color: "#1c1c1c",
			whiteSpace: 'nowrap'
		},

		courseoutlined: {
			marginBottom: "2rem"
		},
		Icons: {
			color: "#f48b1b !important",
		},
		Icon: {
			color: "#f48b1b !important",
		},
		courseHeading: {
			display: 'flex'
		}


	})
)(CourseRatingReviewToast);
export default withTranslation()(CourseRatingReviewWithStyle);
