//@ts-nocheck
import React, { useState } from 'react';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Dialog, DialogTitle, DialogContent, createStyles, withStyles, Button, TextField, MenuItem, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
const notEmpty = (x: any) => !!x;
// Validation Schema for create account
export const CareerExpertDialogSchema = Yup.object().shape({
    user_status_id: Yup.string().required("*Current status Required"),
    expert_status_id: Yup.string().required("*Expert status Required"),
    user_subject_id: Yup.string().required("*User Subject Required"),
    mobile_number: Yup.string()
        .min(10, "Enter 10 digit phone no.")
        .max(10, "Enter 10 digit phone no.")
        .matches(/^(\+91-|\+91|0)?\d{10}$/, "Invalid mobile number")
        .required("*Mobile number is required").nullable(),
});
const style = (theme) => createStyles({
    textRoot: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d3c6c6"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d3c6c6"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d3c6c6"
        },
        "& .MuiOutlinedInput-input": {
            color: "black"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "black"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "black"
        },
        "& .MuiInputLabel-outlined": {
            color: "black"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#ffd0d0"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#ffd0d0"
        }
    },
    dialogTitle: {
        backgroundColor: '#f48b1b',
        color: '#fff',
    },
    selectBoard: {
        backgroundColor: "#ffffff !important",
        marginTop: '0px !important',
        color: 'black !important',
        opacity: '10 !important'
    },
    submitBtn: {
        backgroundColor: '#f48b1b',
        color: '#fff',
        width: '10rem',
        height: '3rem',
        '&:hover': {
            backgroundColor: '#f48b1b',
            color: '#fff',
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
    },
    headings: {
        margin: '0.5rem 0rem'
    },
    startbtm: {
        padding: '1rem 0rem'
    },
});
const MuiMenuItem = withStyles({
    root: { color: 'black !important' },
    MuiMenuItem: {
        root: { color: 'black !important', }
    },
})(MenuItem);
const MuiSelect = ({ classes, name, value, error, handleChange, handleBlur, options = [], noneLable }: any) => {
    return (
        <TextField
            classes={{ root: classes.textRoot }}
            variant="outlined"
            name={name}
            value={notEmpty(value) ? value : null}
            defaultValue="none"
            onChange={handleChange}
            SelectProps={{
                MenuProps: { classes: { paper: classes.selectBoard } }
            }}
            onBlur={handleBlur}
            error={notEmpty(error)}
            helperText={error}
            select
        >
            {noneLable && <MuiMenuItem disabled value="none">
                <Typography variant="subtitle2" className={classes.selectDisColor}>
                    {noneLable}
                </Typography>
            </MuiMenuItem>}
            {options.map((x) => (<MuiMenuItem key={x.id} value={x.id}>
                {x.name}
            </MuiMenuItem>))}
        </TextField>
    )
};
const MuiTextInput = ({ classes, name, value, error, handleChange, handleBlur }: any) => {
    return (
        <TextField
            classes={{ root: classes.textRoot }}
            variant="outlined"
            name={name}
            value={notEmpty(value) ? value : null}
            onChange={handleChange}
            onBlur={handleBlur}
            error={notEmpty(error)}
            helperText={error}
        />
    )
};
export const CareerExpertDialogModel = ({ open = false, onClose = () => { }, classes, onSubmit, allUserStatus, allExpertStatus, allUserSubject }) => {
    const [localOpen, setLocalOpen] = useState(open);
    const handleClose = () => {
        onClose();
        setLocalOpen(false);
    };
    const getOptions = type => {
        if (type === 'user_status_id') {
            return allUserStatus.map(x => ({ name: x.attributes.status, id: x.attributes.id }));
        } else if (type === 'expert_status_id') {
            return allExpertStatus.map(x => ({ name: x.attributes.status, id: x.attributes.id }));
        } else if (type === 'user_subject_id') {
            return allUserSubject.map(x => ({ name: x.attributes.stream, id: x.attributes.id }));
        }
        return [];
    }
    return (
        <Dialog
            open={localOpen}
            onClose={handleClose}
            PaperProps={{
                style: {
                    minHeight: "500px",
                    width: 650,
                    backgroundColor: "#ffffff",
                    boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
                    borderRadius: 0,
                },
            }}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>Enter Details
                <IconButton aria-label="close" className={classes.closeButton} data-testId="handleClose" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        user_status_id: '',
                        expert_status_id: '',
                        user_subject_id: '',
                        mobile_number: '',
                    }}
                    validationSchema={CareerExpertDialogSchema}
                    onSubmit={(values: any) => {
                        onSubmit(values)
                    }}
                >
                    {(formikProps) => {
                        const {
                            values,
                            isValid,
                            errors,
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                        } = formikProps;
                        return (
                            <Form autoComplete="off" onSubmit={handleSubmit}>
                                <Grid container direction="row" spacing={2} justify="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.headings} variant="h3">I am Currently</Typography>
                                        <MuiSelect {...{ classes, noneLable: '-Select-', name: 'user_status_id', value: values.user_status_id, handleChange, error: errors.user_status_id, options: getOptions('user_status_id') }} />
                                        <Typography className={classes.headings} variant="subtitle2">Please Select Your Current Status</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.headings} variant="h3">I am Looking For</Typography>
                                        <MuiSelect {...{ classes, name: 'expert_status_id', value: values.expert_status_id, handleChange, error: errors.expert_status_id, options: getOptions('expert_status_id') }} />
                                        <Typography className={classes.headings1} variant="subtitle2">Please Select Your Current Status</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.headings} variant="h3">Subject / Stream</Typography>
                                        <MuiSelect {...{ classes, noneLable: 'Select', name: 'user_subject_id', value: values.user_subject_id, handleChange, error: errors.user_subject_id, options: getOptions('user_subject_id') }} />
                                        <Typography className={classes.headings} variant="subtitle2">
                                            Please Select subject / stream you have studied
                                            <Typography variant="subtitle2">
                                                -select any if not Decided
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.headings} variant="h3">Mobile Number</Typography>
                                        <MuiTextInput {...{ classes, name: 'mobile_number', value: values.mobile_number, handleChange, error: errors.mobile_number }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="center">
                                            <Grid className={classes.startbtm} item xs={3}>
                                                {localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.submitBtn} >Start Here</Button>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};
export default withStyles(style)(CareerExpertDialogModel);