//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
//@ts-ignore
import { withRouter } from "react-router";
import AssessmentViewAllWebController, {
  Props,
} from "./AssessmentViewAllController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { bannerInImg, groupImg } from "./assets";
//@ts-ignore
import CategorysearchWeb from "../../categoriessubcategories/src/Categorysearch.web";
import { quizAssessmentcss, getCaption, clearFilterOptions } from './QuizAssessmentCommonutility'
import { QuizAssessmentSearchBlock, HeaderTitle, RowTextContainer } from './QuizAssesmentsUtility.web'


const ShowQuizRowContainer = ({ classes, assessmentList, gotoAssessment, isLogin, currentPage, totalPage, onPageChange }) => {
  return (
    <Grid container xs={12} className={classes.paddingExamArVi}>
      <Grid container className={classes.paddingExamArVi}>
        <Grid container direction="row" xs={12} className="w-full">
          {assessmentList &&
            assessmentList.map((e: any, index: number) => (
              <Grid item key={index} >
                <Grid className={classes.quizBox} container direction="column" alignItems="center" justify="space-between">
                  <Grid className={classes.quizBox2} container direction="column" justify="space-between">
                    {e.attributes.heading && <Typography className={classes.quizHeading}>{e.attributes.heading}</Typography>}
                    {isLogin() &&
                      <>
                        <RowTextContainer classes={classes} text1="Attempts" text2={e?.attributes?.attempt_count || 0} />
                      </>}
                    <RowTextContainer classes={classes} text1="Questions" text2={e?.attributes?.test_questions?.data?.length || 0} />
                    <RowTextContainer classes={classes} text1="Time" text2={`${e.attributes.timer ? e.attributes.timer : 0} mins`} />
                  </Grid>
                  <Grid item className={classes.startassessment}>
                    <Button
                      data-testId="gotoAssessment"
                      className={classes.quizBtn}
                      onClick={() => gotoAssessment(e.id)}
                    >
                      <Typography className={classes.quizBtnTxt} variant="body2">Start Assessment</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
        {totalPage > 1 &&
          <Grid container>
            <Grid xs={11} className={classes.paginator}>
              <Pagination page={currentPage} data-testId="onPageChange" onChange={onPageChange} count={totalPage} variant="outlined" shape="rounded" />
            </Grid>
          </Grid>
        }
      </Grid>
      {!(assessmentList && assessmentList.length > 0) &&
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item>
            <Typography variant="body2" className="text-center">Result not found </Typography>
          </Grid>
        </Grid>}
    </Grid>);
};

export class AssessmentViewAllWeb extends AssessmentViewAllWebController {
  constructor(props: Props) {
    super(props);
  }

  setSearcdata = (e: any) => {
    this.setState({ searchValue: e.target.value }, () => {
      this.loadData();
    })
  }

  gotoAssessment = (qId: any) => {
    this.props.history.push(`/home/assessment/${qId}`);
  }

  onPageChange = (e, page) => {
    this.setState({ currentPage: page }, () => {
      this.loadData();
    });
  }

  handleToggle = (ele: any, filterinfo: any, index: any, id: any) => {
    if (filterinfo[index].value.indexOf(ele.id) == -1) {
      if (id == 3) {
        filterinfo[index].value = [ele.id]
      } else {
        filterinfo[index].value.push(ele.id)
      }
    } else {
      filterinfo[index].value.splice(filterinfo[index].value.indexOf(ele.id), 1)
    }
    this.setState({ filterdata: filterinfo })
  }
  applyFilter = (data: any) => {
    this.setState({ pageNo: 0 }, () => {
      this.getAssessmentContentsBysearchCriteria(data)
    })
  }
  clearFilter = (filterinfo: any) => {
    filterinfo = clearFilterOptions(filterinfo)
    this.setState({ filterdata: filterinfo, pageNo: 0, ratingValue: null, selectedType: 0 }, () => {
      this.getAssessmentFilterOptions('content_type', this.state.filterdata)
      this.getAssessmentContentsBysearchCriteria(this.state.filterdata)
    })
  }

  handleDateChange = (e: any, filterinfo: any, index: any) => {
    filterinfo[index][e?.target?.id] = e.target?.value
    this.setState({ filterdata: filterinfo })
  }

  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        if (data.key != 'date') {
          this.getAssessmentFilterOptions(data.key, this.state.filterdata)
        }
      })
    }
  }

  handleRatingChange = (e: any, newValue: any) => {
    this.setState({ ratingValue: newValue })
  }

  render() {
    const { classes } = this.props;
    const { assessmentList, currentPage, totalPage } = this.state;
    const { gotoAssessment, onPageChange } = this;
    const isLogin = () => localStorage.getItem('token');
    return (
      <React.Fragment>
        <Container maxWidth="xl" disableGutters={true}>
          <Grid item xs={12} className={classes.rootname}>
            <img alt="" className="h-full" src={bannerInImg} />
          </Grid>
          <Container className={classes.videoCont2}>
            <Grid container direction="row" alignItems="center" justify="center" className={classes.rootsearch}>
              <QuizAssessmentSearchBlock {...{
                classes,
                setSearcdata: this.setSearcdata,
                placeholderetext: "Search Assessment here"
              }}
              />
            </Grid>
            <br />
            <br />
            {/* quiz */}
            <Grid container direction="row" >
              <Grid className={classes.lhsPanel} item >
                <Paper>
                  {/* @ts-ignore */}
                  <CategorysearchWeb
                    onclick={this.handleClick}
                    handleToggle={this.handleToggle}
                    applyFilter={this.applyFilter}
                    clearFilter={this.clearFilter}
                    handleDateChange={this.handleDateChange}
                    selectedType={this.state.selectedType}
                    filterdata={this.state.filterdata}
                    valuetext={this.valuetext}
                    ratingValue={this.state.ratingValue}
                    handleRatingChange={this.handleRatingChange}
                  />
                </Paper>
              </Grid>
              <Grid className={classes.rhsPanel} item >
                <HeaderTitle {...{ caption: getCaption(this.props?.match?.params?.heading, "Assessment"), classes, assessmentList }} />
                <ShowQuizRowContainer data-testId="ShowQuizRowContainer" {...{ classes, gotoAssessment, assessmentList, isLogin, currentPage, totalPage, onPageChange }} />
              </Grid>
            </Grid>
            <br />
            <br />

          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

// @ts-ignore
const AssessmentViewAllWebWithRouter = withRouter(AssessmentViewAllWeb);
const AssessmentViewAllWebWithLoader = withLoader(AssessmentViewAllWebWithRouter);
const AssessmentViewAllWebToast = withSnackbar(AssessmentViewAllWebWithLoader);
const AssessmentViewAllWebWithStyle = withStyles((theme) =>
  createStyles({
    ...quizAssessmentcss(theme, groupImg),
    paginator: {
      marginTop: '2rem',
      marginBottom: '4rem',
      justifyContent: 'center',
      display: 'flex',
    },
    startassessment: {
      height: '25%',
      margin: 'auto'
    },
    rootname: {
      marginTop: '-0.25rem'
    },
    rootsearch: {
      height: '100%',
      width: '100%'
    },
    cardItem: {
      marginTop: '0.5rem'
    },
    lhsPanel: {
      width: '25%',
      padding: '1rem',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    rhsPanel: {
      width: '75%',
      padding: '1rem',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    titleroot: {
      marginBottom: '1.5rem',
    }
  })
)((AssessmentViewAllWebToast))
export default withTranslation()(AssessmentViewAllWebWithStyle);