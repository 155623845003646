export const volicityNine = require("../assets/volicity-9.png");
export const profileImg = require("../assets/profile.png");
export const googleLogo = require("../assets/google-icon.png");
export const facebookLogo = require("../assets/facebook-2.png");
export const appleLogo = require("../assets/apple.png");
export const linkedinLogo = require("../assets/linked-in.png");
export const downArrow = require("../assets/down-right.png");
export const viewAll = require("../assets/view-all.png");
export const arrowUp = require("../assets/up-arrow.png");
export const eye = require("../assets/eye.png");
export const userImage = require("../assets/eye.png");
