
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  open: boolean;
  showTagSearch: boolean;
  openDiscussionDialog: boolean;
  tagVal: any[],
  question: any,
  dislikeActive: boolean,
  dislike: any,
  like: any,
  likeActive: boolean,
  getQuestions: any[],
  likeList: any[],
  count: any,
  start: any,
  hasMore: boolean,
  heading: string,
  categories: any[],
  tagsList: any[],
  anchorEl: any,
  problemBlock: boolean,
  selectedProblem: any,
  problemType: any[],
  openReportDialog: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ViewAllController extends BlockComponent<Props, S, SS> {
  getTagListApiCallId: string = "";
  getLikeListApiCallId: string = "";
  getCategoriesApiCallId: string = "";
  postQuestionApiCallId: string = "";
  getQuestionsApiCallId: string = "";
  postLikeApiCallId: string = "";
  getInfiniteQuestionApiCallId: string = "";
  postReportApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      open: false,
      showTagSearch: false,
      openDiscussionDialog: false,
      tagVal: [],
      question: {},
      tagsList: [],
      dislikeActive: false,
      dislike: 0,
      like: 0,
      likeActive: false,
      getQuestions: [],
      likeList: [],
      count: 20,
      start: 1,
      hasMore: false,
      heading: "",
      categories: [],
      anchorEl: null,
      problemBlock: false,
      selectedProblem: '',
      problemType: ['Voilence', 'Harrasment', 'Self injury sucide', 'False imformation', 'spam', 'Hate speech', 'Sales and advertisment', 'terrorism', 'Something else'],
      openReportDialog: false
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getQuestionsApiCallId:
          this.quesApi(responseJson);
          break;
        case this.getInfiniteQuestionApiCallId:
          this.infiniteQues(responseJson);
          break;
        case this.postQuestionApiCallId:
          this.postQues(responseJson);
          break;
        case this.postLikeApiCallId:
          this.postLikeUi(responseJson);
          break;
        case this.getLikeListApiCallId:
          this.likeListUi(responseJson);
          break;
        case this.getCategoriesApiCallId:
          this.categoriesUi(responseJson);
          break;
        case this.getTagListApiCallId:
          this.tagListUi(responseJson);
          break;
        case this.postReportApiCallId:
          this.postReport(responseJson);
          break;
      }
    }
  }
  // Customizable Area Start
  quesApi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.props.hideLoader();
      if (responseJson?.data.length > 0) {
        this.setState({ hasMore: true })
      } else {
        this.setState({ hasMore: false })
      }
      this.setState({ getQuestions: responseJson.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  infiniteQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.props.hideLoader();
      if (responseJson?.data.length > 0) {
        this.setState({ hasMore: true })
      } else {
        this.setState({ hasMore: false })
      }
      this.setState({ getQuestions: this.state.getQuestions.concat(responseJson.data) });
    }
    else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  createReport = (value: any) => {
    const headers = { "token": localStorage.getItem("token") || "" };
    const apiEndPoint = configJSON.createReport;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postReportApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let formdata = new FormData();
    value?.title && formdata.append(`data[title]`, value?.title);
    value?.categories && formdata.append(`data[type_problem]`, value?.categories);
    value?.description && formdata.append(`data[description]`, value?.description);
    value?.tagVal?.forEach((ele: any) => {
      formdata.append(`data[images][]`, ele[0]);
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  postReport = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ openReportDialog: false });
      this.props.openToastHandler("success", 'Report Submitted Successfully'!);
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  postQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ openDiscussionDialog: false });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  postLikeUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.getLikes();
      if (this.state.count <= 20) {
        this.getInitialQuestions();
      } else {
        this.getInfiniteQuestions();
      }
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  likeListUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ likeList: responseJson?.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  categoriesUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ categories: responseJson.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  tagListUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      const temp = responseJson?.data.map((e: any) => e?.attributes?.name);
      this.setState({ tagsList: temp });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  openToastHandler = (responseJson: any) => {
    if (responseJson.errors[0].token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
  }
  getTagList = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTagListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTagApiUrl
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleClose = () => {
    this.setState({ openDiscussionDialog: false })
  }
  openPostDialog = () => {
    this.setState({ openDiscussionDialog: true });
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.openPostDialog();
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  postQuestion = (status: any) => {
    const headers = { "token": localStorage.getItem("token") };
    const apiEndPoint = configJSON.postQuestion;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let formdata = new FormData();
    this.state.question?.title && formdata.append(`question[title]`, this.state.question.title);
    this.state.question?.categories && formdata.append(`question[sub_category_id]`, this.state.question.categories);
    this.state.question?.description && formdata.append(`question[description]`, this.state.question.description);
    formdata.append(`question[status]`, status);
    formdata.append(`question[closed]`, '0');
    this.state.question?.tagVals && formdata.append(`question[tag_list]`, this.state.question.tagVals.map((e: any) => e));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  createPost = (value: any) => {
    this.setState({ question: value });
    const token = localStorage.getItem('token');
    let status = "";
    if (token) {
      status = "publish";
      this.postQuestion(status);
    } else {
      this.openDialog(true);
      status = "draft";
    }
  }
  categoryCheck = () => {
    //@ts-ignore
    let heading = this.props?.location?.state?.heading;
    this.setState({ heading: heading });
    if (heading == 'Popular Discussions') {
      heading = 'is_popular=true';
      return heading;
    } else if (heading == 'Trending Discussions') {
      heading = 'is_trending=true';
      return heading;
    } else {
      heading = 'is_recommended=true';
      return heading;
    }
  }
  getInitialQuestions = (searchInfo?: any) => {
    this.props.showLoader();
    const heading = this.categoryCheck();
    this.setState({ hasMore: true });
    const { count, start } = this.state;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getQuestionsApiCallId = requestMessage.messageId;
    const search = searchInfo ? `&search=${searchInfo}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showQuestionApiEndPoint}?${heading}&page=${start}&per_page=${count}${search}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getInfiniteQuestions = () => {
    this.props.showLoader();
    this.setState({ hasMore: true });
    const { count, start } = this.state
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getInfiniteQuestionApiCallId = requestMessage.messageId;
    const heading = this.categoryCheck();
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showQuestionApiEndPoint}?${heading}&is_popular=true&page=${start + 1}&per_page=${count}`
    );
    this.setState({ start: start + 1 })
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getLikes = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getLikeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLikePost
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDisLike = (id: any, unlike: any) => {
    this.handleLike(id, unlike);
  }
  handleLike = (id: any, like: any) => {
    const headers = {
      "token": localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    const apiEndPoint = configJSON.postLikesApiEndPoint;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postLikeApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    const data = {
      "like": {
        "likeable_type": "question",
        "likeable_id": id,
        "is_like": like
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesAPIEndPoint
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  selectProblemType = (prob: any) => {
    this.setState({ problemBlock: true, selectedProblem: prob })
  }
  // Customizable Area End
}