import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { displayTimeInHoursminutes } from "./CommonFunctions"

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    lessonid: any;
    hideLoader: any;
    showLoader: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  seeMore: boolean;
  boughtCourseList: any;
  courseDetail: any;
  coursesId: any;
  getAllBookmarked: any;
  open: any;
  openBuySnack: any;
  vedioLink: any;
  purchased: any;
  cartCourses: any;
  inCart: boolean;
  freetrailOpen: any;
  similarCoursesList: any;
}
interface SS {
  id: any;
}

export default class CoursesDetailPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSuggestedCourseApiCallId: string = "";
  addBookmarkApiCallId: string = "";
  addCourseApiCallId: string = "";
  startFreeTrailApiCallId: string = "";
  getCartAddCourseApiCallId: string = "";
  getSimilarCourseApiCallId: string = "";
  courseProgressBarApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      seeMore: false,
      boughtCourseList: [],
      courseDetail: {},
      coursesId: [],
      getAllBookmarked: [],
      open: false,
      openBuySnack: false,
      vedioLink: '',
      purchased: false,
      cartCourses: [],
      inCart: false,
      freetrailOpen: false,
      similarCoursesList: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.startFreeTrailApiCallId) {
        this.handleStartFreeTrailApiResp(responseJson);
      }
      if (apiRequestCallId === this.courseProgressBarApiCallId) {
        this.handleCourseProgressbarApiResp(responseJson);
      }
      this.handleSuggestedCartAddCourseAPIresp(apiRequestCallId, responseJson)
      this.handleAddbookmakAPIresp(apiRequestCallId, responseJson)
    }
  }

  courseProgressBar = (course_id: any, lesson_id: any, played_hours: any) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const headers = {
      "token": token,
      "Content-Type": configJSON.apiContentType
    }
    const data = {
      "course_id": course_id ?? 0,
      "lession_id": lesson_id ?? "",
      "user_id": userId ?? "",
      "Total_hours_seen": played_hours ?? "",
    }
    if (token) {
      const apiEndPoint = configJSON.addCourseProgress
      const method = configJSON.httpPostType;
      this.courseProgressBarApiCallId = sendAPIRequest(
        apiEndPoint,
        {
          method,
          body: { ...data },
          headers: headers
        },
      );

    }
  }

  handleCourseProgressbarApiResp = (responseJson: any) => {
    if (responseJson && responseJson?.message && !responseJson?.errors) {
      this.props.openToastHandler("success", responseJson?.message);
    } else if (responseJson && responseJson.errors) {
      if (responseJson.errors[0] && responseJson.errors[0].token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      else if (responseJson?.errors?.course_id) {
        this.props.openToastHandler("error", responseJson?.errors?.course_id);
      }
    }
  }

  handleTokenexpire = (responseJson: any) => {
    if (responseJson.errors[0] && responseJson.errors[0].token) {
      this.clearlocalStorage(responseJson?.errors[0]?.token)
    }
  }

  handleStartFreeTrailApiResp = (responseJson: any) => {
    if (responseJson && responseJson?.message && !responseJson?.errors) {
      this.setState({ freetrailOpen: false });
      this.props.openToastHandler("success", responseJson?.message);
    } else if (responseJson && responseJson.errors) {
      this.setState({ freetrailOpen: false });
      if (responseJson.errors[0] && responseJson.errors[0].token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      else if (responseJson?.errors?.course_id) {
        this.setState({ freetrailOpen: false });
        this.props.openToastHandler("error", responseJson?.errors?.course_id);
      }
    }
  }

  handleSuggestedCartAddCourseAPIresp = (apiRequestCallId: any, responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (apiRequestCallId === this.getSuggestedCourseApiCallId) {
        this.setState({ boughtCourseList: responseJson.data });
        this.props.hideLoader();
      }
      else if (apiRequestCallId === this.getCartAddCourseApiCallId) {
        this.handlegetCartAddCourseResponse(responseJson)
      }
      else if (apiRequestCallId === this.getSimilarCourseApiCallId) {
        this.handleSimilarCourseApiResponse(responseJson)
      }
    } else if (responseJson && responseJson.errors) {
      this.handleTokenexpire(responseJson)
    }
  }
  handleAddbookmakAPIresp = (apiRequestCallId: any, responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      if (apiRequestCallId === this.addCourseApiCallId) {
        this.props.history.push("/home/checkout");
      }
    } else if (responseJson && responseJson.errors) {
      this.handleAddbookmakAPIErrorResp(responseJson)
    }
  }
  handleAddbookmakAPIErrorResp = (responseJson: any) => {
    if (responseJson?.errors[0] && responseJson.errors[0].token) {
      this.clearlocalStorage(responseJson?.errors[0]?.token)
    }
    else if (responseJson?.errors[0] && responseJson?.errors[0]?.error) {
      this.props.openToastHandler("error", responseJson?.errors[0]?.error);
    }
    else if (responseJson?.errors?.course_id) {
      this.props.openToastHandler("error", responseJson?.errors?.course_id);
    }
  }
  handlegetCartAddCourseResponse = (responseJson: any) => {
    if (responseJson?.data && responseJson?.data?.attributes) {
      let courseIncart = responseJson.data.attributes.course_ids.indexOf(parseInt(this.state.courseDetail?.id))
      this.setState({ cartCourses: responseJson.data, inCart: courseIncart != -1 ? true : false, purchased: this.state.courseDetail?.attributes?.is_purchased ? true : false });
      this.getBoughtCourses(responseJson?.data?.attributes?.course_ids[0]);
    }

  }

  handleSimilarCourseApiResponse = (responseJson: any) => {
    if (responseJson?.data && responseJson?.data?.attributes) {
      this.setState({ similarCoursesList: responseJson.data });
    }
  }
  clearlocalStorage = (message: any) => {
    this.props.openToastHandler("error", message + `please login`!);
    setTimeout(() => {
      localStorage.clear();
      this.props.history.push('/home');
    }, 2000)
  }
  getBoughtCourses = (id: any) => {
    this.props.showLoader();
    const token = localStorage.getItem('token');
    this.getSuggestedCourseApiCallId = sendAPIRequest(configJSON.getBoughtCourseUrl + `/bought_courses_list`,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  addToCart = (id: any, isInCart: any) => {
    const token = localStorage.getItem('token');
    if (isInCart) {
      this.props.history.push("/home/checkout");
    }
    else {
      if (token) {
        const selectedCourseId = [...this.state.coursesId, id]
        const courseid = selectedCourseId.map(item => {
          return JSON.parse(item)
        });

        const headers = {
          "token": token,
          "Content-Type": configJSON.apiContentType
        }
        const data = {
          "data": {
            "course_ids": courseid
          }
        }
        const apiEndPoint = configJSON.addGetCartCourse;
        const method = configJSON.httpPostType;
        this.addCourseApiCallId = sendAPIRequest(
          apiEndPoint,
          {
            method,
            body: { ...data },
            headers: headers
          },
        );
      }
      else {
        this.openDialog(true);
      }
    }
  }
  addFreeTrailDialog = (id: any) => {
    if (localStorage.getItem('token')) {
      this.setState({ freetrailOpen: true })
    }
    else {
      this.openDialog(true);
    }

  }
  addFreeTrail = () => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.apiContentType
    }
    const data = {
      "data": {
        "course_id": this.state.courseDetail.id
      }
    }
    const apiEndPoint = configJSON.startCourseFreeTrail;
    const method = configJSON.httpPostType;
    this.startFreeTrailApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...data },
        headers: headers
      },
    );
  }
  getAllCoursesInCart = () => {
    const token = localStorage.getItem('token');
    this.getCartAddCourseApiCallId = sendAPIRequest(configJSON.addGetCartCourse,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  gotoReview = (courseDetails: any) => {
    this.props.history.push("/home/courseratereview", { courseDetail: courseDetails });
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }

  showTime = (time: any) => displayTimeInHoursminutes(time)

  getSimilarCourses = (id: any) => {
    const token = localStorage.getItem('token');
    this.getSimilarCourseApiCallId = sendAPIRequest(configJSON.getSimilarCourseUrl,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
}
