import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // url: any;
  // pip: boolean;
  // playing: boolean;
  // controls: boolean;
  // light: boolean;
  // volume: number;
  // muted: boolean;
  // played: number;
  // loaded: number;
  // duration: number;
  // playbackRate: number;
  // loop: boolean;
  // seeking: boolean;
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VideosControllerWeb extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      // url: null,
      // pip: false,
      // playing: true,
      // controls: false,
      // light: false,
      // volume: 0.8,
      // muted: false,
      // played: 0,
      // loaded: 0,
      // duration: 0,
      // playbackRate: 1.0,
      // loop: false,
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
