import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { Category } from "./QuizAssessmentCommonutility"


export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    openToastHandler: any;
    openDialog: any;

    // Customizable Area Start
    // Customizable Area End
  };


interface S {
  // Customizable Area Start
  categoriesData: Category[];
  popularQuizList: [];
  trendingQuizList: [];
  recommendQuizList: [];
  selectedType: any;
  selectedChip: any;
  searchValue: any;
  // Customizable Area End
}
interface SS {
  id: any;
}


export default class QuizViewallWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  getViewAllCategoriesApiCallId: string = "";
  getPopularQuizViewAllApiCallId: string = "";
  getTrendingQuizViewAllApiCallId: string = "";
  getRecommendQuizViewAllApiCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      categoriesData: [],
      popularQuizList: [],
      trendingQuizList: [],
      recommendQuizList: [],
      selectedType: 0,
      searchValue: '',
      selectedChip: '',

    };

    // Customizable Area End
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getCategories();
    this.setState({ selectedChip: this.props?.match?.params?.categoryId }, () => {
      this.loadData();
    })
  }

  loadData = () => {
    this.getPopularQuiz();
    this.getTrendingQuiz();
    if (localStorage.getItem('token')) {
      this.getRecommendQuiz();
    }

  };

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getViewAllCategoriesApiCallId:
            this.handleCategoriesApiCallIdApiResponce(responseJson);
            break;
          case this.getPopularQuizViewAllApiCallId:
            this.handlePopularQuizViewAllApiCallIdApiResponce(responseJson);
            break;
          case this.getTrendingQuizViewAllApiCallId:
            this.handleTrendingQuizViewAllApiCallIdApiResponce(responseJson);
            break;
          case this.getRecommendQuizViewAllApiCallId:
            this.handleRecommendQuizViewAllApiCallIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearQuizViewAllLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }


  handleCategoriesApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ categoriesData: responseJson.data });
    }
  };
  handlePopularQuizViewAllApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ popularQuizList: responseJson.data });
    }
  };
  handleTrendingQuizViewAllApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ trendingQuizList: responseJson.data });
    }
  };
  handleRecommendQuizViewAllApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ recommendQuizList: responseJson.data });
    }
  };
  clearQuizViewAllLocalstorage = (responseJson: any) => {
    if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `. please login again`!);
    }
  };


  getCategories = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getViewAllCategoriesApiCallId = sendAPIRequest(`${configJSON.categoriesAPIEndPoint}?show_on_landing_page=true`,
      { headers: header })
  }
  getPopularQuiz = () => {
    const { searchValue, selectedChip } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getPopularQuizViewAllApiCallId = sendAPIRequest(`${configJSON.getQuizUrl}?page=1&per_page=10&is_popular=true&category[]=${selectedChip}${search}`,
      { headers: header })
  }
  getTrendingQuiz = () => {
    const { searchValue, selectedChip } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getTrendingQuizViewAllApiCallId = sendAPIRequest(`${configJSON.getQuizUrl}?page=1&per_page=10&is_trending=true&category[]=${selectedChip}${search}`,
      { headers: header })
  }

  getRecommendQuiz = () => {
    const { searchValue, selectedChip } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getRecommendQuizViewAllApiCallId = sendAPIRequest(`${configJSON.getQuizUrl}?page=1&per_page=10&is_recommended=true&category[]=${selectedChip}${search}`,
      { headers: header })
  }
}
