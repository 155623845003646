//@ts-nocheck
//@ts-ignore
import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { windowWidth } from '../../../../../framework/src/Helpers';
interface Props {

}

export default class Banner extends React.Component {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View>
                <Image
                    style={styles.image}
                    resizeMode='contain'
                    source={require('../../../assets/Banner.png')} />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    image: {
        width: windowWidth,
        marginStart: 20,
        marginEnd: 20,
        marginTop: 8,
        height: 130,
        alignSelf: 'center'

    }
});