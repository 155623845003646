//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, createStyles, Typography,
  CardMedia, Card, CardActionArea
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import {
  Props,
} from "./ExamViewallController.web";
//@ts-ignore
import insane from 'insane';
import { dummyImage } from "./assets";

export class ExamsList extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, examsList, pages } = this.props;
    return (
      <>
        <container direction="column" alignItems="center" justify="center">
          <Grid item xs={12} className={classes.containerSpacing}>
            <Grid container>
              <Grid item md={11} xs={12}>
                <Grid container spacing={2}>
                  {examsList && examsList.length ? examsList.map((_data: any, idx: any) => (
                    <Grid item xs={12} md={6} sm={6} lg={5} key={idx}>
                      <Card className={classes.root} onClick={() => this.props.history.push("/home/exam/" + _data?.id)}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            className={classes.suggested}
                            src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                            title="suggested Card title"
                          />
                          <Grid container spacing={2} className={classes.cardText}>
                            <Grid item xs={12} >
                              <Typography
                                variant="h3"
                                className={classes.runningCoursesCardTitle}
                              >
                                {_data?.attributes?.heading} </Typography>
                              {/* <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography> */}
                              {_data?.attributes?.description?.length > 30 ?
                                <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                  <span dangerouslySetInnerHTML={{
                                    __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                  }}></span>
                                </Typography>
                                :
                                <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                                  <span dangerouslySetInnerHTML={{
                                    __html: insane(`<p>${_data?.attributes?.description?.replace(/(<([^>]+)>)/ig, " ").trim()}</p>`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                                  }}></span>
                                </Typography>
                              }
                            </Grid>
                            <Grid item xs={12} container direction="row" alignItems="center" justify="space-between" >
                              <Grid item xs={6}>
                                <Typography
                                  variant="caption"
                                  className={classes.shortlistCardSubTitle}
                                >
                                  {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                    :
                    <Grid item>
                      <Typography variant="body2">No results to display, Refine your search</Typography>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {pages > 1 && <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
              <Pagination count={pages} variant="outlined" shape="rounded" onChange={(event, val) => this.props.setPage(val)} />
            </Grid>}
          </Grid>
        </container>
      </>
    )
  }
}
//@ts-ignore
const ExamsListWebWithRouter = withRouter(ExamsList);
export default withStyles((theme) =>
  createStyles({
    root: {
      borderRadius: "8px",
      height: "100%",
      margin: '1rem'
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTime: {
      color: "#3b302e",
      paddingTop: "12px",
      fontWeight: '500',
      fontFamily: 'Montserrat',
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "100%"
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c"
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
      fontWeight: '500',
      fontFamily: 'Montserrat',
    },
    examDes: {
      display: 'inline-flex',
      "& > span": {
        display: 'flex !important'
      }
    },
    cardText: {
      paddingLeft: '1rem',
      paddingRight: '.5rem',
      marginTop: '0.5rem'
    },
  })
)(ExamsListWebWithRouter);