//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  Hidden,
  createStyles,
  Typography,
  Button,
  Breadcrumbs, Link, IconButton,
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import insane from 'insane';
//@ts-ignore
import { withRouter } from "react-router";
import BlogDetailsController, { Props } from './BlogDetailsController.web';
import { withTranslation } from 'react-i18next';
import { dummyImage, courseImg, fbImg, linkedInImg } from "./assets";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
//@ts-ignore
import "react-responsive-carousel/lib/styles/carousel.min.css";
import withLoader from "../../../components/src/withLoader.web";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { FacebookShareButton, LinkedinShareButton } from "react-share";

const RecommendedBlogs = ({ classes, ListItems, history }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center" className={classes.contianerdir}>
        {localStorage.getItem('token') &&
          <Grid item xs={12}>
            <Hidden smDown><Typography align="left" className={classes.blogheading} variant="h4">Recommended Blogs</Typography></Hidden>
            <Hidden mdUp><Typography align="left" className={classes.blogheading} variant="h3">Recommended Blogs</Typography></Hidden>
          </Grid>
        }
        <Grid container direction="column" justify="flex-start">
          <>
            {ListItems && ListItems.length > 0 ? ListItems.map((e: any) => {
              return (
                <Grid container direction="row" xs={12} sm={12} md={11} lg={11} xl={11} alignItems="flex-start" spacing={2} className={classes.blogGrid}>
                  <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
                    {e.attributes.image ?
                      <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image} />
                      :
                      <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
                    }
                  </Grid>
                  <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h3">{e.attributes.name}</Typography>
                      </Grid>
                      <Grid item>
                        {e.attributes.description.length > 300 ?
                          <div className="mt-1.5">
                            <Typography variant="subtitle2" color="primary" >
                              <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                                __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/blogsdetails/" + e.id + "'>[Read more]</a></button>")
                              }}></span>
                            </Typography></div>
                          : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" className={classes.divsss}>
                            <span dangerouslySetInnerHTML={{
                              __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/blogsdetails/" + e.id + "'>[Read more]</a></button>")
                            }}></span>
                          </Typography></div>
                        }
                      </Grid>
                      {e?.attributes?.author &&
                        <Grid item>
                          <Typography className={classes.authoreName} variant="subtitle2" data-testId="authorDetails" onClick={() => history.push('/home/blogprofile/' + e?.attributes?.author_id + '/' + e.id)}>By - {e?.attributes?.author}</Typography>
                        </Grid>}
                    </Grid>
                  </Grid>
                </Grid>
              )
            }) :
              <Grid item>
                {localStorage.getItem('token') && <Typography variant="body2">Will Update You Soon</Typography>}
              </Grid>
            }
          </>
        </Grid>
      </Grid>
    </>
  )
}
export class BlogDetails extends BlogDetailsController {
  constructor(props: Props) {
    super(props);
  }
  //@ts-ignore
  async componentDidMount() {
    this.recommendedBlogs();
    this.BlogDetails();
  }

  publishDate = (created_at: any) => {
    if (created_at) {
      let weeks = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      let months = ['January', 'February', 'March', 'April	', 'May', 'June', 'July', 'August', 'September', 'October	', 'November', 'December']
      let date = new Date(created_at)
      let shortYear = date.getFullYear();
      return months[date.getMonth()] + ' ' + weeks[date.getDay()] + ',  ' + shortYear
    }
  }
  render() {
    //@ts-ignore
    const self = this;
    const { follow, unfollow } = self;
    //@ts-ignore
    const { classes } = this.props;
    const { blogDetail } = this.state;
    return (<>
      <Container maxWidth="xl" className={classes.p0}>
        <Container maxWidth="lg" className={classes.bgnav}>
          <Grid container direction="row" alignItems="center" justify="center" >
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={11}>
              <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                <Link underline="hover" color="inherit">
                  <Typography className={classes.overView} variant='body2'>Overview </Typography>
                </Link>
                <Link underline="hover" color="inherit" data-testId="blogLink" onClick={() => this.props.history.push("/home/blogs")}>
                  <Typography className={classes.bloglink} variant='body2'>Blog</Typography>
                </Link>
                <Link underline="hover" color="inherit" >
                  <Typography variant='body2'>{blogDetail?.attributes?.name}</Typography>
                </Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container maxWidth="xl" className={classes.p0}>
        <Grid container direction="row" alignItems="center" justify="center" >
          <Grid item xs={12} className={classes.banner}>
            <img alt="" className="h-full" src={courseImg} />
          </Grid>
        </Grid>
        <Container maxWidth="lg" className={classes.bgDescription}>
          <Grid container direction="row" >
            <Grid item xs={11}>
              <Typography variant='h4' >{blogDetail?.attributes?.name}</Typography>
              <Typography variant='body2' className={classes.authbelow} data-testId="blogAuthor" onClick={() => this.props.history.push('/home/blogprofile/' + blogDetail?.attributes?.author_id + '/' + blogDetail.id)}>{blogDetail?.attributes?.author} </Typography>
              <Typography variant='body2' className={classes.authtile}>Category : {blogDetail?.attributes?.category?.name}</Typography>
              <Typography variant='body2'>{this.publishDate(blogDetail?.attributes?.created_at)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                className={blogDetail?.attributes?.follow ? classes.unFollowBtn : classes.followBtnBlog}
                color="primary"
                variant="outlined" data-testId="followUnfollowBtn" onClick={() => blogDetail?.attributes?.follow ? unfollow(blogDetail?.attributes?.content_provider?.data?.attributes?.id) : follow(blogDetail?.attributes?.content_provider?.data?.attributes?.id)}>
                <Hidden smDown><Typography variant="body2" className={`font-bold ${classes.followBtn}`}
                >{blogDetail?.attributes?.follow ? 'Followed' : 'Follow'} {blogDetail?.attributes?.follow ? '' : <AddIcon className={classes.addIcon} />}</Typography></Hidden>
                {/* onClick={() => follow(blogDetail?.id)} */}
                <Hidden mdUp><Typography variant="caption" className={classes.followBtn}>{blogDetail?.attributes?.follow ? 'Followed' : 'Follow'}  {blogDetail?.attributes?.follow ? '' : <AddIcon className={classes.addIcon} />}</Typography></Hidden>
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.descriptionGrid}  >
            <Grid item xs={11} >
              <Typography variant='caption'>
                <div dangerouslySetInnerHTML={{
                  __html: insane(`${blogDetail?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '')
                }}></div>
              </Typography>
            </Grid>
            <Grid item xs={1}>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={11}>
              {blogDetail?.attributes?.tag_list && blogDetail?.attributes?.tag_list?.length > 0 && blogDetail?.attributes?.tag_list.map((ele: any) => <Button key={ele} className={classes.chips} variant="outlined">#{ele}</Button>)}
            </Grid>
            <Grid item xs={1}>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.mrt}>
            <Grid item xs={11}>
              <Typography variant='body2'><VisibilityIcon /> {this.views(blogDetail?.attributes?.view_count)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Button className={classes.sharebtn} variant="contained" data-testId="shareBtn" onClick={() => this.setState({ show: true })}>Share</Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <RecommendedBlogs data-testId="RecommendedBlogs" {...{ classes, ListItems: this.state.recommendedBlogs, history: this.props.history }} />
        </Container>
        <Dialog
          open={this.state.show}
          fullWidth={true}
          maxWidth={'xs'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Share this blog"}
            <IconButton
              aria-label="close"
              className={classes.iconclose}
              onClick={() => { this.setState({ show: false }) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.sharecontent}>
            <Grid container >
              <Grid item xs={12}>
                <Grid container direction="column" alignItems="center" justify="center" className={classes.shareCourse}>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item className={classes.socialBtn} >
                        <FacebookShareButton
                          url={`http://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/blogsdetails/${this.props?.match?.params?.id}`}
                          quote={"Blog Views - These are the views got"}
                          hashtag="#CareerHunt"
                          className={classes.shareIcons}
                        >
                          <img src={fbImg} className={classes.socialMediaButton} />
                        </FacebookShareButton>
                      </Grid>
                      <Grid item className={classes.socialBtn}  >
                        <LinkedinShareButton
                          url={`http://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/blogsdetails/${this.props?.match?.params?.id}`}
                          quote={"Blog Views - These are the views got"}
                          hashtag="#CareerHunt"
                          className={classes.shareIcons}
                        >
                          <img src={linkedInImg} className={classes.socialMediaButton} />
                        </LinkedinShareButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Container >
    </>
    );
  }
}
//@ts-ignore
const BlogDetailsRouter = withRouter(BlogDetails);
const BlogDetailsWebWithLoader = withLoader(BlogDetailsRouter);
const BlogDetailsWebToast = withSnackbar(BlogDetailsWebWithLoader);
const BlogDetailsWebWithStyle = withStyles((theme) =>
  createStyles({
    p0: {
      padding: '0px'
    },
    followBtnBlog: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    unFollowBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      color: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    bgDescription: {
      padding: "2.5rem",
      boxShadow: "0 3px 10px 0 rgb(0 0 0 / 10%)",
      borderRadius: "5px",
      marginTop: "-8rem",
      zIndex: 9,
      backgroundColor: "#fff"
    },
    chips: {
      borderRadius: "0.5rem",
      marginRight: "0.3rem",
      padding: "2px 15px"
    },
    sharebtn: {
      fontSize: "14px",
      color: "#fff",
      textAlign: "center",
      padding: "4px 25px",
      borderRadius: "3px",
      backgroundColor: "#f9593a",
      '&:hover': {
        backgroundColor: '#f9593a',
        color: '#ffffff'
      }
    },
    mrt: {
      marginTop: '0.5rem'
    },
    categoryHight: {
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    addIcon: {
      fontSize: "15px",
      fontWeight: "bold",
      paddingLeft: "0.3rem"
    },
    followBtn: {
      display: "contents !important",
    },
    blogheading: {
      marginTop: "3rem",
      marginBottom: "2rem",
      color: "#fa6400"
    },
    banner: {
      marginTop: '-0.25rem',
      zIndex: -1
    },
    authtile: {
      color: '#3c59ff'
    },
    authbelow: {
      color: '#3c59ff',
      paddingTop: '4px',
      cursor: 'pointer'
    },
    bgnav: {
      padding: '1rem'
    },
    overView: {
      color: '#fa6400',
    },
    contianerdir: {
      marginTop: '2rem',
    },
    divsss: {
      display: 'inline',
    },
    result: {
      alignSelf: 'center'
    },
    sharecontent: {
      overflow: 'hidden'
    },
    socialBtn: {
      marginRight: '1rem'
    },
    shareCourse: {
      padding: '2rem',
      backgroundColor: '#f9f5f6'
    },
    socialMediaButton: {
      height: '30px',
      width: '30px',
      margin: 'auto'
    },
    blogGrid: {
      paddingBottom: '2rem'
    },
    shareIcons: {
      backgroundColor: '#ffffff',
      height: '60px',
      width: '60px',
      borderRadius: '50%'
    },
    descriptionGrid: {
      margin: '10px 0px'
    },
    authoreName: {
      color: '#f48b1b',
      cursor: 'pointer',
    },
    bloglink: {
      cursor: 'pointer',
    },
    iconclose: {
      float: 'right',
      top: '-6px',
    }
  })
)(BlogDetailsWebToast);
export default withTranslation()(BlogDetailsWebWithStyle);