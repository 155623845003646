Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DynamicContent";
exports.labelBodyText = "DynamicContent Body";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.methodTypeApiUpdateUserProfile = "PUT";
exports.apiContentType = "application/json";
exports.categoryApiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.categoryAPIEndPoint =
  "bx_block_categories/categories?show_on_landing_page=true";
exports.subCategoryAPIEndPointMobile =
  "bx_block_categories/sub_categories?language=en";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.categoriesAPIEndPoint =
  "bx_block_categories/categories?show_on_landing_page=true";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";
exports.btnExampleTitle = "CLICK ME";
exports.getCourses = "bx_block_contentmanagement/courses";
exports.getFullVideo = "bx_block_contentmanagement/contents";
exports.getRecommendVideo =
  "bx_block_contentmanagement/contents/recommended_videos";
exports.getShortVideo = "bx_block_contentmanagement/contents";
exports.getAptitude_test = "bx_block_contentmanagement/assessments";
exports.getQuizes = "bx_block_contentmanagement/quizzes";
exports.getAssessment = "bx_block_contentmanagement/assessments";
exports.getExams = "bx_block_contentmanagement/exams";
exports.getProfile = "bx_block_profile/profiles/id?language=en";
exports.contentTypeApiGetUserProfile = "application/json";
exports.getEbook = "bx_block_contentmanagement/contents";
exports.getAudioPodcast = "bx_block_contentmanagement/audio_podcasts";
exports.courseDetailsAPIEndPoint = "bx_block_contentmanagement/courses";
exports.contentProviderApiEndPoint =
  "/bx_block_contentmanagement/content_providers";
exports.bannerAPIEndPoint = "bx_block_contentmanagement/banners";
exports.followContent = "bx_block_contentmanagement/follows";
exports.unfollowContent = "bx_block_contentmanagement/follows/unfollow";
exports.coursesAPIEndPoint = "bx_block_contentmanagement/courses/home_courses";
exports.followBookmark = "bx_block_contentmanagement/bookmarks";
exports.unfollowBookmark = "bx_block_contentmanagement/bookmarks/unfollow";
exports.endPointApiGetUserProfiledetails = "/bx_block_profile/profiles/id";
exports.sendOtpAPI = "/account/accounts/send_otp?language=en";
exports.smsConfirmation = "/account/accounts/sms_confirmation?language=en";
exports.sendOtpEmailAPI = "/account_block/accounts/email_confirmations";
exports.loginWithPhone = "login/login?language=en";
exports.createUserQuiz = "bx_block_contentmanagement/user_quizzes";
exports.getAnswer = "bx_block_contentmanagement/user_quizzes/user_option";
exports.getScore = "bx_block_contentmanagement/user_quizzes/my_score?quiz_id";
exports.getParticularEbook = "bx_block_contentmanagement/contents";
exports.createUserAssessment = "bx_block_contentmanagement/user_assessments";
exports.getAssessment = "bx_block_contentmanagement/assessments";
exports.getAssessmentAnswer =
  "bx_block_contentmanagement/user_assessments/user_option";
exports.getAssessmentScore =
  "bx_block_contentmanagement/user_assessments/my_score?assessment_id";
exports.getAnswers =
  "bx_block_contentmanagement/assessments/assessment_answers?id";
exports.freeTrail = "bx_block_contentmanagement/courses/free_trail";
exports.addToCart = "bx_block_contentmanagement/course_carts";
exports.removeCart = "bx_block_contentmanagement/course_carts";
exports.ArticleNews = "/bx_block_contentmanagement/contents";
exports.AudioPodcast = "bx_block_contentmanagement/audio_podcasts";
exports.contentTypeApiEndPoint = "bx_block_contentmanagement/content_types";
exports.contentProviderApiEndPoint =
  "bx_block_contentmanagement/content_providers";
exports.tagsApiEndPoint = "bx_block_contentmanagement/tags";
exports.getContentEndPoint = "/bx_block_contentmanagement/contents";
exports.getCategoriesEndPoint = "/bx_block_categories/categories?language=en";
exports.dynamicFilterApiEndPoint =
  "/bx_block_filter_items/content_management/filters";
exports.getCareerExperts = "bx_block_experts/career_experts";
exports.feedAPI = "bx_block_communityforum/questions";
exports.getDetails = "bx_block_communityforum/questions/details";
exports.deleteUserQuestion = "bx_block_communityforum/questions/";
exports.likeAPI = "bx_block_communityforum/likes";
exports.dislikeAPI = "bx_block_communityforum/likes/delete_like";
exports.sendOTP = "account/accounts/send_otp?language=en";
exports.confirmationPhone = "account/accounts/sms_confirmation?language=en";
exports.confirmationEmail = "/account_block/accounts/email_confirmations";
exports.socialLogin = "bx_block_login/social_login?language=en";
exports.btnExampleTitle = "CLICK ME";
exports.getQuizUrl = "bx_block_contentmanagement/quizzes";
exports.saveQuizUrl = "bx_block_contentmanagement/user_quizzes/user_option";
exports.userQuizUrl = "bx_block_contentmanagement/user_quizzes";
exports.userAssessmentUrl = "bx_block_contentmanagement/assessments";
exports.assessmentUrl = "bx_block_contentmanagement/user_assessments";
exports.saveAssessmentUrl =
  "bx_block_contentmanagement/user_assessments/user_option";

exports.assessmentAnswersUrl =
  "bx_block_contentmanagement/assessments/assessment_answers";
exports.providerslistAPIEndPoint =
  "bx_block_contentmanagement/content_providers";
exports.contentTypelistAPIEndPoint = "bx_block_contentmanagement/content_types";
exports.getTagsList = "bx_block_contentmanagement/tags";

//Razorpay apis

exports.createOrdercouseAPIEndPoint =
  "bx_block_contentmanagement/course_orders";
exports.createOrdersinglecouseAPIEndPoint =
  "bx_block_payments/razorpays/single_course";
exports.verifyPaymentAPIEndPoint =
  "bx_block_payments/razorpays/varify_signature";

exports.btnExampleTitle = "CLICK ME";
exports.getQuizUrl = "bx_block_contentmanagement/quizzes";
exports.saveQuizUrl = "bx_block_contentmanagement/user_quizzes/user_option";
exports.userQuizUrl = "bx_block_contentmanagement/user_quizzes";
exports.userAssessmentUrl = "bx_block_contentmanagement/assessments";
exports.assessmentUrl = "bx_block_contentmanagement/user_assessments";
exports.saveAssessmentUrl =
  "bx_block_contentmanagement/user_assessments/user_option";

exports.assessmentAnswersUrl =
  "bx_block_contentmanagement/assessments/assessment_answers";
exports.providerslistAPIEndPoint =
  "bx_block_contentmanagement/content_providers";
exports.socialAppleLogin =
  "bx_block_login/social_login/apple_login_callback?language=en";
exports.getExamEndPoint = "/bx_block_contentmanagement/exams";
exports.getCTA = "bx_block_categories/cta";
exports.attemptMockTests = "bx_block_contentmanagement/user_mock_tests";
exports.getLanguages = "/bx_block_languageoptions/languages";
exports.boughtCourses = "/bx_block_contentmanagement/bought_courses_list";
exports.btnExampleTitle = "CLICK ME";
exports.getQuizUrl = "bx_block_contentmanagement/quizzes";
exports.saveQuizUrl = "bx_block_contentmanagement/user_quizzes/user_option";
exports.userQuizUrl = "bx_block_contentmanagement/user_quizzes";
exports.userAssessmentUrl = "bx_block_contentmanagement/assessments";
exports.assessmentUrl = "bx_block_contentmanagement/user_assessments";
exports.saveAssessmentUrl =
  "bx_block_contentmanagement/user_assessments/user_option";
exports.assessmentAnswersUrl =
  "bx_block_contentmanagement/assessments/assessment_answers";
exports.providerslistAPIEndPoint =
  "bx_block_contentmanagement/content_providers";
exports.contentTypelistAPIEndPoint = "bx_block_contentmanagement/content_types";
exports.getTagsList = "bx_block_contentmanagement/tags";
exports.getFilterOptions = "bx_block_filter_items/content_management/filters";

//read lesson
exports.readLessonContents = `bx_block_contentmanagement/lession_contents/read_lession_contents`;

//Activity Feed 
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.getContentEndPoint = "/bx_block_contentmanagement/contents";
exports.getCtaEndPoint = "/bx_block_categories/cta";
exports.btnExampleTitle = "CLICK ME";
exports.backgroundSourceUrl =
  "bx_block_login/application_configs/background_file";
exports.categoriesAPIEndPoint = "bx_block_categories/categories";
exports.getContents = "bx_block_contentmanagement/contents";
exports.followContent = "bx_block_contentmanagement/follows";
exports.endPointApiGetUserProfiledetails = "/bx_block_profile/profiles/id";
exports.contentTypeApiGetUserProfile = "application/json";
exports.unfollowContent = "/bx_block_contentmanagement/follows/unfollow";
exports.bookmarkContent = "bx_block_contentmanagement/bookmarks";
exports.removebookmarkContent = "bx_block_contentmanagement/bookmarks/unfollow";
exports.topExam = "bx_block_contentmanagement/exams";
exports.getTrendingCourseUrl =
  "bx_block_contentmanagement/courses?is_tranding=true";
exports.getLiveStreamUrl = "bx_block_contentmanagement/contents";
exports.addBookmarkUrl = "bx_block_contentmanagement/bookmarks";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";
exports.getBookmarkCourseUrl =
  "bx_block_contentmanagement/bookmarks?bookmarkable=course";
exports.getEpubsUrl = "bx_block_contentmanagement/epubs";
exports.getPodcastUrl = "bx_block_contentmanagement/audio_podcasts";
exports.getQuizUrl = "bx_block_contentmanagement/quizzes";
exports.saveQuizUrl = "bx_block_contentmanagement/user_quizzes/user_option";
exports.userQuizUrl = "bx_block_contentmanagement/user_quizzes";
exports.userAssessmentUrl = "bx_block_contentmanagement/assessments";
exports.assessmentUrl = "bx_block_contentmanagement/user_assessments";
exports.saveAssessmentUrl =
  "bx_block_contentmanagement/user_assessments/user_option";

// Customizable Area End
