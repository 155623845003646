//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Button, createStyles, IconButton, Container,
  Breadcrumbs, Typography, Link,
  Card, CardContent, Hidden
} from "@material-ui/core";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
//@ts-ignore
import { withRouter } from "react-router";
import { fbImg, linkedInImg, googlePlay, appStore } from './assets';
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import CoursesDetailPageController, { props } from "./CoursesDetailPageController.web";
import ReactPlayer from "react-player";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { isAndroid, isIOS } from "react-device-detect";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import CoursesCard from "./CourseCard.web";
import StarIcon from "@material-ui/icons/Star";
import { getRandom } from './CommonFunctions'
const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;
const RenderAuthorName = props => {
  const { author = [] } = props;
  return (<Grid item>
    <Typography variant="h3" style={{ color: "#f48b1b" }}>
      By: {author.filter(onlyUnique).join(', ') || ''}
    </Typography>
  </Grid>)
};
const ShareOnSocialLogin = ({ classes }) => {
  return (
    <Grid xs={12}>
      <Grid container direction="column" alignItems="center" justify="center" className={classes.shareCourse}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Share This Course
          </Typography>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item style={{ marginRight: '1rem' }}>
              <FacebookShareButton
                url={"https://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/coursedetail"}
                quote={"Courses - That You should buy"}
                hashtag="#CareerHunt"
                style={{ backgroundColor: '#ffffff', height: '60px', width: '60px', borderRadius: '50%' }}
              >
                <img src={fbImg} className={classes.socialMediaButton} />
              </FacebookShareButton>
            </Grid>
            <Grid item style={{ marginRight: '1rem' }}>
              <LinkedinShareButton
                url={"https://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/coursedetail"}
                quote={"Courses - That You should buy"}
                hashtag="#CareerHunt"
                style={{ backgroundColor: '#ffffff', height: '60px', width: '60px', borderRadius: '50%' }}
              >
                <img src={linkedInImg} className={classes.socialMediaButton} />
              </LinkedinShareButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </Grid>
  );
};
const Banner = ({ classes, courseDetail }) => {
  return (
    <Grid container alignItems="flex-start" className={classes.mainGrid} >
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <Typography variant='body2'>{courseDetail?.attributes?.category?.name}</Typography>
          </Link>
          <Link underline="hover" color="inherit">
            <Typography variant='body2'>{courseDetail?.attributes?.sub_category?.name}</Typography>
          </Link>
          <Typography variant='body2'>{courseDetail?.attributes?.heading}</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};
const DownloadApp = ({ classes, onAppClick }) => {
  return (
    <Grid item xs={12} lg={12} xl={12} className={classes.downloadList} >
      <Typography variant="h4" style={{ marginTop: "0.5rem" }}>Download our app</Typography>
      <Typography variant="subtitle2" style={{ margin: "0.8rem 0" }}>Use your iOS or Android CareerHunt app and watch courses on your mobile device.</Typography>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item style={{ paddingRight: "0.2rem" }}>
          <a onClick={onAppClick}>
            <img className={classes.downloadApp} src={appStore} />
          </a>
        </Grid>
        <Grid item style={{ paddingLeft: "0.2rem" }}>
          <a onClick={onAppClick}>
            <img className={classes.downloadApp} src={googlePlay} />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};
const StdentFeedBack = ({ classes, courseDetail }) => {
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h4">
            Student Feedback
          </Typography>
          <br />
        </Grid>
        <Grid container direction="row" alignItems="center" justify="space-between" style={{ paddingTop: "1.2rem" }}>
          <Grid item className={classes.feedbackLeft}>
            {courseDetail?.attributes?.user_rating &&
              <Grid container direction="column">
                <Typography variant="h4" style={{ color: "#f48b1b !important", fontSize: '55px' }}>
                  {courseDetail?.attributes?.user_rating ? courseDetail?.attributes?.user_rating.toFixed(1) : 0.0}
                </Typography>
                <Grid item xs={12}>
                  {[1, 2, 3, 4, 5].map((_data: any) => {
                    if (courseDetail?.attributes?.user_rating >= _data) {
                      return (<StarIcon className={classes.Icons} />);
                    } else {
                      return (<StarBorderIcon className={classes.Icon} />)
                    }
                  })}
                </Grid>
                <Typography variant="subtitlr2" style={{ color: "#f48b1b !important;" }}>
                  Course Rating
                </Typography>
              </Grid>
            }
          </Grid>
          {!courseDetail?.attributes?.user_rating &&
            <Grid item xs={12}>
              <Typography variant="subtitlr2" >
                No data to display
              </Typography>
            </Grid>
          }
          <Grid className={classes.feedbackRight}>
            {courseDetail?.attributes?.specific_rating && Object.keys(courseDetail?.attributes?.specific_rating).map((key) =>
              <Grid container Direction="row" alignItems="center" justify="space-between" style={{ paddingTop: "0.5rem" }}>
                <Grid item xs={7} md={8} sm={8} lg={8} xl={8} style={{ padding: "0 0.8rem" }}>
                  <LinearProgress variant="determinate" value={courseDetail?.attributes?.specific_rating[key] * 20} className={classes.ratingProgressBar} />
                </Grid>
                <Grid item xs={5} md={4} sm={4} lg={4} xl={4}>
                  {[1, 2, 3, 4, 5].map((_data: any) => {
                    if (courseDetail?.attributes?.specific_rating[key] >= _data) {
                      return (<StarIcon className={classes.Icons} />);
                    }
                    else {
                      return (<StarBorderIcon className={classes.Icon} />);
                    }
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
const CourseOverview = ({ classes, courseDetail }) => {
  return (<>
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.overviewText}>
          Overview
        </Typography>
        <hr />
        <br />
      </Grid>
      <Grid item>
        <Typography variant="h4">
          About this course
        </Typography>
        <br />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {courseDetail?.attributes?.description}
        </Typography>
      </Grid>
    </Grid>
    <br>
    </br>
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h4">
          What You'll learn
        </Typography>
        <br />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {courseDetail?.attributes?.what_you_will_learn_in_this_course ? courseDetail?.attributes?.what_you_will_learn_in_this_course : 'No data to display'}
        </Typography>
      </Grid>
    </Grid>
  </>)
}
const LessonsList = ({ classes, courseDetail, gotoLesonDetails }) => {
  return (
    <Grid item xs={12} className={classes.custAccordionList} >
      {courseDetail?.attributes?.lessions?.data.map((e, i) => (
        <Grid item xs={12} key={'lesson_' + e.id}>
          <Accordion
            className={classes.custAccordion} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandIconColor} />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header">
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid item className={classes.txtHead1}>
                  <Typography variant="h3" className={classes.addTxt}>
                    {e?.attributes?.heading}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <Grid xs={12}>
              {e?.attributes?.lession_contents?.data.map(t_ => (<AccordionDetails>
                <Grid container direction="row" justify="flex-start" alignItems="center" key={'lessonContent_' + t_.id}>
                  <Grid item xs={1}>
                    <PlayCircleFilledWhiteIcon />
                  </Grid>
                  <Grid item xs={9} style={{ padding: '0 0.4rem' }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>{t_?.attributes?.heading}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Link underline="hover" color="inherit" data-testId="gotoLesonDetails" onClick={() => gotoLesonDetails(t_, courseDetail)}>
                      <Typography variant="body2" style={{ cursor: 'pointer' }} >Preview</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </AccordionDetails>))}
            </Grid>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  )
}
const Comments = ({ classes, courseDetail, seeMore, onClickSeeMore }) => {
  return (
    <Grid container direction="column" justify='space-around' spacing={2} aligItems="flex-start">
      <Grid item xs={12}>
        <Typography variant="h4" >
          Comments
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {courseDetail?.attributes?.ratings?.data.map((ele: any, index: any) =>
          <React.Fragment key={'comment_' + ele.id}>
            {index == 0 && (
              <CommentBlock data-testId="CommentBlock" {...{ classes, ele }} key={'comments_' + ele.id} />
            )}
            {index > 0 && seeMore && (
              <CommentBlock data-testId="CommentBlock" {...{ classes, ele }} key={'comments_' + ele.id} />
            )}
          </React.Fragment>
        )}
        {courseDetail?.attributes?.ratings?.data.length > 1 &&
          <Grid container direction="column" alignItems="center" className={classes.seeMoreBox}>
            <Button contained data-testId="onClickSeeMore" onClick={() => onClickSeeMore()} className={classes.seeMorebtn}>
              <Typography variant="h3"> {seeMore ? 'See Less' : 'See More'}</Typography>
            </Button>
          </Grid>
        }
        {courseDetail?.attributes?.ratings?.data.length == 0 &&
          <Grid container direction="column" alignItems="left" className={classes.seeMoreBoxReview} >
            <br />
            <Typography variant="subtitlr2">No Reviews </Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}
const CommentBlock = ({ classes, ele }) => {
  return (
    <Grid item xs={12} key={'commentStat_' + ele.id}>
      <Card className={classes.seeMoreRootcard}>
        <CardContent style={{ padding: '1.25rem 0' }}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item className={classes.leftReview}>
              <Grid container direction="column" justify='space-between'>
                <img src={ele?.attributes?.account_image} className={classes.reviewerImg} />
              </Grid>
            </Grid>
            <Grid item className={classes.rightReview}>
              <Grid container direction="column">
                <Typography variant="h4" >
                  {ele?.attributes?.account_name}
                </Typography>
                <Grid item xs={12}>
                  {[1, 2, 3, 4, 5].map((_data: any) => {
                    if (ele?.attributes?.rating >= _data) {
                      return (<StarIcon className={classes.Icons} key={'comment_' + getRandom()} />);
                    }
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '0.8rem' }}>
            <Typography variant="subtitle2" className={classes.reviewDesc}>
              {ele?.attributes?.review}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
const ActionInfo = ({ classes, courseDetail, purchased, inCart, addFreeTrailDialog, addToCart, gotoReview }) => {
  return (
    <Grid container direction="row" alignItems="flex-start" justify="space-between" style={{ paddingTop: "1.2rem" }}>
      <Grid item xs={6} md={7} sm={6} lg={6} xl={6}>
        <Grid container direction="column">
          <Typography variant="h2" style={{ color: "#000" }}>
            {courseDetail?.attributes?.heading}
          </Typography>
          <br />
          <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
            Conten Provider:{' '}{courseDetail?.attributes?.content_provider?.data?.attributes?.partner_name}
          </Typography>
          {courseDetail?.attributes?.instructors?.data.length > 0 &&
            <> <RenderAuthorName {...{ author: courseDetail?.attributes?.instructors?.data.map(x => x.attributes.name).filter(y => y).filter(onlyUnique), classes }} /></>
          }
        </Grid>
      </Grid>
      <Grid item xs={5} md={5} sm={5} lg={4} xl={3}>
        <Grid container direction="column" alignItems="flex-end">
          <Typography variant="h2" style={{ color: "#000" }}>� {courseDetail?.attributes?.price}</Typography>
          <Grid container justify="flex-end" >
            {
              !purchased && courseDetail?.attributes?.available_free_trail &&
              <Button contained className={classes.freetrailBtn} disabled={!courseDetail?.attributes?.available_free_trail} data-testId="addFreeTrailDialog" onClick={() => addFreeTrailDialog(courseDetail?.id)}>
                <Typography variant="body1">Start A Free Trial</Typography>
              </Button>
            }
            {!purchased &&
              <Button contained style={{ marginLeft: '0.57rem' }} className={classes.addToCartBtn} data-testId="addToCart" onClick={() => addToCart(courseDetail?.id, inCart)}>
                {inCart ?
                  <Typography variant="body1"> In cart</Typography> :
                  <Typography variant="body1"> Add To Cart</Typography>}
              </Button>}
            {purchased &&
              <Button contained style={{ marginLeft: '0.57rem' }} className={classes.addToCartBtn}>
                <Typography variant="body1"> Purchased</Typography>
              </Button>}
            {purchased &&
              <Button contained style={{ marginLeft: '0.57rem' }} className={classes.addToCartBtn} data-testId="gotoReview" onClick={() => gotoReview(courseDetail)} >
                <Typography variant="body1"> {'Add Review'}</Typography>
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
const SimilarCourses = ({ boughtCourseList }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h4">Similar Courses</Typography>
      <Grid container direction="row" alignItems="center"  >
        <Grid item xs={12} className="w-full">
          <Hidden smDown>
            <CoursesCard
              isSmUp={false}
              courseList={boughtCourseList}
            />
          </Hidden>
          <Hidden mdUp>
            <CoursesCard
              isSmUp={true}
              courseList={boughtCourseList}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  )
}
const StudentsAlsoBought = ({ classes, boughtCourseList, getAllBookmarked, props, removeBookmark, addBookmark, showTime, goToCourseDetailPage }) => {
  return (
    <>
      <Grid container direction="column" alignItems="flex-start" justify="center">
        <Grid item xs={12} className={classes.containerSpacing}>
          {boughtCourseList && boughtCourseList.length > 0 && <Typography variant="h4">Students Also Bought</Typography>}
        </Grid>
      </Grid>
      <Grid container className={classes.paddingExamArVi}>
        <Grid item xs={12} className="w-full">
          <Hidden smDown>
            <CoursesCard
              isSmUp={false}
              courseList={boughtCourseList}
            />
          </Hidden>
          <Hidden mdUp>
            <CoursesCard
              isSmUp={true}
              courseList={boughtCourseList}
            />
          </Hidden>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  )
}
export class CoursesDetailPage extends CoursesDetailPageController {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      courseDetail: this.props?.location?.state?.courseDetail,
      purchased: this.props?.location?.state?.courseDetail?.attributes?.is_purchased,
      videoPlayedHours: 0,
    }, () => {
      if (localStorage.getItem('token')) {
        this.getBoughtCourses(this.state.courseDetail?.id);
        this.getSimilarCourses();
        this.getAllCoursesInCart();
      }
    });
  }
  onAppClick = () => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.tetraz.capsigo";
    } else if (isIOS) {
      window.location.href =
        "https://apps.apple.com/app/capsigo/id1547746310";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.flipkart.android&hl=en_IN&gl=US";
    }
  }
  clickSeeMore = () => {
    this.setState(prevState => ({ seeMore: !prevState.seeMore }));
  }
  handleClose = () => {
    this.setState({ freetrailOpen: false });
  }
  handleBuySnackClose = () => {
    this.setState({ openBuySnack: false })
  }
  gotoLesonDetails = (lesson: any, courseDetail: any) => {
    if (courseDetail?.attributes?.available_free_trail || courseDetail?.attributes?.is_purchased) {
      this.setState({ vedioLink: lesson?.attributes?.video });
      this.setState({ courseLessionId: lesson?.attributes?.id })
    }
    else {
      this.setState({ openBuySnack: true })
    }
  }
  goToCourseDetailPage = (data: any) => {
    this.props.history.push("/home/coursedetail", { courseDetail: data });
  }
  render() {
    const { classes } = this.props;
    const { boughtCourseList, courseDetail, purchased, inCart } = this.state;
    return (<>
      <Container maxWidth="xl" style={{ padding: "1rem" }}>
        <Banner data-testId="Banner" {...{ classes, courseDetail }} />
        <br />
        <br />
        <Grid container direction="row" alignItems="flex-start" justify="center" spacing={3} >
          <Grid item xs={12} sm={12} md={7} lg={6} xl={6}   >
            {(courseDetail?.attributes?.lessions?.data[0]?.attributes?.lession_contents?.data[0]?.attributes?.video || courseDetail?.attributes?.video) &&
              <ReactPlayer
                playing={true}
                controls
                data-testId="ReactPlayer"
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                // Disable right click
                onContextMenu={(e: any) => e.preventDefault()}
                volume={1}
                width="100%"
                height="22rem"
                onReady={() => console.log("ready now")}
                url={this.state.vedioLink ? this.state.vedioLink : courseDetail?.attributes?.lessions?.data[0]?.attributes?.lession_contents?.data[0]?.attributes?.video ?? courseDetail?.attributes?.video}
                onProgress={(progress) => {
                  this.setState({ videoPlayedHours: progress.playedSeconds })
                }}
                onPause={
                  () => { courseDetail?.attributes?.is_purchased && this.state?.courseLessionId && this.courseProgressBar(courseDetail?.attributes?.id, this.state?.courseLessionId ?? courseDetail?.attributes?.lessions?.data[0]?.attributes?.lession_contents?.data[0]?.attributes?.id, Math.round(this.state?.videoPlayedHours)) }
                }
              />}
            <br />
            <ActionInfo data-testId="ActionInfo" {...{ classes, courseDetail, purchased, inCart, addFreeTrailDialog: this.addFreeTrailDialog, addToCart: this.addToCart, gotoReview: this.gotoReview }} />
            <br />
            <br />
            <CourseOverview data-testId="CourseOverview" {...{ classes, courseDetail }} />
            <br />
            <br />
            <br />
            <StdentFeedBack data-testId="StdentFeedBack" {...{ classes, courseDetail }} />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Comments data-testId="Comments" {...{ classes, courseDetail, seeMore: this.state.seeMore, onClickSeeMore: this.clickSeeMore }} />
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4} >
            {courseDetail?.attributes?.lessions && courseDetail?.attributes?.lessions?.data?.length > 0 &&
              <LessonsList data-testId="LessonsList" {...{ classes, courseDetail, gotoLesonDetails: this.gotoLesonDetails }} />
            }
            <br />
            <br />
            <DownloadApp data-testId="DownloadApp" {...{ classes, onAppClick: this.onAppClick }} />
            <br /><br />
            {boughtCourseList && boughtCourseList.length > 0 && <SimilarCourses data-testId="SimilarCourses" {...{ boughtCourseList }} />}
          </Grid>
        </Grid>
        <br />
        <br />
      </Container>
      <ShareOnSocialLogin data-testId="ShareOnSocialLogin" {...{ classes }} />
      <br />
      <br />
      <Container maxWidth="xl" style={{ padding: "2rem" }}>
        <StudentsAlsoBought data-testId="StudentsAlsoBought" {...{ classes, boughtCourseList, getAllBookmarked: this.state.getAllBookmarked, props: this.props, removeBookmark: this.removeBookmark, addBookmark: this.addBookmark, showTime: this.showTime, goToCourseDetailPage: this.goToCourseDetailPage }} />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          data-testId="Snackbar"
          open={this.state.freetrailOpen}
          autoHideDuration={6000}
          onClose={() => this.handleClose()}
          message="Are you continue with free trial of this course?"
          action={
            <React.Fragment>
              <Button color="secondary" size="small" data-testId="addFreeTrail" onClick={() => this.addFreeTrail()}>
                Yes
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.handleClose()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.openBuySnack}
          autoHideDuration={6000}
          onClose={() => this.handleBuySnackClose()}
          message="Please buy the course to access the lessons"
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.handleBuySnackClose()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Container>
      <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
    </>
    );
  }
}
//@ts-ignore
const CoursesDetailPageWithRouter = withRouter(CoursesDetailPage);
const CoursesDetailPageWithLoader = withLoader(CoursesDetailPageWithRouter);
const CoursesDetailPageToast = withSnackbar(CoursesDetailPageWithLoader);
const CoursesDetailPageWithStyle = withStyles((theme) =>
  createStyles({
    overviewText: {
      fontSize: '14px',
      color: '#f48b1b',
      borderBottom: '5px solid #f48b1b',
      fontWeight: '600',
      paddingBottom: '12px',
      position: 'relative',
      display: 'inline-block',
    },
    seeMoreRootcard: {
      borderRadius: '0px',
      boxShadow: 'none',
      borderTop: '1px solid #b5b5b5'
    },
    seeMoreRoot: {
      marginTop: '1rem',
      height: '11rem',
      borderRadius: '9px',
      boxShadow: '0 4px 12px 0 rgb(191 175 175 / 60%)',
      backgroundColor: '#fff'
    },
    socialMediaButton: {
      height: '30px',
      width: '30px',
      margin: 'auto'
    },
    shareCourse: {
      padding: '2rem',
      backgroundColor: '#f9f5f6'
    },
    reviewDesc: {
      height: '3rem',
      overflow: 'overlay'
    },
    seeMoreBox: {
      height: '3rem',
      marginTop: '-3rem',
      [theme.breakpoints.down("xs")]: {
        marginTop: '0rem',
      },
    },
    seeMoreBoxReview: {
      height: '3rem',
      [theme.breakpoints.down("xs")]: {
        marginTop: '0rem',
      },
    },
    addToCartBtn: {
      marginTop: '1rem',
      height: '2rem',
      width: '6rem',
      borderRadius: '9px',
      color: '#ffffff',
      backgroundColor: '#f8593b',
      '&:hover': {
        backgroundColor: '#f8593b !important',
        color: '#ffffff !important'
      }
    },
    freetrailBtn: {
      marginTop: '1rem',
      height: '2rem',
      borderRadius: '9px',
      color: '#000',
      backgroundColor: '#b5b5b5',
      '&:hover': {
        backgroundColor: '#ccc !important',
        color: '#000 !important'
      },
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
        color: '#a69393 !important'
      }
    },
    seeMorebtn: {
      marginTop: '.5rem',
      height: '2rem',
      width: '7rem',
      borderRadius: '9px',
      color: '#7764db',
      backgroundColor: '#ffffff',
      boxShadow: '0 3px 6px 0 rgba(224, 214, 214, 0.5)',
      '&:hover': {
        backgroundColor: '#ffffff !important',
        color: '#7764db !important'
      }
    },
    custAccordionList: {
      border: 'solid 1px #dcdacb',
      backgroundColor: '#fbfbf8',
      borderRadius: '5px',
      borderBottom: 'none',
      maxHeight: '353px',
      overflowY: 'auto',
      marginRight: '200px',
      [theme.breakpoints.down('lg')]: {
        marginRight: '70px',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '0px',
      },
    },
    downloadList: {
      marginRight: '200px',
      [theme.breakpoints.down('lg')]: {
        marginRight: '70px',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '0px',
      },
    },
    ratingProgressBar: {
      backgroundColor: '#ffffff',
      border: '1px solid #eee',
      height: '10px',
      '& > div': {
        backgroundColor: '#f48b1b !important',
      }
    },
    custAccordion: {
      borderBottom: 'solid 1px #dcdacb',
      borderRadius: '0px !important',
    },
    expandIconColor: {
      color: '#000000',
      height: '1.4rem',
      width: '2rem',
      div: {
        backgroundColor: '#f8593b',
      }
    },
    leftReview: {
      width: '85px',
      minWidth: '85px',
    },
    rightReview: {
      width: 'calc(100% - 85px)',
      minWidth: 'calc(100% - 85px)',
    },
    feedbackLeft: {
      width: '120px',
      minWidth: '120px',
      [theme.breakpoints.down('xs')]: {
        width: '96px',
        minWidth: '96px',
      },
    },
    feedbackRight: {
      width: 'calc(100% - 120px)',
      minWidth: 'calc(100% - 120px)',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 96px)',
        minWidth: 'calc(100% - 96px)',
      },
    },
    downloadApp: {
      height: '40px',
      [theme.breakpoints.down('xs')]: {
        height: '36px',
      },
    },
    reviewerImg: {
      width: '70px',
      height: '70px',
      backgroundColor: '#483a3a',
      borderRadius: '50%'
    },
    Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    },
  })
)(CoursesDetailPageToast);
export default withTranslation()(CoursesDetailPageWithStyle);
