import AsyncStorage from '@react-native-async-storage/async-storage';
import { Dimensions, Platform, PixelRatio } from 'react-native';
import Share from 'react-native-share';
export function getOS(): string {
  return Platform.OS;
}

export const setStorageData = async (key: string, value: any) => {
  try {
    if (!value) {
      await AsyncStorage.removeItem(key);
    } else {
      await AsyncStorage.setItem(key, value);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getStorageData = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.error(e);
  }
};

//Screen Constatnts
const SCREEN_HEIGHT =
  Platform.OS === 'ios' || Dimensions.get('window').height >= 550
    ? Dimensions.get('window').height
    : 667;
const SCREEN_WIDTH = 375;

const { height, width } = Dimensions.get('window');

export const ScreenHeight = Dimensions.get('screen').height;
export const ScreenWidth = Dimensions.get('screen').width;

export const windowHeight = Dimensions.get('window').height;
export const windowWidth = Dimensions.get('window').width;

/**
 * Function to scale a value based on the size of the screen size and the original
 * size used on the design.
 */
export const scale = (units = 1) => {
  const newSize = (width / SCREEN_WIDTH) * units;
  const fontScale = PixelRatio.getFontScale();
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    if (fontScale > 1) {
      return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
    }
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  }
};

export const verticalScale = (size: number) => {
  const newSize = (height / SCREEN_HEIGHT) * size;
  const fontScale = PixelRatio.getFontScale();
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    if (fontScale > 1) {
      return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
    }
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  }
};


