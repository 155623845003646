import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/Utility";
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  articleDetails: any;
  recommendedBlogs: any;
  expertCategory: any;
  show: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class CareerExpertArticleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getExpertArticleApiCallId: string = "";
  getRecommendedBlogsApiCallId: string = "";
  followApiCallId: string = "";
  getCategorylistId: string = "";
  unfollowApiCallId: string = "";
  articleDetails: any;
  recommendedBlogs: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      url: "",
      mimeType: "",
      articleDetails: {},
      recommendedBlogs: [],
      expertCategory: "",
      show: false,
    };
    // Customizable Area End
  }
  loadData = () => {
    this.getArticleDetails();
    this.getRecommendedBlogs();
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getExpertArticleApiCallId:
            this.handleExpertArticleApiResponce(responseJson);
            break;
          case this.getRecommendedBlogsApiCallId:
            this.handleRecommendedBlogsApiResponce(responseJson);
            break;
          case this.followApiCallId:
            this.handleFollowApiResponce(responseJson);
            break;
          case this.unfollowApiCallId:
            this.handleUnFollowApiResponce(responseJson);
            break;
          case this.getCategorylistId:
            this.handleCategorylistApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
      }
    }
  }
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };

  handleExpertArticleApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ articleDetails: responseJson.data });
    }
  };
  handleRecommendedBlogsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ recommendedBlogs: responseJson.data });
    }
  }
  handleFollowApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.getArticleDetails();
    }
  }
  handleUnFollowApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson?.message) {
      this.getArticleDetails();
    }
  }
  handleCategorylistApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      let data = responseJson?.data.filter((ele: any) => ele.id == this.state.articleDetails.attributes.career_expert.category_id)
      this.setState({ expertCategory: data.length > 0 ? data[0].attributes.name : '' })
    }
  }
  follow = (id: any, articleId: any) => {
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const data = {
      // "bookmarkable_id": id,
      // "bookmarkable_type": "job"
    }
    const apiEndPoint = `${configJSON.getExperts}/${id}/follow_content/${articleId}`;
    const method = configJSON.exampleAPiMethod;
    this.followApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: headers
    })
  }
  unfollow = (id: any, articleId: any) => {
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const data = {
      // "bookmarkable_id": id,
      // "bookmarkable_type": "job"
    }
    const apiEndPoint = `${configJSON.getExperts}/unfollow_content/${articleId}`;
    const method = configJSON.exampleDeleteAPiMethod;

    this.unfollowApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: headers
    })
  }
  getArticleDetails = () => {
    const expertId = this.props?.match?.params?.expertId;
    const articleId = this.props?.match?.params?.articleId;
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const token = localStorage.getItem('token');
    if (token) {
      headers.token = token;
    }
    const apiEndPoint = configJSON.getExperts + `/${expertId}/text/${articleId}`
    const method = configJSON.validationApiMethodType

    this.getExpertArticleApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: headers
    })
  };
  getRecommendedBlogs = () => {
    if (!localStorage.getItem('token')) return;
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const token = localStorage.getItem('token');
    if (token) {
      headers.token = token;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecommendedBlogsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContents +
      `&is_recommended=true&content_type[]=2`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCategories = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiEndPoint = `${configJSON.categoriesAPIEndPoint}`;
    const methodType = configJSON.validationApiMethodType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategorylistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
