import React from 'react'


import { runEngine } from '../../framework/src/RunEngine'
import MessageEnum, {
    getName
} from '../../framework/src/Messages/MessageEnum'
import { Message } from "../../framework/src/Message";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestParams {
    method?: HttpMethods;
    headers?: object;
    body?: object | FormData | any;
}

/**
* generic methods to construct and send an RestAPIRequestMessage
* returns callId - message id of the sent message, used to read response later
*/
export function sendAPIRequest(endpoint: string, params?: RequestParams) {
    const { method = "GET", headers, body } = params || {}
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
    )

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    )

    if (body)
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body?.append ? body : JSON.stringify(body)
        )

    if (headers)
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )
        // console.log(requestMessage,"!!!")
    runEngine.sendMessage(requestMessage.id, requestMessage)
            
    return callId
}



