import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import moment from "moment";
import { sendAPIRequest } from "../../../components/src/Utility";
export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  featureArticle: any[];
  filterdata: any[];
  selectedType: any;
  selectedChip: any;
  searchInfo: any;
  pageNo: any;
  totalPages: any;
  methodIdentifier: any;
  queryobj: any;
  days: any;
  contentTypeInfo: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class CategoriesViewallWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContentBySearchId: string = "";
  getFilterOptionsId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      featureArticle: [],
      filterdata: configJSON.filtersOptions,
      selectedType: 0,
      selectedChip: 0,
      searchInfo: '',
      pageNo: 1,
      totalPages: 0,
      methodIdentifier: 'default',
      queryobj: configJSON.queryobj,
      days: configJSON.daysinfo,
      contentTypeInfo: {
        'articles': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 1, 'parameter': '&content_type[]=1', 'filter_type': 'news_article', 'title': 'Articles' },
        'blogs': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 2, 'parameter': '&content_type[]=2', 'filter_type': 'Blog', 'title': 'Blogs' },
        'video': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 4, 'parameter': '&content_type[]=4', 'filter_type': 'videos', 'title': 'Videos' },
        'shortvideos': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 3, 'parameter': '&content_type[]=3', 'filter_type': 'short_videos', 'title': 'Short Videos' },
        'podcast': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 6, 'parameter': '&content_type[]=6', 'filter_type': 'podcasts', 'title': 'Podcasts' },
        'epubs': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 9, 'parameter': '&content_type[]=9', 'filter_type': 'ebooks', 'title': 'E-Books' },
        'livestream': { 'featured': '&is_featured=true', 'recommended': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 5, 'parameter': '&content_type=5', 'filter_type': 'Live Stream', 'title': 'Live Streams' },
      },
    };
    // Customizable Area End
  }
  async componentDidMount() {
    this.getFilterOptionsInfo('category', this.state.filterdata)
    this.iniateMethods()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const respJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (respJson && !respJson.errors) {
        switch (apiRequestCallId) {
          case this.getContentBySearchId:
            this.handleContentBySearchIdApiResponce(respJson);
            break;
          case this.getFilterOptionsId:
            this.handleFilterOptionsApiResponce(respJson);
            break;
          default:
            break;
        }
      } else if (respJson && respJson.errors) {
        this.clearLocalstorage(respJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }
  handleContentBySearchIdApiResponce = (responseJson: any) => {
    window.scrollTo(0, 0)
    this.setState({ featureArticle: responseJson.data, totalPages: responseJson.total_pages });
  }
  handleFilterOptionsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let data: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        data[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: data });
    }
  }
  clearLocalstorage = (responseJson: any) => {
    this.parseApiErrorResponse(responseJson);
  }
  iniateMethods = () => {
    let data = this.state.filterdata
    data[0].value = []
    data[0].value.push(this.props.match?.params?.categoryId)
    if (this.props.match?.params?.categoryId) {
      this.setState({ selectedChip: this.props?.location?.state?.contentTypeId, filterdata: this.props?.location?.state?.filterinfo ? this.props?.location?.state?.filterinfo : data }, () => {
        this.getContentsBysearchCriteria(this.state.filterdata, this.props?.match?.params?.categoryId)
      })
    }
  }
  getContentsBysearchCriteria = (data: any, categoryId: any) => {
    let artcle = this.state.contentTypeInfo[this.props?.match?.params?.type][this.props?.match?.params?.heading]
    let url = configJSON.getContents
    if (this.state.searchInfo) {
      artcle += '&search=' + this.state.searchInfo
    }
    let params = ''
    if (data && data?.length > 0) {
      data.map((ele: any) => {
        if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.id != 3) {
          params += this.mapOptions(ele, params);
        }
        else if (ele.hasOwnProperty('value') && ele?.value?.length > 0 && ele?.type == 'Date') {
          artcle += '&' + this.setDateValues(ele)
        }
      })
    }
    params += this.state.contentTypeInfo[this.props.match?.params?.type].parameter
    const apiEndPoint = `${url}?page=${this.state.pageNo}&per_page=10${artcle}${params}`;
    this.getContentBySearchId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
  mapOptions = (ele: any, params: any) => {
    let query = ""
    ele.value.forEach((e: any) => {
      if (ele.id == 4) {
        let tagInfo = ele.data.filter((el: any) => el.id == e)
        if (tagInfo && tagInfo.length > 0) {
          e = tagInfo[0]?.attributes?.name
        }
      }
      query += `&${this.state.queryobj[ele.id]}=${e}`
    })
    return query;
  }
  setDateValues = (ele: any) => {
    let param = ''
    if (ele.value.indexOf(6) != -1) {
      param = `date[from]=${moment(ele.fromDate).format("DD/MM/YYYY")}&date[to]=${moment(ele.toDate).format("DD/MM/YYYY")}`
    }
    else if (ele.value.indexOf(1) != -1) {
      param = `date[to]=${moment().format("DD/MM/YYYY")}&date[from]=${moment().format("DD/MM/YYYY")}`
    }
    else if (ele.value.indexOf(5) != -1) {
      const monthlastdate = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY')
      const monthfirstdate = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
      param = `date[to]=${monthfirstdate}&date[from]=${monthlastdate}`
    }
    else {
      param = `date[from]=${moment().subtract(this.state.days[ele.value[0]], 'days').format("DD/MM/YYYY")}&date[to]=${moment().format("DD/MM/YYYY")}`
    }
    return param;
  }
  getFilterOptionsInfo = (filterby: any, filterinfo: any) => {
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.id != 3 && ele.value.length > 0) {
        query += this.mapOptions(ele, query);
      } else if (ele.hasOwnProperty('value') && ele?.value?.length > 0 && ele?.type == 'Date') {
        query += '&' + this.setDateValues(ele)
      }
    })
    let param = this.state.contentTypeInfo[this.props.match?.params?.type].parameter;
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=content${query}${param}`;
    this.getFilterOptionsId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
}
