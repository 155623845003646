//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Button, Divider, createStyles, Container, Typography, CardMedia,
  Card, CardContent, InputBase, Box, Hidden, Collapse
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import CollegeSearchController, { props } from "./CollegeSearchController.web";
import SearchIcon from '@material-ui/icons/Search';
import { collegeImg, schoolImg, amityImg, qsImg, globalImg } from "./assets";
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
const FilterDataCollege = ({ classes, filterdata, handleClick, selectedType, getsearchLocation, selectSearchInfo, applyFilter, clearFilter, showOption }) => {
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.listroot}
    >
      {filterdata && filterdata.length > 0 && filterdata.map((item: any, index: any) =>
        <React.Fragment key={'FL_' + item?.id}>
          <ListItem button data-testId="handleClick" onClick={() => handleClick(item)} >
            <ListItemText className={classes.filterItem} primary={<Typography variant="subtitle1" className={classes.collegecheckgroupTxt}>{item?.type}</Typography>} />
            {selectedType == item?.id ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedType == item?.id} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                item?.id === 0 && <InputBase
                  data-testId="getsearchLocation"
                  onChange={(e) => getsearchLocation(e, index)}
                  placeholder="Search"
                  style={{ color: '#000', width: '100%' }}
                  inputProps={{ 'aria-label': 'search', className: classes.collegeSearchInput }}
                />
              }
              {item.data && item.data.length > 0 && item.data.map((e: any, i: any) => {
                return (
                  <ListItem key={e?.id} role={undefined} dense button data-testId="selectSearchInfo" onClick={() => selectSearchInfo(e, index, i)}>
                    {item?.id === 1 ?

                      <Checkbox
                        edge="start"
                        color="default"
                        tabIndex={-1}
                        checked={item.value.indexOf(i) !== -1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${e?.id}` }}
                        style={{ padding: '5px 10px 5px 5px' }}
                      />
                      :
                      <Checkbox
                        edge="start"
                        color="default"
                        tabIndex={-1}
                        checked={item.value.indexOf(e.id) !== -1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${e?.id}` }}
                        style={{ padding: '5px 10px 5px 5px' }}
                      />
                    }
                    {
                      item?.id == 1 ? <ListItemText id={`checkbox-list-label-${e?.id}`} primary={<Typography variant="subtitle2" className={classes.btnTypo}>

                        {(e?.min)}-{(e?.max)} Lakh {` (${e?.count})`}

                      </Typography>} /> :
                        <ListItemText id={`checkbox-list-label-${e?.id}`} primary={

                          <Typography variant="subtitle2" className={classes.btnTypo}>{showOption(item, e)}</Typography>} />
                    }
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </React.Fragment>)
      }
      <Box component="div" ml={2} mt={2} mb={2}>
        <Button variant="contained" className={classes.applybutton} color="secondary" data-testId="applyFilter" onClick={() => applyFilter()}>
          Apply
        </Button>
        <Button variant="contained" className={classes.clearbuttton} color="secondary" data-testId="clearFilter" onClick={() => clearFilter()} >Clear All</Button>
      </Box>
    </List>
  )
};
const CollegeList = ({ classes, collegesList, numFormatter, gotoWebsite }) => {
  return (
    <Grid container direction="row" alignItems="center" className={collegesList.length == 0 ? classes.nodata : ''}>
      {collegesList && collegesList.length > 0 && collegesList.map((s: any) =>
        <Grid item xs={12} sm={6} md={6} key={'CL_' + s.id}>
          <Card className={classes.popularCollegeCard}>
            <CardContent style={{ padding: "10px" }}>
              <Grid item className={classes.popularCollegecardImg} style={{ marginBottom: "0.85rem" }}>
                {s?.attributes?.logo && s?.attributes?.logo?.url ? <img src={s?.attributes?.logo?.url} className={classes.popularCollegeImg} /> : <img src={schoolImg} className={classes.popularCollegeImg} />}
                <Grid container direction="column" className={classes.popularcollegeBlock}>
                  <Typography variant="h3" className={classes.popularCollegeTitle}>{s?.attributes?.name}</Typography>
                  <Typography variant="body" style={{ color: '#fff' }}>{s?.attributes?.location?.data?.attributes?.name}</Typography>

                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>Total fee</Typography>
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>{numFormatter(s?.attributes?.total_fees_min)} - {numFormatter(s?.attributes?.total_fees_max)}</Typography>

                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>Median salary</Typography>
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>{numFormatter(s?.attributes?.median_salary)}</Typography>

                  </Grid>
                </Grid>
              </Grid>

              <hr style={{ margin: '0.5rem 0' }} />
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>Course Rating</Typography>
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>{s?.attributes?.course_rating ? s?.attributes?.course_rating : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>Ranked</Typography>
                    <Typography variant="body2" style={{ fontSize: '0.73rem' }}>{s?.attributes?.rank} Ranked in {s?.attributes?.location?.data?.attributes?.name}</Typography>

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '0.5rem', display: 'flex' }}>
                <Button className={classes.visitedWebsiteBtn} style={{ marginRight: '0.5rem' }} disabled={!s?.attributes?.website_url}>
                  <Typography variant="body2" className={classes.btnTypo} style={{ fontSize: '0.73rem' }} data-testId="gotoWebsite" onClick={(e) => gotoWebsite(s)}>Visite website</Typography>
                </Button>
                <a href={s?.attributes?.brochure} target="_blank" download disabled={!s?.attributes?.brochure}><Button disabled={!s?.attributes?.brochure} className={classes.brochureBtnDownload}>
                  <Typography variant="body2" className={classes.btnTypo} style={{ fontSize: '0.73rem' }}>Download Brochure</Typography>
                </Button></a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      {collegesList && collegesList.length === 0 &&
        <Grid item xs={12} sm={6} md={6} >
          No colleges to display
        </Grid>
      }
    </Grid>
  )

};
export class CollegeSearch extends CollegeSearchController {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.getFilterOptions('location', this.state.filterdata)
    this.getCollegeCtas()
    this.initiateMethod()
  }
  initiateMethod = () => {
    let quary = ''
    if (this.props?.location?.state) {
      let filterInfo = this.state.filterdata

      if (Object.keys(this.props?.location?.state)[0] == 'locations') {
        filterInfo[0].value.push(this.props?.location?.state?.locations)
        this.setState({ filterdata: filterInfo })
        quary += `${Object.keys(this.props?.location?.state)[0]}[]=` + this.props?.location?.state[Object.keys(this.props?.location?.state)[0]]
      }
      else {
        quary += `${Object.keys(this.props?.location?.state)[0]}=` + this.props?.location?.state[Object.keys(this.props?.location?.state)[0]]
      }
    }
    this.getCollegesBySearchInfo(quary)
  }
  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        this.getFilterOptions(data.key, this.state.filterdata)
      })
    }
  }
  onPageChange = (e, page) => {
    this.setState({ currentPage: page }, () => {
      this.setSearchQuaryparameters(this.state.filterdata)
    });
  }

  render() {
    const self = this;
    const { numFormatter, gotoWebsite, handleClick, getsearchLocation, selectSearchInfo, applyFilter, clearFilter, showOption } = self
    const { classes } = this.props;
    const { filterdata, collegesList, collegesCtasList, totalPages, currentPage } = this.state;
    return (<>
      <Container maxWidth="xl" style={{ padding: "1rem" }}>
        <Grid item xs={12} className={classes.categoryViewallGrid}>
          <img alt="" src={collegeImg} className={classes.collegeImage} style={{ width: "100%" }} />
          <Grid container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallaxGrid} justifyContent="center">
            <Grid container direction="row" alignItems='center' className={classes.categorySearchItem}>
              <Grid item xs={2} sm={1}>
                <SearchIcon color="secondary" />
              </Grid>
              <Grid item xs={10} sm={11}>
                <InputBase
                  placeholder="Search colleges here"
                  classes={{
                    input: classes.inputCollegeInput,
                  }}
                  value={this.state.searchText}
                  data-testId="searchData"
                  onChange={(e) => this.searchData(e)}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" style={{ padding: "0.8rem 0.5rem", backgroundColor: "#eeeeee" }} className={classes.featuredBlock}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2" className={classes.textFeatured}>Featured</Typography>
          </Grid>
          <Grid item style={{ width: "calc(100% - 25px)" }}>
            <Grid container direction="row" alignItems="center" style={{ flexWrap: "inherit", overflowX: "auto" }}>
              {collegesCtasList && collegesCtasList.length > 0 && collegesCtasList.map((fc: any, index: any) => {
                return (<Grid key={'CAL_' + index} item xs={6} md={3} sm={4} lg={3} xl={2} style={{ padding: "0 0.25rem", maxWidth: "15rem", minWidth: "15rem" }}>
                  <Card className={classes.featuredCard} data-testId="navigateCta" onClick={(e) => this.navigateCta(fc?.attributes?.href_link)}>
                    <CardMedia
                      className={classes.coveritem}
                      component='img'
                      image={fc?.attributes?.square_background_image}
                      title="Live from space album cover"
                    />
                  </Card>
                </Grid>)
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
      <Container className={classes.collegeSearchBlock}>
        <Grid container direction="row">
          <Grid item xs={12} md={3} sm={12} className={classes.schoolSearchLeftBlock}>
            <Grid container direction="column" justifyContent="flex-start" style={{ border: '1px solid #eee', borderRadius: '4px' }} >
              <Grid item xs={12} >
                <Typography variant="h3" className={classes.collegeheadTxt}>Filter</Typography>
                <Divider variant="fullWidth" component="h3" />
                <FilterDataCollege data-testId="filterDataCollege" {...{ classes, filterdata, handleClick, selectedType: this.state.selectedType, getsearchLocation, selectSearchInfo, applyFilter, clearFilter, showOption }} />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12} md={6} sm={12} id="box" className={classes.schoolSearchMiddleBlock}>
            {this.props?.location?.state && (Object.keys(this.props?.location?.state)[0] == 'featured' || Object.keys(this.props?.location?.state)[0] == 'popular') &&
              <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.paddingExamArVi}>
                <Hidden smDown><Typography align="center" style={{ color: "#f48b1b", paddingLeft: '0.6rem' }} variant="h4">{Object.keys(this.props?.location?.state)[0] == 'featured' ? 'Featured' : ''} {Object.keys(this.props?.location?.state)[0] == 'popular' ? 'Popular' : ''}{' '}Colleges</Typography></Hidden>
                <Hidden mdUp><Typography align="center" style={{ color: "#f48b1b", paddingLeft: '0.6rem' }} variant="h3">{Object.keys(this.props?.location?.state)[0] == 'featured' ? 'Featured' : ''} {Object.keys(this.props?.location?.state)[0] == 'popular' ? 'Popular' : ''}{' '}Colleges</Typography></Hidden>
              </Grid>
            }
            <CollegeList data-testId="collegeList" {...{ classes, collegesList, numFormatter, gotoWebsite }} />
          </Grid>
          <br />
          <Grid item xs={12} md={3} sm={12} className={classes.collegeSearchRightBlock}>
            {this.props?.location?.state && (Object.keys(this.props?.location?.state)[0] == 'featured' || Object.keys(this.props?.location?.state)[0] == 'popular') &&
              <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.paddingExamArVi}>
                <br />
              </Grid>
            }
            <Grid item xs={12} style={{ padding: "0 0.25rem" }}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item className={classes.addsCardLeft}>
                  <Button className={classes.sessionCollegeBtn}>
                    <Typography variant="h3" className={classes.sessionbtnTypo} >2021 SESSION APPLY NOW</Typography>
                  </Button>
                </Grid>
                <Grid item className={classes.addsCardRight}>
                  <img src={amityImg} className={classes.amityLogo} style={{ width: "6rem" }} />
                </Grid>
              </Grid>
              <Card className={classes.addsCard} style={{ position: 'relative', minHeight: '235px', borderRadius: '0' }}>
                <img src={globalImg} className={classes.globalCollegeBg} />
                <CardContent className={classes.globalCardContent} >
                  <br />

                  <Grid container direction="column">
                    <Typography variant="h4" style={{ marginBottom: '0.5rem', color: '#fff', fontWeight: '500' }} >RANKED AMONGST THE TOP 3% UNIVERSITIES GLOBALLY</Typography>
                    <img src={qsImg} className={classes.collegeQsLogo} />
                    <br />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>  <br />
            <Grid item xs={12} style={{ padding: "0 0.25rem" }}>
              <Card className={classes.featuredCard}>
                <CardContent style={{ padding: "0px" }}>
                  <Grid item className={classes.schoolSubCard} style={{ position: "relative" }}>
                    <img src={schoolImg} className={classes.imgReviewer} />
                    <Grid container direction="column" className={classes.popularcollegeBlock}>
                      <Typography variant="body" className={classes.featuredButton}>Featured</Typography>
                    </Grid>
                  </Grid>

                  <Grid container direction="column" style={{ padding: "0.3rem" }}>
                    <Typography variant="h4" style={{ marginBottom: '0.3rem' }} className={classes.rightCollegeReviewTitle}>Dps Delhi</Typography>
                    <Typography variant="body2" style={{ fontSize: '0.65rem' }} >Ranked 1 For IITs by The Week 2020</Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {totalPages > 1 && <Grid container direction="row" className={classes.paginatation} alignItems="center" spacing={4}>
          <Pagination page={currentPage} data-testId="onPageChange" onChange={this.onPageChange} count={totalPages} variant="outlined" shape="rounded" />
        </Grid>}
      </Container>
    </>

    );
  }
}
//@ts-ignore
const CollegeSearchWithRouter = withRouter(CollegeSearch);
const CollegeSearchWithLoader = withLoader(CollegeSearchWithRouter);
const CollegeSearchToast = withSnackbar(CollegeSearchWithLoader);
const CollegeSearchWithStyle = withStyles((theme) =>
  createStyles({
    categoryViewallGrid: {
      margin: '3rem 3rem 1rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        margin: '1.5rem 2rem 0rem',
      },
      [theme.breakpoints.down('md')]: {
        margin: '0.5rem 0rem 0rem',
      },
    },
    uParallaxGrid: {
      position: 'absolute',
      backgroundColor: 'rgb(6 55 106 / 53%)',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
    },
    categorySearchItem: {
      width: '50%',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      borderRadius: '17px',
      padding: '12px 15px 8px',
      color: '#fff',
      position: 'absolute',
      left: '25%',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        left: '19%',
      },
    },
    featuredButton: {
      fontSize: '0.5rem',
      color: '#fff',
      backgroundColor: 'rgb(203 199 199 / 54%)',
      padding: '0.2rem',
      borderRadius: '4px',
      width: 'fit-content',
    },
    textFeatured: {
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      color: ' #222',
      fontSize: '1rem',
    },
    imgReviewer: {
      height: '80px',
      objectFit: 'cover',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      width: '100%',
    },
    popularCollegeCard: {
      margin: '0 0.75rem 1.5rem 0.75rem',
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 0.5rem 0',
      },
    },
    popularCollegecardImg: {
      position: 'relative',
    },
    popularCollegeImg: {
      height: '130px',
      objectFit: 'cover',
      width: '100%',
    },
    popularcollegeBlock: {
      position: 'absolute',
      top: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
      padding: '10px',
    },
    popularCollegeTitle: {
      fontSize: '1.2rem',
      color: '#fff',
    },
    visitedWebsiteBtn: {
      backgroundColor: '#7764db',
      padding: '7px 14px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      '&:hover': {
        backgroundColor: '#5840d7 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.6,
      },
    },
    brochureBtnDownload: {
      backgroundColor: '#fa6400',
      padding: '7px 4px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      '&:hover': {
        backgroundColor: '#dd5a03 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.7,
      },
    },
    rightCollegeReviewTitle: {
      fontSize: '14px',
      color: '#7764db',
    },
    collegeSearchBlock: {
      padding: '0 2rem',
      maxWidth: '1820px',
      margin: '0 0 0 auto',
      [theme.breakpoints.down('md')]: {
        padding: '0 1rem',
      },
    },
    schoolSearchMiddleBlock: {
      padding: '0',
    },
    schoolSearchLeftBlock: {
      paddingRight: '2rem',
      [theme.breakpoints.down('lg')]: {
        paddingRight: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: '0rem',
      },
    },
    collegeSearchRightBlock: {
      paddingLeft: '2rem',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0rem',
      },
    },
    collegeheadTxt: {
      color: '#000',
      margin: '0.6rem',
    },
    collegecheckgroupTxt: {
      color: '#000',
      margin: '0rem',

    },
    collegeSearchInput: {
      border: '1px solid #eee',
      color: '#000',
      borderRadius: '4px',
      margin: '0 10px',
      padding: '5px',
    },
    collegeQsLogo: {
      maxWidth: '100px !important',
    },
    globalCollegeBg: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      minHeight: '235px',
    },
    globalCardContent: {
      top: '0px',
      width: '100%',
      height: '100%',
      padding: '12px',
      position: 'absolute',
      backgroundColor: 'rgb(3 62 96 / 60%)',
    },
    sessionCollegeBtn: {
      width: '6.8rem',
      textAlign: 'left',
      padding: '2px',
      backgroundColor: '#f7a600',
      borderRadius: '0',
    },
    inputCollegeInput: {
      '&::placeholder': {
        color: '#fff !important',
        opacity: '1 !important',

      }
    }, applybutton: {
      padding: '6px 42px',
      marginRight: '10px',
      margin: '5px 0px',
      backgroundColor: '#5285f8 !important',
      borderRadius: '6px',
      fontSize: '13px',
      fontFamily: 'Montserrat',
      fontWeight: '500',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1a5df3 !important',
        color: '#fff'
      }
    },
    clearbuttton: {
      margin: '5px 0px',
      backgroundColor: '#fff !important',
      boxShadow: 'none',
      borderRadius: '6px',
      fontSize: '13px',
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: '500',
      color: '#000',
      padding: '6px 8px',
    },
    nodata: {
      justifyContent: 'center',
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
    },
    coveritem: {
      height: '6.1rem',
      cursor: 'pointer',
    },
    paginatation: {
      marginTop: '2rem',
      marginBottom: '4rem',
      justifyContent: 'center',
    },

  })
)(CollegeSearchToast);
export default withTranslation()(CollegeSearchWithStyle);