export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const blog = require("../assets/placeholder_all.png");
export const volicity = require("../assets/volicity.png");
export const eye = require("../assets/eye.png");
export const play = require("../assets/play.png");
export const share = require("../assets/share.png");
export const profile = require("../assets/profile.png");
export const dummyImage = require("../assets/image.png");
export const courseImg = require("../assets/cousres-all.png");
export const fbImg = require("../assets/facebook.png");
export const linkedInImg = require("../assets/Linkedin.png");
export const prev = require("../assets/prev.png");
export const next = require("../assets/next.png");
