//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Stepper, Step, StepButton, createStyles
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { Props } from './UserProfileBasicBlockController.web';
import PersonalInformation from "./PersonalInfo.web";
import Explore from "./Explore.web";
import K12 from "./categories/K12.web";
import HigherEducation from "./categories/HigherEducation.web";
import CompetitiveExam from "./categories/CompetitiveExam.web";
import Govt from "./categories/Govt.web";
import Upskilling from "./categories/Upskilling.web";
import Finished from "./categories/Finished.web";
export class StepperWeb extends React.Component {
  constructor(props: Props) {
    super(props);
  }
  getStepContent = (stepNumber: any, stepLabel: string) => {
    switch (stepNumber) {
      case 0:
        return (<PersonalInformation
          userDetailList={this.props.userDetailList}
          updateUserDetail={this.props.updateUserDetail}
          profileImgBase64={this.props.profileImgBase64}
          getSteps={this.props.getSteps}
          activeStep={this.props.activeStep}
          profileImgBase64Obj={this.props.profileImgBase64Obj}
          profileImageHandler={this.props.profileImageHandler}
        />);
      case 1:
        return (<Explore
          selectedCategory={this.props.selectedCategory}
          selectedSubCategory={this.props.selectedSubCategory}
          showSubCatArray={this.props.showSubCatArray}
          categories={this.props.categories}
          getAllCategories={this.props.getAllCategories}
          onBtnClick={this.props.onBtnClick}
          clickNext={this.props.clickNext}
          handleSubCategorySelection={this.props.handleSubCategorySelection}
          userDetailList={this.props.userDetailList}
          userDetailCat={this.props.userDetailCat}
          handleReset={this.props.handleReset}
          activeStep={this.props.activeStep}
          getSteps={this.props.getSteps}
        />);
      case 2:
        return (this.getDynamicComponent(stepLabel))
      case 3:
        return (this.getDynamicComponent(stepLabel))
      case 4:
        return (this.getDynamicComponent(stepLabel))
      case 5:
        return (this.getDynamicComponent(stepLabel))
      case 6:
        return (this.getDynamicComponent(stepLabel))
      default:
        return (<PersonalInformation />);
    }
  }
  getDynamicComponent = (stepLabel: string) => {
    switch (stepLabel?.toLowerCase()) {
      case 'k12':
        return (<K12
          updateK12Detail={this.props.updateK12Detail}
          boards={this.props.boards}
          subjects={this.props.subjects}
          k12Subject={this.props.k12Subject}
          standards={this.props.standards}
          getSteps={this.props.getSteps}
          handleReset={this.props.handleReset}
          profileDetail={this.props.profileDetail}
          activeStep={this.props.activeStep}
        />);
      case 'higher education':
        return (<HigherEducation
          updateHigherEducationDetail={this.props.updateHigherEducationDetail}
          colleges={this.props.colleges}
          degree={this.props.degree}
          courses={this.props.courses}
          passingYear={this.props.passingYear}
          specialization={this.props.specialization}
          getSteps={this.props.getSteps}
          handleReset={this.props.handleReset}
          profileDetail={this.props.profileDetail}
          activeStep={this.props.activeStep}
          setOtherCollege={this.props.setOtherCollege}
          openNewField={this.props.openNewField}
          setCollege={this.props.setCollege}
        />);
      case 'competitive exams':
        return (<CompetitiveExam
          updateCompetitiveExam={this.props.updateCompetitiveExam}
          colleges={this.props.colleges}
          educationLevel={this.props.educationLevel}
          degree={this.props.degree}
          courses={this.props.courses}
          passingYear={this.props.passingYear}
          specialization={this.props.specialization}
          boards={this.props.boards}
          subjects={this.props.subjects}
          subject={this.props.subject}
          standards={this.props.standards}
          getSteps={this.props.getSteps}
          handleReset={this.props.handleReset}
          profileDetail={this.props.profileDetail}
          activeStep={this.props.activeStep}
          openNewComField={this.props.openNewComField}
          setComCollege={this.props.setComCollege}
          setOtherComCollege={this.props.setOtherComCollege}
        />);
      case 'govt job':
        return (<Govt
          specialization={this.props.specialization}
          educationLevel={this.props.educationLevel}
          profileDetail={this.props.profileDetail}
          handleReset={this.props.handleReset}
          casteCategory={this.props.casteCategory}
          activeStep={this.props.activeStep}
          getSteps={this.props.getSteps}
          updateGovtDetail={this.props.updateGovtDetail} />);
      case 'upskilling':
        return (<Upskilling
          updateUpskilling={this.props.updateUpskilling}
          colleges={this.props.colleges}
          educationLevel={this.props.educationLevel}
          degree={this.props.degree}
          courses={this.props.courses}
          passingYear={this.props.passingYear}
          specialization={this.props.specialization}
          boards={this.props.boards}
          subjects={this.props.subjects}
          standards={this.props.standards}
          getSteps={this.props.getSteps}
          handleReset={this.props.handleReset}
          profileDetail={this.props.profileDetail}
          activeStep={this.props.activeStep}
          addEducations={this.props.addEducations}
          addCertificates={this.props.addCertificates}
          addEmployments={this.props.addEmployments}
          handleELChange={this.props.handleELChange}
          handleCertiChange={this.props.handleCertiChange}
          handleEmploymentChange={this.props.handleEmploymentChange}
          upskillingELValues={this.props.upskillingELValues}
          selectedEducationLevel={this.props.selectedEducationLevel}
          upskillingCertificateValues={this.props.upskillingCertificateValues}
          upskillingEmployementValues={this.props.upskillingEmployementValues}
          currentMaxIdOfAddEducation={this.props.currentMaxIdOfAddEducation}
          currentEduIndex={this.props.currentEduIndex}
          currentCertIndex={this.props.currentCertIndex}
          currentEmpIndex={this.props.currentEmpIndex}
          setAccIndex={this.props.setAccIndex}
          setCertificateAccIndex={this.props.setCertificateAccIndex}
          setEmploymentAccIndex={this.props.setEmploymentAccIndex}
          isExpand={this.props.isExpand}
          isCerExpand={this.props.isCerExpand}
          isEmpExpand={this.props.isEmpExpand}
          toggle={this.props.toggle}
          domainWork={this.props.domainWork}
          removeItem={this.props.removeItem}
          isDisabled={this.props.isDisabled}
          openNewFieldInUpskilling={this.props.openNewFieldInUpskilling}
          upskillSubject={this.props.upskillSubject}
        />)
      default:
        return (<PersonalInformation />);
    }
  }

  render() {
    //@ts-ignore
    const { classes, getSteps, activeStep } = this.props;
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Stepper alternativeLabel activeStep={activeStep}>
            {getSteps.map((label: any, index: any) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={label.stepLabel} {...stepProps}>
                  <StepButton >
                    {label.stepLabel}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        {this.props.isFinishStep ? (
          <Grid container direction="row" alignItems="center" justify="center">
            <Finished handleReset={this.props.handleReset} />
          </Grid>
        ) :
          (<Grid item xs={12} className={classes.profile}>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item xs={12}>
                {this.getStepContent(this.props.activeStep, this.props.activeLabel)}
              </Grid>
            </Grid>
          </Grid>)
        }
      </Grid>
    )
  }
}
//@ts-ignore
const StepperWebWithRouter = withRouter(StepperWeb);
export default withStyles((theme) =>
  createStyles({
    profile: {
      border: 'solid 1px #f0dbdb',
      height: 'auto',
      position: 'relative',
      bottom: '20px',
      width: '45rem',
      borderRadius: '7px',
      boxShadow: '0 8px 12px 0 rgba(213, 210, 210, 0.16)',
      marginTop: '20px'
    },
    btn: {
      position: 'absolute',
      bottom: '2rem',
      width: '18rem'
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      width: '120px',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#000000'
      }
    }
  })
)(StepperWebWithRouter);