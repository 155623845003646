import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  runningCourseList: any;
  shortlistedCourseList: any;
  suggestedCourseList: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
export default class UserLearningDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  getRunningCourseApiCallId: string = "";
  getShortlistedCourseApiCallId: string = "";
  getSuggestedCourseApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      loading: false,
      showToast: {
        show: false,
        message: "",
        type: "success" || "info" || "warning" || "error",
      },
      runningCourseList: [],
      shortlistedCourseList: [],
      suggestedCourseList: []
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        switch (apiRequestCallId) {
          case this.getRunningCourseApiCallId:
            this.setState({ runningCourseList: responseJson.data });
            break;
          case this.getShortlistedCourseApiCallId:
            this.setState({ shortlistedCourseList: responseJson.data });
            break;
          case this.getSuggestedCourseApiCallId:
            this.setState({ suggestedCourseList: responseJson.data });
            break;
          default:
            break;
        }
      } else if (responseJson.errors) {
        if (responseJson.errors[0].token)
          this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
        setTimeout(() => {
          localStorage.clear();
          this.props.history.push('/home');
        }, 2000)
      }
    }
  }
  // Customizable Area Start
  getRunningCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getRunningCourseApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRuningCourseUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getShortlistedCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getShortlistedCourseApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShortlistedCourseUrl + `?language=${lang} `
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSuggestedCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSuggestedCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSuggestedCourseUrl
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}