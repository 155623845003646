import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import moment from "moment";
import { sendAPIRequest } from "../../../components/src/Utility";
import { Category } from "./CommonFunctions"

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    navigation: any;
    match: any;
    hideLoader: any;
    showLoader: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  categoriesData: Category[];
  featureArticle: any[];
  trendingArticle: any[];
  recommendArticle: any[];
  featureVideo: any;
  filterdata: any[];
  selectedType: any;
  selectedChip: any;
  searchInfo: any;
  contentTypelist: any[];
  tagsList: any[];
  queryobj: any;
  contentTypeInfo: any;
  days: any;
  // Customizable Area End

}
interface SS {
  id: any;
}



export default class ArticleviewWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContentBySearchId: string = "";
  getTrendingContentBySearchId: string = "";
  getRecommendContentBySearchId: string = "";
  getFilterOptionsId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      categoriesData: [],
      featureArticle: [],
      featureVideo: [],
      filterdata: configJSON.filtersOptions,
      selectedType: 0,
      selectedChip: 0,
      searchInfo: '',
      contentTypelist: [],
      trendingArticle: [],
      recommendArticle: [],
      tagsList: [],
      queryobj: configJSON.queryobj,
      contentTypeInfo: {
        'articles': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 1, 'parameter': '&content_type[]=1', 'filter_type': 'news_article', 'title': 'Article' },
        'blogs': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 2, 'parameter': '&content_type[]=2', 'filter_type': 'Blog', 'title': 'Blog' },
        'video': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 4, 'parameter': '&content_type[]=4', 'filter_type': 'videos', 'title': 'Video' },
        'shortvideos': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 3, 'parameter': '&content_type[]=3', 'filter_type': 'short_videos', 'title': 'Short Videos' },
        'podcast': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 6, 'parameter': '&content_type[]=6', 'filter_type': 'podcasts', 'title': 'Podcast' },
        'epubs': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 9, 'parameter': '&content_type[]=9', 'filter_type': 'ebooks', 'title': 'E-Books' },
        'livestream': { 'feature': '&is_featured=true', 'recommend': '&is_popular=true', 'trending': '&is_trending=true', 'contentTypeId': 5, 'parameter': '&content_type=5', 'filter_type': 'Live Stream', 'title': 'Live Stream' },
      },
      days: configJSON.daysinfo,
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getFilterOptions('category', this.state.filterdata)
    this.iniateMethods()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getContentBySearchId:
            this.handleContentBySearchIdApiResponce(responseJson);
            break;
          case this.getRecommendContentBySearchId:
            this.handleRecommendContentBySearchIdApiResponce(responseJson);
            break;
          case this.getTrendingContentBySearchId:
            this.handleTrendingContentBySearchIdApiResponce(responseJson);
            break;
          case this.getFilterOptionsId:
            this.handleFilterOptionsIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }

  handleContentBySearchIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      window.scrollTo(0, 0)
      this.setState({ featureArticle: responseJson.data });
    }
    this.props.hideLoader();
  }
  handleRecommendContentBySearchIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      window.scrollTo(0, 0)
      this.setState({ recommendArticle: responseJson.data });
    }
  }
  handleTrendingContentBySearchIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      window.scrollTo(0, 0)
      this.setState({ trendingArticle: responseJson.data });
    }
  }
  handleFilterOptionsIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        info[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: info });
    }
  }
  clearLocalstorage = (responseJson: any) => {
    console.log(responseJson.errors);
  }


  iniateMethods = () => {
    let data = this.state.filterdata
    data[0].value = []
    data[1].value = []
    if (this.props.match?.params?.categoryId) {
      data[0].value.push(this.props.match?.params?.categoryId)
    }
    if (this.props.match?.params?.subCategoryId) {
      data[1].value.push(this.props.match?.params?.subCategoryId)
    }
    this.setState({ filterdata: data }, () => {
      this.getContentsBysearchCriteria(this.state.filterdata)
      this.getTrendingContentsBysearchCriteria(this.state.filterdata)
      this.getRcommendContentsBysearchCriteria(this.state.filterdata)
    })

  }
  // get contents types

  getContentsBysearchCriteria = (data: any) => {
    this.props.showLoader();
    let searchInfo: any = this.getParameters('feature', data, this.props.match?.params?.categoryId)
    const apiEndPoint = `${configJSON.getContents}?page=1&per_page=2${searchInfo.article}${searchInfo.params}`;
    this.getContentBySearchId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
  getTrendingContentsBysearchCriteria = (data: any) => {
    let searchInfo: any = this.getParameters('trending', data, this.props.match?.params?.categoryId)
    const apiEndPoint = `${configJSON.getContents}?page=1&per_page=2${searchInfo.article}${searchInfo.params}`;
    this.getTrendingContentBySearchId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
  getRcommendContentsBysearchCriteria = (data: any) => {
    let searchInfo: any = this.getParameters('recommend', data, this.props.match?.params?.categoryId)
    const apiEndPoint = `${configJSON.getContents}?page=1&per_page=2${searchInfo.article}${searchInfo.params}`;
    this.getRecommendContentBySearchId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }

  // epub List api start
  getParameters = (typeCase: any, fiterData: any, id: any) => {
    let article = ''
    article = this.state.contentTypeInfo[this.props.match?.params?.type][typeCase]
    if (this.state.searchInfo) {
      article += '&search=' + this.state.searchInfo
    }
    let params = ''
    if (fiterData && fiterData.length > 0) {
      fiterData.map((ele: any) => {
        if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.id != 3) {
          params += this.setOptions(ele, params)
        } else if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.type == 'Date') {
          article += '&' + this.dateValue(ele);
        }
      })
    }
    params += this.state.contentTypeInfo[this.props.match?.params?.type].parameter
    let obj = {
      article,
      params
    }
    return obj
  }
  setOptions = (ele: any, params: any) => {
    ele.value.forEach((e: any) => {
      if (ele.id == 4) {
        let tagInfo = ele.data.filter((el: any) => el.id == e)
        if (tagInfo && tagInfo.length > 0) {
          e = tagInfo[0]?.attributes?.name
        }
      }
      params += `&${this.state.queryobj[ele.id]}=${e}`
    })
    return params;
  }
  dateValue = (ele: any) => {
    let param = ''
    if (ele.value.indexOf(6) != -1) {
      param = `date[from]=${moment(ele.fromDate).format("DD/MM/YYYY")}&date[to]=${moment(ele.toDate).format("DD/MM/YYYY")}`
    } else if (ele.value.indexOf(1) != -1) {
      param = `date[to]=${moment().format("DD/MM/YYYY")}&date[from]=${moment().format("DD/MM/YYYY")}`
    } else {
      param = `date[from]=${moment().subtract(this.state.days[ele.value[0]], 'days').format("DD/MM/YYYY")}&date[to]=${moment().format("DD/MM/YYYY")}`
    }
    return param;
  }
  getFilterOptions = (filterby: any, filterinfo: any) => {
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.value.length > 0 && ele.id != 3) {
        ele.value.forEach((e: any, i: any) => {
          if (ele.id == 4) {
            let tagInfo = ele.data.filter((el: any) => el.id == e)
            if (tagInfo && tagInfo.length > 0) {
              e = tagInfo[0]?.attributes?.name
            }
          }
          query += `&${this.state.queryobj[ele.id]}=` + e
        })
      }
    })
    let param = this.state.contentTypeInfo[this.props.match?.params?.type].parameter;
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=content${query}${param}`;

    this.getFilterOptionsId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
}


