//@ts-nocheck
//@ts-ignore

import React from 'react';
import {View,Text, FlatList,TouchableOpacity,Linking,Alert} from 'react-native';
import Button from '../Button/Button';
import CardImage from '../CardImages/CardImage';
import Card from '../cards/Cards';
import CardTitle from '../cards/CardTitle';
import LocationOnCard from '../cards/LocationOnCard';
import { bg_white, Bold, grey_text_color, orange_title_text, size_14pt } from '../../Utils';
import ThemeColorContext from '../../../../../components/src/themeColorManagement/themeColorContext';
import GlobalTags from '../globalTag/GlobalTags';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import { OrangeVampireGreyColor, whiteBlackColor, white_dimGreycolor } from '../Helper/common';
interface Props{
    title:any;
    searchResults:any;
    onPress:()=>void;
    globalTag:any;
    activeTab:any
}
let controller :any

export default class SearchResult extends React.Component<Props> {
    // controller = new GlobalTags();
    constructor(props:Props){
        super(props);
        controller = new GlobalTags();
    }

    renderNameItem = (item: any, index: any, themeColorContext: any) => {
        if(index>=3) return null
        if(this.props.activeTab === 'Schools'){
            if(item.type === 'Location'){
                return <Text style={{color: OrangeVampireGreyColor(themeColorContext), fontSize:size_14pt, fontFamily:Bold,marginStart:5}}>{JSON.stringify(item.name)},</Text>
            }
        } else if(this.props.activeTab === 'Colleges') {
            if(item.type === 'Category'){
                return <Text style={{color: OrangeVampireGreyColor(themeColorContext), fontSize:size_14pt, fontFamily:Bold,marginStart:5}}>{JSON.stringify(item.name)},</Text>
            }
        }
        return null
    }

    manageAllOption = (themeColorContext: any) => {
        return this.props.globalTag?.length ==0 ? <Text style={{color: OrangeVampireGreyColor(themeColorContext), fontSize:size_14pt, fontFamily:Bold,marginStart:5}}>All</Text> : null
    }

    renderSeeAll = (themeColorContext: any) => {
        return this.props.searchResults.length > 3 ?
            <TouchableOpacity style={{marginRight:5}} onPress={()=>this.props.onPress()}>
                <Text style={{ color: white_dimGreycolor(themeColorContext),fontSize:12, fontFamily:Bold,marginStart:15,borderBottomWidth:1,borderColor: white_dimGreycolor(themeColorContext)}}>See All</Text>
            </TouchableOpacity>
        : null 
    }

    onPressBtn = (item: any) => {
        item?.attributes?.website_url
            ? Linking.openURL(item?.attributes?.website_url)
                .then(foo => {
                    console.log('yesss ', foo)
                })
                .catch(error => {
                    Alert.alert('URl not found')
                })
            : Alert.alert('URl not found')
    }

    renderItem = (item: any, themeColorContext: any) => {
        return (
            <Card
                activeTheme={themeColorContext.themeColor}
                onPress={() => null}
            >
            <CardImage
                types={'SearchResults'}
                rating={item?.attributes?.course_rating}
                logo={item?.attributes?.logo}
            />
            <CardTitle
                activeTheme={themeColorContext.themeColor}
                name={item?.attributes?.name}
            />
            <LocationOnCard
                onlyLocation
                locationName={item?.attributes?.city?.data?.attributes?.name}
                rating={item?.attributes?.course_rating}
            />
            <Button
                types={''}
                exploreButton
                onPress={() => this.onPressBtn(item)}
            />
            </Card>
        )
    }

    render(){
       // console.log('globalTag dataaa ',this.props.activeTab )
        return( 
            <ThemeColorContext.Consumer>
            {(themeColorContext) => (
            <>
            <View>
            <View style={{flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <View style={{flexDirection:'row',width:'83%'}}>
                    <Text style={{color: OrangeVampireGreyColor(themeColorContext), fontSize:size_14pt, fontFamily:Bold}}>Search Results</Text>
                    {this.manageAllOption(themeColorContext)}
                    <FlatList
                        data={this.props.globalTag}
                        horizontal
                        renderItem={({item,index}:{item:any,index:any})=>this.renderNameItem(item, index, themeColorContext)}
                    /> 
                </View>
            {this.renderSeeAll(themeColorContext)}
            
            </View>
            <FlatList
                data={this.props.searchResults}
                horizontal
                ListEmptyComponent={()=>{
                    return  <View style={{justifyContent:'center',width:widthPercentageToDP('100%'),padding:heightPercentageToDP('2%')}}>
                        <Text style={{color: whiteBlackColor(themeColorContext),alignSelf:'center'}}>No Result Found</Text>
                        </View> 
                }}
                showsHorizontalScrollIndicator={false}
                renderItem={({item}:{item:any})=> this.renderItem(item, themeColorContext)}
            />
            </View>
            </>
            )}
            </ThemeColorContext.Consumer>
        );
    }
}