Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.numFormatter = (num) => {
  if (num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num > 100000) {
      return (num / 100000).toFixed(1) + 'Lakh'; // convert to M for number from > 1 million 
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  } else {
    return '-'
  }
}

exports.errorHandling = (responseJson) => {
  if (responseJson && responseJson.errors) {
    if (Array.isArray(responseJson?.errors) && responseJson?.errors[0]?.token) {
      this.props?.openToastHandler("error", responseJson.errors[0].token + `please login again`);
      setTimeout(() => {
        localStorage.clear();
        this.props?.history.push('/home');
      }, 2000)
    } else if (responseJson?.errors) {
      this.props?.openToastHandler("error", responseJson?.errors);
    }
  }
}
// Customizable Area End
