//@ts-nocheck
//@ts-ignore
import React from 'react';
import { View, Text, FlatList, TouchableOpacity, Linking, Alert } from 'react-native';
import Button from '../Button/Button';
import CardImage from '../CardImages/CardImage';
import Card from '../cards/Cards';
import CardTitle from '../cards/CardTitle';
import LocationOnCard from '../cards/LocationOnCard';
import { bg_white, Bold, grey_text_color, orange_title_text, size_14pt } from '../../Utils';
import ThemeColorContext from '../../../../../components/src/themeColorManagement/themeColorContext';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import { translate } from "../../../../../components/src/i18n/translate";

interface Props {
    title: string;
    popularSchools?: any;
    onPress: () => void;
}

export default class Popular extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        //console.log('Checkkkk ',this.props.popularSchools)
        return (
            <ThemeColorContext.Consumer>
                {(themeColorContext) => (
                    <>
                        <View>
                            {/* <Text style={{color: themeColorContext.themeColor == 'dark'? white : grey_text_color, fontSize:size_14pt, fontFamily:Bold,marginStart:15}}>{this.props.title}</Text> */}
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text style={{ color: themeColorContext.themeColor == 'dark' ? orange_title_text : grey_text_color, fontSize: size_14pt, fontFamily: Bold }}>{this.props.title}</Text>

                                {
                                    this.props.popularSchools.length > 3 ?
                                    <TouchableOpacity style={{ marginRight: 5 }}
                                    onPress={() => this.props.onPress()} >
                                    <Text style={{ color: themeColorContext.themeColor === 'dark' ? 'rgb(255,255,255)' : 'rgb(105,105,105)', fontSize: 12, fontFamily: Bold, marginStart: 15, borderBottomWidth: 1, borderColor: themeColorContext.themeColor === 'dark' ? 'rgb(255,255,255)' : 'rgb(105,105,105)' }}>{translate("SeeAll")}</Text>
                                </TouchableOpacity>
                                :
                                null
                                }
                               
                            </View>
                            <FlatList
                                data={this.props.popularSchools.slice(0,3)}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                ListEmptyComponent={() => {
                                    return <View style={{ justifyContent: 'center', width: widthPercentageToDP('100%'), padding: heightPercentageToDP('2%') }}>
                                        <Text style={{ color: themeColorContext.themeColor === 'dark' ? bg_white : '#000', alignSelf: 'center' }}>No Result Found</Text>
                                    </View>
                                }}
                                renderItem={({ item }: { item: any }) => {
                                    return <Card activeTheme={themeColorContext.themeColor}
                                        onPress={() => null}
                                    //  onPress={()=>{ item?.attributes?.website_url?
                                    //     Linking.openURL(item?.attributes?.website_url) .then(foo => {
                                    //         console.log('yesss ',foo)
                                    //     })
                                    //     .catch(error => {
                                    //         Alert.alert('URl not found')
                                    //     })
                                    //     : Alert.alert('URl not found');}}
                                    >
                                        <CardImage types={'Popular'}
                                            logo={item?.attributes?.logo}
                                            rating={item?.attributes?.course_rating}
                                        // rating = {item?.attributes?.rank}
                                        />
                                        <CardTitle activeTheme={themeColorContext.themeColor} name={item?.attributes?.name} />
                                        <LocationOnCard onlyLocation locationName={item?.attributes?.city?.data?.attributes?.name}
                                            rating={item?.attributes?.course_rating}
                                        />
                                        <Button exploreButton onPress={() => {
                                            item?.attributes?.website_url ?
                                                Linking.openURL(item?.attributes?.website_url).then(foo => {
                                                    console.log('yesss ', foo)
                                                })
                                                    .catch(error => {
                                                        Alert.alert('URL not found')
                                                    })
                                                : Alert.alert('URL not found');
                                        }}
                                            types={''}
                                        />
                                    </Card>
                                }}
                            />
                        </View>
                    </>
                )}
            </ThemeColorContext.Consumer>
        );
    }
}