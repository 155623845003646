//@ts-nocheck
//@ts-ignore
import React from 'react';
import { ScrollView } from 'react-native';
import Featured from '../components/featured/Featured';
import Popular from '../components/popular/Popular';
import Banner from '../components/banner/Banner';
import Categories from '../components/categories/Categories';
import { featured_cllg, Popular_clg, search_results_mngmnt } from '../constants';
import SearchResult from '../components/searchResults/SearchResults';
import { translate } from "../../../../components/src/i18n/translate";

interface Props {
    featuredColleges: any;
    popularColleges: any;
    CategoriesData: any;
    searchCollage: any;
    globalTag: any;
    activeTab: any;
    navigation: (type: any) => void;
    selectedCategories?: any;                        // list of selected location from user
    onCategoriesChanged: (id: any, name: any) => void;    // location changed by user callback
}

export default class Colleges extends React.Component<Props>{
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ScrollView style={{ flex: 1, width: '95%', alignSelf: 'center' }} contentContainerStyle={{ flex: 1, paddingBottom: 100 }}>

                <Featured title={featured_cllg} onPress={() => { this.props.navigation(featured_cllg) }} featuredSchools={this.props.featuredColleges} />

                <Popular title={Popular_clg} onPress={() => { this.props.navigation(Popular_clg) }} popularSchools={this.props.popularColleges} />

                <Categories
                    title={translate("Categories")}
                    categories={this.props.CategoriesData}
                    selectedCategories={this.props.selectedCategories}
                    onCategoriesChanged={(id, name) => this.props.onCategoriesChanged(id, name)}
                />

                <SearchResult title={search_results_mngmnt} activeTab={this.props.activeTab} globalTag={this.props.globalTag} onPress={() => { this.props.navigation(search_results_mngmnt) }} searchResults={this.props.searchCollage} />

                <Banner />

            </ScrollView>
        );
    }

}   