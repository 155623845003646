//@ts-nocheck
import React from "react";
import {
	withStyles,
	Grid,
	createStyles,
	Typography,
	AccordionSummary, Accordion, AccordionDetails
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import JobsFilterController, { Props } from './JobsFilterController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";

//@ts-ignore
export class JobsFilter extends JobsFilterController {
	constructor(props: Props) {
		super(props);
	}
	async componentDidMount() {
		this.getJobTypeAndExperience();
		this.getLocation();
	}
	handleCheckbox = (l: any, fieldName: any) => {
		const { selectedFilterValue } = this.state;
		const { selectedCategory, selectedCompanyName } = this.props;
		const value = selectedFilterValue[fieldName] || [];
		const findIdx = value.indexOf(l);
		if (findIdx > -1) {
			value.splice(findIdx, 1);
		} else {
			value.push(l);
		}
		this.setState({ selectedFilterValue: { ...selectedFilterValue, [fieldName]: value } }, () =>
			this.props.showJobCategories(selectedCompanyName, selectedCategory));
		this.props.showTrendingJobCategories(selectedCompanyName, selectedCategory)
		this.props.parentCallback(this.state.selectedFilterValue);
	};
	renderJobExperience = (e: any) => {
		if (e == '0-2yrs') {
			return 'Entry Level';
		} else if (e == '2-4yrs') {
			return 'Associate';
		} else if (e == '4-8yrs') {
			return 'Mid-senior level';
		} else if (e == '8-16yrs') {
			return 'Director'
		} else if (e == 'parttime') {
			return 'Part Time';
		} else if (e == 'fulltime') {
			return 'Full Time';
		} else if (e == 'remote') {
			return 'Remote';
		} else if (e == 'internship') {
			return 'Internship'
		} else if (e == 'any_time') {
			return 'Any Time';
		} else if (e == 'past_month') {
			return 'Past Month';
		} else if (e == 'past_week') {
			return 'Past Week';
		} else if (e == 'last_24_hours') {
			return 'Last 24 Hrs'
		}
	}
	renderJobSalaries = (e: any) => {
		if (e == '0-3 lakhs') {
			return '0-3 Lakhs'
		} else if (e == '3-6 lakhs') {
			return '3-6 Lakhs'
		} else if (e == '6-10 lakhs') {
			return '6-10 Lakhs'
		} else if (e == '10-15 lakhs') {
			return '10-15 Lakhs'
		} else if (e == '15-30 lakhs') {
			return '15-30 Lakhs'
		} else if (e == '30-50 lakhs') {
			return '30-50 Lakhs'
		}
	}
	render() {
		//@ts-ignore
		const { classes } = this.props;
		const { selectedFilterValue, locationsArr, jobTypeAndExperience } = this.state;
		const { location = [], experience = [], jobType = [], datePosted = [], salary = [] } = selectedFilterValue;
		return (
			<>
				<Grid item container direction="row" >
					<Grid item xs={12}>
						<Accordion className={classes.accordion}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header">
								<Typography variant="h3">Location</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container direction="row">
									{locationsArr && locationsArr.map(l =>
										<Grid item key={l?.attributes?.id} container direction='row' alignItems="center" justify="flex-start">
											<Grid item>
												<Checkbox
													key={l?.attributes?.id}
													name={l?.attributes?.city}
													data-testId="handleCheckbox1"
													onChange={() => this.handleCheckbox(l?.attributes?.id, "location")}
													checked={location.includes(l?.attributes?.id)}
													color="default"
													disableRipple
													inputProps={{ 'aria-labelledby': `checkbox-list-label-${l?.attributes?.id}` }}
												/>
											</Grid>
											<Grid item>
												<Typography variant="sutitle1">{l?.attributes?.city} ({l?.attributes?.count === null ? 0 : l?.attributes?.count})</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
				<Grid item container direction="row">
					<Grid item xs={12}>
						<Accordion className={classes.accordion}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header">
								<Typography variant="h3">Job Type</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container direction="row">
									{jobTypeAndExperience && jobTypeAndExperience?.job_type &&
										Object?.keys(jobTypeAndExperience?.job_type).length > 0 && Object?.keys(jobTypeAndExperience?.job_type).map(e =>
											<Grid item container direction='row' alignItems="center" justify="flex-start">
												<Grid item>
													<Checkbox
														key={e}
														name={e}
														onChange={() => this.handleCheckbox(e, "jobType")}
														checked={jobType.includes(e)}
														color="default"
														disableRipple
														inputProps={{ 'aria-labelledby': `checkbox-list-label-${e}` }}
													/>
												</Grid>
												<Grid item>
													<Typography variant="subtitle1">{this.renderJobExperience(e)}</Typography>
												</Grid>
											</Grid>
										)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
				<Grid item container direction="row">
					<Grid item xs={12}>
						<Accordion className={classes.accordion}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header">
								<Typography variant="h3">Date Posted</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container direction="row">
									{jobTypeAndExperience && jobTypeAndExperience?.date_posted &&
										Object?.keys(jobTypeAndExperience?.date_posted).length > 0 && Object?.keys(jobTypeAndExperience?.date_posted).map(e =>
											<Grid item container direction='row' alignItems="center" justify="flex-start">
												<Grid item>
													<Checkbox
														key={e}
														name={e}
														onChange={() => this.handleCheckbox(e, "datePosted")}
														checked={datePosted.includes(e)}
														color="default"
														disableRipple
														inputProps={{ 'aria-labelledby': `checkbox-list-label-${e}` }}
													/>
												</Grid>
												<Grid item>
													<Typography variant="subtitle1">{this.renderJobExperience(e)}</Typography>
												</Grid>
											</Grid>
										)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
				<Grid item container direction="row">
					<Grid item xs={12}>
						<Accordion className={classes.accordion}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header">
								<Typography variant="h3">Experience Level</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container direction="row">
									{jobTypeAndExperience && jobTypeAndExperience?.experience &&
										Object?.keys(jobTypeAndExperience?.experience).length > 0 && Object?.keys(jobTypeAndExperience?.experience).map(e =>
											<Grid item container direction='row' alignItems="center" justify="flex-start">
												<Grid item>
													<Checkbox
														key={e}
														name={e}
														onChange={() => this.handleCheckbox(e, "experience")}
														checked={experience.includes(e)}
														color="default"
														disableRipple
														inputProps={{ 'aria-labelledby': `checkbox-list-label-${e}` }}
													/>
												</Grid>
												<Grid item>
													<Typography variant="subtitle1">{this.renderJobExperience(e)}</Typography>
												</Grid>
											</Grid>
										)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
				<Grid item container direction="row">
					<Grid item xs={12}>
						<Accordion className={classes.accordion}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-label="Expand"
								aria-controls="additional-actions1-content"
								id="additional-actions1-header">
								<Typography variant="h3">Salary Range</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container direction="row">
									{jobTypeAndExperience && jobTypeAndExperience?.salaries &&
										Object?.keys(jobTypeAndExperience?.salaries).length > 0 && Object?.keys(jobTypeAndExperience?.salaries).map(e =>
											<Grid item container direction='row' alignItems="center" justify="flex-start">
												<Grid item>
													<Checkbox
														key={e}
														name={e}
														onChange={() => this.handleCheckbox(e, "salary")}
														checked={salary.includes(e)}
														color="default"
														disableRipple
														inputProps={{ 'aria-labelledby': `checkbox-list-label-${e}` }}
													/>
												</Grid>
												<Grid item>
													<Typography variant="subtitle1">{this.renderJobSalaries(e)}</Typography>
												</Grid>
											</Grid>
										)}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
			</>
		)
	}
}
//@ts-ignore
const JobsFilterWithRouter = withRouter(JobsFilter);
const JobsFilterWithLoader = withLoader(JobsFilterWithRouter);
const JobsFilterToast = withSnackbar(JobsFilterWithLoader);
const JobsFilterWithStyle = withStyles((theme) =>
	createStyles({
		divider: {
			width: '100%',
			backgroundColor: '#000000'
		},
		accordion: {
			borderRadius: '4px',
			border: 'solid 1px #dec9c9',
			boxShadow: 'none'
		},
		location: {
			color: '#707070'
		},
		selectedCompanyName: {
			backgroundColor: '#f48b1b',
			color: '#ffffff !important',
			height: '52px',
			width: '180px',
			marginBottom: '.5rem',
			'&:hover': {
				backgroundColor: '#f48b1b !important',
				color: '#ffffff !important'
			}
		},
		companyName: {
			backgroundColor: '#ffffff',
			height: '52px',
			width: '180px',
			color: '#f48b1b',
			marginBottom: '.5rem',
			border: '1px solid #f48b1b',
			'&:hover': {
				backgroundColor: '#f48b1b',
				color: '#ffffff'
			}
		},
		jobHeaderImg: {
			backgroundImage: 'linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 117%)',
			height: '45rem',
			objectFit: 'fill',
			width: '100%'
		},
	})
)(JobsFilterToast);
export default withTranslation()(JobsFilterWithStyle);