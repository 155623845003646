//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Button,
  createStyles,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import UserLearningDashboardController, {
  Props,
} from "./UserLearningDashboardController.web";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { CourseListCardComp } from './CourseListCardComp.web';

//@ts-ignore
export const BrowseCousre = ({ classes, props }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={classes.headerCard} variant="outlined">
          <CardContent className={classes.cardContent}>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12}>
                <Typography variant="h2">
                  Welcome{" "}
                  {localStorage.getItem("firstName") || "New User"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                >
                  Education is the passport to the future,So Learn More &
                  More
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  data-testId="courses"
                  variant="contained"
                  className={classes.browseButton}
                  onClick={() => props?.history?.push("/home/courses")}
                >
                  <Typography variant="h3" style={{ fontSize: "16px" }}>
                    Browse Course
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
export const RunningCourseSeeAll = ({ classes, runningCourseList }) => {
  return (
    <Grid item xs={12} className={classes.containerSpacing}>
      <Grid container alignItems="center" direction="row">
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.runningCoursesText}>
            Running Courses
          </Typography>
        </Grid>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
export const RunningCourse = ({ classes, runningCourseList, props }) => {
  return (
    <Grid item xs={12} className={classes.containerSpacing}>
      <Grid container>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            {runningCourseList && runningCourseList.length > 0 ? runningCourseList.map((_data: any, i:number) => {
              return (
                <Grid key={i} item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Card data-testId="coursedetail" className={classes.root1} onClick={() => props?.history?.push("/home/coursedetail", { courseDetail: _data })}>
                      <CardContent className={classes.runningCoursesCardContent}>
                        <Typography
                          className={classes.runningCoursesCardTitle}
                          gutterBottom
                          variant="h4"
                        >
                          {_data.attributes.heading}
                        </Typography>
                        <Typography
                          className={classes.runningCoursesCardSubTitle}
                          gutterBottom
                          variant="h3"
                        >
                          {_data?.attributes?.content_provider?.data?.attributes?.partner_name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Grid
                          container
                          className={classes.containerPadding}
                        >
                          <Grid item xs={5}>
                            <Typography
                              variant="h3"
                              className={classes.authorText}
                            >
                              Author
                            </Typography>
                            <Typography
                              variant="h3"
                              className={classes.runningCoursesCardTitle}
                            >
                              {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} />
                          <Grid item xs={6}>
                            <Typography
                              variant="body2"
                              className={classes.authorText}
                            >
                              {parseInt(_data?.attributes?.progress_percentage)} %
                            </Typography>
                            <BorderLinearProgress
                              variant="determinate"
                              value={parseInt(_data?.attributes?.progress_percentage)}
                            />
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
              );
            }) :
              <Grid item>
                <Typography variant="body2">No Running Courses Found</Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
export const ShortlistedCoursesSeeAll = ({ classes, shortlistedCourseList, props }) => {
  return (
    <Grid item xs={12} className={classes.containerSpacing}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.runningCoursesText}>
            Shortlisted Courses
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.buttonPosition}>
          <Button data-testId="shortlistedCourseList" variant="text" onClick={() => props?.shortlistSeeAll()}>
            {shortlistedCourseList && shortlistedCourseList.length > 2 &&
              <Typography
                variant="subtitle1"
                className={classes.seeAllText}
              >
                See all
              </Typography>
            }
          </Button>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
export const ShortlistedCourses = ({ classes, shortlistedCourseList, props, showTime }) => {
  return (
    <Grid item xs={12} className={classes.containerSpacing}>
      <Grid container>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            {shortlistedCourseList && shortlistedCourseList.length > 0 ? shortlistedCourseList.map((data, i) => {
              if (i < 2) {
                return (
                  <Grid item xs={12} md={6} key={i}>
                      <Card data-testId="ShortlistedCourses" variant="outlined" className={classes.shortlistCard}
                        onClick={() => props?.history?.push("/home/coursedetail", { courseDetail: data })}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                              <img src={data.attributes.thumbnail}
                                className={classes.shortThumb} />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                  >
                                    <Grid item xs={7}>
                                      <Typography
                                        variant="body2"
                                        className={classes.shortlistCardTitle}
                                      >
                                        {data.attributes.heading} {':'} </Typography>
                                      <Typography variant="body2" className={classes.shortlistCardTitle}>{data.attributes.description}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      {data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
                                        return (data?.attributes?.user_rating >= rating ? <StarIcon key={rating} className={classes.Icons} /> : <StarBorderIcon key={rating} className={classes.Icon} />);
                                      })}
                                      {!data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
                                        return (<StarBorderIcon key={ratings} className={classes.Icon} />);
                                      })}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  direction="column"
                                  style={{
                                    marginTop: '2rem'
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="flex-end"
                                      justify="space-between"
                                    >
                                      <Grid item xs={5}>
                                        {data?.attributes?.instructors?.data.length > 0 &&
                                          <Typography variant="caption" className={classes.shortlistCardSubTitle}>
                                            {data?.attributes?.instructors?.data[0]?.attributes?.name}
                                          </Typography>
                                        }
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography variant="caption" className={classes.shortlistCardTitle} >
                                          {showTime(data?.attributes?.total_time) || 'nill'}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
              }
            }) :
              <Grid item>
                <Typography variant="body2">No ShortListed Courses Found</Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
export const SuggestedCoursesSeeAll = ({ classes, suggestedCourseList, props }) => {
  return (
    <Grid item xs={12} className={classes.containerSpacing}>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.runningCoursesText}>
            Suggested Courses
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.buttonPosition}>
          <Button  data-testId="suggestedCourses" variant="text" onClick={() => { props?.history?.push("/home/courses") }}>
            {suggestedCourseList && suggestedCourseList.length > 3 &&
              <Typography
                variant="subtitle1"
                className={classes.seeAllText}
              >
                See all
              </Typography>
            }
          </Button>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
export const SuggestedCourses = ({ classes, suggestedCourseList, props }) => {
  return (
    <Grid item xs={12} className={classes.containerSpacing}>
      <Grid container>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            {suggestedCourseList && suggestedCourseList.length > 0 ? suggestedCourseList.map((_data: any, idx: any) => {
              if (idx < 3) {
                return (
                  <>
                    <CourseListCardComp {...{ classes, props, _data, bookmarkButton: false }} />
                  </>
                );
              }
            }) :
              <Grid item className="text-center">
                <Typography variant="body2">No Data Available</Typography>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}
export class UserLearningDashboard extends UserLearningDashboardController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    this.getShortlistedCourses();
    this.getRunningCourses();
    this.getSuggestedCourses();
  }
  showTime = (time: any) => {
    let n: any = parseInt(time);
    return `0${n / 60 ^ 0}`.slice(-2) + 'H :' + ('0' + n % 60).slice(-2) + 'M'
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { runningCourseList, shortlistedCourseList, suggestedCourseList } = this.state;
    return (
      <Container maxWidth="xl" className={classes.internalPadding}>
        <Grid direction="column" alignItems="center" justify="center">
          <BrowseCousre {...{ classes, props: this.props }} />
          <RunningCourseSeeAll {...{ classes, runningCourseList }} />
          <RunningCourse {...{ classes, runningCourseList, props: this.props }} />
          <ShortlistedCoursesSeeAll {...{ classes, shortlistedCourseList, props: this.props }} />
          <ShortlistedCourses {...{ classes, shortlistedCourseList, props: this.props, showTime: this.showTime }} />
          {
            <SuggestedCoursesSeeAll  {...{ classes, suggestedCourseList, props: this.props }} />
          }
          {
            < SuggestedCourses {...{ classes, suggestedCourseList, props: this.props }} />
          }
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const UserLearningDashboardWithRouter = withRouter(UserLearningDashboard);
const UserLearningDashboardWithLoader = withLoader(
  UserLearningDashboardWithRouter
);
const UserLearningDashboardToast = withSnackbar(
  UserLearningDashboardWithLoader
);
const UserLearningDashboardWithStyle = withStyles((theme) =>
  createStyles({
    root: {
      poniter: 'cursor',
      borderRadius: "18px",
      height: '22rem'
    },
    root1: {
      poniter: 'cursor',
      borderRadius: "18px",
      height: "100%",
    },
    internalPadding: {
      padding: "0px",
      paddingLeft: "5rem",
      [theme.breakpoints.down("sm")]: {
        padding: "0rem 0rem 0rem 1rem"
      }
    },
    shortlistCard: {
      height: "8rem",
      cursor: 'pointer',
      borderRadius: "1rem",
    },
    shortThumb: {
      backgroundColor: "#f6efef",
      height: "91px",
      width: "99px",
      border: "1px solid #979797",
      borderRadius: "12px"
    },
    headerCard: {
      borderBottomLeftRadius: "2rem",
      marginTop: '-2rem'
    },
    containerSpacing: {
      marginTop: "1rem",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    cardContent: {
      backgroundColor: "#47464c",
      height: "15rem",
      paddingTop: "2rem",
      paddingLeft: "2rem",
    },
    containerPadding: {
      padding: ".5rem",
    },
    browseButton: {
      width: "10rem",
      height: "3rem",
      borderRadius: "13px",
      backgroundColor: "#fff",
      color: "#7764db",
    },
    runningCoursesText: {
      color: "#f8593b",
    },
    seeAllText: {
      color: "#826ce4",
    },
    runningCoursesCardContent: {
      height: "7rem",
      backgroundColor: "#FFF",
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c"
    },
    runningCoursesCardSubTitle: {
      color: "#1c1c1c",
      paddingTop: "10px",
    },
    authorText: {
      color: "#1c1c1c",
      paddingBottom: "10px",
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    shortlistCardTitle: {
      color: "#3b302e",
      whiteSpace: 'nowrap',
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: 'pointer'
      // width: "14rem",
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "18rem"
    }, Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    },
    buttonPosition: {
      display: "flex",
      justifyContent: "end",
    }
  })
)(UserLearningDashboardToast);
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 2,
    marginTop: "5px",
    cursor: 'pointer'
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 2,
    backgroundColor: "#d78e42",
  },
}))(LinearProgress);
export default withTranslation()(UserLearningDashboardWithStyle);
