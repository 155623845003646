//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, MenuItem, Button, createStyles, Typography, TextField
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import { withTranslation } from 'react-i18next';
import withLoader from "../../../../components/src/withLoader.web";
import { withSnackbar } from "../../../../components/src/toast.web";
import { K12validationSchema } from "../UserBasicProfile.web.validation";
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);
export class K12 extends React.Component {
  subjectInput = (classes, setFieldValue, handleBlur, touched, errors, values, subjects) => {
    return (<>
      <PinkTextField
        variant="outlined"
        name="subjects"
        style={{ width: '20rem' }}
        defaultValue={["none"]}
        onChange={(e: any) => {
          return setFieldValue('subjects', Array.from(e?.target?.value, option => option))
        }}
        SelectProps={{
          multiple: true,
          MenuProps: { classes: { paper: classes.selectBoard } }
        }}
        FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
        onBlur={handleBlur}
        error={touched.subjects && errors.subjects ? true : false}
        InputProps={{ classes: { input: classes.txtProps } }}
        helperText={
          touched.subjects && errors.subjects ? errors.subjects : ""
        }
        value={values.subjects.map(e => e)}
        select
      >
        <MyMenuItem value={[]} selected disabled>
          <Typography variant="subtitle1" className={classes.selectDisColor}>
            Select Subjects
          </Typography>
        </MyMenuItem>
        {subjects && subjects.map((option) => (
          <MyMenuItem key={option.id} value={option.id}>
            {option.attributes.name}
          </MyMenuItem>
        ))}
      </PinkTextField>
    </>)
  }
  render() {
    const { classes, activeStep, getSteps, boards, subjects, standards } = this.props;
    return (
      <Grid item xs={12} >
        <Grid container direction="row" alignItems="center" justify="flex-start" className={classes.heading}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.headTxt}>K12</Typography>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            selectBoard: this.props.profileDetail?.board?.data?.id || "none",
            schoolName: this.props.profileDetail?.school_name || "",
            standards: this.props.profileDetail?.standard?.data && this.props.profileDetail?.standard?.data?.id || "none",
            subjects: this.props.profileDetail?.subjects?.data && this.props.profileDetail?.subjects?.data.map(e => e.id) || []
          }}
          validationSchema={K12validationSchema}
          // @ts-ignore
          onSubmit={(values: any, { setSubmitting }) => {
            this.props.updateK12Detail(values);
            setSubmitting(false)
          }}
        >
          {(formikProps) => {
            const {
              values,
              isValid,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
              setFieldValue,
              isSubmitting,
            } = formikProps;
            return (
              // @ts-ignore
              <Form autoComplete='off' onSubmit={handleSubmit} >
                <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Select Board*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="selectBoard"
                              style={{ width: '20rem' }}
                              defaultValue="none"
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.selectBoard && errors.selectBoard ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.selectBoard && errors.selectBoard ? errors.selectBoard : ""
                              }
                              value={values?.selectBoard}
                              select
                            >
                              <MyMenuItem value="none" disabled>
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Select Board
                                </Typography>
                              </MyMenuItem>
                              {boards && boards.map((option) => (
                                <MyMenuItem key={option.id} value={option.id}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Enter School Name</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              name="schoolName"
                              value={values.schoolName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={
                                touched.schoolName && errors.schoolName
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.schoolName && errors.schoolName
                                  ? errors.schoolName
                                  : ""
                              }
                              InputProps={{ classes: { input: classes.txtProps } }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              placeholder="Name"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Select Class*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="standards"
                              style={{ width: '20rem' }}
                              defaultValue="none"
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.standards && errors.standards ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.standards && errors.standards ? errors.standards : ""
                              }
                              value={values.standards}
                              select
                            >
                              <MyMenuItem value="none" disabled>
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Select Class
                                </Typography>
                              </MyMenuItem>
                              {standards && standards.map((option) => (
                                <MyMenuItem key={option.id} value={option.id}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Subject</Typography>
                          </Grid>
                          <Grid item>
                            {this.subjectInput(classes, setFieldValue, handleBlur, touched, errors, values, subjects)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="row" alignItems="center" justify="space-evenly">
                      <Button type="submit" disabled={isSubmitting || !isValid || values.selectBoard === "none"
                        || values.standards === "none"}
                        className={classes.nextBtn}>
                        {activeStep === getSteps.length - 1 ? <Typography variant="h3">Finish</Typography> : <Typography variant="h3">Next</Typography>}
                      </Button>
                      <Button
                        onClick={this.props.handleReset} className={classes.cancelBtn}>
                        <Typography variant="h3">Cancel</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    )
  }
}
//@ts-ignore
const K12WebWithRouter = withRouter(K12);
const K12WebWithLoader = withLoader(K12WebWithRouter);
const K12WebToast = withSnackbar(K12WebWithLoader);
const K12WebWebWithStyle = withStyles((theme) =>
  createStyles({
    heading: {
      boxShadow: '0 8px 12px 0 rgb(213 210 210 / 16%)',
      border: 'solid 1px #e8e2e2',
      borderRadius: '7px',
      height: '5rem'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    txtHead: {
      color: '#4f4f4f',
      paddingBottom: '.5rem'
    },
    headTxt: {
      color: '#f48b1b',
      marginLeft: '1.5rem'
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      width: '120px',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#000000'
      }
    },
    txtProps: {
      width: '18.5rem',
      color: '#7764db',
      fontWeight: 600
    },
    helperTxt: {
      margin: '0px',
      textAlign: 'left',
      color: '#f9593a',
      fontWeight: 'bold',
      marginTop: '.5rem'
    },
    persInfo: {
      marginTop: '20px'
    },
    selectDisColor: {
      color: 'grey'
    }
  })
)(K12WebToast);
export default withTranslation()(K12WebWebWithStyle);