//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, createStyles, IconButton,
  Typography, CardMedia,
  Card, CardActionArea, CardContent
} from "@material-ui/core";
import ScrollMenu from "react-horizontal-scrolling-menu";
//@ts-ignore
import { withRouter } from "react-router";
import { prev, next } from './assets';
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CourseCardController from "./CourseCardController.web";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { Ratings } from "./Utility.web"
import { getRandom } from './CommonFunctions'
import { createBrowserHistory } from 'history';

const keyValue = getRandom();
export const SmDownDetail = ({ classes, _data, getAllBookmarked, removeBookmark, addBookmark, showTime, goToCourseDetailPage }) => {
  const keyDownValue = getRandom();
  return (
    <Grid item key={'SmDown_' + getRandom()}>
      <Card className={classes.root}  >
        <CardActionArea >
          <CardMedia
            data-testId="goToCourseDetailPage"
            onClick={() => goToCourseDetailPage(_data)}
            component="img"
            className={classes.suggested}
            src={_data?.attributes?.thumbnail}
            title="suggested Card title"
          />
          <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                {_data?.attributes?.enrolled_users}{" "}Enrolled
              </Typography>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                {showTime(_data?.attributes?.total_time)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.suggestedDetail}>{_data?.attributes?.heading} {':'} {_data.attributes.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Ratings key={'SmDownRating_' + getRandom()} {...{ classes, user_rating: _data?.attributes?.user_rating, keyDownValue }} />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="caption"
                    className={classes.shortlistCardSubTitle}
                  >
                    {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {localStorage.getItem('token') && getAllBookmarked && getAllBookmarked.map((e: any) =>
                    getAllBookmarked.findIndex((e) => { return e.attributes.content.id == _data.id }) > -1 ?
                      <IconButton aria-label="bookmark" key={'SmDownSelectBook_' + getRandom()} data-testId="removeBookmark" onClick={(event) => removeBookmark(_data?.attributes?.id, event)}>
                        <BookmarkIcon color="primary" />
                      </IconButton>
                      :
                      <IconButton aria-label="bookmark" key={'SmDownUnSelectBook_' + getRandom()} onClick={(event) => addBookmark(_data?.attributes?.id, event)}>
                        <BookmarkBorderIcon color="primary" />
                      </IconButton>
                  )}
                  {getAllBookmarked.length <= 0 &&
                    <IconButton aria-label="bookmark" key={'SmDownSigninSelectBook_' + getRandom()} data-testId="addBookmark" onClick={(event) => addBookmark(_data?.attributes?.id, event)}>
                      <BookmarkBorderIcon color="primary" />
                    </IconButton>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CardContent />
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export const SmUpDetail = ({ classes, _data, getAllBookmarked, removeBookmark, addBookmark, showTime, goToCourseDetailPage }) => {
  return (
    <Card className={classes.rootSmall} key={'smUp_' + getRandom()}>
      <CardActionArea>
        <CardMedia onClick={() => { goToCourseDetailPage(_data) }}
          component="img"
          className={classes.suggestedSmall}
          src={_data?.attributes?.thumbnail}
          title="suggested Card title"
        />
        <Grid container style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginTop: '.5rem' }}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.suggestedCardEnrolledTimeSmall}>
              {_data?.attributes?.enrolled_users}{" "}Enrolled
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.suggestedCardEnrolledTimeSmall}>
              {showTime(_data?.attributes?.total_time)}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '.5rem' }} className={classes.courseHeading}>
            <Typography
              variant="body2"
              className={classes.runningCoursesCardTitleSmall}
            >
              {_data.attributes.heading} {':'} </Typography>
            <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>

          </Grid>
          <Grid item xs={12}>
            <Ratings {...{ classes, user_rating: _data?.attributes?.user_rating, keyValue }} key={'smUpRating_' + getRandom()} />
          </Grid>
          <Grid item xs={12} className={classes.instructors} container direction="row" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography variant="body1"
                className={classes.shortlistCardSubTitle}>
                {_data?.attributes?.instructors?.data[0]?.attributes?.name}
              </Typography>
            </Grid>
            <Grid item xs={4} >
              {localStorage.getItem('token') && getAllBookmarked && getAllBookmarked.map((e: any) =>
                getAllBookmarked.findIndex((e) => { return e.attributes.content.id == _data.id }) > -1 ?
                  <IconButton aria-label="bookmark" key={'smUpSelectBook_' + getRandom()} onClick={() => removeBookmark(_data?.attributes?.id)}>
                    <BookmarkIcon color="primary" style={{ height: '1rem' }} />
                  </IconButton>
                  :
                  <IconButton aria-label="bookmark" key={'smUpUnSelectBook_' + getRandom()} onClick={() => addBookmark(_data?.attributes?.id)}>
                    <BookmarkBorderIcon color="primary" style={{ height: '1rem' }} />
                  </IconButton>
              )}
              {getAllBookmarked.length <= 0 &&
                <IconButton aria-label="bookmark" key={getRandom()} onClick={() => addBookmark(_data?.attributes?.id)}>
                  <BookmarkBorderIcon color="primary" style={{ height: '1rem' }} />
                </IconButton>
              }
            </Grid>
          </Grid>
        </Grid>
        <CardContent />
      </CardActionArea>
    </Card>

  )
}

export class CoursesCard extends CourseCardController {

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem('token')) {
      this.getAllBookmarkedCourses();
    }
  }


  goToCourseDetailPage = (data: any) => {
    const history = createBrowserHistory({ forceRefresh: true });
    if (history.location.pathname == '/home/coursedetail') {
      history.push("/home/coursedetail", { courseDetail: { ...data } });
    }
    else {
      this.props.history.push("/home/coursedetail", { courseDetail: { ...data } })
    }
  }

  render() {
    const { classes, isSmUp, courseList } = this.props;
    return (<>
      <ScrollMenu
        clickWhenDrag={true}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        scrollToSelected={true}
        alignCenter={false}
        transition={0.3}
        translate={0}
        wheel={false}
        arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
        arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
        data={courseList &&
          courseList.map((_data: any, idx: any) => (
            <React.Fragment key={idx + getRandom()}>
              {isSmUp ?
                <SmUpDetail
                  {...{
                    classes,
                    _data,
                    getAllBookmarked: this.state.getAllBookmarked,
                    removeBookmark: this.removeBookmark,
                    addBookmark: this.addBookmark,
                    showTime: this.showTime,
                    goToCourseDetailPage: this.goToCourseDetailPage,
                  }}
                  key={'smUp_' + getRandom()}
                />
                :
                <SmDownDetail
                  {...{
                    classes,
                    _data,
                    getAllBookmarked: this.state.getAllBookmarked,
                    removeBookmark: this.removeBookmark,
                    addBookmark: this.addBookmark,
                    showTime: this.showTime,
                    goToCourseDetailPage: this.goToCourseDetailPage,
                  }}
                  key={'smDown_' + getRandom()}
                />}
            </React.Fragment>
          ))
        } />
      <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
    </>
    );
  }
}
//@ts-ignore
const CoursesCardWithRouter = withRouter(CoursesCard);
const CoursesCardWithLoader = withLoader(CoursesCardWithRouter);
const CoursesCardToast = withSnackbar(CoursesCardWithLoader);
const CoursesCardWithStyle = withStyles((theme) =>
  createStyles({
    runningCoursesCardTitleSmall: {
      fontSize: '10px !important',
      color: "#1c1c1c",
      whiteSpace: 'pre-wrap'
    },
    rootSmall: {
      borderRadius: "8px",
      width: '9rem',
      margin: '.3rem',
      height: '11.5rem'
    },
    suggestedSmall: {
      backgroundColor: "#bdb4b4",
      height: "5rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTimeSmall: {
      color: "#1c1c1c"
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c",
      whiteSpace: 'nowrap'
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "18rem"
    },
    root: {
      borderRadius: "8px",
      width: '20rem',
      margin: '1rem'
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    },
    courseHeading: {
      display: 'flex'
    },
    instructors: {
      marginTop: '-0.75rem'
    }
  })
)(CoursesCardToast);
export default withTranslation()(CoursesCardWithStyle);
