//@ts-nocheck 
import React from "react";
import {
  withStyles, Grid, MenuItem, Button, createStyles, Typography, TextField
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import withLoader from "../../../../components/src/withLoader.web";
import { withSnackbar } from "../../../../components/src/toast.web";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "react-datepicker/dist/react-datepicker.css";
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem)
export class Upskilling extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updateUpskilling();
  }
  render() {
    const { classes, activeStep, setCertificateAccIndex, setEmploymentAccIndex, isCerExpand, isEmpExpand, currentEmpIndex, getSteps, domainWork, openNewFieldInUpskilling, upskillingELValues, isExpand, educationLevel, removeItem, isDisabled, selectedEducationLevel, upskillingEmployementValues, addEducations, addCertificates, addEmployments, upskillingCertificateValues, boards, standards, setAccIndex, subjects, degree, colleges, courses, specialization, handleELChange, handleCertiChange, handleEmploymentChange, currentEduIndex } = this.props;
    const object = { classes, upskillingEmployementValues, domainWork, removeItem }
    return (
      <Grid item xs={12} >
        <Grid container direction="row" alignItems="center" justify="flex-start" className={classes.heading}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.headTxt}>Upskilling</Typography>
          </Grid>
        </Grid>
        <form autoComplete='off' data-testId="handleSubmitUpskilling" onSubmit={this.handleSubmit}>
          <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="flex-start" justify="space-around">
                {upskillingELValues && upskillingELValues.map((el, i) => (
                  <Accordion expanded={isExpand && currentEduIndex === i ? true : false}
                    key={i} className={classes.custAccordion} elevation={0} data-testId="setAccIndex" onChange={(e) => setAccIndex(e, el, i)}>
                    <AccordionSummary
                      className={classes.expansionIcon}
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header">
                      <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead1}>
                              <Typography variant="h3" className={classes.addTxt}>Education Level {i + 1}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead1}>
                              <Button color="primary" data-testId="addEducation" onClick={() => removeItem(el.id, 'addEducation')}>
                                <Typography variant="caption" className={`${classes.addTxt} underline`}>Remove</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Choose Education Level*</Typography>
                            </Grid>
                            <Grid item key={i} className={classes.gridProp}>
                              <PinkTextField
                                variant="outlined"
                                name="education_level"
                                placeholder="Select education_level"
                                data-testId="education_level"
                                onChange={e => handleELChange(e, el, i, 'education_level')}
                                SelectProps={{
                                  MenuProps: { classes: { paper: classes.selectBoard } }
                                }}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                value={el?.attributes?.education_level?.id}
                                defaultValue="none"
                                select
                              >
                                <MyMenuItem key={""} value="none" disabled>
                                  <Typography variant="subtitle1" className={classes.selectDisColor}>
                                    Choose Education Level
                                  </Typography>
                                </MyMenuItem>
                                {educationLevel && educationLevel.map((option) => (
                                  <MyMenuItem key={option.id} value={option.id}>
                                    {option.attributes.name}
                                  </MyMenuItem>
                                ))}
                              </PinkTextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {selectedEducationLevel === false && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Select board*</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="board"
                                  defaultValue="none"
                                  data-testId="board"
                                  onChange={e => handleELChange(e, el, i, 'board')}
                                  SelectProps={{
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.board?.id}
                                  select
                                >
                                  <MyMenuItem disabled value="none">
                                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                                      Select Board*
                                    </Typography>
                                  </MyMenuItem>
                                  {boards && boards.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Degree*</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="degrees"
                                  data-testId="degrees"
                                  onChange={e => handleELChange(e, el, i, 'degree')}
                                  SelectProps={{
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.degree?.id}
                                  defaultValue="none"
                                  select
                                >
                                  <MyMenuItem key={""} value="none" disabled>
                                    Choose degree
                                  </MyMenuItem>
                                  {degree && degree.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel === false && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">School Name</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  name="schoolName"
                                  value={el?.attributes?.school_name}
                                  data-testId="schoolName"
                                  onChange={e => handleELChange(e, el, i, 'school_name')}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  placeholder="School Name"
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel === false && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Select Class*</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="standard"
                                  defaultValue="none"
                                  data-testId="standard"
                                  onChange={e => handleELChange(e, el, i, 'standard')}
                                  SelectProps={{
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.standard?.id}
                                  select
                                >
                                  <MyMenuItem disabled value="none">
                                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                                      Select Class*</Typography></MyMenuItem>
                                  {standards && standards.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel === false && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Subjects</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="subjects"
                                  placeholder="Select Subjects"
                                  data-testId="subjects"
                                  onChange={e => handleELChange(e, el, i, 'subjects')}
                                  SelectProps={{
                                    multiple: true,
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.subjects.map(e => e.id)}
                                  defaultValue="none"
                                  select
                                >
                                  <MyMenuItem key={""} disabled value="none">
                                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                                      Select Subjects</Typography>
                                  </MyMenuItem>
                                  {subjects && subjects.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Specialization</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="specialization"
                                  placeholder="Select Specialization"
                                  data-testId="specialization"
                                  onChange={e => handleELChange(e, el, i, 'specialization')}
                                  SelectProps={{
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.specialization?.id}
                                  defaultValue="none"
                                  select
                                >
                                  <MyMenuItem key={""} value="none" disabled>
                                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                                      Choose Specialization
                                    </Typography>
                                  </MyMenuItem>
                                  {specialization && specialization.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Course*</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="course"
                                  placeholder="Select Course"
                                  defaultValue="none"
                                  data-testId="course"
                                  onChange={e => handleELChange(e, el, i, 'educational_course')}
                                  SelectProps={{
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.educational_course?.id}
                                  select
                                >
                                  <MyMenuItem key={""} value="none" disabled>
                                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                                      Choose Course
                                    </Typography>
                                  </MyMenuItem>
                                  {courses && courses.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel && currentEduIndex === i &&
                          <>
                            <Grid item xs={12}>
                              <Grid container direction="column" alignItems="flex-start" justify="center">
                                <Grid item className={classes.txtHead}>
                                  <Typography variant="caption">College / University</Typography>
                                </Grid>
                                <Grid item className={classes.gridProp}>
                                  <PinkTextField
                                    variant="outlined"
                                    name="college"
                                    placeholder="Select College"
                                    defaultValue="none"
                                    data-testId="college"
                                    onChange={e => handleELChange(e, el, i, 'college')}
                                    SelectProps={{
                                      MenuProps: { classes: { paper: classes.selectBoard } }
                                    }}
                                    InputProps={{ classes: { input: classes.txtProps } }}
                                    value={el?.attributes?.college?.id}
                                    select
                                  >
                                    <MyMenuItem key={""} value="none" disabled>
                                      <Typography variant="subtitle1" className={classes.selectDisColor}>
                                        Choose College
                                      </Typography>
                                    </MyMenuItem>
                                    {colleges && colleges.map((option) => (
                                      <MyMenuItem key={option.id} value={option.id}>
                                        {option.attributes.name}
                                      </MyMenuItem>
                                    ))}
                                  </PinkTextField>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container direction="column" alignItems="flex-start" justify="center">
                                {openNewFieldInUpskilling ? <>
                                  <Grid item className={classes.txtHead}>
                                    <Typography variant="caption">College Name</Typography>
                                  </Grid>
                                  <Grid item className={classes.gridProp}>
                                    <PinkTextField
                                      variant="outlined"
                                      name="otherCollege"
                                      placeholder="Enter College"
                                      data-testId="otherCollege"
                                      onChange={e => handleELChange(e, el, i, 'college_name')}
                                      InputProps={{ classes: { input: classes.txtProps } }}
                                      value={el?.attributes?.college_name}
                                    >
                                    </PinkTextField>
                                  </Grid></> : <Grid item style={{ width: '20rem' }}></Grid>}
                              </Grid>
                            </Grid>
                          </>
                        }
                        {selectedEducationLevel && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Passing Year</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="passingYear"
                                  placeholder="Passing Year"
                                  data-testId="passingYear"
                                  onChange={e => handleELChange(e, el, i, 'passing_year')}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.passing_year}
                                >
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                        {selectedEducationLevel && currentEduIndex === i &&
                          <Grid item xs={12}>
                            <Grid item className={classes.gridHe}>
                              <Grid container direction="column" alignItems="flex-start" justify="center">
                                <Grid item className={classes.txtHead}>
                                </Grid>
                                <Grid item >
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Grid item xs={11}>
                  <Button color="primary" data-testId="addEducations" value='add more' onClick={() => addEducations()}>
                    <Typography variant="h3" className={classes.addTxt}>+Add Education</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="flex-start" justify="space-around">
                {upskillingCertificateValues && upskillingCertificateValues.map((el, i) => (
                  <Accordion expanded={isCerExpand && currentEduIndex === i ? true : false} key={i}
                    className={classes.custAccordion} elevation={0}
                    data-testId="setCertificateAccIndex"
                    onChange={(e) => setCertificateAccIndex(e, el, i)}>
                    <AccordionSummary
                      className={classes.expansionIcon}
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header">
                      <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead1}>
                              <Typography variant="h3" className={classes.addTxt}>Certifications {i + 1}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead1}>
                              <Button color="primary" data-testId='certification' onClick={() => removeItem(el?.id, 'certification')}>
                                <Typography variant="caption" className={`${classes.addTxt} underline`}>Remove</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Certification Course Name*</Typography>
                            </Grid>
                            <Grid item className={classes.gridProp}>
                              <PinkTextField
                                name="certificatecoursename"
                                value={el?.attributes?.certification_course_name}
                                data-testId="certificatecoursename"
                                onChange={e => handleCertiChange(e, el, i, "certification_course_name")}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                placeholder="Certification Course Name"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Provided By</Typography>
                            </Grid>
                            <Grid item className={classes.gridProp}>
                              <PinkTextField
                                name="providedby"
                                value={el?.attributes?.provided_by}
                                data-testId="providedby"
                                onChange={e => handleCertiChange(e, el, i, "provided_by")}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                placeholder="Provided By"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Duration</Typography>
                            </Grid>
                            <Grid item className={classes.gridProp}>
                              <PinkTextField
                                name="duration"
                                value={el?.attributes?.duration}
                                data-testId="duration"
                                onChange={e => handleCertiChange(e, el, i, "duration")}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                placeholder="Duration"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Completion Year</Typography>
                            </Grid>
                            <Grid item className={classes.gridProp}>
                              <PinkTextField
                                name="completionyear"
                                value={el?.attributes?.completion_year}
                                data-testId="completionyear"
                                onChange={e => handleCertiChange(e, el, i, "completion_year")}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                placeholder="Completion Year"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Grid item xs={11}>
                  <Button color="primary" data-testId="addCertificates" value='add more' onClick={() => addCertificates()}>
                    <Typography variant="h3" className={classes.addTxt}>+Add Certificates</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="flex-start" justify="space-around">
                {upskillingEmployementValues && upskillingEmployementValues.map((el, i) => (
                  <Accordion key={i} className={classes.custAccordion}
                    expanded={isEmpExpand && currentEmpIndex === i ? true : false}
                    data-testId="setEmploymentAccIndex"
                    onChange={(e) => setEmploymentAccIndex(e, el, i)}
                    elevation={0}>
                    <AccordionSummary
                      className={classes.expansionIcon}
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header">
                      <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead1}>
                              <Typography variant="h3" className={classes.addTxt}>Employment {i + 1}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridHe}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead1}>
                              <Button color="primary" data-testId="employment_detail" onClick={() => removeItem(el.id, 'employment_detail')}>
                                <Typography variant="caption" className={`${classes.addTxt} underline`}>Remove</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Last Employment*</Typography>
                            </Grid>
                            <Grid item className={classes.gridProp}>
                              <PinkTextField
                                name="lastemployment"
                                data-testId="lastemployment"
                                value={el?.attributes?.last_employer}
                                onChange={e => handleEmploymentChange(e, el, i, 'last_employer')}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                placeholder="Last Employment"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="column" alignItems="flex-start" justify="center">
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">Designation</Typography>
                            </Grid>
                            <Grid item className={classes.gridProp}>
                              <PinkTextField
                                name="designation"
                                value={el?.attributes?.designation}
                                data-testId="designation"
                                onChange={e => handleEmploymentChange(e, el, i, 'designation')}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                placeholder="Designation"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item>
                            <Grid container direction="column" alignItems="flex-start" justify="center">
                              <Grid item className={classes.txtHead}>
                                <Typography variant="caption">Domain Work Function</Typography>
                              </Grid>
                              <Grid item className={classes.gridProp}>
                                <PinkTextField
                                  variant="outlined"
                                  name="domain_work_function"
                                  placeholder="Select domain_work_function"
                                  data-testId="domain_work_function"
                                  onChange={e => handleEmploymentChange(e, el, i, 'domain_work_function')}
                                  SelectProps={{
                                    MenuProps: { classes: { paper: classes.selectBoard } }
                                  }}
                                  InputProps={{ classes: { input: classes.txtProps } }}
                                  value={el?.attributes?.domain_work_function?.id}
                                  defaultValue="none"
                                  select
                                >
                                  <MyMenuItem key={""} value="none" disabled>
                                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                                      Choose Domain Work Function
                                    </Typography>
                                  </MyMenuItem>
                                  {domainWork && domainWork.map((option) => (
                                    <MyMenuItem key={option.id} value={option.id}>
                                      {option.attributes.name}
                                    </MyMenuItem>
                                  ))}
                                </PinkTextField>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Grid item xs={11}>
                  <Button color="primary" data-testid="addEmployments" value='add more' onClick={() => addEmployments()}>
                    <Typography variant="h3" className={classes.addTxt}>+Add Employment Details</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid container direction="row" alignItems="center" justify="space-evenly">
                <Button type="submit" value="submit"
                  disabled={isDisabled}
                  // && upskillingELValues.length<0 && upskillingEmployementValues.length<0 &&
                  // upskillingCertificateValues.length<0 
                  className={classes.nextBtn}>
                  {activeStep === getSteps.length - 1 ? <Typography variant="h3">Finish</Typography> : <Typography variant="h3">Next</Typography>}
                </Button>
                <Button data-testId="handleReset"
                  onClick={this.props.handleReset} className={classes.cancelBtn}>
                  <Typography variant="h3">Cancel</Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </form>
      </Grid>
    )
  }
}
//@ts-ignore
const UpskillingWebWithRouter = withRouter(Upskilling);
const UpskillingWebWithLoader = withLoader(UpskillingWebWithRouter);
const UpskillingWebToast = withSnackbar(UpskillingWebWithLoader);
const UpskillingWebWithStyle = withStyles((theme) =>
  createStyles({
    custAccordion: {
      borderRadius: '4px',
      border: 'solid 1px #d3d3d3',
      width: '42rem'
    },
    heading: {
      boxShadow: '0 8px 12px 0 rgb(213 210 210 / 16%)',
      border: 'solid 1px #e8e2e2',
      borderRadius: '7px',
      height: '5rem'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    txtHead: {
      color: '#4f4f4f',
      paddingBottom: '.5rem'
    },
    txtHead1: {
      color: '#4f4f4f'
    },
    headTxt: {
      color: '#f48b1b',
      marginLeft: '1.5rem'
    },
    addTxt: {
      color: '#7764db',
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      width: '120px',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#000000'
      }
    },
    txtProps: {
      // width: '18.5rem',
      color: '#7764db',
      fontWeight: 600
    },
    helperTxt: {
      margin: '0px',
      textAlign: 'left',
      color: '#f9593a',
      fontWeight: 'bold',
      marginTop: '.5rem'
    },
    persInfo: {
      marginTop: '20px'
    },
    datePicker: {
      border: '1px solid #ffd0d0',
      height: '3rem',
      borderRadius: '4px',
      color: '#7764db',
      fontWeight: 600,
      paddingLeft: '10px',
      ' &:focus': {
        borderColor: '#ffd0d0'
      }
    },
    expansionIcon: {
      flexDirection: "row-reverse"
    },
    gridProp: {
      width: '100%'
    },
    selectDisColor: {
      color: 'grey'
    }
  })
)(UpskillingWebToast);
export default withTranslation()(UpskillingWebWithStyle);