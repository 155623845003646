import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  purchased: boolean;
  featuredCollegeList: any;
  popularCollegeList: any;
  topLocations: any;
  collegeListByLocation: any;
  cartCourses: any;
  totalAmount: any;
  selectedLocation: any;
  featuredCtaList: any;
  specializationsList: any;
  tabValue: any;
  degreeList: any;
  searchText: any;
  locationObj: any;
  colegesCtasList: any;
  tabdegreeList: any;
}
interface SS {
  id: any;
}

export default class CollegeController extends BlockComponent<
  Props,
  S,
  SS
> {
  featureCollegeApiCallId: string = "";
  PopularCollegesApiCallId: string = "";
  topLocationsApiCallId: string = "";
  collegeByLocationApiCallId: string = "";
  collegeCtasApiCallId: string = "";
  specializationsApiCallId: string = "";
  degreesApiCallId: string = "";
  collegesCTAApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      purchased: false,
      featuredCollegeList: [],
      popularCollegeList: [],
      topLocations: [],
      collegeListByLocation: [],
      cartCourses: [],
      totalAmount: 0,
      selectedLocation: 'Near You',
      featuredCtaList: [],
      specializationsList: [],
      tabValue: 0,
      degreeList: [],
      searchText: '',
      locationObj: {},
      colegesCtasList: {},
      tabdegreeList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.featureCollegeApiCallId:
            this.handleFeatureCollegeApiResponce(responseJson);
            break;
          case this.PopularCollegesApiCallId:
            this.handlePopularCollegeApiResponce(responseJson);
            break;
          case this.topLocationsApiCallId:
            this.handleTipCollegeApiResponce(responseJson);
            break;
          case this.collegeByLocationApiCallId:
            this.handleCollegeByLocationApiResponce(responseJson);
            break;
          case this.collegeCtasApiCallId:
            this.handleColegeCtasApiResponce(responseJson);
            break;
          case this.specializationsApiCallId:
            this.handleSpecializationApiResponce(responseJson);
            break;
          case this.degreesApiCallId:
            this.handleDegreeApiResponce(responseJson);
            break;
          case this.collegesCTAApiCallId:
            this.handlecollegeCTAApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handleFeatureCollegeApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ featuredCollegeList: responseJson.data });
    }
  };
  handlePopularCollegeApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ popularCollegeList: responseJson.data });
    }
  };
  handleTipCollegeApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ topLocations: responseJson?.data });
    }
  };
  handleCollegeByLocationApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ collegeListByLocation: responseJson?.data });
    }
  };
  handleColegeCtasApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ featuredCtaList: responseJson?.data });
    }
  };
  handleSpecializationApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ specializationsList: responseJson?.data });
    }
  };
  handleDegreeApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      // tabdegreeList
      let list: any = []
      if (responseJson?.data.length <= 5) {
        list = responseJson?.data
      }
      else {
        list = responseJson?.data.filter((ele: any, i: any) => {
          if (i <= 4) {
            return ele
          }
        })
      }
      this.setState({ degreeList: responseJson?.data, tabdegreeList: list }, () => {
        this.getSpecializations(responseJson?.data[0].id)
      });
    }
  };
  handlecollegeCTAApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ colegesCtasList: responseJson?.data });
    }
  };

  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)

  };
  getFeatureColleges = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.featureCollegeApiCallId = requestMessage.messageId;
    let quary = ''
    if (this.state.searchText) {
      quary += '&search=' + this.state.searchText
    }
    const apiEndPoint = `${configJSON.getPopularColleges}?page=1&per_page=10&featured=true${quary}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getPopularColleges = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.PopularCollegesApiCallId = requestMessage.messageId;
    let quary = ''
    if (this.state.searchText) {
      quary += '&search=' + this.state.searchText
    }
    const apiEndPoint = `${configJSON.getPopularColleges}?page=1&per_page=10&popular=true${quary}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getTopLocations = (location: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.topLocationsApiCallId = requestMessage.messageId;

    const apiEndPoint = `${configJSON.getToplocations}?page=1&per_page=10&current_page=true&top_locations=true`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  getCollegeByLocation = (location: any, obj: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.collegeByLocationApiCallId = requestMessage.messageId;
    let queary: any = ''
    if (location == 'Near You') {
      queary = 'distance_range=5'
    }
    else {
      queary = `location[]=${obj.id}`
    }
    if (this.state.searchText) {
      queary += '&search=' + this.state.searchText
    }
    const apiEndPoint = `${configJSON.getPopularColleges}?${queary}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  getCollegeFeaturedCtas = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.collegeCtasApiCallId = requestMessage.messageId;
    const apiEndPoint = `${configJSON.getPopularColleges}?with_cta=true`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  totalAmount = (list: any) => {
    let amount: any = 0
    if (list && list.length > 0) {
      amount = list.reduce(
        (prev: any, current: any) => prev + current?.attributes?.price,
        0)
    }
    return amount
  }
  setSelectLocationCollege = (location: any, obj: any) => {
    this.setState({ selectedLocation: location, locationObj: obj }, () => {
      this.getCollegeByLocation(location, obj)
    })
  }

  getSpecializations = (id: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.specializationsApiCallId = requestMessage.messageId;

    const apiEndPoint = `${configJSON.getSpecializations}?degree_id=${id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  numFormatter = (num: any) => {
    return resuableFun.numFormatter(num)


  }

  gotoWebsite = (data: any) => {
    if (data.attributes.website_url) {
      window.open(
        data.attributes.website_url, "_blank");
    }

  }
  handleTabChange = (event: any, newValue: any) => {
    this.setState({ tabValue: newValue })
    if (newValue == 5) {
      let list = this.state.degreeList.filter((ele: any, i: any) => {
        if (i >= 5) {
          return ele
        }
      })
      this.setState({ specializationsList: list })
    } else {
      this.getSpecializations(this.state.degreeList[newValue].id)
    }

  }

  getDegrees = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.degreesApiCallId = requestMessage.messageId;

    const apiEndPoint = `${configJSON.getDegrees}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  searchData = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      this.getFeatureColleges()
      this.getPopularColleges()
      this.getCollegeByLocation(this.state.selectedLocation, this.state.locationObj)
    })
  }

  getCollegeCtas = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.collegesCTAApiCallId = requestMessage.messageId;

    const apiEndPoint = `${configJSON.getSchoolsCollegeCtas}?module[]=college`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  navigateCta = (url: any) => {
    if (url) {
      window.open(
        url, "_blank");
    }
    else {
      this.props.openToastHandler("error", "Redirect URL is not available");

    }

  }

}
