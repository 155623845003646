//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Container,
  Typography, Tabs, Tab, Box, WithStyles, Theme
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { TabPanel, a11yProps, styles } from "./TabPanelComp.web";
import { CourseListCardComp } from "./CourseListCardComp.web";
const getComponentStyle = (theme: Theme): any => {
  return createStyles({
    addPadding: {
      padding: "0px",
      paddingLeft: "5rem"
    },
    noPadding: {
      padding: "0px",
    },
    indicator: {
      backgroundColor: '#f48b1b',
      height: '.35rem'
    },
    selectFilter: {
      backgroundColor: "#ffffff !important",
      color: '#7764db',
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "18rem"
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c",
      whiteSpace: 'nowrap'
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    root: {
      borderRadius: "18px",
      height: "100%"
    },
    courseHeading: {
      display: 'flex'
    },
    Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    }
  })
}
type Props =
  WithStyles<typeof getComponentStyle> & {
    id: string;
  };

export class MySubscription extends React.Component<Props>{
  render() {
    const { classes, mobileView, allRunningCourseList, allShortlistedCourseList } = this.props;
    return (
      <Container maxWidth="xl" data-testid="container" >
        <Grid container direction="row" justify="space-between" alignItems="center" className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
          <Grid item>
            <Box>
              <Tabs data-testid="tabs"
                value={this.props.match?.params?.name === "courses" ? 0 : 1}
                onChange={this.props.handleSubsTabChange}
                classes={{ indicator: classes.indicator }}
                aria-label="secondary tabs example">
                <Tab to="/dashboard/mysubscription/runningcourses" style={this.props.subsTabValue === 0 ? styles.tab : styles.inActiveTab}
                  label="Running Courses" {...a11yProps(0)} />
                <Tab to="/dashboard/mysubscription/shortlistcourses" style={this.props.subsTabValue === 1 ? styles.tab : styles.inActiveTab}
                  label="Shortlisted Courses" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="flex-start" style={{ marginTop: '1rem' }} className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
          <Grid item xs={12}>
            <TabPanel value={this.props.subsTabValue} data-testid="running-courses" index={0}>
              <Grid container spacing={2}>
                {allRunningCourseList && allRunningCourseList.length > 0 ? allRunningCourseList.map((_data: any, idx: any) => {
                  return (
                    <>
                      <CourseListCardComp {...{ classes, props: this.props, _data, bookmarkButton: false }} />
                    </>
                  );
                }) :
                  <Grid item xs={12} >
                    <Typography data-testid="no-running-subscriptions-grid-item" variant="body2" align="center">No Running Subscriptions Found</Typography>
                  </Grid>
                }
              </Grid>
            </TabPanel>
            <TabPanel value={this.props.subsTabValue} data-testid="shortlisted-courses" index={1}>
              <Grid container spacing={2}>
                {allShortlistedCourseList && allShortlistedCourseList.length > 0 ? allShortlistedCourseList.map((_data: any, idx: any) => {
                  return (
                    <>
                      <CourseListCardComp {...{ classes, props: this.props, _data, bookmarkButton: true }} />
                    </>
                  );
                }) :
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center">No Shortlisted Courses Found</Typography>
                  </Grid>
                }
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const MySubscriptionWithRouter = withRouter(MySubscription);
const MySubscriptionWithLoader = withLoader(MySubscriptionWithRouter);
const MySubscriptionToast = withSnackbar(MySubscriptionWithLoader);
export const MySubscriptionWithStyle = withStyles((theme) =>
  getComponentStyle(theme)
)(MySubscriptionToast);
export default withTranslation()(MySubscriptionWithStyle);
