//@ts-nocheck
//@ts-ignore

import React from 'react';
import {View,Text, StyleSheet} from 'react-native';
import { grey_location_color, lineHeight_14, semiBold, size_10pt, tag_yellow_color } from '../../Utils';
import { translate } from "../../../../../components/src/i18n/translate";

interface Props{
    onlyLocation?:boolean;
    locationName?:any;
    //rank:any;
    rating:any;
}

export default class LocationOnCard extends React.Component<Props> {
    constructor(props:Props){
        super(props);
    }

    render(){
        return(
           <View>
               {this.props.onlyLocation ?
                   <View style={styles.container}>
                       <View></View>
                     <Text numberOfLines={1} style={styles.cardTitlesingleLocation}>{this.props.locationName}</Text>
            </View>

                  : <View style={styles.container}>
                   <Text numberOfLines={1} style={styles.cardTitle}>{this.props.locationName}</Text>
                   <View style={styles.tags}>
                       <Text style={styles.score}>{this.props.rating?this.props.rating:0}{translate("five")}</Text>
                   </View>
                 {/* { 
                 this.props.rating?
                  <View style={styles.tags}>
                       <Text style={styles.score}>{this.props.rating}/5</Text>
                   </View>
                   : null
                   } */}
                   </View>
               }
           </View> 
        );
    }
}

const styles = StyleSheet.create({

    container:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginEnd:7
    },

    cardTitle:{
        marginStart:7,
        marginEnd:17,
        fontSize:size_10pt,
        lineHeight:lineHeight_14,
        color:grey_location_color,
        fontFamily:semiBold
    },

    cardTitlesingleLocation:{
        marginStart:7,
        marginEnd:5,
        fontSize:size_10pt,
        lineHeight:lineHeight_14,
        color:grey_location_color,
        fontFamily:semiBold
    },

    tags:{backgroundColor:tag_yellow_color, width:31, height:13, borderRadius:7,justifyContent:'center'},

    score:{fontSize:8,fontFamily:semiBold,color:'black',alignSelf:'center'}
})