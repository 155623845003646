Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint = "account/accounts/sms_confirmation?";
exports.apiEmailVerifyOtpEndPoint = "account_block/accounts/email_confirmations";
exports.accountsAPiEndPoint = "login/login?";
exports.httpPostType = "POST";
exports.apiContentType = "application/json";

exports.apiVerifyForgotPasswordOtpEndPoint = "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";
exports.btnTxtSubmitOtp = "Submit otp";
exports.placeHolderOtp = "OTP";
exports.successCreateAccount = "Account created successfully.";
exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";
exports.successAccountActivated = "Account activated successfully.";
exports.submitButtonColor = "#6200EE";

exports.resendOtpWebAPIEndPoint = "account/accounts/send_otp?";
exports.confirmOTPWebApiEndPoint = "bx_block_forgot_password/otp_confirmation";
