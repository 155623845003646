export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const jobHeaderImg = require("../assets/courses-all.png");
export const next = require("../assets/next.png");
export const prev = require("../assets/prev.png");
export const team = require("../assets/title.png");
export const teambase = require("../assets/titlePath.png")
export const mission1 = require("../assets/Image.png")
export const mission2 = require("../assets/Image2.png")
export const mission3 = require("../assets/Image 3.png")
export const josh = require("../assets/josh.png")
export const daily = require("../assets/dailyhunt.png")
export const brown = require("../assets/img1.png")
export const tresure = require("../assets/img2.png")
export const certificate = require("../assets/img3.png")
export const cylinder = require("../assets/cylinder.png")
export const helix = require("../assets/Helix.png")
export const rectangle = require("../assets/Rectangle.png")
export const female = require('../assets/female2.png')
export const water = require('../assets/water.png')
export const female2 = require('../assets/female.png')
export const male = require('../assets/male.png')
export const helix2 = require("../assets/Helix.png")
export const helix3 = require("../assets/Helix.png")
