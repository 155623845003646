import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  companyDetails: any[];
  jobCategories: any[];
  selectedJobType: any[];
  selectedJobExperience: any[];
  jobTypeAndExperience: any;
  selectedFilterValue: any;
  locationsArr: any[];
  selectedCompanyName: any;
  selectedCategory: number;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class JobsFilterController extends BlockComponent<Props, S, SS> {
  getJobTypeAndExperienceApiCallId: string = "";
  getLocationApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      companyDetails: [],
      jobCategories: [],
      selectedJobType: [],
      selectedJobExperience: [],
      jobTypeAndExperience: {},
      locationsArr: [],
      selectedFilterValue: { location: [], jobType: [], experience: [], salary: [], datePosted: [] },
      selectedCompanyName: 0,
      selectedCategory: 0,
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {

        if (apiRequestCallId === this.getJobTypeAndExperienceApiCallId) {
          this.setState({ jobTypeAndExperience: responseJson });
        } else if (apiRequestCallId === this.getLocationApiCallId) {
          this.setState({ locationsArr: responseJson?.data?.job_locations?.data });
        }
      }
      else {
        if (responseJson && responseJson.errors && responseJson.errors[0].token)
          this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getJobTypeAndExperience = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getJobTypeAndExperienceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobTypeAndExperienceApiEndPoint}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getLocation = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getLocationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLocationEndPoint}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
