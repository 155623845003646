
//@ts-nocheck
import React from "react";
import {
  withStyles, IconButton, Menu, MenuItem, ListSubheader, Box, Chip, Card, CardContent,
  Container, Grid, Typography, Button, TextField, Dialog, Divider, List, ListItemText, ListItem, ListItemSecondaryAction,
  DialogContent, createStyles
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//@ts-ignore
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ViewAllController, { Props } from "./ViewAllController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { userImage } from "./assets";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import { DiscussionforumSchema, DiscussionforumReportSchema } from "./Discussionforum.web.validation";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);
const ReportMenuItem = withStyles({
  root: { backgroundColor: '#ffffff', color: '#000000 !important' },
  MuiMenuItem: {
    root: { backgroundColor: '#ffffff', color: '#000000 !important', }
  },
})(MenuItem);
export class ViewAll extends ViewAllController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { searchInfo } = this.props;
    this.setState({ heading: this.props?.location?.state?.heading });
    window.scrollTo(0, 0);
    this.getTagList();
    this.getCategories();
    this.getInitialQuestions(searchInfo);
    this.getLikes();
    TimeAgo.addDefaultLocale(en)
  }
  async componentWillReceiveProps(nextProps: any) {
    if (nextProps.searchInfo !== this.props.searchInfo) {
      this.getInitialQuestions(nextProps.searchInfo);
    }
  }
  renderSelectGroup = (t) => {
    const items = t?.attributes?.sub_categories.map(p => {
      return (
        <MyMenuItem key={p.id} value={p.id}>{p?.name}</MyMenuItem>
      );
    });
    return [<ListSubheader>{t.name}</ListSubheader>, items];
  };
  addTag = () => {
    this.setState(prevState => ({ showTagSearch: !prevState.showTagSearch }));
  }
  saveAsDraft = (values: any, draft: any) => {
    this.setState({ "question": values }, () => this.postQuestion(draft))
  }
  openMenu = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget, problemBlock: false });
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null, openReportDialog: true });
  };
  discussionFormUi = (handleSubmit, ...args) => {
    const [handleChange, handleBlur, classes, touched, errors,
      values, categories, tagsList, showTagSearch, setFieldValue, isSubmitting, isValid] = args;
    return (<Form autoComplete="off" onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item xs={12}>
            <>
              <PinkTextField
                variant="outlined"
                name="categories"
                label="Choose Categories"
                defaultValue="none"
                onChange={handleChange}
                placeholder="choose categories"
                SelectProps={{
                  MenuProps: { classes: { paper: classes.selectBoard } }
                }}
                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                onBlur={handleBlur}
                error={touched.categories && errors.categories ? true : false}
                InputProps={{ classes: { input: classes.txtProps } }}
                InputLabelProps={{ style: { color: '#a9a9a9', fontSize: '14px' } }}
                helperText={
                  touched.categories && errors.categories ? errors.categories : ""
                }
                value={values.categories}
                select
              >
                <MyMenuItem disabled value="none">
                  <Typography variant="subtitle2" className={classes.selectDisColor}>
                    Choose Categories*
                  </Typography>
                </MyMenuItem>
                {categories && categories.map(p => this.renderSelectGroup(p))}
              </PinkTextField>
            </>
          </Grid>
          <>
            <Grid item xs={12}>
              <PinkTextField
                variant="outlined"
                name="title"
                defaultValue="none"
                onChange={handleChange}
                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                onBlur={handleBlur}
                error={touched.title && errors.title ? true : false}
                InputProps={{ classes: { input: classes.txtProps } }}
                helperText={
                  touched.title && errors.title ? errors.title : ""
                }
                value={values.title}
                placeholder="Enter Title"
              />
            </Grid>
          </>
          <Grid item xs={12}>
            <PinkTextField
              variant="outlined"
              name="description"
              multiline
              rows={10}
              onChange={handleChange}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              error={touched.description && errors.description ? true : false}
              InputProps={{ classes: { input: classes.txtProps } }}
              helperText={
                touched.description && errors.description ? errors.description : ""
              }
              value={values.description}
              placeholder="Enter Description"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="text" data-testId="addTag" onClick={this.addTag}><Typography variant="body2" className={classes.addtagTxt}>Add tag +</Typography></Button>
            {showTagSearch &&
              <Box sx={{ width: 500 }}>
                <>
                  <Autocomplete
                    data-testId="tagSearchAutocomplete"
                    multiple
                    freeSolo
                    name="tagVal"
                    id="tagVal"
                    options={tagsList}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {
                      setFieldValue("tagVals", [...new Set([].concat(values.tagVals, value))]);
                      setFieldValue("tagVal", []);
                    }}
                    value={values?.tagVal.map(e => e)}
                    renderTags={() => null}
                    renderInput={params => {
                      return (<PinkTextField fullWidth {...params} name="tagVal" variant="outlined" placeholder="Search Tags" />)
                    }}
                  />
                </>
                <Box
                  mt={3}
                  sx={{
                    '& > :not(:last-child)': { mr: 1 },
                    '& > *': { mr: 1 },
                  }}>
                  {values?.tagVals && values?.tagVals.map((v, i) => (
                    <Chip className={classes.chip} key={i} label={v} onDelete={() => {
                      setFieldValue("tagVals", values.tagVals.filter(x => x !== v));
                    }} />
                  ))}
                </Box>
              </Box>}
          </Grid>
          <Grid item xs={12}>
            {this.buttonUiLogic(values, isSubmitting, isValid, classes)}
          </Grid>
        </Grid>
      </DialogContent>
    </Form>
    )
  }
  buttonUiLogic = (values, isSubmitting, isValid, classes) => {
    return (
      <Grid container direction="row" justify="flex-end">
        <Grid item xs={3}>
          {!localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.cancelBtn} >Save as Draft </Button>}
        </Grid>
        <Grid item xs={3}>
          {!localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.publishBtn} >Publish</Button>}
        </Grid>
        <Grid item xs={3}>
          {localStorage.getItem("token") && <Button variant="contained" name="draft" disabled={values.title == "" || values.categories === "" || values.description.length < 200 || values.description === ""} data-testId="saveAsDraft" onClick={() => this.saveAsDraft(values, "draft")} className={classes.cancelBtn} >Save as Draft </Button>}
        </Grid>
        <Grid item xs={3}>
          {localStorage.getItem("token") && <Button variant="contained" name="publish" disabled={!isValid} type="submit" className={classes.publishBtn} >Publish</Button>}
        </Grid>
      </Grid>)
  }
  rightContainerUi = (classes: any) => {
    return (<Grid item xs={12}>
      <Card className={classes.rightContainer}>
        <CardContent>
          <>
            <Grid container>
              <StarBorderIcon color="light" className={classes.addIcon} />
              <Typography variant='body2'>Must-read Posts</Typography>
            </Grid>
          </>
          <br />
          <>
            <Divider />
          </>
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid item style={{ display: "inline-flex" }}>
              <FiberManualRecordIcon color="light" className={classes.dotIcon} />
              <Typography variant="body1" className={classes.rightBlueTxt}> Please read rules before you start working</Typography>
            </Grid>
            <br />
            <>
              <Grid item style={{ display: "inline-flex" }}>
                <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                <Typography variant="body1" className={classes.rightBlueTxt}> Vision & Strategy of Alemhelp</Typography>
              </Grid>
              <br />
            </>
          </Grid>
          <br />
          <Grid container>
            <InsertLinkIcon color="light" className={classes.addIcon} />
            <Typography variant='body2'>Featured Links</Typography>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid container direction="row">
            <Grid item style={{ display: "inline-flex" }}>
              <FiberManualRecordIcon color="light" className={classes.dotIcon} />
              <Typography variant="body1" className={classes.rightBlueTxt}> Alemhelp source-code on GitHub</Typography>
            </Grid>
            <br />
            <>
              <Grid item style={{ display: "inline-flex" }}>
                <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                <Typography variant="body1" className={classes.rightBlueTxt}>Golang best-practices</Typography>
              </Grid>
            </>
            <>
              <br />
              <Grid item style={{ display: "inline-flex" }}>
                <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                <Typography variant="body1" className={classes.rightBlueTxt}>Alem.School dashboard</Typography>
              </Grid>
            </>
          </Grid>
        </CardContent>
      </Card>
    </Grid>)
  }
  communicationIcon = (e, classes, likeList) => {
    return (
      <Grid container direction="row" >
        <Grid item xs={2}>
          <Grid container direction="row" alignItems="center">
            <RemoveRedEyeIcon color="light" className={classes.dfIcon} />
            {e && e.attributes && e.attributes.view && <Typography variant="body1">{e.attributes.view}</Typography>}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <>
            <Grid container direction="row" alignItems="center">
              <ChatBubbleIcon data-testId="ChatBubbleIcon" color="light" className={classes.dfIcon}
                onClick={() => this.props.history.push("/dashboard/detailview", { postDetails: e })} />
              {e && e.attributes && e.attributes.comments_count && <Typography variant="body1">{e.attributes.comments_count}</Typography>}
            </Grid>
          </>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="row" alignItems="center">
            {this.likeUiBlock(likeList, e, classes)}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  likeUiBlock = (likeList, e, classes) => {
    return (
      localStorage.getItem('token') &&
        likeList.findIndex((et) => { return et?.attributes?.likeable_id === e?.attributes.id }) > -1
        && e?.attributes?.is_like === true
        ? <>
          <ThumbUpIcon color="light" key={e?.id} onClick={() => this.handleDisLike(e?.attributes?.id, false)} className={classes.dfIcon} />
          {e && e.attributes && e.attributes.likes_count && <Typography variant="body1">{e.attributes.likes_count}</Typography>}
        </>
        :
        <>
          <ThumbUpAltOutlinedIcon color="light" key={e.id} data-testId="handleLike" onClick={() => this.handleLike(e?.attributes?.id, true)} className={classes.dfIcon} />
          {e && e.attributes && e.attributes.likes_count && <Typography variant="body1">{e.attributes.likes_count}</Typography>}
        </>
    )
  }
  tagsUi = (e, classes) => {
    return (
      e?.attributes?.tags && e?.attributes?.tags.length > 0 && <> <br />
        <Grid container direction="row" justify="flex-start">
          {e?.attributes?.tags && e?.attributes?.tags.map((el, index) =>
            <Button key={index} variant="contained" className={classes.tagChips}>{el && el.name && <Typography variant="body1">
              {el.name}</Typography>}</Button>)}
        </Grid></>
    )
  }
  discussionCard = (getQuestions, classes, anchorEl, likeList) => {
    return (getQuestions && getQuestions.map((e, index) => (
      <>
        <Card className={classes.rootCard}>
          <CardContent>
            <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
              <Grid item xs={10}>
                <Grid container direction="row" alignItems="center" justify="flex-start">
                  <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                    <img src={e?.attributes?.user_image ? e?.attributes?.user_image : userImage} className={classes.userImg} />
                  </Grid>
                  <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                    <Grid container direction="column" >
                      {e && e.attributes && e.attributes.account && e.attributes.account.first_name && <Typography variant="caption">{e.attributes.account.first_name}</Typography>}
                      <Typography variant="body1">{e && e.attributes && e.attributes.created_at && <ReactTimeAgo date={e.attributes.created_at} locale="en-US" />}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container direction="row" alignItems="center" justify="flex-end">
                  <MoreVertIcon color="light" data-testId="openMenu" onClick={(e) => this.openMenu(e)} className={classes.addIcon} />
                  <Menu
                    classes={{ paper: classes.reportMenu }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    <ReportMenuItem className={classes.menuColor} data-testId="handleMenuClose" onClick={this.handleMenuClose}>Report</ReportMenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid container direction="row">
              {e && e.attributes && e.attributes.title && <Typography variant="h3">{e.attributes.title}</Typography>}
              <Grid item>
              </Grid>
            </Grid>
            <br />
            {this.discussionUi(e, classes, index)}
            {this.tagsUi(e, classes)}
            <br />
            <Grid container direction="row" justify="space-between">
              <Grid item xs={8}>
                {this.communicationIcon(e, classes, likeList)}
              </Grid>
              <Grid item xs={2}>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
      </>)))
  }
  discussionUi = (e, classes, index) => {
    return (
      <Grid container direction="row">
        {e?.attributes?.description && e?.attributes?.description.length > 300 ? <Typography variant="subtitle2" data-testId={`${index}_detailview`}
          onClick={() => this.props.history.push("/dashboard/detailview", { postDetails: e })}
          className={classes.desc}>{e?.attributes?.description}...</Typography> :
          <Typography data-testId={`${index}_detailview2`} variant="subtitle2" onClick={() => this.props.history.push("/dashboard/detailview", { postDetails: e })}
            className={classes.desc}>{e?.attributes?.description}</Typography>
        }
      </Grid>
    )
  }
  probBlock1 = (problemType, classes) => {
    return (
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginBottom: '.5rem' }}  >
            Please select a problem
          </Typography>
          <Typography variant="subtitle2" >
            If someone is in immediate danger, get help before reporting to Us. Don't wait.
          </Typography>
          <>
            <List dense={false}>
              {problemType.map((prob: any) =>
                <ListItem key={prob} className={classes.problemItem}>
                  <ListItemText
                    data-testId="selectProblemType1"
                    onClick={() => this.selectProblemType(prob)}
                    secondary={prob}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" data-testId="selectProblemType2" onClick={() => this.selectProblemType(prob)}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
          </>
        </Grid>
      </Grid>
    )
  }
  probBlock2 = (classes, ...args) => {
    const [values, touched, errors, handleBlur, handleChange, isSubmitting, isValid, setFieldValue] = args;
    return (
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography variant="body2" >
            Shared Details {values.categories}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PinkTextField
            variant="outlined"
            name="categories"
            defaultValue="none"
            onChange={handleChange}
            FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
            onBlur={handleBlur}
            error={touched.categories && errors.categories ? true : false}
            InputProps={{ classes: { input: classes.txtProps }, readOnly: true, }}
            helperText={
              touched.categories && errors.categories ? errors.categories : ""
            }
            value={values.categories}
            placeholder="Enter type of report"
          />
        </Grid>
        <Grid item xs={12}>
          <PinkTextField
            variant="outlined"
            name="title"
            defaultValue="none"
            onChange={handleChange}
            FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
            onBlur={handleBlur}
            error={touched.title && errors.title ? true : false}
            InputProps={{ classes: { input: classes.txtProps } }}
            helperText={
              touched.title && errors.title ? errors.title : ""
            }
            value={values.title}
            placeholder="Enter Title"
          />
        </Grid>
        <>
          <Grid item xs={12}>
            <PinkTextField
              variant="outlined"
              name="description"
              multiline
              rows={10}
              onChange={handleChange}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              InputProps={{ classes: { input: classes.txtProps } }}
              helperText={
                touched.description && errors.description ? errors.description : ""
              }
              value={values.description}
              placeholder="Enter Description"
            />
          </Grid>
        </>
        <Grid item xs={12}>
          <input
            style={{ display: 'none' }}
            accept="image/*"
            id={`preview-1`}
            type="file"
            onChange={(e, value) => { setFieldValue("tagVal", [...new Set(values.tagVal.concat(e.target.files))]); }}
          />
          <Button variant="text" onClick={(event) => this.openFile(event)}>
            <Typography variant="body2" className={classes.addtagTxt}   >+ Add Screen Short
            </Typography></Button>
          {values.tagVal.length > 0 &&
            <Box sx={{ width: 500 }}>
              <Box
                mt={3}
                sx={{
                  '& > :not(:last-child)': { mr: 1 },
                  '& > *': { mr: 1 },
                }}>
                {values?.tagVal && values?.tagVal.map((v, i) => (
                  <Chip className={classes.chip} key={i} label={v[0].name} onDelete={() => {
                    setFieldValue("tagVal", values.tagVal.filter(x => x[0].name !== v[0].name));
                  }} />
                ))}
              </Box>
            </Box>}
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="flex-end">
            <Grid item xs={3}>
              {<Button variant="contained" type="submit"
                onClick={() => this.setState({ openReportDialog: false, problemBlock: false, selectedProblem: '' })}
                className={classes.cancelBtn} >Cancel </Button>}
            </Grid>
            <Grid item xs={3}>
              {<Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.publishBtn} >Report Now</Button>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  render() {
    const { classes } = this.props;
    const { anchorEl, getQuestions, likeList, heading, hasMore, categories, tagsList, showTagSearch, problemBlock, problemType } = this.state;
    return (
      <Container maxWidth="xl" style={{ paddingLeft: '4rem', paddingRight: '4rem' }} >
        <Grid item xs={12} container direction="row" justify="space-between">
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Grid item xs={12} className={classes.newThread}>
                <Grid container direction="row" alignItems="center" justify="space-between" style={{ height: '3rem' }} >
                  <Grid xs={9} style={{ marginLeft: '.5rem' }}>
                    <Typography variant="caption">
                      Add a new Thread
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <IconButton aria-label="createPost" className={classes.iconBtn}
                      data-testId="openPostDialog"
                      onClick={() => {
                        if (localStorage.getItem("token")) {
                          this.openPostDialog();
                        } else { this.openDialog(true) }
                      }}>
                      <AddCircleIcon color="secondary" className={classes.addIcon} />
                    </IconButton>
                    <Dialog open={this.state.openDiscussionDialog} data-testId="handleClose" onClose={this.handleClose}
                      PaperProps={{
                        style: {
                          minHeight: "500px",
                          width: "720px",
                          backgroundColor: "#ffffff",
                          boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
                        },
                      }}>
                      <Formik
                        data-testId="createPost"
                        initialValues={{
                          title: "",
                          categories: "",
                          description: "",
                          tagVal: [],
                          tagVals: [],
                        }}
                        validationSchema={DiscussionforumSchema}
                        onSubmit={(values: any) => {
                          this.createPost(values);
                        }}
                      >
                        {(formikProps) => {
                          const {
                            values,
                            isValid,
                            errors,
                            touched,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                            isSubmitting,
                          } = formikProps;
                          return (
                            this.discussionFormUi(handleSubmit, handleChange, handleBlur, classes, touched, errors,
                              values, categories, tagsList, showTagSearch, setFieldValue, isSubmitting, isValid)
                          );
                        }}
                      </Formik>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid container item direction="row" alignItems="center" justifyContent="space-between">
              <Typography align="center" variant="h4" className={classes.txtColor}>{heading}</Typography>
            </Grid>
            <br /> <br />
            <Grid container direction="row" >
              <Grid item xs={12}>
                <InfiniteScroll
                  style={{ overflow: 'hidden !important' }}
                  dataLength={getQuestions.length}
                  next={this.getInfiniteQuestions}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {getQuestions.length > 0 && <b>You have seen it all</b>}
                    </p>
                  }>
                  {this.discussionCard(getQuestions, classes, anchorEl, likeList)}
                </InfiniteScroll>
              </Grid>
            </Grid>
            <Dialog open={this.state.openReportDialog} onClose={this.handleClose}
              PaperProps={{
                style: {
                  minHeight: "500px",
                  width: "720px",
                  backgroundColor: "#ffffff",
                  boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
                },
              }}>
              <Formik
                data-testId="createReport"
                enableReinitialize={true}
                initialValues={{
                  title: "",
                  categories: this.state.selectedProblem,
                  description: "",
                  tagVal: [],
                  tagVals: [],
                }}
                validationSchema={DiscussionforumReportSchema}
                onSubmit={(values: any) => {
                  this.createReport(values);
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    isValid,
                    errors,
                    touched,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                  } = formikProps;
                  return (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <Container maxWidth='xl'>
                        <Grid container direction="row" className={classes.reportHead} justify="space-between" alignItems="center">
                          <Grid item></Grid>
                          <Grid item>
                            <Typography variant="h3" >
                              Report
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton aria-label="close" className={classes.closeButton} data-testId="closeBtn" onClick={() => this.setState({ openReportDialog: false, problemBlock: false, selectedProblem: '' })}>
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Container>
                      <DialogContent>
                        {!problemBlock && this.probBlock1(problemType, classes)}
                        {problemBlock && this.probBlock2(classes, values, touched, errors, handleBlur, handleChange, isSubmitting, isValid, setFieldValue)}
                      </DialogContent>
                    </Form>);
                }}
              </Formik>
            </Dialog>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {this.rightContainerUi(classes)}
          </Grid>

          {!localStorage.getItem("token") && <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />}
        </Grid>
      </Container >
    );
  }
}
// @ts-ignore
const ViewAllWithRouter = withRouter(ViewAll);
const ViewAllWithLoader = withLoader(ViewAllWithRouter);
const ViewAllToast = withSnackbar(ViewAllWithLoader);
const ViewAllWithStyle = withStyles((theme) =>
  createStyles({
    reportMenu: {
      backgroundColor: 'white !important',
      borderRadius: '4px !important',
      boxShadow: '0px 1px 3px 0 rgba(0, 0, 0, 0.15) !important',
    },
    rootCard: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#fffefe'
    },
    selectDisColor: { fontSize: '10px' },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#ffffff'
      }
    },
    addtagTxt: {
      color: '#7764db'
    },
    iconBtn: {
      backgroundColor: '#7764db',
      borderRadius: '10px',
      height: '2rem',
      width: '2rem',
      "&:hover, &.Mui-focusVisible": { backgroundColor: "#7764db" }
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    newThread: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#fffefe'
    },
    userImg: {
      height: '2rem',
      width: '2.5rem',
      borderRadius: '50%'
    },
    desc: {
      wordBreak: 'break-all',
      cursor: 'pointer'
    },
    dotIcon: {
      color: '#1682fd',
      height: '1rem'
    },
    rightBlueTxt: {
      color: '#1682fd'
    },
    rightContainer: {
      padding: '1rem',
      marginTop: '3rem',
      borderRadius: '4.5px',
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #eaeaea',
      backgroundColor: '#fffefe'
    },
    publishBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    reportColor: { color: 'red' },
    txtColor: {
      color: ' #f48b1b'
    },
    addIcon: {
      height: '1.2rem'
    },
    dfIcon: {
      height: '1.2rem',
      fill: '#7764db'
    },
    reportHead: {
      borderBottom: '1px solid #e1e1e1'
    },
    problemItem: {
      paddingLeft: '0px',
    }
  })
)((ViewAllToast))
export default withTranslation()(ViewAllWithStyle);