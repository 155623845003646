import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End

export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/Utility";


export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  categories: any;
  homeDesc: string;
  currentPage: number;
  quizData: any;
  userQuizData: any;
  quizResultData: any;
  show: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class QuizControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuizDataApiCallId: string = "";
  getuserQuizDataApiCallId: string = "";
  saveQuizApiCallId: string = "";
  startQuizApiCallId: string = "";
  getQuizResultApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      categories: [],
      homeDesc: "",
      currentPage: 0,
      quizData: [],
      userQuizData: [],
      quizResultData: [],
      show: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getQuizDataApiCallId:
            this.handleQuizDataApiCallIdApiResponce(responseJson);
            break;
          case this.getuserQuizDataApiCallId:
            this.handleuserQuizDataApiCallIdApiResponce(responseJson);
            break;
          case this.saveQuizApiCallId:
            this.handlesaveQuizApiCallIdApiResponce(responseJson);
            break;
          case this.startQuizApiCallId:
            this.handlestartQuizApiCallIdApiResponce(responseJson);
            break;
          case this.getQuizResultApiCallId:
            this.handleQuizResultApiCallIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handleQuizDataApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ quizData: responseJson.data });
    } else if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `. please login again`!);
    }
  }
  handleuserQuizDataApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ userQuizData: responseJson.data });
    }
  }
  handlesaveQuizApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.props.openToastHandler("success", 'Your response has been submitted');
    } else if (responseJson?.errors || responseJson?.error) {
      if (responseJson?.error) {
        this.props.openToastHandler("error", responseJson?.error);
      }
    } else {
      this.props.openToastHandler("error", `Something went Wrong!`);
    }
  }
  handlestartQuizApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      console.log(responseJson?.data)
    } else if (responseJson?.errors || responseJson?.error) {
      if (responseJson?.error) {
        this.props.openToastHandler("error", responseJson?.error);
      }
    } else {
      this.props.openToastHandler("error", `Something went Wrong!`);
    }
  }
  handleQuizResultApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ quizResultData: responseJson.data });
    }
  }
  clearLocalstorage = (responseJson: any) => {
    if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `. please login again`!);
    }
  }

  getQuizData = () => {
    const quizId = this.props?.match?.params?.quizId;
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getQuizDataApiCallId = sendAPIRequest(`${configJSON.getQuizUrl}/${quizId}`,
      { headers: header })
  }
  getUserQuizData = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getuserQuizDataApiCallId = sendAPIRequest(`${configJSON.userQuizUrl}`,
      { headers: header })
  }
  saveQuiz = (data: any) => {
    const { questionId, optionId } = data;
    const quizId = this.props?.match?.params?.quizId;
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType

    };
    if (token) {
      headers['token'] = token
    }
    const payload = {
      quiz_id: quizId,
      data: {
        test_question_id: questionId,
        option_id: optionId
      }
    }
    const apiEndPoint = configJSON.saveQuizUrl;
    const method = configJSON.httpPostType;
    this.saveQuizApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...payload },
        headers: headers
      },
    );
  }
  postStartQuiz = () => {
    const quizId = this.props?.match?.params?.quizId;
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const payload = {
      quiz_id: quizId,
    }
    const apiEndPoint = configJSON.userQuizUrl;
    const method = configJSON.httpPostType;
    this.startQuizApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...payload },
        headers: headers
      },
    );
  }
  getQuizResult = () => {
    const quizId = this.props?.match?.params?.quizId;
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getQuizResultApiCallId = sendAPIRequest(`${configJSON.userQuizUrl}/my_score?quiz_id=${quizId}`,
      { headers: header })
  }
  // Customizable Area End
}
