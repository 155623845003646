//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Button,
  Divider,
  createStyles,
  Container,
  Typography,
  Card,
  CardContent
} from "@material-ui/core";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import JobsDetailsViewAllController, { Props, isEmpty } from './JobsDetailsViewAllController.web';
//@ts-ignore
import { withRouter } from "react-router";
import {  withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import JobsFilter from './jobsFilter.web';

const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;

const RenderLocation = ({ classes, positions = [], locations: citys = [] }) => {
  const locations = [];
  if (!isEmpty(citys)) {
    citys?.forEach(x => {
      if (x?.attributes?.city) {
        locations?.push(x?.attributes?.city)
      }
    });
  } else {
    positions?.forEach(({ attributes }) => {
      attributes?.locations?.forEach(x => {
        if (x?.attributes?.city) {
          locations?.push(x?.attributes?.city)
        }
      });
    });
  }
  return (<Grid item>
    <Typography variant="body2" className={classes.location}>
      {locations?.filter(onlyUnique).join(', ')}
    </Typography>
  </Grid>)
};
const sortdata = (list: any) => {
  list = list.sort((a, b) => a.id - b.id)
  return list
}

const RenderSimilarLocation = ({ locations }) => {
  const location = [];
  locations?.forEach(x => {
    if (x?.attributes?.city) { location.push(x?.attributes?.city) }
  });
  return (<Grid item>
    <Typography variant="caption">
      {location?.filter(onlyUnique).join(', ')}
    </Typography>
  </Grid>)
}
const RenderTag = ({ tags = [], classes }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="flex-start">
      {tags && tags.length > 0 && sortdata(tags).map((e, index) => {
        if (index < 3) {
          return (
            <Grid style={{ marginRight: '0.5rem' }} item>
              <div className={classes.tagBtn} key={index + 1} >
                <Typography variant="body2"> {e.name}</Typography>
              </div>
            </Grid>)
        }
      }
      )}
    </Grid>
  );
};

const RenderTagList = ({ tags = [], classes }) => {
  return (
    <Grid Grid container item direction="row" alignItems="center" justify="space-between" >
      {tags && tags.length > 0 && sortdata(tags).map((e, index) => {
        if (index < 3) {
          return (
            <Grid item>
              <div className={classes.tagBtn} key={index + 1} >
                <Typography variant="body2"> {e}</Typography>
              </div>
            </Grid>)
        }
      }
      )
      }<br />
    </Grid >
  );
};
const RenderSimilarJob = ({ classes, jobs, gotoSimilarJobDetails, openDialog }) => {
  return (<>
    {jobs?.attributes?.companies && jobs?.attributes?.companies.map((c, index) => {
      if (index < 5) {
        return (
          <>
            <Grid container direction="row" alignItems="" justify="space-between" item
              className={classes.pointer}
              data-testId="gotoSimilarJobDetails"
              onClick={evt => {
                if (localStorage.getItem('token')) {
                  evt.stopPropagation();
                  gotoSimilarJobDetails(c?.id,)
                }
                else { openDialog(true) }
              }}>
              <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                <div className={classes.similarLogoPaper}>
                  <img src={c?.logo?.url} className={classes.similarCompanyLogo} alt="job-Image" />
                </div>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography style={{ color: 'black' }} variant="h3">{jobs?.attributes?.name}</Typography></Grid>
                  <Grid item> <Typography className={classes.orangeText} variant="h3">{c?.name}</Typography>
                  </Grid>
                  <RenderTagList data-testId="RenderTagList" {...{ tags: c?.tag_list || [], classes }} />
                  {jobs?.attributes?.locations && jobs?.attributes?.locations.length > 0 && <Grid container direction="row" alignItems='center'>
                    <Grid item>
                      <LocationOnOutlinedIcon className={classes.smalllocationIcon} />
                    </Grid>
                    <RenderSimilarLocation data-testId="RenderSimilarLocation" {...{ locations: jobs?.attributes?.locations }} />
                  </Grid>}
                  <br />
                </Grid>
              </Grid>
            </Grid>
            <br />  <Divider style={{ width: '100%', color: '#e6d3d3' }} /><br />
          </>
        )
      }
    }
    )}
  </>)
}
//@ts-ignore
export class JobsDetailsViewAllWeb extends JobsDetailsViewAllController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.showJobCategories();
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  filterValue = (value: any) => {
    this.setState({ filterData: value }, () => this.showJobCategories());
  };
  gotoJobDetails = (jobId: any) => {
    const companyId = this.props?.match?.params?.companyId;
    const { history } = this.props;
    history.push(`/home/jobs/all-jobs/company/${companyId}/job/${jobId}`);
  };
  gotoSimilarJobDetails = (companyId: any, jobId: any) => {
    const { history } = this.props;
    history.push(`/home/jobs/all-jobs/company/${companyId}/job/${jobId}`);
  };
  jobDetailAppliedButton = (e, classes) => {
    return (e?.attributes?.applied_job ?
      <Grid container justify="center" alignItems="center" className={classes.appliedBtn}>
        <Typography variant="body2">
          Applied
        </Typography>
      </Grid>
      : <Button
        data-testId="jobDetailBtn"
        onClick={evt => {
          if (localStorage.getItem('token')) {
            evt.stopPropagation();
            this.gotoJobDetails(e?.attributes?.id)
          }
          else { this.openDialog(true) }
        }}
        variant="contained"
        type="button"
        className={classes.viewBtn}>
        Job Details
      </Button>)
  }
  jobDetailCard = (attributes, classes) => {
    return (<Grid container direction="row" alignItems="center" spacing={1}>
      {attributes.positions && attributes?.positions?.length > 0 ? attributes.positions.map((e, i) => {
        return (
          <Grid item xs={12}>
            <Card className={classes.jobcontent}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Typography data-testId="gotoJobDetails2" onClick={() => this.gotoJobDetails(e?.attributes?.id)} variant="h3">{e?.attributes?.name}</Typography>
                    {this.jobDetailAppliedButton(e, classes)}
                  </Grid>
                  <Grid item data-testId="gotoJobDetails1" onClick={() => this.gotoJobDetails(e?.attributes?.id)} container direction="row">
                    <Grid item>
                      <Grid container direction="row" alignItems='center'>
                        <Grid item>
                          <BusinessCenterOutlinedIcon className={classes.locationIcon} />
                        </Grid>
                        <Grid item>
                          <Typography style={{ marginLeft: '0.2rem' }} variant="subtitle2" className={classes.orangeText}>{e?.attributes?.experience}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" >{e?.attributes?.job_type}</Typography>
                  </Grid>
                  {e?.attributes?.min_salary && e?.attributes?.max_salary &&
                    <Grid item container direction="row" spacing={1}>
                      <Grid item>
                        <AccountBalanceWalletIcon />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">{e?.attributes?.min_salary}{"-"}{e?.attributes?.max_salary}</Typography>
                      </Grid>
                    </Grid>}
                  <br />
                  <Grid container item data-testId="gotoJobDetails3" onClick={() => this.gotoJobDetails(e?.attributes?.id)} direction="row">
                    <Grid item>
                      <Typography variant="subtitle2">{e?.attributes?.description}</Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container item data-testId="gotoJobDetails4" onClick={() => this.gotoJobDetails(e?.attributes?.id)} direction="row" alignItems="center" justify="space-between">
                    <Grid item>
                      <Grid container direction="row" alignItems='center'>
                        {e?.attributes?.locations.length > 0 && <Grid item>
                          <LocationOnOutlinedIcon className={classes.locationIcon} />
                        </Grid>}
                        <RenderLocation data-testId="RenderLocation" {...{ locations: e?.attributes?.locations, classes }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>)
      }) : <Grid item xs={12}><Typography variant="body2">No Jobs Available</Typography></Grid>}
    </Grid>)
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { companyDetails, similarJobs } = this.state;
    const { attributes = {} } = companyDetails;
    const { gotoSimilarJobDetails, openDialog } = this;
    return (
      <>
        <Grid container direction="row" alignItems="center" className={classes.overlay}>
          <Grid item xs={12} className={classes.imgTag}>
            <img src={attributes?.logo} className={classes.jobHeaderImg} alt="job-Image" />
          </Grid>
          <Grid container direction="row" alignItems="flex-start" spacing={4} item xs={12} >
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              {/* dummy space */}
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid container direction="row" alignItems="center" item xs={12} spacing={2}>
                <Grid item>
                  <div className={classes.logoPaper}>
                    <img src={attributes?.logo} className={classes.companyLogo} alt="job-Image" />
                  </div>
                </Grid>
                <Grid item>
                  <Grid item container direction="column" spacing={1}>
                    <Grid item><Typography variant="h4" className={classes.orangeText}>{attributes.name}</Typography></Grid>
                    {attributes.company_addresses && attributes?.company_addresses.length > 0 &&
                      <Grid item container direction="row" alignItems="center" justify="space-evenly">
                        <Grid item><LocationOnOutlinedIcon className={classes.locationIcon} /></Grid >
                        <Grid item><Typography variant="body1">{attributes?.company_addresses[0]?.attributes?.address}</Typography></Grid></Grid>}
                    <Grid item><RenderTag data-testId="RenderTag" {...{ tags: attributes.tags || [], classes }} /></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <Container maxWidth="xl">
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item md={1} lg={1} xl={1}>
            </Grid>
            <Grid item md={10} lg={10} xl={10}>
              <br />
              <Grid container direction="row" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Grid container direction="row" spacing={1}>
                    <JobsFilter parentCallback={this.filterValue} showJobCategories={() => { }} showTrendingJobCategories={() => { }} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={6} xl={6}>
                  <Grid container direction="row" >
                    <div>
                      <Typography variant="body2" className={`${classes.jobHeading} ${classes.underLine}`}>Jobs</Typography>
                    </div>
                  </Grid>
                  <br />
                  <Grid style={{ marginTop: '0rem' }} container direction="row">
                    <Typography variant="h4" className={classes.jobHeading}>{`About ${attributes.name}`}</Typography>
                  </Grid><br />
                  <Grid container direction="row">
                    <Typography variant="body2">{attributes.about}</Typography>
                  </Grid>
                  <br />
                  <Grid container direction="row">
                    <Typography style={{ color: '#4f4f4f' }} variant="body2">{`Available Positions(${attributes.vacant_position_count || 0})`}</Typography>
                  </Grid>
                  <br />
                  {this.jobDetailCard(attributes, classes,)}
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                  <Grid container direction="column" alignItems="center" className={classes.btnBox}>
                    <Grid item>
                      <Typography className={classes.personalisedTxt} variant="subtitle1">Jobs From Similar companies</Typography>
                    </Grid><br />
                    {similarJobs.map((jobs, i) => (
                      <>
                        <br />
                        <RenderSimilarJob data-testId="RenderSimilarJob" {...{ classes, jobs, gotoSimilarJobDetails, openDialog }} />
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
              </Grid>
            </Grid>
          </Grid>
          {!localStorage.getItem("token") && <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />}
        </Container>
        <br />
        <br />
      </>
    );
  }
}
//@ts-ignore
const JobsDetailsViewAllWebWithRouter = withRouter(JobsDetailsViewAllWeb);
const JobsDetailsViewAllWebWithLoader = withLoader(JobsDetailsViewAllWebWithRouter);
const JobsDetailsViewAllWebToast = withSnackbar(JobsDetailsViewAllWebWithLoader);
const JobsDetailsViewAllWebWithStyle = withStyles((theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer'
    },
    tagBtn: {
      borderRadius: "5px",
      padding: "3px 5px",
      backgroundColor: '#b5b5b54a',
    },
    underLine: {
      textDecoration: 'underline',
      textDecorationThickness: '.2rem'
    },
    divider: {
      width: '100%',
      backgroundColor: '#000000'
    },
    personalisedTxt: {
      fontSize: '16px',
      fontWeight: 700,
      textAlign: 'center',
      [theme.breakpoints.down("lg")]: {
        fontSize: '12px',
      }
    },
    btnBox: {
      padding: '1rem',
      border: '1px solid #e1e1e1',
      boxShadow: '0 3px 6px 0 rgba(232, 226, 226, 0.42)'
    },
    locationIcon: {
      color: '#707070'
    },
    smalllocationIcon: {
      color: '#707070',
      height: '1rem'
    },
    accordion: {
      borderRadius: '4px',
      border: 'solid 1px #dec9c9',
      boxShadow: 'none'
    },
    location: {
      color: '#707070'
    },
    jobcontent: {
      borderRadius: '2px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)',
      cursor: 'pointer',
    },
    jobHeading: {
      color: '#f48b1b',
    },
    logo: {
      height: '7rem',
      width: '8rem'
    },
    viewBtn: {
      backgroundColor: '#f48b1b',
      height: '2rem',
      width: '7rem',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    overlay: {
      position: 'relative'
    },
    jobHeaderImg: {
      backgroundImage: 'linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 117%)',
      height: '15rem',
      objectFit: 'contain',
      width: '100%'
    },
    orangeText: {
      color: '#F48B1B',
    },
    companyLogo: {
      height: '9rem',
      width: '9rem',
      borderRadius: 10,
      border: 'solid 1px rgba(112, 112, 112, 0.27)',
    },
    similarCompanyLogo: {
      height: '3rem',
      width: '3rem',
      objectFit: 'contain',
      [theme.breakpoints.down("sm")]: {
        width: '3.5rem',
        height: '3rem',
      }
    },
    logoPaper: {
      width: '100%',
      height: '100%',
      marginTop: '-2rem',
    },
    similarLogoPaper: {
      width: '4rem',
      height: '4rem',
      padding: '0.5rem',
      borderRadius: 4,
      border: 'solid 1px rgba(112, 112, 112, 0.27)',
      backgroundColor: '#fff',
      [theme.breakpoints.down("md")]: {
        width: '3.5rem',
        height: '4rem',
      }
    },
    appliedBtn: {
      borderRadius: 5,
      backgroundColor: '#7764db',
      height: '2rem',
      width: '7rem',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#7764db',
        color: '#ffffff'
      }
    },
  })
)(JobsDetailsViewAllWebToast);
export default withTranslation()(JobsDetailsViewAllWebWithStyle);