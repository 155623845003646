import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import { sendAPIRequest } from "../../../components/src/Utility";

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    navigation: any;
    match: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  showExamdetails: any;
  examPapers: any;
  featureArticle: any;
  getAllBookmarked: any;
  categoryId: any;
  mockTestList: any;
  examCourses: any;
  examVedios: any;
  examDiscussions: any;
  careerExperts: any;
  examCalenders: any;
  likeList: any[];

  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ExamWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getExamdetailId: string = "";
  getExamPapersApiId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  getMockTestCallId: string = "";
  getExamCoursesCallId: string = "";
  getExamDiscussionAPICallId: string = "";
  getExamCalendarApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      showExamdetails: {},
      examPapers: {},
      featureArticle: [],
      getAllBookmarked: [],
      categoryId: '',
      mockTestList: [],
      examCourses: [],
      examVedios: [],
      examDiscussions: [],
      careerExperts: [],
      examCalenders: [],
      likeList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    window.scroll(0, 0);
    this.getExamDetails(this.props.match?.params?.id)
    this.getMockTests(this.props.match?.params?.id)
    this.downloadSamplePapers(this.props.match?.params?.id)
    this.getExamCourses(this.props.match?.params?.id)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors && responseJson !== undefined) {
        switch (apiRequestCallId) {
          case this.getExamdetailId:
            this.getExamdetailApiResponce(responseJson);
            break;
          case this.getMockTestCallId:
            this.getMockTestApiResponce(responseJson);
            break;
          case this.getExamPapersApiId:
            this.getExamPapersApiResponce(responseJson);
            break;
          case this.getExamCoursesCallId:
            this.getExamCoursesApiResponce(responseJson);
            break;

          case this.getExamDiscussionAPICallId:
            this.getExamDiscussionApiResponce(responseJson);
            break;
          case this.getExamCalendarApiCallId:
            this.getExamCalendarApiResponce(responseJson);
            break;
          default:
            break;
        }
      }
      else {
        if (responseJson && !responseJson.errors) {
          console.log(responseJson.errors);
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  getExamdetailApiResponce = (responseJson: any) => {
    this.setState({
      showExamdetails: responseJson.data,
      examVedios: responseJson.data.attributes.content_videos,
      careerExperts: responseJson.data.attributes.career_experts
    }, () => {
      this.getExamDiscussionForms();
      this.getExamCalendar();
    });
    this.props.hideLoader();
  }

  getMockTestApiResponce = (responseJson: any) => {
    this.setState({
      mockTestList: responseJson.data,
    });
  }

  getExamPapersApiResponce = (responseJson: any) => {
    this.setState({
      examPapers: responseJson.data,
    });
  }

  getExamCoursesApiResponce = (responseJson: any) => {
    this.setState({
      examCourses: responseJson.data,
    });
  }

  getExamDiscussionApiResponce = (responseJson: any) => {
    this.setState({
      examDiscussions: responseJson.data,
    });
  }

  getExamCalendarApiResponce = (responseJson: any) => {
    this.setState({
      examCalenders: responseJson.data,
    });
  }

  getExamDetails = (examId: any) => {
    this.props.showLoader();
    this.getExamdetailId = sendAPIRequest(`${configJSON.examDetailsUrl}/${examId}`,
      { headers: { "Content-Type": configJSON.apiContentType } }
    )
  }
  getMockTests = (examId: any) => {
    const header: any = {
      "Content-Type": configJSON.apiContentType,
    };
    const token = localStorage.getItem('token');
    if (token) {
      header['token'] = token
    }
    const apiEndPoint = `${configJSON.examDetailsUrl}/${examId}/all_mock_assessments`;
    const methodType = configJSON.httpGetType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMockTestCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getExamCourses = (examId: any) => {
    const header: any = {
      "Content-Type": configJSON.apiContentType,
    };
    const token = localStorage.getItem('token');
    if (token) {
      header['token'] = token
    }
    const apiEndPoint = `${configJSON.examCourseUrl}?page=${1}&per_page=${10}&exam[]=${examId}`
    this.getExamCoursesCallId = sendAPIRequest(apiEndPoint, { headers: header })
  }
  downloadSamplePapers = (examId: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const apiEndPoint = `${configJSON.examDetailsUrl}/${examId}/download_papers`;
    const methodType = configJSON.httpGetType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExamPapersApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addBookmark = (id: any, event: any) => {
    event?.stopPropagation()
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.apiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.addBookmarkUrl;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  removeBookmark = (id: any, event: any) => {
    event?.stopPropagation()
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.apiContentType
    }
    if (token) {
      headers['token'] = token
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getExamDiscussionForms = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const apiEndPoint = `${configJSON.examDiscussionUrl}?sub_category[]=${this.state?.showExamdetails?.attributes?.sub_category?.id}`;
    const methodType = configJSON.httpGetType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExamDiscussionAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getExamCalendar = () => {
    const { showExamdetails } = this.state
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const apiEndPoint = configJSON.examDetailsUrl + `?page=${1}&per_page=${10}&upcoming=true&category[]=${showExamdetails?.attributes?.category?.id}&sub_category[]=${showExamdetails?.attributes?.sub_category?.id}&content_provider[]=${showExamdetails?.attributes?.content_provider?.id}`
    this.getExamCalendarApiCallId = sendAPIRequest(apiEndPoint, { headers: header })
  }
  scrollToModule = (id: any) => {
    let tag: any = document.getElementById(`${id}`) as HTMLElement
    tag?.scrollIntoView();
  }
  gotoExpertProfile = (id: any) => {
    const { history } = this.props;
    history.push(`/home/careerexpert/${id}`);
  }
  // Customizable Area End
}
