//@ts-nocheck
import React from "react";
import {
  Grid,
  Typography,
  Button,
  Link,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { Formik, Form, FormikHelpers, Field } from "formik";
//@ts-ignore
import { withRouter } from "react-router";
import ConfirmOtpController, { Props } from "./ConfirmOtpController.web";
import { validationSchema } from "./OTPInputAuth.web.validation";
import OtpInput from "../../../components/src/OtpInput.web";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
interface FormValues {
  otpCode: string | null | undefined;
}
export class ConfirmOTP extends ConfirmOtpController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className="mt-8"
      >
        <Formik
          initialValues={{
            otpCode: "",
          }}
          validationSchema={validationSchema}
          // @ts-ignore
          onSubmit={(
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
          ) => {
            console.log("values", values);
            setSubmitting(true);
            setTimeout(() => {
              setSubmitting(false);
            }, 500);
          }}
          enableReinitialize
        >
          {(formikProps) => {
            const {
              values,
              errors,
              touched,
              isSubmitting,
            } = formikProps;
            return (
              // @ts-ignore
              <Form autoComplete='off'>
                <Grid container direction="column" spacing={3} alignItems="center">
                  <Grid item>
                    <Typography variant="h3" className="text-center" color="secondary">
                      Enter The 4-digit code sent
                    </Typography><br />
                    {this.state.phoneNumber ? <Typography variant="h3" className="text-center" color="secondary">
                      to +91{this.state.phoneNumber}
                    </Typography>
                      : <Typography variant="h3" className={`${classes.textCss} text-center`} color="secondary">
                        to {this.state.email}
                      </Typography>}
                  </Grid>
                  <Grid item>
                    <OtpInput
                      // @ts-ignore
                      OTPLength={4}
                      otpType={"number"}
                      onOTPChange={(otp: any) => {
                        this.setState({ otpCode: otp });
                      }}
                      error={
                        touched.otpCode && errors.otpCode ? true : false
                      }
                      helperText={
                        touched.otpCode && errors.otpCode
                          ? errors.otpCode
                          : ""
                      }
                      secure={false}
                      value={this.state.otpCode}
                    />
                  </Grid>
                  <Grid item container direction="row" justify="center">
                    <Grid item >
                      {this.state.counter === 0 ? null : (
                        <Typography variant="subtitle1" className="text-sm" color="secondary">
                          Resend OTP in 0{Math.floor(this.state.counter / 60)}:
                          {(this.state.counter % 60).toString().length > 1
                            ? this.state.counter % 60
                            : "0" + (this.state.counter % 60)}
                        </Typography>
                      )}
                      {this.state.counter === 0 ? (
                        <Link onClick={() => { this.resendOTP() }}>
                          <Typography variant="caption" color="secondary" className="underline cursor-pointer">Resend OTP</Typography></Link>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => {
                        this.handleVerifyOtp();
                      }}
                      className={classes.conBtn}
                    >
                      <Typography variant="h3">Continue</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    );
  }
}
//@ts-ignore
const ConfirmOTPWebWithRouter = withRouter(ConfirmOTP);
const ConfirmOTPWebWithLoader = withLoader(ConfirmOTPWebWithRouter);
const ConfirmOTPWebWithSnackBar = withSnackbar(ConfirmOTPWebWithLoader);
export default withStyles((theme) =>
  createStyles({
    conBtn: {
      width: '11rem',
      borderRadius: '6.75rem',
      boxShadow: '0 6px 15px 0 rgba(100, 87, 87, 0.17)',
      border: 'solid .15rem #ffffff',
      '&:hover': {
        backgroundColor: '#ffffff',
        color: '#000000'
      }
    },
    textCss: {
      overflowWrap: 'anywhere'
    }
  })
)(ConfirmOTPWebWithSnackBar);
