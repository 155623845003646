//@ts-nocheck
import React from "react";
import {
    withStyles, Box, Divider, Grid, Button, createStyles, Typography,
    TextField, ListItemIcon
} from "@material-ui/core";
import { withRouter, } from "react-router";
import {
    Props,
} from "./CategoriesViewallController.web";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import Rating from '@material-ui/lab/Rating';


export class Coursesearch extends React.Component {
    constructor(props: Props) {
        super(props);
    }
    render() {
        //@ts-ignore
        const { classes, filterdata, ratingValue } = this.props;
        return (
            <Grid container direction="column" alignItems="left" justify="flex-start">
                <Grid item xs={12} style={{ padding: '0.5rem' }}>
                    <Typography variant="body2" className={classes.headTxtCs}>Filter</Typography>
                    <Divider variant="fullWidth" component="h3" />
                    <br />
                    {filterdata && filterdata.length > 0 ? (
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className={classes.listrootCs}
                        >
                            {/* selectedSubType */}
                            {filterdata.map((item: any, index: any) => (
                                <React.Fragment key={item?.id}>
                                    <ListItem button data-testId="onclick" onClick={() => this.props.onclick(item)} >
                                        <ListItemText className={classes.filterItemCs} primary={<Typography variant="subtitle1" className={classes.checkgroupTxtCs}>{item?.type}</Typography>} />
                                        {this.props.selectedType == item?.id ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={this.props.selectedType == item?.id} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {(item.id == 0 || item.id == 1 || item.id == 2 || item.id == 3) && (<>
                                                {item.data && item.data.length > 0 && item.data.map((ele: any) => {
                                                    return (
                                                        <ListItem key={ele.id} role={undefined} dense button data-testId="handleToggle" onClick={() => this.props.handleToggle(ele, filterdata, index)}>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={item.value.indexOf(ele.id) !== -1}
                                                                color="default"
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${ele.id}` }}
                                                            />
                                                            <ListItemText id={`checkbox-list-label-${ele.id}`} primary={<Typography variant="subtitle2" className={classes.btnTypo}>{ele?.attributes?.name ? `${ele?.attributes?.name} (${ele?.attributes?.count})` : `${ele?.attributes?.partner_name} (${ele?.attributes?.count})`}</Typography>} />
                                                        </ListItem>
                                                    );
                                                })}
                                            </>)}

                                            {/* 'Price' */}
                                            {item.type == "Price" && (

                                                <React.Fragment>
                                                    <ListItem key={item.id} role={undefined} dense >
                                                        <TextField
                                                            id="fromPrice"
                                                            label="Min Price"
                                                            type="number"
                                                            variant="outlined"
                                                            value={item.fromPrice}
                                                            className={classes.textField}
                                                            InputProps={{ inputProps: { min: "1" } }}
                                                            onChange={(e) => this.props.handlePriceChange(e, filterdata, index)}
                                                        />
                                                    </ListItem>
                                                    <ListItem key={item.id + 1} role={undefined} dense >
                                                        <ListItemIcon>
                                                            <TextField
                                                                id="toPrice"
                                                                label="Max Price"
                                                                variant="outlined"
                                                                type="number"
                                                                value={item.toPrice}
                                                                className={classes.textField}
                                                                InputProps={{ inputProps: { min: "1" } }}
                                                                error={item.fromPrice > item.toPrice}
                                                                onChange={(e) => this.props.handlePriceChange(e, filterdata, index)}
                                                            />
                                                        </ListItemIcon>
                                                    </ListItem>
                                                </React.Fragment>
                                            )}
                                            {/* 'Rating' */}
                                            {item.id == 4 && (
                                                <React.Fragment>
                                                    <ListItem key={item.id} role={undefined} dense >
                                                        <Box component="fieldset" mb={3} borderColor="transparent">
                                                            <Rating
                                                                name="simple-controlled"
                                                                value={ratingValue}
                                                                onChange={this.props.handleRatingChange}
                                                            />
                                                        </Box>
                                                    </ListItem>
                                                </React.Fragment>
                                            )}

                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            ))}
                            <Box component="div" ml={2} mt={2} mb={2}>
                                <Button variant="contained" className={classes.applybtnCs} color="secondary" data-testId="applyFilter" onClick={() => this.props.applyFilter(filterdata)}>
                                    Apply
                                </Button>
                                <Button variant="contained" className={classes.clearbtnCs} color="secondary" data-testId="clearFilter" onClick={() => this.props.clearFilter(filterdata)} >Clear All</Button>
                            </Box>
                        </List>
                    ) : (<Grid item>
                        <Typography variant="body2">Will Update You Soon</Typography>
                    </Grid>

                    )}

                </Grid>
            </Grid>
        )
    }
}
// export default Categorysearch
//@ts-ignore
const CoursesWebWithRouter = withRouter(Coursesearch);

export default withStyles((theme) =>
    createStyles({
        headTxtCs: {
            color: '#000',
            margin: '0.8rem',
            fontSize: '15px'
        },
        checkgroupTxtCs: {
            color: '#000',
            margin: '0rem',
            fontSize: '15px',
            fontFamily: 'Montserrat-SemiBold',
        },
        filterItemCs: {
            fontSize: '14px !important',
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: '400',
            lineHeight: '1.167',
            letterSpacing: '0em',
        },
        listrootCs: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        applybtnCs: {
            padding: '6px 42px',
            marginRight: '10px',
            margin: '5px 0px',
            backgroundColor: '#5285f8 !important',
            borderRadius: '6px',
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontWeight: '500',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#1a5df3 !important',
                color: '#fff'
            }
        },
        clearbtnCs: {
            margin: '5px 0px',
            backgroundColor: '#fff !important',
            boxShadow: 'none',
            borderRadius: '6px',
            fontSize: '15px',
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: '500',
            color: '#000',
            padding: '6px 8px',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: 200,
            "& input": {
                color: '#2b2b2b !important',
            },
            "& label": {
                color: 'gray !important',
            },
        },
    })
)(CoursesWebWithRouter);