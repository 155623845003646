//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Button,
  InputBase,
  createStyles,
  Container,
  Typography,
  Card,
  CardContent
} from "@material-ui/core";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Pagination from '@material-ui/lab/Pagination';
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import JobsViewAllWebController, { Props } from './JobsViewAllController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { jobHeaderImg } from "./assets";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import SearchIcon from '@material-ui/icons/Search';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import JobsFilter from './jobsFilter.web';

const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;

const RenderLocation = props => {
  const { classes, locations } = props;
  const location = [];
  locations?.forEach(x => {
    if (x?.attributes?.city) { location.push(x?.attributes?.city) }
  });
  return (<Grid item>
    <Typography variant="body2" className={classes.location}>
      {location.filter(onlyUnique).join(', ')}
    </Typography>
  </Grid>)
}

const JobCompany = ({ classes, e, gotoJobDetails, openDialog }) => {
  return (
    <Grid item xs={12}>
      <Card data-testId="gotoJobDetails" onClick={() => gotoJobDetails(e?.company?.id, e?.job?.attributes?.id)} className={classes.jobcontent}>
        <CardContent className={classes.jobCardContent}>
          <Grid container spacing={1}>
            <Grid container item direction="row" alignItems="center" justify="space-between">
              <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item xs={3} md={4} lg={2} xl={3} className={classes.logoPaper}>
                    <img src={e?.company?.logo?.url} className={classes.companyLogo} alt="job-Image" />
                  </Grid>
                  <Grid item xs={8} md={7} lg={9} xl={9}>
                    <Typography variant="h3" >{e?.company?.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={3} xl={2}><Button variant="contained" type="button"
                onClick={evt => {
                  if (localStorage.getItem('token')) {
                    evt.stopPropagation();
                    gotoJobDetails(e?.company?.id, e?.job?.attributes?.id)
                  }
                  else { openDialog(true) }
                }} className={classes.viewBtn} >Job Details</Button></Grid>
            </Grid><br />
            <Grid item container direction="row" alignItems='center'>
              <Grid item>
                <BusinessCenterOutlinedIcon className={classes.locationIcon} />
              </Grid>
              <Grid item>
                <Typography variant="caption" className={classes.orangeText} > {e?.job?.attributes?.experience}</Typography>
              </Grid>
            </Grid><br />
            <Grid item>
              <Typography variant="body2" >{e?.job?.attributes?.job_type}</Typography>
            </Grid>
            {e?.company?.salary &&
              <Grid container item direction="row" spacing={1}>
                <Grid item>
                  <AccountBalanceWalletIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{e?.company?.salary}</Typography>
                </Grid>
              </Grid>}
            <Grid container item direction='row' alignItems='center'>
              <Typography variant="subtitle2" >{e?.job?.attributes?.description}</Typography>
            </Grid>
            <Grid container item direction="row" alignItems="flex-start" justify="space-between">
              <Grid item>
                {e?.job?.attributes?.locations && e?.job?.attributes?.locations.length > 0 &&
                  <Grid container direction="row" alignItems='center'>
                    <Grid item>
                      <LocationOnOutlinedIcon className={classes.locationIcon} />
                    </Grid>
                    <RenderLocation data-testId="RenderLocation" {...{ locations: e?.job?.attributes?.locations, classes }} />
                  </Grid>}
              </Grid>
              <Grid item>
                <Grid item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
//@ts-ignore
export class JobsViewAll extends JobsViewAllWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ searchJobs: this.props?.match?.params?.searchJobs }, () => this.getAllJobs())
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  setSearchJob = (ev: any) => {
    this.setState({ searchJobs: ev?.target?.value }, () =>
      this.getAllJobs())
  }
  filterValue = (value: any) => {
    this.setState({
      selectedLocation: value.location, selectedJobExperience: value.experience,
      selectedJobType: value.jobType, selectedDatePosted: value.datePosted, selectedSalaries: value.salary
    },
      () => this.getAllJobs());
  }
  gotoJobDetails = (compId: any, jobId: any) => {
    const { history } = this.props;
    this.setState({ companyId: compId })
    history.push(`/home/jobs/all-jobs/company/${compId}/job/${jobId}`);
  };
  setPage = (value: any) => {
    this.setState({ currentPage: value }, () => {
      this.getAllJobs()
    })
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { companyDetails, totalPages, selectedCompanyName, selectedCategory, searchJobs } = this.state;
    let locations = "";
    return (
      <>
        <Grid container direction="row" alignItems="center" className={classes.overlay}>
          <Grid item xs={12} className={classes.imgTag}>
            <img src={jobHeaderImg} className={classes.jobHeaderImg} alt="job-Image" />
          </Grid>
          <Grid item container direction="row" alignItems="center" className={classes.txtTag}>
            <Grid item>
              <Typography color="primary" className={classes.fontTxt} variant="h1">Search Between more than</Typography>
            </Grid>
            <Grid item className={classes.secondaryTxt}>
              <Typography variant="h1" className={classes.orangeHeadingTxt}>50,000</Typography>
              <Typography color="primary" variant="h1" className={classes.fontTxt}>Open Jobs</Typography>
            </Grid>
            <Grid item container direction="row" justify="space-around" className={classes.filters} alignItems="center">
              <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
                  <Grid item xs={1}>
                    <SearchIcon color="secondary" />
                  </Grid>
                  <Grid item xs={10}>
                    <InputBase
                      className={classes.searchinput}
                      placeholder="Search Jobs, Companies"
                      value={searchJobs}
                      classes={{
                        input: classes.inputInput,
                      }}
                      data-testId="searchInput"
                      onChange={(e) => this.setSearchJob(e)}
                      inputProps={{ 'aria-label': 'search' }}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container maxWidth="xl">
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item md={1} lg={1} xl={1}>
            </Grid>
            <Grid item md={10} lg={10} xl={10}>
              <Grid container direction="column" alignItems="center" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Grid container direction="row" spacing={1}>
                    <JobsFilter selectedCompanyName={selectedCompanyName} selectedCategory={selectedCategory}
                      parentCallback={this.filterValue} getAllJobs={this.getAllJobs} showJobCategories={() => { }}
                      showTrendingJobCategories={() => { }} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <br />
                  <Grid container direction="row">
                    <Typography variant="h3" className={classes.jobHeading}>All jobs </Typography>
                  </Grid>
                  <br />
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    {companyDetails && companyDetails.length > 0 ? companyDetails.map((e, i) => {
                      if (e?.job?.type === 'job_company') {
                        return (
                          <JobCompany data-testId="JobCompany" {...{ classes, e, gotoJobDetails: this.gotoJobDetails, openDialog: this.openDialog }} />
                        )
                      }
                    })
                      : <Grid item xs={12}><Typography variant="body2">No Jobs Available</Typography></Grid>}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
                  <br />
                  {companyDetails && companyDetails.length > 0 && companyDetails.map((e, i) => {
                    if (e?.type === "cta") {
                      return (
                        <>
                          <Grid container direction="row" alignItems="center" spacing={1} key={i}>
                            <Grid item className={classes.Cta}>
                              <Grid container direction='row' alignItems="center">
                                <Grid item>
                                  <img src={e?.attributes?.square_background_image} alt="job-ad" className={classes.leftImg} />
                                </Grid>
                                <Grid item>
                                </Grid>
                              </Grid>
                              <br />
                              <Grid container direction='column' alignItems="center" spacing={1}>
                                <Grid item>
                                  <Typography variant='h4' className={classes.ctaHeadline}>
                                    {e?.attributes?.headline}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant='h3' className="text-center">
                                    {e?.attributes?.description}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button variant="contained" type="button" className={classes.viewBtn} >Apply Now</Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid><br /></>)
                    }
                  }
                  )}
                </Grid>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
              </Grid>
            </Grid>
            {totalPages > 1 &&
              <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
                <Pagination count={totalPages} data-testId="setPage" onChange={(val) => this.setPage(val)} variant="outlined" shape="rounded" />
              </Grid>}
          </Grid>
          {!localStorage.getItem("token") && <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />}
        </Container>
        <br />
        <br />
      </>
    );
  }
}
//@ts-ignore
const JobsViewAllWithRouter = withRouter(JobsViewAll);
const JobsViewAllWithLoader = withLoader(JobsViewAllWithRouter);
const JobsViewAllToast = withSnackbar(JobsViewAllWithLoader);
const JobsViewAllWithStyle = withStyles((theme) =>
  createStyles({
    ctaHeadline: {
      color: '#3d9ee8',
      textAlign: 'center'
    },
    tagBtn: {
      borderRadius: "5px",
      padding: "3px 5px",
      color: 'black'
    },
    leftImg: {
      height: '3rem',
      width: '3rem'
    },
    Cta: {
      border: ' solid 1px rgba(112, 112, 112, 0.59)',
      padding: '1rem'
    },
    similarCompanyLogo: {
      height: '3rem',
      width: '3rem',
    },
    companyLogo: {
      height: '3rem',
      // width:'3rem',
      [theme.breakpoints.down('sm')]: {
        height: '2rem',
        width: '2rem',
      }
    },
    logoPaper: {
      // width: '4rem',
      // height: '4rem',
      padding: '0.5rem',
      borderRadius: 17,
      // border: 'solid 1px rgba(112, 112, 112, 0.27)',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: '3rem',
        height: '3rem',
      }
    },
    similarLogoPaper: {
      width: '4rem',
      height: '4rem',
      marginTop: '-2rem',
      padding: '0.5rem',
      borderRadius: 4,
      border: 'solid 1px rgba(112, 112, 112, 0.27)',
      backgroundColor: '#fff',
    },
    paginator: {
      marginTop: '2rem',
      marginBottom: '4rem',
      justifyContent: 'center',
    },
    inputInput: { color: '#000000', fontSize: '16px', fontWeight: 600 },
    searchinput: {
      marginLeft: theme.spacing(1),
      flex: 1,
      '& input': {
        color: "#ffffff",
      }
    },
    categorySearch: {
      margin: 'auto',
      height: '4rem',
      borderRadius: '24px',
      padding: '1rem',
      webkitBackdropFilter: 'blur(50px)',
      backdropFilter: 'blur(50px)',
      backgroundColor: 'rgba(207, 207, 207, 0.13)'
    },
    seeLogo: {
      objectFit: 'cover',
      height: '3rem',
      width: '7rem'
    },
    purpleTxt: {
      color: '#7764db'
    },
    twoDivider: {
      width: '100%'
    },
    divider: {
      width: '100%',
      backgroundColor: '#000000'
    },
    uploadBtn: {
      height: '3rem',
      backgroundColor: '#f48b1b',
      borderRadius: '6px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff',
        borderRadius: '6px',
      }
    },
    personalisedTxt: {
      fontSize: '14px',
      fontWeight: 700,
      [theme.breakpoints.down("lg")]: {
        fontSize: '12px',
      }
    },
    personalisedBtn: {
      backgroundColor: '#f48b1b',
      height: '7rem',
      borderRadius: '6px',
      // width: '140px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff',
        height: '7rem',
        borderRadius: '6px',
      }
    },
    btnBox: {
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      border: '1px solid #e1e1e1',
      boxShadow: '0 3px 6px 0 rgba(232, 226, 226, 0.42)'
    },
    locationIcon: {
      color: '#707070'
    },
    accordion: {
      borderRadius: '4px',
      border: 'solid 1px #dec9c9',
      boxShadow: 'none'
    },
    location: {
      color: '#707070'
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    compLogo: {
      width: '72px',
      height: '58px'
    },
    jobcontent: {
      width: '100%',
      borderRadius: '2px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)'
    },
    jobHeading: {
      color: '4f4f4f'
    },
    selectedCompanyName: {
      backgroundColor: '#f48b1b',
      color: '#ffffff !important',
      height: '52px',
      width: '180px',
      marginBottom: '.5rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    companyName: {
      backgroundColor: '#ffffff',
      height: '52px',
      width: '180px',
      color: '#f48b1b',
      marginBottom: '.5rem',
      border: '1px solid #f48b1b',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    logo: {
      height: '7rem',
      width: '8rem'
    },
    publishBtn: {
      backgroundColor: '#f48b1b',
      height: '52px',
      // width: '140px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    viewBtn: {
      backgroundColor: '#f48b1b',
      height: '2rem',
      width: '7rem',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    fontTxt: {
      fontSize: '70px',
      [theme.breakpoints.down("xs")]: {
        fontSize: '40px',
      }
    },
    secondaryTxt: {
      display: 'flex'
    },
    overlay: {
      position: 'relative'
    },
    txtTag: {
      display: 'inline-block',
      position: 'absolute',
      paddingLeft: '9rem',
      paddingRight: '9rem',
      [theme.breakpoints.down("xs")]: {
        padding: '0rem'
      }
    },
    jobHeaderImg: {
      backgroundImage: 'linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 117%)',
      height: '45rem',
      objectFit: 'fill',
      width: '100%'
    },
    orangeHeadingTxt: {
      color: '#F48B1B',
      fontSize: '70px',
      [theme.breakpoints.down("xs")]: {
        fontSize: '40px',
      }
    },
    orangeText: {
      color: '#F48B1B',
    },
    filters: {
      // backgroundColor: '#ffffff',
      marginTop: '1rem',
      height: '8rem'
    },
    input: {
      color: '#000000',
      [theme.breakpoints.down("sm")]: {
        fontSize: '12px',
      }
    },
    addIcon: {
      height: '1.2rem'
    },
  })
)(JobsViewAllToast);
export default withTranslation()(JobsViewAllWithStyle);