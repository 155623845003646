export default {
  Explore: "Explore",
  Languages:"Languages",
  Home:"Home",
  noDataFound:"No data found",
  By:"By",
  noResultFound:"No Result Found",
  SearchResults:"Search Results",
  NothingFound:"Nothing Found!",
  Courses:"Courses",
  SeeAll:"See all",
  LiveStream:"Live Stream",
  Videos:"Videos",
  ShortVideos:"Short Videos",
  QuizAssessment:"Quiz & Assessment",
  ArticlesNews:"Articles & News",
  Blogs:"Blogs",
  Exams:"Exams",
  Podcast:"Podcast",
  EBooks:"E-Books",
  ComingSoon:'Coming Soon...',
  PopularVideos:"Popular Videos",
  TrendingVideos:"Trending Videos",
  Recommended:"Recommended for you",
  PopularArticles:"Popular Articles & News",
  TrendingArticles:"Trending Articles & News",
  ContentType:"Content Type",
  ContentProvider:"Content Provider",
  Tags:"Tags",
  ClearAll:"Clear All",
  Date:"Date",
  Filter:"Filter",
  Quiz:"Quiz",
  Assessment:"Assessment",
  RecentSearch:"Recent Search",
  searchAgain:"Please search again…",
  Search:"Search",
  placeholder:"Looking for something specific?",
  Previous:"Previous",
  Skip:"Skip",
  PopularQuiz:"Popular Quiz",
  noQuiz:"There is no Popular Quiz",
  TrendingQuiz:"Trending Quiz",
  noTrendingQuiz:"There is no Trending Quiz,",
  noRecommendedQuiz:"There is no Recommended Quiz",
  StartNow:"Start Now",
  Feed:"Feed",
  Published:"Published",
  Drafted:"Drafted",
  PostPublish:"Post/Publish \nYour Question or \nDiscuss a Topic ",
  TOP10location:"Top 10 Location",
  PostReport:"Post Report",
  quesTitle:"Please enter question title",
  Enterdescription:"Enter description",
  quesDescription:"please enter question description",
  AddScreenShot:"+ Add Screen Shot",
  ReportNow  :"Report Now",
  Cancel:"Cancel",
  SubmittedSuccessfully:"Report Submitted Successfully",
  Continue:"Continue",
  Report:"Report",
  selectProblem:"Please select a problem",
  getHelp:"If someone is in immediate danger, get help before reporting to Us. Don't wait.",
  noPublishedData:"There is no Published data",
  Apply:"Apply",
  SearchLocation:"Search location",
  noFeedData:"There is no Feed data",
  PopularDiscussion:"Popular Discussion",
  TrendingDiscussion:"Trending Discussion",
  noDrafteddata:"There is no Drafted data",
  DiscussionThread:"Discussion Thread",
 EntermobEmail :"Enter Your \n Mobile Number / Email Address",
 MobileNumber:"Mobile Number / Email",
 Or:"Or",
 EnterOTP:"Enter Your OTP",
 pleasecomment:"please enter comment",
 Confirm:"Confirm",
 NoTrialsFound:'No Recommended Trials Found',
 RecommendedTrials:"Recommended Trials",
 noRunningTrials:"No Running Trials Found",
 RunningTrials:"Running Trials",
 RecommendedSubscription:"Recommended Subscription",
 NoRecommendedSubscription:"No Recommended Subscrtiptions Found",
 Author:"Author",
 Seventy:"70%",
 noRunningSubscriptions:"No Running Subscriptions Found",
 RunningSubscription:"Running Subscription",
 NoShortlistedSubscription:"No Shortlisted Subscription Found",
 ShortlistedSubscription:"Shortlisted Subscription",
 All:"All",
 URlnotfound:"URl not found",
 X:"X",
 TotalFees:"Total Fees",
 MedianSalary:"Median Salary",
 CourseRating:"Course Rating",
 Ranked:"Ranked",
 Rankedin:"Ranked in",
 VisitWebsite:"Visit Website",
 DataAvailable:"No Data Available",
 DownloadBrochure:"Download Brochure",
 NoCourses:"No Running Courses Found",
 ShortlistedCourses:"No Shortlisted Courses Found",
 Welcome:"Welcome",
 NoRecommendedCourses:"No Recommended Courses Found",
 RecommendedCourses:"Recommended Courses",
 RunningCourses:"Running Courses",
 shortlistedCourses:"Shortlisted Courses",
 five:"/5",
 ExploreMore:"Explore More",
 Learners:"Learners",
 Categories:"Categories",
 NewThread:"New Thread",
 Entertitle:"Enter Title",
 postPublished:"The Post Has Been Published!",
 postDraft:"The Post Has Been Saved As A Draft!",
 AddTags:"Add Tags",
 EnterTag:"Enter Tag",
 spaceTag:"Press space to add a tag",
 selectCategoryDraft:"Request you to select Category for Draft",
 Q:"Q",
 Articles:"Articles",
 article:"article",
 blog:"blog",
 YourOrder:"Your Order",
 YourDetails:"Your Details",
 OrderDetails:"Order Details",
 TotalSessionPrice:"Total Session Price",
 Rs:"Rs.",
 noArticlesdata:"There is no Articles data",
 noBlogsdata:"There is no Blogs data",
 noVideosdata:"There is no Videos data",
 BOOKNOW:"BOOK NOW",
 BOOKED:"BOOKED",
 loginfirst:"Request you to login first",
 OK:"OK",
 PerSession:"Per Session",
 ReviewRating:"Review & Rating",
 AboutExpert:"About Expert",
 noExperts:"There is no Experts",
 CareerExperts:"Career Experts",
 CareerExpert:"Career Expert",
 customTitle:"Search Expert, Articles, Videos, Etc",
 Price:"Price",
 Rating:"Rating",
 MinimumPrice:"Minimum Price",
 MinimumPriceReq:"Minimum price required",
 MaximumPrice:"Maximum Price",
 MaximumPriceReq:"Maximum price required and greater than minimum price",
 EnterDetails:"Enter Details",
 Iamcurrently:"I am currently",
 CurrentStatus:"Please Select Your Current Status",
 IamLookingFor:"I am Looking For",
 SubjectStream:"Subject/Stream",
 selectSubjectStream:"Please Select subject/stream you have studied \n-select any if not Decided",
 EnterMobileNumber:"Please enter your mobile number",
 Starthere:"Start here",
 BookNow:"Book Now",
 NextGeneraion:"Next Generation Career Hunting \n Experience",
 EnterMobileEmail:"Enter Mobile / E-mail",
 orloginvia:"or login via",
 SkipforNow:"Skip for Now",
 EnterOTP1:"Enter OTP",
 codeSent:"Enter the 4-digit code sent",
 to:"to",
 ResendOTP:"Resend OTP",
 zerozero:"00",
 InvalidPassword:"Invalid Password",
 incorrectNumber:"Phone Number is incorrect",
 EmailNumberincorrrect:"Email Id / Phone Number is incorrect",
 lessthan10:"Phone Number is less than 10",
 morethan10:"Phone Number is more than 10",
 EmailisIncorrect:"Email is Incorrect",
 Empty:"Empty",
 lengthmorethan10:"Length of Phone Number is more than 10",
 SignUp:"Sign Up",
 Dashboard:"Dashboard",
 WelcomeAndrew:"Welcome Andrew",
 Educationpassport:"Education is the passport",
 subtitle2:"to the future, so Learn More & More",
 Recommendedcourses:"Recommended courses",
 Signout:"Signout",
 SearchScreen:"Search Screen",
 ActivityFeed:"ActivityFeed",
 Profile:"Profile",
 Changethemefrom:"Change theme from",
 MySubscriptions:"My Subscriptions",
 RunningSubscriptions:"Running Subscriptions",
 RecommendedSubscriptions:"Recommended Subscriptions",
 ChangeTheme:"Change Theme",
 Noresultsfound:"No results found",
 MyTrials:"My Trials",
 TransactionHistory:"Transaction History",
 NoTransactionHistory:"There is no transaction history",
 Details:"Details",
 TransectionID:"Transection ID",
 DebitCard:"Payment Via Debit Card",
 
 
 

 
 

}