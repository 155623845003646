//@ts-nocheck
import React from "react";
import {
  withStyles, Hidden,
  Container, Grid, Typography, Button,
  createStyles
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import CoursesPageWebController, { Props } from "./CoursesPageController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { next, prev, coursesbottom, courseLandingPageimg } from "./assets";
import ScrollMenu from "react-horizontal-scrolling-menu";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import CoursesCard from "./CourseCard.web"


const CourseHeadingViewAll = ({ classes, props, categoryId, courseList, heading, stateInfo }) => {
  return (
    <Grid container direction="row" alignItems='center' justify="space-between" className={classes.paddingExamArVi}>
      <Grid item xs={6} md={3} lg={3} xl={3}>
        <Hidden smDown><Typography variant="h4" color="primary" className={classes.categoryHeading}>{heading}</Typography></Hidden>
        <Hidden mdUp><Typography variant="h3" color="primary" className={classes.categoryHeading}>{heading}</Typography></Hidden>
      </Grid>
      <Grid item>
        {courseList && courseList.length > 4 &&
          <Button
            className={classes.viewAll}
            color="primary"
            onClick={() => props.history.push(
              {
                pathname: `/home/courseviewall/${categoryId}`,
                state: stateInfo
              })}
            variant="outlined">
            <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
            <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
          </Button>
        }
      </Grid>
    </Grid>
  )
}

export class CoursesPageWeb extends CoursesPageWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getCategories();
    this.getTrendingCourses();
    this.getFascinatingCourse();
    this.getNewCourses();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Container maxWidth="xl">
          <Grid container direction="row" className={classes.titleHeader} alignItems="flex-start" justify="space-evenly">
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className={classes.titleBlock}>
              <Grid container direction="column">
                <Typography variant="h5" className={classes.title} color="primary">
                  <span style={{ fontFamily: 'Montserrat-SemiBold' }}>The</span> Latest Digital </Typography>
                <Typography variant="h5" className={classes.title} color="primary">Skills, Within reach</Typography>
                <div className="mt-8">
                  <Typography variant="body2">Discover the fastest,most effective way togain job-ready</Typography>
                  <Typography variant="body2" >expertise for the careers of the future with flexible online course</Typography>
                </div>
                <div className="mt-8">
                  {!localStorage.getItem('token') && <Button contained className={classes.register} onClick={this.openDialog.bind(this)}>
                    Register
                  </Button>}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Hidden smDown>
                <Grid container direction="row">
                  <img src={courseLandingPageimg} />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid container direction="row" justify="flex-end">
                  <img src={courseLandingPageimg} className={classes.titleImg} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>  <br />
            <br />
            <br />
          </Hidden>
          <br />
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={12} className="w-full">
              <ScrollMenu
                menuClass={classes.coursesCat}
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                //@ts-ignore
                data={this.state.categories && this.state.categories.length > 0 && this.state.categories.map((item: any, index: any) =>

                  <React.Fragment key={item?.id}>
                    <Hidden smDown>
                      <Button key={item.id}
                        className={this.state.selectedChip && localStorage.getItem("selectedChip") == index ? `${classes.selectedBtn}` : `${classes.catBtn}`}
                        color="primary"
                        onClick={() => this.handleChipsClick(index, item.id)}
                        variant="outlined">
                        <Typography variant="subtitle2" className={classes.btnTypo}>{item?.attributes?.name}</Typography>
                      </Button>
                    </Hidden>
                    <Hidden mdUp>
                      <Button key={item.id}
                        className={this.state.selectedChip && localStorage.getItem("selectedChip") == index ? `${classes.selectedSmallBtn}` : `${classes.catSmallBtn}`}
                        color="primary"
                        onClick={() => this.handleChipsClick(index, item.id)}
                        variant="outlined">
                        <Typography variant="subtitle2" className={classes.btnSmallTypo}>{item?.attributes?.name}</Typography>
                      </Button>
                    </Hidden>
                  </React.Fragment>
                )}
              />
            </Grid>
          </Grid>
          <br />
          <Hidden smDown> <br />
            <br />
            <br />
          </Hidden>
          <CourseHeadingViewAll {...{
            classes,
            props: this.props,
            categoryId: this.state.categoryId,
            courseList: this.state.fascinatingCourseList,
            heading: "Find out what fascinates you most",
            stateInfo: { is_popular: true }
          }} />
          <br />
          <Hidden smDown> <br />
            <br />
            <br /></Hidden>
          <Grid container className={classes.paddingExamArVi}>
            <Grid item xs={12} className="w-full">
              <Hidden smDown>
                <CoursesCard
                  isSmUp={false}
                  courseList={this.state.fascinatingCourseList}
                />
              </Hidden>
              <Hidden mdUp>
                <CoursesCard
                  isSmUp={true}
                  courseList={this.state.fascinatingCourseList}
                />
              </Hidden>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <br />
          <br />
          <Hidden smDown>
            <br />
            <br />
            <br />
          </Hidden>
          <CourseHeadingViewAll {...{
            classes,
            props: this.props,
            categoryId: this.state.categoryId,
            courseList: this.state.trendingCourseList,
            heading: "Trending Courses",
            stateInfo: { is_trending: true }
          }} />
          <br />
          <br />
          <Grid container className={classes.paddingExamArVi}>
            <Grid item xs={12} className="w-full">
              <Hidden smDown>
                <CoursesCard
                  isSmUp={false}
                  courseList={this.state.trendingCourseList}
                />
              </Hidden>
              <Hidden mdUp>
                <CoursesCard
                  isSmUp={true}
                  courseList={this.state.trendingCourseList}
                />
              </Hidden>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <br />
          <br />
          <Hidden smDown> <br />
            <br />
            <br /></Hidden>
          <CourseHeadingViewAll {...{
            classes,
            props: this.props,
            categoryId: this.state.categoryId,
            courseList: this.state.newCourseList,
            heading: "New Courses",
            stateInfo: { new_courses: true }
          }} />
          <br />
          <br />
          <Grid container className={classes.paddingExamArVi}>
            <Grid item xs={12} className="w-full">
              <Hidden smDown>
                <CoursesCard
                  isSmUp={false}
                  courseList={this.state.newCourseList}
                />

              </Hidden>
              <Hidden mdUp>
                <CoursesCard
                  isSmUp={true}
                  courseList={this.state.newCourseList}
                />

              </Hidden>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <br />
          <br />
          <Hidden smDown>  <br />
            <br />
            <br />
          </Hidden>

        </Container>
        <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
        <Grid container>
          <img src={coursesbottom} className={classes.coursesBottom} />
        </Grid>
      </>
    );
  }
}

// @ts-ignore
const CoursesPageWebWithRouter = withRouter(CoursesPageWeb);
const CoursesPageWebWithLoader = withLoader(CoursesPageWebWithRouter);
const CoursesPageWebToast = withSnackbar(CoursesPageWebWithLoader);
const CoursesPageWebWithStyle = withStyles((theme) =>
  createStyles({
    btnSmallTypo: {
      whiteSpace: 'pre-wrap',
      width: '4rem',
      textAlign: 'center',
      fontSize: '9px',
      fontWeight: 100,
      lineHeight: '1'
    },
    catSmallBtn: {
      border: '1.5px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '4.5rem',
      height: '1.8rem',
      marginRight: '.2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      padding: '0.5rem !important'
    },
    selectedSmallBtn: {
      border: '1.5px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '.2rem',
      width: '4.5rem',
      height: '1.8rem',
      padding: '0.5rem !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    paddingExamArVi: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      }
    },
    btnTypo: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflow: 'hidden'
    },
    catBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '173px',
      height: '3rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    colDiv: {
      width: '12rem',
      height: '6rem',
      position: 'absolute',
      textAlign: 'center',
      top: '26rem',
      borderRadius: '.5rem',
      background: 'white',
      right: '30rem'
    },
    greenDiv: {
      left: '5rem',
      position: 'relative',
      height: '3rem',
      width: '6rem',
      backgroundColor: '#ecd551',
      borderRadius: '.5rem',
      textAlign: 'center',
      padding: '.3rem'
    },
    whiteDiv: {
      height: '4rem',
      width: '7rem',
      textAlign: 'center',
      position: 'relative',
      right: '4rem'
    },
    certified: {
      height: '2rem',
      margin: 'auto'
    },
    title: {
      fontFamily: 'Montserrat-Regular'
    },
    titleImg: {
      width: 'fit-content'
    },
    titleBlock: {
      display: 'inline-block'
    },
    titleHeader: {
      marginTop: '4rem'
    },
    register: {
      marginTop: '1rem',
      height: '2rem',
      width: '7rem',
      borderRadius: '9px',
      color: '#ffffff',
      backgroundColor: '#f48b1b',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    selectedBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      width: '173px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    coursesCat: {
      justifyContent: 'center'
    },
    categoryHeading: {
      paddingLeft: '2rem',
      [theme.breakpoints.down("md")]: {
        paddingLeft: "0rem"
      }
    }
  })
)((CoursesPageWebToast))
export default withTranslation()(CoursesPageWebWithStyle);