//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, MenuItem, Button, createStyles, Typography, TextField
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { Formik, Form, } from "formik";
import { withTranslation } from 'react-i18next';
import withLoader from "../../../../components/src/withLoader.web";
import { withSnackbar } from "../../../../components/src/toast.web";
import { GovtExamvalidationSchema } from "../UserBasicProfile.web.validation";

const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);

const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);
export class Govt extends React.Component {
  render() {
    localStorage.setItem('currentStep', 'govt job');
    const { classes, activeStep, getSteps, specialization, casteCategory, educationLevel, } = this.props;
    return (
      <Grid item xs={12} >
        <Grid container direction="row" alignItems="center" justify="flex-start" className={classes.heading}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.headTxt}>Govt. Exam</Typography>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            selectEducationLevel: this.props.profileDetail?.govt_job_details?.data?.attributes?.higher_education_level?.data?.id || "none",
            specialization: this.props.profileDetail?.govt_job_details?.data?.attributes?.specialization?.id || "none",
            category: this.props.profileDetail?.govt_job_details?.data?.attributes?.caste_category || "none",
          }}
          validationSchema={GovtExamvalidationSchema}
          // @ts-ignore
          onSubmit={(values: any, { setSubmitting }) => {
            this.props.updateGovtDetail(values);
            setSubmitting(false)
          }}
        >
          {(formikProps) => {
            const {
              values,
              isValid,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
              isSubmitting,
            } = formikProps;
            return (
              // @ts-ignore
              <Form autoComplete='off' onSubmit={handleSubmit} >
                <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Highest Education level*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="selectEducationLevel"
                              defaultValue="none"
                              style={{ width: '20rem' }}
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.selectEducationLevel && errors.selectEducationLevel ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.selectEducationLevel && errors.selectEducationLevel ? errors.selectEducationLevel : ""
                              }
                              value={values.selectEducationLevel}
                              select
                            >
                              <MyMenuItem disabled value="none">
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Select Education Level*
                                </Typography>
                              </MyMenuItem>
                              {educationLevel && educationLevel.map((option) => (
                                <MyMenuItem key={option.id} value={option.id}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Specialization</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="specialization"
                              style={{ width: '20rem' }}
                              placeholder="Select Specialization"
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.specialization && errors.specialization ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.specialization && errors.specialization ? errors.specialization : ""
                              }
                              value={values.specialization}
                              select
                            >
                              <MyMenuItem key={""} value="none" disabled>
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Choose Specialization
                                </Typography>
                              </MyMenuItem>
                              {specialization && specialization.map((option) => (
                                <MyMenuItem key={option.id} value={option.id}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Select Category</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="category"
                              style={{ width: '20rem' }}
                              placeholder="Select Category"
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.category && errors.category ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.category && errors.category ? errors.category : ""
                              }
                              value={values.category}
                              select
                            >
                              <MyMenuItem key={""} value="none" disabled>
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Choose Category
                                </Typography>
                              </MyMenuItem>
                              {casteCategory && casteCategory.map((option) => (
                                <MyMenuItem key={option} value={option}>
                                  {option}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                          </Grid>
                          <Grid item style={{ width: '20rem' }}>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="row" alignItems="center" justify="space-evenly">
                      <Button type="submit" disabled={isSubmitting || !isValid || values.selectEducationLevel === "none"}
                        className={classes.nextBtn}>
                        {activeStep === getSteps.length - 1 ? <Typography variant="h3">Finish</Typography> : <Typography variant="h3">Next</Typography>}
                      </Button>
                      <Button
                        onClick={this.props.handleReset} className={classes.cancelBtn}>
                        <Typography variant="h3">Cancel</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    )
  }
}
//@ts-ignore
const GovtWebWithRouter = withRouter(Govt);
const GovtWebWithLoader = withLoader(GovtWebWithRouter);
const GovtWebToast = withSnackbar(GovtWebWithLoader);
const GovtWebWithStyle = withStyles((theme) =>
  createStyles({
    selectDisColor: {
      color: 'grey'
    },
    heading: {
      boxShadow: '0 8px 12px 0 rgb(213 210 210 / 16%)',
      border: 'solid 1px #e8e2e2',
      borderRadius: '7px',
      height: '5rem'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    txtHead: {
      color: '#4f4f4f',
      paddingBottom: '.5rem'
    },
    headTxt: {
      color: '#f48b1b',
      marginLeft: '1.5rem'
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      width: '120px',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#000000'
      }
    },
    txtProps: {
      width: '18.5rem',
      color: '#7764db',
      fontWeight: 600
    },
    helperTxt: {
      margin: '0px',
      textAlign: 'left',
      color: '#f9593a',
      fontWeight: 'bold',
      marginTop: '.5rem'
    },
    persInfo: {
      marginTop: '20px'
    }
  })
)(GovtWebToast);
export default withTranslation()(GovtWebWithStyle);