//@ts-ignore
//@ts-nocheck
import React from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import { Icon } from "react-native-elements";
import ThemeColorContext from "./themeColorManagement/themeColorContext";
import { widthPercentageToDP as WIDTH, heightPercentageToDP as HEIGHT } from "react-native-responsive-screen";
import { scale } from '../../framework/src/Helpers';

interface myProps {
  onPress: any;
  doCustom?: boolean;
  customTitle?: any;
  searchText?: string;
  testID?: string;
}

export default function SearchUI(props: myProps) {
  return (
    <ThemeColorContext.Consumer>
      {(themeColorContext) => (
        <>
          <TouchableOpacity
            testID={props?.testID}
            style={{ paddingVertical: 1, justifyContent: "center", alignItems: 'center' }}
            onPress={props.onPress}>
            <View style={[styles.searchView, {
              backgroundColor: themeColorContext.themeColor == "dark"
                ? "#242424"
                : "#fcfcfe",
              borderColor: themeColorContext.themeColor == "dark"
                ? "rgba(112,112,112,0.2)"
                : "rgb(112,112,112)",
            }]} >
              <Text
                style={[styles.textLable, {
                  color: themeColorContext.themeColor == "dark"
                    ? "rgba(255,255,255,0.38)"
                    : "#aaa9ab",
                }
                ]}
                numberOfLines={1}>{props.searchText ? props.searchText : props.doCustom ? props.customTitle : 'Looking for something specific?'}</Text>
              <Icon
                name="search"
                type="fontisto"
                size={scale(20)}
                color={themeColorContext.themeColor == "dark" ? "#fff" : "#000"}
              />
            </View>
          </TouchableOpacity>
        </>
      )}
    </ThemeColorContext.Consumer>
  );
}


const styles = StyleSheet.create({
  swiperViewStyle: {
    paddingHorizontal: 14,
    paddingTop: 14, paddingBottom: 25
  },

  swiperImgStyle: {
    width: "100%",
    height: "100%",
    borderRadius: 12,
  },

  searchView: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: WIDTH(90),
    alignItems: "center",
    borderRadius: 51,
    borderWidth: 1.5,
    paddingVertical: HEIGHT(1.2),
    paddingHorizontal: HEIGHT(2),
    overflow: 'hidden',
    borderColor: 'rgb(112, 112, 112)'
  },

  textLable: {
    fontSize: scale(14),
    fontFamily: 'Roboto',
    width: '90%'
  }
});