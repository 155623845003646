//@ts-nocheck
import React from "react";
import {
  withStyles, IconButton, Menu, MenuItem, ListSubheader, Box, Chip, Card, CardContent,
  Container, Grid, Typography, Button, TextField, Dialog, Divider,
  DialogContent, createStyles, List, ListItemText, ListItem, ListItemSecondaryAction
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import DiscussionForumController, { Props } from "./DiscussionForumController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { DiscussionforumSchema, DiscussionforumReportSchema } from "./Discussionforum.web.validation";
import { userImage } from "./assets";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);
const ReportMenuItem = withStyles({
  root: { backgroundColor: '#ffffff', color: '#000000 !important' },
  MuiMenuItem: {
    root: { backgroundColor: '#ffffff', color: '#000000 !important', }
  },
})(MenuItem);
export class DiscussionForum extends DiscussionForumController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    const { searchInfo } = this.props;
    window.scrollTo(0, 0);
    this.getTagList();
    this.getCategories();
    this.getPopularQuestions(searchInfo);
    this.getTrendingQuestions(searchInfo);
    if (localStorage.getItem("token")) { this.getRecommendedQuestions(searchInfo); }
    this.getLikes();
    TimeAgo.addDefaultLocale(en)
  }
  async componentWillReceiveProps(nextProps: any) {
    if (nextProps.searchInfo !== this.props.searchInfo) {
      this.getPopularQuestions(nextProps.searchInfo);
      this.getTrendingQuestions(nextProps.searchInfo);
      this.getRecommendedQuestions(nextProps.searchInfo);
    }
  }
  openPopularMenu = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget, problemBlock: false });
  }
  handlePopularMenuClose = () => {
    this.setState({ anchorEl: null, openReportDialog: true });
  };
  openTrendingMenu = (event: any) => {
    this.setState({ anchorTrendingEl: event?.currentTarget, problemBlock: false });
  }
  handleTrendingMenuClose = () => {
    this.setState({ anchorTrendingEl: null, openReportDialog: true });
  };
  openRecommendedMenu = (event: any) => {
    this.setState({ anchorRecommendedEl: event?.currentTarget, problemBlock: false });
  }
  handleRecommendedMenuClose = () => {
    this.setState({ anchorRecommendedEl: null, openReportDialog: true });
  };
  renderSelectGroup = (t) => {
    const items = t?.attributes?.sub_categories.map(p => {
      return (
        <MyMenuItem key={p.id} value={p.id}>{p?.name}</MyMenuItem>
      );
    });
    return [<ListSubheader>{t.name}</ListSubheader>, items];
  };
  addTag = () => {
    this.setState(prevState => ({ showTagSearch: !prevState.showTagSearch }));
  }
  saveAsDraft = (values: any, draft: any) => {
    this.setState({ "question": values }, () => this.postQuestion(draft))
  }
  openFile = (evt: any) => {
    document.getElementById("preview-1").click()
  }
  chatBubbleUi = (e, classes) => {
    return (<Grid container direction="row" alignItems="center">
      <ChatBubbleIcon color="light" className={classes.dfIcon}
        data-testId="chatBubbleUi"
        onClick={() => {
          if (localStorage.getItem('token')) {
            this.props.history.push("/dashboard/detailview", { postDetails: e })
          }
          else { this.openDialog(true) }
        }} />
      <Typography variant="body1">{e?.attributes?.comments_count}</Typography>
    </Grid>)
  }
  redEyeIconUi = (e, classes) => {
    return (<Grid container direction="row" alignItems="center">
      <RemoveRedEyeIcon data-testId="redEyeIconUi" onClick={() => {
        if (localStorage.getItem('token')) {
          this.props.history.push("/dashboard/detailview", { postDetails: e })
        }
        else { this.openDialog(true) }
      }} color="light" className={classes.dfIcon} />
      <Typography variant="body1">{e?.attributes?.view}</Typography>
    </Grid>)
  }
  descUi = (e, classes) => {
    return (<Grid container direction="row">
      {e?.attributes?.description && e?.attributes?.description.length > 300 ? <Typography variant="subtitle2"
        onClick={() => this.props.history.push("/dashboard/detailview", { postDetails: e })}
        className={classes.desc}>{e?.attributes?.description} ...</Typography> :
        <Typography variant="subtitle2" onClick={() => this.props.history.push("/dashboard/detailview", { postDetails: e })}
          className={classes.desc}>{e?.attributes?.description}</Typography>
      }
    </Grid>)
  }
  headerUi = (e, classes, anchorEl, module) => {
    return (<Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
      <Grid item xs={10}>
        <Grid container direction="row" alignItems="center" justify="flex-start">
          <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
            <img src={e?.attributes?.user_image ? e?.attributes?.user_image : userImage} className={classes.userImg} />
          </Grid>
          <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
            <Grid container direction="column" >
              <Typography variant="caption">{e?.attributes?.account?.first_name}</Typography>
              <Typography variant="body1"><ReactTimeAgo date={e?.attributes?.created_at} locale="en-US" /></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {this.reportUi(anchorEl, classes, module)}
      </Grid>
    </Grid>)
  }
  popularQuesUi = (getPopularQuestions, classes, anchorEl, likeList) => {
    return (
      getPopularQuestions.length > 0 ? getPopularQuestions.map((e: any, i: any) => {
        if (i < 2) {
          return (<>
            <Card className={classes.rootCard}>
              <CardContent>
                {this.headerUi(e, classes, anchorEl, 'popular')}
                <br />
                <Grid container direction="row">
                  <Typography variant="h3">{e?.attributes?.title}</Typography>
                  <Grid item>
                  </Grid>
                </Grid>
                <br />
                {this.descUi(e, classes)}
                {e?.attributes?.tags && e?.attributes?.tags.length > 0 && <> <br />
                  <Grid container direction="row" justify="flex-start">
                    {e?.attributes?.tags && e?.attributes?.tags.map((el, index) => <Button key={index} variant="contained" className={classes.tagChips}>
                      <Typography variant="body1">{el?.name}</Typography></Button>)}
                  </Grid></>}
                <br />
                <Grid container direction="row" justify="space-between">
                  {this.communicationIcon(e, classes, likeList, 'Popular Discussions')}
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
          </>
          )
        }
      })
        :
        <Typography variant="body2" className="text-center">No Data available</Typography>
    )
  }
  trendingQuesUi = (getTrendingQuestions, classes, anchorTrendingEl, likeList) => {
    return (
      getTrendingQuestions.length > 0 ? getTrendingQuestions.map((e: any, i: any) => {
        if (i < 2) {
          return (<>
            <Card className={classes.rootCard} >
              <CardContent>
                {this.headerUi(e, classes, anchorTrendingEl, 'trending')}
                <br />
                <Grid container direction="row">
                  <Typography variant="h3">{e?.attributes?.title}</Typography>
                  <Grid item>
                  </Grid>
                </Grid>
                <br />
                {this.descUi(e, classes)}
                {e?.attributes?.tags && e?.attributes?.tags.length > 0 && <> <br />
                  <><Grid container direction="row" justify="flex-start">
                    {e?.attributes?.tags && e?.attributes?.tags.map((el, index) => <Button key={index} variant="contained" className={classes.tagChips}><Typography variant="body1">{el?.name}</Typography></Button>)}
                  </Grid></></>}
                <br />
                <>
                  <Grid container direction="row" justify="space-between">
                    {this.communicationIcon(e, classes, likeList, 'Trending Discussions')}
                    <Grid item xs={2}>
                    </Grid>
                  </Grid>
                </>
              </CardContent>
            </Card>
            <br />
          </>
          )
        }
      }) :
        <Typography variant="body2" className="text-center">No Data available</Typography>
    )
  }
  likeUiBlock = (likeList, e, classes, heading) => {
    return (<Grid container direction="row" alignItems="center">
      {localStorage.getItem('token') &&
        likeList.findIndex((et) => { return et?.attributes?.likeable_id === e?.attributes.id }) > -1
        && e?.attributes?.is_like
        ? <>
          <ThumbUpIcon color="light" key={e?.id} onClick={() => this.handleDisLike(e?.attributes?.id, false, heading)} className={classes.dfIcon} />
          <Typography variant="body1">{e?.attributes?.likes_count}</Typography>
        </>
        :
        <>
          <ThumbUpAltOutlinedIcon color="light" key={e.id} data-testId="handleLike" onClick={() => this.handleLike(e?.attributes?.id, true, heading)} className={classes.dfIcon} />
          <Typography variant="body1">{e?.attributes?.likes_count}</Typography>
        </>
      }
    </Grid>)
  }
  reportUi = (anchorModuleEl, classes, module) => {
    let openMenu;
    let handleMenuClose;
    switch (module) {
      case 'popular': openMenu = this.openPopularMenu;
        handleMenuClose = this.handlePopularMenuClose;
        break;
      case 'trending': openMenu = this.openTrendingMenu;
        handleMenuClose = this.handleTrendingMenuClose;
        break;
      case 'recommended': openMenu = this.openRecommendedMenu;
        handleMenuClose = this.handleRecommendedMenuClose;
        break;
    }
    return (<Grid container direction="row" alignItems="center" justify="flex-end">
      <MoreVertIcon color="light" data-testId="openMenu" onClick={(e) => openMenu(e)} className={classes.addIcon} />
      <Menu
        classes={{ paper: classes.reportMenu }}
        id="simple-menu"
        anchorEl={anchorModuleEl}
        keepMounted
        open={Boolean(anchorModuleEl)}
        onClose={this.handleMenuClose}
      >
        <ReportMenuItem className={classes.menuColor} data-testId="handleMenuClose" onClick={handleMenuClose}>Report</ReportMenuItem>
      </Menu>
    </Grid>)
  }
  communicationIcon = (e, classes, likeList, module) => {
    return (<Grid item xs={8}>
      <Grid container direction="row" >
        <Grid item xs={2}>
          {this.redEyeIconUi(e, classes)}
        </Grid>
        <Grid item xs={2}>
          {this.chatBubbleUi(e, classes)}
        </Grid>
        <Grid item xs={2}>
          {this.likeUiBlock(likeList, e, classes, module)}
        </Grid>
      </Grid>
    </Grid>)
  }
  recommendedQuesUi = (getRecommendedQuestions, classes, anchorRecommendedEl, likeList) => {
    return (
      getRecommendedQuestions.length > 0 ? getRecommendedQuestions.map((e: any, i: any) => {
        if (i < 2) {
          return (<>
            <Card className={classes.rootCard} >
              <CardContent>
                {this.headerUi(e, classes, anchorRecommendedEl, 'recommended')}
                <br />
                <Grid container direction="row">
                  <Typography variant="h3">{e?.attributes?.title}</Typography>
                  <Grid item>
                  </Grid>
                </Grid>
                <br />
                {this.descUi(e, classes)}
                {e?.attributes?.tags.length > 0 && <> <br />
                  <Grid container direction="row" justify="flex-start">
                    {e?.attributes?.tags && e?.attributes?.tags.map((el, index) => <Button key={index} variant="contained" className={classes.tagChips}><Typography variant="body1">{el?.name}</Typography></Button>)}
                  </Grid></>}
                <br />
                <Grid container direction="row" justify="space-between">
                  {this.communicationIcon(e, classes, likeList, 'Recommended Discussions')}
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br />
          </>
          )
        }
      })
        :
        <Typography variant="body2" className="text-center">No Data available</Typography>
    )
  }
  buttonUiLogic = (values, isSubmitting, isValid, classes) => {
    return (<Grid container direction="row" justify="flex-end">
      <Grid item xs={3}>
        {!localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.cancelBtn} >Save as Draft </Button>}
      </Grid>
      <Grid item xs={3}>
        {!localStorage.getItem("token") && <Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.publishBtn} >Publish</Button>}
      </Grid>
      <Grid item xs={3}>
        {localStorage.getItem("token") && <Button variant="contained" name="draft" disabled={!isValid || values.title == "" || values.categories === "" || values.description === "" || values.description.length < 100} data-testId="saveAsDraft" onClick={() => this.saveAsDraft(values, "draft")} className={classes.cancelBtn} >Save as Draft </Button>}
      </Grid>
      <Grid item xs={3}>
        {localStorage.getItem("token") && <Button variant="contained" name="publish" disabled={!isValid} type="submit" className={classes.publishBtn} >Publish</Button>}
      </Grid>
    </Grid>)
  }
  discussionFormUi = (handleSubmit, ...args) => {
    const [handleChange, handleBlur, classes, touched, errors,
      values, categories, tagsList, showTagSearch, setFieldValue, isSubmitting, isValid] = args;
    return (< Form autoComplete="off" onSubmit={handleSubmit} >
      <DialogContent>
        <Grid container direction="row" spacing={2} justify="center">
          <Grid item xs={12}>
            <PinkTextField
              variant="outlined"
              name="categories"
              label="Choose Categories"
              defaultValue="none"
              onChange={handleChange}
              placeholder="choose categories"
              SelectProps={{
                MenuProps: { classes: { paper: classes.selectBoard } }
              }}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              error={touched.categories && errors.categories ? true : false}
              InputProps={{ classes: { input: classes.txtProps } }}
              InputLabelProps={{ style: { color: '#a9a9a9', fontSize: '14px' } }}
              helperText={
                touched.categories && errors.categories ? errors.categories : ""
              }
              value={values.categories}
              select
            >
              <>
                <MyMenuItem disabled value="none">
                  <Typography variant="subtitle2" className={classes.selectDisColor}>
                    Choose Categories*
                  </Typography>
                </MyMenuItem>
              </>
              {categories && categories.map(p => this.renderSelectGroup(p))}
            </PinkTextField>
          </Grid>
          <Grid item xs={12}>
            <>
              <PinkTextField
                variant="outlined"
                name="title"
                defaultValue="none"
                onChange={handleChange}
                FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                onBlur={handleBlur}
                error={touched.title && errors.title ? true : false}
                InputProps={{ classes: { input: classes.txtProps } }}
                helperText={
                  touched.title && errors.title ? errors.title : ""
                }
                value={values.title}
                placeholder="Enter Title"
              />
            </>
          </Grid>
          <Grid item xs={12}>
            <PinkTextField
              variant="outlined"
              name="description"
              multiline
              rows={10}
              onChange={handleChange}
              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
              onBlur={handleBlur}
              error={touched.description && errors.description ? true : false}
              InputProps={{ classes: { input: classes.txtProps } }}
              helperText={
                touched.description && errors.description ? errors.description : ""
              }
              value={values.description}
              placeholder="Enter Description"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="text" data-testId="addTag" onClick={this.addTag}><Typography variant="body2" className={classes.addtagTxt}>Add tag +</Typography></Button>
            {showTagSearch &&
              <Box>
                <Autocomplete
                  multiple
                  freeSolo
                  name="tagVal"
                  id="tagVal"
                  options={tagsList}
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => {
                    setFieldValue("tagVals", [...new Set([].concat(values.tagVals, value))]);
                    setFieldValue("tagVal", []);
                  }}
                  value={values?.tagVal.map(e => e)}
                  renderTags={() => null}
                  renderInput={params => {
                    return (<PinkTextField fullWidth {...params} name="tagVal" variant="outlined" placeholder="Search Tags" />)
                  }}
                />
                <Box
                  mt={3}
                  sx={{
                    '& > :not(:last-child)': { mr: 1 },
                    '& > *': { mr: 1 },
                  }}>
                  {values?.tagVals && values?.tagVals.map((v, i) => (
                    <Chip className={classes.chip} key={i} label={v} onDelete={() => {
                      setFieldValue("tagVals", values.tagVals.filter(x => x !== v));
                    }} />
                  ))}
                </Box>
              </Box>}
          </Grid>
          <Grid item xs={12}>
            {this.buttonUiLogic(values, isSubmitting, isValid, classes)}
          </Grid>
        </Grid>
        {/* </Grid> */}
      </DialogContent>
    </Form>)
  }
  recommendedDiscussionUi = (getRecommendedQuestions, classes, anchorRecommendedEl, likeList) => {
    return (
      getRecommendedQuestions.length > 0 &&
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Typography align="center" variant="h4" className={classes.txtColor}>Recommended Discussions</Typography>
              {this.viewAllUi(getRecommendedQuestions, classes, 'Recommended Discussions')}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" >
          <Grid item xs={12}>
            {this.recommendedQuesUi(getRecommendedQuestions, classes, anchorRecommendedEl, likeList)}
          </Grid>
        </Grid>
      </>
    )
  }
  rightContainerUi = (classes: any) => {
    return (<Card className={classes.rightContainer}>
      <CardContent>
        <Grid container>
          <StarBorderIcon color="light" className={classes.addIcon} />
          <Typography variant='body2'>Must-read Posts</Typography>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container direction="row" spacing={2}>
          <Grid item style={{ display: "inline-flex" }}>
            <FiberManualRecordIcon color="light" className={classes.dotIcon} />
            <Typography variant="body1" className={classes.rightBlueTxt}> Please read rules before you start working</Typography>
          </Grid>
          <br />
          <Grid item style={{ display: "inline-flex" }}>
            <FiberManualRecordIcon color="light" className={classes.dotIcon} />
            <Typography variant="body1" className={classes.rightBlueTxt}> Vision & Strategy of Alemhelp</Typography>
          </Grid>
          <br />
          <br />
        </Grid>
        <br />
        <Grid container>
          <InsertLinkIcon color="light" className={classes.addIcon} />
          <Typography variant='body2'>Featured Links</Typography>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid container direction="column" spacing={1}>
          <Grid item style={{ display: "inline-flex" }}>
            <FiberManualRecordIcon color="light" className={classes.dotIcon} />
            <Typography variant="body1" className={classes.rightBlueTxt}> Alemhelp source-code on GitHub</Typography>
          </Grid>
          <br />
          <Grid item style={{ display: "inline-flex" }}>
            <FiberManualRecordIcon color="light" className={classes.dotIcon} />
            <Typography variant="body1" className={classes.rightBlueTxt}>Golang best-practices</Typography>
          </Grid>
          <br />
          <Grid item style={{ display: "inline-flex" }}>
            <FiberManualRecordIcon color="light" className={classes.dotIcon} />
            <Typography variant="body1" className={classes.rightBlueTxt}>Alem.School dashboard</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>)
  }
  viewAllUi = (getQues, classes, heading) => {
    return (
      getQues && getQues.length > 2 &&
      <Button className={classes.viewAll} variant="contained"
        onClick={() => this.props.history.push("/dashboard/viewallpost", { heading: heading })}>
        <Typography variant="body2" className="font-bold">View all</Typography>
      </Button>
    )
  }
  trendingDiscussionUi = (getTrendingQuestions, classes, anchorTrendingEl, likeList) => {
    return (
      getTrendingQuestions.length > 0 &&
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Typography align="center" variant="h4" className={classes.txtColor}>Trending Discussions</Typography>
              {this.viewAllUi(getTrendingQuestions, classes, 'Trending Discussions')}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" >
          <Grid item xs={12}>
            {this.trendingQuesUi(getTrendingQuestions, classes, anchorTrendingEl, likeList)}
          </Grid>
        </Grid>
      </>
    )
  }
  popularDiscussionUi = (getPopularQuestions, classes, anchorEl, likeList) => {
    return (
      getPopularQuestions.length > 0 &&
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Typography align="center" variant="h4" className={classes.txtColor}>Popular Discussions</Typography>
              {this.viewAllUi(getPopularQuestions, classes, 'Popular Discussions')}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="row" >
          <Grid item xs={12}>
            {this.popularQuesUi(getPopularQuestions, classes, anchorEl, likeList)}
          </Grid>
        </Grid>
      </>
    )
  }
  probBlock1 = (problemType, classes) => {
    return (
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginBottom: '.5rem' }} >
            Please select a problem
          </Typography>
          <Typography variant="subtitle2" >
            If someone is in immediate danger, get help before reporting to Us. Don't wait.
          </Typography>
          <List dense={false}>
            {problemType.map((prob: any) =>
              <ListItem key={prob} className={classes.problemItem}>
                <ListItemText
                  data-testId="selectProblemType1"
                  onClick={() => this.selectProblemType(prob)}
                  secondary={prob}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" data-testId="selectProblemType2" onClick={() => this.selectProblemType(prob)}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    )
  }
  probBlock2 = (classes, ...args) => {
    const [values, touched, errors, handleBlur, handleChange, isSubmitting, isValid, setFieldValue] = args;
    return (
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography variant="body2" >
            Shared Details {values.categories}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PinkTextField
            variant="outlined"
            name="categories"
            defaultValue="none"
            onChange={handleChange}
            FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
            onBlur={handleBlur}
            error={touched.categories && errors.categories ? true : false}
            InputProps={{ classes: { input: classes.txtProps }, readOnly: true, }}
            helperText={
              touched.categories && errors.categories ? errors.categories : ""
            }
            value={values.categories}
            placeholder="Enter type of report"
          />
        </Grid>
        <Grid item xs={12}>
          <PinkTextField
            variant="outlined"
            name="title"
            defaultValue="none"
            onChange={handleChange}
            FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
            onBlur={handleBlur}
            error={touched.title && errors.title ? true : false}
            InputProps={{ classes: { input: classes.txtProps } }}
            helperText={
              touched.title && errors.title ? errors.title : ""
            }
            value={values.title}
            placeholder="Enter Title"
          />
        </Grid>
        <Grid item xs={12}>
          <PinkTextField
            variant="outlined"
            name="description"
            multiline
            rows={10}
            onChange={handleChange}
            FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
            onBlur={handleBlur}
            InputProps={{ classes: { input: classes.txtProps } }}
            helperText={
              touched.description && errors.description ? errors.description : ""
            }
            value={values.description}
            placeholder="Enter Description"
          />
        </Grid>
        <Grid item xs={12}>
          <input
            style={{ display: 'none' }}
            accept="image/*"
            id={`preview-1`}
            type="file"
            onChange={(e, value) => { setFieldValue("tagVal", [...new Set(values.tagVal.concat(e.target.files))]); }}
          />
          <Button variant="text" onClick={(event) => this.openFile(event)}>
            <Typography variant="body2" className={classes.addtagTxt}   >+ Add Screen Short
            </Typography></Button>
          {values.tagVal.length > 0 &&
            <Box sx={{ width: 500 }}>
              <Box
                mt={3}
                sx={{
                  '& > :not(:last-child)': { mr: 1 },
                  '& > *': { mr: 1 },
                }}>
                {values?.tagVal && values?.tagVal.map((v, i) => (
                  <Chip className={classes.chip} key={i} label={v[0].name} onDelete={() => {
                    setFieldValue("tagVal", values.tagVal.filter(x => x[0].name !== v[0].name));
                  }} />
                ))}
              </Box>
            </Box>}
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="flex-end">
            <Grid item xs={3}>
              {<Button variant="contained" type="submit"
                onClick={() => this.setState({ openReportDialog: false, problemBlock: false, selectedProblem: '' })}
                className={classes.cancelBtn} >Cancel </Button>}
            </Grid>
            <Grid item xs={3}>
              {<Button variant="contained" disabled={isSubmitting || !isValid} type="submit" className={classes.publishBtn} >Report Now</Button>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  render() {
    const { classes } = this.props;
    const { anchorEl, anchorTrendingEl, anchorRecommendedEl, getPopularQuestions, getRecommendedQuestions,
      getTrendingQuestions, likeList, categories, tagsList, showTagSearch, problemBlock, problemType } = this.state;
    return (
      <>
        <Container maxWidth="xl" style={{ paddingLeft: '4rem', paddingRight: '4rem' }} >
          <Grid item xs={12} container direction="row" justify="space-between">
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid item xs={12} className={classes.newThread}>
                  <Grid container direction="row" alignItems="center" justify="space-between" style={{ height: '3rem' }} >
                    <Grid item xs={9} style={{ marginLeft: '.5rem' }}>
                      <Typography variant="caption">
                        Add a new Thread
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="createPost" className={classes.iconBtn} data-testId="openPostDialog" onClick={() => {
                        if (localStorage.getItem("token")) {
                          this.openPostDialog()
                        } else { this.openDialog(true) }
                      }}>
                        <AddCircleIcon color="secondary" className={classes.addIcon} />
                      </IconButton>
                      <Dialog open={this.state.openDiscussionDialog} data-testId="handleClose1" onClose={this.handleClose}
                        PaperProps={{
                          style: {
                            minHeight: "500px",
                            width: "720px",
                            backgroundColor: "#ffffff",
                            boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
                          },
                        }}>
                        <Formik
                          data-testId="createPost"
                          initialValues={{
                            title: "",
                            categories: "",
                            description: "",
                            tagVal: [],
                            tagVals: [],
                          }}
                          validationSchema={DiscussionforumSchema}
                          onSubmit={(values: any) => {
                            this.createPost(values);
                          }}
                        >
                          {(formikProps) => {
                            const {
                              values,
                              isValid,
                              errors,
                              touched,
                              handleSubmit,
                              handleBlur,
                              handleChange,
                              setFieldValue,
                              isSubmitting,
                            } = formikProps;
                            return (
                              this.discussionFormUi(handleSubmit, handleChange, handleBlur, classes, touched, errors,
                                values, categories, tagsList, showTagSearch, setFieldValue, isSubmitting, isValid)
                            );
                          }}
                        </Formik>
                      </Dialog>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {this.popularDiscussionUi(getPopularQuestions, classes, anchorEl, likeList)}
              <br />
              {this.trendingDiscussionUi(getTrendingQuestions, classes, anchorTrendingEl, likeList)}
              <br />
              {this.recommendedDiscussionUi(getRecommendedQuestions, classes, anchorRecommendedEl, likeList)}
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <Grid item xs={12}>
                {this.rightContainerUi(classes)}
              </Grid>
            </Grid>
          </Grid>
          <Dialog open={this.state.openReportDialog} data-testId="handleClose2" onClose={this.handleClose}
            PaperProps={{
              style: {
                minHeight: "500px",
                width: "720px",
                backgroundColor: "#ffffff",
                boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
              },
            }}>
            <Formik
              enableReinitialize={true}
              data-testId="createReport"
              initialValues={{
                title: "",
                categories: this.state.selectedProblem,
                description: "",
                tagVal: [],
                tagVals: [],
              }}
              validationSchema={DiscussionforumReportSchema}
              onSubmit={(values: any) => {
                this.createReport(values);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  isValid,
                  errors,
                  touched,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  isSubmitting,
                } = formikProps;
                return (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Container maxWidth='xl'>
                      <Grid container direction="row" className={classes.reportHead} justify="space-between" alignItems="center">
                        <Grid item></Grid>
                        <Grid item>
                          <Typography variant="h3" >
                            Report
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton aria-label="close" className={classes.closeButton} onClick={() => this.setState({ openReportDialog: false, problemBlock: false, selectedProblem: '' })}>
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Container>
                    <DialogContent>
                      {!problemBlock && this.probBlock1(problemType, classes)}
                      {problemBlock && this.probBlock2(classes, values, touched, errors, handleBlur, handleChange, isSubmitting, isValid, setFieldValue)}
                    </DialogContent>
                  </Form>);
              }}
            </Formik>
          </Dialog>
        </Container>
        {!localStorage.getItem("token") && <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />}
      </>
    );
  }
}
// @ts-ignore
const DiscussionForumWithRouter = withRouter(DiscussionForum);
const DiscussionForumWithLoader = withLoader(DiscussionForumWithRouter);
const DiscussionForumToast = withSnackbar(DiscussionForumWithLoader);
const DiscussionForumWithStyle = withStyles((theme) =>
  createStyles({
    reportMenu: {
      backgroundColor: 'white !important',
      borderRadius: '4px !important',
      boxShadow: '0px 1px 3px 0 rgba(0, 0, 0, 0.15) !important',
    },
    rootCard: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
    },
    userImg: {
      height: '2rem',
      width: '2.5rem',
      borderRadius: '50%'
    },
    desc: {
      wordBreak: 'break-all',
      cursor: 'pointer'
    },
    dotIcon: {
      color: '#1682fd',
      height: '1rem'
    },
    rightBlueTxt: {
      color: '#1682fd'
    },
    rightContainer: {
      padding: '1rem',
      marginTop: '3rem',
      borderRadius: '4.5px',
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #eaeaea',
      backgroundColor: '#fffefe'
    },
    chip: {
      height: '1.5rem',
      borderRadius: '0.5rem',
      background: '#f48b1b',
      color: '#ffffff',
      margin: '.2rem',
      "& .MuiChip-deleteIcon": {
        height: "15px"
      },
      "&:hover": {
        "& .MuiChip-deleteIcon": {
          height: "15px"
        }
      }
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    reportColor: { color: 'red' },
    txtColor: {
      color: ' #f48b1b'
    },
    addtagTxt: {
      color: '#7764db'
    },
    selectDisColor: { fontSize: '10px' },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#ffffff'
      }
    },
    viewAll: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      color: '#000000',
      '&:hover': {
        backgroundColor: 'transparent',
        color: "#000000",
        boxShadow: 'none',
      }
    },
    publishBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    addIcon: {
      height: '1.2rem'
    },
    dfIcon: {
      height: '1.2rem',
      fill: '#7764db'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    iconBtn: {
      backgroundColor: '#7764db',
      borderRadius: '10px',
      height: '2rem',
      width: '2rem',
      "&:hover, &.Mui-focusVisible": { backgroundColor: "#7764db" }
    },
    newThread: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#fffefe'
    },
    interactiveBtn: {
      height: '2rem',
      width: '2rem'
    },
    reportHead: {
      // padding: '11px 0px',
      borderBottom: '1px solid #e1e1e1'
    },
    problemItem: {
      paddingLeft: '0px',
    }
  })
)((DiscussionForumToast))
export default withTranslation()(DiscussionForumWithStyle);