
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  open: boolean;
  showTagSearch: boolean;
  openDiscussionDialog: boolean;
  tagVal: any[],
  question: any,
  dislikeActive: boolean,
  dislike: any,
  like: any,
  likeActive: boolean,
  getQuestions: any[],
  likeList: any[],
  count: number,
  start: number,
  hasMore: boolean,
  anchorEl: any,
  openDeleteDialog: boolean,
  unload: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class MyThreadsController extends BlockComponent<Props, S, SS> {
  getLikeListApiCallId: string = "";
  getQuestionsApiCallId: string = "";
  postLikeApiCallId: string = "";
  getInfiniteQuestionApiCallId: string = "";
  deleteQuestionApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      open: false,
      showTagSearch: false,
      openDiscussionDialog: false,
      tagVal: [],
      question: {},
      dislikeActive: false,
      dislike: 0,
      like: 0,
      count: 20,
      start: 1,
      likeActive: false,
      getQuestions: [],
      likeList: [],
      hasMore: false,
      anchorEl: null,
      openDeleteDialog: false,
      unload: false
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.getQuestionsApiCallId:
          this.quesApi(responseJson);
          break;
        case this.getInfiniteQuestionApiCallId:
          this.infiniteQues(responseJson);
          break;
        case this.postLikeApiCallId:
          this.postLike(responseJson);
          break;
        case this.getLikeListApiCallId:
          this.likeList(responseJson);
          break;
        case this.deleteQuestionApiCallId:
          this.deleteThreadApi(responseJson);
          break;
      }
    }
  }
  // Customizable Area Start
  deleteThreadApi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.message) {
      this.handleClose();
      this.props.openToastHandler("success", `My Thread Deleted Successfully`!);
      this.getQuestions();
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  likeList = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ likeList: responseJson?.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  postLike = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.getLikes();
      this.setState({ unload: true });
      if (this.state.count <= 20) {
        this.getQuestions();
      } else {
        this.getInfiniteQuestions();
      }
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  infiniteQues = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      const { unload } = this.state;
      if (!unload)
        this.props.hideLoader();
      if (responseJson?.data.length > 0) {
        this.setState({ hasMore: true })
      } else {
        this.setState({ hasMore: false })
      }
      this.setState({ getQuestions: this.state.getQuestions.concat(responseJson.data) });
    }
    else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
    }
  }
  quesApi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      const { unload } = this.state;
      if (!unload)
        this.props.hideLoader();
      if (responseJson?.data.length > 0) {
        this.setState({ hasMore: true })
      } else {
        this.setState({ hasMore: false })
      }
      this.setState({ getQuestions: responseJson.data });
    } else if (responseJson?.errors) {
      this.openToastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  openToastHandler = (responseJson: any) => {
    if (responseJson?.errors[0]?.token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
  }
  handleClose = () => {
    this.setState({ openDiscussionDialog: false, openDeleteDialog: false, anchorEl: null })
  }
  deleteThread = (id: number) => {
    const headers = { "token": localStorage.getItem("token") };
    const apiEndPoint = configJSON.postQuestion + `/${id}`;
    const methodType = configJSON.deleteAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
        this.getQuestions();
        this.getLikes();
      } else {
        this.setState({ open: val });
        this.props.history.push("/dashboard/discussionforum");
        localStorage.clear();
      }
    }
  }
  getQuestions = (searchInfo?: any) => {
    const { unload } = this.state;
    if (!unload)
      this.props.showLoader();
    this.setState({ hasMore: true })
    const { count } = this.state;
    this.setState({ start: 1 });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getQuestionsApiCallId = requestMessage.messageId;
    const search = searchInfo ? `&search=${searchInfo}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showMyThreadsApiEndPoint}?page=${this.state?.start}&per_page=${count}${search}`
    );
    const header = {
      "token": localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getInfiniteQuestions = () => {
    this.setState({ hasMore: true })
    this.props.showLoader();
    const { count, start } = this.state
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getInfiniteQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showMyThreadsApiEndPoint}?page=${start + 1}&per_page=${count}`
    );
    this.setState({ start: start + 1 })
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getLikes = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getLikeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLikePost
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDisLike = (id: any, unlike: any) => {
    this.handleLike(id, unlike);
  }
  handleLike = (id: any, like: any) => {
    const headers = {
      "token": localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    const apiEndPoint = configJSON.postLikesApiEndPoint;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postLikeApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    const data = {
      "like": {
        "likeable_type": "question",
        "likeable_id": id,
        "is_like": like
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}