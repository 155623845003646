//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    name: string;
    openToastHandler: any;
    // Customizable Area End
  };
interface Categories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}
interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
  cat_parent_id: string;
}
interface Category {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategories[];
  };
}
interface SubCategory {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
  };
}
interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
  cat_parent_id: string;
}
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  accountDetail: any;
  token: any;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  profileImgBase64: any;
  profileImgBase64Obj: any;
  userDetailList: any;
  completed: any;
  activeStep: number;
  activeLabel: string;
  skipped: any;
  selectedCategory: string | null;
  isDisable: boolean;
  selectedSubCategory: any[];
  categories: Category[];
  btnIndex: number;
  showSubCatArray: any[];
  getSteps: any[];
  profileDetail: any[];
  boards: any[];
  subjects: any[];
  standards: any[];
  colleges: any[];
  degree: any[];
  courses: any[];
  passingYear: '';
  specialization: any[];
  educationLevel: any[];
  selectEducationLevel: string;
  casteCategory: any[];
  upskillingELValues: any[];
  selectedEducationLevel: boolean;
  currentMaxIdOfAddEducation: any;
  currentEduIndex: boolean;
  currentCerIndex: boolean;
  currentEmpIndex: boolean;
  currentMaxIdOfCertificate: any;
  currentMaxIdOfAddEmployment: any;
  upskillingCertificateValues: any[];
  upskillingEmployementValues: any[];
  setMaxEmploymentId: any;
  setMaxCertificationId: any;
  setMaxAddEducationId: any;
  isShowCompetitiveForm: boolean;
  isEdited: boolean;
  isExpand: boolean;
  isCerExpand: boolean;
  isEmpExpand: boolean;
  domainWork: any;
  isDisabled: boolean;
  currentCertificateIndex: number;
  currentEmploymentIndex: number;
  openNewComField: boolean;
  openNewField: boolean;
  openNewFieldInUpskilling: boolean;
  upskillSubject: any;
  updateUPApi: boolean;
  updateK12Api: boolean;
  updateCEApi: boolean;
  updateGJApi: boolean;
  updateHEApi: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class UserProfileBlockWebController extends BlockComponent<Props, S, SS> {
  getUserDetailApiCallId: string;
  updateUserDetailApiCallId: string;
  updateSubCatApiCallId: string;
  updateCatApiCallId: string;
  getBoardDetailApiCallId: string;
  updateK12DetailApiCallId: string;
  getCategoriesApiCallId: string;
  getSubjectDetailApiCallId: string;
  getStandardDetailApiCallId: string;
  getCollegeDetailApiCallId: string;
  getSpecializationDetailApiCallId: string;
  getDegreeDetailApiCallId: string;
  getCourseDetailApiCallId: string;
  updateHigherDetailApiCallId: string;
  updateCompetitiveExamApiCallId: string;
  getEducationLevelApiCallId: string;
  getCasteCategoryApiCallId: string;
  updateGovtExamDetailApiCallId: string;
  updateUpskillingExamDetailApiCallId: string;
  getDomainApiCallId: string;
  RemoveUpskillingDetailApiCallId: string;
  deleteCatApiCallId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      accountDetail: null,
      token: null,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      userDetailList: [],
      profileImgBase64: "",
      profileImgBase64Obj: "",
      completed: new Set<number>(),
      activeStep: 0,
      activeLabel: 'personal information',
      skipped: 0,
      selectedCategory: "",
      isDisable: true,
      selectedSubCategory: [],
      categories: [],
      btnIndex: 0,
      showSubCatArray: [],
      getSteps: [{ stepLabel: 'Personal Information' },
      { stepLabel: 'Explore' }],
      profileDetail: [],
      boards: [],
      subjects: [],
      standards: [],
      colleges: [],
      degree: [],
      courses: [],
      passingYear: '',
      specialization: [],
      educationLevel: [],
      selectEducationLevel: "",
      casteCategory: [],
      upskillingELValues: [],
      selectedEducationLevel: null,
      currentMaxIdOfAddEducation: 0,
      currentMaxIdOfCertificate: 0,
      currentMaxIdOfAddEmployment: 0,
      upskillingCertificateValues: [],
      upskillingEmployementValues: [],
      setMaxEmploymentId: 0,
      setMaxCertificationId: 0,
      setMaxAddEducationId: 0,
      isShowCompetitiveForm: true,
      isEdited: false,
      currentEduIndex: 0,
      currentCerIndex: 0,
      currentEmpIndex: 0,
      isExpand: true,
      isCerExpand: true,
      isEmpExpand: true,
      domainWork: [],
      isDisabled: true,
      currentCertificateIndex: 0,
      currentEmploymentIndex: 0,
      openNewComField: false,
      openNewField: false,
      openNewFieldInUpskilling: false,
      upskillSubject: [],
      updateUPApi: true,
      updateK12Api: true,
      updateCEApi: true,
      updateGJApi: true,
      updateHEApi: true
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    const logged = localStorage.getItem("isActive");
    if (logged === "true") {
      window.addEventListener("popstate", e => {
        this.props.history.go(1);
      });
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson && responseJson !== undefined) {
        switch (apiRequestCallId) {
          case this.getUserDetailApiCallId:
            this.getUserDetailApiCall(responseJson);
            break;
          case this.updateUserDetailApiCallId:
            this.updateUserDetailApiCall(responseJson);
            break;
          case this.updateCatApiCallId:
            this.updateCatApiCall(responseJson);
            break;
          case this.updateSubCatApiCallId:
            this.updateSubCatApiCall(responseJson);
            break;
          case this.getCategoriesApiCallId:
            this.getCategoriesApiCall(responseJson);
            break;
          case this.getBoardDetailApiCallId:
            this.getBoardDetailApiCall(responseJson);
            break;
          case this.getSubjectDetailApiCallId:
            this.getSubjectDetailApiCall(responseJson);
            break;
          case this.getStandardDetailApiCallId:
            this.getStandardDetailApiCall(responseJson);
            break;
          case this.updateK12DetailApiCallId:
            this.updateK12DetailApiCall(responseJson);
            break;
          case this.getDegreeDetailApiCallId:
            this.getDegreeDetailApiCall(responseJson);
            break;
          case this.getSpecializationDetailApiCallId:
            this.getSpecializationDetailApiCall(responseJson);
            break;
          case this.getCollegeDetailApiCallId:
            this.getCollegeDetailApiCall(responseJson);
            break;
          case this.getCourseDetailApiCallId:
            this.getCourseDetailApiCall(responseJson);
            break;
          case this.getDomainApiCallId:
            this.getDomainApiCall(responseJson);
            break;
          case this.updateHigherDetailApiCallId:
            this.updateHigherDetailApiCall(responseJson);
            break;
          case this.getEducationLevelApiCallId:
            this.getEducationLevelApiCall(responseJson);
            break;
          case this.updateCompetitiveExamApiCallId:
            this.updateCompetitiveExamApiCall(responseJson);
            break;
          case this.getCasteCategoryApiCallId:
            this.getCasteCategoryApiCall(responseJson);
            break;
          case this.updateGovtExamDetailApiCallId:
            this.updateGovtExamDetailApiCall(responseJson);
            break;
          case this.updateUpskillingExamDetailApiCallId:
            this.updateUpskillingExamDetailApiCall(responseJson);
            break;
          case this.RemoveUpskillingDetailApiCallId:
            this.RemoveUpskillingDetailApiCall(responseJson);
            break;
          case this.deleteCatApiCallId:
            this.deleteCatApiCall(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson)
      }
    }
  }

  getUserDetailApiCall = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      const selectedSubCategory = this.getSelectedSubcategoryFromUserData(responseJson?.data?.attributes?.categories?.data)
      const selectedCategory = selectedSubCategory && selectedSubCategory?.length > 0 && (selectedSubCategory[0]?.cat_parent_id + "")
      let selectedCategoryObj = null
      let selectedsubCategoryArr = []
      if (selectedCategory) {
        selectedCategoryObj = this.state.categories.find((category) => {
          return category.id == selectedCategory
        })
      }
      if (selectedCategoryObj) {
        selectedsubCategoryArr = selectedCategoryObj?.attributes?.sub_categories || [];
      }
      let previousSteps = this.previousStep(responseJson);

      let uniqueSteps = [...new Map(previousSteps.map(item => [item.stepLabel, item])).values()]
      let steps = JSON.parse(localStorage.getItem('getCurrentStep'));
      if (!steps) {
        steps = { currentStep: 0, currentLabel: 'personal information' }
      }
      this.setState({
        userDetailList: responseJson?.data?.attributes, profileDetail: responseJson?.data?.attributes?.profile?.data?.attributes,
        selectedSubCategory: selectedSubCategory, selectedCategory: selectedCategory, showSubCatArray: selectedsubCategoryArr,
        getSteps: uniqueSteps, profileImgBase64: responseJson?.data?.attributes?.user_pic,
        activeLabel: steps.currentLabel, activeStep: steps.currentStep,
        upskillingELValues: responseJson?.data?.attributes?.profile?.data?.attributes?.education_levels?.data || [],
        upskillingCertificateValues: responseJson?.data?.attributes?.profile?.data?.attributes?.certifications?.data || [],
        upskillingEmployementValues: responseJson?.data?.attributes?.profile?.data?.attributes?.employment_details?.data || [],
      }, () => {
        this.openNewField(responseJson);

        const { upskillingELValues } = this.state;

        this.upskillingELValues(upskillingELValues);
        this.educationLevelValues(upskillingELValues);

        this.setCompetitiveFormAndCurrentMaxId();
        //board/degree validity onload

        this.currentEduIndex(upskillingELValues);

      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.props.openToastHandler("error", responseJson.errors[0]?.token + `please login again`!);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }

  currentEduIndex = (upskillingELValues: any) => {
    if (upskillingELValues.length > 0 && upskillingELValues[0].attributes.education_level.id !== "none") {
      this.setState({ currentEduIndex: 0 });
      this.checkEducationFormValidity();
    }
  }

  openNewField = (responseJson: any) => {
    if (responseJson?.data?.attributes?.profile) {
      if (responseJson?.data?.attributes?.profile?.data?.attributes?.college_name !== "" &&
        responseJson?.data?.attributes?.profile?.data?.attributes?.college_name !== "none") {
        this.setState({ openNewField: true, openNewComField: true })
      }
    } else {
      this.setState({ openNewField: false, openNewComField: false })
    }
  }

  previousStep = (responseJson: any) => {
    let previousStep = [...this.state.getSteps];
    if (responseJson?.data?.attributes?.categories?.data) {
      let getArr = responseJson?.data?.attributes?.categories?.data;
      getArr.forEach((e, i) => {
        if (e?.attributes?.selected_sub_categories?.length > 0)
          return previousStep.push({ stepLabel: e?.attributes?.name })
      })
    }
    return previousStep;
  }

  upskillingELValues = (upskillingELValues: any) => {
    let subArr = []
    let element = [];
    //subjects on load
    for (let upSkill of upskillingELValues) {
      element = upSkill.attributes.subjects
      if (element !== []) {
        for (let sub of this.state.subjects) {
          const item = sub;
          element.map(e => {
            if (e.id === item.attributes.id) {
              return subArr.push(item);
            }
          })
        }
      }
    }
    this.setState({ upskillSubject: subArr });
  }

  educationLevelValues = (upskillingELValues: any) => {
    for (let edu of this.state?.educationLevel) {
      if (upskillingELValues.length > 0 && upskillingELValues[0].attributes.education_level &&
        upskillingELValues[0].attributes.education_level.id &&
        upskillingELValues[0].attributes.education_level.id !== undefined &&
        upskillingELValues[0].attributes.education_level.id !== "") {
        if (edu.id == upskillingELValues[0].attributes.education_level.id) {
          const eduObj = edu;
          if (eduObj.attributes.above_12) {
            this.setState({ selectedEducationLevel: true });
          } else {
            this.setState({ selectedEducationLevel: false })
          }
        }
      }
      else {
        this.setState({ selectedEducationLevel: null })
      }
    }
  }

  updateUserDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleNext();
      localStorage.setItem("userImage", responseJson?.data?.attributes?.user_pic)
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.props.history.push("/home");
      this.props.openToastHandler("error", responseJson.errors[0]?.token);
    }
    else if (responseJson.errors && responseJson.errors[0]?.profile) {
      this.props.openToastHandler("error", responseJson.errors[0]?.profile);
    }
  }
  updateCatApiCall = (responseJson: any) => {
    if (responseJson?.data.length > 0) {
      let tempArr = responseJson.data;
      this.PassBlankValue(tempArr);
      tempArr.sort((a, b) => b.attributes.rank - a.attributes.rank)
      const newSteps = tempArr.map(a => { return a.attributes.name });
      const alwaysStep = [{ stepLabel: 'Personal Information' }, { stepLabel: 'Explore' }];
      if (newSteps.length > -1) {
        newSteps.reverse();
        newSteps.forEach(element => {
          alwaysStep.push({
            stepLabel: element
          })
        });
      }
      this.setState({ getSteps: alwaysStep }, () => {
        this.handleStep(newSteps[0], 2);
      });
      this.updateSubCategory();
    } else {
      this.parseApiErrorResponse(responseJson)
    }
  }
  updateSubCatApiCall = (responseJson: any) => {
    if (responseJson?.data.length > 0) {
      console.log("responseJson", responseJson)
    } else {
      this.parseApiErrorResponse(responseJson)
    }
  }
  getCategoriesApiCall = (responseJson: any) => {
    const categories = responseJson.data;
    this.setState({
      categories: this.parseCategoryData(categories),
    }, () => {
      this.getUserDetails()
    });
  }
  getBoardDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ boards: responseJson?.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      if (responseJson.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0]?.token + `please login again`!);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  getSubjectDetailApiCall = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      this.setState({ subjects: responseJson?.data });
    } else {
      console.log(responseJson)
    }
  }
  getStandardDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ standards: responseJson?.data });
    }
    else {
      console.log(responseJson)
    }
  }
  updateK12DetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleNext();
    }
    else {
      console.log(responseJson)
    }
  }
  getDegreeDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ degree: responseJson?.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors)
    }
  }
  getSpecializationDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ specialization: responseJson?.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors)
    }
  }
  getCollegeDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ colleges: responseJson?.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors)
    }
  }
  getCourseDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ courses: responseJson?.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors)
    }
  }
  getDomainApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ domainWork: responseJson?.data });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors)
    }
  }
  updateHigherDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleNext();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
      this.props.openToastHandler("error", responseJson.errors[0]?.token);
    }
  }
  getEducationLevelApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ educationLevel: responseJson?.data })
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
    }
  }
  updateCompetitiveExamApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleNext();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
      this.props.openToastHandler("error", responseJson.errors[0]?.token);
    }
  }
  getCasteCategoryApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ casteCategory: responseJson?.data })
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
    }
  }
  updateGovtExamDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleNext();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
    }
  }
  updateUpskillingExamDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.handleNext();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
    }
    else if (responseJson.errors && responseJson.errors[0]) {
      this.props.openToastHandler("error", responseJson.errors[0]);
    }
    else if (responseJson.errors && responseJson.errors[0]?.profile) {
      this.props.openToastHandler("error", responseJson.errors[0]?.profile);
    }
  }
  RemoveUpskillingDetailApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.getUserDetails();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
    }
    else if (responseJson.errors && responseJson.errors[0]) {
      this.props.openToastHandler("error", responseJson.errors[0]);
    }
  }
  deleteCatApiCall = (responseJson: any) => {
    if (responseJson?.data) {
      console.log(responseJson?.data)
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      console.log(responseJson.errors);
      this.props.openToastHandler("error", responseJson.errors[0]?.token);
    }
  }

  PassBlankValue = (selectedCategories: any) => {
    const { categories } = this.state;
    const notSelected = categories.filter((o1) => {
      return !selectedCategories.some((o2) => {
        return o1.id == o2.id;
      });
    });
    let catArr = [];
    notSelected.map(e => catArr.push(e.id));
    this.deleteCategoryData(catArr);
  }
  checkEducationFormValidity = () => {
    let index = this.state.upskillingELValues.length == this.state.currentEduIndex ?
      this.state.currentEduIndex : this.state.upskillingELValues.length - 1;
    if (this.state.upskillingELValues[index]?.attributes?.education_level?.id !== "none" &&
      this.state.upskillingELValues[index]?.attributes?.education_level?.id !== "") {
      if (this.state.selectedEducationLevel) {
        if (this.state.upskillingELValues[index]?.attributes.degree.id !== "none" &&
          this.state.upskillingELValues[index].attributes?.educational_course.id !== "none" &&
          this.state.upskillingELValues[index]?.attributes.degree.id !== "" &&
          this.state.upskillingELValues[index].attributes?.educational_course.id !== "") {
          this.setState({ isDisabled: false })
        } else {
          this.setState({ isDisabled: true })
        }
      } else {
        this.setBoardStandard(index)
      }
    }
  }
  setBoardStandard = (index) => {
    if (this.state.upskillingELValues[index]?.attributes?.board?.id !== "none" &&
      this.state.upskillingELValues[index]?.attributes?.standard?.id !== "none" &&
      this.state.upskillingELValues[index]?.attributes?.board?.id !== "" &&
      this.state.upskillingELValues[index]?.attributes?.standard?.id !== "") {
      this.setState({ isDisabled: false })
    } else {
      this.setState({ isDisabled: true })
    }
  }
  handleNext = () => {
    if (this.state.getSteps.length - 1 === this.state.activeStep) {
      this.setState({
        isFinishStep: true,
        activeStep: this.state.activeStep + 1
      })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1, activeLabel: this.state.getSteps[this.state.activeStep + 1].stepLabel.toLowerCase() });
      localStorage.setItem("getCurrentStep", JSON.stringify({ currentStep: this.state.activeStep, currentLabel: this.state.activeLabel }));
      if (this.state?.activeStep - 1 == 0) {
        this.props.showUSerData();
      }
    }
  };
  setCompetitiveFormAndCurrentMaxId = () => {
    let maxCertificateId = 0;
    let maxEmploymentId = 0;
    let maxEducationId = 0;
    if (this.state.upskillingCertificateValues?.length > 0) {
      for (let upSkillCert of this.state.upskillingCertificateValues) {
        const element = upSkillCert;
        if (maxCertificateId < element.id) {
          maxCertificateId = element.id;
          this.setState({ isDisabled: false })
        }
      }
    }
    if (this.state.upskillingEmployementValues?.length > 0) {
      for (let upSkillEmp of this.state.upskillingEmployementValues) {
        const element = upSkillEmp;
        if (maxEmploymentId < element.id) {
          maxEmploymentId = element.id;
          this.setState({ isDisabled: false })
        }
      }
    }
    maxEducationId = this.setMaxEducationId(maxEducationId)

    this.setState({
      currentMaxIdOfAddEmployment: maxEmploymentId,
      setMaxEmploymentId: maxEmploymentId,
      setMaxCertificationId: maxCertificateId,
      currentMaxIdOfCertificate: maxCertificateId,
      setMaxAddEducationId: maxEducationId,
      currentMaxIdOfAddEducation: maxEducationId,
      isShowCompetitiveForm: true,
    });
  }
  setMaxEducationId = (maxEducationId) => {
    let maxEduId = 0
    if (this.state.upskillingELValues?.length > 0) {
      for (let upSkillEL of this.state.upskillingELValues) {
        const element = upSkillEL;
        if (maxEducationId < element?.id) {
          maxEduId = element.id;
          this.setState({ isDisabled: false })
        }
      }
    }
    return maxEduId
  }
  profileImageHandler = (e: any) => {
    const files = e.target ? e.target.files : [];
    if (files.length > 0) {
      this.setState({ profileImgBase64: files[0] });
      this.setState({ profileImgBase64Obj: '' });
      let reader = new FileReader();
      let images = e.target.files[0];
      reader.onloadend = () => {
        this.setState({ profileImgBase64Obj: images, profileImgBase64: reader.result });
      }
      reader.readAsDataURL(images)
    }
  };
  imgBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
  };
  getSelectedSubcategoryFromUserData = (data: any) => {
    const selectedSubcategory = []
    for (let cat of data) {
      for (let subCat of cat?.attributes?.selected_sub_categories) {
        const sub = { ...subCat, cat_parent_id: Number(cat.id) }
        selectedSubcategory.push(sub)
      }
    }
    return selectedSubcategory;
  }
  getUserDetails = async () => {
    this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    const userId = localStorage.getItem("userId");
    this.getUserDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + userId
    );
    const token = await localStorage.getItem("token");
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //Update User Details 
  updateUserDetail = (data: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateUserDetailApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + userId);
    const token = localStorage.getItem('token');
    const header = { "token": token };
    let formdata = new FormData();
    let dat = new Date(data.date);
    let month = dat.getMonth() + 1;
    let day = dat.getDate();
    let year = dat.getFullYear();
    let tempDate = day + "/" + month + "/" + year;
    localStorage.setItem("firstName", data.firstName)
    formdata.append(`data[first_name]`, data.firstName);
    data.city && formdata.append(`data[city]`, data.city);
    if (data.phone !== "") {
      localStorage.setItem("phoneNumber", data.phone);
    }
    if (data.email !== "") {
      localStorage.setItem("email", data.email);
      formdata.append(`data[new_email]`, data.email);
    }
    data.gender && data.gender !== "none" && formdata.append(`data[gender]`, data.gender);
    data.date && formdata.append(`data[dob]`, tempDate);
    data.phone && formdata.append(`data[new_phone_number]`, `91${data.phone}`);
    if (data.user_pic !== "") {
      formdata.append(`data[image_attributes][image]`, data.user_pic);
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Get All Categories
  getAllCategories = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
    };
    const apiEndPoint = configJSON.categoriesAPIEndPoint;
    const methodType = configJSON.methodTypeApiGetUserProfile;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  //OnExploreNext
  handleStep = (step: string, stepNumber) => {
    this.setState({ activeLabel: step, activeStep: stepNumber });
    localStorage.setItem("getCurrentStep", JSON.stringify({ currentStep: stepNumber, currentLabel: step }))
  };
  clickNext = () => {
    this.updateCategory();
  }
  updateCategory = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateCatApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putCategoriesApiEndPoint + `/${userId}?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token,
      "Content-Type": configJSON.contentTypeApiUpdateUser
    };
    const data = this.getSelectedCat();
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateSubCategory = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateSubCatApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putSubCategoriesApiEndPoint + `/${userId}?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token,
      "Content-Type": configJSON.contentTypeApiUpdateUser
    };
    const data = this.getSubSelectedCat();
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSelectedCat = () => {
    const category = this.state.selectedSubCategory.map(item => {
      return JSON.parse(item.cat_parent_id)
    });
    return { categories_ids: Array.from(new Set(category)).sort() };
  }
  getSubSelectedCat = () => {
    const subcategory = this.state.selectedSubCategory.map(item => {
      return item.id
    });
    return { sub_categories_ids: subcategory };
  }
  parseCategoryData = (data: any) => {
    const newCategory: any[] = [];
    for (let cat of data) {
      const category = cat;
      for (let subCat of category?.attributes?.sub_categories) {
        if (category?.attributes?.sub_categories) {
          subCat.cat_parent_id = category.id
        }
      }
      newCategory.push(category);
    }
    return newCategory;
  }
  onBtnClick = (item: any) => {
    if (this.state.selectedCategory !== item.id) {
      this.setState(
        {
          selectedCategory: item.id,
        },
        () => {
          this.setState({
            showSubCatArray: [...item.attributes.sub_categories],
          });
        }
      );
    } else {
      this.setState({
        selectedCategory: null,
        showSubCatArray: [],
      });
    }
  };
  handleSubCategorySelection = (subCategory: any) => {
    const selectedSubCategory = [...this.state.selectedSubCategory];
    if (this.state.selectedSubCategory.findIndex((subCategoryTemp) => { return subCategoryTemp.id === subCategory.id }) > -1) {
      this.setState({
        selectedSubCategory: selectedSubCategory.filter((subCategoryTemp) => {
          return subCategoryTemp.id != subCategory.id
        })
      })
    } else {
      this.setState({
        selectedSubCategory: [...selectedSubCategory, subCategory]
      });
    }
  };
  //K12 form
  getBoards = async () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getBoardDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBoardsApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSubjects = async () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSubjectDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubjectsApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStandards = async () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getStandardDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStandardsApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateK12Detail = (data: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateK12DetailApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("language") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + `${userId}?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    let formdata = new FormData();
    data.selectBoard && data.selectBoard !== "none" ?
      formdata.append(`data[profile_attributes][board_id]`, data.selectBoard) :
      formdata.append(`data[profile_attributes][board_id]`, "");

    data.schoolName && data.schoolName !== "none" ?
      formdata.append(`data[profile_attributes][school_name]`, data.schoolName) :
      formdata.append(`data[profile_attributes][school_name]`, "");

    data.standards && data.standards !== "none" ?
      formdata.append(`data[profile_attributes][standard_id]`, data.standards) :
      formdata.append(`data[profile_attributes][standard_id]`, "");

    if (data.subjects.length > 0) {
      for (let subject of data.subjects) {
        formdata.append(`data[profile_attributes][subject_ids][]`, subject)
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // higher education form
  getCourse = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCourseDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCoursesApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getDegree = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getDegreeDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDegreesApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSpecialization = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSpecializationDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecializationApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCollege = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCollegeDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCollegesApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateHigherEducationDetail = (data: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateHigherDetailApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("language") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + `${userId}?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    let formdata = new FormData();
    data.degree && data.degree !== "none" ?
      formdata.append(`data[profile_attributes][degree_id]`, data.degree) :
      formdata.append(`data[profile_attributes][degree_id]`, "");

    data.specialization && data.specialization !== "none" ?
      formdata.append(`data[profile_attributes][specialization_id]`, data.specialization) :
      formdata.append(`data[profile_attributes][specialization_id]`, "");

    data.courses && data.courses !== "none" ?
      formdata.append(`data[profile_attributes][educational_course_id]`, data.courses) :
      formdata.append(`data[profile_attributes][educational_course_id]`, "");

    data.colleges && data.colleges !== "none" ?
      formdata.append(`data[profile_attributes][college_id]`, data.colleges) :
      formdata.append(`data[profile_attributes][college_id]`, "");

    if (typeof data.passingYear === "string" && data.passingYear !== "none") {
      formdata.append(`data[profile_attributes][passing_year]`, data.passingYear);
    } else {
      data.passingYear !== "none" && formdata.append(`data[profile_attributes][passing_year]`, (data.passingYear).getFullYear());
    }
    data.otherCollege ? formdata.append(`data[profile_attributes][college_name]`, data.otherCollege) :
      formdata.append(`data[profile_attributes][college_name]`, "");
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Competitive Exam
  getEducationLevel = async () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getEducationLevelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationLevelApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateCompetitiveExam = (data: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCompetitiveExamApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("language") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + `${userId}?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    let formdata = new FormData();
    data.selectEducationLevel && data.selectEducationLevel !== "none" ?
      formdata.append(`data[profile_attributes][higher_education_level_id]`, JSON.parse(data.selectEducationLevel).id) :
      formdata.append(`data[profile_attributes][higher_education_level_id]`, "");

    data.degrees && data.degrees !== "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_degree_id]`, data.degrees) :
      formdata.append(`data[profile_attributes][competitive_exam_degree_id]`, "");

    data.specialization && data.specialization !== "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_specialization_id]`, data.specialization) :
      formdata.append(`data[profile_attributes][competitive_exam_specialization_id]`, "");

    data.course && data.course != "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_course_id]`, data.course) :
      formdata.append(`data[profile_attributes][competitive_exam_course_id]`, "");

    data.college && data.college !== "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_college_id]`, data.college) :
      formdata.append(`data[profile_attributes][competitive_exam_college_id]`, "");

    if (typeof data.passingCYear === "string" && data.passingCYear !== "none") {
      formdata.append(`data[profile_attributes][competitive_exam_passing_year]`, data.passingCYear);
    } else {
      data.passingCYear !== "none" && formdata.append(`data[profile_attributes][competitive_exam_passing_year]`, (`"` + (data.passingCYear).getFullYear()) + `"`);
    }
    this.setAttributes(data, formdata)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  setAttributes = (data, formdata) => {
    data.otherCollege && data.otherCollege !== "none" &&
      formdata.append(`data[profile_attributes][competitive_exam_college_name]`, data.otherCollege)

    data.board && data.board !== "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_board_id]`, data.board) :
      formdata.append(`data[profile_attributes][competitive_exam_board_id]`, "");

    data.schoolName && data.schoolName !== "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_school_name]`, data.schoolName) :
      formdata.append(`data[profile_attributes][competitive_exam_school_name]`, "");

    data.standard && data.standard !== "none" ?
      formdata.append(`data[profile_attributes][competitive_exam_standard_id]`, data.standard) :
      formdata.append(`data[profile_attributes][competitive_exam_standard_id]`, "");

    if (data.subject.length > 0) {
      for (let sub of data.subject) {
        formdata.append(`data[profile_attributes][competitive_exam_subject_ids][]`, sub)
      }
    }
  }
  //Govt Form
  getCasteCategory = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCasteCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCasteCategoryApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //update updateGovtDetail
  updateGovtDetail = (data: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateGovtExamDetailApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("language") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + `${userId}?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    let formdata = new FormData();
    data.selectEducationLevel && data.selectEducationLevel !== "none" ?
      formdata.append(`data[profile_attributes][govt_job_attributes][education_level_id]`, data.selectEducationLevel) :
      formdata.append(`data[profile_attributes][govt_job_attributes][education_level_id]`, "");

    data.specialization && data.specialization !== "none" ?
      formdata.append(`data[profile_attributes][govt_job_attributes][specialization_id]`, data.specialization) :
      formdata.append(`data[profile_attributes][govt_job_attributes][specialization_id]`, "");

    data.category && data.category !== "none" ?
      formdata.append(`data[profile_attributes][govt_job_attributes][caste_category]`, data.category) :
      formdata.append(`data[profile_attributes][govt_job_attributes][caste_category]`, "");
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //domain work function
  getDomainWork = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getDomainApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDomainApiEndPoint
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  //update Upskilling
  updateUpskilling = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateUpskillingExamDetailApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    const lang = localStorage.getItem("language") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + `${userId}?language=${lang}`);
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    let isAddEducationEdited = false;
    for (let upSkillEL of this.state.upskillingELValues) {
      const element = upSkillEL;
      if (element.isEdited) {
        isAddEducationEdited = true;
      }
    }
    let isCertificatesEdited = false;
    isCertificatesEdited = this.setIsCert()

    let isAddEmploymentEdited = false;
    isAddEmploymentEdited = this.setIsAddEmp()

    let newCertificates = this.state.upskillingCertificateValues.filter((e: any) => e.isEdited);
    let newEmployments = this.state.upskillingEmployementValues.filter((e: any) => e.isEdited);
    let newEducations = this.state.upskillingELValues.filter((e: any) => e.isEdited);
    let formdata = new FormData();
    if (isAddEducationEdited || isCertificatesEdited || isAddEmploymentEdited) {
      if (isAddEducationEdited) {
        this.setIsAddEducationEdited(formdata, newEducations)
      }
      if (isCertificatesEdited) {
        this.setIsCertificatesEdited(formdata, newCertificates)
      }
      if (isAddEmploymentEdited) {
        this.setIsAddEmploymentEdited(formdata, newEmployments)
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.handleNext();
    }
  }
  setIsCert = () => {
    for (let upSkillCert of this.state.upskillingCertificateValues) {
      const element = upSkillCert;
      if (element.isEdited) {
        return true;
      }
    }
  }
  setIsAddEmp = () => {
    for (let upSkillEmp of this.state.upskillingEmployementValues) {
      const element = upSkillEmp;
      if (element.isEdited) {
        return true;
      }
    }
  }
  setIsAddEducationEdited = (formdata, newEducations) => {
    newEducations.forEach((item: any, index: any) => {
      item.id && !item?.isNewRecord && !item?.isNewRecord !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][id]`,
          item.id);
      item?.attributes?.education_level?.id && item?.attributes?.education_level?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][education_level_id]`,
          item?.attributes?.education_level?.id);
      item?.attributes?.board?.id && item?.attributes?.board?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][board_id]`,
          item?.attributes?.board.id);
      item?.attributes?.standard?.id && item?.attributes?.standard?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][standard_id]`,
          item?.attributes?.standard?.id);
      item?.attributes?.school_name &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][school_name]`,
          item?.attributes?.school_name);
      if (item.attributes.subjects.length > 0) {
        for (let subject of item.attributes.subjects) {
          formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][subject_ids][]`,
            subject.id);
        }
      }
      item?.attributes?.degree?.id && item?.attributes?.degree?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][degree_id]`,
          item?.attributes?.degree?.id);
      item?.attributes?.specialization?.id && item?.attributes?.specialization?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][specialization_id]`,
          item?.attributes?.specialization?.id);
      item?.attributes?.educational_course?.id && item?.attributes?.educational_course?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][educational_course_id]`,
          item?.attributes?.educational_course?.id);
      item?.attributes?.college?.id && item?.attributes?.college?.id !== "none" &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][college_id]`,
          item?.attributes?.college?.id);
      item?.attributes?.college_name ?
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][college_name]`,
          item?.attributes?.college_name) :
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][college_name]`,
          "");
      item?.attributes?.passing_year &&
        formdata.append(`data[profile_attributes][education_level_profiles_attributes][${index}][passing_year]`,
          item?.attributes?.passing_year)
    })
  }
  setIsCertificatesEdited = (formdata, newCertificates) => {
    newCertificates.forEach((item: any, index: any) => {
      item?.id && !item?.isNewRecord &&
        formdata.append(`data[profile_attributes][certifications_attributes][${index}][id]`,
          item?.id);
      item?.attributes?.certification_course_name &&
        formdata.append(`data[profile_attributes][certifications_attributes][${index}][certification_course_name]`,
          item?.attributes?.certification_course_name ? item.attributes.certification_course_name : "")

      item?.attributes?.provided_by &&
        formdata.append(`data[profile_attributes][certifications_attributes][${index}][provided_by]`,
          item?.attributes?.provided_by ? item.attributes.provided_by : "")

      item?.attributes?.duration &&
        formdata.append(`data[profile_attributes][certifications_attributes][${index}][duration]`,
          item?.attributes?.duration ? item.attributes.duration : "")

      item.attributes.completion_year != "" &&
        formdata.append(`data[profile_attributes][certifications_attributes][${index}][completion_year]`,
          item?.attributes?.completion_year ? item.attributes.completion_year : "")
    });
  }
  setIsAddEmploymentEdited = (formdata, newEmployments) => {
    newEmployments.forEach((item: any, index: any) => {
      item?.id && !item?.isNewRecord &&
        formdata.append(`data[profile_attributes][employment_details_attributes][${index}][id]`,
          item.id)
      item?.attributes?.last_employer &&
        formdata.append(`data[profile_attributes][employment_details_attributes][${index}][last_employer]`,
          item.attributes.last_employer)
      item?.attributes?.designation &&
        formdata.append(`data[profile_attributes][employment_details_attributes][${index}][designation]`,
          item.attributes.designation)
      item?.attributes?.domain_work_function?.id && item?.attributes?.domain_work_function?.id != "none" &&
        formdata.append(`data[profile_attributes][employment_details_attributes][${index}][domain_work_function_id]`,
          item?.attributes?.domain_work_function?.id)
    })
  }
  removeItem = (id: any, label: any) => {
    let isIdExist = false;
    if ("certification" == label) {
      isIdExist = this.isBelongToSavedCertificate(id)
    }
    if ("addEducation" == label) {
      isIdExist = this.isBelongToSavedEducation(id)
    }
    if ("employment_detail" == label) {
      isIdExist = this.isBelongToSavedEmployment(id)
    }
    let formdata = new FormData();
    if (isIdExist) {
      this.setState({ labelForRemoveItemFromUpskilling: label })
      if (label == 'addEducation') {
        formdata.append('data[profile_attributes][education_level_profiles_attributes][0][id]', id)
        formdata.append('data[profile_attributes][education_level_profiles_attributes][0][_destroy]', 'true')
      }
      if (label == 'certification') {
        formdata.append('data[profile_attributes][certifications_attributes][0][id]', id)
        formdata.append('data[profile_attributes][certifications_attributes][0][_destroy]', 'true')
      }
      if (label == 'employment_detail') {
        formdata.append('data[profile_attributes][employment_details_attributes][0][id]', id)
        formdata.append('data[profile_attributes][employment_details_attributes][0][_destroy]', 'true')
      }
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.RemoveUpskillingDetailApiCallId = requestMessage.messageId;
      const userId = localStorage.getItem("userId");
      const lang = localStorage.getItem("language") || "en";
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserDetailApiEndPoint + `${userId}?language=${lang}`);
      const token = localStorage.getItem('token');
      const header = {
        "token": token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setUpskillingValues(label, id)
    }
  }
  setUpskillingValues = (label, id) => {
    if ("certification" == label) {
      let tempCert = this.state.upskillingCertificateValues.filter((e: any) => e.id != id);
      this.setState({ upskillingCertificateValues: tempCert, isDisabled: false }, () => {
        if (this.state.upskillingCertificateValues.length <= 0) {
          this.setState({ isDisabled: true })
        }
      });
    }
    if ("addEducation" == label) {
      let tempEducation = this.state.upskillingELValues.filter((e: any) => e.id != id);
      this.setState({ upskillingELValues: tempEducation, isDisabled: false }, () => {
        if (this.state.upskillingELValues.length <= 0) {
          this.setState({ isDisabled: true })
        }
      });
    }
    if ("employment_detail" == label) {
      let tempEmployment = this.state.upskillingEmployementValues.filter((e: any) => e.id != id);
      this.setState({ upskillingEmployementValues: tempEmployment, isDisabled: false }, () => {
        if (this.state.upskillingEmployementValues.length <= 0) {
          this.setState({ isDisabled: true })
        }
      });
    }
  }
  isBelongToSavedCertificate = (id: any) => {
    if (id > this.state.setMaxCertificationId) {
      return false;
    }
    return true;
  }

  isBelongToSavedEducation = (id: any) => {
    if (id > this.state.setMaxAddEducationId) {
      return false;
    }
    return true;
  }

  isBelongToSavedEmployment = (id: any) => {
    if (id > this.state.setMaxEmploymentId) {
      return false;
    }
    return true;
  }
  deleteCategoryData = (category: any) => {
    const data = { 'deleted_category_ids': category };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.deleteCatApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCategoriesApiEndPoint + `?language=${lang}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token,
      "Content-Type": configJSON.contentTypeApiUpdateUser
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiDeleteUserDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}