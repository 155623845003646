//@ts-nocheck
import React from "react";
import {
    withStyles,
    Grid,
    createStyles,
    Container,
    Typography,
    Card,
    CardContent,
    CardMedia, TextField, Button, CardActionArea, CardHeader, IconButton
} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import CarrerExpertRatingController from "./CarrerExpertRatingController.web";
import { next, prev } from "./assets";
import ScrollMenu from "react-horizontal-scrolling-menu";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

const ReviewTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-input": {
            color: "rgba(0, 0, 0, 0.85)"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "rgba(0, 0, 0, 0.85)"
        }
    },
})(TextField);
//@ts-ignore
export class CarrerExpertRatingWeb extends CarrerExpertRatingController {

    async componentDidMount() {
        window.scrollTo(0, 0);
        if (localStorage.getItem('token')) {
            this.getBoughtCourses();
            this.getAllBookmarkedCourses();
        }
    }

    handleChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    showTime = (time: any) => {
        let n: any = parseInt(time);
        return `0${n / 60 ^ 0}`.slice(-2) + 'H :' + ('0' + n % 60).slice(-2) + 'M';
    }

    render() {
        //@ts-ignore
        const { classes } = this.props;
        const { boughtCourseList, rating, review } = this.state
        const expertDetails = this.props?.location?.state?.careerExpertData
        return (
            <>
                <Container maxWidth="xl" style={{ padding: "5rem" }}>
                    <Grid container direction="row" justify="space-between">
                        <Grid xs={12} md={7}>
                            <Card variant="outlined" className={classes.shortlistCard}>
                                <CardHeader style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} subheader={
                                    <Typography variant="h3">Review Course</Typography>
                                }>
                                </CardHeader>
                                <CardContent>
                                    <Grid container direction="row" className={classes.courseoutlined}
                                        justify="space-between" spacing={3} >
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Card>
                                                <CardActionArea>
                                                    <CardMedia
                                                        component="img"
                                                        className={classes.suggested}
                                                        src={expertDetails?.image?.url}
                                                        title="suggested Card title"
                                                    />
                                                    <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                                                0 Enrolled
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} >

                                                            <Typography variant="body2" className={classes.suggestedDetail}>{expertDetails?.description}</Typography>

                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        variant="caption"
                                                                        className={classes.shortlistCardSubTitle}
                                                                    >
                                                                        {expertDetails?.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <CardContent />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Grid container direction="row" justify="center" spacing={2}  >
                                                <Grid item xs={12}>
                                                    <Typography className={classes.rate_reviewSec}>Rate Expert</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.rate_reviewGrid}>
                                                    <Rating
                                                        name="rating"
                                                        value={rating}
                                                        data-testId="handleChange"
                                                        onChange={(e) => { this.handleChange(e) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.rate_reviewGrid}>
                                                    <Typography className={classes.rate_reviewSec}>Share your experience & learnings</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.rate_reviewGrid}>
                                                    <ReviewTextField
                                                        className={classes.textField}
                                                        variant="outlined"
                                                        multiline
                                                        name="review"
                                                        placeholder="enter the review"
                                                        rows={4}
                                                        rowsMax={8}
                                                        value={review}
                                                        onChange={(e) => { this.handleChange(e) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.rate_reviewGrid}>
                                                    <Button fullWidth variant="contained" className={classes.submitBtn}
                                                        data-testId="submitReview"
                                                        onClick={() => { this.submitReview(expertDetails?.id) }} >
                                                        <Typography>Submit Review </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="xl" style={{ padding: "0rem 5rem" }}>
                    <Typography variant="h4">Students Also Bought</Typography>
                    <Grid container direction="row">
                        <Grid item xs={12} className="w-full">
                            <ScrollMenu
                                clickWhenDrag={true}
                                dragging={true}
                                hideArrows={true}
                                hideSingleArrow={true}
                                scrollToSelected={true}
                                alignCenter={false}
                                transition={0.3}
                                translate={0}
                                wheel={false}
                                arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                                arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                                //@ts-ignore
                                data={boughtCourseList && boughtCourseList.length > 0 &&
                                    boughtCourseList.map((_data: any, idx: any) => (
                                        <Grid item key={idx} >
                                            <Card className={classes.card} key={idx} onClick={() => this.props.history.push("/home/coursedetail", { courseDetail: _data })}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        component="img"
                                                        className={classes.suggested}
                                                        src={_data?.attributes?.thumbnail}
                                                        title="suggested Card title"
                                                    />
                                                    <Grid container spacing={2} className={classes.expertCard}>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                                                {_data?.attributes?.enrolled_users}{" "}Enrolled
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} />
                                                        <Grid item xs={4}>
                                                            <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                                                                {this.showTime(_data?.attributes?.total_time)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography
                                                                variant="body2"
                                                                className={classes.runningCoursesCardTitle}
                                                            >
                                                                {_data?.attributes?.heading} {':'} </Typography>
                                                            <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>

                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {_data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
                                                                return (_data?.attributes?.user_rating >= rating ? <StarIcon key={rating + idx} style={{ color: "f48b1b" }} /> : <StarBorderIcon key={rating + idx} style={{ color: "f48b1b" }} />);
                                                            })}
                                                            {!_data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
                                                                return (<StarBorderIcon key={ratings + idx} style={{ color: "f48b1b", }} />);
                                                            })}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        variant="caption"
                                                                        className={classes.shortlistCardSubTitle}
                                                                    >
                                                                        {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    {localStorage.getItem('token') && this.state.getAllBookmarked && this.state.getAllBookmarked.map((e: any) =>
                                                                        this.state.getAllBookmarked.findIndex((e) => { return e.attributes.content.id == _data.id }) > -1 ?
                                                                            <IconButton aria-label="bookmark" data-testId="removeBookmark" onClick={(event) => this.removeBookmark(_data?.attributes?.id, event)}>
                                                                                <BookmarkIcon color="primary" />
                                                                            </IconButton>
                                                                            :
                                                                            <IconButton aria-label="bookmark" data-testId="addBookmark" onClick={(event) => this.addBookmark(_data?.attributes?.id, event)}>
                                                                                <BookmarkBorderIcon color="primary" />
                                                                            </IconButton>
                                                                    )}
                                                                    {localStorage.getItem('token') && this.state.getAllBookmarked.length <= 0 &&
                                                                        <IconButton aria-label="bookmark" onClick={(event) => this.addBookmark(_data?.attributes?.id, event)}>
                                                                            <BookmarkBorderIcon color="primary" />
                                                                        </IconButton>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <CardContent />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))
                                } />
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}
//@ts-ignore
const CarrerExpertRatingWithRouter = withRouter(CarrerExpertRatingWeb);
const CarrerExpertRatingWithLoader = withLoader(CarrerExpertRatingWithRouter);
const CarrerExpertRatingToast = withSnackbar(CarrerExpertRatingWithLoader);
const CarrerExpertRatingWithStyle = withStyles((theme) =>
    createStyles({
        root: {
            paddingTop: '5rem',
            paddingBottom: '5rem'
        },
        head: {
            padding: '1rem'
        },
        rowGrid: {
            marginBottom: '1rem'
        },
        media: {
            height: 140
        },
        rate_reviewSec: {
            color: "#a7a7a7"
        },
        submitBtn: {
            fontSize: "14px",
            color: "#fff",
            textAlign: "center",
            padding: "4px 25px",
            borderRadius: "1rem",
            backgroundColor: "#ff6d0d",
            '&:hover': {
                backgroundColor: '#ff6d0d',
                color: '#ffffff'
            }
        },
        rate_reviewGrid: {
            marginTop: '0.7rem'
        },
        card: {
            borderRadius: "8px",
            width: '20rem',
            margin: '1rem'
        },
        suggested: {
            backgroundColor: "#bdb4b4",
            height: "10rem",
            objectFit: 'fill'
        },
        suggestedDetail: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#3b302e",
            textOverflow: "ellipsis",
            width: "18rem"
        },
        suggestedCardEnrolledTime: {
            color: "#1c1c1c",
            paddingTop: "20px",
        },
        runningCoursesCardTitle: {
            color: "#1c1c1c"
        },
        expertCard: {
            paddingLeft: '1rem',
            paddingRight: '1rem'

        },
        expertTitle: {
            color: "#4a6bd8"
        },
        courseoutlined: {
            marginBottom: "2rem"
        },
    })
)(CarrerExpertRatingToast);
export default withTranslation()(CarrerExpertRatingWithStyle);