import React from "react";

export const categoryArticalList=(theme:any)=>
    ({
        headTxt: {
            color: '#7764db',
            marginLeft: '1.5rem'
          },
          subTxt: {
            color: '#4f4f4f',
            textAlign: 'center'
          },
          finishImg: {
            height: '8rem',
            width: '8rem'
          },
          cancelBtn: {
            borderRadius: '24px',
            backgroundColor: '#ebebeb',
            height: '50px',
            width: '200px',
            color: '#4f4f4f',
            '&:hover': {
              backgroundColor: '#ebebeb',
              color: '#4f4f4f'
            }
          },
          paginator: {
            marginTop: '2rem',
            marginBottom: '4rem',
            justifyContent: 'center',
          },
          categoryHight: {
            height: '9rem',
             backgroundColor: '#383535',
             borderRadius: '20px',
             display: 'flex',
             alignItems: 'center',
             width: 'fit-content',
             [theme.breakpoints.down('md')]: {
               width: '12rem',
          },
          [theme.breakpoints.down('xs')]: {
              width: 'fit-content',
          },
            
          },
          categoryHight1: {
            minHeight: '9rem',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              minHeight: 'auto',
            },
          },
          categoryHeading: {
            fontSize: '16px',
            fontFamily: 'Montserrat-Bold',
          },
          fvSmallPlayer:{
            opacity: '0.51',
            borderRadius: '10px',
            objectFit: 'fill',
            backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
            backgroundAttachment: 'fixed'
          },	
          smallPlay:{
            position: 'absolute',
            height: '2rem',
            width: '2rem',
            zIndex: 10000,
            bottom: '2rem',
            left: '5rem'
          },

    })

    export const categoryArticalViewAll=(theme:any)=>
    ({
          categoryViewall: {
            marginTop: '-0.25rem',
            backgroundColor: '#3d2f2f',
            height: '15rem',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
              height: '10rem',
            },
          },
          categorySearch: {
            width: '50%',
            backgroundImage: 'linear-gradient(103deg, rgba(255, 255, 255, 0) 32%, rgba(240, 240, 240, 0.19) 51%)',
            boxShadow: 'inset 1px 1px 2px 0 #fff',
            borderRadius: '17px',
            padding: '10px 15px 6px',
            color: '#fff',
            [theme.breakpoints.down('md')]: {
              width: '80%',
            },
          },
    })