Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.contentTypeApiEndPoint="bx_block_contentmanagement/content_types";
exports.contentProviderApiEndPoint="bx_block_contentmanagement/content_providers";
exports.tagsApiEndPoint="bx_block_contentmanagement/tags";
exports.getContentEndPoint="/bx_block_contentmanagement/contents";
exports.getCategoriesEndPoint="/bx_block_categories/categories?language=en?show_on_landing_page=true"; 
exports.getSubcategoriesEndPoint="/bx_block_categories/sub_categories?language=en";
exports.dynamicFilterApiEndPoint="/bx_block_filter_items/content_management/filters"
// Customizable Area End