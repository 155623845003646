import * as Yup from "yup";

// Validation Schema for create account
export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("*mandatory"),
  lastName: Yup.string().required("*mandatory"),
  phone: Yup.string()
    .min(10, "Enter 10 digit phone no.")
    .max(10, "Enter 10 digit phone no.")
    .required("*mandatory"),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("*mandatory"),
  password: Yup.string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must include min 8 char. with one upper case,number and special char.')
    .required("*mandatory"),
  check: Yup.string().required("mandatory"),
});
