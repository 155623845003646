Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityFeed";
exports.labelBodyText = "ActivityFeed Body";
exports.getContentEndPoint = "/bx_block_contentmanagement/contents";
exports.getCtaEndPoint = "/bx_block_categories/cta";
exports.btnExampleTitle = "CLICK ME";

exports.getActivityFeedLog = "/bx_block_activityfeed/activityfeeds";
exports.addActivityFeedLog = "/bx_block_activityfeed/activityfeeds";
// Customizable Area End
