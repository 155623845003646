import React from "react";
import moment from "moment";
export const quizAssessmentcss = (theme: any, groupImg: any) =>
({
  paper: {
    borderRadius: '0rem'
  },
  selectedBtn: {
    border: '2px solid #f48b1b !important',
    backgroundColor: '#f48b1b',
    borderRadius: '20px !important',
    color: '#ffffff !important',
    marginRight: '15px',
    width: '173px',
    height: '3rem',
    '&:hover': {
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '55px',
      whiteSpace: 'break-spaces',
      marginRight: '5px',
      height: '23px',
    },
  },
  category: {
    marginTop: '4rem',
    marginBottom: '4rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0rem',
    },
  },
  btnTypo: {
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: '5px',
      width: '40px',
      margin: '0px -6px',
    },
  },
  catBtn: {
    border: '2px solid #f48b1b !important',
    backgroundColor: '#ffffff',
    borderRadius: '20px !important',
    width: '173px',
    height: '3rem',
    marginRight: '15px',
    '&:hover': {
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important'
    },
    [theme.breakpoints.down('xs')]: {
      width: '55px',
      whiteSpace: 'break-spaces',
      marginRight: '5px',
      height: '23px',
    },
  },
  search: {
    height: '3.5rem',
    borderRadius: '1.5rem',
    webkitBackdropFilter: 'blur(50px)',
    backdropFilter: 'blur(50px)',
    backgroundColor: 'rgba(207, 207, 207, 0.13)',
    marginTop: '0rem',
    paddingLeft: '1rem',
  },
  searchSm: {
    height: '3rem',
    borderRadius: '1.5rem',
    webkitBackdropFilter: 'blur(50px)',
    backdropFilter: 'blur(50px)',
    backgroundColor: 'rgba(207, 207, 207, 0.13)',
    marginTop: '6rem',
    paddingLeft: '1rem'
  },
  inputRoot: {
    width: '100%'
  },
  videoCont: {
    marginTop: '-20rem',
    padding: '0px',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-11rem',
    },
  },
  videoCont2: {
    marginTop: '-20rem',
    padding: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-11rem',
    },
  },
  quizBox1: {
    width: '100%',
    height: '75%',
    padding: '11px 14px 23px 9px',
    opacity: 0.88,
    borderRadius: 8,
    backgroundColor: '#0f1c2b',
  },
  uParallax: {
    marginBottom: '15rem',
    width: "100%",
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0rem',
    },
  },
  viewAll: {
    border: '2px solid #f48b1b !important',
    backgroundColor: '#ffffff',
    borderRadius: '20px !important',
    width: '7rem',
    height: '2rem',
    marginRight: '15px',
    '&:hover': {
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important'
    },
    [theme.breakpoints.down("xs")]: {
      border: '1px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      height: '1rem',
      padding: '.7rem !important',
      width: '5rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    }
  },
  paddingExamArVi: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    [theme.breakpoints.down("xs")]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',

    },
  },
  quizBox: {
    width: '20rem',
    margin: '0 38px 14px 0',
    padding: '0 0 16px',
    borderRadius: 8,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #e1e1e1',
    backgroundColor: '#fff',
    [theme.breakpoints.down("md")]: {
      width: '12rem',
      margin: '0 14px 14px 0',
      flexDirection: 'inherit',
    },
  },
  quizBox2: {
    width: '100%',
    padding: '11px 14px 13px 9px',
    opacity: 0.88,
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#0f1c2b',
    backgroundImage: `url(${groupImg})`,
    backgroundSize: 'cover',
    flexWrap: 'nowrap',
    [theme.breakpoints.down("md")]: {
      padding: 4,
      marginTop: '-17px',
    },
  },
  quizBtn: {
    height: 35,
    margin: '17px auto 0px !important',
    padding: '6px 9px 6px 10px',
    borderRadius: 8,
    backgroundColor: '#ff9322',
    '&:hover': {
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important'
    },
    [theme.breakpoints.down("md")]: {
      margin: '17px auto 0px !important',
      height: 25,
    },
  },
  quizBtnTxt: {
    letterSpacing: 'normal',
    color: '#fff',
  },
  quizHeading: {
    fontWeight: 'bold',
    height: '45px',
    color: '#ff9322',
    fontSize: '0.9rem',
    whiteSpace: 'initial',
  },
  quizText: {
    color: '#fff',
    fontSize: '0.8rem',
  }
})

export const quizAssessmentTestcss = (theme: any) => ({
  uParallax: {
    width: "100%",
    margin: "auto",
  },
  root: {
    width: "100%",
  },
  qGrid: {
    paddingLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '0rem',
    },
  },
  radioBtn: {
    color: "#000",
    textAlign: "initial",
    fontSize: "20px",
  },
  chkRadioBtn: {
    color: "#4bb543",
    textAlign: "initial",
    fontSize: "20px",
  },
  chkErrRadioBtn: {
    color: "red",
    textAlign: "initial",
    fontSize: "20px",
  },
  nextBtn: {
    border: "2px solid #f48b1b !important",
    backgroundColor: "#f48b1b",
    borderRadius: "20px !important",
    color: "#ffffff !important",
    marginRight: "15px",
    width: "6rem",
    height: "2rem",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: "#f48b1b !important",
      color: "#ffffff !important",
    },
  },
  content: {
    width: '80%',
  },
  qContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  carouselDots: {
    "& .control-dots": {
      position: "relative !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
  },
  flexRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusIndicator: {
    paddingTop: "1rem",
  },
  dotsContainer: {
    padding: "0rem 1rem",
    height: "1.5rem",
    margin: "0 27px 27px 0",
    opacity: 0.39,
    borderRadius: "13px",
    backgroundColor: "#ededed",
    justifyContent: 'flex-start',
  },
  cDot: {
    margin: '0rem 1rem !important',
  },
  commonRdo: {
    padding: '0.5rem 0rem',
    // '& > span': {
    //   display: 'flex',
    //   alignItem: 'center',
    // }
  },
  dot: {
    width: 13,
    height: 13,
    margin: "0 2px",
    borderRadius: "50%",
    color: "gray",
    background: "gray",
    cursor: "pointer",
  },
  dotActive: {
    width: 13,
    height: 13,
    margin: "0 2px",
    borderRadius: "50%",
    color: "green",
    background: "green",
    cursor: "pointer",
  },
  dotErr: {
    width: 13,
    height: 13,
    margin: "0 2px",
    borderRadius: "50%",
    color: "red",
    background: "red",
    cursor: "pointer",
  },
  timer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 3rem",
    height: "1.5rem",
    margin: "0 27px 27px 0",
    opacity: 0.39,
    borderRadius: "13px",
    color: "#10ac05",
    backgroundColor: "#ededed",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: 'initial',
  },
  skipBtn: {
    color: "#f48b1b",
    marginRight: "15px",
    cursor: "pointer",
    fontSize: 'initial',
    padding: '1.5rem 0rem',
    textAlign: 'right'
  },
  startRoot: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  paper: {
    margin: '5rem 0rem',
    width: '80%',
    padding: '5rem',
    borderRadius: 18,
    boxShadow: '0 15px 23px 15px rgba(240, 237, 237, 0.5)',
    backgroundColor: '#fff',
  },
  image: {
    width: '15rem',
    height: '12rem',
    borderRadius: 20,
    border: 'solid 1px #979797',
    backgroundColor: '#d8d8d8',
  },
  optionImage: {
    maxWidth: '150px',
    maxHeight: '75px',
  },
  resultImage: {
    width: '15rem',
    height: '12rem',
    borderRadius: 20,
    border: 'solid 1px #979797',
    backgroundColor: '#fff2f2',
  },
  startBtn: {
    width: 178,
    borderRadius: 5,
    backgroundColor: '#f48b1b',
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#f48b1b !important",
      color: "#ffffff !important",
    },
  },
  quizCard: {
    width: '15rem',
    height: '11rem',
    margin: '1rem 1rem',
    borderRadius: 7,
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#dfdcdc',
    padding: '9px 12px !important',
    position: 'relative'
  },
  topContent: {
    margin: '1rem ​0rem !important',
  },
  quizPaper: {
    padding: '1rem',
    width: '100%',
    margin: '2rem 0rem',
    [theme.breakpoints.down("md")]: {
      width: '90%',
    },
  },
  sharescore: {
    '& > span': {
      color: "#2b2b2b !important",
      "&:hover": {
        color: "#2b2b2b !important",
      },
    },
  },
  qtitle: {
    fontWeight: "bold",
    textAlign: 'left',
  },
})

export interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
}
export interface Category {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategories[];
  };
}
export const daysObject = { '1': 0, '2': 1, '3': 7, '4': 15, '5': 30, '6': '' }
export const queryobj = { '0': 'content_type[]', '1': 'content_provider[]', '2': 'tag[]', '3': 'Date' }
export const filterdata = [
  {
    "type": "Content Type",
    "key": "content_type",
    "id": 0,
    "data": [],
    "value": [],
  },
  {
    "type": "Content Provider",
    "key": "content_provider",
    "id": 1,
    "data": [],
    "value": [],
  },
  {
    "type": "Tags",
    "key": "tag",
    "id": 2,
    "data": [],
    "value": [],
  },
  {
    "type": "Date",
    "id": 3,
    "key": "date",
    'fromDate': '',
    'toDate': '',
    "data": [{ "name": "Today", "id": 1 },
    { "name": "Yesterday", "id": 2 },
    { "name": "Last 7 days", "id": 3 },
    { "name": "Last 15 days", "id": 4 },
    { "name": "Last month", "id": 5 },
    { "name": "Custom", "id": 6 }],
    "value": [],
  }
]
export const setDate = (ele: any, days: any) => {
  let param = ''
  if (ele.value.indexOf(6) != -1) {
    param = `date[from]=${moment(ele.fromDate).format("DD/MM/YYYY")}&date[to]=${moment(ele.toDate).format("DD/MM/YYYY")}`
  }
  else if (ele.value.indexOf(1) != -1) {
    param = `date[to]=${moment().format("DD/MM/YYYY")}&date[from]=${moment().format("DD/MM/YYYY")}`
  }
  else if (ele.value.indexOf(5) != -1) {
    const lastmonthlastdate = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY')
    const lastmonthfirstdate = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY')
    param = `date[to]=${lastmonthfirstdate}&date[from]=${lastmonthlastdate}`
  }
  else {
    param = `date[from]=${moment().subtract(days[ele.value[0]], 'days').format("DD/MM/YYYY")}&date[to]=${moment().format("DD/MM/YYYY")}`
  }
  return param;
}

export const getTypeOfListQparam = (headingParams: any) => {
  let api = '';
  if (headingParams === 'popular') {
    api = '&is_popular=true';
  } else if (headingParams === 'trending') {
    api = '&is_trending=true';
  } else if (headingParams === 'recommend') {
    api = '&is_recommended=true';
  }
  return api;
}

export const getTypeOfFilter = (headingParams: any, isQuiz: any) => {
  let api = '';
  if (headingParams === 'popular') {
    api = isQuiz ? 'is_popular_quiz' : 'is_popular_assessment';
  } else if (headingParams === 'trending') {
    api = isQuiz ? 'is_trending_quiz' : 'is_trending_assessment';
  } else if (headingParams === 'recommend') {
    api = isQuiz ? 'is_recommended_quiz' : 'is_recommended_assessment';
  }
  return api;

}

export const getCaption = (headingParams: any, isQuizAssessment: any) => {
  let caption = '';
  if (headingParams === 'popular') {
    caption = `Popular ${isQuizAssessment}`;
  } else if (headingParams === 'trending') {
    caption = `Trending ${isQuizAssessment}`;
  } else if (headingParams === 'recommend') {
    caption = 'Recommended For You';
  }
  return caption;
};

export const setOptions = (item: any, params: any, queryobj: any) => {
  let query = ""
  item.value.forEach((e: any, i: any) => {
    if (item.id == 2) {
      let data = item.data.filter((el: any) => el.id == e)
      e = data[0].attributes.name
    }
    query += `&${queryobj[item.id]}=` + e
  })
  return query;
}

export const clearFilterOptions = (filterinfo: any) => {

  filterinfo = filterinfo.map((ele: any) => {
    if (ele.hasOwnProperty('value')) {
      ele.value = []
      if (ele.hasOwnProperty('fromDate') && ele.hasOwnProperty('toDate')) {
        ele.fromDate = '';
        ele.toDate = '';
      }
      else {
        ele.data = [];
      }
      return ele;
    }
  })
  return filterinfo;
}
export const isEmpty = (x: any) => {
  if (x === undefined) return true;
  if (x === null) return true;
  if (typeof x === "object") return Object.keys(x).length === 0;
  if (typeof x === "string" || Array.isArray(x)) return x.length === 0;
  return !x;
};
export const secondsToTime = (e: any) => {
  const h = Math.floor(e / 3600).toString().padStart(2, '0');
  const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0');
  const s = Math.floor(e % 60).toString().padStart(2, '0');
  return `${h}.${m}.${s}`;
}
export const notEmpty = (x: any) => !isEmpty(x);
export const getAsciiToChar = (ascii: any) => String.fromCodePoint(ascii);
export const getClassName = (classes: any, id: any, hasSubmit: any, correct_answer: any, value: any) => {
  let className = classes.radioBtn;
  if (hasSubmit && correct_answer) {
    if (value === `${id}` && `${id}` === `${correct_answer.id}`) {
      className = classes.chkRadioBtn;
    } else {
      className = value === `${id}` ? classes.chkErrRadioBtn : classes.radioBtn;
    }
  } else if (value === `${id}`) {
    className = classes.chkRadioBtn;
  }
  return className;
};

export const getCheckBox = (id: any, hasSubmit: any, correct_answer: any, GreenRadio: any, RedRadio: any) => {
  let checkBox = <GreenRadio color="primary" />;
  if (hasSubmit && correct_answer) {
    checkBox = `${id}` === `${correct_answer.id}` ? <GreenRadio color="primary" /> : <RedRadio color="primary" />;
  }
  return checkBox;
};
export const checkImage = (imageSrc: any) => {
  let img = new Image();
  try {
    img.src = imageSrc;
    return true;
  } catch (err) {
    return false;
  }
}
