
//@ts-nocheck
//@ts-ignore
import React from 'react'
import { View, Text, FlatList, Image, StyleSheet } from 'react-native'
import {
  blue,
  Bold,
  size_14pt
} from '../../Utils'
import ThemeColorContext from '../../../../../components/src/themeColorManagement/themeColorContext'
import CategoriesCard from '../cards/CategoriesCard'
import { OrangeVampireGreyColor } from '../Helper/common'

interface Props {
  title: any
  categories?: any
  selectedCategories?: any
  onCategoriesChanged: (id: any, name: any) => void
}
interface s {
  // selectedId:any,
  // selectedItem:any
}
export default class Categories extends React.Component<Props, s> {
  constructor (props: Props) {
    super(props)
  }

  locationViewBorderColor = (item: any) => {
    if (item.id === this.props.selectedCategories) return 'rgb(108,89,214)'
    else return 'transparent'
  }

  locationViewBGColor = (item: any) => {
    if (item.id === this.props.selectedCategories) return 'white'
    else return 'transparent'
  }

  locationNameColor = (item: any) => {
    if (item.id === this.props.selectedCategories) return 'rgb(108,89,214)'
    else return 'rgb(123,123,123)'
  }

  renderCatCard = (item: any) => {
    return item.id === -1 ? (
      <View
        style={[
          styles.locationNameView,
          {
            borderColor: this.locationViewBorderColor(item),
            backgroundColor: this.locationViewBGColor(item)
          }
        ]}
      >
        <Text
          style={[
            styles.location_name,
            {
              color: this.locationNameColor(item)
            }
          ]}
        >
          {item.attributes?.name + '\n' + (this.props.categories.length - 1)}
        </Text>
      </View>
    ) : (
      <View
        style={{
          width: 90,
          height: 90,
          backgroundColor: this.locationViewBGColor(item),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
          borderWidth: 1,
          borderColor: this.locationViewBorderColor(item)
        }}
      >
        <Image
          style={[styles.images]}
          source={{
            uri: item.attributes?.logo ? item.attributes?.logo : ''
          }}
        />
        <Text
          style={[
            styles.location_name,
            {
              color: this.locationNameColor(item)
            }
          ]}
          numberOfLines={2}
        >
          {item.attributes?.name}
        </Text>
        <Text
          style={[
            styles.location_count,
            {
              color: this.locationNameColor(item)
            }
          ]}
        >
          ({item?.attributes?.specialization_count})
        </Text>
      </View>
    )
  }

  render () {
    return (
      <ThemeColorContext.Consumer>
        {themeColorContext => (
          <>
            <View>
              <Text
                style={{
                  color: OrangeVampireGreyColor(themeColorContext),
                  fontSize: size_14pt,
                  fontFamily: Bold
                }}
              >
                {this.props.title}
              </Text>
              <FlatList
                data={this.props.categories}
                horizontal
                showsHorizontalScrollIndicator={false}
                renderItem={({ item }: { item: any }) => {
                  return (
                    <CategoriesCard
                      activeTheme={themeColorContext.themeColor}
                      onPress={() => {
                        this.props.onCategoriesChanged(
                          item.id,
                          item?.attributes?.name
                        )
                      }}
                    >
                      {this.renderCatCard(item)}
                    </CategoriesCard>
                  )
                }}
              />
            </View>
          </>
        )}
      </ThemeColorContext.Consumer>
    )
  }
}

const styles = StyleSheet.create({
  images: { width: 32, height: 32, resizeMode: 'center', alignSelf: 'center' },
  locationNameView: {
    width: 90,
    alignItems: 'center',
    justifyContent: 'center',
    height: 90,
    borderRadius: 10,
    borderWidth: 1
  },
  location_name: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 9,
    color: blue,
    lineHeight: 10,
    fontFamily: Bold,
    marginTop: 5
  },
  location_count: {
    alignSelf: 'center',
    fontSize: 7,
    lineHeight: 8,
    fontFamily: Bold,
    color: blue
  }
})
