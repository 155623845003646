//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Paper,
  Button,
  InputBase,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import CourseViewallWebController, {
  Props,
} from "./CourseViewAllController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import CoursesList from './CoursesList.web';
import Coursesearch from './Coursesearch.web';
import { courseImg } from "./assets";
import Pagination from '@material-ui/lab/Pagination';

import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";

export class CourseViewallWeb extends CourseViewallWebController {
  constructor(props: Props) {
    super(props);

  }
  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        this.getFilterOptions(data.key, this.state.filterdata)
      })
    }
  }
  handleToggle = (ele: any, filterinfo: any, index: any) => {
    if (filterinfo[index].value.indexOf(ele.id) == -1) {
      filterinfo[index].value.push(ele.id)
    } else {
      filterinfo[index].value.splice(filterinfo[index].value.indexOf(ele.id), 1)
    }
    this.setState({ filterdata: filterinfo })
  }
  applyFilter = (data: any) => {
    this.getCoursesBysearchCriteria(data)
  }
  clearFilter = (filterinfo: any) => {
    filterinfo = filterinfo.map((ele: any) => {
      if (ele.hasOwnProperty('value')) {
        ele.value = []
        ele.data = []
        if (ele.hasOwnProperty('fromPrice') && ele.hasOwnProperty('toPrice')) {
          ele.toPrice = ''
          ele.fromPrice = ''
        }
        return ele
      }
    })

    filterinfo[0].value.push(this.props.match?.params?.categoryId)
    this.setState({ filterdata: filterinfo, searchValue: '', ratingValue: null, sliderValue: [0, 0], selectedType: 0 }, () => {
      this.getFilterOptions('category', this.state.filterdata)
      this.getCoursesBysearchCriteria(filterinfo)
    })
  }
  handleChipsClick = (index: any, item: any) => {
    this.setState({ selectedChip: item.id }, () => {
      this.getCoursesBysearchCriteria(this.state.filterdata)
    })
  }
  handlePriceChange = (e: any, filterinfo: any, index: any) => {
    filterinfo[index][e?.target?.id] = e.target?.value
    this.setState({ filterdata: filterinfo })
  }
  handleSliderChange = (e: any, newValue: any) => {
    this.setState({ sliderValue: newValue })
  }

  handleRatingChange = (e: any, newValue: any) => {
    this.setState({ ratingValue: newValue })
  }
  valuetext = (value) => {
    return `${value}`;
  }
  handleSearchCriteria = (e: any) => {
    this.getCoursesBysearchCriteria(this.state.filterdata)
  }

  setSearcdata = (e: any) => {
    e.preventDefault();
    this.setState({ searchValue: e.target.value })
  }
  setPage = (value: any) => {
    this.setState({ pageNo: value }, () => {
      this.getCoursesBysearchCriteria(this.state.filterdata)
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginTop: '-0.25rem' }}>
          <img alt="" className="h-full" src={courseImg} />
        </Grid>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justify="center" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
            <Paper component="form" onSubmit={(e: any) => e.preventDefault()} className={classes.searchroot}>
              <InputBase
                className={classes.searchinput}
                placeholder="Search for Courses"
                data-testId="setSearcdata"
                onChange={(e) => this.setSearcdata(e)}
                value={this.state.searchValue}
                inputProps={{ "aria-label": "Search for Courses" }}
              />
              <Button variant="contained" className={classes.searchBtn} data-testId="handleSearchCriteria" onClick={(e) => this.handleSearchCriteria(e)} >Search</Button>
            </Paper>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '15px' }}>
            <Grid item xs={12} md={3} sm={12}>
              <Paper className={classes.paperCourseViewall}>
                {/* @ts-ignore */}
                <Coursesearch
                  onclick={this.handleClick}
                  handleToggle={this.handleToggle}
                  applyFilter={this.applyFilter}
                  clearFilter={this.clearFilter}
                  handlePriceChange={this.handlePriceChange}
                  selectedType={this.state.selectedType}
                  filterdata={this.state.filterdata}
                  handleSliderChange={this.handleSliderChange}
                  sliderValue={this.state.sliderValue}
                  valuetext={this.valuetext}
                  ratingValue={this.state.ratingValue}
                  handleRatingChange={this.handleRatingChange}
                  selectedSubType={this.state.selectedSubType}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} sm={12}>
              <Paper className={classes.paperserchCourseViewall}>
                <CoursesList
                  courseList={this.state.courseList}
                  pages={this.state.totalPages}
                  addBookmark={this.addBookmark}
                  removeBookmark={this.removeBookmark}
                  getAllBookmarked={this.state.getAllBookmarked}
                  setPage={this.setPage}
                  setDateTime={this.showTime}
                />
              </Paper>
              {this.state?.totalPages > 1 &&
                <Grid container >
                  <Grid xs={9} className={classes.paginator}>
                    <Pagination count={this.state.totalPages} variant="outlined" shape="rounded" data-testId="setPage" onChange={(event, val) => this.setPage(val)} />
                  </Grid>
                </Grid>}
            </Grid>
          </Grid>
        </Container>
        <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
      </React.Fragment>
    );
  }
}

// @ts-ignore
const CourseViewallWebWithRouter = withRouter(CourseViewallWeb);
const CourseViewallWebWithLoader = withLoader(CourseViewallWebWithRouter);
const CourseViewallWebToast = withSnackbar(CourseViewallWebWithLoader);
const CourseViewallWebWithStyle = withStyles((theme) =>
  createStyles({
    paperCourseViewall: {
      borderRadius: '0rem'
    },
    paperserchCourseViewall: {
      borderRadius: '0rem',
      boxShadow: 'none',
    },
    searchroot: {
      padding: "0px 0px",
      display: "flex",
      alignItems: "center",
      width: '60%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    searchinput: {
      marginLeft: theme.spacing(1),
      flex: 1,
      '& input': {
        color: "gray",
      }
    },
    searchBtn: {
      color: "#ffffff !important",
      border: "2px solid #f48b1b !important",
      padding: "6px 37px",
      backgroundColor: "#f48b1b",
      '&:hover': {
        color: "#ffffff !important",
        backgroundColor: "#f48b1b",
      }
    },
    paginator: {
      marginTop: '2rem',
      marginBottom: '2rem',
      justifyContent: 'center',
      alingItem: 'center',
      display: 'flex',
    },
  })
)((CourseViewallWebToast))
export default withTranslation()(CourseViewallWebWithStyle);