import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End

export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    location: any
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  expertProfile: any;
  totalPage: number,
  currentPage: number,
  searchinfo: string
  featureArticle: any;
  expertFeatured: any;
  popularBlogs: any;
  expertPopular: any;


  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CarrerExpertArticleViewAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFeatureArticleApiCallId: string = "";
  getExpertArticlesApiCallId: string = "";
  getPopularBlogsApiCallId: string = "";
  getExpertPopularProfileApiCallId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      expertProfile: [],
      totalPage: 1,
      currentPage: 1,
      searchinfo: '',
      featureArticle: [],
      expertFeatured: [],
      popularBlogs: [],
      expertPopular: []


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  loadData = (searchInfo?: any) => {
    let { pathname } = this.props.location;
    if (pathname?.includes('articlesviewall')) {
      this.getArticlewithCategory(searchInfo)
    } else if (pathname?.includes('expertArticlesviewall')) {
      this.getExpertProfileArticleWithFeatured()
    }
    else if (pathname?.includes('blogsviewall')) {
      this.BlogswithCategory(searchInfo)
    }
    else if (pathname?.includes('expertBlogsviewall')) {
      this.getExpertProfileArticleWithFeatured()
    }
    else if (pathname?.includes('expertpopularBlogsviewall')) {
      this.getExpertProfileWithPopular()
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getFeatureArticleApiCallId:
            this.handlefeatureArticlesApiResponce(responseJson);
            break;
          case this.getExpertArticlesApiCallId:
            this.handleExpertArticlesApiResponce(responseJson);
            break;
          case this.getPopularBlogsApiCallId:
            this.handlePopularBlogsApiResponce(responseJson);
            break;
          case this.getExpertPopularProfileApiCallId:
            this.handleUnExpertPopularApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handlefeatureArticlesApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ featureArticle: responseJson.data, totalPage: responseJson?.total_pages },);
    }
  };
  handleExpertArticlesApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ expertFeatured: responseJson.data, totalPage: responseJson?.attributes?.content_articles.total_pages });
    }
  };
  handlePopularBlogsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ popularBlogs: responseJson.data, totalPage: responseJson?.total_pages });
    }
  };
  handleUnExpertPopularApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ expertPopular: responseJson.data, totalPage: responseJson.total_pages });
    }
  };

  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)

  };
  getArticlewithCategory = (searchInfo?: any, activeCatId?: string) => {
    const search = searchInfo ? `&search=${searchInfo}` : '';
    const catId = this.props?.location?.state?.catId

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getFeatureArticleApiCallId = requestMessage.messageId;
    let url;
    if (catId) {
      url = `${configJSON.expertArticles}?page=${this.state?.currentPage}&per_page=10&category[]=${catId}?${search}`
    } else {
      url = `${configJSON.expertArticles}?page=${this.state?.currentPage}&per_page=10?${search}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getExpertProfileArticleWithFeatured = () => {
    const expertId = this.props?.location?.state?.expertid
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getExpertArticlesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}/${expertId}&is_featured=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  BlogswithCategory = (searchInfo?: any) => {
    const search = searchInfo ? `&search=${searchInfo}` : '';
    const catId = this.props?.location?.state?.catId
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPopularBlogsApiCallId = requestMessage.messageId;
    let url;
    if (catId) {
      url = `${configJSON.expertBlogs}?page=${this.state?.currentPage}&per_page=10&category[]=${catId}?${search}`
    } else {
      url = `${configJSON.expertBlogs}?page=${this.state?.currentPage}&per_page=10?${search}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getExpertProfileWithPopular = () => {
    const expertId = this.props?.location?.state?.expertid
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getExpertPopularProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}/${expertId}?is_popular=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}