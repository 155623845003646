//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Paper,
  InputBase
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import CategoriesViewallWebController, {
  Props,
} from "./CategoriesViewallController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import Category from './category.web';
import Categorysearch from './Categorysearch.web';
import SearchIcon from '@material-ui/icons/Search';
import { categoryArticalViewAll } from './CategoriesCommonutility'
import { categoryArticalExamViewAllSearch } from '../../../components/src/CommonWebCSSUtility'


export class CategoriesViewallWeb extends CategoriesViewallWebController {
  constructor(props: Props) {
    super(props);

  }
  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        if (data.id != 3) {
          this.getFilterOptionsInfo(data.key, this.state.filterdata)
        }
      })
    }
  }
  handleToggle = (ele: any, filterinfo: any, index: any) => {
    if (filterinfo[index].value.indexOf(ele.id) == -1) {
      filterinfo[index].value.push(ele.id)
    } else {
      filterinfo[index].value.splice(filterinfo[index].value.indexOf(ele.id), 1)
    }
    this.setState({ filterdata: filterinfo })
  }
  applyFilter = (data: any) => {
    this.setState({ pageNo: 0, methodIdentifier: 'filter' }, () => {
      this.getContentsBysearchCriteria(data, this.props?.match?.params?.categoryId)
    })

  }
  clearFilter = (filterinfo: any) => {
    filterinfo.map((ele: any) => {
      if (ele.hasOwnProperty('value')) {
        ele.value = []
        if (ele.hasOwnProperty('fromDate') && ele.hasOwnProperty('toDate')) {
          ele.fromDate = ''
          ele.toDate = ''
        }
        else {
          ele.data = []
        }
        return ele
      }
    })
    let data = this.state.filterdata
    data[0].value.push(this.props.match?.params?.categoryId)
    this.setState({ filterdata: this.props?.location?.state?.filterinfo ? this.props?.location?.state?.filterinfo : data, pageNo: 0, methodIdentifier: 'default', selectedType: 0 }, () => {
      this.getFilterOptionsInfo('category', this.state.filterdata)
      this.getContentsBysearchCriteria(this.state.filterdata, this.props?.match?.params?.categoryId)
    })
  }
  handleChipsClick = (index: any, item: any) => {
    this.setState({ selectedChip: item.id, methodIdentifier: 'default' }, () => {
      this.getContentsBysearchCriteria(this.state.filterdata, this.props?.match?.params?.categoryId)

    })
  }
  handleDateChange = (e: any, filterinfo: any, index: any) => {
    filterinfo[index][e?.target?.id] = e.target?.value
    this.setState({ filterdata: filterinfo })
  }
  searchData = (e: any) => {
    this.setState({ searchInfo: e.target.value, methodIdentifier: 'search' }, () => {
      this.getContentsBysearchCriteria(this.state.filterdata, this.props?.match?.params?.categoryId)
    })
  }

  setPage = (value: any) => {
    this.setState({ pageNo: value }, () => {
      if (this.state.methodIdentifier == 'default' || this.state.methodIdentifier == 'filter' || this.state.methodIdentifier == 'search') {
        this.getContentsBysearchCriteria(this.state.filterdata, this.props?.match?.params?.categoryId)
      }
    })
  }
  render() {
    const { classes } = this.props;
    const hideFilters = this.props.match?.params?.type
    return (
      <React.Fragment>

        <Grid item xs={12} className={classes.categoryViewall}>
          <Grid container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">

            <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
              <Grid item xs={1}>
                <SearchIcon color="secondary" />
              </Grid>
              <Grid item xs={11}>
                <InputBase
                  placeholder="Search Articles, Exam, Courses here"
                  data-testId="searchData"
                  classes={{
                    input: classes.inputInput,
                  }}
                  onChange={(e) => this.searchData(e)}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container maxWidth="lg">
          <Grid container justify="center" spacing={3} style={{ marginTop: '4rem' }}>
            {hideFilters === "blogs" ? null :
              <Grid item xs={12} md={3} sm={5} style={{ marginBottom: '1rem' }}>
                <Paper className={classes.paper}>
                  {/* @ts-ignore */}
                  <Categorysearch
                    data-testId="Categorysearch"
                    onclick={this.handleClick}
                    handleToggle={this.handleToggle}
                    applyFilter={this.applyFilter}
                    clearFilter={this.clearFilter}
                    handleDateChange={this.handleDateChange}
                    selectedType={this.state.selectedType}
                    filterdata={this.state.filterdata}
                  />
                </Paper>
              </Grid>
            }

            <Grid item xs={12} md={9} sm={7} style={{ marginBottom: '1rem' }}>
              <Paper className={classes.paperserch}>
                <Category
                  setPage={this.setPage}
                  {...this.state}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

// @ts-ignore
const CategoryViewallWebWithRouter = withRouter(CategoriesViewallWeb);
const CategoryViewallWebWithLoader = withLoader(CategoryViewallWebWithRouter);
const CategoryViewallWebToast = withSnackbar(CategoryViewallWebWithLoader);
const CategoryViewallWebWithStyle = withStyles((theme) =>
  createStyles({
    ...categoryArticalViewAll(theme),
    ...categoryArticalExamViewAllSearch(theme)
  })
)((CategoryViewallWebToast))
export default withTranslation()(CategoryViewallWebWithStyle);