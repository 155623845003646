import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//@ts-ignore
export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  ratingDetails: any
  cartCourses: any;
  boughtCourseList: any
  rating: number
  review: string
  getAllBookmarked: any;


}

interface SS {
  id: any;
}
export default class CarrerExpertRatingController extends BlockComponent<Props, S, SS> {

  ReviewRatingApiCallId: string = "";
  getSuggestedCourseApiCallId: string = ""
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  BookmarkedCourseApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      ratingDetails: [],
      cartCourses: [],
      boughtCourseList: [],
      rating: 0,
      review: '',
      getAllBookmarked: []
    };
    this.props.hideLoader();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.ReviewRatingApiCallId:
            this.handleratingReviewApiResponce(responseJson);
            break;
          case this.getSuggestedCourseApiCallId:
            this.handleSuggestedCourseApiResponce(responseJson);
            break;
          case this.addBookmarkApiCallId:
            this.handleAddBookmarkApiResponce(responseJson);
            break;
          case this.removeBookmarkApiCallId:
            this.handleRemoveBookmarkApiResponce(responseJson);
            break;
          case this.BookmarkedCourseApiCallId:
            this.handlBookMarkCourseApiResponce(responseJson);
            break;

          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }
  // Customizable Area Start


  handleratingReviewApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson?.data) {
      this.setState({ ratingDetails: responseJson?.data });
      this.props.openToastHandler("success", `Rating Submitted`!);
      setTimeout(() => {
        this.props.history.push("/home/careerexpert/viewall")
      }, 3000)

    }
  };
  handleSuggestedCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ boughtCourseList: responseJson.data });
    }
  };
  handleAddBookmarkApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.getAllBookmarkedCourses()
      this.props.openToastHandler("success", 'Bookmarked'!);
    }
  };
  handleRemoveBookmarkApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.success) {
      this.getAllBookmarkedCourses();
    }
  };
  handlBookMarkCourseApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ getAllBookmarked: responseJson?.data });
    }
  };
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };

  getBoughtCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getSuggestedCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBoughtCourseUrl + `/bought_courses_list`
    );
    const token = localStorage.getItem('token');

    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // review And Rating

  submitReview = (expertId: any) => {
    const { rating, review } = this.state;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.ReviewRatingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}/${expertId}/ratings?data[rating]=${rating}&data[review]=${review}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // bookmark Api Calls

  getAllBookmarkedCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.BookmarkedCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBookmarkCourseUrl
    );
    const token = localStorage.getItem('token');
    const header = { "token": token };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addBookmark = (id: any, event: any) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    if (token) {
      const headers = {
        "token": token,
        "Content-Type": configJSON.validationApiContentType
      }
      const data = {
        "bookmarkable_id": id,
        "bookmarkable_type": "course"
      }
      const apiEndPoint = configJSON.addBookmarkUrl;
      const methodType = configJSON.httpPostType;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addBookmarkApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    else {
      this.props.openToastHandler("warning", 'Please login to mark thic course as bookmark'!);
    }
  };

  removeBookmark = (id: any, event: any) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.validationApiContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const methodType = configJSON.httpPostType;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  // Customizable Area End
}