import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export const resuableFun = require("./resuableFunction")
import { sendAPIRequest } from "../../../components/src/Utility";

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  popularschools: any;
  standardsList: any;
  educationlevesList: any;
  featureSchools: any;
  topLocations: any;
  selectedLocation: any;
  schoolsListByLocation: any;
  classList: any;
  schoolsCtasList: any;
  selectedStanard: any;
  searchText: any;
  locationObj: any;
}
interface SS {
  id: any;
}

export default class SchoolsController extends BlockComponent<
  Props,
  S,
  SS
> {

  PopularSchoosApiCallId: string = "";
  standsardsApiCallId: string = "";
  educationLevelsApiCallId: string = "";
  featureSchoosApiCallId: string = "";
  topLocationsApiCallId: string = "";
  schoolsByLocationApiCallId: string = "";
  schoolCtasApiCallId: string = "";
  schoolsCTAApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      popularschools: [],
      standardsList: [],
      educationlevesList: [],
      featureSchools: [],
      topLocations: [],
      selectedLocation: 'Near You',
      schoolsListByLocation: [],
      classList: ['PLAY GROUP', 'PRE NURSEUERY', 'NURSEUERY', 'LKG', 'UKG', 'CLASS 1', 'CLASS 2', 'CLASS 3', 'CLASS 4'],
      schoolsCtasList: [],
      selectedStanard: '',
      searchText: '',
      locationObj: {},

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.PopularSchoosApiCallId:
            this.handlePopularSchoolsApiResponce(responseJson);
            break;
          case this.featureSchoosApiCallId:
            this.handleFeatureSchoolsApiResponce(responseJson);
            break;
          case this.standsardsApiCallId:
            this.handleStandardsApiResponce(responseJson);
            break;
          case this.educationLevelsApiCallId:
            this.handleEducationLevelsApiResponce(responseJson);
            break;
          case this.topLocationsApiCallId:
            this.handleLocationsApiResponce(responseJson);
            break;
          case this.schoolsByLocationApiCallId:
            this.handleSchoolsByLocationApiResponce(responseJson);
            break;
          case this.schoolCtasApiCallId:
            this.handleSchoolsCtasApiResponce(responseJson);
            break;
          case this.schoolsCTAApiCallId:
            this.handleSchoolCTAApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
      }
    }
  }

  handlePopularSchoolsApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ popularschools: responseJson?.data });
    }
  }
  handleFeatureSchoolsApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ featureSchools: responseJson?.data });
    }
  }
  handleStandardsApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ standardsList: responseJson?.data });
    }
  }
  handleEducationLevelsApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ educationlevesList: responseJson?.data });
    }
  }
  handleLocationsApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ topLocations: responseJson?.data });
    }
  }
  handleSchoolsByLocationApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ schoolsListByLocation: responseJson?.data });
    }
  }
  handleSchoolsCtasApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ schoolsListByLocation: responseJson?.data });
    }
  }
  handleSchoolCTAApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ schoolsCtasList: responseJson?.data });
    }
  }

  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  }
  getPopularSchools = () => {
    let quary = ''
    if (this.state.selectedStanard) {
      quary += '&standards[]=' + this.state.selectedStanard
    }
    if (this.state.searchText) {
      quary += '&search=' + this.state.searchText
    }
    const apiEndPoint = `${configJSON.getPopularSchools}?page=1&per_page=10&popular=true${quary}`;
    const method = configJSON.validationApiMethodType
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    this.PopularSchoosApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }

  getFeatureSchools = () => {
    let quary = ''
    if (this.state.selectedStanard) {
      quary += '&standards[]=' + this.state.selectedStanard
    }
    if (this.state.searchText) {
      quary += '&search=' + this.state.searchText
    }
    const apiEndPoint = `${configJSON.getPopularSchools}?page=1&per_page=10&featured=true${quary}`;
    const method = configJSON.validationApiMethodType
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    this.featureSchoosApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  getStandards = () => {
    const apiEndPoint = `${configJSON.getStandards}`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.standsardsApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  getEducationLevels = () => {
    const apiEndPoint = `${configJSON.getEducationLevels}`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.educationLevelsApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  getTopLocations = (location: any) => {
    const apiEndPoint = `${configJSON.getToplocations}?page=1&per_page=10&current_page=true&top_locations=true`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.topLocationsApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  numFormatter = (num: any) => {
    return resuableFun.numFormatter(num)
  }

  gotoWebsite = (data: any) => {
    if (data.attributes.website_url) {
      window.open(
        data.attributes.website_url, "_blank");
    }
    if (data.attributes.redirect_url) {
      window.open(
        data.attributes.redirect_url, "_blank");
    }
  }

  setSelectLocation = (location: any, obj: any) => {
    this.setState({ selectedLocation: location, locationObj: obj }, () => {
      this.getSchoolsByLocation(location, obj)
    })
  }
  searchByStandard = (obj: any) => {
    this.setState({ selectedStanard: obj.id }, () => {
      this.getPopularSchools()
      this.getFeatureSchools()
    })
  }
  getSchoolsByLocation = (location: any, obj: any) => {
    let queary: any = ''
    if (location == 'Near You') {
      queary += 'distance_range=5'
    }
    else {
      queary += `location[]=${obj.id}`
    }
    if (queary && this.state.searchText) {
      queary += `&search=${this.state.searchText}`
    } else if (!queary && this.state.searchText) {
      queary += `search=${this.state.searchText}`
    }
    const apiEndPoint = `${configJSON.getPopularSchools}?${queary}`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.schoolsByLocationApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  getSchoolFeaturedCtas = () => {
    const apiEndPoint = `${configJSON.getPopularSchools}?with_cta=true`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.schoolCtasApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  getSchoolCtas = () => {
    const apiEndPoint = `${configJSON.getSchoolsCollegeCtas}?module[]=school`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.schoolsCTAApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }
  searchData = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      this.getFeatureSchools()
      this.getPopularSchools();
      this.getSchoolsByLocation(this.state.selectedLocation, this.state.locationObj)
    })
  }
  navigateCta = (url: any) => {
    if (url) {
      window.open(
        url, "_blank");
    }
    else {
      this.props.openToastHandler("error", "Redirect URL is not available");
    }
  }
}
