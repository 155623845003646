Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.apiContentType = "application/json";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories?show_on_landing_page=true";
exports.subCategoryAPIEndPointMobile = "bx_block_categories/sub_categories?language=en";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.categoriesAPIEndPoint = "bx_block_categories/categories?show_on_landing_page=true";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";
exports.artcledetailsAPIEndPoint = "bx_block_contentmanagement/contents";
exports.getContents = "bx_block_contentmanagement/contents";
exports.ctalistAPIEndPoint = "bx_block_categories/cta";

exports.contentTypelistAPIEndPoint = "bx_block_contentmanagement/content_types";
exports.providerslistAPIEndPoint = "bx_block_contentmanagement/content_providers";
exports.courselistAPIEndPoint = "bx_block_contentmanagement/courses";
exports.languagelistAPIEndPoint = "bx_block_languageoptions/languages";

exports.getBoughtCourseUrl = "bx_block_contentmanagement/";
exports.purchaseCoursesUrl = "bx_block_contentmanagement/course_orders";
exports.examDetailsUrl = "bx_block_contentmanagement/exams"

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";

exports.addBookmarkUrl = "bx_block_contentmanagement/bookmarks";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";
exports.getTrendingCourseUrl = "bx_block_contentmanagement/courses?is_trending=true";
exports.getNewCourseUrl = "bx_block_contentmanagement/courses?new_courses=true";
exports.getFascinatingCourseUrl = "bx_block_contentmanagement/courses?is_popular=true";
exports.getBookmarkCourseUrl = "bx_block_contentmanagement/bookmarks?bookmarkable=course";
exports.getEpubListUrl = "bx_block_contentmanagement/epubs";
exports.getPodcastUrl = "bx_block_contentmanagement/audio_podcasts";
exports.addGetCartCourse = "bx_block_contentmanagement/course_carts";
exports.startCourseFreeTrail = "bx_block_contentmanagement/courses/free_trail";
exports.getTagsList = "bx_block_contentmanagement/tags";
exports.getOrderIdUrl = "bx_block_payments/razorpays";
exports.getPaymentIdUrl = "bx_block_payments/razorpays/varify_signature"
exports.getUserDetailApiEndPoint = "bx_block_profile/profiles/";
exports.deleteCourseFromCart = "/bx_block_contentmanagement/course_carts";
exports.readLessonContents = `bx_block_contentmanagement/lession_contents/read_lession_contents`;
exports.getFilterOptions="bx_block_filter_items/content_management/filters"
exports.getSimilarCourseUrl="bx_block_contentmanagement/courses/similar_courses"
exports.addCourseProgress = "bx_block_contentmanagement/courses/progress_bar";

exports.daysinfo = { '1': 0, '2': 1, '3': 7, '4': 15, '5': 30, '6': '' }
exports.queryobj = { '0': 'category[]', '1': 'sub_category[]', '2': 'content_provider[]', '3': 'date', '4': 'tag[]' }
exports.filtersOptions = [
  {
    "type": "Categories",
    "key": "category",
    "id": 0,
    "data": [],
    "value": [],
  },
  {
    "type": "Sub Categories",
    "key": "sub_category",
    "id": 1,
    "data": [],
    "value": [],
  },
  {
    "type": "Content Provider",
    "key": "content_provider",
    "id": 2,
    "data": [],
    "value": [],
  },
  {
    "type": "Date",
    "key": "date",
    "id": 3,
    'fromDate': '',
    'toDate': '',
    "data": [{ "name": "Today", "id": 1 },
    { "name": "Yesterday", "id": 2 },
    { "name": "Last 7 days", "id": 3 },
    { "name": "Last 15 days", "id": 4 },
    { "name": "Last month", "id": 5 },
    { "name": "Custom", "id": 6 }],
    "value": [],
  },
  {
    "type": "Tags",
    "key": "tag",
    "id": 4,
    "data": [],
    "value": [],
  },
];






// Customizable Area End
