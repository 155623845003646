//@ts-nocheck
import React from "react";
import { GoogleIcn, AppleIcn, FacebookIcn, LinkedInIcn } from "./assets";
import {
  withStyles,
  Grid,
  createStyles,
  TextField,
  Typography,
  Button,
  Container,
  Tooltip
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Formik, Form } from "formik";
import { loginSchema } from "./EmailAccountLoginBlock.web.validation";
//@ts-ignore
import { withRouter } from "react-router";
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
//@ts-ignore
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
//@ts-ignore
import { LinkedIn } from "react-linkedin-login-oauth2";
import ConfirmOTP from "../../otp-input-confirmation/src/ConfirmOtp.web";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
export const configJSON = require("./config");
const WhiteTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff", // Solid underline on focus
    },
  },
})(TextField);
export class EmailAccountLoginWeb extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    localStorage.setItem("confirmDialog", "false");
  }
  loginType = (val: any) => {
    return val.includes("@") ? true : false;
  };
  render() {
    //@ts-ignore
    const { classes } = this.props;
    // console.log(this.props)
    return (
      <Container>
        {/* <Grid container direction="column" alignItems="center"> */}
        <Dialog
          open={this.props.ParentCallback}
          onClose={this.handleDrawerClose}
          // width="370px"
          PaperProps={{
            style: {
              minHeight: "430px",
              width: "400px",
              borderRadius: "25px",
              backgroundColor: "#2b2b2b",
              opacity: 0.87,
              boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            },
          }}
        >
          <DialogContent>
            {localStorage.getItem("otp_token") ? (
              <ConfirmOTP ParentCCallback={this.handleDrawerClose} />
            ) : (
              <Formik
                initialValues={{
                  mobile_number: "",
                  email: "",
                  // password: "",
                  loginType: "email" || "",
                }}
                validationSchema={loginSchema}
                onSubmit={(values: any) => {
                  this.handleLogin(values);
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                  } = formikProps;
                  return (
                    //@ts-ignore
                    <Form autoComplete="off">
                      <div className="text-center md:my-4 md:mb-4">
                        <Typography variant="h4" gutterBottom color="secondary">
                          Login To My Account
                        </Typography>
                      </div>
                      <Grid container className="mt-8 p-1.2">
                        <Grid
                          container
                          alignItems="center"
                          direction="column"
                          style={{ flexWrap: "nowrap" }}
                          spacing={4}
                        >
                          <Grid item xs={12}>
                            <Tooltip
                              title={values.email}
                              aria-label={values.email}
                            >
                              <WhiteTextField
                                name="email"
                                InputLabelProps={{
                                  style: { color: "#fff" },
                                }}
                                inputProps={{ style: { textAlign: "center" } }}
                                color="primary"
                                data-testId="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.email && errors.email ? true : false
                                }
                                helperText
                                ={
                                  touched.email && errors.email
                                    ? errors.email
                                    : ""
                                }
                                placeholder="Enter your Mobile / Email"
                                variant="standard"
                                style={{ width: "15rem" }}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={10}>
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              className={classes.conBtn}
                              fullWidth
                            >
                              <Typography variant="h3">Continue</Typography>
                            </Button>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              color="secondary"
                              className={classes.loginVia}
                            >
                              ------ or Login via ------
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid container justify="space-evenly">
                              <Grid item>
                                <GoogleLogin
                                  //@ts-ignore
                                  clientId={configJSON.googleClientID}
                                  data-testId="googleLogin"
                                  render={(renderProps) => (
                                    <Button
                                      onClick={() => renderProps.onClick()}
                                      className={classes.smallBtn}
                                      variant="contained"
                                      color="secondary"
                                    >
                                      <img
                                        className={classes.img}
                                        src={GoogleIcn}
                                        style={{ position: "absolute" }}
                                      />
                                    </Button>
                                  )}
                                  onSuccess={(res: any) =>
                                    this.resultGoogleLogin(res)
                                  }
                                  onFailure={(res: any) =>
                                    this.resultGoogleLogin(res)
                                  }
                                  isSignedIn={false}
                                  autoLoad={false}
                                  prompt="consent"
                                  cookiePolicy={"single_host_origin"}
                                  accessType="offline"
                                  className={`${classes.googlelogin} ${classes.smallBtn
                                    }`}
                                />
                              </Grid>
                              <Grid item>
                                <Button
                                  data-testId="facebookLogin"
                                  className={classes.smallBtn}
                                  onMouseEnter={() =>
                                    this.setState({ fbStatus: true })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({ fbStatus: false })
                                  }
                                  variant="contained"
                                  color="secondary"
                                >
                                  <FacebookLogin
                                    // appId="859701918236670"
                                    appId="1105812036736234"
                                    autoLoad={false}
                                    textButton=""
                                    fields="name,email,picture"
                                    callback={this.resultFacebookLogin}
                                    //@ts-ignore
                                    className={`${classes.fblogin}`}
                                    icon={
                                      <img
                                        className={`${classes.fbimg}`}
                                        //  ${this.state.fbStatus ?`animate__animated animate__slideOutUp` :''}  `}
                                        src={FacebookIcn}
                                      />
                                    }
                                  />
                                </Button>
                              </Grid>

                              <Grid item>
                                <AppleLogin
                                  data-testId="appleLogin"
                                  clientId={configJSON.appleClientID}
                                  redirectURI={configJSON.appleRedirectURI}
                                  scope="email name"
                                  responseType="code id_token"
                                  responseMode="form_post"
                                  usePopup={true}
                                  render={(renderProps) => (
                                    <Button
                                      onClick={(res: any) =>
                                        renderProps.onClick(res)
                                      }
                                      onMouseEnter={() =>
                                        this.setState({ appleStatus: true })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({ appleStatus: false })
                                      }
                                      className={classes.smallBtn}
                                      variant="contained"
                                      color="secondary"
                                    >
                                      <img
                                        className={classes.img}
                                        //  ${this.state.appleStatus ?`animate__animated animate__slideOutUp` : ''} `}
                                        src={AppleIcn}
                                      />
                                    </Button>
                                  )}
                                  callback={(res: any) =>
                                    this.resultAppleLogin(res)
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <LinkedIn
                                  data-testId="linkedIn"
                                  clientId={configJSON.linkedClientID}
                                  redirectUri={configJSON.RedirectURI}
                                  //@ts-ignore
                                  grant_type="authorization_code"
                                  scope="r_liteprofile r_emailaddress"
                                  className={`${classes.fblogin}`}
                                  onSuccess={(res: any) => {
                                    this.resultLinkedLogin(res);
                                  }}
                                  onFailure={(res: any) =>
                                    this.resultFailureLinkedLogin(res)
                                  }
                                  //@ts-ignore
                                  renderElement={(renderProps) => (
                                    <Button
                                      onClick={() => renderProps.onClick()}
                                      onMouseEnter={() =>
                                        this.setState({ liStatus: true })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({ liStatus: false })
                                      }
                                      className={classes.smallBtn}
                                      variant="contained"
                                      color="secondary"
                                    >
                                      <img
                                        className={classes.img}
                                        //  ${this.state.liStatus ?`animate__animated animate__slideOutUp` : ''} `}
                                        src={LinkedInIcn}
                                      />
                                    </Button>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </DialogContent>
        </Dialog>
        {/* </Grid> */}
        {/* <Snackbar
          open={this.state.showToast.show}
          autoHideDuration={4000}
          onClose={this.closeToastHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeToastHandler}
            severity={this.state.showToast.type}
          >
            {this.state.showToast.message}
          </MuiAlert>
        </Snackbar> */}
      </Container>
    );
  }
}
//@ts-ignore
const EmailAccountLoginWebWithRouter = withRouter(EmailAccountLoginWeb);
const EmailAccountLoginWithLoader = withLoader(EmailAccountLoginWebWithRouter);
const EmailAccountLoginSnackBar = withSnackbar(EmailAccountLoginWithLoader);
export default withStyles((theme) =>
  createStyles({
    loginVia: {
      fontSize: "12px",
      textAlign: "center",
    },
    conBtn: {
      width: "11rem",
      borderRadius: "6.75rem",
      boxShadow: "0 6px 15px 0 rgba(100, 87, 87, 0.17)",
      border: "solid .15rem #ffffff",
      "&:hover": {
        backgroundColor: "#ffffff",
        color: "#000000",
      },
    },
    smallBtn: {
      overflow: "hidden",
      borderRadius: theme.spacing(1),
      minWidth: theme.spacing(5),
      height: theme.spacing(6),
      width: theme.spacing(5.6),
    },
    img: {
      height: theme.spacing(2.25),
      maxWidth: theme.spacing(2.5),
    },
    fbimg: {
      height: theme.spacing(2.25),
      maxWidth: theme.spacing(2.5),
      position: "relative",
      margin: "auto",
    },
    fbLogin: {
      position: "relative",
      zIndex: 1000,
    },
    googleLogin: {
      position: "relative",
      zIndex: 1000,
    },
  })
)(EmailAccountLoginSnackBar);