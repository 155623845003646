import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  categories: any;
  homeDesc: string;
  currentPage: number;
  quizData: any;
  userQuizData: any;
  quizResultData: any;
  assessmentAnswers: any;
  open: any;
  show: any;
  // Customizable Area End
}


interface SS {
  id: any;
}

export default class AssessmentTestControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAssessmentDataApiCallId: string = "";
  getuserAssessmentDataApiCallId: string = "";
  saveAssessmentApiCallId: string = "";
  startAssessmentApiCallId: string = "";
  getAssessmentResultApiCallId: string = "";
  getAssessmentAnswerApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      categories: [],
      homeDesc: "",
      currentPage: 0,
      quizData: [],
      userQuizData: [],
      quizResultData: [],
      assessmentAnswers: [],
      open: false,
      show: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getAssessmentDataApiCallId:
            this.handleAssessmentDataApiCallIdApiResponce(responseJson);
            break;
          case this.getuserAssessmentDataApiCallId:
            this.handleuserAssessmentDataApiCallIdApiResponce(responseJson);
            break;
          case this.saveAssessmentApiCallId:
            this.handlesaveAssessmentApiCallIdApiResponce(responseJson);
            break;
          case this.startAssessmentApiCallId:
            this.handlestartQuizApiCallIdApiResponce(responseJson);
            break;
          case this.getAssessmentResultApiCallId:
            this.handleAssessmentResultApiCallIdApiResponce(responseJson);
            break;
          case this.getAssessmentAnswerApiCallId:
            this.handleAssessmentAnswerApiCallIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearAssessmentTestLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }
  handleAssessmentDataApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ quizData: responseJson.data });
    }
  };
  handleuserAssessmentDataApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ userQuizData: responseJson.data });
    }
  };
  handlesaveAssessmentApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.props.openToastHandler("success", 'Your response has been submitted');
    } else if (responseJson?.errors || responseJson?.error) {
      if (responseJson?.error) {
        this.props.openToastHandler("error", responseJson?.error);
      }
    } else {
      this.props.openToastHandler("error", `Something went Wrong!`);
    }
  };
  handlestartQuizApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      console.log(responseJson?.data)
    } else if (responseJson?.errors || responseJson?.error) {
      if (responseJson?.error) {
        this.props.openToastHandler("error", responseJson?.error);
      }
    } else {
      this.props.openToastHandler("error", `Something went Wrong!`);
    }
  };
  handleAssessmentResultApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ quizResultData: responseJson.data });
    }
  };
  handleAssessmentAnswerApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ assessmentAnswers: responseJson.data, open: true });
    }
  };
  clearAssessmentTestLocalstorage = (responseJson: any) => {
    if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `. please login again`!);
    }
  };
  getAssessmentData = () => {
    const quizId = this.props?.match?.params?.assessmentId;
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getAssessmentDataApiCallId = sendAPIRequest(`${configJSON.userAssessmentUrl}/${quizId}`,
      { headers: header })
  }
  getUserAssessmentData = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getuserAssessmentDataApiCallId = sendAPIRequest(`${configJSON.assessmentUrl}`,
      { headers: header })
  }
  saveAssessment = (data: any) => {
    const { questionId, optionId } = data;
    const quizId = this.props?.match?.params?.assessmentId;
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const payload = {
      assessment_id: quizId,
      data: {
        test_question_id: questionId,
        option_id: optionId
      }
    }
    const apiEndPoint = configJSON.saveAssessmentUrl;
    const method = configJSON.httpPostType;
    this.saveAssessmentApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...payload },
        headers: headers
      },
    );
  }
  postStartAssessment = () => {
    const quizId = this.props?.match?.params?.assessmentId;
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const payload = {
      assessment_id: quizId,
    }
    const apiEndPoint = configJSON.assessmentUrl;
    const method = configJSON.httpPostType;
    this.startAssessmentApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...payload },
        headers: headers
      },
    );
  }

  getAssessmentResult = () => {
    const quizId = this.props?.match?.params?.assessmentId;
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getAssessmentResultApiCallId = sendAPIRequest(`${configJSON.assessmentUrl}/my_score?assessment_id=${quizId}`,
      { headers: header })
  }
  getAssessmentAnswers = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getAssessmentAnswerApiCallId = sendAPIRequest(`${configJSON.assessmentAnswersUrl}?id=${this.props?.match?.params?.assessmentId}`,
      { headers: header })
  }

  // Customizable Area End
}
