import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
export type Props = RouterProps &
    StyledProps & {
        id: string;
        showAlert: any;
        showLoader: any;
        hideLoader: any;
        openToastHandler: any;
        // showToast : any;
        // Customizable Area Start
        // Customizable Area End
    };
interface S {
    // Customizable Area Start
    url: string;
    mimeType: string;
    categories: any;
    selectedChip: any;
    selectedExamChip: any;
    contents: any;
    expand: boolean;
    featureArticle: any;
    featureVideo: any;
    homeDesc: string;
    topExam: any;
    trendingCourseList: any;
    categoryId: any;
    liveStreamingVideos: any;
    getAllBookmarked: any[];
    featureEpubList: any[];
    featurePodcasList: any[];
    audioStatus: any;
    featureQuiz: any;
    assessmentsList: any;
    open: boolean;
    // Customizable Area End
}
interface SS {
    id: any;
}
export default class ActivityFeedControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getTrendingCourseApiCallId: string = '';
    bgImageVideoUrlApiCallId: string = "";
    getCategoriesApiCallId: string = "";
    categoryData: any;
    getContentsApiCallId: string = "";
    getFeatureArticleApiCallId: string = "";
    getFeatureVideoApiCallId: string = "";
    getTopExamApiCallId: string = "";
    getLiveStreamVideoApiCallId: string = "";
    addBookmarkApiCallId: string = "";
    removeBookmarkApiCallId: string = "";
    BookmarkedCourseApiCallId: string = "";
    getFeatureEpubListApiCallId: string = "";
    getFeaturePodcastListApiCallId: string = "";
    getFeatureQuizApiCallId: string = "";
    getFeatureAssessmentApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage)
        ];
        this.state = {
            url: "",
            mimeType: "",
            categories: [],
            selectedChip: localStorage.getItem("selectedChip") !== null && localStorage.getItem("selectedChip") !== undefined ? localStorage.getItem("selectedChip") : 0,
            selectedExamChip: 0,
            contents: [],
            expand: false,
            featureArticle: [],
            featureVideo: [],
            homeDesc: '',
            topExam: [],
            trendingCourseList: [],
            categoryId: localStorage.getItem("categoryId") !== null && localStorage.getItem("categoryId") !== undefined ? localStorage.getItem("categoryId") : 4,
            liveStreamingVideos: [],
            getAllBookmarked: [],
            featureEpubList: [],
            featurePodcasList: [],
            audioStatus: '',
            featureQuiz: [],
            assessmentsList: [],
            open: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    getFeature = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ featureArticle: responseJson.data });
        }
    }
    getFeatureVideo = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ featureVideo: responseJson.data });
        }
    }
    getTopExam = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ topExam: responseJson.data });
        }
    }
    getTrendingCourse = (responseJson: any) => {
        if (responseJson && !responseJson?.errors && responseJson?.data) {
            this.setState({ trendingCourseList: responseJson.data });
        } else if (responseJson?.errors) {
            if (responseJson.errors[0].token) {
                // this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
            }
        }
    }
    getLiveStreaming = (responseJson: any) => {
        if (responseJson && !responseJson?.errors && responseJson?.data) {
            this.setState({ liveStreamingVideos: responseJson.data });
        } else if (responseJson?.errors) {
            if (responseJson?.errors[0]?.token) {
                //   this.props.openToastHandler("error", responseJson?.errors[0].token + `please login again`!);
            }
        }
    }
    getBookmark = (responseJson: any) => {
        if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
            this.getAllBookmarkedCourses()
            this.props.openToastHandler("success", 'Bookmarked'!);
        } else if (responseJson?.data?.attributes?.errors) {
            if (responseJson?.data?.attributes?.errors?.account_id[0]) {
                this.props.openToastHandler("error", responseJson?.data?.attributes?.errors?.account_id[0]!);
            }
        }
    }
    removeBookmarkFunc = (responseJson: any) => {
        if (responseJson && responseJson?.success) {
            this.getAllBookmarkedCourses();
            this.props.openToastHandler("success", 'Bookmarked removed'!);
        } else if (responseJson?.data?.attributes?.errors) {
            if (responseJson?.data?.attributes?.errors?.account_id[0]) {
                this.props.openToastHandler("error", responseJson?.data?.attributes?.errors?.account_id[0]!);
            }
        }
    }
    BookmarkedCourse = (responseJson: any) => {
        if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
            this.setState({ getAllBookmarked: responseJson?.data });
        } else if (responseJson?.errors) {
            if (responseJson?.errors[0]?.token) {
                this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
                setTimeout(() => {
                    localStorage.clear();
                }, 2000)
            }
        }
    }
    getFeatureEpub = (responseJson: any) => {
        if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
            this.setState({ featureEpubList: responseJson?.data });
        } else if (responseJson && responseJson.errors) {
            if (responseJson.errors[0].token) {
                this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
                setTimeout(() => {
                    localStorage.clear();
                }, 2000)
            }
        }
    }
    getFeaturedPodcast = (responseJson: any) => {
        if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
            this.setState({ featurePodcasList: responseJson?.data });
        } else if (responseJson && responseJson.errors) {
            if (responseJson.errors[0].token) {
                this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
                setTimeout(() => {
                    localStorage.clear();
                }, 2000)
            }
        }
    }
    getFeaturedQuiz = (responseJson: any) => {
        if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
            this.setState({ featureQuiz: responseJson?.data });
        }
    }
    getFeaturedAssessment = (responseJson: any) => {
        if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
            this.setState({ assessmentsList: responseJson?.data });
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            switch (apiRequestCallId) {
                case this.bgImageVideoUrlApiCallId:
                    this.bgImageUrl(responseJson);
                    break;
                case this.getContentsApiCallId:
                    this.getContents(responseJson);
                    break;
                case this.getFeatureArticleApiCallId:
                    this.getFeature(responseJson);
                    break;
                case this.getFeatureVideoApiCallId:
                    this.getFeatureVideo(responseJson);
                    break;
                case this.getTopExamApiCallId:
                    this.getTopExam(responseJson);
                    break;
                case this.getTrendingCourseApiCallId:
                    this.getTrendingCourse(responseJson);
                    break;
                case this.getLiveStreamVideoApiCallId:
                    this.getLiveStreaming(responseJson);
                    break;
                case this.addBookmarkApiCallId:
                    this.getBookmark(responseJson);
                    break;
                case this.removeBookmarkApiCallId:
                    this.removeBookmarkFunc(responseJson);
                    break;
                case this.BookmarkedCourseApiCallId:
                    this.BookmarkedCourse(responseJson);
                    break;
                case this.getFeatureEpubListApiCallId:
                    this.getFeatureEpub(responseJson);
                    break;
                case this.getFeaturePodcastListApiCallId:
                    this.getFeaturedPodcast(responseJson);
                    break;
                case this.getFeatureQuizApiCallId:
                    this.getFeaturedQuiz(responseJson);
                    break;
                case this.getFeatureAssessmentApiCallId:
                    this.getFeaturedAssessment(responseJson);
                    break;
                default: this.errorFunc(responseJson, errorReponse);
                    break;
            }
        }
        // Customizable Area End
    }
    errorFunc = (responseJson: any, errorReponse: any) => {

        if (responseJson && responseJson.errors) {
            console.log(responseJson);
        } else if (errorReponse) {
            console.log(errorReponse)
        }
    }
    bgImageUrl = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.categoryData = localStorage.getItem('categories');
            let category = JSON.parse(this.categoryData)
            localStorage.setItem("url", responseJson.data.attributes.login_background_file)
            localStorage.setItem("mimeType", responseJson.data.attributes.mime_type)
            this.setState({
                url: responseJson.data.attributes.login_background_file,
                mimeType: responseJson.data.attributes.mime_type,
                categories: category,
                homeDesc: responseJson.data.attributes.home_page_description,
                categoryId: category && category[this.state.selectedChip].id
            });
        }
        this.props.hideLoader();
    }
    getContents = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ contents: responseJson.data });
        }
    }
    // Customizable Area Start
    backgroundSourceUrl = () => {
        this.props.showLoader();
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.bgImageVideoUrlApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.backgroundSourceUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleChipsClick = (index: any, catIndex: any) => {
        localStorage.setItem("categoryId", catIndex);
        localStorage.setItem("selectedChip", index);
        this.setState((state: any) => ({
            ...state,
            selectedChip: index,
            categoryId: catIndex
        }), () => {
            this.featureArticle();
            this.featureVideo();
            this.topExam(0);
            this.getTrendingCourses();
            this.getLiveStreamingVideo();
            this.getFeatureEpubList();
            this.getFeaturePodcastList();
            if (localStorage.getItem('token')) {
                this.getAllBookmarkedCourses();
            }
            this.featureQuiz();
            this.featureAssesments();
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getContentsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        const lang = localStorage.getItem("lang") || 'en';
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContents + `?page=1&per_page=5&category[]=${catIndex}&language=${lang}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    featureArticle = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getFeatureArticleApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContents + `?page=1&per_page=5&is_featured=true&category[]=${this.state.categoryId}&content_type[]=1`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    featureVideo = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getFeatureVideoApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContents + `?page=1&per_page=5&is_featured=true&category[]=${this.state.categoryId}&content_type[]=4&content_type[]=3`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getLiveStreamingVideo = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getLiveStreamVideoApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getLiveStreamUrl + `?category[]=${this.state.categoryId}&content_type[]=5`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    topExam = (index: any) => {
        this.setState((state: any) => ({
            ...state,
            selectedExamChip: index
        }));
        const headers = {
            "Content-Type": configJSON.validationApiContentType
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getTopExamApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.topExam + `?date[end_date]=${lastDay.toLocaleDateString('en-GB')}&date[start_date]=${firstDay.toLocaleDateString('en-GB')}&category[]=${this.state.categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getTrendingCourses = () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getTrendingCourseApiCallId = requestMessage.messageId;
        const lang = localStorage.getItem("lang") || "en";
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTrendingCourseUrl + `?language=${lang}&category[]=${this.state.categoryId} `
        );
        const token = localStorage.getItem('token');
        let header: any = {
            "Content-Type": configJSON.validationApiContentType

        };
        if (token) {
            header['token'] = token
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getAllBookmarkedCourses = () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.BookmarkedCourseApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBookmarkCourseUrl
        );
        const token = localStorage.getItem('token');
        let header: any = {
            "Content-Type": configJSON.validationApiContentType

        };
        if (token) {
            header['token'] = token
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    addBookmark = (id: any) => {
        const token = localStorage.getItem('token');
        let headers: any = {
            "Content-Type": configJSON.validationApiContentType

        };
        if (token) {
            headers['token'] = token

            const data = {
                "bookmarkable_id": id,
                "bookmarkable_type": "course"
            }
            const apiEndPoint = configJSON.addBookmarkUrl;
            const methodType = configJSON.exampleAPiMethod;
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage));
            this.addBookmarkApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers));
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(data)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                methodType
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } else {
            this.openDialog(true);
        }
    }
    removeBookmark = (id: any) => {
        const token = localStorage.getItem('token');
        let headers: any = {
            "Content-Type": configJSON.exampleApiContentType
        }
        if (token) {
            headers['token'] = token
        }
        const data = {
            "bookmarkable_id": id,
            "bookmarkable_type": "course"
        }
        const apiEndPoint = configJSON.removeBookmarkUrl;
        const methodType = configJSON.exampleAPiMethod;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeBookmarkApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    getFeatureEpubList = () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getFeatureEpubListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContents + `?page=1&per_page=5&category[]=${this.state.categoryId}&content_type[]=9&is_featured=true`
        );
        let header: any = {
            "Content-Type": configJSON.validationApiContentType

        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getFeaturePodcastList = () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getFeaturePodcastListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContents + `?page=1&per_page=5&category[]=${this.state.categoryId}&content_type[]=6&is_featured=true`
        );
        let header: any = {
            "Content-Type": configJSON.validationApiContentType

        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    featureQuiz = () => {
        const headers: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        const token = localStorage.getItem('token');
        if (token) {
            headers['token'] = token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getFeatureQuizApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getQuizUrl + `?page=1&per_page=5&is_featured=true&category[]=${this.state.categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    featureAssesments = () => {
        const headers: any = {
            "Content-Type": configJSON.validationApiContentType
        };
        const token = localStorage.getItem('token');
        if (token) {
            headers['token'] = token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.getFeatureAssessmentApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userAssessmentUrl + `?page=1&per_page=5&is_featured=true&category[]=${this.state.categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    openDialog = (val: any) => {
        if (val) {
            this.setState({ open: true });
        }
        else {
            if (localStorage.getItem("confirmDialog") === "true") {
                this.setState({ open: val });
            } else {
                this.setState({ open: val });
                localStorage.clear();
            }
        }
    }
    showTime = (time: any) => {
        let n: any = parseInt(time);
        return `0${n / 60 ^ 0}`.slice(-2) + 'H :' + ('0' + n % 60).slice(-2) + 'M'
    }
}   // Customizable Area End
