//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import QuizViewallWebController, {
  Props,
} from "./QuizViewAllController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import { groupImg, bannerInImg } from "./assets";
//@ts-ignore
import { Title, CategorysBlock, QuizAssessmentSearchBlock, ScrollableQuiz } from './QuizAssesmentsUtility.web'
import { quizAssessmentcss } from './QuizAssessmentCommonutility'


export class QuizViewallWeb extends QuizViewallWebController {
  constructor(props: Props) {
    super(props);
  }

  handleChipsClick = (item: any) => {
    this.setState({ selectedChip: item.id }, () => {
      this.loadData();
    })
  }
  setQuizSearcdata = (e: any) => {
    this.setState({ searchValue: e.target.value }, () => {
      this.loadData();
    })
  }

  handleViewAll = (type: any) => {
    this.props.history.push(`/home/quizcategoryviewall/${type}/${this.state.selectedChip}`);
  }

  gotoQuiz = (qId: any) => {
    const { history } = this.props;
    history.push(`/home/quiz/${qId}`);
  }

  render() {
    const { classes } = this.props;
    const { selectedChip, categoriesData, popularQuizList, trendingQuizList, recommendQuizList } = this.state;

    const { handleViewAll, gotoQuiz } = this;
    const isLogin = () => localStorage.getItem('token');
    return (
      <React.Fragment>
        <Container maxWidth="xl" disableGutters={true}>
          <Grid item xs={12} className={classes.gridtwo}>
            <img alt="" className="h-full" src={bannerInImg} />
          </Grid>
          <Container className={classes.videoCont}>
            <Grid container direction="row" alignItems="center" justify="center" className={classes.gridcount}>
              <QuizAssessmentSearchBlock {...{
                classes,
                setSearcdata: this.setQuizSearcdata,
                placeholderetext: "Search quiz here"
              }}
              />
              <CategorysBlock {...{ classes, categoriesData, selectedChip, handleChipsClick: this.handleChipsClick }} />
            </Grid>
            <br />
            <br />
            {/* Popular quiz */}
            <Title {...{ type: 'popular', caption: 'Popular Quiz', classes, handleViewAll, quizList: popularQuizList }} />
            <br />
            <br />
            <br />
            <br />
            <ScrollableQuiz {...{ classes, gotoPage: gotoQuiz, quizList: popularQuizList, isLogin, isQuiz: true, textdata: "quiz" }} />
            <br />
            <br />
            <br />
            <br />
            {/* Trending Quiz */}
            <Title {...{ type: 'trending', classes, caption: 'Trending Quiz', handleViewAll, quizList: trendingQuizList }} />
            <br />
            <br />
            <br />
            <br />
            <ScrollableQuiz {...{ classes, gotoPage: gotoQuiz, quizList: trendingQuizList, isLogin, isQuiz: true, textdata: "quiz" }} />
            <br />
            <br />
            <br />
            <br />
            {/* Recommended for you */}
            {isLogin() &&
              <>
                <Title {...{ type: 'recommend', caption: 'Recommended For You', classes, handleViewAll, quizList: recommendQuizList }} />
                <br />
                <br />
                <br />
                <br />
                <ScrollableQuiz {...{ classes, gotoPage: gotoQuiz, quizList: recommendQuizList, isLogin, isQuiz: true, textdata: "quiz" }} />
                <br />
                <br />
              </>}
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

// @ts-ignore
const QuizViewallWebWithRouter = withRouter(QuizViewallWeb);
const QuizViewallWebWithLoader = withLoader(QuizViewallWebWithRouter);
const QuizViewallWebToast = withSnackbar(QuizViewallWebWithLoader);
const QuizViewallWebWithStyle = withStyles((theme) =>
  createStyles({
    ...quizAssessmentcss(theme, groupImg),

    gridone: {
      height: '25%',
      margin: 'auto'
    },
    gridtwo: {
      marginTop: '-0.25rem',
    },
    gridcount: {
      height: '100%',
      width: '100%',
    },
    cardItem: {
      marginTop: '0.5rem'
    }
  })
)((QuizViewallWebToast))
export default withTranslation()(QuizViewallWebWithStyle);