import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    location: any
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  expertProfile: any;
  totalPage: number,
  currentPage: number,
  searchinfo: string
  featureVideos: any;
  expertVideos: any
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class CarrerExpertVideosViewAllPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFeatureVideosApiCallId: string = "";
  getExpertVideosApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      expertProfile: [],
      totalPage: 1,
      currentPage: 1,
      searchinfo: '',
      featureVideos: [],
      expertVideos: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  loadData = (searchInfo?: any) => {
    let { pathname } = this.props.location;
    if (pathname?.includes('videosviewAll')) {
      this.getFeatureVideos(searchInfo);

    } else if (pathname?.includes('expertVideosviewall')) {

      this.getExpertVideos(searchInfo);
    }
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getFeatureVideosApiCallId:
            this.handlegetVideosApiResponce(responseJson);
            break;
          case this.getExpertVideosApiCallId:
            this.handleGetExpertVideosApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }
  handlegetVideosApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ featureVideos: responseJson.data, totalPage: responseJson.total_pages },);
    }
  };
  handleGetExpertVideosApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ expertVideos: responseJson.data, totalPage: responseJson.total_pages });
    }
  };
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)

  };
  getFeatureVideos = (searchInfo?: any) => {
    const search = searchInfo ? `&search=${searchInfo}` : '';
    const catId = this.props?.location?.state?.catId
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    let url;
    if (catId) {
      url = `${configJSON.expertVideos}?page=${this.state?.currentPage}&per_page=10&category[]=${catId}?${search}`
    } else {
      url = `${configJSON.expertVideos}?page=${this.state?.currentPage}&per_page=10?${search}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getFeatureVideosApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getExpertVideos = (searchInfo?: any) => {
    const expertId = this.props?.location?.state?.expertid
    const search = searchInfo ? `&search=${searchInfo}` : '';
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getExpertVideosApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}/${expertId}&is_featured=true${search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}