import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { displayTimeInHoursminutes } from "./CommonFunctions"

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    ParentCallback: any;

    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  categories: any[];
  selectedChip: any;
  trendingCourseList: any[];
  categoryId: any;
  fascinatingCourseList: any[];
  newCourseList: any[];
  open: boolean;
}
interface SS {
  id: any;
}


export default class CoursesPageWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: string = "";
  getTrendingCourseApiCallId: string = "";
  getFascinatingCourseApiCallId: string = "";
  getNewCourseApiCallId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      categories: [],
      selectedChip: localStorage.getItem("selectedChip") !== null && localStorage.getItem("selectedChip") !== undefined ? localStorage.getItem("selectedChip") : 0,
      trendingCourseList: [],
      categoryId: localStorage.getItem("categoryId") !== null && localStorage.getItem("categoryId") !== undefined ? localStorage.getItem("categoryId") : 4,
      fascinatingCourseList: [],
      newCourseList: [],
      open: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCategoriesApiCallId:
            this.handleCategoriesApiResponce(responseJson);
            break;
          case this.getTrendingCourseApiCallId:
            this.handleTrendingCourseApiResponce(responseJson);
            break;
          case this.getFascinatingCourseApiCallId:
            this.handleFascinatingCourseApiResponce(responseJson);
            break;
          case this.getNewCourseApiCallId:
            this.handleNewCourseApiResponce(responseJson);
            break;
          default:
            break;
        }
      }
      else if (responseJson && responseJson?.errors) {
        this.handleApiErrorResponce(responseJson);
      }
    }
    // Customizable Area End
  }

  handleApiErrorResponce = (responseJson: any) => {
    if (responseJson?.errors && responseJson?.errors[0]?.token) {
      this.props.openToastHandler("error", responseJson?.errors[0].token + `please login`!);
    }

  }

  handleCategoriesApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson?.data) {
      this.setState({
        categories: responseJson.data,
      });
    }
  }

  handleTrendingCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ trendingCourseList: responseJson.data });
    }
  }
  handleFascinatingCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ fascinatingCourseList: responseJson.data });
    }
  };
  handleNewCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ newCourseList: responseJson.data });
    }
  };


  getCategories = () => {
    this.getCategoriesApiCallId = sendAPIRequest(`${configJSON.categoriesAPIEndPoint}`,
      { headers: { "Content-Type": configJSON.apiContentType } }
    )
  }
  handleChipsClick = (index: any, catIndex: any) => {
    localStorage.setItem("categoryId", catIndex)
    localStorage.setItem("selectedChip", index)
    this.setState((state: any) => ({
      ...state,
      selectedChip: index,
      categoryId: catIndex
    }), () => {
      this.getTrendingCourses();
      this.getFascinatingCourse();
      this.getNewCourses();
    });
  }
  getTrendingCourses = () => {
    const lang = localStorage.getItem("lang") || "en";
    const token = localStorage.getItem('token');
    this.getTrendingCourseApiCallId = sendAPIRequest(configJSON.getTrendingCourseUrl + `?language=${lang}&category=${this.state.categoryId}`,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  getFascinatingCourse = () => {
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem("lang") || "en";
    this.getFascinatingCourseApiCallId = sendAPIRequest(configJSON.getFascinatingCourseUrl + `?language=${lang}&category=${this.state.categoryId}`,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  getNewCourses = () => {
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem("lang") || "en";
    this.getNewCourseApiCallId = sendAPIRequest(configJSON.getNewCourseUrl + `?language=${lang}&category=${this.state.categoryId}`,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }

  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  showTime = (time: any) => displayTimeInHoursminutes(time)

}
