//@ts-nocheck
import React from "react";
import {
  Avatar,
  Button, createStyles,
  Container,
  Grid,
  Hidden,
  InputBase,
  Typography, withStyles,
  Tabs, Tab,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import insane from 'insane';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { withSnackbar } from "../../../components/src/toast.web";
import withLoader from "../../../components/src/withLoader.web";
import { prev, next, dummyImage, play } from "./assets";
import CareerExpertController, { Props } from "./CareerExpertController.web";
import CareerExpertDialogModelWeb from "./CareerExpertDialogModel.web";
const imgUrl = "https://minio.b54423.dev.centralindia.az.svc.builder.cafe/sbucket/uploads/bx_block_contentmanagement/course/thumbnail/4/Thumbnail1.jpg";

const Title = ({ type, caption, classes, handleViewAll, ItemList, catId, subCategoryId }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
      <Hidden smDown> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
      <Hidden mdUp> <Typography align="center" variant="h3">{caption}</Typography></Hidden>
      {ItemList && ItemList.length > 0 &&
        <Button
          className={classes.viewAll}
          color="primary"
          onClick={() => handleViewAll(type, caption, catId, subCategoryId)}
          variant="outlined">
          <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
          <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
        </Button>
      }
    </Grid>);
};
const MuiTabs = ({ classes, currentTab, onTabChange, categories }) => {
  return (
    <>
      <Tabs
        value={currentTab}
        onChange={onTabChange}
        textColor="primary"
        variant="fullWidth"
        classes={{
          indicator: classes.indicator,
          root: classes.tabRoot
        }}
      >
        {categories && categories.map((_list: any, index) => (
          < Tab className={classes.tabinfo} classes={{ selected: classes.selectedTab }} label={`For ${_list?.attributes?.name}`} key={_list.id} />
        ))
        }
      </Tabs>
    </>
  )
};
const TabPanel = ({ children, currentTab, index }) => {
  if (currentTab === index) {
    return (
      <>
        {children}
      </>
    );
  }
  return null;
};
const TabContent = ({ classes, onSubCategoryChange, subCat, subItems, }) => {
  let content = subItems ? subItems : [];
  return (
    <>
      <Grid container>
        <Grid className={classes.category} container justify="center" spacing={2}>
          {content?.sub_categories?.map((_data, i, index) => (
            <>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Grid key={index} data-testId="onSubCategoryChange" onClick={() => onSubCategoryChange(i, _data.id)} className={subCat === i ? classes.categoryItemActive : classes.categoryItem} direction="column" justify="center" item>
                  <Typography align="center" className={classes.belowline} variant="h3">{_data.name}</Typography>
                  <br />
                  <Typography align="center">{_data.sub_heading}</Typography>
                  <br />
                  <Typography align="center">
                    <div className={classes.belowlinetext} />
                  </Typography>
                  <br />
                  <Typography align="center">{_data.description}</Typography>
                </Grid>
              </Grid>
            </>))}
        </Grid>
        {!(content?.sub_categories && content?.sub_categories?.length > 0) &&
          <Grid container direction="column" alignItems="center" justify="flex-start">
            <Grid item>
              <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
            </Grid>
          </Grid>}
      </Grid>
    </>
  )
};
const ScrollableExpertsProfile = ({ classes, profiles, gotoExpertProfile, token, handleprofile }) => {
  return (
    <Grid container item xs={12} className={classes.paddingExamArVi}>
      <Grid container>
        <Grid item xs={12} className="w-full">
          <ScrollMenu
            clickWhenDrag={true}
            dragging={true}
            hideArrows={true}
            hideSingleArrow={true}
            scrollToSelected={true}
            alignCenter={false}
            transition={0.3}
            translate={0}
            wheel={false}
            arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
            arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
            data={profiles &&
              profiles.map((e: any, index: number) => (
                <Grid item key={index} >
                  <Grid onClick={() => { token ? gotoExpertProfile(e?.attributes?.id) : handleprofile() }} className={classes.proBox} container direction="column" alignItems="center">
                    {e?.attributes?.image ?
                      <Avatar alt="Remy Sharp" src={e?.attributes?.image || imgUrl} className={classes.profilePic} /> :
                      <Avatar alt="Remy Sharp" src={dummyImage} className={classes.profilePic} />
                    }
                    <Typography className={classes.expertname}>{e?.attributes?.name}</Typography>
                  </Grid>
                </Grid>
              ))
            } />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {!(profiles && profiles.length > 0) &&
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item>
            <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
          </Grid>
        </Grid>}
    </Grid>);
};
const RenderBlogAndArticals = ({ type, classes, listItems, history }) => {
  return (
    <>
      <Grid container direction="column" alignItems="center" justify="flex-start" className={classes.RenderBlogAndArticals} >
        <>
          {listItems && listItems.length > 0 ? listItems.map((e: any, index: any) => (
            index < 3 &&
            <>
              < Grid item container direction="row" key={index} xs={12} sm={12} md={10} lg={10} xl={10} alignItems="flex-start" spacing={2} className={classes.RenderBlogAndArticalsContent} >
                <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
                  {e.attributes.image &&
                    <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image ? e.attributes.image : dummyImage} onClick={() => { history.push(`/home/articaldetails/${type}/${e.id}`) }} />
                  }
                </Grid>
                <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                  <Grid container direction="column" spacing={2} >
                    <Grid item>
                      <Typography variant="h3">{e.attributes.name}</Typography>
                    </Grid>
                    <Grid item>
                      {e.attributes.description.length > 300 ?
                        <div className="mt-1.5">
                          <Typography variant="subtitle2" color="primary" >
                            <span className={classes.description} dangerouslySetInnerHTML={{
                              __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/articaldetails/${type}/${e.id}'>[Read more]</a></button>`)
                            }}></span>
                          </Typography></div>
                        : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" className={classes.description}>
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300)
                          }}></span>
                        </Typography></div>
                      }
                    </Grid>
                    <Grid item>
                      {e?.attributes?.author ?
                        <Typography className={classes.authname} variant="subtitle2"
                          onClick={() => history.push('/home/blogprofile/' + e?.attributes?.author_id + '/' + e.id)}>
                          By - {e?.attributes?.author}</Typography> :
                        <Typography className={classes.authname} variant="subtitle2"></Typography>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )) :
            <Grid item>
              <Typography variant="body2">Will Update You Soon</Typography>
            </Grid>
          }
        </>
      </Grid >
      <div className={classes.spacer} />
    </>
  )
};
const RenderVideos = ({ classes, listItems }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="center" className={classes.RenderVideos}>
      <Grid item xs={12} sm={11} md={11} lg={11} xl={11} className={classes.RenderVideos}>
        <Grid container direction="row" spacing={4} >
          {listItems && listItems.length > 0 && listItems.map((e: any, index: any) => (
            index < 3 &&
            <>
              <Grid item key={e.id} lg={4} md={6} sm={6} xs={12}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                  <Grid item>
                    {e.attributes.video ?
                      <div className="relative ">
                        <video
                          autoPlay={true}
                          muted
                          loop
                          className={classes.fvPlayer}
                          key={e.attributes.video}
                        >
                          <source src={e.attributes.video}
                            type="video/mp4" />
                        </video>
                        <div className={classes.overlay}>
                          <img alt="playvideo" className={classes.play} src={play} />
                        </div>
                      </div>
                      :
                      <Typography variant="body1">No video </Typography>}
                  </Grid>
                  <Grid item className={classes.nameinfo}>
                    <Typography variant="h3">{e.attributes.name}</Typography>
                  </Grid>
                  <Grid item className={classes.nameinfo}>
                    {e.attributes.description.length > 100 ?
                      <div>
                        <Typography variant="subtitle2" color="primary" className={classes.description}>
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography></div>
                      : <div className="mt-1.5"> <Typography variant="subtitle2" color="primary" className={classes.description} >
                        <span dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100)
                        }}></span>
                      </Typography></div>}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))
          }
        </Grid>
        {listItems && listItems.length === 0 &&
          <Grid item>
            <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}
export class CareerExpertWeb extends CareerExpertController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    window.scroll(0, 0)
    this.getCategories();
  }
  setSearchdata = (e: any) => {
    const { categories } = this.state;
    let activeCategory = categories.length > 0 ? categories[this.state?.currentTab] : null;
    let activeCatId = activeCategory?.id ?? '';
    this.setState({ searchinfo: e.target?.value }, () => {
      this.loadData(activeCatId, this.state.searchinfo);
    })
  }
  onTabChange = (e, tabId) => {
    const { categories } = this.state;
    let activeCategory = categories.length > 0 ? categories[tabId] : null;
    this.loadInitialData(activeCategory)
    this.setState({ currentTab: tabId, subCat: null, subTabCategoryId: null });
  };
  onSubCategoryChange = (id, subCategoryId) => {
    this.setState({ subCat: id, subTabCategoryId: subCategoryId })
    const { categories } = this.state;
    let activeCategory = categories.length > 0 ? categories[this.state?.currentTab] : null;
    let activeCatId = activeCategory?.id;
    this.getExpertsProfiles(activeCatId, subCategoryId)
    this.getFeatureArticle(activeCatId, subCategoryId)
    this.getFeatureVideo(activeCatId, subCategoryId)
    this.getPopularBlogs(activeCatId, subCategoryId)
  };
  handleViewAll = (type, caption, catId, subCategoryId) => {
    if (type) {
      const { history } = this.props;
      history.push({ pathname: type, state: { caption, catId, subCategoryId } })
    }
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
    this.getAllUserStatus();
    this.getAllExpertStatus();
    this.getAllUserSubject();
  }
  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { setSearchdata, handleViewAll, onTabChange, onSubCategoryChange, gotoExpertProfile, onSubmit, handleprofile } = self;
    const { currentTab, subCat = null, featureArticle, featureVideo, popularBlogs, expertsProfile, tabName, dialogOpen, allUserStatus, allExpertStatus, allUserSubject, categories, activeCategoryData, subTabCategoryId } = this.state;
    const activeTabData = activeCategoryData[0]?.attributes;
    const token = localStorage.getItem('token');

    //sub_categories
    return (
      <>
        <Grid
          item
          className={classes.imgBg}
          xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.search}>
            <Grid item xs={1}>
              <SearchIcon color="secondary" />
            </Grid>
            <Grid item xs={11}>
              <InputBase
                placeholder="Search Here Experts, Articles, Exam, Courses"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                data-testId="setSearchData"
                onChange={setSearchdata}
              />
            </Grid>
          </Grid>
        </Grid>
        <Container className={classes.videoCont}>
          {/* Title card */}
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            <Typography align="center" className={classes.headname} variant="h5">Speak to Experts</Typography>
            <Typography align="center" variant="subtitle1" className={classes.subText}>Comprehensive set of startup tools for all imaginable entrepreneurial needs. Create landing pages, send <br /> emails, find freelancers. Perfect for sales, marketing, and support</Typography>
          </Grid>
          {/* Tab panel */}
          <Grid className={classes.tabs}>
            <MuiTabs data-testId="MuiTabs" {...{ classes, currentTab, onTabChange, categories }} />
            {
              categories.map((x, i) => (
                <TabPanel {...{ currentTab, index: i }} key={i}>
                  <TabContent data-testId="TabContent"  {...{ classes, subCat, subItems: activeTabData, onSubCategoryChange, tabName }} />
                </TabPanel>
              ))
            }
          </Grid>
          {localStorage.getItem("token") ?
            <div>
              <Button variant="contained" size="large" className={classes.verticalBtn} data-testId="handleOpenDialog" onClick={this.handleOpenDialog}>Request</Button>
            </div> : null
          }
          {/* Available expert */}
          <Title {...{ type: '/home/careerexpert/viewall', caption: 'Available Experts', classes, handleViewAll, ItemList: expertsProfile, catId: activeTabData?.id, subCategoryId: subTabCategoryId }} />
          <ScrollableExpertsProfile data-testId="scrollableExpertsProfile" {...{ classes, profiles: expertsProfile, gotoExpertProfile, token, handleprofile }} />
          {/* Featured Article */}
          <Title {...{ type: '/home/carrerexpert/articlesviewall', caption: 'Articles', classes, handleViewAll, ItemList: featureArticle, catId: activeTabData?.id, subCategoryId: subTabCategoryId }} />
          <RenderBlogAndArticals data-testId="renderBlogAndArticals" {...{ classes, type: 'articles', listItems: featureArticle, history: this.props.history }} />
          {/* Featured Video */}
          <Title {...{ type: '/home/carrerexpert/videosviewAll', caption: 'Videos', classes, handleViewAll, ItemList: featureVideo, catId: activeTabData?.id, subCategoryId: subTabCategoryId }} />
          <RenderVideos data-testId="renderVideos" {...{ type: 'videos', classes, listItems: featureVideo, history: this.props.history }} />
          {/* Popular Blogs */}
          <Title data-testId="title" {...{ type: '/home/carrerexpert/blogsviewall', caption: 'Blogs', classes, handleViewAll, ItemList: popularBlogs, catId: activeTabData?.id, subCategoryId: subTabCategoryId }} />
          <RenderBlogAndArticals {...{ classes, type: 'blogs', listItems: popularBlogs, history: this.props.history }} />
          {dialogOpen &&
            <CareerExpertDialogModelWeb
              open={dialogOpen}
              onClose={() => {
                this.setState({ dialogOpen: false });
              }}
              {...{ onSubmit, allUserStatus, allExpertStatus, allUserSubject }}
            />}
        </Container>
      </>
    );
  }
}
//@ts-ignore
const CareerExpertWebWithRouter = withRouter(CareerExpertWeb);
const CareerExpertWebWithLoader = withLoader(CareerExpertWebWithRouter);
const CareerExpertWebToast = withSnackbar(CareerExpertWebWithLoader);
export const CareerExpertWebWithStyle = withStyles((theme) =>
  createStyles({
    container: {
      // marginTop: "-66px",
    },
    search: {
      width: "44rem",
      height: "3.5rem",
      borderRadius: "1.5rem",
      webkitBackdropFilter: "blur(50px)",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      marginTop: "0rem",
      paddingLeft: "1rem",
      [theme.breakpoints.down("md")]: {
        width: '22rem'
      }
    },
    inputRoot: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 'max-content'
      }
    },
    imgBg: {
      backgroundImage: 'url(https://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/static/media/cousres-all.aed0df2b.png)',
      width: "100%",
      height: "505px",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      backgroundSize: "cover",
      justifyContent: "center",
      backgroundPosition: "center",
      marginTop: "-0.25rem"
    },
    videoCont: {
      // marginTop: '-66px',
      // padding: '0px',
      maxWidth: '100%'
    },
    tabs: {
      padding: '5rem 10rem',
    },
    category: {
      width: '100%',
      minHeight: '12rem',
    },
    categoryItem: {
      // width: '260px',
      // width: '32%',
      marginTop: '1rem',
      border: '1px solid lightgray',
      cursor: 'pointer',
      padding: '0.6rem',
    },
    categoryItemActive: {
      // width: '32%',
      // width: '260px',
      marginTop: '1rem',
      border: '1px solid lightgray',
      borderTop: '2px solid',
      cursor: 'pointer',
      padding: '0.6rem',
    },
    categoryItemDiv: {
      width: '2%',
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    paddingExamArVi: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingBottom: '2rem',
      [theme.breakpoints.down("xs")]: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
      },
    },
    btn: {
      marginTop: '20px',
      padding: '6px 9px 6px 10px',
      backgroundColor: '#ff9322',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("md")]: {
        margin: '7px 0px 0 55px',
        width: 80,
        height: 25,
      },
    },
    btnTxt: {
      letterSpacing: 'normal',
      color: '#fff',
    },
    selectedTab: {
      borderRadius: 4,
      backgroundColor: '#fff',
      borderLeft: '1px solid #d0caca',
      borderRight: '1px solid #d0caca',
    },
    tabRoot: {
      border: '1px solid #d0caca',
      borderRadius: 4,
      backgroundColor: '#f2f2f2',
    },
    indicator: {
      background: 'transparent',
    },
    proContainer: {
      width: 220,
      height: 294,
      border: '1px solid',
      margin: '1rem',
    },
    profilePic: {
      width: '12rem',
      height: '12rem'
    },
    proBox: {
      margin: '1rem'
    },
    fvPlayer: {
      height: '16rem',
      display: 'block',
      width: '18rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
    overlay: {
      position: 'absolute',
      top: '6rem',
      bottom: '0',
      left: '8rem',
      right: '0',
      height: '100%',
      width: '100%',
    },
    tabinfo: {
      textTransform: 'none',
      /*fontSize: '20px'*/
    },
    belowline: {
      paddingTop: '1rem',
    },
    belowlinetext: {
      borderBottom: '1px solid',
      width: '20%',
      margin: 'auto',
    },
    expertname: {
      color: '#f48b1b',
      marginTop: '1rem'
    },
    authname: {
      color: '#f48b1b',
      cursor: 'pointer'
    },
    nameinfo: {
      width: '18rem',
    },
    headname: {
      color: 'black',
      marginTop: '2rem'
    },
    RenderVideos: {
      marginBottom: '2rem'
    },
    RenderBlogAndArticals: {
      marginBottom: '3rem'
    },
    RenderBlogAndArticalsContent: {
      marginBottom: '2rem'
    },
    subText: {
      marginTop: '2.5rem'
    },
    description: {
      display: 'inline'
    },
    title: {
      marginBottom: '1.5rem'
    },
    tabsWrapper: {
      justifyContent: 'space-between'
    },
    cardSpace: {
      marginRight: '1rem'
    },
    tabsDescription: {
      whiteSpace: 'normal'
    },
    selectsubCatButtons: {
      // '&:hover': {
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important'
      // }
    },
    unselectsubCatButtons: {
      border: '1px solid #d0caca',
      borderRadius: 4,
      backgroundColor: 'blue',
    },
    subBtn: {
      marginTop: '20px',
      border: '1px solid #f48b1b',
      backgroundColor: '#ffffff !important',
      color: '#f48b1b !important',
      '&:hover': {
        backgroundColor: '#ffffff !important',
        color: '#f48b1b !important',
      }
    },
    subBtnTxt: {
      color: 'f48b1b'
    },
    activeSubBtn: {
      marginTop: '20px',
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important',
      }
    },
    catData: {
      marginTop: '3rem'
    },
    verticalBtn: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px',
      cursor: 'pointer',
      lineHeight: 1.75,
      border: '1px solid rgba(255, 198, 117, 0.5)',
      padding: '0.6rem 1.5rem',
      fontSize: '15px',
      top: '40%',
      right: '-40px',
      position: 'fixed',
      transform: 'rotate(90deg)',
      backgroundColor: '#f48b1b !important',
      color: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important',
      }
    },
    categoryHight: {
      cursor: "pointer"
    }
  })
)(CareerExpertWebToast);
export default withTranslation()(CareerExpertWebWithStyle);