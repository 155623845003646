//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Container, Card,
  CardMedia,
  CardActionArea,
  CardContent, IconButton,
  Typography, Tabs, Tab, Box, Button
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { TabPanel, a11yProps, styles } from "./TabPanelComp.web";
import { CourseListCardComp } from "./CourseListCardComp.web";

type shortListedJob = {
  allShortlistedJobList: any,
  props: any,
}

export const AllShortlistedJobList: React.FC<shortListedJob> = ({ allShortlistedJobList, classes, props }) => {
  return (
    allShortlistedJobList && allShortlistedJobList.length > 0 ? allShortlistedJobList.map((_data: any, idx: any) => {
      return (
        <>
          <Grid item xs={12} md={4}>
            <Card className={classes.root} >
              <CardActionArea>
                <CardMedia data-testId="CardMedia" onClick={() => props?.history?.push(`/home/jobs/all-jobs/company/${_data?.attributes?.companies[0]?.id}`, { courseDetail: _data })}
                  image={_data?.attributes?.companies[0]?.logo?.url}
                  className={classes.suggested}
                  src={_data?.attributes?.companies[0]?.logo?.url}
                  title={_data?.attributes?.name}
                />
                <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                      {_data.attributes && _data.attributes.name && <span> {_data.attributes.name} </span>}{""} Enrolled
                    </Typography>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                      123min
                    </Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <Typography
                      variant="body2"
                      className={classes.runningCoursesCardTitle}
                    >
                      {_data.attributes.job_type} {':'} </Typography>
                    <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.requirement}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {[1, 2, 3, 4, 5].map((_data: any) => {
                      return (<StarIcon key={_data} style={{ color: "f8593b", }} />);
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs={6}>
                        {_data.attributes && _data.attributes.companies && _data.attributes.companies.length >0 && _data.attributes.companies[0].name && <Typography
                          variant="caption"
                          className={classes.shortlistCardSubTitle}>
                          {_data.attributes.companies[0].name}
                        </Typography>}
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton data-testId="BookmarkIcon" aria-label="bookmark" onClick={() => props?.removeJobsBookmark(_data?.attributes?.id)}>
                          <BookmarkIcon color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <CardContent />
              </CardActionArea>
            </Card>
          </Grid>
        </>
      );
    }) :
      <Grid item xs={12}>
        <Typography variant="body2" align="center">No Job Bookmark Found</Typography>
      </Grid>
  )
}
export class MyBookmark extends React.Component {
  render() {
    const { classes, mobileView, allShortlistedJobList, allShortlistedCourseList, suggestedCourseList } = this.props;
    return (
      <Container maxWidth="xl" >
        <Grid container direction="row" justify="space-between" alignItems="center" className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
          <Grid item>
            <Box>
              <Tabs
                value={this.props.subsTabValue}
                onChange={this.props.handlebookmarkTabChange}
                classes={{ indicator: classes.indicator }}
                aria-label="secondary tabs example">
                <Tab to="/dashboard/mybookmark/courseshortlist" style={this.props.subsTabValue === 0 ? styles.tab : styles.inActiveTab}
                  label="Course Bookmark" {...a11yProps(0)} />
                <Tab to="/dashboard/mybookmark/jobshortlist" style={this.props.subsTabValue === 1 ? styles.tab : styles.inActiveTab}
                  label="Jobs Bookmark" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="flex-start" style={{ marginTop: '1rem' }} className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
          <Grid item xs={12}>
            <TabPanel value={this.props.subsTabValue} index={1}>
              <Grid container spacing={2}>
                <AllShortlistedJobList data-testId="AllShortlistedJobList" {...{ allShortlistedJobList, classes, props: this.props }} />
              </Grid>
            </TabPanel>
            <TabPanel value={this.props.subsTabValue} index={0}>
              <Grid container spacing={2}>
                {allShortlistedCourseList && allShortlistedCourseList.length > 0 ? allShortlistedCourseList.map((_data: any, idx: any) => {
                  return (
                    <>
                      <CourseListCardComp {...{ classes, props: this.props, _data, bookmarkButton: true }} />
                    </>
                  );
                }) :
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center">No Course Bookmark Found</Typography>
                  </Grid>}
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="flex-start" justify="" style={{ marginTop: '1rem' }} className={!mobileView ? `${classes.addPadding}` : `${classes.noPadding}`}>
          <Grid item xs={12} className={classes.containerSpacing}>
            <Grid container >
              <Grid item xs={11}>
                <Typography variant="h4" className={classes.runningCoursesText}>
                  Recommended courses
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.buttonPosition}>
                <Button data-testId="suggestedCourseList" variant="text" onClick={() => { this.props.history.push("/home/courses") }}>
                  {suggestedCourseList && suggestedCourseList.length > 3 &&
                    <Typography
                      variant="subtitle1"
                      className={classes.seeAllText}>
                      See all
                    </Typography>
                  }
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.containerSpacing}>
            <Grid container spacing={2}>
              {suggestedCourseList && suggestedCourseList.length > 0 ? suggestedCourseList.map((_data: any, idx: any) => {
                if (idx < 3) {
                  return (
                    <>
                      <CourseListCardComp {...{ classes, props: this.props, _data, bookmarkButton: false }} />
                    </>
                  );
                }
              }) :
                <Grid item className="text-center">
                  <Typography variant="body2">No Data Available</Typography>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const MyBookmarkWithRouter = withRouter(MyBookmark);
const MyBookmarkWithLoader = withLoader(MyBookmarkWithRouter);
const MyBookmarkToast = withSnackbar(MyBookmarkWithLoader);
const MyBookmarkWithStyle = withStyles((theme) =>
  createStyles({
    addPadding: {
      padding: "0px",
      paddingLeft: "5rem"
    },
    noPadding: {
      padding: "0px",
    },
    indicator: {
      backgroundColor: '#f48b1b',
      width: '2px'
    },
    containerSpacing: {
      marginTop: "1rem",
    },
    runningCoursesText: {
      color: "#f8593b",
    },
    selectFilter: {
      backgroundColor: "#ffffff !important",
      color: '#7764db',
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "18rem"
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c"
    },
    seeAllText: {
      color: "#826ce4",
      textAlign: 'end'
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    root: {
      borderRadius: "18px",
      height: "100%"
    },
    Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    },
    buttonPosition: {
      display: "flex",
      justifyContent: "end",
    }
  })
)(MyBookmarkToast);
export default withTranslation()(MyBookmarkWithStyle);
