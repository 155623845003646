import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { OPTION_ALL, Schools } from "./constants";
import LocationController from "./components/Helper/LocationController";
import CategoriesController from "./components/Helper/CategoriesController";
// Customizable Area End

import FilterDataController from "./filters/helper/filterCategories/FilterDataController";
import FilterSingleton from "./filters/filterHelpers/FilterSingleton";
import GlobalTags from "./components/globalTag/GlobalTags";
import { sendAPIRequest } from "../../../components/src/Utility";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  route: any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;     //loader
  userProfilePic: any;   //profilePicture store
  profilePicFromGmail: any;  //gmail
  bannerData: any        //Banner List
  activeTab: any         //tab manager
  featuredSchools: any;   //Featured Schools
  popularSchools: any;   //Popular Schools
  searchSchools: any;    //search results of schools
  location: any;         //Top 10 locations
  featuredColleges: any; //Featured Colleges
  popularColleges: any;  //Popular Colleges
  selectedLocations: any;  //Top 10 Selected Locations
  categories: any;
  selectedCategories: any;
  searchCollage: any;
  tagsList: any;
  globalTag: any;        // Global Tags for managing the tags
  collegesTag: any;
  filterLocationAndFeesCollege: any,
  filterLocationAndFeesSchool: any,


  // Customizable Area End
}

interface SS {
  id: any;
}
let controller: any

//filter parameters passed in API's
let locationParams: any;
let boards: any;
let languages: any;
let admission_processes: any;
let school_types: any;
let standards: any;
let city: any;
let total_fees: any;

let courses: any;
let specialization: any;

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {

  //global
  userProfileGetApiCallId: any;
  getBannerApiCallId: any;

  //schools
  featuredSchoolsApiCallId: any;
  popularSchoolsApiCallId: any;

  //colleges
  featuredCollegesApiCallId: any;
  popularCollegesApiCallId: any;

  //search results:
  searchSchoolsApiCallId: any;
  searchCollegesApiCallId: any;

  //top location
  locationApiCallId: any;

  //Categories
  CategoriesApiCallId: any;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      profilePicFromGmail: '',
      userProfilePic: '',
      bannerData: [],
      activeTab: Schools,
      featuredSchools: [],
      popularSchools: [],
      searchSchools: [],
      location: [],
      featuredColleges: [],
      popularColleges: [],
      selectedLocations: [],
      categories: [],
      selectedCategories: -1,
      searchCollage: [],
      tagsList: [],
      // globalTag:this.props?.route?.params?.filters,
      globalTag: [],
      collegesTag: [],
      filterLocationAndFeesCollege: '',
      filterLocationAndFeesSchool: '',

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.focus();
    const profilPicFromGmail = await getStorageData('profile_pic')
    if (profilPicFromGmail) {
      console.log("104 302 301", profilPicFromGmail)
      this.setState({ profilePicFromGmail: profilPicFromGmail })
    }
  }

  checkData(_id:any){
      let selectedParams = this.state.globalTag;
      let sortedParams: any = [];
      selectedParams.map((e: any, index: number) => {
        if (e.type === 'location') {  //Istanbul ignore next
          sortedParams.push(`${'location'}[]=${e.id}`);
        }
      });

      if (_id === -1) {  //Istanbul ignore next
        this.setState({filterLocationAndFeesSchool:''})
        this.getFeaturedSchools();
        this.getPopularSchools();
      } else {  //Istanbul ignore next
        this.setState({filterLocationAndFeesSchool: [...sortedParams]},()=>{
          this.getFeaturedSchools();
          this.getPopularSchools();
          console.log("GLOBALSELECTDVALUES++++++", this.state.filterLocationAndFeesSchool,sortedParams);
        });
      }
  }

  focus() {
    controller = new FilterDataController()
    let gtag = new GlobalTags();
    let filteredDataFromFilter = FilterSingleton.getInstance();
    this.setState({ globalTag: filteredDataFromFilter.getGlobalFilterData() }, () => {
      let schoolFilter =  this.state.globalTag?.filter((item: any)=>item?.filterType == 'school');
      let collegeFilter =  this.state.globalTag?.filter((item: any)=>item?.filterType == 'college');
      let tempLocationSchool =  gtag.getSelectedValueById(schoolFilter, 'location', 'location');
      let tempLocationCollege =  gtag.getSelectedValueById(collegeFilter, 'CollegesLocation', 'location');
      let tempCitySchool =  gtag.getSelectedValueById(schoolFilter, 'city', 'city');
      let tempCityCollege =  gtag.getSelectedValueById(collegeFilter, 'city', 'city');
      let tempFeesSchool =  gtag.getTotalFees(schoolFilter);
      let tempFeesCollege =  gtag.getTotalFees(collegeFilter);
     
      this.setState({filterLocationAndFeesSchool: [...tempLocationSchool,...tempCitySchool,...tempFeesSchool], filterLocationAndFeesCollege:[...tempLocationCollege,...tempCityCollege,...tempFeesCollege]});
    });
    this.getUserProfile();
    this.getBanner();
    this.getFeaturedSchools();
    this.getPopularSchools();
    this.getsearchSchools();
    this.getsearchCollages();
    this.getLocation();
    this.getFeaturedColleges();
    this.getPopularColleges();
    this.getCategories();

    this.props.navigation.addListener('focus', () => {
      let filteredDataFromFilter = FilterSingleton.getInstance();
      this.setState({ globalTag: filteredDataFromFilter.getGlobalFilterData() }, () => {

        let schoolFilter =  this.state.globalTag?.filter((item: any)=>item?.filterType == 'school');
        let collegeFilter =  this.state.globalTag?.filter((item: any)=>item?.filterType == 'college');
        let tempLocationSchool =  gtag.getSelectedValueById(schoolFilter, 'location', 'location');
        let tempLocationCollege =  gtag.getSelectedValueById(collegeFilter, 'CollegesLocation', 'location');
        let tempCitySchool =  gtag.getSelectedValueById(schoolFilter, 'city', 'city');
        let tempCityCollege =  gtag.getSelectedValueById(collegeFilter, 'city', 'city');
        let tempFeesSchool =  gtag.getTotalFees(schoolFilter);
        let tempFeesCollege =  gtag.getTotalFees(collegeFilter);
        this.setState({filterLocationAndFeesSchool: [...tempLocationSchool,...tempCitySchool,...tempFeesSchool], filterLocationAndFeesCollege:[...tempLocationCollege,...tempCityCollege,...tempFeesCollege]});
       
        locationParams = gtag.getSelectedValueById(this.state.globalTag, 'locations', 'locations');
        boards = gtag.getSelectedValueById(this.state.globalTag, 'board', 'boards');
        standards = gtag.getSelectedValueById(this.state.globalTag, 'standard', 'standards');
        languages = gtag.getSelectedValueByName(this.state.globalTag, 'language_of_interaction', 'languages');
        admission_processes = gtag.getSelectedValueByName(this.state.globalTag, 'AdmissionProcess', 'admission_processes');
        school_types = gtag.getSelectedValueByName(this.state.globalTag, 'SchoolType', 'school_types');
        city = gtag.getSelectedValueById(this.state.globalTag, 'city', 'city');

        specialization = gtag.getSelectedValueById(this.state.globalTag, 'specialization', 'specialization');
        courses = gtag.getSelectedValueById(this.state.globalTag, 'course', 'courses');
        total_fees = gtag.getTotalFees(this.state.globalTag);
        //  total_fees = 

        //courses = gtag.
        this.updateSchoolsTab(gtag);
        this.updateCollegesTab(gtag);
        this.updateCategories(gtag);

        
      });


    })


    

    
  }

  updateCategories(gtag: any) {
    let selectedCategories = gtag.getFirstCategory(this.state.globalTag);
    if (selectedCategories !== -1) {
      this.addSelectedCategory('' + selectedCategories);
    } else {
      this.addSelectedCategory(-1);
    }
  }

  updateSchoolsTab(gtag: any) {
    let selectedLocations = gtag.getLocationFilteredFromGlobalTag(this.state.globalTag);
    this.setState({ selectedLocations: selectedLocations }, () => {
      this.getsearchSchools();
        this.getPopularSchools();
        this.getFeaturedSchools();
        this.getLocation();
    });
  }

  updateCollegesTab(gtag: any) {
    let selectedLocations = gtag.getLocationFilteredFromGlobalTag(this.state.globalTag);
    this.setState({ selectedLocations: selectedLocations }, () => {
      this.getsearchSchools();
        this.getPopularSchools();
        this.getFeaturedSchools();
        this.getCategories();
    });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getBannerApiCallId) {
        this.setState({ bannerData: responseJson.data, isLoading: false });
      }

      else if (apiRequestCallId === this.featuredSchoolsApiCallId) {
        console.log('data from filter',responseJson.data)
        this.setState({ featuredSchools: responseJson.data, isLoading: false });
        // console.log(this.state.featuredSchools,"fetching")
      }

      else if (apiRequestCallId === this.popularSchoolsApiCallId) {
        this.setState({ popularSchools: responseJson.data, isLoading: false });
      }

      else if (apiRequestCallId === this.searchSchoolsApiCallId) {

        this.setState({ searchSchools: responseJson.data, isLoading: false });
      }


      else if (apiRequestCallId === this.locationApiCallId) {
        let locationList = responseJson.data;
        console.log('location api data',locationList)
        locationList.unshift(OPTION_ALL);
        let controller1 = new LocationController();
        this.setState({ location: locationList, selectedLocations: controller1.defaultSelector(), isLoading: false });
      }
      else if (apiRequestCallId == this.CategoriesApiCallId) {
        //  console.log('gettttt categories api data ',responseJson.data)
        let categorieList = responseJson.data;
        console.log("FAAAAAAAAAAAAA", categorieList)
        categorieList.unshift(OPTION_ALL);
        let controller2 = new CategoriesController();
        // this.setState({categories:categorieList})
        this.setState({ categories: categorieList, selectedCategories: controller2.defaultSelector(), isLoading: false })
      }

      else if (apiRequestCallId === this.featuredCollegesApiCallId) {
        this.setState({ featuredColleges: responseJson.data, isLoading: false });
      }

      else if (apiRequestCallId === this.popularCollegesApiCallId) {
        console.log('GLOBALREQUESTMESSAGEPOPULARCLGRES:', responseJson.data);

        this.setState({ popularColleges: responseJson.data, isLoading: false });
      }
      else if (apiRequestCallId === this.searchCollegesApiCallId) {
        // console.log('searchCollegesApiCallId ,',responseJson.data)
        this.setState({ searchCollage: responseJson.data, isLoading: false })
      }
      else if (apiRequestCallId === this.userProfileGetApiCallId) {
        console.log('get profile image ', responseJson.data)
        this.setState({ userProfilePic: responseJson?.data?.attributes?.user_pic })
      }


    }
    // Customizable Area End
  }


  // Customizable Area Start
  switchTabs = () => {
  
  }

  //GetUserProfile
  async getUserProfile() {
    this.showLoader()
    const token = await getStorageData('token')
    
      const header = { 
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    
    const apiEndPoint= configJSON.getProfile
    const method=configJSON.validationApiMethodType
    this.userProfileGetApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }

  //Banners
  getBanner = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiEndPoint= configJSON.bannerAPIEndPoint
    const method=configJSON.validationApiMethodType
    this.getBannerApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  };


  //featured Schools
  getFeaturedSchools = async () => {
    let gtag = new GlobalTags();
    locationParams = gtag.getSelectedValueById(this.state.globalTag, 'locations', 'locations');

    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    // feature : true req.
    const apiEndPoint= configJSON.getFeaturedSchools + `?page=${1}&per_page=${10}&featured=true${this.state.filterLocationAndFeesSchool ? '&' + this.state.filterLocationAndFeesSchool.join('&') : ''}${boards != undefined && boards?.length > 0 ? '&' + boards.join('&') : ''}${standards != undefined && standards?.length > 0 ? '&' + standards.join('&') : ''}${languages != undefined && languages?.length > 0 ? '&' + languages.join('&') : ''}${admission_processes != undefined && admission_processes?.length > 0 ? '&' + admission_processes.join('&') : ''}${school_types != undefined && school_types?.length > 0 ? '&' + school_types.join('&') : ''}`
    const method=configJSON.validationApiMethodType
    this.featuredSchoolsApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }

  //Popular Schools
  getPopularSchools = async () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    //popular : true req.
    const apiEndPoint= configJSON.getFeaturedSchools + `?page=${1}&per_page=${10}&popular=true${this.state.filterLocationAndFeesSchool ? '&' + this.state.filterLocationAndFeesSchool.join('&') : ''}${boards != undefined && boards?.length > 0 ? '&' + boards.join('&') : ''}${standards != undefined && standards?.length > 0 ? '&' + standards.join('&') : ''}${languages != undefined && languages?.length > 0 ? '&' + languages.join('&') : ''}${admission_processes != undefined && admission_processes?.length > 0 ? '&' + admission_processes.join('&') : ''}${school_types != undefined && school_types?.length > 0 ? '&' + school_types.join('&') : ''}`
    const method=configJSON.validationApiMethodType
    this.popularSchoolsApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }

  //Search Schools
  getsearchSchools = async () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    //search params : req.
    const apiEndPoint= configJSON.getSearchSchools + `?${this.state.filterLocationAndFeesSchool ? '&' + this.state.filterLocationAndFeesSchool.join('&') : ''}${boards != undefined && boards?.length > 0 ? '&' + boards.join('&') : ''}${standards != undefined && standards?.length > 0 ? '&' + standards.join('&') : ''}${languages != undefined && languages?.length > 0 ? '&' + languages.join('&') : ''}${admission_processes != undefined && admission_processes?.length > 0 ? '&' + admission_processes.join('&') : ''}${school_types != undefined && school_types?.length > 0 ? '&' + school_types.join('&') : ''}` //${this.state.categoryTemp.join('&')}&${this.state.subCategoryTemp.join('&')}
    const method=configJSON.validationApiMethodType
    this.searchSchoolsApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }

  //Search Collages
  getsearchCollages = async () => {
    this.setState({ isLoading: true })

    let urlSplit = `?${this.state.filterLocationAndFeesCollege ? '&' + this.state.filterLocationAndFeesCollege.join('&') : ''}${this.state.selectedCategories != -1 ? "&specializations[]=" + this.state.selectedCategories : ''}${courses ? '&' + courses.join('&') : ''}`
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    //search params : req.
    const apiEndPoint= configJSON.getSearchCollages + urlSplit  //${this.state.categoryTemp.join('&')}&${this.state.subCategoryTemp.join('&')}
    const method=configJSON.validationApiMethodType
    this.searchCollegesApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }


  //featured Colleges
  getFeaturedColleges = async () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    // feature : true req.
    const apiEndPoint= configJSON.getFeaturedColleges + `?page=${1}&per_page=${10}&featured=true&${'location'}[]=${this.state.selectedLocations}${this.state.filterLocationAndFeesCollege ? '&' + this.state.filterLocationAndFeesCollege.join('&') : ''}${this.state.selectedCategories != -1 ? "&specializations[]="  + this.state.selectedCategories : ''}${courses ? '&' + courses.join('&') : ''}`
    const method=configJSON.validationApiMethodType
    this.featuredCollegesApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }

  //Popular Schools
  getPopularColleges = async () => {
    this.setState({ isLoading: true })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    // feature : true req.
    const apiEndPoint= configJSON.getFeaturedColleges + `?page=${1}&per_page=${10}&popular=true&  ${'location'}[]=${this.state.selectedLocations} ${this.state.filterLocationAndFeesCollege ? '&' + this.state.filterLocationAndFeesCollege.join('&') : ''}${this.state.selectedCategories != -1 ? "&specializations[]=" + this.state.selectedCategories : ''}${courses ? '&' + courses.join('&') : ''}`
    const method=configJSON.validationApiMethodType
    this.popularCollegesApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  }


  //Get Top 10 locations
  getLocation = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiEndPoint= configJSON.getLocation + `?page=${1}&per_page=${10}&top_locations=true&current_page=schools`
    const method=configJSON.validationApiMethodType
    this.locationApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  };

  // Get Categories
  getCategories = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const apiEndPoint=  configJSON.getCategories
    const method=configJSON.validationApiMethodType
    this.CategoriesApiCallId=sendAPIRequest(apiEndPoint,{
      method,
      headers:header
    })
  };


  addSelectedLocation(selectedLocationList: []) {
    this.setState({ selectedLocations: selectedLocationList }, () => {
      this.getsearchSchools();
        this.getPopularSchools();
        this.getFeaturedSchools();
        this.checkData(selectedLocationList)
    });

  }
  addSelectedTags(TagsData: []) {
  
    this.setState({ tagsList: TagsData })
  }


  addSelectedCategory(id: any) {
    console.log('GLOBALTAG ID', id)
    this.setState({ selectedCategories: id }, () => {
      this.getFeaturedColleges();
        this.getPopularColleges();
        this.getsearchCollages();
      //let temp = controller.FilterCategoryDataFun(this.state.selectedCategories)
      //  let temp = controller.FilterCategoryDataFun(this.state.selectedCategories)
    })
  }

  getMultipleArrayOf(array: any) {
    if (array.length) {
      return '&' + array.join('&')
    }
    return '';
  }

  setGlobalFilterData() {
    let globalData = FilterSingleton.getInstance();
    globalData.setGlobalFilterData(this.state.globalTag);
  }

  showLoader = () => {
    this.setState({ isLoading: true })
  }

  hideLoader = () => {
    this.setState({ isLoading: false })
  }




  // Customizable Area End
}
