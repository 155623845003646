Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.apiContentType = "application/json";
exports.categoryApiContentType = "application/json";
// exports.categoryAPIEndPoint = "categories/categories";
// exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.categoriesAPIEndPoint = "bx_block_categories/categories";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";

exports.selectLanguage = "bx_block_languageoptions/languages?type=app_languages"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
  // Customizable Area End
