import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
//@ts-ignore
import i18n from "i18next";
//@ts-ignore
import { Trans } from "react-i18next";
export type Props = {
  id: string;
  navigation: any;
  match: any;
  // Customizable Area Start
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  open: boolean;
  subMenuOpen: boolean;
  anchorPEl: boolean;
  searchInfo: string;
  methodIdentifier: string;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DiscussionForumHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      open: false,
      // mobileView:false,
      // topDrawerOpen:false,
      subMenuOpen: false,
      anchorPEl: false,
      searchInfo: "",
      methodIdentifier: "default",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    }
    // Customizable Area End
  }
  // Customizable Area End
}
