//@ts-nocheck
import React from "react";
import {
  InputBase,
  Grid,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
  createStyles,
  withStyles,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../withLoader.web";
import DiscussionForumHeaderController, {
  Props,
} from "./DiscussionForumHeaderController.web";
//@ts-ignore
import i18n from "i18next";
import { logo } from "./assets";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/Inbox";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import EmailAccountLoginBlock from "../../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import _ from "lodash";

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "0rem",
    backgroundColor: "#7764db",
    right: ".2rem",
    transform: "scale(1) translate(50%, -50%)",
    transformOrigin: " 100% 0%",
    height: "16px",
    borderRadius: "50%",
  },
}))(Badge);
class DiscussionForumHeader extends DiscussionForumHeaderController {
  constructor(props: Props) {
    super(props);
  }
  searchData = (e: any) => {
    this.props.handleSearchInfo(e);
    this.setState(
      { searchInfo: e.target.value, methodIdentifier: "search" },
      () => {
        // this.getContentsBysearchCriteria(this.state.filterdata)
      }
    );
  };
  handleProfile = (event: any) => {
    this.setState({ anchorPEl: event.currentTarget });
  };
  handlePClose = () => {
    this.setState({ anchorPEl: null });
  };
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    } else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  };
  onChange = (e) => {
    e.persist();
    const handleChangeDebounce = _.debounce((e) => {
      this.searchData(e);
    }, 1000);
    handleChangeDebounce(e);
  };
  render() {
    //@ts-ignore
    const { classes, t, i18n } = this.props;
    return (
      <AppBar
        position="static"
        color="transparent"
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "none",
          position: "fixed",
          height: "4rem",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.21)",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid xs={9}>
              <Grid container direction="row" justify="flex-start">
                <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                  <Grid container direction="row" justify="space-between">
                    <Hidden smDown>
                      <img
                        onClick={() => {
                          this.props.history.push("/home");
                        }}
                        className="left-8 h-10 w-12 relative cursor-pointer"
                        width="68px"
                        src={logo}
                      />
                    </Hidden>
                    {this.props.mobileView && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-haspopup={true}
                        onClick={this.props.handleSideDrawerOpen}
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={7} sm={5} md={5} lg={6} xl={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.categorySearch}
                  >
                    <Grid item xs={2}>
                      <SearchIcon color="primary" />
                    </Grid>
                    <Grid item xs={10}>
                      <InputBase
                        placeholder="Search Topics,Questions,Answers"
                        classes={{
                          input: classes.inputInput,
                        }}
                        // onKeyPress={(ev) => {
                        //   if (ev.key === 'Enter') {
                        //     ev.preventDefault();
                        //     this.searchData(ev)
                        //   }
                        // }}
                        onChange={this.onChange}
                        inputProps={{ "aria-label": "search" }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                  <StyledBadge
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={2}
                    className={classes.badge}
                  >
                    <InboxIcon className={classes.inbox} />
                  </StyledBadge>
                </Grid>
                <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                  {localStorage.getItem("isActive") === "true" &&
                    localStorage.getItem("token") ? (
                    <div className="flex items-center">
                      <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        onClick={this.handleProfile}
                        color="inherit"
                        style={{ padding: "0", marginRight: "0" }}
                      >
                        <PersonIcon className={classes.person} />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorPEl}
                        open={Boolean(this.state.anchorPEl)}
                        onClose={this.handlePClose}
                        classes={{
                          paper: classes.paper,
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.menuItemRoot,
                            selected: classes.menuItemSelected,
                          }}
                          onClick={() => {
                            this.props.showLoader();
                            localStorage.clear();
                            window.location.href = "/";
                          }}
                        >
                          <Typography color="primary" variant="subtitle1">
                            {" "}
                            Logout
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <Button
                        onClick={this.openDialog.bind(this)}
                        className={classes.signInBtn}
                      >
                        <Typography color="primary" variant="subtitle1">
                          {t("SignIn")}{" "}
                        </Typography>
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <EmailAccountLoginBlock
          ParentCallback={this.state.open}
          openDialog={this.openDialog}
        />
      </AppBar>
    );
  }
}
// @ts-ignore
const DiscussionForumHeaderWithRouter = withRouter(DiscussionForumHeader);
const DiscussionForumHeaderWithLoader = withLoader(
  DiscussionForumHeaderWithRouter
);
const DiscussionForumHeaderWithStyle = withStyles((theme) =>
  createStyles({
    inputInput: {
      color: "#000000",
    },
    categorySearch: {
      margin: "auto",
      // width: '309px',
      // height: '41px',
      borderRadius: "10px",
      backgroundColor: "#f5f5f5",
      padding: ".5rem",
    },
    inbox: {
      position: "relative",
      fill: "#7764db",
      cursor: "pointer",
    },
    person: {
      position: "relative",
      fill: "#7764db",
      cursor: "pointer",
      height: "1.9rem",
      width: "2.3rem",
    },
    toolbar: {
      minHeight: "62px",
    },
    signInBtn: {
      fill: "#7764db",
      cursor: "pointer",
      "&:hover": {
        color: "#7764db",
      },
    },
    menuItemRoot: {
      "&:hover": {
        borderLeft: "2px solid #7764db !important",
      },
      "&:focus": {
        borderLeft: "2px solid #7764db !important",
      },
    },
    menuItemSelected: {
      "&:hover": {
        borderLeft: "2px solid #7764db !important",
      },
      "&:focus": {
        borderLeft: "2px solid #7764db !important",
      },
    },
    toolbar: {
      minHeight: "62px",
    },
    paper: {
      backgroundColor: "#ffffff !important",
    },
  })
)(DiscussionForumHeaderWithLoader);
export default withTranslation()(DiscussionForumHeaderWithStyle);
