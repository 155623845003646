import React from "react";
import { Typography, Box } from "@material-ui/core";

export const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                //@ts-ignore
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const styles = {
    tab: {
        color: '#f48b1b',
        fontWeight: 700,
        fontFamily: 'Montserrat-Bold',
        fontSize: '18px',
        paddingTop: '.5rem',
        textTransform: 'capitalize'
    },
    inActiveTab: {
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: 400,
        fontFamily: 'Montserrat-Bold',
        fontSize: '13px',
        paddingTop: '.5rem',
        textTransform: 'capitalize'
    }
}