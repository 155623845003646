Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.httpGetType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Referrals";
exports.labelBodyText = "Referrals Body";
exports.btnExampleTitle = "CLICK ME";
exports.examDetailsUrl = "bx_block_contentmanagement/exams"
exports.addBookmarkUrl = "bx_block_contentmanagement/bookmarks";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";
exports.categoriesAPIEndPoint = "bx_block_categories/categories";
exports.providerslistAPIEndPoint = "bx_block_contentmanagement/content_providers";
exports.getTagsList = "bx_block_contentmanagement/tags";
exports.examCourseUrl = "bx_block_contentmanagement/courses";
exports.examVediosUrl = "bx_block_contentmanagement/contents";
exports.examDiscussionUrl = "bx_block_communityforum/questions";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";
exports.contactUsApiEndPoint = "bx_block_dashboard/contact_us"
exports.attemptMockTests = "bx_block_contentmanagement/user_mock_tests";
exports.getFilterOptions = "bx_block_filter_items/content_management/filters";

exports.queryobj = { '0': 'category[]', '1': 'sub_category[]', '2': 'content_provider[]', '3': 'date', '4': 'tag[]' }
exports.daysinfo = { '1': 0, '2': 1, '3': 7, '4': 15, '5': 30, '6': '' }

// Customizable Area End
