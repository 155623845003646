import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
  };
interface S {
  // Customizable Area Start
  contactus: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ContactUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  contactUsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];
    this.state = {
      contactus: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.contactUsApiCallId) {
        if (responseJson && !responseJson?.errors) {
          this.setState({ contactus: responseJson });
          this.props.openToastHandler("success", `Details submitted succesfully`)
        }
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  contactUs = (value: any) => {
    const headers = { "token": localStorage.getItem("token") || "" };
    const apiEndPoint = configJSON.contactUsApiEndPoint;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let formdata = new FormData();
    formdata.append(`data[name]`, value?.name);
    formdata.append(`data[email]`, value?.email);
    formdata.append(`data[message]`, value?.message);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
