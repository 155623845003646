import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    location: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  purchased: boolean;
  locationsList: any;
  totalAmount: any;
  filterdata: any;
  schoolsList: any;
  schoolCtasList: any;
  distancerange: any;
  rangeInfo: any;
  queryobj: any;
  queryFilterObj: any;
  searchText: any;
  pageNo: any;
  per_page: any;
  schoolsCtasList: any;
  selectedType: any;
  totalPages: any;
  currentPage: any;
}
interface SS {
  id: any;
}

export default class SchoolsSearchController extends BlockComponent<
  Props,
  S,
  SS
> {

  topLocationsApiCallId: string = "";
  schoolsBysearchInfoApiCallId: string = "";
  schoolCtasApiCallId: string = "";
  schoolsCTAApiCallId: string = "";
  getFilterOptionsId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      purchased: false,
      locationsList: [],
      schoolsList: [],
      totalAmount: 0,
      schoolCtasList: [],
      filterdata: [
        {
          "type": "Location",
          "id": 0,
          "key": "location",
          "data": [],
          "value": [],
        },
        {
          "type": "Total Fees",
          "key": "total_fee",
          "id": 1,
          "data": [],
          "value": [],
        },
        {
          "type": "Class",
          "key": "standard",
          "id": 2,
          "data": [],
          "value": [],
        },
        {
          "type": "Board",
          "id": 3,
          "key": "board",
          "data": [{ 'id': 1, "name": "CBSE" }, { 'id': 2, "name": "GSEB" }, { 'id': 3, "name": "ICSE" }, { 'id': 4, "name": "State Board" }, { 'id': 5, "name": "IB" }],
          "value": [],
        },
        {
          "type": "School Type",
          "key": "school_type",
          "id": 4,
          "data": [{ 'id': 1, "name": "Private" }, { 'id': 2, "name": "Private Aided" }],
          "value": [],
        },
        {
          "type": "Language of Interaction",
          "key": "language_of_interaction",
          "id": 5,
          "data": [],
          "value": [],
        },
        {
          "type": "Location And Distance Range",
          "key": "range",
          "id": 6,
          "data": [],
          "value": [],
        },
        {
          "type": "Admission Process",
          "key": "admission_process",
          "id": 7,
          "data": [{ 'id': 2, "name": "Closed" }, { 'id': 1, "name": "Ongoing" }],
          "value": [],
        },

      ],

      distancerange: [{
        value: 1,
        label: '1 km',
      },
      {
        value: 5,
        label: '5 km',
      },
      {
        value: 10,
        label: '10 km',
      },],
      rangeInfo: 1,
      queryobj: {
        '0': 'location[]',
        '1': 'total_fees',
        '2': 'standards[]',
        '3': 'boards[]',
        '4': 'school_types[]',
        '5': 'languages',
        '6': 'distance_range',
        '7': 'admission_processes[]',
      },
      queryFilterObj: {
        '0': 'location[]',
        '1': 'total_fees',
        '2': 'standard[]',
        '3': 'board[]',
        '4': 'school_type[]',
        '5': 'language_of_interaction',
        '6': 'distance_range',
        '7': 'admission_process',
      },
      searchText: '',
      pageNo: 1,
      per_page: 10,
      schoolsCtasList: [],
      selectedType: 0,
      totalPages: 0,
      currentPage: 1,
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.topLocationsApiCallId:
            this.handleLocationApiResponce(responseJson);
            break;
          case this.schoolsBysearchInfoApiCallId:
            this.handleSchoolsBySearchApiResponce(responseJson);
            break;
          case this.schoolCtasApiCallId:
            this.handleSchoolsCtasApiResponce(responseJson);
            break;
          case this.schoolsCTAApiCallId:
            this.handleschoolsCtaApiResponce(responseJson);
            break;
          case this.getFilterOptionsId:
            this.handleFilterOptionApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
      }
    }
  }
  handleLocationApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      let filterInfo = this.state.filterdata
      filterInfo[0].data = responseJson.data.filter((ele: any) => ele.attributes.search_count > 0)
      this.setState({ locationsList: responseJson.data, filterdata: filterInfo });
    }
  }
  handleSchoolsBySearchApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ schoolsList: responseJson.data, totalPages: responseJson?.meta?.pagination?.total_pages });
    }
  }
  handleSchoolsCtasApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ schoolCtasList: responseJson.data });
    }
  }
  handleschoolsCtaApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ schoolsCtasList: responseJson.data });
    }
  }
  handleFilterOptionApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        if (responseJson.data[keys[0]].data) {
          info[this.state.selectedType].data = responseJson.data[keys[0]].data
        }
        else {
          info[this.state.selectedType].data = responseJson.data[keys[0]]
        }
      }
      this.setState({
        filterdata: info
      });
    }
  }
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  }
  searchLocation = (e: any) => {
    this.getLocations(e.target.value)
  }
  getLocations = (name: any) => {

    let query = "?current_page=schools"
    if (name) {
      query += '&name=' + name
    }
    const apiEndPoint = `${configJSON.getToplocations}${query}`;
    const method = configJSON.validationApiMethodType
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    this.topLocationsApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  getSchoolsBySearchInfo = (quary: any) => {

    const apiEndPoint = quary ? `${configJSON.getPopularSchools}?page=${this.state.currentPage}&per_page=10&${quary}` : `${configJSON.getPopularSchools}?page=${this.state.currentPage}&per_page=10`;
    const method = configJSON.validationApiMethodType;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    this.schoolsBysearchInfoApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  getSchoolFeaturedCtas = () => {
    const apiEndPoint = `${configJSON.getPopularSchools}?with_cta=true`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.schoolCtasApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  numFormatter = (num: any) => {
    return resuableFun.numFormatter(num)
  }
  gotoWebsite = (data: any) => {
    if (data.attributes.website_url) {
      window.open(
        data.attributes.website_url, "_blank");
    }

  }
  setSearchInfo = (info: any, index: any, subindex: any) => {
    let filterInfo = this.state.filterdata
    let id: any;
    if (index == 4) {
      id = info.school_type
    }
    else if (index == 7) {
      id = info.admission_process
    }
    else if (index == 5) {
      id = info.lanuage
    }
    else if (index == 1) {
      id = subindex
    }
    else {
      id = info.id
    }

    if (filterInfo[index].value.indexOf(id) == -1) {
      filterInfo[index].value.push(id)
    }
    else {
      filterInfo[index].value = filterInfo[index].value.filter((item: any) => item !== id)
    }
    this.setState({ filterdata: filterInfo }, () => {
      // this.setSearchQuaryparameters(filterInfo)

    });
  }
  handleChange = (event: any, newValue: any, index: any) => {
    let filterInfo = this.state.filterdata
    filterInfo[index].value = [newValue]
    this.setState({ rangeInfo: newValue, filterdata: filterInfo }, () => {
      // this.setSearchQuaryparameters(filterInfo)
    })
  }
  valuetext = (value: any) => {
    return `${value} km`;
  }
  applyFilter = () => {
    this.setSearchQuaryparameters(this.state.filterdata)
  }
  clearFilter = () => {
    let filterinfo = this.state.filterdata
    filterinfo = filterinfo.map((ele: any) => {
      if (ele.hasOwnProperty('value')) {
        ele.value = []
        ele.data = []
        return ele
      }
    })
    this.setState({ filterdata: filterinfo, rangeInfo: 1, pageNo: 1, per_page: 10, searchText: '', selectedType: 0, currentPage: 1 }, () => {
      this.getFilterOptions('location', this.state.filterdata)
      this.setSearchQuaryparameters(this.state.filterdata)
    })
  }
  setSearchQuaryparameters = (filterdata: any) => {
    let query = ''
    filterdata.forEach((ele: any) => {
      if (ele.id != 1 && ele.value.length > 0) {
        query += this.notFeesfilter(ele)
      }
      if (ele.id == 1 && ele.value.length > 0) {
        query += this.feesFilter(ele)
      }
    })
    if (this.state.searchText) {
      query += query ? '&' : ''
      query += 'search=' + this.state.searchText
    }
    this.getSchoolsBySearchInfo(query)
  }
  feesFilter = (ele: any) => {
    let query = ""
    ele?.value?.forEach((e: any, i: any) => {
      if (i == 0 && !query) {
        query += `${this.state.queryobj[ele.id]}[${i}][]=${ele.data[e].min}&${this.state.queryobj[ele.id]}[${i}][]=${ele.data[e].max}`
      } else {
        query += `&${this.state.queryobj[ele.id]}[${i}][]=${ele.data[e].min}&${this.state.queryobj[ele.id]}[${i}][]=${ele.data[e].max}`
      }
    })
    return query
  }
  notFeesfilter = (ele: any) => {
    let query = ""
    ele?.value?.forEach((e: any, i: any) => {
      if (i == 0 && !query) {
        query += `${this.state.queryobj[ele.id]}=` + e
      } else {
        query += `&${this.state.queryobj[ele.id]}=` + e
      }
    })
    return query
  }
  searchData = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      this.setSearchQuaryparameters(this.state.filterdata)
    })
  }
  navigateCta = (url: any) => {
    if (url) {
      window.open(
        url, "_blank");
    }
    else {
      this.props.openToastHandler("error", "Redirect URL is not available");
    }

  }
  getSchoolCtas = () => {
    const apiEndPoint = `${configJSON.getSchoolsCollegeCtas}?module[]=school`;
    const method = configJSON.validationApiMethodType
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    this.schoolsCTAApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  getFilterOptions = (filterby: any, filterinfo: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.value.length > 0) {
        ele.value.forEach((e: any, i: any) => {
          if (ele.id == 1) {
            query += `&min[]=${ele.data[e].min}&max[]=${ele.data[e].max}`
          } else {
            query += `&${this.state.queryFilterObj[ele.id]}=` + e
          }
        })
      }
    })
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=school${query}`;
    const method = configJSON.validationApiMethodType;
    this.getFilterOptionsId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
}
    // Customizable Area End