//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
  Typography,
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import ReferEarnController, { Props } from './ReferEarnController.web';
import { refer, copy, fimage, wimage, limage, simage, timage } from "./assets";
//@ts-ignore
import "react-responsive-carousel/lib/styles/carousel.min.css";
import withLoader from "../../../components/src/withLoader.web";

export class ReferEarn extends ReferEarnController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (<>
      <Container maxWidth="xl" className={classes.p0}>
        <Grid container direction="row" className={classes.referRoot} alignItems="center" justify="center" >
          <Grid item xs={8} className={classes.banner}>
            <Grid container direction="row"
              alignItems="center" justify="space-between"
            >
              <Grid item xs={4}>
                <Typography variant='h5' >Refer and Earn! </Typography>
                <Typography className={classes.subtitle} variant='caption'>Join the Dailyhunt referral Program and earn revenue everytime an eligible referral signs up for the dailyhunt subscription.</Typography>
              </Grid>
              <Grid item xs={4}>
                <img alt="" className="h-full" src={refer} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container maxWidth="md" className={classes.bgDescription}>
          <Grid container  >
            <Grid item xs={12} sm={4}>
              <Typography variant='h3' >Your Referral Code</Typography>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.referalcode}>
              <Typography variant='body2' className={classes.authbelow} >{'https://www.example.com/ball?breath=bone#airplane'}        <img alt="" className="h-full" src={copy} /></Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" >
            <Grid item xs={12} sm={4}>
              <Typography variant='h3' >Share Directly</Typography>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.shareimg}>
              <img alt="" className="h-full" src={timage} />
              <img alt="" className="h-full" src={fimage} />
              <img alt="" className="h-full" src={limage} />
              <img alt="" className="h-full" src={wimage} />
              <img alt="" className="h-full" src={simage} />
            </Grid>
          </Grid>
        </Container>
      </Container >
    </>
    );
  }
}
//@ts-ignore
const ReferEarnRouter = withRouter(ReferEarn);
const ReferEarnWebWithLoader = withLoader(ReferEarnRouter);
const ReferEarnWebToast = withSnackbar(ReferEarnWebWithLoader);
const ReferEarnWebWithStyle = withStyles((theme) =>
  createStyles({
    p0: {
      padding: '0px'
    },

    bgDescription: {
      padding: "2.5rem",
      boxShadow: "0 3px 10px 0 rgb(0 0 0 / 10%)",
      borderRadius: "5px",
      marginTop: "-3rem",
      zIndex: 9,
      backgroundColor: "#fff"
    },
    chips: {
      borderRadius: "0.5rem",
      marginRight: "0.3rem",
      padding: "2px 15px"
    },

    banner: {
      marginTop: '1rem',
      // zIndex: -1
    },
    authtile: {
      color: '#3c59ff'
    },
    authbelow: {
      color: '#3c59ff',
      paddingTop: '4px',
      cursor: 'pointer'
    },


    referRoot: {
      backgroundColor: '#004782'
    },
    subtitle: {
      color: '#fff'
    },
    referalcode: {
      backgroundColor: '#f5f5f5',
      padding: '8px'
    },
    shareimg: {
      display: 'flex'
    }
  })
)(ReferEarnWebToast);
export default withTranslation()(ReferEarnWebWithStyle);
