'use strict';

const baseURL = '__MARKER_FOR_BACKEND_URL_REPLACEMENT';
// const baseURL =
//   'https://dailyhuntwip-54423-ruby.b54423.dev.centralindia.az.svc.builder.cafe/';
// const baseURL =
//   'https://dailyhuntwip-54423-ruby.b54423.stage.centralindia.az.svc.builder.ai/';

const getUrl = () => {
  let frontEndBaseUrl = '';
  if (baseURL.includes('dev')) {
    frontEndBaseUrl =
      'https://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe';
  } else if (baseURL.includes('stage')) {
    frontEndBaseUrl =
      'https://dailyhuntwip-54423-react-native.b54423.stage.centralindia.az.svc.builder.cafe';
  }
  return frontEndBaseUrl;
};
Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.baseURL = baseURL;
exports.frontEndBaseUrl = getUrl();
