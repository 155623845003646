//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Button,
  Hidden,
  InputBase,
  createStyles,
  Container,
  Typography,
  Card,
  CardContent
} from "@material-ui/core";
import ScrollMenu from "react-horizontal-scrolling-menu";
import JobsHomeWebController, { Props } from './JobsHomeController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { jobHeaderImg, next, prev } from "./assets";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import JobsFilter from './jobsFilter.web';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;
const RenderLocationCompany = props => {
  const { classes, positions } = props;
  const locations = [];
  positions.forEach(({ attributes }) => {
    attributes.locations.forEach(x => {
      if (x?.attributes?.city) { locations.push(x?.attributes?.city) }
    });
  });
  return (<>{locations.length > 0 && <>
    <Grid item>
      <LocationOnOutlinedIcon className={classes.locationIcon} />
    </Grid>
    <Grid item>
      <Typography variant="body2" className={classes.location}>
        {locations.filter(onlyUnique).join(', ')}
      </Typography>
    </Grid></>}</>)
}
const RenderPositionCompany = props => {
  const { positions } = props;
  let position = [];
  positions.forEach(({ attributes }) => {
    if (attributes?.job_type) { position.push(attributes?.job_type) }
  });
  position = position.filter(onlyUnique);
  position = position.length > 4 ? position.concat("...etc") : position;
  return (
    <Typography variant="h3" >  {position.join(', ')}</Typography>
  )
}
//@ts-ignore
export class CompaniesViewAll extends JobsHomeWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getJobCategories();
    if (this.props?.location?.state?.popular === 'popular') {
      this.setState({ pageHeading: 'Popular Hiring Companies' })
    } else {
      this.setState({ pageHeading: 'Trending Hiring Companies' })
    }
    {
      this.props?.location?.state?.popular === 'popular' &&
        this.showJobCategories(this.props?.location?.state?.selectedCompanyName, this.props?.location?.state?.selectedCategory)
    }
    {
      this.props?.location?.state?.trending === 'trending' &&
        this.showTrendingJobCategories(this.props?.location?.state?.selectedCompanyName, this.props?.location?.state?.selectedCategory)
    }
    this.getBannerImage();
    this.getCompaniesInfo();
  }

  filterValue = (value: any) => {
    this.setState({
      selectedLocation: value.location, selectedJobExperience: value.experience,
      selectedJobType: value.jobType, selectedDatePosted: value.datePosted, selectedSalaries: value.salary
    });
  }
  jobCategoryCompany = (classes) => {
    const { jobCategories, selectedCompanyName } = this.state
    return (
      jobCategories && jobCategories.length > 0 ?
        <ScrollMenu
          clickWhenDrag={true}
          dragging={true}
          hideArrows={true}
          hideSingleArrow={true}
          scrollToSelected={true}
          alignCenter={false}
          transition={0.3}
          translate={0}
          wheel={false}
          arrowLeft={<Grid item ><Hidden mdDown><img className="h-6 w-12 arrow-prev" src={prev} /></Hidden>
            <Hidden lgUp><img className="h-6 w-20 arrow-prev" src={prev} /></Hidden></Grid>}
          arrowRight={<Grid item><Hidden mdDown><img className="h-6 w-12 arrow-next" src={next} /></Hidden>
            <Hidden lgUp><img className="h-6 w-20 arrow-next" src={next} /></Hidden> </Grid>}
          //@ts-ignore
          data={jobCategories && jobCategories.length > 0 && [...jobCategories].sort((a, b) => a.id - b.id).map((e, index) =>
            <div className={classes.container} key={e?.attributes?.id} >
              {selectedCompanyName === index && <CheckCircleIcon color="secondary"
                className={selectedCompanyName === index ? `${classes.checkCircle}` : ``} />}
              <Button variant="contained" key={e?.attributes?.id}
                className={selectedCompanyName === index ? `${classes.selectedCompanyName}` : `${classes.companyName}`}
                onClick={() => {
                  if (this.props?.location?.state?.popular === 'popular') this.showJobCategories(index, e.id)
                  else if (this.props?.location?.state?.trending === 'trending') this.showTrendingJobCategories(index, e.id)
                }} >
                {e?.attributes?.name}{" ("}{e?.attributes?.jobs_count}{")"}</Button>
            </div>
          )}
        />
        :
        <Typography variant="body1" >No Exam Available this month</Typography>
    )
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { trendingCompanyDetails, pageHeading, companyDetails, selectedCompanyName, selectedCategory } = this.state;
    return (
      <>
        <Grid container direction="row" alignItems="center" className={classes.overlay}>
          <Grid item xs={12} className={classes.imgTag}>
            <img src={jobHeaderImg} className={classes.jobHeaderImg} alt="job-Image" />
          </Grid>
          <Grid item container direction="row" alignItems="center" className={classes.txtTag}>
            <Grid item>
              <Typography color="primary" className={classes.fontTxt} variant="h1">Search Between more than</Typography>
            </Grid>
            <Grid item className={classes.secondaryTxt}>
              <Typography variant="h1" className={classes.orangeHeadingTxt}>50,000</Typography>
              <Typography color="primary" variant="h1" className={classes.fontTxt}>Open Jobs</Typography>
            </Grid>
            <Grid item container direction="row" justify="space-around" className={classes.filters} alignItems="center">
              <Grid item xs={12} sm={10} md={7} lg={7} xl={7}>
                <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
                  <Grid item xs={1}>
                    <SearchIcon color="secondary" />
                  </Grid>
                  <Grid item xs={10}>
                    <InputBase
                      className={classes.searchinput}
                      placeholder="Search Jobs,Companies"
                      classes={{
                        input: classes.inputInput,
                      }}
                      data-testId="searchInput"
                      onKeyPress={(ev: any) => { if (ev?.key === "Enter") this.props.history.push(`/home/jobs/all-jobs/${ev?.target?.value}`) }}
                      inputProps={{ 'aria-label': 'search' }}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container maxWidth="xl">
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item md={1} lg={1} xl={1}>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Grid container direction="column" alignItems="center" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Grid container direction="row" spacing={1}>
                    <JobsFilter selectedCompanyName={selectedCompanyName} selectedCategory={selectedCategory}
                      parentCallback={this.filterValue} showJobCategories={this.showJobCategories}
                      showTrendingJobCategories={this.showTrendingJobCategories} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
                  <Grid container direction="row" alignItems="center" justify="space-evenly">
                    <Grid item xs={12} className="w-full">
                      {this.jobCategoryCompany(classes)}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction="row">
                    <Typography variant="h4" className={classes.jobHeading}>{pageHeading}</Typography>
                  </Grid>
                  <br />
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    {this.props?.location?.state?.popular == 'popular' && companyDetails && companyDetails.length > 0 ? companyDetails.map((e, i) =>
                      <Grid item xs={12} key={i}>
                        <Card className={classes.jobcontent}>
                          <CardContent>
                            <Grid container direction="row" alignItems="center" justify="space-between">
                              <Typography variant="h3" className={classes.orangeText}>{e?.attributes?.name}</Typography>
                              <img src={e?.attributes?.logo} className={classes.compLogo} />
                            </Grid>
                            <Grid container direction="row">
                              <Typography variant="h3" >Job Available ({e?.attributes?.vacant_position_count})</Typography>
                            </Grid>
                            <br />
                            <Grid container direction="row">
                              <RenderPositionCompany {...{ positions: e?.attributes?.positions, classes }} />
                            </Grid>
                            <br />
                            {e?.attributes?.salary_range && e?.attributes?.salary_range.length > 0 &&
                              <Grid container direction="row" spacing={1}>
                                <Grid item>
                                  <AccountBalanceWalletIcon />
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2">{e?.attributes?.salary_range}</Typography>
                                </Grid>
                              </Grid>}
                            <br />
                            <Grid container direction='row' alignItems='center'>
                              <Typography variant="subtitle2" >{e?.attributes?.about}</Typography>
                            </Grid>
                            <br />
                            <Grid container direction='row' alignItems='center'>
                              {e?.attributes?.tags.length > 0 && e?.attributes?.tags.map(t =>
                                <Button variant="contained" className={classes.tagChips}><Typography variant="body1">{t?.name}</Typography></Button>
                              )}
                            </Grid>
                            <br />
                            <Grid container direction="row" alignItems="center" justify="space-between">
                              <Grid item>
                                <Grid container direction="row" alignItems='center'>
                                  <RenderLocationCompany  {...{ positions: e?.attributes?.positions, classes }} />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Button variant="contained" data-testId="gotoJobs1" onClick={() => this.gotoJobs(e?.attributes?.id)} type="button" className={classes.viewBtn} >View Jobs</Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) :
                      <Grid container direction="row" alignItems="center" spacing={1}>
                        {this.props?.location?.state?.trending == 'trending' && trendingCompanyDetails && trendingCompanyDetails.length > 0 ? trendingCompanyDetails.map((e, i) =>
                          <Grid item xs={12} key={i}>
                            <Card className={classes.jobcontent}>
                              <CardContent>
                                <Grid container direction="row" alignItems="center" justify="space-between">
                                  <Typography variant="h3" className={classes.orangeText}>{e?.attributes?.name}</Typography>
                                  <img src={e?.attributes?.logo} className={classes.compLogo} />
                                </Grid>
                                <Grid container direction="row">
                                  <Typography variant="h3" >Job Available ({e?.attributes?.vacant_position_count})</Typography>
                                </Grid>
                                <br />
                                <Grid container direction="row">
                                  <RenderPositionCompany data-testId="RenderPositionCompany" {...{ positions: e?.attributes?.positions, classes }} />
                                </Grid>
                                <br />
                                <Grid container direction='row' alignItems='center'>
                                  <Typography variant="subtitle2" >{e?.attributes?.about}</Typography>
                                </Grid>
                                <br />
                                <Grid container direction='row' alignItems='center'>
                                  {e?.attributes?.tags.length > 0 && e?.attributes?.tags.map(t =>
                                    <Button variant="contained" className={classes.tagChips}><Typography variant="body1">{t?.name}</Typography></Button>
                                  )}
                                </Grid>
                                <br />
                                <Grid container direction="row" alignItems="center" justify="space-between">
                                  <Grid item>
                                    <Grid container direction="row" alignItems='center'>
                                      <RenderLocationCompany data-testId="RenderLocationCompany" {...{ positions: e?.attributes?.positions, classes }} />
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Button variant="contained" data-testId="gotoJobs2" onClick={() => this.gotoJobs(e?.attributes?.id)} type="button" className={classes.viewBtn} >View Jobs</Button>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        ) : <Grid item xs={12}><Typography variant="body2">No Jobs Available</Typography></Grid>}
                      </Grid>}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                </Grid>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
              </Grid>
            </Grid>
            <Grid item md={1} lg={1} xl={1}></Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const CompaniesViewAllWithRouter = withRouter(CompaniesViewAll);
const CompaniesViewAllWithLoader = withLoader(CompaniesViewAllWithRouter);
const CompaniesViewAllToast = withSnackbar(CompaniesViewAllWithLoader);
const CompaniesViewAllWithStyle = withStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginRight: '1rem',
      marginTop: '.5rem',
      height: '4rem'
    },
    checkCircle: {
      position: 'absolute',
      right: '-5px',
      top: '-6px',
      zIndex: '1000',
      color: '#f48b1b',
    },
    inputInput: { color: '#000000', fontSize: '16px', fontWeight: 600 },
    searchinput: {
      marginLeft: theme.spacing(1),
      flex: 1,
      '& input': {
        color: "#ffffff",
      }
    },
    categorySearch: {
      margin: 'auto',
      height: '4rem',
      borderRadius: '24px',
      padding: '1rem',
      webkitBackdropFilter: 'blur(50px)',
      backdropFilter: 'blur(50px)',
      backgroundColor: 'rgba(207, 207, 207, 0.13)'
    },
    seeLogo: {
      objectFit: 'contain',
      height: '3rem',
      width: '7rem',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.08)',
      border: 'solid 1px #f4f4f4'
    },
    purpleTxt: {
      color: '#7764db'
    },
    twoDivider: {
      width: '100%'
    },
    divider: {
      width: '100%',
      backgroundColor: '#000000'
    },
    uploadBtn: {
      height: '3rem',
      backgroundColor: '#f48b1b',
      borderRadius: '6px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff',
        borderRadius: '6px',
      }
    },
    personalisedTxt: {
      fontSize: '16px',
      fontWeight: 700,
      [theme.breakpoints.down("lg")]: {
        fontSize: '12px',
      }
    },
    personalisedBtn: {
      backgroundColor: '#f48b1b',
      height: '7rem',
      borderRadius: '6px',
      // width: '140px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff',
        height: '7rem',
        borderRadius: '6px',
      }
    },
    btnBox: {
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      border: '1px solid #e1e1e1',
      boxShadow: '0 3px 6px 0 rgba(232, 226, 226, 0.42)'
    },
    locationIcon: {
      color: '#707070'
    },
    accordion: {
      borderRadius: '4px',
      border: 'solid 1px #dec9c9',
      boxShadow: 'none'
    },
    location: {
      color: '#707070'
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    compLogo: {
      width: '72px',
      height: '58px',
      borderRadius: '8px',
      border: 'solid 1px #979797'
    },
    jobcontent: {
      borderRadius: '2px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)'
    },
    jobHeading: {
      color: '4f4f4f'
    },
    selectedCompanyName: {
      backgroundColor: '#ffffff',
      color: '#f48b1b !important',
      height: '52px',
      width: '180px',
      border: '1px solid #f48b1b',
      '&:hover': {
        backgroundColor: '#ffffff !important',
        color: '#f48b1b !important'
      }
    },
    companyName: {
      backgroundColor: '#ffffff',
      height: '52px',
      width: '180px',
      color: '#000000',
      border: '1px solid #000000',
      '&:hover': {
        backgroundColor: '#ffffff',
        color: '#000000'
      }
    },
    logo: {
      height: '7rem',
      width: '8rem'
    },
    publishBtn: {
      backgroundColor: '#f48b1b',
      height: '52px',
      // width: '140px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    viewBtn: {
      backgroundColor: '#f48b1b',
      height: '2rem',
      width: '7rem',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    fontTxt: {
      fontSize: '70px',
      [theme.breakpoints.down("xs")]: {
        fontSize: '40px',
      }
    },
    secondaryTxt: {
      display: 'flex'
    },
    overlay: {
      position: 'relative'
    },
    txtTag: {
      display: 'inline-block',
      position: 'absolute',
      paddingLeft: '9rem',
      paddingRight: '9rem',
      [theme.breakpoints.down("xs")]: {
        padding: '0rem'
      }
    },
    jobHeaderImg: {
      backgroundImage: 'linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 117%)',
      height: '45rem',
      objectFit: 'fill',
      width: '100%'
    },
    orangeHeadingTxt: {
      color: '#F48B1B',
      fontSize: '70px',
      [theme.breakpoints.down("xs")]: {
        fontSize: '40px',
      }
    },
    orangeText: {
      color: '#F48B1B',
    },
    filters: {
      // backgroundColor: '#ffffff',
      marginTop: '1rem',
      height: '8rem'
    },
    input: {
      color: '#000000',
      [theme.breakpoints.down("sm")]: {
        fontSize: '12px',
      }
    },
    addIcon: {
      height: '1.2rem'
    },
  })
)(CompaniesViewAllToast);
export default withTranslation()(CompaniesViewAllWithStyle);