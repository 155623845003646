import { heightPercentageToDP as hp } from "react-native-responsive-screen";

//Colors
export const blue = 'rgb(119,100,219)';
export const white = '#fff';
export const grey = 'rgb(181,181,181)';
export const bg_white = 'rgb(255, 255, 255)'
export const grey_text_color = 'rgb(79, 79, 79)'
export const grey_location_color = 'rgb(155, 155, 155)'
export const tag_yellow_color = 'rgb(255, 218, 70)'
export const orange_title_text = 'rgb(244, 139, 27)';
export const themeBlack = 'rgb(41, 41, 41)';
export const light_grey_border = 'rgba(112, 112, 112, 0.13)'
export const font_black="#000"

//Sizes
export const size_8pt = hp(1.1);
export const size_10pt = hp(1.2);
export const size_12pt = hp(1.5);
export const size_14pt = hp(1.7);
export const size_16pt = hp(2);
export const size_18pt = 18;


//line heights
export const lineHeight_20 = 20;
export const lineHeight_18 = 18;
export const lineHeight_14 = 14;


//fonts
export const semiBold = 'Roboto';
export const Bold = 'Roboto';

//images:
export const sampleImage = 'https://www.collegeconsensus.com/wp-content/uploads/2016/12/o-HARVARD-UNIVERSITY-BUILDING-facebook-1024x512.jpg';
export const placeHolderImage = require('../assets/Courses.png')

export const getBoardsApiEndPoint = '/bx_block_profile/boards';