
//@ts-ignore
//@ts-nocheck
import React from 'react';
import {Text, StyleSheet, TouchableOpacity} from 'react-native';
import { Bold, lineHeight_14, orange_title_text, size_8pt } from '../../Utils';
import { translate } from "../../../../../components/src/i18n/translate";

interface Props{
    onPress:()=>void;
}

export default class DownloadBrochureTitle extends React.Component <Props> {
    constructor(props:Props){
        super(props);
    }

    render(){
        return(
          <TouchableOpacity style={[styles.cardTitle,{}]} onPress={()=>this.props.onPress()}>
              <Text  numberOfLines={1} style={[{color:orange_title_text,textDecorationLine:'underline',fontSize:size_8pt,fontFamily:Bold,}]}>{translate("DownloadBrochure")}</Text>
          </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    cardTitle:{
        alignSelf:'center',
        fontSize:size_8pt,
        lineHeight:lineHeight_14,
        color:orange_title_text,
        fontFamily:Bold,
        textDecorationLine:'underline',
        marginBottom:11
    }
})