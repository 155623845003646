Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.httpGetType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.httpPostType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BlogPostsManagement";
exports.labelBodyText = "BlogPostsManagement Body";
exports.followAPIEndPoint = "bx_block_contentmanagement/follows";
exports.unfollowAPIEndPoint = "bx_block_contentmanagement/follows/unfollow";

exports.httpDeleteType = "DELETE";
exports.blogAPIEndPoint = "bx_block_contentmanagement/contents";
exports.blogAPIPoint = "bx_block_contentmanagement/contents";
exports.followAuthorAPIEndPoint = "bx_block_experts/career_experts/";
exports.unfollowAuthorAPIEndPoint = "bx_block_experts/account_experts/";
exports.getProfile = "bx_block_profile/profiles/id?language=en";
exports.getAuthor = "bx_block_experts/career_experts/";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityFeed";
exports.labelBodyText = "ActivityFeed Body";

exports.btnExampleTitle = "CLICK ME";
exports.backgroundSourceUrl ="bx_block_login/application_configs/background_file";
exports.categoriesAPIEndPoint = "bx_block_categories/categories";
exports.getContents = "bx_block_contentmanagement/contents";
exports.topExam = "bx_block_contentmanagement/exams";
exports.getTrendingCourseUrl = "bx_block_contentmanagement/courses?is_tranding=true";
exports.getLiveStreamUrl = "bx_block_contentmanagement/contents";
exports.addBookmarkUrl = "bx_block_contentmanagement/bookmarks";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";

exports.getContentsBlogs = "bx_block_contentmanagement/contents?content_type_name=blog";
exports.getAuthors="bx_block_contentmanagement/authors";
exports.getProfiles="bx_block_experts/career_experts";
// exports.getRecents="bx_block_contentmanagement/adding_day"
exports.getRecentBlogs="bx_block_contentmanagement/contents?recently_add=true"
exports.getAuthorBs="bx_block_contentmanagement/authors"
exports.getFollws="bx_block_contentmanagement/follows"
exports.getUnfollow="bx_block_contentmanagement/bookmarks/unfollow"
exports.blogFollow="bx_block_contentmanagement/follows"
exports.blogUnfollow="bx_block_contentmanagement/follows/unfollow"
exports.getContents = "bx_block_contentmanagement/contents"
