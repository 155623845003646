//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  Hidden,
  createStyles,
  Typography,
  Button,
  InputBase,
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import insane from 'insane';
//@ts-ignore
import { withRouter } from "react-router";
import BlogPostsManagementController, { Props } from './BlogPostsManagementController.web';
import { withTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import { dummyImage, prev, next } from "./assets";
//@ts-ignore
import ScrollMenu from "react-horizontal-scrolling-menu";
//@ts-ignore
import "react-responsive-carousel/lib/styles/carousel.min.css";
import withLoader from "../../../components/src/withLoader.web";

export const ScrollMenuCategory = ({ classes, ListItems, handleChipsClick, selectedChip }) => {
  return (
    <ScrollMenu
      clickWhenDrag={true}
      dragging={true}
      hideArrows={true}
      hideSingleArrow={true}
      scrollToSelected={true}
      alignCenter={false}
      transition={0.3}
      translate={0}
      wheel={false}
      arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
      arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
      //@ts-ignore
      data={ListItems && ListItems.length > 0 && ListItems.map((item: any, index: any) =>
        <Button key={item.id}
          className={selectedChip == index ? `${classes.selectedBtn}` : `${classes.catBtn}`}
          color="primary"
          onClick={() => handleChipsClick(index, item.id)}
          variant="outlined"
        >
          <Typography variant="subtitle2" className={classes.btnTypo}>{item && item.attributes && item.attributes.name}</Typography>
        </Button>
      )}
    />
  )
}
export const BlogPosts = ({ classes, ListItems, history }) => {
  return (
    <Grid container direction="column" alignItems="center" justify="flex-start">
      <>
        {ListItems && ListItems.length > 0 ? ListItems.map((e: any) => (
          <>
            <Grid container direction="row" xs={12} sm={12} md={10} lg={10} xl={10} alignItems="flex-start" spacing={2} className={classes.blogTitle}>
              <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
                {e.attributes.image ?
                  <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image} />
                  :
                  <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
                }
              </Grid>
              <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="h3">{e.attributes.name}</Typography>
                  </Grid>
                  <Grid item >
                    {e.attributes.description.length > 300 ?
                      <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" >
                          <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/blogsdetails/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography></div>
                      : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                        <span dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/blogsdetails/" + e.id + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>
                    }
                  </Grid>
                  {e?.attributes?.author &&
                    <Grid item>
                      <Typography data-test-id="route-blog-profile-btn" className={classes.authoreName} variant="subtitle2" onClick={() => history.push(`/home/blogprofile/${e?.attributes?.author_id}/${e.id}`)}>By - {e?.attributes?.author}</Typography>
                    </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </>
        )) :
          <Grid item>
            <Typography variant="body2">Will Update You Soon</Typography>
          </Grid>
        }
      </>
    </Grid>
  )
}
export class BlogPostsManagementWeb extends BlogPostsManagementController {
  //categoryData: any;
  constructor(props: Props) {
    super(props);
  }
  //@ts-ignore
  async componentDidMount() {
    window.scrollTo(0, 0)
    if (localStorage.getItem("selectedChip")) {
      this.handleChipsClick(localStorage.getItem("selectedChip"), localStorage.getItem("categoryId"));
    } else {
      this.handleChipsClick(0, 4);
    }
  }
  searchData = (e: any) => {
    this.setState({ searchInfo: e.target.value }, () => {
      this.featuredBlogs();
      this.popularBlogs();
      this.trendingBlogs();
      this.recommendedBlogs();
    })
  }
  handleViewAll = (value: any) => {
    const { history } = this.props;
    history.push(`/home/articleview/${value}/${this.state.categoryId}`)
  }
  render() {
    //@ts-ignore
    const self = this;
    const { handleChipsClick } = self;
    //@ts-ignore
    const { classes } = this.props;
    const { popularBlogs, trendingBlogs, recommendedBlogs, featuredBlogs } = this.state;
    return (<>
      <Container maxWidth="xl">
        <Grid container direction="row" alignItems="center" justify="center" >
          <Grid item xs={12} >
            <Grid container direction="row" alignItems="center" justify="center">
              <Hidden smDown>
                <Grid item className="overflow-x-auto whitespace-nowrap" style={{ marginTop: '2rem' }} >
                  <ScrollMenuCategory {...{ classes, ListItems: this.state.categories, handleChipsClick, selectedChip: this.state.selectedChip }} />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Container wrap="nowrap" item className="overflow-x-auto whitespace-nowrap" style={{ marginTop: '2rem' }}>
                  <ScrollMenuCategory {...{ classes, ListItems: this.state.categories, handleChipsClick, selectedChip: this.state.selectedChip }} />
                </Container>
              </Hidden>
            </Grid>
            <Grid item xs={12} >
              <Grid container item direction="column" xs={12} lg={12} xl={12} alignItems='center' justify="center" className={classes.SearchGrid} >
                <Hidden smDown>
                  <Grid container direction="row" alignItems='center' className={classes.search}>
                    <Grid item xs={1}>
                      <SearchIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <InputBase
                        placeholder="Search Blogs here"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        data-testId="searchData1"
                        onChange={(e) => this.searchData(e)}
                        inputProps={{ 'aria-label': 'search' }}
                      />
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid container direction="row" alignItems='center' className={classes.searchSm} >
                    <Grid item xs={1}>
                      <SearchIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <InputBase
                        placeholder="Search Blogs here"
                        data-testId="searchData2"
                        onChange={(e) => this.searchData(e)}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInputSm,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                      />
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <><Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Typography className={classes.textStart} variant="h4">Featured Blogs</Typography>
              {featuredBlogs && featuredBlogs.length > 0 &&
                <Button className={classes.viewAll} color="primary"
                  data-testId="featuredBlogs"
                  onClick={() => this.handleViewAll('blogs')}
                  variant="outlined">
                  <Typography variant="body2" className="font-bold">View all</Typography>
                </Button>
              }
            </Grid>
              <BlogPosts {...{ classes, ListItems: featuredBlogs, history: this.props.history }} />
              <div className={classes.spacer} />
            </>

            <><Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Typography className={classes.textStart} variant="h4">Popular Blogs</Typography>
              {popularBlogs && popularBlogs.length > 0 &&
                <Button className={classes.viewAll} color="primary"
                  data-testId="popularBlogs"
                  onClick={() => this.handleViewAll('blogs')}
                  variant="outlined">
                  <Typography variant="body2" className="font-bold">View all</Typography>
                </Button>
              }
            </Grid>
              <BlogPosts {...{ classes, ListItems: popularBlogs, history: this.props.history }} />
              <div className={classes.spacer} />
            </>
            <><Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Typography className={classes.textStart} variant="h4">Trending Blogs</Typography>
              {trendingBlogs && trendingBlogs.length > 0 &&
                <Button className={classes.viewAll} color="primary"
                  data-testId="trendingBlogs"
                  onClick={() => this.handleViewAll('blogs')}
                  variant="outlined">
                  <Typography variant="body2" className="font-bold">View all</Typography>
                </Button>
              }
            </Grid>
              <BlogPosts {...{ classes, ListItems: trendingBlogs, history: this.props.history }} />
              <div className={classes.spacer} />
            </>
            <><Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Typography className={classes.textStart} variant="h4"> Recommended for you</Typography>
              {recommendedBlogs && recommendedBlogs.length > 0 &&
                <Button className={classes.viewAll} color="primary"
                  data-testId="recommendedBlogs"
                  onClick={() => this.handleViewAll('blogs')}
                  variant="outlined">
                  <Typography variant="body2" className="font-bold">View all</Typography>
                </Button>
              }
            </Grid>
              <BlogPosts {...{ classes, ListItems: recommendedBlogs, history: this.props.history }} />
              <div className={classes.spacer} />
            </>
          </Grid>
        </Grid>
      </Container >
    </>
    );
  }
}
//@ts-ignore
const BlogpostWebRouter = withRouter(BlogPostsManagementWeb);
const BlogpostWebWithLoader = withLoader(BlogpostWebRouter);
const BlogpostWebToast = withSnackbar(BlogpostWebWithLoader);
export const BlogpostWebWithStyle = withStyles((theme) =>
  createStyles({
    runningCoursesCardTitle: {
      color: "#1c1c1c"
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "18rem"
    },
    root: {
      borderRadius: "18px",
      width: '20rem',
      margin: '1rem'
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    search: {
      width: '44rem',
      height: '3.5rem',
      borderRadius: '1.5rem',
      webkitBackdropFilter: 'blur(50px)',
      backdropFilter: 'blur(50px)',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      marginTop: '0rem',
      paddingLeft: '1rem',
    },
    searchSm: {
      // width: '34rem',
      height: '3rem',
      borderRadius: '1.5rem',
      webkitBackdropFilter: 'blur(50px)',
      backdropFilter: 'blur(50px)',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      marginTop: '6rem',
      paddingLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
      },
    },
    car: {
      // overflow:'hidden',
      // whiteSpace:'nowrap',
      // textOverflow:'ellipsis',
      // width:'150px',
      marginTop: '3rem',
      textAlign: 'center',
      marginBottom: '.7rem'
    },
    arrow: {
      position: 'absolute',
      bottom: '1rem',
      height: '1.5rem'
    },
    inputInput: {
      width: '26rem',
      fontSize: '18px',
      fontWeight: 700,
      color: 'black'
    },
    inputInputSm: {
      width: '15rem',
      fontSize: '13px',
      fontWeight: 700,
      color: 'black'
    },
    careerTxt: {
      color: '#8a75f4'
    },
    hunting: {
      color: '#ff931e'
    },
    videoCont: {
      marginTop: '-66px',
      // padding: '0px',
      // maxWidth: '100%'
    },
    uParallax: {
      height: "100vh",
      width: "100%",
      margin: 'auto'
    },
    player: {
      width: "100%",
      height: "100%",
      objectFit: 'cover',
      position: 'absolute',
      backgroundAttachment: 'fixed',
      zIndex: -1,
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)'
    },
    gifImg: {
      width: "100%",
      height: "100%",
      objectFit: 'fill',
      position: 'fixed',
      backgroundAttachment: 'fixed',
      zIndex: -1,
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)'
    },
    catBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '173px',
      height: '3rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    examBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      // width: '145px',
      height: '1.9rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    selectedBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      width: '173px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    selectedExamBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      // width: '145px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    divider: {
      border: '1px solid black',
      marginTop: '.5rem',
      marginBottom: '1rem'
    },
    article: {
      marginLeft: '1rem'
    },
    list: {
      width: '100%',
      padding: '0px',
      marginTop: '-.5rem'
    },
    list2: {
      width: '100%',
    },
    listItem: {
      padding: '0px',
      wordBreak: 'break-word'
    },
    fvPlayer: {
      height: '16rem',
      display: 'block',
      width: '18rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
    btnTypo: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflow: 'hidden'
    },
    paddingExamArVi: {
      paddingLeft: '3rem',
      paddingRight: '3rem',
      flexWrap: 'nowrap',
      // [theme.breakpoints.down("md")]: {
      //   paddingLeft: '1rem',
      // },
      // [theme.breakpoints.up("md")]: {
      //   paddingLeft: '3rem',
      // },
      // [theme.breakpoints.up("lg")]: {
      //   paddingLeft: '8rem',
      // },
      // [theme.breakpoints.up("xl")]: {
      //   paddingLeft: '8rem',
      // },
      // [theme.breakpoints.down('xs')]: {
      //   paddingLeft: '0',
      //   paddingRight: '0',
      // },
    },
    play: {
      position: 'absolute',
      marginTop: '6rem',
      height: '4rem',
      width: '4rem',
      marginLeft: '7.3rem',
      zIndex: 10000
    },
    overlay: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      height: '100%',
      width: '100%',
    },
    examBox: {
      padding: '4px'
    },
    textStart: {
      textAlign: 'start',
      [theme.breakpoints.down('xs')]: {
        fontSize: '17px',
      },
    },
    spacer: {
      height: '6rem',
      [theme.breakpoints.down('xs')]: {
        height: '2rem',
      },
      categoryHight: {
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
      },
    },
    authoreName: {
      color: '#f48b1b',
      cursor: 'pointer',
    },
    SearchGrid: {
      padding: '3rem 0rem 3rem 0rem'
    },
    blogTitle: {
      paddingTop: '1.5rem'
    },
  })
)(BlogpostWebToast);
export default withTranslation()(BlogpostWebWithStyle);
