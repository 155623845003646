//@ts-nocheck
import React from "react";
import {
    withStyles,
    Container,
    Grid,
    createStyles,
    Paper,
    Typography,
    Button,
    InputBase,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import ScrollMenu from "react-horizontal-scrolling-menu";
//@ts-ignore
import { withRouter } from "react-router";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import ShowSearchDataControllerWeb, { Props } from "./ShowSearchdataController.web";
import { dummyImage, next, prev } from "./assets";
import { withTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
//@ts-ignore
import insane from 'insane';
import { categoryArticalExamViewAllSearch } from '../../../components/src/CommonWebCSSUtility'

const allType = { attributes: { id: 'all', name: 'All' } };


const Content = ({ e }) => {
    return (
        <>
            {(e.attributes.content_type.type == "Text" || e.attributes.content_type.type == "AudioPodcast") &&
                (<>
                    {e.attributes.image ? (<img alt="article" className="rounded-lg h-40 w-48" src={e.attributes.image} />) : (<img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />)}
                </>)
            }

            {e.attributes.content_type.type == "Epub" &&
                (<>
                    {e.attributes.study_material ? (<iframe className="rounded-lg h-40 w-48" src={`https://docs.google.com/gview?url=${e.attributes.study_material}&embedded=true`}></iframe>) : (<img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />)}
                </>)
            }
        </>
    )
}

const FeatureArticle = ({ classes, featureArticle }) => {
    return (
        <>
            {featureArticle && featureArticle.length > 0 ? featureArticle.map((e: any, i: number) => (
                <Grid key={i} container direction="row" xs={12} sm={12} md={9} lg={9} xl={9} style={{ paddingBottom: '3rem' }} alignItems="flex-start" spacing={2}>
                    <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>

                        {e && e.attributes && e.attributes.content_type && e.attributes.content_type.type && <>

                            <Content data-testId={`${i}_Content`} e={e} />

                            {e.attributes.content_type.type == "Videos" && (<>{e.attributes.video ? (<video className="rounded-lg h-40 w-48" style={{ margin: "auto" }} controls >
                                <source src={e.attributes.video} type="video/mp4" /></video>)
                                : (<img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />)}</>)}

                            {e.attributes.content_type.type == "Live Stream" && (<>{e.attributes && e.attributes.contentable && e.attributes.contentable.data && e.attributes.contentable.data.attributes && e.attributes.contentable.data.attributes.url ? (<video className="rounded-lg h-40 w-48" style={{ margin: "auto" }} controls >
                                <source src={e.attributes.contentable.data.attributes.url} type="video/mp4" /></video>)
                                : (<img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />)}</>)}
                        </>}
                    </Grid>
                    <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                        <Grid container direction="column">
                            <Grid item>
                                <Typography variant="h3">{e.attributes.name}</Typography>
                            </Grid>
                            <Grid item>
                                {e.attributes.description.length > 300 ?
                                    <div className="mt-1.5">
                                        <Typography variant="subtitle2" color="primary" >
                                            <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                                                __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                                            }}></span>
                                        </Typography></div>
                                    : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                                        <span dangerouslySetInnerHTML={{
                                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                                        }}></span>
                                    </Typography></div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )) :
                <Grid item>
                    <Typography variant="body2">Will Update You Soon</Typography>
                </Grid>
            }
        </>
    )
}
export class ShowSearchdata extends ShowSearchDataControllerWeb {
    constructor(props: Props) {
        super(props);
    }
    handleChipsClick = (id: any) => {
        this.setState({ selectedChip: id, currentPage: 1 }, () => {
            this.featureArticle(this.state.searchinfo, this.state.selectedChip);
        })
    }
    setSearcdata = (e: any) => {
        this.setState({ searchinfo: e.target?.value, currentPage: 1 }, () => {
            this.featureArticle(this.state.searchinfo, this.state.selectedChip);
        })
    }
    onPageChange = (e, page) => {
        this.setState({ currentPage: page }, () => {
            this.featureArticle(this.state.searchinfo, this.state.selectedChip);
        });
    }

    render() {
        const { setSearcdata, handleChipsClick, onPageChange } = this;
        const { classes } = this.props;
        const { contentTypes, selectedChip, searchinfo, featureArticle, currentPage, totalPage } = this.state;
        return (
            <React.Fragment>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justify="center" style={{ marginBottom: '4rem', marginTop: '4rem' }}>
                        <Paper component="form" className={classes.searchroot}>
                            <IconButton className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                className={classes.searchinput}
                                placeholder="Search here "
                                data-testId="setSearcdata"
                                onChange={(e) => setSearcdata(e)}
                                value={searchinfo}
                                inputProps={{ "aria-label": "Search here" }}
                            />
                        </Paper>
                    </Grid>
                    <Grid container spacing={3} style={{ marginBottom: '4rem' }}>
                        <Grid item xs={12} md={12}>
                            <Paper className={classes.paperserch}>
                                <Grid container direction="column" alignItems="center" justify="flex-start">
                                    <Grid container direction="row" alignItems="center" justify="center">
                                        <Grid item className="overflow-x-auto whitespace-nowrap" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
                                            {contentTypes && contentTypes.length > 0 &&
                                                <ScrollMenu
                                                    clickWhenDrag={true}
                                                    dragging={true}
                                                    hideArrows={true}
                                                    hideSingleArrow={true}
                                                    scrollToSelected={true}
                                                    alignCenter={false}
                                                    transition={0.3}
                                                    translate={0}
                                                    wheel={false}
                                                    arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                                                    arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                                                    //@ts-ignore
                                                    data={contentTypes && contentTypes.length > 0 &&
                                                        [allType].concat(contentTypes).map((item: any, index: any) => (
                                                            <Button key={item.attributes.id}
                                                                className={selectedChip === item.attributes.id ? `${classes.selectedBtn}` : `${classes.catBtn}`}
                                                                color="primary"
                                                                onClick={() => handleChipsClick(item.attributes.id)}
                                                                variant="outlined"
                                                            >
                                                                <Typography variant="subtitle2" className={classes.btnTypo}>{item.attributes.name}</Typography>
                                                            </Button>
                                                        ))
                                                    } />
                                            }
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <FeatureArticle data-testId="FeatureArticle" {...{ classes, featureArticle }} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    {
                        totalPage > 1 && <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
                            <Pagination page={currentPage} data-testId="onPageChange" onChange={onPageChange} count={totalPage} variant="outlined" shape="rounded" />
                        </Grid>
                    }
                </Container >
            </React.Fragment >
        );
    }
}
//@ts-ignore
const ShowSearchDataWebWithRouter = withRouter(ShowSearchdata);
const ShowSearchDataWebWithLoader = withLoader(ShowSearchDataWebWithRouter);
const ShowSearchDataWebToast = withSnackbar(ShowSearchDataWebWithLoader);
const SearchDataWebWithStyle = withStyles((theme) =>
    createStyles({
        ...categoryArticalExamViewAllSearch(theme),
        headTxt: {
            color: '#7764db',
            marginLeft: '1.5rem'
        },
        subTxt: {
            color: '#4f4f4f',
            textAlign: 'center'
        },
        finishImg: {
            height: '8rem',
            width: '8rem'
        },
        cancelBtn: {
            borderRadius: '24px',
            backgroundColor: '#ebebeb',
            height: '50px',
            width: '200px',
            color: '#4f4f4f',
            '&:hover': {
                backgroundColor: '#ebebeb',
                color: '#4f4f4f'
            }
        },
        title: {
            paddingLeft: "16px",
        },
        viewAll: {
            border: '2px solid #f48b1b !important',
            backgroundColor: '#ffffff',
            borderRadius: '20px !important',
            width: '7rem',
            height: '2rem',
            marginRight: '15px',
            '&:hover': {
                backgroundColor: '#f48b1b !important',
                color: '#ffffff !important'
            }
        },
        paginator: {
            marginTop: '2rem',
            marginBottom: '4rem',
            justifyContent: 'center',
        },
        searchroot: {
            padding: "0px 0px",
            display: "flex",
            alignItems: "center",
            width: '60%',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        searchinput: {
            marginLeft: theme.spacing(1),
            flex: 1,
            '& input': {
                color: "gray",
            }
        },
        searchBtn: {
            color: "#ffffff !important",
            border: "2px solid #f48b1b !important",
            padding: "6px 37px",
            backgroundColor: "#f48b1b",
            '&:hover': {
                color: "#ffffff !important",
                backgroundColor: "#f48b1b",
            },
        },
        iconButton: {
            padding: 10,
        },
        categoryHight: {
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
        },
    })
)((ShowSearchDataWebToast));
export default withTranslation()(SearchDataWebWithStyle);
