import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";

export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface Categories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}
interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
}
interface Category {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategories[];
  };
}
interface SubCategory {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
  };
}
interface Contenttype {
  id: number,
  type: string;
  created_at: string;
  updated_at: string;
  rank: string
}
interface Article {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    description: string;
    image: string;
    video: string;
    audio: string;
    study_material: string;
    content_type: Contenttype;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
    tag_list: string[];
  };
}
interface S {
  // Customizable Area Start
  searchinfo: any;
  categoriesData: Category[];
  subCategoriesData: SubCategory[];
  showCategoryData: Category;
  showSubCategoryData: Category;
  showArticledetails: Article;
  contentTypes: any[];
  featureArticle: any[];
  featureVideo: any;
  selectedType: any;
  chips: any[];
  selectedChip: any;
  currentPage: any
  totalPage: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ShowSearchDataControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  getContentTypelistId: string = "";
  getFeatureArticleApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.handleReceiveResponse = this.handleReceiveResponse.bind(this);
    this.state = {
      searchinfo: this.props?.match?.params?.searchinfo,
      categoriesData: [],
      subCategoriesData: [],
      showCategoryData: {
        id: "",
        type: "",
        attributes: {
          id: "",
          name: "",
          created_at: "",
          updated_at: "",
          sub_categories: [],
        },
      },
      showSubCategoryData: {
        id: "",
        type: "",
        attributes: {
          id: "",
          name: "",
          created_at: "",
          updated_at: "",
          sub_categories: [],
        },
      },
      showArticledetails: {
        id: "",
        type: "",
        attributes: {
          id: "",
          name: "",
          description: "",
          image: "",
          video: "",
          audio: "",
          study_material: "",
          content_type: {
            id: 0,
            type: "",
            created_at: "",
            updated_at: "",
            rank: "",
          },
          created_at: "",
          updated_at: "",
          categories: [],
          sub_categories: [],
          tag_list: [],
        },
      },
      featureArticle: [],
      featureVideo: [],
      contentTypes: [],
      selectedType: 0,
      chips: ['New Article', 'Courses', 'discusson Forms(12)', 'Vedos(Short)(22)', 'Vedios(Full Length)(34)'],
      selectedChip: 'all',
      currentPage: 1,
      totalPage: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() {
    this.getContentTypes();
    this.featureArticle(this.props.match?.params?.searchinfo, this.state.selectedChip);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        this.handleReceiveResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  handleReceiveResponse(apiRequestCallId : any , responseJson: any){
    if (apiRequestCallId === this.getContentTypelistId) {
      if (responseJson.data) {
        this.setState({ contentTypes: responseJson.data });
      }
    } else if (apiRequestCallId === this.getFeatureArticleApiCallId) {
      if (responseJson.data) {
        this.setState({ featureArticle: responseJson.data, totalPage: responseJson?.total_pages || 1 });
      }
    }
  }

  getContentTypes = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContentTypelistId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentTypelistAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  featureArticle = (searchValue: any, selectedType: any) => {
    const search = searchValue ? `&search=${searchValue}` : '';
    const contentType = selectedType !== 'all' ? `&content_type[]=${selectedType}` : '';
    const headers = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getFeatureArticleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    const lang = localStorage.getItem("lang") || 'en';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContents + `?page=${this.state.currentPage}&per_page=10${search}${contentType}&language=${lang}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
