
//@ts-ignore
//@ts-nocheck
import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import { ScreenWidth } from '../../../../../framework/src/Helpers';
import { bg_white, blue, grey_location_color, white } from '../../Utils';


interface Props{
    borderEnabled?:boolean,
    activeTheme?:any,
    onPress:()=>void,
    style?: any
}

export default class Card extends React.Component<Props> {
    constructor(props:Props){
        super(props);
    }

    render(){
        const style = this.props.style || {}
       // console.log('borderEnabled ..... ',this.props.borderEnabled)
        return(
           
            <TouchableOpacity onPress={()=>this.props.onPress()} style={[styles.cardContainer,{
                borderWidth: this.props.borderEnabled ? 0.8 : 0 ,
                borderColor:this.props.activeTheme =='dark'? grey_location_color : blue,
                backgroundColor:this.props.activeTheme == 'dark'?'rgb(41,41,41)' : white,
               // borderLeftWidth:this.props.activeTheme == 'dark'? 0 : 0.5,
                //borderBottomWidth:this.props.activeTheme == 'dark'? 0 : 0.5
                }, style]}>
              {this.props.children}
          </TouchableOpacity>
         
        );
    }
}

const styles = StyleSheet.create({
    cardContainer: {
        width:ScreenWidth/2.7,
        borderRadius:6,
        backgroundColor:bg_white,
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowRadius: 1,  
        marginTop:9,
        marginEnd:10,marginStart:2,marginBottom:17
    }
})
