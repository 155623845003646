import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import DashboardWeb from '../../blocks/dashboard/src/Dashboard.web';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import HomepageContainer from '../../blocks/DynamicContent/src/HomepageContainer.web';
const history = createHistory();
class RouteConfig extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-000000-1');
    history.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search);
      console.log(location.pathname);
    });
  }
  render() {
    return (
      <Switch>
        <Redirect exact to="/home" from="/" />
        <Route path="/home" component={HomepageContainer} />
        <Route path="/dashboard" component={DashboardWeb} />
      </Switch>
    );
  }
}
export default RouteConfig;
