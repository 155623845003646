//@ts-nocheck
import React from "react";
import {
  Button, createStyles,
  Container,
  Grid,
  Hidden,
  Typography, withStyles,
  Paper, IconButton,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import insane from 'insane';
import moment from "moment";
import { withTranslation } from "react-i18next";
//@ts-ignore
import { withRouter } from "react-router";
import { withSnackbar } from "../../../components/src/toast.web";
import withLoader from "../../../components/src/withLoader.web";
import { courseImg, dummyImage, linkedInImg, fbImg } from "./assets";
import CareerExpertArticleWebController, { Props } from "./CareerExpertArticleController.web";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { FacebookShareButton, LinkedinShareButton } from "react-share";

const Title = ({ type, caption, classes, handleViewAll, itemList }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
      <Hidden smDown> <Typography align="center" className={classes.Acaption} variant="h4">{caption}</Typography></Hidden>
      <Hidden mdUp> <Typography align="center" className={classes.Acaption} variant="h3">{caption}</Typography></Hidden>
      {itemList && itemList.length > 4 &&
        <Button
          className={classes.viewAll}
          color="primary"
          onClick={() => handleViewAll(type)}
          variant="outlined">
          <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
          <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
        </Button>
      }
    </Grid>);
};

const RenderBlogAndArticles = ({ type, classes, listItems }) => {
  return (
    <>
      <Grid container direction="column" alignItems="center" justify="flex-start" className={classes.RenderBlogAndArticals}>
        <>
          {listItems && listItems.length > 0 ? listItems.map((e: any, index: number) => (
            <>
              <Grid key={index} container direction="row" xs={12} sm={12} md={10} lg={10} xl={10} alignItems="flex-start" spacing={2} className={classes.RenderBlogAndArticalsContent}>
                <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
                  {e.attributes.image ?
                    <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image} />
                    :
                    <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
                  }
                </Grid>
                <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h3">{e.attributes.name}</Typography>
                    </Grid>
                    <Grid item>
                      {e.attributes.description.length > 300 ?
                        <div className="mt-1.5">
                          <Typography variant="subtitle2" color="primary" >
                            <span className={classes.description} dangerouslySetInnerHTML={{
                              __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/articaldetails/${type}/${e.id}'>[Read more]</a></button>`)
                            }}></span>
                          </Typography></div>
                        : <div className="mt-1.5"><Typography variant="subtitle2" color="primary">
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/articaldetails/${type}/${e.id}'>[Read more]</a></button>`)
                          }}></span>
                        </Typography></div>
                      }
                    </Grid>
                    {e?.attributes?.content_provider?.data?.attributes?.partner_name &&
                      <Grid item>
                        <Typography className={classes.contentProvider} variant="subtitle2">By - {e?.attributes?.content_provider?.data?.attributes?.partner_name}</Typography>
                      </Grid>}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )) :
            <Grid item>
              <Typography variant="body2">Will Update You Soon</Typography>
            </Grid>
          }
        </>
      </Grid>
      <div className={classes.spacer} />
    </>
  )
};

export class CareerExpertArticleWeb extends CareerExpertArticleWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.loadData();
  }

  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { handleViewAll, follow, unfollow } = self;
    const { articleDetails: { attributes }, recommendedBlogs, expertCategory } = this.state;
    return (
      <>
        <Grid
          item
          className={classes.imgBg}
          xs={12}
        />
        <Container className={classes.videoCont}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.containeraujest}
            >
              <Grid className={classes.gridsize} item>
                <Paper className={classes.papersize}>
                  <Grid
                    container
                    direction="column"
                  >
                    <Grid container direction="row" justify="space-between" item>
                      <Grid className={classes.blogHead} item>
                        <Hidden smDown> <Typography variant="h4">{attributes?.headline}</Typography></Hidden>
                        <Hidden mdUp> <Typography variant="h3">{attributes?.headline}</Typography></Hidden>
                      </Grid>
                      <Grid className={classes.followContainer} item>
                        <Button
                          className={classes.followBtn}
                          color="primary"
                          data-testId="follow"
                          onClick={() => {
                            attributes?.follow ? unfollow(attributes?.career_expert?.id, attributes?.id) : follow(attributes?.career_expert?.id, attributes?.id)
                          }}
                          variant="outlined">
                          <Typography variant="body2" className="font-bold" > {attributes?.follow ? 'Followed' : 'Follow'}</Typography>
                          {!attributes?.follow && <AddIcon className={classes.addicon} />}
                        </Button>

                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.categoinfo} >{attributes?.career_expert?.name}</Typography>
                      <Typography variant="body2" className={classes.categoinfo} >Category : {expertCategory}</Typography>
                      <Typography variant="body2" >{moment(attributes?.updated_at).format("MMM DD, YYYY")}.</Typography>
                    </Grid>
                    <Grid item>
                      <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" >
                          <div dangerouslySetInnerHTML={{
                            __html: `${attributes?.content}`.replace('&nbsp', '')
                          }}>
                          </div>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid className={classes.taginfo} item>
                      <Grid container direction="row">
                        {attributes?.career_expert?.tag_list.map((tag: any, index: number) => (<Grid item>
                          <Button key={index} className={classes.proTag} disabled color="primary" variant="outlined" >
                            <Typography variant="caption">{tag}</Typography>
                          </Button>
                        </Grid>))}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" item>
                      <Grid className={classes.description} item>
                        <Typography variant="body2" className="font-bold" >
                          <VisibilityIcon className={classes.vicon} />
                          {attributes?.view_count && attributes?.view_count < 1000 ? attributes?.view_count : (attributes?.view_count / 1000) + 'k'}
                        </Typography>
                      </Grid>
                      <Grid className={classes.shareContainer} item >
                        <Button
                          className={classes.shareBtn}
                          onClick={() => this.setState({ show: true })}
                          variant="outlined">
                          <Typography variant="body2" className="font-bold"  >Share</Typography>
                        </Button>

                      </Grid>

                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Title data-testId="title" {...{ type: 'recommendedBlogs', caption: 'Recommended Blogs', classes, handleViewAll, ItemList: recommendedBlogs }} />
            <RenderBlogAndArticles data-testId="renderBlogAndArticles" {...{ type: 'blogs', classes, listItems: recommendedBlogs }} />
          </Grid>
          <Dialog
            open={this.state.show}
            fullScreen={false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Share The Blog"}
              <IconButton
                className={classes.dailogTitleIconBtn}
                aria-label="close"
                onClick={() => { this.setState({ show: false }) }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.sharecontent}>
              <Grid container >
                <Grid xs={12}>
                  <Grid container direction="column" alignItems="center" justify="center" className={classes.shareCourse}>

                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item className={classes.socialBtn} >
                          <FacebookShareButton
                            url={`http://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/careerexpert/expert/${this.props?.match.params?.expertId}/article/${this.props?.match.params?.articleId}`}
                            quote={"Blog Views - These are the views got"}
                            hashtag="#CareerHunt"
                            className={classes.shareIcons}

                          >
                            <img src={fbImg} className={classes.socialMediaButton} />
                          </FacebookShareButton>
                        </Grid>
                        <Grid item className={classes.socialBtn}  >
                          <LinkedinShareButton
                            url={`http://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/careerexpert/expert/${this.props?.match.params?.expertId}/article/${this.props?.match.params?.articleId}`}
                            quote={"Blog Views - These are the views got"}
                            hashtag="#CareerHunt"
                            className={classes.shareIcons}
                          >
                            <img src={linkedInImg} className={classes.socialMediaButton} />
                          </LinkedinShareButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const CareerExpertArticleWebWithRouter = withRouter(CareerExpertArticleWeb);
const CareerExpertArticleWebWithLoader = withLoader(CareerExpertArticleWebWithRouter);
const CareerExpertArticleWebToast = withSnackbar(CareerExpertArticleWebWithLoader);
const CareerExpertArticleWebWithStyle = withStyles((theme) =>
  createStyles({
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    followContainer: {
      width: '25%',
      alignSelf: 'center',
      textAlign: 'end',
    },
    followBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    shareBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b ',
      color: '#ffffff !important',
      width: '7rem',
      height: '2rem',
      '&:hover': {
        backgroundColor: '#f48b1b ',
        color: '#ffffff'
      },
      [theme.breakpoints.down("xs")]: {
        backgroundColor: '#f48b1b',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    paddingExamArVi: {
      marginTop: '1.5rem',
      padding: '3rem 2rem 2rem 2rem',
      [theme.breakpoints.down("xs")]: {
        padding: '0rem 0rem 0rem 0rem',
        marginTop: '0rem',



      },
    },
    imgBg: {
      backgroundImage: `url(${courseImg})`,
      width: "100%",
      height: "505px",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      backgroundSize: "cover",
      justifyContent: "center",
      backgroundPosition: "center",
      marginTop: "-0.25rem"
    },
    videoCont: {
      // marginTop: '-66px',
      // padding: '0px',
      maxWidth: '100%'
    },
    proTag: {
      color: 'black !important',
      height: '1.7rem',
      margin: '0.2rem',
    },
    Acaption: {
      color: 'rgb(250, 100, 0)',
    },
    categoinfo: {
      color: '#3c59ff',
    },
    vicon: {
      fontSize: '1.4rem',
      marginRight: '0.5rem',
    },
    addicon: {
      fontSize: '1rem',
    },
    containeraujest: {
      marginTop: '-10rem',
    },
    gridsize: {
      width: '100%',
    },
    papersize: {
      padding: '1.5rem 3rem'
    },
    taginfo: {
      padding: '1rem 0rem'
    },
    RenderBlogAndArticals: {
      marginBottom: '3rem'
    },
    RenderBlogAndArticalsContent: {
      marginBottom: '1.5rem'
    },
    dailogTitleIconBtn: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
    sharecontent: {
      overflow: 'hidden'
    },
    socialBtn: {
      marginRight: '1rem'
    },
    shareCourse: {
      padding: '2rem',
      backgroundColor: '#f9f5f6'
    },
    socialMediaButton: {
      height: '30px',
      width: '30px',
      margin: 'auto'
    },
    shareIcons: {
      backgroundColor: '#ffffff',
      height: '60px',
      width: '60px',
      borderRadius: '50%'
    },
    sharebtn: {
      fontSize: "14px",
      color: "#fff",
      textAlign: "center",
      padding: "4px 25px",
      borderRadius: "3px",
      backgroundColor: "#ff6d0d",
      '&:hover': {
        backgroundColor: '#ff6d0d',
        color: '#ffffff'
      }
    },
    description: {
      display: 'inline'
    },
    blogHead: {
      width: '75%'
    },
    contentProvider: {
      // color: '#f48b1b',
      marginTop: '2.5rem'
    },
    shareContainer: {
      alignSelf: 'center',
      textAlign: 'end',
      display: 'flex',
      justifyContent: 'space-around'
    },
  })
)(CareerExpertArticleWebToast);
export default withTranslation()(CareerExpertArticleWebWithStyle);