import React from "react";
import {
  Typography,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import SubCategoriesController, {
  Props,
} from "./SubCategoriesController.web";

export class SubCategoryList extends SubCategoriesController {
  constructor(props: Props) {
    super(props);
    this.showSubCategory(this.props.match?.params?.id)
  }

  componentDidmount = async () => {
    this.showSubCategory(this.props.match?.params?.id);
  }
  componentWillReceiveProps = async (nextProps: any) => {
    if (nextProps.match.params.id) {
      this.showSubCategory(nextProps.match?.params?.id);
    }
  }
  render() {
    const { showSubCategoryData } = this.state;
    return (
      <div className="flex items-center flex-wrap mt-4">
        <Typography>{showSubCategoryData.attributes?.name}'s list display here..</Typography>
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(SubCategoryList);
