
//@ts-nocheck
import React from "react";
import {
  withStyles, Menu, MenuItem, Card, CardContent,
  Container, Grid, Typography, Button, Dialog, DialogActions, Divider,
  DialogContent, createStyles
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import MyThreadsController, { Props } from "./MyThreadsController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { withSnackbar } from "../../../components/src/toast.web";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { userImage } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import MoreVertIcon from '@material-ui/icons/MoreVert';
const ReportMenuItem = withStyles({
  root: { backgroundColor: '#ffffff', color: '#000000 !important' },
  MuiMenuItem: {
    root: { backgroundColor: '#ffffff', color: '#000000 !important', }
  },
})(MenuItem);
export class MyThreads extends MyThreadsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const { searchInfo } = this.props;
    window.scrollTo(0, 0);
    if (localStorage.getItem("token")) {
      this.getQuestions(searchInfo);
      this.getLikes();
    } else {
      this.openDialog(true);
    }
    TimeAgo.addDefaultLocale(en)
  }
  async componentWillReceiveProps(nextProps: any) {
    if (nextProps.searchInfo !== this.props.searchInfo) {
      this.getQuestions(nextProps.searchInfo);
    }
  }
  openMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  savAsDraft = (values: any) => {
    localStorage.setItem("draftDiscussion", values);
  }
  rightContainerUi = (classes) => {
    return (
      <>
        <Grid item xs={12}>
          <Card className={classes.rightContainer}>
            <CardContent>
              <Grid container>
                <StarBorderIcon color="light" className={classes.addIcon} />
                <Typography variant='body2'>Must-read Posts</Typography>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container direction="row" spacing={2}>
                <Grid item style={{ display: "inline-flex" }}>
                  <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                  <Typography variant="body1" className={classes.rightBlueTxt}> Please read rules before you start working</Typography>
                </Grid>
                <br />
                <Grid item style={{ display: "inline-flex" }}>
                  <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                  <Typography variant="body1" className={classes.rightBlueTxt}> Vision & Strategy of Alemhelp</Typography>
                </Grid>
                <br />
                <br />
              </Grid>
              <br />
              <Grid container>
                <InsertLinkIcon color="light" className={classes.addIcon} />
                <Typography variant='body2'>Featured Links</Typography>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container direction="column" spacing={1}>
                <Grid item style={{ display: "inline-flex" }}>
                  <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                  <Typography variant="body1" className={classes.rightBlueTxt}> Alemhelp source-code on GitHub</Typography>
                </Grid>
                <br />
                <Grid item style={{ display: "inline-flex" }}>
                  <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                  <Typography variant="body1" className={classes.rightBlueTxt}>Golang best-practices</Typography>
                </Grid>
                <br />
                <Grid item style={{ display: "inline-flex" }}>
                  <FiberManualRecordIcon color="light" className={classes.dotIcon} />
                  <Typography variant="body1" className={classes.rightBlueTxt}>Alem.School dashboard</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </>
    )
  }
  communicationUi = (e, classes, likeList) => {
    return (<Grid item xs={8}>
      <Grid container direction="row" >
        <Grid item xs={2}>
          <Grid container direction="row" alignItems="center">
            <RemoveRedEyeIcon color="light" className={classes.dfIcon} />
            {e && e.attributes && e.attributes.view && <Typography variant="body1">{e.attributes.view}</Typography>}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="row" alignItems="center">
            <ChatBubbleIcon data-testId="ChatBubbleIcon" color="light" className={classes.dfIcon}
              onClick={() => this.props.history.push("/dashboard/detailview", { postDetails: e })} />
            <Typography variant="body1">{e?.attributes?.comments_count}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="row" alignItems="center">
            {localStorage.getItem('token') &&
              likeList.findIndex((et) => { return et?.attributes?.likeable_id === e?.attributes.id }) > -1
              && e?.attributes?.is_like === true
              ? <> <ThumbUpIcon color="light" onClick={() => this.handleDisLike(e?.attributes?.id, false)} className={classes.dfIcon} />
                {e && e.attributes && e.attributes.likes_count && <Typography variant="body1">{e.attributes.likes_count}</Typography>}
              </>
              :
              <> <ThumbUpAltOutlinedIcon color="light" data-testId="handleLike" onClick={() => this.handleLike(e?.attributes?.id, true)} className={classes.dfIcon} />
                {e && e.attributes && e.attributes.likes_count && <Typography variant="body1">{e.attributes.likes_count}</Typography>}
              </>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>)
  }
  handleDeleteMenu = (event: any) => {
    event?.preventDefault();
    this.setState({ openDeleteDialog: true })
  }
  descUi = (e, classes) => {
    return (<Grid container direction="row">
      {e?.attributes?.description.length > 300 ?
        <Typography variant="subtitle2" onClick={() => this.props.history.push("/dashboard/detailview",
          { postDetails: e })} className={classes.desc}>
          {e?.attributes?.description}...</Typography> :
        <Typography variant="subtitle2" onClick={() => this.props.history.push("/dashboard/detailview",
          { postDetails: e })} className={classes.desc}>
          {e?.attributes?.description}</Typography>}
    </Grid>)
  }
  tagUi = (e, classes) => {
    return (e?.attributes?.tags.length > 0 &&
      <> <br />
        <Grid container direction="row" justify="flex-start">
          {e?.attributes?.tags && e?.attributes?.tags.map((el, index) =>
            <Button key={index} variant="contained" className={classes.tagChips}><Typography variant="body1">{el?.name}</Typography></Button>)}
        </Grid>
      </>)
  }
  cardHeader = (e, classes, anchorEl, openDeleteDialog) => {
    return (
      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center" justify="flex-start">
            <Grid item xs={3} sm={2} md={1} lg={1} xl={1} >
              <img src={e?.attributes?.user_image ? e?.attributes?.user_image : userImage} className={classes.userImg} />
            </Grid>
            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
              <Grid container direction="column" >
                <Typography variant="caption">{e?.attributes?.account?.first_name}</Typography>
                <Typography variant="body1"><ReactTimeAgo date={e?.attributes?.updated_at} locale="en-US" /></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="row" alignItems="center" justify="flex-end">
            <MoreVertIcon color="light" onClick={(el) => { this.openMenu(el); this.setState({ threadId: e?.attributes?.id }) }} className={classes.addIcon} />
            <Menu
              classes={{ paper: classes.reportMenu }}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleMenuClose}
            >
              <ReportMenuItem data-testId="handleDeleteMenu" onClick={(el: any) => this.handleDeleteMenu(el)}>Delete</ReportMenuItem>
              {localStorage.getItem("token") &&
                <>
                  <Dialog
                    PaperProps={{
                      style: { boxShadow: 'none' }
                    }}
                    BackdropProps={{ style: { background: 'rgb(0,0,0,.15)' } }}
                    open={openDeleteDialog}
                    onClose={this.handleClose}>
                    <DialogContent>
                      Are you sure you want to delete the post?
                    </DialogContent>
                    <DialogActions>
                      <Button className={classes.cancelBtn} data-testId="deleteThread" onClick={() => this.deleteThread(this.state?.threadId)}>Yes</Button>
                      <Button className={classes.publishBtn} onClick={this.handleClose} autoFocus>No</Button>
                    </DialogActions>
                  </Dialog>
                </>}
            </Menu>
          </Grid>
        </Grid>
      </Grid >)
  }
  cardUi = (getQuestions, classes, anchorEl, likeList, openDeleteDialog) => {
    return (
      getQuestions.length > 0 ? getQuestions.map(e => (
        <>
          <Card className={classes.rootCard}>
            <CardContent>
              {this.cardHeader(e, classes, anchorEl, openDeleteDialog)}
              <br />
              <Grid container direction="row">
                {e && e.attributes && e.attributes.title && <Typography variant="h3">{e.attributes.title}</Typography>}
                <Grid item>
                </Grid>
              </Grid>
              <br />
              {this.descUi(e, classes)}
              <br />
              {this.tagUi(e, classes)}
              <br />
              <Grid container direction="row" justify="space-between">
                {this.communicationUi(e, classes, likeList)}
                <Grid item xs={2}>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ))
        :
        <Typography variant="body2" className="text-center">No Data available</Typography>
    )
  }
  render() {
    const { classes } = this.props;
    const { anchorEl, getQuestions, likeList, hasMore, openDeleteDialog } = this.state;
    return (
      <>
        <Container maxWidth="xl" style={{ paddingLeft: '4rem', paddingRight: '4rem' }} >
          <Grid item xs={12} container direction="row" justify="space-between">
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Typography align="center" variant="h4" className={classes.txtColor}>My Threads</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <Grid container direction="row" >
                <Grid item xs={12}>
                  <InfiniteScroll
                    dataLength={getQuestions.length}
                    next={this.getInfiniteQuestions}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        {getQuestions.length > 0 && <b>You have seen it all</b>}
                      </p>
                    }>
                    {this.cardUi(getQuestions, classes, anchorEl, likeList, openDeleteDialog)}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              {this.rightContainerUi(classes)}
            </Grid>
          </Grid>
        </Container>
        <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
      </>
    );
  }
}
// @ts-ignore
const MyThreadsWithRouter = withRouter(MyThreads);
const MyThreadsWithLoader = withLoader(MyThreadsWithRouter);
const MyThreadsToast = withSnackbar(MyThreadsWithLoader);
const MyThreadsWithStyle = withStyles((theme) =>
  createStyles({
    reportMenu: {
      backgroundColor: 'white !important',
      borderRadius: '4px !important',
      boxShadow: '0px 1px 3px 0 rgba(0, 0, 0, 0.15) !important',
    },
    userImg: {
      height: '2rem',
      width: '2.5rem'
    },
    desc: {
      cursor: 'pointer',
      wordBreak: 'break-all',
    },
    dotIcon: {
      color: '#1682fd',
      height: '1rem'
    },
    rightBlueTxt: {
      color: '#1682fd'
    },
    rightContainer: {
      padding: '1rem',
      marginTop: '3rem',
      borderRadius: '4.5px',
      boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #eaeaea',
      backgroundColor: '#fffefe'
    },
    chip: {
      height: '1.5rem',
      borderRadius: '0.5rem',
      background: '#f48b1b',
      color: '#ffffff',
      "& .MuiChip-deleteIcon": {
        height: "15px"
      },
      "&:hover": {
        "& .MuiChip-deleteIcon": {
          height: "15px"
        }
      }
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    reportColor: { color: 'red' },
    txtColor: {
      color: ' #f48b1b'
    },
    addtagTxt: {
      color: '#7764db'
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#ffffff'
      }
    },
    viewAll: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      color: '#000000',
      '&:hover': {
        backgroundColor: 'transparent',
        color: "#000000",
        boxShadow: 'none',
      }
    },
    publishBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    addIcon: {
      height: '1.2rem'
    },
    dfIcon: {
      height: '1.2rem',
      fill: '#7764db'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    iconBtn: {
      backgroundColor: '#7764db',
      borderRadius: '10px',
      height: '2rem',
      width: '2rem',
      "&:hover, &.Mui-focusVisible": { backgroundColor: "#7764db" }
    },
    newThread: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      backgroundColor: '#fffefe'
    },
    interactiveBtn: {
      height: '2rem',
      width: '2rem'
    },
    rootCard: {
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      margin: '5px 5px 20px 5px',
    },
  })
)((MyThreadsToast))
export default withTranslation()(MyThreadsWithStyle);