Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarPostApiMethodType = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.backgroundSourceUrl = "bx_block_login/application_configs/background_file";
exports.showUserDataUrl = "bx_block_profile/profiles/id";
exports.getRuningCourseUrl = "bx_block_contentmanagement/course_orders/my_courses";
exports.getShortlistedCourseUrl = "bx_block_contentmanagement/courses/shotlist_courses";
exports.getShortlistedJobUrl = "bx_block_jobs/jobs/shotlist_jobs";
exports.getSuggestedCourseUrl = "bx_block_contentmanagement/courses/recommended_courses";
exports.getFaqApiUrl = "bx_block_dashboard/faqs";
exports.getHelpnSupportApiUrl = "bx_block_dashboard/help_and_supports";
exports.removeBookmarkUrl = "bx_block_contentmanagement/bookmarks/unfollow";
exports.getQuizzesAPiUrl = "bx_block_contentmanagement/user_quizzes";
exports.getAssessmentsApUrl = "bx_block_contentmanagement/user_assessments";
exports.getFreeTrails = "bx_block_contentmanagement/courses/free_trails";
exports.getTransaction = "bx_block_contentmanagement/course_orders";
exports.getachievementslist = "bx_block_contentmanagement/my_achievements";
