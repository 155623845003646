//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Button, createStyles, Typography
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { finish } from "../assets";
export class Finished extends React.Component {
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={5}
      >
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}>
          <img src={finish} className={classes.finishImg} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" className={classes.headTxt}>
            THANK YOU
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.subTxt}>
            Lorem ipsum dolor sit amet,consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolor
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Button
            onClick={this.props.handleReset} className={classes.cancelBtn}>
            <Typography variant="h3">Back to Profile Page</Typography>
          </Button>
        </Grid>
      </Grid>
    )
  }
}
//@ts-ignore
const FinishedWebWithRouter = withRouter(Finished);
export default withStyles((theme) =>
  createStyles({
    headTxt: {
      color: '#7764db',
      marginLeft: '1.5rem'
    },
    subTxt: {
      color: '#4f4f4f',
      textAlign: 'center'
    },
    finishImg: {
      height: '8rem',
      width: '8rem'
    },
    cancelBtn: {
      borderRadius: '24px',
      backgroundColor: '#ebebeb',
      height: '50px',
      width: '200px',
      color: '#4f4f4f',
      '&:hover': {
        backgroundColor: '#ebebeb',
        color: '#4f4f4f'
      }
    },
  })
)(FinishedWebWithRouter);