//@ts-nocheck
import React from "react";
//@ts-ignore
import insane from 'insane';
import {
  withStyles,
  Grid,
  Hidden,
  createStyles,
  Paper,
  Typography,
  Button,
  InputBase,
  List, ListItem, ListItemText,
  ListItemAvatar,
  Avatar,
  CardMedia,
  Divider, Card, CardActionArea, CardContent, IconButton,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
//@ts-ignore
import { withRouter } from "react-router";
import ExamWebController, {
  Props,
} from "./ExamController.web";
import { next, prev, play, dummyImage, userImage } from "./assets";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import ReactTimeAgo from 'react-time-ago';
//@ts-ignore
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json';
import { withTranslation } from 'react-i18next';
//@ts-ignore
import ScrollMenu from "react-horizontal-scrolling-menu";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import StarBorderIcon from "@material-ui/icons/StarBorder";
//@ts-ignore
const imgUrl = "https://minio.b54423.dev.centralindia.az.svc.builder.cafe/sbucket/uploads/bx_block_contentmanagement/course/thumbnail/4/Thumbnail1.jpg";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

type coursesProps = {
  classes: any,
  examCourses: any,
  props: any,
  getAllBookmarked: any,
  removeBookmark: any,
  addBookmark: any,
};
type MockTestProps = {
  classes: any,
  mockTestList: any,
};
type examVediosProps = {
  classes: any,
  examVedios: any,
};
type expertProps = {
  classes: any,
  profiles: any,
  gotoExpertProfile: any,
};
type examDiscussionsProps = {
  classes: any,
  examDiscussions: any,
  props: any,
  likeList: any,
};
type examCalenderProps = {
  classes: any,
  props: any,
  examCalenders: any,
};
const ExpertsProfile: React.FC<expertProps> = ({ classes, profiles, gotoExpertProfile }) => {
  return (
    <>
      <Grid item xs={12} className="w-full">
        <ScrollMenu
          clickWhenDrag={true}
          dragging={true}
          hideArrows={true}
          hideSingleArrow={true}
          scrollToSelected={true}
          alignCenter={false}
          transition={0.3}
          translate={0}
          wheel={false}
          arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          data={profiles &&
            profiles.map((e: any, index: number) => (
              <Grid item key={index} >
                <Grid onClick={() => gotoExpertProfile(e?.attributes?.id)} className={classes.proBox} container direction="column" alignItems="center">
                  {e?.attributes?.image ?
                    <Avatar alt="Remy Sharp" src={e?.attributes?.image || imgUrl} className={classes.profilePic} /> :
                    <Avatar alt="Remy Sharp" src={dummyImage} className={classes.profilePic} />
                  }
                  <Typography className={classes.expertname}>{e?.attributes?.name}</Typography>
                </Grid>
              </Grid>
            ))
          } />
      </Grid>
      <Grid item xs={1} />
      {!(profiles && profiles?.length > 0) &&
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item>
            <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
          </Grid>
        </Grid>}
    </>
  );
};
const ExamCourses: React.FC<coursesProps> = ({ classes, examCourses, props, getAllBookmarked, removeBookmark, addBookmark }) => {
  return (
    <Grid item xs={12} className="w-full">
      <Hidden smDown>
        <ScrollMenu
          clickWhenDrag={true}
          dragging={true}
          hideArrows={true}
          hideSingleArrow={true}
          scrollToSelected={true}
          alignCenter={false}
          transition={0.3}
          translate={0}
          wheel={false}
          arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          data={examCourses &&
            (examCourses.map((_data: any, index: number) => (
              <Card className={classes.root} key={index}>
                <CardActionArea >
                  <CardMedia
                    onClick={() => props.history.push("/home/coursedetail", { courseDetail: _data })}
                    component="img"
                    className={classes.suggested}
                    src={_data?.attributes?.thumbnail}
                    title="suggested Card title" />
                  <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                        {_data?.attributes?.enrolled_users}{" "}Enrolled
                      </Typography>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                        {_data?.attributes?.total_time}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography
                        variant="body2"
                        className={classes.runningCoursesCardTitle}
                      >
                        {_data.attributes.heading} {':'} </Typography>
                      <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {_data?.attributes?.user_rating && ([1, 2, 3, 4, 5].map((rating: any) => {
                        return (_data?.attributes?.user_rating >= rating ? <StarIcon key={rating + index} style={{ color: "f48b1b" }} /> : <StarBorderIcon key={rating + index} style={{ color: "f48b1b" }} />);
                      }))}
                      {!_data?.attributes?.user_rating && ([1, 2, 3, 4, 5].map((ratings: any) => {
                        return (<StarBorderIcon key={ratings + index} style={{ color: "f48b1b", }} />);
                      }))}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={6}>
                          <Typography
                            variant="caption"
                            className={classes.shortlistCardSubTitle}>
                            {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          {localStorage.getItem('token') && getAllBookmarked && getAllBookmarked.map((e: any) =>
                            getAllBookmarked.findIndex((e: any) => { return e.id === _data.id }) > -1 ?
                              <IconButton aria-label="bookmark" key={e.id} onClick={(event) => removeBookmark(_data?.attributes?.id, event)}>
                                <BookmarkIcon color="primary" />
                              </IconButton>
                              :
                              <IconButton aria-label="bookmark" key={e.id} onClick={(event) => addBookmark(_data?.attributes?.id, event)}>
                                <BookmarkBorderIcon color="primary" />
                              </IconButton>
                          )}
                          {localStorage.getItem('token') && getAllBookmarked?.length <= 0 &&
                            <IconButton aria-label="bookmark" data-testId="addBookmark" onClick={(event) => addBookmark(_data?.attributes?.id, event)}>
                              <BookmarkBorderIcon color="primary" />
                            </IconButton>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent />
                </CardActionArea>
              </Card>
            ))
            )
          } />
      </Hidden>
      <Hidden mdUp>
        <ScrollMenu
          clickWhenDrag={true}
          dragging={true}
          hideArrows={true}
          hideSingleArrow={true}
          scrollToSelected={true}
          alignCenter={false}
          transition={0.3}
          translate={0}
          wheel={false}
          arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          data={examCourses &&
            examCourses.map((_data: any, index: number) => (
              <Card className={classes.rootSmall} key={index} >
                <CardActionArea>
                  <CardMedia
                    onClick={() => props.history.push("/home/coursedetail", { courseDetail: _data })}
                    component="img"
                    className={classes.suggestedSmall}
                    src={_data?.attributes?.thumbnail}
                    title="suggested Card title"
                  />
                  <Grid container style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginTop: '.5rem' }}>
                    <Grid item xs={4}>
                      <Typography variant="body1" className={classes.suggestedCardEnrolledTimeSmall}>
                        {_data?.attributes?.enrolled_users}{" "}Enrolled
                      </Typography>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Typography variant="body1" className={classes.suggestedCardEnrolledTimeSmall}>
                        {_data?.attributes?.total_time}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '.5rem' }} >
                      <Typography
                        variant="body2"
                        className={classes.runningCoursesCardTitleSmall}
                      >
                        {_data.attributes.heading} {':'} </Typography>
                      <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {[1, 2, 3, 4, 5].map((_data: any) => {
                        return (<StarIcon key={_data} style={{ color: "f8593b", width: '1rem' }} />);
                      })}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '-.75rem' }} container direction="row" alignItems="center" justify="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body1"
                          className={classes.shortlistCardSubTitle}>
                          {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        {localStorage.getItem('token') && getAllBookmarked && getAllBookmarked.map((e: any) =>
                          getAllBookmarked.findIndex((e: any) => { return e.id === _data.id }) > -1 ?
                            <IconButton aria-label="bookmark" key={e.id} onClick={(event) => removeBookmark(_data?.attributes?.id, event)}>
                              <BookmarkIcon color="primary" style={{ height: '1rem' }} />
                            </IconButton>
                            :
                            <IconButton aria-label="bookmark" key={e.id} onClick={(event) => addBookmark(_data?.attributes?.id, event)}>
                              <BookmarkBorderIcon color="primary" style={{ height: '1rem' }} />
                            </IconButton>
                        )}
                        {localStorage.getItem('token') && getAllBookmarked?.length <= 0 &&
                          <IconButton aria-label="bookmark" onClick={(event) => addBookmark(_data?.attributes?.id, event)}>
                            <BookmarkBorderIcon color="primary" style={{ height: '1rem' }} />
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent />
                </CardActionArea>
              </Card>
            ))
          } />
      </Hidden>
      {examCourses && examCourses?.length === 0 &&
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
      }
    </Grid>
  )
}
const MockTest: React.FC<MockTestProps> = ({ classes, mockTestList }) => {
  return (
    <Grid container direction="row" className={classes.mockTestListSection}>
      {mockTestList && mockTestList?.length > 0 ? mockTestList.map((e: any) => (
        <>
          <Grid container direction="row" xs={11} style={{ paddingBottom: '2rem' }} alignItems="flex-start" spacing={2}>
            <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
              {e.attributes.image ?
                <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image} />
                :
                <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
              }
            </Grid>
            <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h3">{e.attributes.name}</Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item>
                    {e.attributes.description?.length > 300 ?
                      <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" >
                          <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                          {/* <Typography variant="subtitle2" color="primary" style={{ cursor: 'pointer', display: 'inline' }}> [... [Read more]]</Typography> */}
                        </Typography></div>
                      : <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography></div>
                    }
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item>
                    {e.attributes.description?.length > 150 ?
                      <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" >
                          <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 150).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                          {/* <Typography variant="subtitle2" color="primary" style={{ cursor: 'pointer', display: 'inline' }}> [... [Read more]]</Typography> */}
                        </Typography></div>
                      : <div className="mt-1.5">
                        <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 150).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography></div>
                    }
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </>
      )) :
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
      }
    </Grid>
  )
}
const ExamVedios: React.FC<examVediosProps> = ({ classes, examVedios }) => {
  return (
    <Grid item xs={12} className="w-full">
      <ScrollMenu
        clickWhenDrag={true}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        scrollToSelected={true}
        alignCenter={false}
        transition={0.3}
        translate={0}
        wheel={false}
        arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
        arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
        data={examVedios && examVedios?.length > 0 &&
          examVedios.map((e: any, index: number) => (
            <Card className={classes.root} key={index}>
              <CardActionArea>
                <Grid container spacing={2} style={{ padding: '0.5rem' }}>
                  <Grid item xs={12}>
                    {e.attributes.video ?
                      <video
                        autoPlay={true}
                        muted
                        loop
                        className={classes.fvPlayer}
                        key={e.attributes.video}
                      >
                        <source src={e.attributes.video}
                          type="video/mp4" />
                      </video>
                      :
                      <img alt="playvideo" className={classes.play} src={play} />
                    }
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <Typography variant="h3">{e.attributes.name}</Typography>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {e.attributes.description?.length > 100 ?
                      <div>
                        <Typography variant="subtitle2" className={classes.descVideos} color="primary">
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                          {/* <Typography variant="subtitle2" color="primary" style={{ color: 'black', cursor: 'pointer', display: 'inline' }}> [... [Read more]]</Typography> */}
                        </Typography></div>
                      : <div className="mt-1.5">
                        <Typography variant="subtitle2" className={classes.descVideos} color="primary">
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography></div>}
                  </Grid>
                </Grid>
                <CardContent />
              </CardActionArea>
            </Card>
          ))
        } />
      {examVedios && examVedios?.length === 0 &&
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
      }
    </Grid>
  )
}
const ExamDiscussions: React.FC<examDiscussionsProps> = ({ classes, props, examDiscussions, likeList }) => {
  return (
    <Grid item xs={12} className="w-full">
      <ScrollMenu
        clickWhenDrag={true}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        scrollToSelected={true}
        alignCenter={false}
        transition={0.3}
        translate={0}
        wheel={false}
        arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
        arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
        data={examDiscussions &&
          (examDiscussions.map((e: any, index: number) => (
            <Card className={classes.rootCard}>
              <CardContent>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item xs={10}>
                    <Grid container direction="row" alignItems="center" justify="flex-start">
                      <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                        <img src={e?.attributes?.user_image ? e?.attributes?.user_image : userImage} className={classes.userImg} />
                      </Grid>
                      <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                        <Grid container direction="column" >
                          <Typography variant="caption">{e?.attributes?.account?.first_name}</Typography>
                          <Typography variant="body1"><ReactTimeAgo date={e?.attributes?.created_at} locale="en-US" /></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid container direction="row">
                  <Typography variant="h3">{e?.attributes?.title}</Typography>
                  <Grid item>
                  </Grid>
                </Grid>
                <br />
                <Grid container direction="row">
                  {e.attributes.description && e.attributes.description?.length > 100 ?
                    <div>
                      <Typography variant="subtitle2" className={classes.desc} color="primary">
                        <span dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/dashboard/detailview" + { postDetails: e } + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>
                    : <div className="mt-1.5">
                      <Typography variant="subtitle2" className={classes.desc} color="primary">
                        <span dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/dashboard/detailview" + { postDetails: e } + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>}
                </Grid>
                {e?.attributes?.tags && e?.attributes?.tags.length > 0 && <> <br />
                  <Grid container direction="row" justify="flex-start">
                    {e?.attributes?.tags && e?.attributes?.tags.map((el: any) => {
                      return (
                        <Button variant="contained" className={classes.tagChips}>
                          <Typography variant="body1">{el?.name}</Typography></Button>)
                    })}
                  </Grid></>}
                <br />
                <Grid container direction="row" justify="space-between">
                  <Grid item xs={8}>
                    <Grid container direction="row" >
                      <Grid item xs={3}>
                        <Grid container direction="row" alignItems="center">
                          <RemoveRedEyeIcon color="disabled" className={classes.dfIcon} />
                          <Typography variant="body1">{e?.attributes?.view}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction="row" alignItems="center">
                          <ChatBubbleIcon color="disabled" className={classes.dfIcon} />
                          <Typography variant="body1">{e?.attributes?.comments_count}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction="row" alignItems="center">
                          {localStorage.getItem('token') &&
                            likeList?.findIndex((et: any) => { return et?.attributes?.likeable_id === e?.attributes.id }) > -1
                            && e?.attributes?.is_like
                            ? <>
                              <ThumbUpIcon color="disabled" key={e?.id} className={classes.dfIcon} />
                              <Typography variant="body1">{e?.attributes?.likes_count}</Typography>
                            </>
                            :
                            <>
                              <ThumbUpAltOutlinedIcon color="disabled" key={e.id} className={classes.dfIcon} />
                              <Typography variant="body1">{e?.attributes?.likes_count}</Typography>
                            </>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))
          )
        } />
      {examDiscussions && examDiscussions?.length === 0 &&
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
      }
    </Grid >
  )
}
const ExamCalenders: React.FC<examCalenderProps> = ({ classes, props, examCalenders }) => {
  return (
    <>
      <Grid item xs={12} className="w-full">
        <Hidden smDown><ScrollMenu
          clickWhenDrag={true}
          dragging={true}
          hideArrows={true}
          hideSingleArrow={true}
          scrollToSelected={true}
          alignCenter={false}
          transition={0.3}
          translate={0}
          wheel={false}
          arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          //@ts-ignore
          data={examCalenders && examCalenders?.length > 0 &&
            examCalenders.map((_data: any, idx: any) => (
              <Grid item key={idx}>
                <Card className={classes.root} key={idx} >
                  <CardActionArea>
                    <CardMedia
                      data-testId="examRedirect"
                      onClick={() => props.history.push("/home/exam/" + _data.id)}
                      component="img"
                      className={classes.suggested}
                      src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                      title="suggested Card title"
                    />
                    <Grid container spacing={2} className={classes.cardText}>
                      <Grid item xs={12} >
                        <Grid container xs={12}>
                          <Grid xs={6}>
                            <Typography
                              variant="h3"
                              className={classes.examCalenderCardTitle}
                            >
                              {_data?.attributes?.heading}  </Typography>
                          </Grid>
                          <Grid xs={6} className={classes.examDate}>
                            <Typography
                              variant="h3"
                              className={classes.examCalenderCardTitle}
                            >
                              {_data?.attributes?.to}  </Typography>
                          </Grid>
                        </Grid>

                        {_data?.attributes?.description?.length > 30 ?
                          <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                            <span dangerouslySetInnerHTML={{
                              __html: insane(`${_data?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                            }}></span>
                          </Typography>
                          :
                          <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                            <span dangerouslySetInnerHTML={{
                              __html: insane(`${_data?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                            }}></span>
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                          <Grid item xs={6}>
                            <Typography
                              variant="caption"
                              className={classes.shortlistCardSubTitle}>
                              {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <CardContent />
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          } />
        </Hidden>
        <Hidden mdUp> <ScrollMenu
          clickWhenDrag={true}
          dragging={true}
          hideArrows={true}
          hideSingleArrow={true}
          scrollToSelected={true}
          alignCenter={false}
          transition={0.3}
          translate={0}
          wheel={false}
          arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          data={examCalenders &&
            examCalenders.map((_data: any, index: number) => (
              <Card className={classes.rootSmall} key={index} >
                <CardActionArea>
                  <CardMedia
                    onClick={() => props.history.push("/home/exam/" + _data.id)}
                    component="img"
                    className={classes.suggestedSmall}
                    src={_data?.attributes?.thumbnail ? _data?.attributes?.thumbnail : dummyImage}
                    title="suggested Card title"
                  />
                  <Grid container style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginTop: '.5rem' }}>
                    <Grid item xs={12} style={{ marginTop: '.5rem' }} >
                      <Typography
                        variant="h3"
                        className={classes.runningCoursesCardTitleSmall}
                      >
                        {_data?.attributes?.heading}</Typography>
                      {_data?.attributes?.description?.length > 30 ?
                        <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${_data?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 30).concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography>
                        :
                        <Typography variant="subtitle2" color="primary" className={classes.examDes} >
                          <span dangerouslySetInnerHTML={{
                            __html: insane(`${_data?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').concat("...<button><a href='/home/exam/" + _data.id + "'>[Read more]</a></button>")
                          }}></span>
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={12} container direction="row" alignItems="center" justify="space-between">
                      <Grid item xs={12}>
                        <Typography variant="body1"
                          className={classes.shortlistCardSubTitle}>
                          {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent />
                </CardActionArea>
              </Card>
            ))
          } />
        </Hidden>
      </Grid>
      <Grid item xs={1} />
      {
        examCalenders && examCalenders?.length == 0 &&
        <Grid item xs={12}>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
      }
    </>
  )
}
export class ExamWeb extends ExamWebController {
  constructor(props: Props) {
    super(props);
  }
  publishDate = (obj: any) => {
    let months = ['January', 'February', 'March', 'April	', 'May', 'June', 'July', 'August', 'September', 'October	', 'November', 'December']
    let dateobj: any = ''
    if (obj) {
      let date = new Date(obj.updated_at ? obj.updated_at : obj.created_at)
      let shortYear = date.getFullYear();
      let sam = '';
      if (date.getDay() == 1) {
        sam = 'st';
      } else if (date.getDay() == 2 || date.getDay() == 3) {
        sam = 'rd';
      } else {
        sam = 'th';
      }
      dateobj = date.getDay() + sam + ' ' + months[date.getMonth()] + ', ' + shortYear
    }
    return dateobj
  }
  handleViewAll = (value: any) => {
    const { history } = this.props;
    history.push(`/home/articleview/${value}/${this.state.categoryId}`)
  }
  handleCourseViewAll = () => {
    const { history } = this.props;
    history.push({
      pathname: `/home/courseviewall/${this.state.categoryId}`,
      state: {
        is_tranding: true
      }
    })
  }
  render() {
    TimeAgo.addDefaultLocale(en);
    TimeAgo.addLocale(en);
    //@ts-ignore
    const { classes } = this.props;
    const { showExamdetails, examCourses, examVedios, careerExperts, mockTestList, examDiscussions, examCalenders, likeList } = this.state;
    const self = this;
    const { gotoExpertProfile } = self;
    return (
      <>
        <Grid item xs={12} className="items-center flex-wrap" style={{ marginTop: '-0.25rem' }}>
          <Grid className={classes.mainsection}>
            <Typography variant="h4">{showExamdetails?.attributes?.heading} - Latest News, Exam Dates, Registration, Admit Card, Result, Syllabus, Pattern</Typography>
            <Typography variant="h3">Updated on {this.publishDate(showExamdetails?.attributes)}</Typography>
            <Button variant="contained">Download Sample papers</Button>
          </Grid>
          <Grid item xs={12} className={classes.categotybtns}>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item className="overflow-x-auto whitespace-nowrap" style={{ marginTop: '2rem' }}>
                <Button color="primary" variant="outlined">
                  <Typography variant="subtitle2" className={classes.btnTypo}>About {showExamdetails?.attributes?.heading}</Typography>
                </Button>
                <Button color="primary" variant="outlined" data-testId="courseSection" onClick={() => this.scrollToModule('courseSection')}>
                  <Typography variant="subtitle2" className={classes.btnTypo} >Courses</Typography>
                </Button>
                <Button color="primary" variant="outlined" data-testId="videosSection" onClick={() => this.scrollToModule('videosSection')} >
                  <Typography variant="subtitle2" className={classes.btnTypo}>Videos</Typography>
                </Button>
                <Button color="primary" variant="outlined" data-testId="expertSection" onClick={() => this.scrollToModule('expertSection')}  >
                  <Typography variant="subtitle2" className={classes.btnTypo}>Career Experts</Typography>
                </Button>
                <Button color="primary" variant="outlined" data-testId="examDiscussionsSection" onClick={() => this.scrollToModule('examDiscussionsSection')}>
                  <Typography variant="subtitle2" className={classes.btnTypo}>Discussion Forums</Typography>
                </Button>
                <Button color="primary" variant="outlined" data-testId="examCalendersSection" onClick={() => this.scrollToModule('examCalendersSection')}>
                  <Typography variant="subtitle2" className={classes.btnTypo}>Exam Calender</Typography>
                </Button>
                <Button color="primary" variant="outlined" data-testId="mockTestSection" onClick={() => this.scrollToModule('mockTestSection')}>
                  <Typography variant="subtitle2" className={classes.btnTypo} >Mock Tests</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.mainDiv}>
            <Grid item container xs={11}>
              <Grid item xs={12} md={9}>
                <div className={classes.maincontentleft}>
                  <Paper variant="outlined" square className={classes.jeepaper}>
                    <Typography variant="h3" className={classes.articlehead1}>{showExamdetails?.attributes?.heading}</Typography>
                    <Typography variant="subtitle2" color="primary" component="div">
                      {showExamdetails?.attributes?.description?.length > 0 ? <><div dangerouslySetInnerHTML={{
                        __html: insane(`${showExamdetails?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '')
                      }}></div></> : <><Grid item xs={12}>
                        <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
                      </Grid></>}
                    </Typography>
                  </Paper>
                </div>
                <Grid container direction="row" alignItems="center" justify="center">
                  <Grid item xs={12} >
                    <br />
                    <br />
                    <Grid id="courseSection" container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                      <Hidden smDown> <Typography align="center" variant="h4">Courses</Typography></Hidden>
                      <Hidden mdUp> <Typography align="center" variant="h3">Courses</Typography></Hidden>
                      {examCourses && examCourses?.length > 3 &&
                        <>
                          <Button
                            className={classes.viewAll}
                            color="primary"
                            data-testId="handleCourseViewAll"
                            onClick={() => this.handleCourseViewAll()}
                            variant="outlined">
                            <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
                            <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                          </Button>
                        </>
                      }
                    </Grid>
                    <br />
                    <Grid container className={classes.paddingExamArVi}>
                      <ExamCourses data-testId="ExamCourses"  {...{ classes, examCourses, props: this.props, getAllBookmarked: this.state.getAllBookmarked, removeBookmark: this.removeBookmark, addBookmark: this.addBookmark }} />
                      <Grid item xs={1} />
                    </Grid>
                    <br />
                    <Grid id="videosSection" container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                      <Hidden smDown><Typography align="center" variant="h4">Videos</Typography></Hidden>
                      <Hidden mdUp><Typography align="center" variant="h3">Videos</Typography></Hidden>
                      {examVedios && examVedios?.length > 3 && <Button
                        className={classes.viewAll}
                        color="primary"
                        data-testId="handleViewAll"
                        onClick={() => this.handleViewAll('video')}
                        variant="outlined"
                      >
                        <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                        <Hidden mdUp><Typography variant="caption" >View all</Typography></Hidden>
                      </Button>}
                    </Grid>
                    <br />
                    <Grid container className={classes.paddingExamArVi}>
                      <ExamVedios data-testId="ExamVedios" {...{ classes, examVedios }} />
                    </Grid>
                    <br />
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Grid id="expertSection" container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                    <Hidden smDown> <Typography align="center" variant="h4">Career Experts</Typography></Hidden>
                    <Hidden mdUp> <Typography align="center" variant="h3">Career Experts</Typography></Hidden>
                    {careerExperts && careerExperts?.length > 4 && <Button
                      className={classes.viewAll}
                      color="primary"
                      // onClick={() => this.handleChipsClick(index,item.id)}
                      variant="outlined">
                      <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                      <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                    </Button>}
                  </Grid>
                  <br />
                  <Grid container className={classes.paddingExamArVi}>
                    <ExpertsProfile data-testId="ExpertsProfile" {...{ classes, profiles: careerExperts, gotoExpertProfile }} />
                  </Grid>
                  <br />
                  <br />
                  <Grid id="examDiscussionsSection" container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                    <Hidden smDown> <Typography align="center" variant="h4">Exam Discussions</Typography></Hidden>
                    <Hidden mdUp> <Typography align="center" variant="h3">Exam Discussions</Typography></Hidden>
                    {examDiscussions && examDiscussions?.length > 4 && <Button
                      className={classes.viewAll}
                      color="primary"
                      // onClick={() => this.handleChipsClick(index,item.id)}
                      variant="outlined">
                      <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                      <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                    </Button>}
                  </Grid>
                  <br />
                  <Hidden smDown> <br /></Hidden>
                  <Grid container className={classes.paddingExamArVi}>
                    <ExamDiscussions data-testId="ExamDiscussions" {...{ classes, props: this.props, examDiscussions, likeList }} />
                  </Grid>
                  <br />
                  <br />
                  <Grid id="examCalendersSection" container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                    <Hidden smDown> <Typography align="center" variant="h4">Exam Calender</Typography></Hidden>
                    <Hidden mdUp> <Typography align="center" variant="h3">Exam Calender</Typography></Hidden>
                    {examCalenders && examCalenders?.length > 4 && <Button
                      className={classes.viewAll}
                      color="primary"
                      // onClick={() => this.handleChipsClick(index,item.id)}
                      variant="outlined">
                      <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                      <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                    </Button>}
                  </Grid>
                  <br />
                  <Grid container className={classes.paddingExamArVi}>
                    <ExamCalenders data-testId="ExamCalenders" {...{ classes, props: this.props, examCalenders }} />
                  </Grid>
                  <br />
                  <br />
                  <Grid id="mockTestSection" container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                    <Hidden smDown><Typography align="center" variant="h4">Mock Test</Typography></Hidden>
                    <Hidden mdUp><Typography align="center" variant="h3">Mock Test</Typography></Hidden>
                    {mockTestList && mockTestList?.length > 4 && <Button className={classes.viewAll} color="primary"
                      // onClick={() => this.handleViewAll('articles')}
                      variant="outlined">
                      <Hidden smDown> <Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                      <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                    </Button>}
                  </Grid>
                  <br />
                  <Grid container className={classes.paddingExamArVi}>
                    <MockTest data-testId="MockTest" {...{ classes, mockTestList }} />
                  </Grid>
                  <br />
                  <br />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} className={classes.maincontentRight1}>
                <div className={classes.maincontentright}>
                  <Paper className={classes.paper}>
                    <h1 className={classes.paperHeader}>MBA /B,Arch Admission 2021 Open</h1>
                    <List className={classes.lists}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <p className={classes.listItemheading}>UPSE B.Tech Admissions <br />2021</p>
                            </React.Fragment>
                          }
                        />
                        <Button variant="outlined">Apply</Button>
                      </ListItem>
                      <p className={classes.lineitemtext}>Registrations 2021 Open for M.Tech | Apply through VITMEE | QS Star Rated for Excellence</p>
                      <Divider variant="inset" component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <p className={classes.listItemheading}>UPSE B.Tech Admissions <br />2021</p>
                            </React.Fragment>
                          }
                        />
                        <Button variant="outlined">Apply</Button>
                      </ListItem>
                      <p className={classes.lineitemtext}>Registrations 2021 Open for M.Tech | Apply through VITMEE | QS Star Rated for Excellence</p>
                      <Divider variant="inset" component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          secondary={
                            <React.Fragment>
                              <p className={classes.listItemheading}>UPSE B.Tech Admissions <br />2021</p>
                            </React.Fragment>
                          }
                        />
                        <Button variant="outlined">Apply</Button>
                      </ListItem>
                      <p className={classes.lineitemtext}>Registrations 2021 Open for M.Tech | Apply through VITMEE | QS Star Rated for Excellence</p>
                      <Divider variant="inset" component="li" />
                    </List>
                  </Paper>
                  <div className={classes.mailDiv}>
                    <h1>STAY INFORMED</h1>
                    <p>Join our newsletter and have sharp analysis delivered to your inbox every day.</p>
                    <Paper component="form" className={classes.mailpaper}>
                      <InputBase
                        className={classes.malinput}
                        placeholder="Your Mail"
                        inputProps={{ 'aria-label': 'Your Mail' }}
                      />
                      <Button variant="outlined">SUBSCRIBE</Button>
                    </Paper>
                  </div>
                  <div className={classes.courselist}>
                    <Typography variant="h4" style={{ color: '#fa6400' }} className={classes.articlehead}>Suggested Courses</Typography>
                    <List className={classes.lists}>
                      <ListItem className={classes.ebpaperli} alignItems="flex-start">
                        <ListItemAvatar className={classes.ebpaperimg}>
                          <img alt="Remy Sharp" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAM1BMVEXp7vG6vsHs8fS3u77Fycy+wsXc4eTX3N/h5unT2NrHzM7N0tW1ubzu8/W7v8LBxcjl6uwx8f6JAAADy0lEQVR4nO2c23aDIBBFCQheUf//a6vRpEZuJgXj0LNXH7oaK3WXwXEQGAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwGnw9Hz7Et+Ds1ElpxoJaeGsHHqRHlkoKlJ0JbvbKQhRjCSs8FKcY+RuRVKQwqsTlUxShm9f8BGGU53cuvryHeXUyLnTj9++5hC8WJ2kv+2sTkR79Y4y9uuf2papKVYnxcWd8GpV0uj0aaxcnFx9lH04ESeMfLpZ2pLJW/obZzrhcGK2BSdmW3BitkXdyfxYz7mO2hZtJ7yqCznIoVUsXv8h7YSPzZJ2CtENZTQplJ1Mj0CbZ6CuiFUdI+yEt69PhUJGih+6Tni5L7qJJlJbZJ2MZu1A1FHuP2Sd7CPnTh+nLapOKtNIrIyOqhNe28puYvjXThp7KfKAE16FDqDqxF6x7sI1VK26wFCcmRMR6gOTEhG6P+XmJNRPtJrruqL0SSHrxD6ehJxwtZS6vVLIOrFP9wTuO1o95XnCh6qTj/ITrsSRQ8k6Ydbg8YYOV9tDhbO4QNaJbUrd301elXikkHUyZbLGc7F34m4bOI9z2ccUuk6Ybl+liMFXP9GGEme/IuxkfubZXKcofL+vVW8ocYUPZSfThRbdYkUIWftKj3YljjyFtBPGtWplL259UzJfZmoLHPeYQtvJMr0zjsxfnnYrsY4p1J0c+l1H4DzOaByfv5N9XhLsKfk7MfOSkJTsnYSVGANt7k50IHBsZ83ciSsv8faUjJxw821w303YLSUfJ7q+VbvPjit5eRs2Gyfzw0//usTkaODsz5yLk6mXTPTbnhLKS5xSMnGyKJnnMn4j4I3AWeie9e8cnGxmSh/h876S55CShZNtEX8Nn3eG1xyd6Nf59FnKsVQtXyf7qR5R6U96SU5OLG9dVB8pyceJbUJQvpOX5OdElx9dfs5OdMxVgnk4ibtwMgsnvI5oJA8nMceSTJxEHUvycBJ/ETZ5JwnWpZN3Yn1n+H874RJODr4LCidwAic74MQETkzgxAROTODEBE5MzDy2i763VEfcCVOlmr+UMr8J/8DxybpIjKyTlG3BidkWnJhtwYnZFpyYbcGJ2VZBwwkb18SqV6lb4usUyeX3NmTrJozzvy81j7S2Sd8l/4a27XeSFHH5jbqfG4OexvVDx7HjSTqu300Y+91p+BS6NuregKnQjn1gEiBCe6RcBl7K6AUCO0VFRMm89EK1RXKatoq4e+QJJN+N+r4jNQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzuIHpk8/wVCHmdcAAAAASUVORK5CYII=" />
                        </ListItemAvatar>
                        <ListItemText
                          className={classes.ebpaperlitext}
                          primary={
                            <React.Fragment>
                              <Typography variant="body2">Hand-Drawn Animation: The Beginner's Guide to Animating with Procreate</Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Grid item xs={12}>
                                {[1, 2, 3, 4, 5].map((_data: any, id: any) => {
                                  return (<StarIcon key={id} style={{ color: "#000", }} />);
                                })}
                              </Grid>
                              <Grid container spacing={2} style={{ paddingLeft: '0rem', paddingRight: '0rem' }}>
                                <Grid item xs={5}>
                                  <Typography variant="caption" className={classes.shortlistCardSubTitle}>
                                    Joe Thomas
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5}>
                                  <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                    7h 44min
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem className={classes.ebpaperli} alignItems="flex-start">
                        <ListItemAvatar className={classes.ebpaperimg}>
                          <img alt="Remy Sharp" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAM1BMVEXp7vG6vsHs8fS3u77Fycy+wsXc4eTX3N/h5unT2NrHzM7N0tW1ubzu8/W7v8LBxcjl6uwx8f6JAAADy0lEQVR4nO2c23aDIBBFCQheUf//a6vRpEZuJgXj0LNXH7oaK3WXwXEQGAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwGnw9Hz7Et+Ds1ElpxoJaeGsHHqRHlkoKlJ0JbvbKQhRjCSs8FKcY+RuRVKQwqsTlUxShm9f8BGGU53cuvryHeXUyLnTj9++5hC8WJ2kv+2sTkR79Y4y9uuf2papKVYnxcWd8GpV0uj0aaxcnFx9lH04ESeMfLpZ2pLJW/obZzrhcGK2BSdmW3BitkXdyfxYz7mO2hZtJ7yqCznIoVUsXv8h7YSPzZJ2CtENZTQplJ1Mj0CbZ6CuiFUdI+yEt69PhUJGih+6Tni5L7qJJlJbZJ2MZu1A1FHuP2Sd7CPnTh+nLapOKtNIrIyOqhNe28puYvjXThp7KfKAE16FDqDqxF6x7sI1VK26wFCcmRMR6gOTEhG6P+XmJNRPtJrruqL0SSHrxD6ehJxwtZS6vVLIOrFP9wTuO1o95XnCh6qTj/ITrsSRQ8k6Ydbg8YYOV9tDhbO4QNaJbUrd301elXikkHUyZbLGc7F34m4bOI9z2ccUuk6Ybl+liMFXP9GGEme/IuxkfubZXKcofL+vVW8ocYUPZSfThRbdYkUIWftKj3YljjyFtBPGtWplL259UzJfZmoLHPeYQtvJMr0zjsxfnnYrsY4p1J0c+l1H4DzOaByfv5N9XhLsKfk7MfOSkJTsnYSVGANt7k50IHBsZ83ciSsv8faUjJxw821w303YLSUfJ7q+VbvPjit5eRs2Gyfzw0//usTkaODsz5yLk6mXTPTbnhLKS5xSMnGyKJnnMn4j4I3AWeie9e8cnGxmSh/h876S55CShZNtEX8Nn3eG1xyd6Nf59FnKsVQtXyf7qR5R6U96SU5OLG9dVB8pyceJbUJQvpOX5OdElx9dfs5OdMxVgnk4ibtwMgsnvI5oJA8nMceSTJxEHUvycBJ/ETZ5JwnWpZN3Yn1n+H874RJODr4LCidwAic74MQETkzgxAROTODEBE5MzDy2i763VEfcCVOlmr+UMr8J/8DxybpIjKyTlG3BidkWnJhtwYnZFpyYbcGJ2VZBwwkb18SqV6lb4usUyeX3NmTrJozzvy81j7S2Sd8l/4a27XeSFHH5jbqfG4OexvVDx7HjSTqu300Y+91p+BS6NuregKnQjn1gEiBCe6RcBl7K6AUCO0VFRMm89EK1RXKatoq4e+QJJN+N+r4jNQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzuIHpk8/wVCHmdcAAAAASUVORK5CYII=" />
                        </ListItemAvatar>
                        <ListItemText
                          className={classes.ebpaperlitext}
                          primary={
                            <React.Fragment>
                              <Typography variant="body2">Hand-Drawn Animation: The Beginner's Guide to Animating with Procreate</Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Grid item xs={12}>
                                {[1, 2, 3, 4, 5].map((_data: any, id: any) => {
                                  return (<StarIcon key={id} style={{ color: "#000", }} />);
                                })}
                              </Grid>
                              <Grid container spacing={2} style={{ paddingLeft: '0rem', paddingRight: '0rem' }}>
                                <Grid item xs={5}>
                                  <Typography variant="caption" className={classes.shortlistCardSubTitle}>
                                    Joe Thomas
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5}>
                                  <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                    7h 44min
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem className={classes.ebpaperli} alignItems="flex-start">
                        <ListItemAvatar className={classes.ebpaperimg}>
                          <img alt="Remy Sharp" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAM1BMVEXp7vG6vsHs8fS3u77Fycy+wsXc4eTX3N/h5unT2NrHzM7N0tW1ubzu8/W7v8LBxcjl6uwx8f6JAAADy0lEQVR4nO2c23aDIBBFCQheUf//a6vRpEZuJgXj0LNXH7oaK3WXwXEQGAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwGnw9Hz7Et+Ds1ElpxoJaeGsHHqRHlkoKlJ0JbvbKQhRjCSs8FKcY+RuRVKQwqsTlUxShm9f8BGGU53cuvryHeXUyLnTj9++5hC8WJ2kv+2sTkR79Y4y9uuf2papKVYnxcWd8GpV0uj0aaxcnFx9lH04ESeMfLpZ2pLJW/obZzrhcGK2BSdmW3BitkXdyfxYz7mO2hZtJ7yqCznIoVUsXv8h7YSPzZJ2CtENZTQplJ1Mj0CbZ6CuiFUdI+yEt69PhUJGih+6Tni5L7qJJlJbZJ2MZu1A1FHuP2Sd7CPnTh+nLapOKtNIrIyOqhNe28puYvjXThp7KfKAE16FDqDqxF6x7sI1VK26wFCcmRMR6gOTEhG6P+XmJNRPtJrruqL0SSHrxD6ehJxwtZS6vVLIOrFP9wTuO1o95XnCh6qTj/ITrsSRQ8k6Ydbg8YYOV9tDhbO4QNaJbUrd301elXikkHUyZbLGc7F34m4bOI9z2ccUuk6Ybl+liMFXP9GGEme/IuxkfubZXKcofL+vVW8ocYUPZSfThRbdYkUIWftKj3YljjyFtBPGtWplL259UzJfZmoLHPeYQtvJMr0zjsxfnnYrsY4p1J0c+l1H4DzOaByfv5N9XhLsKfk7MfOSkJTsnYSVGANt7k50IHBsZ83ciSsv8faUjJxw821w303YLSUfJ7q+VbvPjit5eRs2Gyfzw0//usTkaODsz5yLk6mXTPTbnhLKS5xSMnGyKJnnMn4j4I3AWeie9e8cnGxmSh/h876S55CShZNtEX8Nn3eG1xyd6Nf59FnKsVQtXyf7qR5R6U96SU5OLG9dVB8pyceJbUJQvpOX5OdElx9dfs5OdMxVgnk4ibtwMgsnvI5oJA8nMceSTJxEHUvycBJ/ETZ5JwnWpZN3Yn1n+H874RJODr4LCidwAic74MQETkzgxAROTODEBE5MzDy2i763VEfcCVOlmr+UMr8J/8DxybpIjKyTlG3BidkWnJhtwYnZFpyYbcGJ2VZBwwkb18SqV6lb4usUyeX3NmTrJozzvy81j7S2Sd8l/4a27XeSFHH5jbqfG4OexvVDx7HjSTqu300Y+91p+BS6NuregKnQjn1gEiBCe6RcBl7K6AUCO0VFRMm89EK1RXKatoq4e+QJJN+N+r4jNQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzuIHpk8/wVCHmdcAAAAASUVORK5CYII=" />
                        </ListItemAvatar>
                        <ListItemText
                          className={classes.ebpaperlitext}
                          primary={
                            <React.Fragment>
                              <Typography variant="body2">Hand-Drawn Animation: The Beginner's Guide to Animating with Procreate</Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Grid item xs={12}>
                                {[1, 2, 3, 4, 5].map((_data: any, id: any) => {
                                  return (<StarIcon key={id} style={{ color: "#000", }} />);
                                })}
                              </Grid>
                              <Grid container spacing={2} style={{ paddingLeft: '0rem', paddingRight: '0rem' }}>
                                <Grid item xs={5}>
                                  <Typography variant="caption" className={classes.shortlistCardSubTitle}>
                                    Joe Thomas
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5}>
                                  <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                    7h 44min
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.mainDiv2}>
         
          </Grid> */}
        </Grid>
      </>
    );
  }
}
//@ts-ignore
const ExamWebRouter = withRouter(ExamWeb);
const ExamWebLoader = withLoader(ExamWebRouter);
const ExamWebToast = withSnackbar(ExamWebLoader);
export const ExamWebWithStyle = withStyles((theme) =>
  createStyles({
    mainsection: {
      padding: '9.375rem 0.9375rem 2.5rem',
      margin: '0 auto',
      textAlign: 'center',
      backgroundColor: '#142b67',
      [theme.breakpoints.down("lg")]: {
        padding: '6.25rem 0.9375rem 1.875rem',
      },
      [theme.breakpoints.down('md')]: {
        padding: '4.375rem 0.9375rem 1.25rem',
      },
      "& h4": {
        maxWidth: '56.25rem',
        margin: '0 auto',
        color: '#fff',
        marginBottom: '1.25rem',
      },
      "& h3": {
        color: '#fff',
        marginBottom: '1.5625rem',
      },
      "& button": {
        backgroundColor: '#fff',
        borderRadius: '0.625rem',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        letterSpacing: 'normal',
        color: '#000',
        "&:hover": {
          backgroundColor: '#c5c0c0',
        },
      }
    },
    categotybtns: {
      padding: '0px 0.625rem',
      "& button": {
        borderRadius: '3.125rem !important',
        borderColor: '#f9593a !important',
        marginRight: '2rem',
        minWidth: '6rem',
        "&:hover": {
          backgroundColor: '#5285f7 !important',
          borderColor: '#5287f7 !important',
        },
        "& h6": {
          letterpacinSg: '1px',
        },
      },
    },
    maincontentright: {
      padding: '0 0.625rem 0 1.875rem',
      [theme.breakpoints.down("md")]: {
        padding: '0px',
      },
    },
    subcategorybtns: {
      "& button": {
        borderColor: '#333333 !important',
        marginRight: '1.5rem',
      },
    },
    selectedBtn: {
      backgroundColor: '#5285f7 !important',
      borderColor: '#5287f7 !important',
    },
    subbtn: {
      borderColor: '#333333 !important',
    },
    jeepaper: {
      padding: '0.8125rem 2.3125rem 1.25rem 1.5rem',
      marginBottom: '1.25rem',
      [theme.breakpoints.down("md")]: {
        padding: '0.8125rem 0.625rem 1.25rem',
      },
      "& hr": {
        marginBottom: '0.9375rem',
      },
      "& h6": {
        marginBottom: '0.9375rem',
      },
      "& table": {
        backgroundColor: '#fffafa',
        "& td": {
          fontFamily: 'Montserrat-Regular',
        },
      },
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    paper: {
      textAlign: "center",
      color: "gray",
      maxWidth: "19rem",
      [theme.breakpoints.down("md")]: {
        marginTop: '0.9375rem',
        maxWidth: "100%",
      },
    },
    lists: {
      width: "100%",
      // maxWidth: "36ch",
      paddingRight: "0.625rem",
      backgroundColor: "",
      "& li": {
        paddingRight: '6px !important'
      },
      " & button": {
        alignSelf: "center",
        borderColor: "#f9593a",
        padding: "0px 0.75rem",
        borderRadius: "0.625rem",
        fontSize: '0.875rem',
        fontFamily: 'Montserrat-SemiBold !important',
        "&:hover": {
          backgroundColor: '#f9593a',
          color: '#fff',
        },
      },
    },
    inline: {
      display: "inline"
    },
    lineitemtext: {
      fontSize: "0.75rem",
      color: "#000",
      paddingLeft: "4.6875rem",
      textAlign: 'left',
      marginBottom: '0.625rem'
    },
    paperHeader: {
      fontSize: "0.875rem",
      color: "#5050ff",
      padding: "0.75rem 0.3125rem 0.8125rem 0.3125rem",
      borderBottom: "1px solid #ecdbdb",
      fontFamily: 'Montserrat-SemiBold',
    },
    mailDiv: {
      padding: "0.625rem",
      color: "gray",
      maxWidth: "19rem",
      margin: "2.5rem 0 3.5rem",
      [theme.breakpoints.down("md")]: {
        padding: "0px",
        margin: '0.9375rem 0 0.9375rem',
      },
      "& h1": {
        color: "#1c1e21",
        fontSize: "1rem",
        fontFamily: 'Montserrat-SemiBold',
        marginBottom: '0.3125rem',
      },
      "& p": {
        color: "#1c1e21",
        fontFamily: 'Montserrat-SemiBold',
      }
    },
    courselist: {
      maxWidth: '31.25rem',
      "& li": {
        padding: '0.625rem',
        margin: '0 0 0.9375rem 0px',
        backgroundColor: ' #fff',
        borderRadius: '1.25rem',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: '100%',
      },
    },
    mockpaper: {
      backgroundColor: '#fffafa',
      borderRadius: '0px !important',
      margin: '1.25rem 0',
      "& h3": {
        padding: '0.3125rem',
        color: '#fff',
        backgroundColor: '#1e43b3',
        textAlign: 'center',
      },
      "& form": {
        maxWidth: '31.25rem',
        margin: '0 auto',
        padding: '1.5625rem 0.9375rem 3.125rem',
        textAlign: 'center',
        "& label": {
          color: '#000',
          fontSize: '0.8125rem',
        },
        "& input": {
          color: '#000',
        },
        "& button": {
          borderColor: '#f9593a',
          color: '#fff !important',
          padding: '4px 1rem !important',
          borderRadius: '1.25rem !important',
          backgroundColor: '#f9593a !important',
        }
      },
    },
    mailpaper: {
      padding: "0px 0px 0px 4px",
      display: "flex",
      alignItems: "center",
      // width: 400,
      borderRadius: "0.3125rem",
      boxShadow: "0 0.3125rem 0.5rem 0 rgba(221, 221, 221, 0.25) !important",
      border: "solid 1px #e2e2e2 !important",
      backgroundColor: "#fff",
      marginTop: "0.875rem",
      "& button": {
        borderLeft: '1px solid #e2e2e2 !important',
        border: 'none',
        padding: '0.4375rem 1.125rem',
        fontFamily: 'Montserrat-SemiBold',
      },
    },
    malinput: {
      "& input": {
        fontSize: "1.125rem",
        color: "#a89595 !important",
        paddingLeft: "0.625rem",
      },
    },
    // ebpaper: {
    //   maxWidth: "460.3125rem",
    //   padding: "22px 0.625rem 1.25rem 0.5rem",
    //   boxShadow: "0 3px 0.75rem 0 gba(0, 0, 0, 0.16)",
    //   backgroundColor: "#fff",
    //   margin: "0 auto",
    //   [theme.breakpoints.down("md")]: {
    //     maxWidth: "100%",
    //    },
    //   "& li": {
    //     paddingLeft: "0px !important",
    //   },
    // },
    ebpaperhead: {
      fontSize: "0.9375rem",
      color: "#1d1313",
      fontFamily: 'Montserrat-SemiBold',
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8125rem",
      },
    },
    ebpaperbtn: {
      alignSelf: "flex-end",
      fontSize: "1rem !important",
      color: "#fff !important",
      padding: "2px 1rem !important",
      textShadow: "0 3px 0.75rem 0 gba(0, 0, 0, 0.16)",
      backgroundColor: "#f9593a !important",
      borderRadius: "0.3125rem !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8125rem !important",
      },
    },
    ebpaperimg: {
      width: "5rem",
      height: "5rem",
      marginRight: "0.625rem",
      marginTop: "0px !important",
      borderRadius: "0.625rem !important",
      [theme.breakpoints.down("md")]: {
        width: "3.75rem",
        height: "auto",
        marginRight: "0.625rem",
      },
      "& img": {
        maxWidth: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "0.625rem !important",
      },
    },
    ebpaperli: {
      display: "grid  !important",
      gridTemplateColumns: "auto auto"
    },
    ebpaperlitext: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: "1 1 auto",
      marginTop: "0px !important",
      marginBottom: "0px !important",
      height: "100%",
    },
    articlehead1: {
      color: "#6236ff",
      fontWeight: 'bold',
      marginBottom: '0.625rem',
    },
    articlebox: {
      padding: '0.9375rem 1.875rem',
      display: 'block',
      margin: '1.25rem 0px',
      backgroundColor: '#fffafa',
    },
    articlehead: {
      fontSize: "1rem",
      color: "#6236ff",
      fontWeight: 'bold',
      marginBottom: '1.25rem',
    },
    registercard: {
      padding: "0.8125rem 2.3125rem 1.25rem 1.5rem",
      // boxShadow: "0 3px 6px 0 var(--black-16)",
      boxShadow: "0px 2px 1px -1px rgb(155 151 151 / 0%), 0px 1px 1px 0px rgb(141 136 136 / 18%), 0px 1px 3px 0px rgb(151 149 149 / 60%)",
      backgroundColor: "#fff",
      marginBottom: '1.25rem',
      [theme.breakpoints.down("md")]: {
        padding: "0.8125rem 0.625rem 1.25rem",
      },
      "& h3": {
        color: '#1e43b3 !important',
      },
    },
    registercardbody: {
      border: '1px solid #d9d2d2',
      padding: '1rem 0.9375rem 0.3125rem 1.3125rem',
    },
    registercardfooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1.25rem 0.3125rem 0.625rem",
      "& p": {
        color: "#9c9c9c",
      },
      "& button": {
        backgroundColor: '#f9593a',
        padding: '2px 0.625rem',
        marginLeft: '0.625rem',
        color: '#fff',
        [theme.breakpoints.down("md")]: {
          padding: '2px 1rem',
          whiteSpace: 'nowrap',
        },
      },
    },
    regcardli: {
      padding: '0px !important',
    },
    documentlists: {
      padding: '0px !important',
    },
    // nextarticleli: {
    //   width: "220.4375rem",
    //   height: "190px",
    //   margin: "40.3125rem auto",
    //   paddingLeft: "0px !important",
    // },
    // nextarticleimg: {
    //   marginTop: "0px!important",
    //   marginRight: "52px",
    // },
    nextarticlelitext: {
      alignSelf: "center",
      "& p": {
        fontSize: "1.125rem",
        color: "#201616",
      },
      "& >span": {
        fontSize: "0.8125rem",
        color: "#1e43b3",
      },
    },
    listItemheading: {
      fontSize: '1rem',
      fontFamily: 'Montserrat-semibold',
      color: '#000'
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    mainDiv: {
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
    },
    mainDiv2: {
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c"
    },
    examCalenderCardTitle: {
      color: "#1c1c1c",
      marginBottom: '0.5rem',
      whiteSpace: 'normal',
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      // width: "18rem"
    },
    rootSmall: {
      borderRadius: "8px",
      width: '9rem',
      margin: '.3rem',
      height: '11.5rem'
    },
    root: {
      borderRadius: "8px",
      width: '20rem',
      margin: '1rem'
    },
    suggestedSmall: {
      backgroundColor: "#bdb4b4",
      height: "5rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTimeSmall: {
      color: "#1c1c1c"
    },
    runningCoursesCardTitleSmall: {
      fontSize: '10px !important',
      color: "#1c1c1c",
      whiteSpace: 'pre-wrap'
    },
    fvPlayer: {
      display: 'block',
      width: '20rem',
      height: '10rem',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
    play: {
      display: 'block',
      width: '20rem',
      height: '10rem',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed'
    },
    proBox: {
      marginRight: '2rem'
    },
    profilePic: {
      width: '12rem',
      height: '12rem'
    },
    expertname: {
      color: '#f48b1b',
      marginTop: '1rem'
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    cardText: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginTop: '0.5rem'
    },
    examDes: {
      display: 'inline-flex',
      "& > span": {
        display: 'flex !important'
      },
      "& p": {
        color: '#2b2b2b !important'
      }
    },
    rootCard: {
      width: "20rem",
      margin: "1rem",
      borderRadius: '4.5px',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)'
    },
    dfIcon: {
      height: '1.2rem',
      fill: '#7764db'
    },
    tagChips: {
      backgroundColor: '#eaeaea',
      margin: '.2rem'
    },
    userImg: {
      height: '2rem',
      width: '2.5rem',
      borderRadius: '50%',
      paddingRight: "5px",
    },
    descVideos: {
      wordBreak: 'break-all',
      cursor: 'pointer',
      whiteSpace: 'normal'
    },
    desc: {
      wordBreak: 'break-all',
      height: '65px',
      cursor: 'pointer',
      whiteSpace: 'normal'
    },
    examDate: {
      display: 'flex',
      justifyContent: 'end',
    },
    mockTestListSection: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)(ExamWebToast);
export default withTranslation()(ExamWebWithStyle);