//@ts-nocheck
import React from "react";
import {
    withStyles,
    Container,
    Grid,
    createStyles,
    Typography,
    Divider,
} from "@material-ui/core";
import { withSnackbar } from "../../../components/src/toast.web";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import ActivityFeedLogController, { Props } from './ActivityFeedLogController.web';
import withLoader from "../../../components/src/withLoader.web";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

export class ActivityFeedLog extends ActivityFeedLogController {
    constructor(props: Props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getActivityFeedLog();
    }

    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" style={{ padding: "0px", paddingLeft: '5rem' }}>
                <Grid container direction="column" alignItems="flex-start" justify="center">
                    <Grid item xs={12} style={{ margin: '1rem 0rem' }}>
                        <Typography variant="h4" >My Activity<Divider className={classes.underLineTxt}></Divider></Typography>
                    </Grid>
                    <Grid item container direction="row" xs={11} alignItems="flex-start" style={{ margin: '2rem 0rem' }}>
                        {this.state?.activityFeedLogList?.length > 0 ?

                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">No.</TableCell>
                                            <TableCell align="right">Type</TableCell>
                                            <TableCell align="right">Module Name</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                            <TableCell align="right">Id</TableCell>
                                            <TableCell align="right">Date Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state?.activityFeedLogList && this.state?.activityFeedLogList?.map((data, index) => (
                                            <TableRow key={data?.id}>
                                                <TableCell align="left"> {this.state?.currentPage > 1 ? (index + 1) + (10 * (this.state?.currentPage - 1)) : (index + 1)}</TableCell>
                                                <TableCell align="right">{data?.type}</TableCell>
                                                <TableCell align="right">{data?.attributes?.module_name}</TableCell>
                                                <TableCell align="right">{data?.attributes?.action}</TableCell>
                                                <TableCell align="right">{data?.attributes?.module_id}</TableCell>
                                                <TableCell align="right">{data?.attributes?.DateTime}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <Typography variant="h3" display="block" className={classes.noActivityText} align="center" gutterBottom>
                                No Activity Available 
                            </Typography>
                        }
                        {this.state?.totalPage > 1 && <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
                            <Pagination page={this.state?.currentPage} onChange={this.onPageChange} count={this.state?.totalPage} variant="outlined" shape="rounded" />
                        </Grid>}
                    </Grid>
                </Grid>
            </Container >
        );
    }
}
const ActivityFeedLogWebRouter = withRouter(ActivityFeedLog);
const ActivityFeedLogWebWithLoader = withLoader(ActivityFeedLogWebRouter);
const ActivityFeedLogWebToast = withSnackbar(ActivityFeedLogWebWithLoader);
const ActivityFeedLogWebWithStyle = withStyles((theme) =>
    createStyles({
        activityText: {
            color: "#fff",
            fontSize: "20px"
        },
        table: {
            minWidth: 650,
        },
        underLineTxt: {
            backgroundColor: '#7764db',
            textDecoration: 'underline',
            height: '4px',
            width: '9rem',
        },
        paginator: {
            marginTop: '2rem',
            marginBottom: '4rem',
            justifyContent: 'center',
        },
    })
)(ActivityFeedLogWebToast);
export default withTranslation()(ActivityFeedLogWebWithStyle);
