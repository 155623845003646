import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { displayTimeInHoursminutes } from "./CommonFunctions"

export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    openToastHandler: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  filterdata: any[];
  selectedType: any;
  selectedSubType: any;
  selectedChip: any;
  courseList: any[];
  sliderValue: any[];
  ratingValue: any;
  searchValue: any;
  totalPages: any;
  getAllBookmarked: any;
  pageNo: any;
  queryobj: any;
  open: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}


export default class CourseViewallWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCourseBySearchId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  BookmarkedCourseApiCallId: string = "";
  getFilterOptionsId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      filterdata: [
        {
          "type": "Category",
          "key": "category",
          "id": 0,
          "data": [],
          "value": [],
          "subvalue": [],
        },
        {
          "type": "Sub Category",
          "key": "sub_category",
          "id": 1,
          "data": [],
          "value": [],
          "subvalue": [],
        },
        {
          "type": "Content Provider",
          "key": "content_provider",
          "id": 2,
          "data": [],
          "value": [],
        },
        {
          "type": "Languages",
          "key": "content_language",
          "id": 3,
          "data": [],
          "value": [],
        },
        {
          "type": "Rating",
          "key": "rating",
          "id": 4,
          "data": [],
          "value": [],
        },
        {
          "type": "Price",
          "key": "price",
          "fromPrice": "",
          "toPrice": "",
          "id": 5,
          "data": [],
          "value": [],
        }
      ],
      selectedType: 0,
      selectedSubType: 1,
      selectedChip: 0,
      courseList: [],
      sliderValue: [0, 0],
      ratingValue: null,
      searchValue: '',
      totalPages: 0,
      getAllBookmarked: [],
      pageNo: 0,
      queryobj: {
        '0': 'category[]',
        '1': 'sub_category[]',
        '2': 'content_provider[]',
        '3': 'language[]',
        '4': 'rating',
        '5': 'price',
      },
      open: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.iniateMethods()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.addBookmarkApiCallId:
            this.handleAddBookMarkResponce(responseJson);
            break;
          case this.BookmarkedCourseApiCallId:
            this.handleBookMarkCourseResponce(responseJson);
            break;
          case this.removeBookmarkApiCallId:
            this.handleremoveBookmarkResponce(responseJson);
            break;
          case this.getFilterOptionsId:
            this.handleFilterOptionsResponce(responseJson);
            break;
          case this.getCourseBySearchId:
            this.handleCourseBySearchIdResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }

  handleCourseBySearchIdResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ courseList: responseJson.data, totalPages: responseJson.total_pages });
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
    }
    this.props.hideLoader();
  }
  handleFilterOptionsResponce = (responseJson: any) => {
    if (responseJson !== undefined && responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        info[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: info });
    }
  }

  handleremoveBookmarkResponce = (responseJson: any) => {
    if (responseJson && responseJson?.success) {
      if (localStorage.getItem('token')) {
        this.getAllBookmarkedCourses();
      }
    } else if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.clearLocalstorage(responseJson)
      }
    }
  }

  handleBookMarkCourseResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ getAllBookmarked: responseJson?.data });
    } else if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.clearLocalstorage(responseJson)
      }
    }
  }
  handleAddBookMarkResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      if (localStorage.getItem('token')) {
        this.getAllBookmarkedCourses();
      }
      this.props.openToastHandler("success", 'Bookmarked'!);
    }
    else if (responseJson && responseJson?.data && responseJson?.data?.attributes?.errors?.account_id) {
      this.props.openToastHandler("error", responseJson?.data?.attributes?.errors?.account_id[0]);
    }
    else if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.clearLocalstorage(responseJson)
      }
    }
  }

  clearLocalstorage = (responseJson: any) => {
    if (responseJson?.errors[0]?.token) {
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login`!);
      setTimeout(() => {
        localStorage.clear();
      }, 2000)
    }
    else {
      this.props.openToastHandler("error", responseJson.errors[0]);
    }
  }

  // set default chip
  iniateMethods = () => {
    let data = this.state.filterdata
    data[0].value.push(this.props.match?.params?.categoryId)
    if (this.props?.match?.params?.categoryId) {
      this.setState({ selectedChip: this.props?.match?.params?.categoryId, filterdata: data }, () => {
        this.getFilterOptions(this.state?.filterdata[0]?.key, this.state.filterdata)
        this.getCoursesBysearchCriteria(this.state.filterdata)
      })
    }
  }
  getCoursesBysearchCriteria = (data: any) => {
    this.props.showLoader();
    const header: any = {
      "Content-Type": configJSON.apiContentType,
    };
    const token = localStorage.getItem('token');
    if (token)
      header['token'] = token;
    let params = ''
    if (data && data.length > 0) {
      data.map((ele: any) => {
        if (ele.value.length > 0 && ele.id != 5) {
          ele.value.forEach((e: any, i: any) => {
            params += `&${this.state.queryobj[ele.id]}=` + e
          })
        }
        if (ele.id == 5 && ele.fromPrice && ele.toPrice) {
          params += `&price[min]=${ele.fromPrice}&price[max]=${ele.toPrice}`
        }
      })
    }

    if (this.state.ratingValue) {
      params += '&rating=' + this.state.ratingValue
    }
    if (this.state.searchValue) {
      params += `&search=${this.state.searchValue}`;
    }
    let key = Object.keys(this.props.location.state)[0]
    let apiEndPoint = ''
    if (params) {
      apiEndPoint = `${configJSON.courselistAPIEndPoint}?page=${this.state.pageNo}&per_page=10${params}&${key}=${this.props.location.state[key]}`;
    }
    else {
      apiEndPoint = `${configJSON.courselistAPIEndPoint}?page=${this.state.pageNo}&per_page=10&${key}=${this.props.location.state[key]}`;
    }
    this.getCourseBySearchId = sendAPIRequest(apiEndPoint,
      { headers: header })
  }

  addBookmark = (id: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      const headers = {
        "token": token,
        "Content-Type": configJSON.apiContentType
      }
      const data = {
        "bookmarkable_id": id,
        "bookmarkable_type": "course"
      }
      const apiEndPoint = configJSON.addBookmarkUrl;
      const method = configJSON.httpPostType;
      this.addBookmarkApiCallId = sendAPIRequest(
        apiEndPoint,
        {
          method,
          body: { ...data },
          headers: headers
        },
      );
    }
    else {
      this.openDialog(true);
    }
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  removeBookmark = (id: any) => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.apiContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const method = configJSON.httpPostType;
    this.removeBookmarkApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...data },
        headers: headers
      },
    );
  }
  getAllBookmarkedCourses = () => {
    const token = localStorage.getItem('token');
    this.BookmarkedCourseApiCallId = sendAPIRequest(configJSON.getBookmarkCourseUrl,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } });
  }

  getFilterOptions = (filterby: any, filterinfo: any) => {
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.value.length > 0) {
        ele.value.forEach((e: any, i: any) => {
          query += `&${this.state.queryobj[ele.id]}=` + e
        })
      }
    })
    let key = Object.keys(this.props.location.state)[0];
    let filter_type: any = { "is_popular": 'is_popular_course', "is_trending": 'is_trending_course', "new_courses": 'new_course' };
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=${filter_type[key]}${query}`;
    this.getFilterOptionsId = sendAPIRequest(apiEndPoint,
      { headers: { "Content-Type": configJSON.apiContentType } })
  }
  showTime = (time: any) => displayTimeInHoursminutes(time)

}