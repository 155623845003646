//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  Hidden,
  createStyles,
  IconButton,
  Typography,
  Button,
  List, ListItem, ListItemText, CardMedia,
  Divider, Card, CardActionArea, CardContent
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { withSnackbar } from "../../../components/src/toast.web";
import insane from 'insane';
import { withRouter } from "react-router";
import ActivityFeedControllerWeb, { Props } from './ActivityFeedController.web';
//@ts-ignore
import { withTranslation } from 'react-i18next';
import GifPlayer from "react-gif-player";
import { next, prev, downArrow, epub, dummyImage, groupImg, playweb } from "./assets";
//@ts-ignore
import { Carousel } from "react-responsive-carousel";
import ScrollMenu from "react-horizontal-scrolling-menu";
import withLoader from "../../../components/src/withLoader.web";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import VisibilityIcon from '@material-ui/icons/Visibility';
//@ts-ignore
import AudioPlayer from 'react-responsive-audio-player'
import SearchWeb from "../../Search/src/Search.web";
import StarBorderIcon from "@material-ui/icons/StarBorder";
//@ts-ignore
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";

let ScrollMenuCommonProps = {
  clickWhenDrag: true,
  dragging: true,
  hideArrows: true,
  hideSingleArrow: true,
  scrollToSelected: true,
  alignCenter: true,
  transition: "0.3",
  translate: 0,
  wheel: true
};

export class ActivityFeedWeb extends ActivityFeedControllerWeb {
  categoryData: any;
  constructor(props: Props) {
    super(props);
  }
  //@ts-ignore
  async componentDidMount() {
    this.backgroundSourceUrl();
    if (localStorage.getItem("selectedChip") !== null && localStorage.getItem("selectedChip") !== undefined) {
      this.handleChipsClick(localStorage.getItem("selectedChip"), localStorage.getItem("categoryId"));
    } else {
      this.handleChipsClick(0, 4);
    }
  }
  handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
  handleViewAll = (value: any, categoryId: any) => {
    const { history } = this.props;
    history.push(`/home/articleview/${value}/${categoryId}`)
  }
  handleExamViewAll = () => {
    const { history } = this.props;
    history.push(`/home/examlist`)
  }
  handleQuizViewAll = (categoryId: any) => {
    const { history } = this.props;
    history.push(`/home/quizviewall/${categoryId}`)
  };
  handleAssessmentViewAll = (categoryId: any) => {
    const { history } = this.props;
    history.push(`/home/assessments/${categoryId}`)
  };
  handleCourseViewAll = (categoryId) => {
    const { history } = this.props;
    history.push({
      pathname: `/home/courseviewall/${this.state.categoryId}`,
      state: {
        is_trending: true
      }
    })
  }
  highlightSelectedExam = (index: any, item: any) => {
    const { history } = this.props;
    this.setState((state: any) => ({
      ...state,
      selectedExamChip: index
    }));
    history.push(`/home/exam/${item.id}`)
  }
  gotoQuiz = (qId: any) => {
    const { history } = this.props;
    history.push(`/home/quiz/${qId}`);
  }
  gotoAssessment = (qId: any) => {
    const { history } = this.props;
    history.push(`/home/assessment/${qId}`);
  }
  gotoCourseDetailPage = (_data: any) => {
    this.props.history.push("/home/coursedetail", { courseDetail: _data })
  }

  examBox = (topExam, classes, selectedExamChip) => {
    return (<>{
      topExam && topExam.length > 0 ?
        <ScrollMenu
          {...ScrollMenuCommonProps}
          arrowLeft={<Grid item ><img className="h-6 w-8 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="h-6 w-8 arrow-next" src={next} /></Grid>}
          //@ts-ignore
          data={topExam && topExam.length > 0 &&
            topExam.map((item: any, index: number) => (
              //@ts-ignore
              <Button
                key={index}
                className={selectedExamChip === index ? `${classes.selectedExamBtn}` : `${classes.examBtn}`}
                color="primary"
                style={{ height: '2rem', marginBottom: '.5rem' }}
                onClick={() => this.highlightSelectedExam(index, item)}
                variant="outlined"
              >
                {item && item.attributes && item.attributes.heading && <Typography variant="body2" className={classes.btnTypo}>{item.attributes.heading}</Typography>}
              </Button>
            ))
          }
        />
        :
        <Grid container direction="column" alignItems="center" className={classes.paddingExamArVi}>
          <Grid item >
            <Typography align="center" variant="body2" >No Exam Available this month</Typography>
          </Grid>
        </Grid>
    }</>
    )
  }
  categoryBox = (classes, categories, selectedChip) => {
    return (
      <>
        {categories && categories.length > 0 &&
          categories.map((item: any, index: number) => (
            <React.Fragment key={item.id}>
              <Hidden smDown>
                <Button
                  testId="categoryBox"
                  className={selectedChip && localStorage.getItem("selectedChip") == index ? `${classes.selectedBtn}` : `${classes.catBtn}`}
                  color="primary"
                  onClick={() => this.handleChipsClick(index, item.id)}
                  variant="outlined"
                >
                  {item && item.attributes && item.attributes.name && <Typography variant="subtitle2" className={classes.btnTypo}>{item.attributes.name}</Typography>}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Button
                  key={item.id}
                  className={selectedChip && localStorage.getItem("selectedChip") == index ? `${classes.selectedSmallBtn}` : `${classes.catSmallBtn}`}
                  color="primary"
                  onClick={() => this.handleChipsClick(index, item.id)}
                  variant="outlined"
                >
                  {item && item.attributes && item.attributes.name && <Typography variant="subtitle2" className={classes.btnSmallTypo}>{item.attributes.name}</Typography>}
                </Button>
              </Hidden>
            </React.Fragment>
          ))
        }
      </>
    );
  }
  videoBox = (mimeType, url, classes) => {
    return (<>{
      mimeType && mimeType.includes('video') ?
        <video
          autoPlay={true}
          muted
          loop
          className={classes.player}
          key={url}
        >
          <source src={localStorage.getItem("url") || url || ""}
            type={localStorage.getItem("mimeType") || mimeType || ""} />
        </video>
        :
        <GifPlayer autoPlay={true} className={classes.gifImg} gif={url} />
    } </>)
  }
  contentsUi = (contents, classes) => {
    return (<>{
      contents && contents.length > 0 ? contents.map((e: any) => (
        < div key={e.id}>
          <ListItem className={classes.listItem} key={e.id}>
            <ListItemText
              primary={<Typography variant="h3">{e.attributes.name}</Typography>}
              secondary={e.attributes.description.length > 100 ?
                <div className="mt-2">
                  <Typography variant="caption" color="primary">
                    <p style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                      __html: insane(`${e.attributes.description}`).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                    }}></p>
                  </Typography></div>
                : <div className="mt-1.5"><Typography variant="caption" color="primary" style={{ display: 'inline' }}>
                  <span dangerouslySetInnerHTML={{
                    __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).substring(0, 100).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                  }}></span>
                </Typography></div>
              }
            />
          </ListItem>
          <Divider className={classes.divider} />
        </div>
      )) :
        <Grid item>
          <Typography variant="body1" className="text-center">Will Update You Soon</Typography>
        </Grid>
    }</>)
  }
  callingAddBookmark = (_data) => this.addBookmark(_data?.attributes?.id)
  callingCourseDetailFunction = (_data) => this.gotoCourseDetailPage(_data);
  callingRemoveBookmarkButton = (_data) => this.removeBookmark(_data?.attributes?.id);
  callingTrendinUi = (trendingCourseList, classes, getAllBookmarked) => {
    return (
      trendingCourseList &&
      trendingCourseList.map((_data: any, index: number) => (
        <Card key={index} className={classes.root}
          data-testid="redirecttocoursepage" id="trending" >
          <CardActionArea >
            <CardMedia
              component="img"
              onClick={this.callingCourseDetailFunction.bind(this, _data)}
              className={classes.suggested}
              src={_data?.attributes?.thumbnail}
              title="suggested Card title" />
            <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                  {_data && _data.attributes && _data.attributes.enrolled_users && <>{_data.attributes.enrolled_users}</>}{" "}Enrolled
                </Typography>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                  {_data && _data.attributes && _data.attributes.total_time && this.showTime(_data.attributes.total_time)}
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.heading} {' : '} {_data.attributes.description}</Typography>
              </Grid>
              <Grid item xs={12}>
                {_data && _data.attributes && _data.attributes.user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
                  return (_data.attributes.user_rating >= rating ? <StarIcon key={rating + index} className={classes.Icons} /> : <StarBorderIcon key={rating + index} className={classes.Icon} />);
                })}
                {!_data.attributes.user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
                  return (<StarIcon key={ratings + index} className={classes.Icons} />)
                })}
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      className={classes.shortlistCardSubTitle}>
                      {_data && _data.attributes && _data.attributes.instructors && _data.attributes.instructors.data && _data.attributes.instructors.data.length > 0 && _data.attributes.instructors.data[0].attributes && _data.attributes.instructors.data[0].attributes.name && <>{_data.attributes.instructors.data[0].attributes.name}</>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {localStorage.getItem('token') && getAllBookmarked && getAllBookmarked.map((e: any) =>
                      getAllBookmarked.findIndex((e) => { return e.attributes?.content?.id == _data.id }) > -1 ?
                        <IconButton aria-label="bookmark" data-testid="remove" key={e.id}
                          onClick={this.callingRemoveBookmarkButton.bind(this, _data)}>
                          <BookmarkIcon color="primary" />
                        </IconButton>
                        :
                        <IconButton aria-label="bookmark" key={e.id} onClick={this.callingAddBookmark.bind(this, _data)}>
                          <BookmarkBorderIcon color="primary" />
                        </IconButton>
                    )}
                    {getAllBookmarked.length <= 0 &&
                      <IconButton aria-label="bookmark" onClick={this.callingAddBookmark.bind(this, _data)}>
                        <BookmarkBorderIcon color="primary" />
                      </IconButton>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CardContent />
          </CardActionArea>
        </Card>
      )))
  }
  trendingUi = (trendingCourseList, classes, getAllBookmarked) => {
    return (<Grid item xs={12} className="w-full">
      <Hidden smDown>
        <ScrollMenu
          {...ScrollMenuCommonProps}
          arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          data={this.callingTrendinUi(trendingCourseList, classes, getAllBookmarked)
          } />
      </Hidden>
      <Hidden mdUp>
        <ScrollMenu
          {...ScrollMenuCommonProps}
          arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
          arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
          data={trendingCourseList &&
            trendingCourseList.map((_data: any, index: number) => (
              <Card data-testid="redirecttocoursepage" className={classes.rootSmall} id="courseCard" key={index}
                onClick={this.callingCourseDetailFunction.bind(this, _data)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    className={classes.suggestedSmall}
                    src={_data?.attributes?.thumbnail}
                    title="suggested Card title"
                  />
                  <Grid container style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginTop: '.5rem' }}>
                    <Grid item xs={4}>
                      <Typography variant="body1" className={classes.suggestedCardEnrolledTimeSmall}>
                        {_data && _data.attributes && _data.attributes.enrolled_users && <>{_data.attributes.enrolled_users}</>}{" "}Enrolled
                      </Typography>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Typography variant="body1" className={classes.suggestedCardEnrolledTimeSmall}>
                        {_data && _data.attributes && _data.attributes.total_time && <>{_data.attributes.total_time}</>}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '.5rem' }} >
                      <Typography
                        variant="body2"
                        className={classes.runningCoursesCardTitleSmall}
                      >
                        {_data.attributes.heading} {':'} </Typography>
                      <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {_data && _data.attributes && _data.attributes.user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
                        return (_data.attributes.user_rating >= rating ? <StarIcon key={rating + index} style={{ color: "f48b1b" }} /> : <StarBorderIcon key={rating + index} style={{ color: "f48b1b" }} />);
                      })}
                      {!_data.attributes.user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
                        return (<StarBorderIcon key={ratings + index} style={{ color: "f48b1b", }} />);
                      })}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '-.75rem' }} container direction="row" alignItems="center" justify="space-between">
                      <Grid item xs={6}>
                        <Typography variant="body1"
                          className={classes.shortlistCardSubTitle}>

                          {_data && _data.attributes && _data.attributes.instructors && _data.attributes.instructors.data && _data.attributes.instructors.data.length > 0 && _data.attributes.instructors.data[0].attributes && _data.attributes.instructors.data[0].attributes.name && <>{_data.attributes.instructors.data[0].attributes.name}</>}

                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        {localStorage.getItem('token') && getAllBookmarked && getAllBookmarked.map((e: any) =>
                          getAllBookmarked.findIndex((e) => { return e.attributes?.content?.id == _data.id }) > -1 ?
                            <IconButton aria-label="bookmark" data-testid="remove" key={e.id} onClick={this.callingRemoveBookmarkButton.bind(this, _data)}>
                              <BookmarkIcon color="primary" style={{ height: '1rem' }} />
                            </IconButton>
                            :
                            <IconButton aria-label="bookmark" key={e.id} onClick={this.callingAddBookmark.bind(this, _data)}>
                              <BookmarkBorderIcon color="primary" style={{ height: '1rem' }} />
                            </IconButton>
                        )}
                        {getAllBookmarked.length <= 0 &&
                          <IconButton aria-label="bookmark" onClick={this.callingAddBookmark.bind(this, _data)}>
                            <BookmarkBorderIcon color="primary" style={{ height: '1rem' }} />
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent />
                </CardActionArea>
              </Card>
            ))
          } />
      </Hidden>
    </Grid>)
  }

  handleLiveStreamDataSm = (liveStreamingVideos, classes) => {
    liveStreamingVideos.map((e: any, i: any) => (
      <>
        <Grid item key={i}>
          <>
            <Grid container direction="column" alignItems="center">
              <>
                <Grid item style={{ margin: '1rem' }}>
                  {e && e.attributes && e.attributes.contentable && e.attributes.contentable.data && e.attributes.contentable.data.attributes && e.attributes.contentable.data.attributes.url && e.attributes.contentable.data.attributes.url ?
                    <div className="relative ">
                      <video
                        autoPlay={true}
                        muted
                        loop
                        className={classes.fvPlayer}
                        key={e.attributes.contentable.data.attributes.url}
                      >
                        <source src={e.attributes.contentable.data.attributes.url}
                        />
                      </video>
                      <div className={classes.overlay}>
                        <img alt="playvideo" className={classes.play} src={playweb} />
                      </div>
                    </div>
                    :
                    <Typography variant="body1">No video </Typography>}
                </Grid>
              </>
              <br />
              <Grid item style={{ width: '18rem', whiteSpace: 'break-spaces' }}>
                {e && e.attributes && e.attributes.name && <Typography variant="h3">{e.attributes.name}</Typography>}
              </Grid>
              <br />
              <Grid item style={{ width: '18rem', whiteSpace: 'break-spaces' }}>
                {this.handleLiveStreamDesc(e, classes)}
              </Grid>
            </Grid>
          </>
        </Grid>
      </>
    ))
  }

  handleLiveStreamDataMd = (liveStreamingVideos, classes) => {
    liveStreamingVideos.map((e: any, i: any) => (
      <Grid item key={i}>
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ margin: '1rem' }}>
            {e && e.attributes && e.attributes.contentable && e.attributes.contentable.data && e.attributes.contentable.data.attributes && e.attributes.contentable.data.attributes.url && e.attributes.contentable.data.attributes.url ?
              <div className="relative ">
                <video
                  autoPlay={true}
                  muted
                  loop
                  className={classes.fvSmallPlayer}
                  key={e.attributes.contentable.data.attributes.url}
                >
                  <source src={e.attributes.contentable.data.attributes.url}
                    type="video/mp4"
                  />
                </video>
                <div className={classes.overlay}>
                  <img alt="playvideo" className={classes.smallPlay} src={playweb} />
                </div>
              </div>
              :
              <Typography variant="body1">No video </Typography>}
          </Grid>
          <br />
          <Grid item className={classes.liveStreamingText} >
            {e && e.attributes && e.attributes.name && <Typography variant="h3">{e.attributes.name}</Typography>}
          </Grid>
          <br />
          <Grid item className={classes.liveStreamingText}>
            {this.handleLiveStreamDesc(e, classes)}
          </Grid>
        </Grid>
      </Grid>
    ))
  }


  handleLiveStreamDesc = (e, classes) => {
    return <>
      {e && e.attributes && e.attributes.description && e.attributes.description.length > 100 ?
        <div>
          <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
            <span dangerouslySetInnerHTML={{
              __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/livestream/" + e.id + "'>[Read more]</a></button>")
            }}></span>
          </Typography></div>
        : <div className="mt-1.5"> <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
          <span dangerouslySetInnerHTML={{
            __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/livestream/" + e.id + "'>[Read more]</a></button>")
          }}></span>
        </Typography></div>}
    </>
  }

  liveStreamingUi = (liveStreamingVideos, classes) => {
    return (liveStreamingVideos &&
      liveStreamingVideos.length > 0 ?
      <>
        <Hidden smDown>
          <ScrollMenu
            clickWhenDrag={true}
            dragging={true}
            hideArrows={true}
            hideSingleArrow={true}
            scrollToSelected={true}
            alignCenter={false}
            transition={0.3}
            translate={0}
            wheel={false}
            arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
            arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
            //@ts-ignore
            data=
            {
              this.handleLiveStreamDataSm(liveStreamingVideos, classes)
            } />
        </Hidden>
        <Hidden mdUp>
          <ScrollMenu
            clickWhenDrag={true}
            dragging={true}
            hideArrows={true}
            hideSingleArrow={true}
            scrollToSelected={true}
            alignCenter={false}
            transition={0.3}
            translate={0}
            wheel={false}
            arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
            arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
            //@ts-ignore
            data=
            {
              this.handleLiveStreamDataMd(liveStreamingVideos, classes)
            } />
        </Hidden>
      </>
      :
      <Grid item>
        <Typography className="text-center" variant="body2">Will Update You Soon</Typography>
      </Grid>
    )
  }
  featureArticleUi = (classes, featureArticle) => {
    return (
      featureArticle && featureArticle.length > 0 ? featureArticle.map((e: any, i: number) => (
        <Grid key={i} container item direction="row" xs={12} sm={12} md={9} lg={9} xl={9} style={{ paddingBottom: '3rem' }} alignItems="flex-start" spacing={2}>
          <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
            {e.attributes.image ?
              <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image} />
              :
              <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
            }
          </Grid>
          <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h3">{e.attributes.name}</Typography>
              </Grid>
              <Hidden smDown>
                <Grid item>
                  {e.attributes.description.length > 300 ?
                    <div className="mt-1.5">
                      <Typography variant="subtitle2" color="primary" >
                        <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>
                    : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                      <span dangerouslySetInnerHTML={{
                        __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                      }}></span>
                    </Typography></div>
                  }
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item>
                  {e.attributes.description.length > 150 ?
                    <div className="mt-1.5">
                      <Typography variant="subtitle2" color="primary" >
                        <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 150).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>
                    : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                      <span dangerouslySetInnerHTML={{
                        __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 150).concat("...<button><a href='/home/articaldetails/articles/" + e.id + "'>[Read more]</a></button>")
                      }}></span>
                    </Typography></div>
                  }
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      )) :
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
    )
  };
  featureVideoUi = (classes, featureVideo) => {
    return (
      featureVideo && featureVideo.length > 0 && featureVideo.map((e: any) => (
        <Grid item key={e.id} lg={4} md={6} sm={6} xs={12}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              {e.attributes.video ?
                <div className="relative ">
                  <video
                    autoPlay={true}
                    muted
                    loop
                    className={classes.fvPlayer}
                    key={e.attributes.video}
                  >
                    <source src={e.attributes.video}
                      type="video/mp4" />
                  </video>
                  <div className={classes.overlay}>
                    <img alt="playvideo" className={classes.play} src={playweb} />
                  </div>
                </div>
                :
                <Typography variant="body1">No video </Typography>}
            </Grid>
            <br />
            <Grid item style={{ width: '18rem' }}>
              <Typography variant="h3">{e.attributes.name}</Typography>
            </Grid>
            <br />
            <Grid item style={{ width: '18rem' }}>
              {e.attributes.description.length > 100 ?
                <div>
                  <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                    <span dangerouslySetInnerHTML={{
                      __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                    }}></span>
                  </Typography></div>
                : <div className="mt-1.5"> <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                  <span dangerouslySetInnerHTML={{
                    __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 100).concat("...<button><a href='/home/articaldetails/video/" + e.id + "'>[Read more]</a></button>")
                  }}></span>
                </Typography></div>}
            </Grid>
          </Grid>
        </Grid>
      ))
    )
  }
  epubBox = (e, val) => {
    let followCount = 0;
    if (e.attributes.follow) {
      if (e.attributes.follow > 1000) {
        followCount = (e.attributes.follow / 1000) + 'k';
      } else {
        followCount = e.attributes.follow;
      }
    } else {
      followCount = '0k';
    }
    return (
      <div className="mt-1.5">
        {e.attributes.description.length > val ? (
          <Typography variant="subtitle2" color="primary">
            <span
              style={{ display: 'inline' }}
              dangerouslySetInnerHTML={{
                __html: insane(`${e.attributes.description}`, {
                  allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'],
                })
                  .replace('&nbsp', '')
                  .substring(0, val)
                  .concat(
                    "...<button><a href='/home/articaldetails/epubs/" +
                    e.id +
                    "'>[Read more]</a></button>"
                  ),
              }}
            ></span>
          </Typography>
        ) : (
          <Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
            <span
              dangerouslySetInnerHTML={{
                __html: insane(`${e.attributes.description}`, {
                  allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li']
                })
                  .replace('&nbsp', '')
                  .substring(0, val)
                  .concat(
                    "...<button><a href='/home/articaldetails/epubs/" +
                    e.id +
                    "'>[Read more]</a></button>"
                  ),
              }}
            ></span>
          </Typography>
        )}
        <Typography variant="subtitle2">
          <VisibilityIcon style={{ fontSize: '0.81rem' }} />{' '}
          {followCount}</Typography>
      </div>
    );
  };
  featurePodcastUi = (classes, featurePodcasList) => {
    return (
      featurePodcasList && featurePodcasList.length > 0 ? featurePodcasList.map((e: any, i: number) => (
        <Grid key={i} container direction="row" alignItems="flex-start" justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Card className={classes.rootpodcast}>
              <CardActionArea>
                <CardContent style={{ backgroundColor: '#060606' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h3" style={{ color: '#fff' }} >
                        {e.attributes.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ color: '#fff' }} >
                      {e.attributes.audio ? <AudioPlayer playlist={[{
                        url: e.attributes.audio,
                        title: ''
                      }]} controls={['playpause', 'progressdisplay']} /> : <AudioPlayer playlist={[{
                        url: 'https://parse-server-ff.s3.amazonaws.com/ae5992f0f5bb1f259bafa41b3771e3bb_call12565815456dwwwwww795896232www-01b59bd3.mp3',
                        title: ''
                      }]} controls={['playpause', 'progressdisplay']} />}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  {e.attributes.description.length > 300 ?
                    <div className="mt-1.5">
                      <Typography variant="subtitle2" color="primary" >
                        <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                          __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/podcast/" + e.id + "'>[Read more]</a></button>")
                        }}></span>
                      </Typography></div>
                    : (<div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                      <span dangerouslySetInnerHTML={{
                        __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/podcast/" + e.id + "'>[Read more]</a></button>")
                      }}></span>
                    </Typography></div>)
                  }
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      )) :
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
    )
  }
  featureEpubUi = (classes, featureEpubList) => {
    return (
      featureEpubList && featureEpubList.length > 0 ? featureEpubList.map((e: any, i: number) => (
        <Grid key={i} container item direction="row" xs={12} sm={12} md={10} lg={10} xl={10} className={classes.rootpodcast} alignItems="flex-start" spacing={2}>
          <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
            {e && e.attributes && e.attributes.thumbnail ?
              <img alt="article" className="rounded-lg h-40 w-48"
                src={e.attributes.thumbnail} style={{ borderRadius: '20px' }} />
              :
              <img alt="article" className="rounded-lg h-40 w-48" src={epub} style={{ borderRadius: '20px' }} />
            }
          </Grid>
          <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h3">{e.attributes.heading}</Typography>
              </Grid>
              <Hidden smDown>
                <Grid item>
                  {this.epubBox(e, 300)}
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item>
                  {this.epubBox(e, 150)}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      )) :
        <Grid item>
          <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
        </Grid>
    )
  }
  callingGotoQuiz = (id) => this.gotoQuiz(id)
  quizBlock = (classes, featureQuiz, isLogin) => {
    return (<>  <Grid item xs={12} className="w-full" id="quiz">
      <ScrollMenu
        clickWhenDrag={true}
        dragging={true}
        hideArrows={true}
        hideSingleArrow={true}
        scrollToSelected={true}
        alignCenter={false}
        transition={0.3}
        translate={0}
        wheel={false}
        arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
        arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
        data={featureQuiz &&
          featureQuiz.map((e: any, index: number) => (
            <Grid item key={index}>
              <Grid className={classes.quizBox} container direction="column" alignItems="center" justify="space-between">
                <Grid className={classes.quizBox2} container direction="column">
                  {e.attributes.heading && <Typography id="quizHeading" className={classes.quizHeading}>{e.attributes.heading}</Typography>}
                  {isLogin &&
                    <>
                      <Grid container direction="row" justify="space-between" className={classes.cardItem}>
                        <Typography className={classes.quizText}>Attempts</Typography>
                        <Typography className={classes.quizText}>{e?.attributes?.attempt_count || 0}</Typography>
                      </Grid>
                    </>}
                  <Grid container direction="row" justify="space-between" className={classes.cardItem}>
                    <Typography className={classes.quizText}>Questions</Typography>
                    <Typography className={classes.quizText}>{e?.attributes?.test_questions?.data?.length || 0}</Typography>
                  </Grid>
                  <Grid container direction="row" justify="space-between" className={classes.cardItem}>
                    <Typography className={classes.quizText}>Time</Typography>
                    <Typography className={classes.quizText}>{e?.attributes?.timer.indexOf(':') == -1 ? e?.attributes?.timer : parseInt(e?.attributes?.timer.split(':')[0] * 60) + parseInt(e?.attributes?.timer.split(':')[1])} mins</Typography>
                  </Grid>
                </Grid>
                <Grid item className={classes.startassessment}>
                  <Button
                    className={classes.quizBtn}
                    onClick={this.callingGotoQuiz.bind(this, e.id)}
                  >
                    <Typography className={classes.quizBtnTxt} variant="body2">Start Quiz</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))
        } />
    </Grid>
      <Grid item xs={1} /></>)
  }
  callingGotoAssessment = (id) => this.gotoAssessment(id)
  assessmentUi = (classes, assessmentsList, isLogin) => {
    return (<>
      <Grid container className={classes.paddingExamArVi}>
        <Grid item xs={12} className="w-full">
          <ScrollMenu
            clickWhenDrag={true}
            dragging={true}
            hideArrows={true}
            hideSingleArrow={true}
            scrollToSelected={true}
            alignCenter={false}
            transition={0.3}
            translate={0}
            wheel={false}
            arrowLeft={<Grid item><img className="w-12 arrow-prev" src={prev} /></Grid>}
            arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
            data={assessmentsList &&
              assessmentsList.map((e: any, index: number) => (
                <Grid item key={index} >
                  <Grid className={classes.quizBox} container direction="column" alignItems="center" justify="space-between">
                    <Grid className={classes.quizBox2} container direction="column">
                      {e.attributes.heading && <Typography className={classes.quizHeading}>{e.attributes.heading}</Typography>}
                      {isLogin &&
                        <>
                          <Grid container direction="row" justify="space-between" className={classes.cardItem}>
                            <Typography className={classes.quizText}>Attempts</Typography>
                            <Typography className={classes.quizText}>{e?.attributes?.attempt_count || 0}</Typography>
                          </Grid>
                        </>}
                      <Grid container direction="row" justify="space-between" className={classes.cardItem}>
                        <Typography className={classes.quizText}>Questions</Typography>
                        <Typography className={classes.quizText}>{e?.attributes?.test_questions?.data?.length || 0}</Typography>
                      </Grid>
                      <Grid container direction="row" justify="space-between" className={classes.cardItem}>
                        <Typography className={classes.quizText}>Time</Typography>
                        <Typography className={classes.quizText}>{e.attributes.timer} mins</Typography>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.startassessment}>
                      <Button data-testid="assessmentbtn"
                        className={classes.quizBtn}
                        onClick={this.callingGotoAssessment.bind(this, e.id)}>
                        <Typography className={classes.quizBtnTxt} variant="body2">Start Assessment</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            } />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {!(assessmentsList && assessmentsList.length > 0) &&
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item>
            <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
          </Grid>
        </Grid>}</>
    )
  }
  callingHandleExamViewAll = () => this.handleExamViewAll();
  callingHandleCoursesViewAll = (id) => this.handleCourseViewAll(id);
  callingHandleViewAll = (type, id) => this.handleViewAll(type, id)
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { mimeType, url, homeDesc, categories, selectedChip, selectedExamChip, contents, featureArticle, featureVideo, topExam,
      trendingCourseList, categoryId, liveStreamingVideos, getAllBookmarked, featureEpubList, featurePodcasList, featureQuiz, assessmentsList, open } = this.state;
    const isLogin = () => localStorage.getItem('token');
    return (<>
      <Grid item xs={12} style={{ marginTop: '-66px' }}>
        {this.videoBox(mimeType, url, classes)}
      </Grid>
      <Container className={classes.videoCont} maxWidth="xl">
        <Grid container direction="row" alignItems="center" justify="center" style={{ marginTop: '-66px' }}>
          <Grid item xs={12} >
            <Grid item container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">
              <SearchWeb id="searchweb" />
              <Grid container direction="row" alignItems="center" justify="center" >
                <Hidden smDown>
                  <Grid item xs={10}>
                    <Typography variant="h5" color="secondary" className={`${classes.car}`}>
                      Next Generation <span className={classes.careerTxt}>Career</span><span className={classes.hunting}> Hunting </span>Experience
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={6} lg={6} xl={6} className="text-center" style={{ marginTop: '-2rem', margin: 'auto', width: '43rem' }}>
                    <Typography variant="caption" color="secondary" className="overflow-hidden break-words ">
                      {homeDesc}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="secondary" className={`${classes.car}`}>
                      Next Generationssss <span className={classes.careerTxt}>Career</span>
                      <span className={classes.hunting}> Hunting </span>Experience
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={6} lg={6} xl={6} className="text-center" style={{ margin: 'auto', width: '43rem' }}>
                    <Typography variant="body1" color="secondary" className={classes.smallText}>
                      {homeDesc}
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
              <Grid container direction="row" alignItems="center" justify="center">
                <img alt="clickdown" className={classes.arrow} onClick={this.handleScroll} src={downArrow} />
              </Grid>
            </Grid>
            <br />
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item className="overflow-x-auto whitespace-nowrap" style={{ marginTop: '2rem' }}>
                {this.categoryBox(classes, categories, selectedChip)}
              </Grid>
            </Grid>
            <br />
            <br />
            <Hidden smDown>
              <br />
              <br />
              <br />
            </Hidden>
            <Grid container direction="row" alignItems="flex-start" spacing={3} justify="space-around">
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Grid container direction="row" alignItems="center" justify="center" >
                  <div className="border rounded-lg border-black h-96 ">
                    <Carousel showThumbs={false}>
                      <div className="h-96">
                        <img className="rounded-lg h-full" alt="" src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
                        <div className="bottom-24 relative"><Button variant="contained"> Apply</Button></div>
                      </div>
                      <div className="h-96">
                        <img alt="" className="rounded-lg h-full" src="https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270" />
                      </div>
                      <div className="h-96">
                        <img alt="" className="rounded-lg h-full" src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
                      </div>
                    </Carousel>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid item container direction="row">
                  <List dense={false} className={classes.list}>
                    {this.contentsUi(contents, classes)}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <br />
            <Hidden smDown>
              <br />
              <br />
              <br />
            </Hidden>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Top Exam This Month</Typography><br /></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Top Exam This Month</Typography><br /></Hidden>
              {topExam && topExam.length > 4 &&
                <Button
                  className={classes.viewAll}
                  color="primary"
                  onClick={this.callingHandleExamViewAll.bind(this)}
                  variant="outlined">
                  <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <Grid container direction="row" alignItems="center"  >
              <div className="mt-20" ></div>
              <Grid item xs={12} className="w-full">
                {this.examBox(topExam, classes, selectedExamChip)}
              </Grid>
            </Grid>
            <br />
            <br />
            <Hidden smDown>
              <br />
              <br />
              <br />
            </Hidden>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown>
                <Typography align="center" variant="h4">Trending Courses</Typography>
              </Hidden>
              {trendingCourseList && trendingCourseList.length > 4 &&
                <Button id="trendingcourse"
                  className={classes.viewAll}
                  color="primary"
                  onClick={this.callingHandleCoursesViewAll.bind(this, categoryId)}
                  variant="outlined">
                  <Hidden smDown><Typography variant="body2" className="font-bold" >View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <br />
            <br />
            <Grid container className={classes.paddingExamArVi}>
              {this.trendingUi(trendingCourseList, classes, getAllBookmarked)}
              <Grid item xs={1} />
            </Grid>
            <br />
            <br />
            <Hidden smDown>
              <br />
              <br />
              <br />
            </Hidden>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown> <Typography align="center" variant="h4">Live Stream</Typography></Hidden>
              <Hidden mdUp> <Typography align="center" variant="h3">Live Stream</Typography></Hidden>
              {liveStreamingVideos && liveStreamingVideos.length > 4 &&
                <Button
                  className={classes.viewAll}
                  color="primary"
                  onClick={this.callingHandleViewAll.bind(this, 'livestream', categoryId)}
                  variant="outlined">
                  <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <br />
            <br />
            <Hidden smDown> <br /></Hidden>
            <Grid container className={classes.paddingExamArVi}>
              <Grid item xs={12} className="w-full">
                {this.liveStreamingUi(liveStreamingVideos, classes)}
              </Grid>
            </Grid>
            <br />
            <br />
            <Hidden smDown> <br />
              <br />
              <br /></Hidden>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Featured Articles</Typography></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Featured Articles</Typography></Hidden>
              {featureArticle && featureArticle.length > 4 &&
                <Button className={classes.viewAll} color="primary"
                  onClick={this.callingHandleViewAll.bind(this, 'articles', categoryid)}
                  variant="outlined">
                  <Hidden smDown> <Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <br />
            <br />
            <Grid container direction="column" alignItems="center" justify="flex-start">
              {this.featureArticleUi(classes, featureArticle)}
            </Grid>
            <Hidden smDown>
              <br />
              <br />
              <br />
            </Hidden>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Featured Videos</Typography></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Featured Videos</Typography></Hidden>

              {featureVideo && featureVideo.length > 4 &&
                <Button
                  className={classes.viewAll}
                  color="primary"
                  onClick={this.callingHandleViewAll.bind(this, 'video', categoryId)}
                  variant="outlined"
                >
                  <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption" >View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <br />
            <br />
            <Hidden smDown> <br /></Hidden>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item xs={12}>
                <Grid container direction="row" spacing={4} >
                  {this.featureVideoUi(classes, featureVideo)}
                </Grid>
                {featureVideo && featureVideo.length === 0 &&
                  <Grid item>
                    <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
            <br />
            <br />
            <Hidden smDown> <br />
              <br />
              <br /></Hidden>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Featured Podcasts</Typography></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Featured Podcasts</Typography></Hidden>
              {featurePodcasList && featurePodcasList.length > 4 &&
                <Button className={classes.viewAll} color="primary"
                  onClick={this.callingHandleViewAll.bind(this, 'podcast', categoryId)}
                  variant="outlined">
                  <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <br />
            <br />
            <Grid container direction="column" alignItems="center" justify="flex-start">
              {this.featurePodcastUi(classes, featurePodcasList)}
            </Grid>
            <br />
            <br />
            <Hidden smDown>
              <br />
              <br />
              <br /></Hidden>
            <Grid container direction="row" alignItems="flex-start" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Featured E-Books</Typography></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Featured E-Books</Typography></Hidden>
              {featureEpubList && featureEpubList.length > 4 &&
                <Button className={classes.viewAll} color="primary"
                  onClick={this.callingHandleViewAll.bind(this, 'epubs', categoryId)}
                  variant="outlined">
                  <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                  <Hidden mdUp><Typography variant="caption">View all</Typography></Hidden>
                </Button>
              }
            </Grid>
            <br />
            <br />
            <Grid container direction="column" alignItems="center" justify="flex-start">
              {this.featureEpubUi(classes, featureEpubList)}
              <br />
              <br />
            </Grid>
            <br />
            <br />
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Featured Quiz </Typography></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Featured Quiz </Typography></Hidden>
              {featureQuiz && featureQuiz.length > 4 && <Button
                className={classes.viewAll}
                color="primary"
                onClick={() => this.handleQuizViewAll(categoryId)}
                variant="outlined"
              >
                <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption" >View all</Typography></Hidden>
              </Button>}
            </Grid>
            <br />
            <br />
            <Grid container className={classes.paddingExamArVi}>
              {this.quizBlock(classes, featureQuiz, isLogin)}
            </Grid>
            {!(featureQuiz && featureQuiz.length > 0) &&
              <Grid container direction="column" alignItems="center" justify="flex-start">
                <Grid item>
                  <Typography variant="body2" className="text-center">Will Update You Soon</Typography>
                </Grid>
              </Grid>}
            <Hidden smDown>
              <br />
              <br />
              <br /></Hidden>
            <br />
            <br />
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
              <Hidden smDown><Typography align="center" variant="h4">Featured Assessments</Typography></Hidden>
              <Hidden mdUp><Typography align="center" variant="h3">Featured Assessments</Typography></Hidden>
              {assessmentsList && assessmentsList.length > 4 && <Button
                className={classes.viewAll}
                color="primary"
                onClick={() => this.handleAssessmentViewAll(categoryId)}
                variant="outlined"
              >
                <Hidden smDown><Typography variant="body2" className="font-bold">View all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption" >View all</Typography></Hidden>
              </Button>}
            </Grid>
            <br />
            <br />
            {this.assessmentUi(classes, assessmentsList, isLogin)}
            <Hidden smDown>
              <br />
              <br />
              <br /></Hidden>
          </Grid>
        </Grid>
      </Container>
      <EmailAccountLoginBlock ParentCallback={open} openDialog={this.openDialog} />
    </>
    );
  }
}
//@ts-ignore
const ActivityFeedWebWithRouter = withRouter(ActivityFeedWeb);
const ActivityFeedWebWithLoader = withLoader(ActivityFeedWebWithRouter);
const ActivityFeedWebToast = withSnackbar(ActivityFeedWebWithLoader);
const ActivityFeedWebWithStyle = withStyles((theme) =>
  createStyles({
    runningCoursesCardTitle: {
      color: "#1c1c1c"
    },
    runningCoursesCardTitleSmall: {
      fontSize: '10px !important',
      color: "#1c1c1c",
      whiteSpace: 'pre-wrap'
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis"
    },
    root: {
      borderRadius: "8px",
      width: '20rem',
      margin: '1rem'
    },
    rootSmall: {
      borderRadius: "8px",
      width: '9rem',
      margin: '.3rem',
      height: '11.5rem'
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    suggestedSmall: {
      backgroundColor: "#bdb4b4",
      height: "5rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTime: {
      color: "#1c1c1c",
      paddingTop: "20px",
    },
    suggestedCardEnrolledTimeSmall: {
      color: "#1c1c1c"
    },
    liveStreamingText: {
      paddingLeft: '.7rem',
      paddingRight: '.7rem',
      width: '12rem',
      whiteSpace: 'break-spaces'
    },
    search: {
      width: '44rem',
      height: '3.5rem',
      borderRadius: '1.5rem',
      webkitBackdropFilter: 'blur(50px)',
      backdropFilter: 'blur(50px)',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      marginTop: '0rem',
      paddingLeft: '1rem',
    },
    searchSm: {
      height: '3rem',
      borderRadius: '1.5rem',
      webkitBackdropFilter: 'blur(50px)',
      backdropFilter: 'blur(50px)',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      marginTop: '6rem',
      paddingLeft: '1rem'
    },
    car: {
      marginTop: '3rem',
      textAlign: 'center',
      marginBottom: '.7rem'
    },
    arrow: {
      position: 'absolute',
      bottom: '1rem',
      height: '1.5rem'
    },
    inputInput: {
      width: '22rem',
      fontSize: '18px',
      fontWeight: 700,
      color: 'white'
    },
    inputInputSm: {
      width: '15rem',
      fontSize: '13px',
      fontWeight: 700,
      color: 'white'
    },
    careerTxt: {
      color: '#8a75f4'
    },
    hunting: {
      color: '#ff931e'
    },
    videoCont: {
      marginTop: '-66px'
    },
    uParallax: {
      height: "100vh",
      width: "100%",
      margin: 'auto'
    },
    player: {
      width: "100%",
      height: "100%",
      objectFit: 'cover',
      position: 'absolute',
      backgroundAttachment: 'fixed',
      zIndex: -1,
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)'
    },
    gifImg: {
      width: "100%",
      height: "100%",
      objectFit: 'fill',
      position: 'fixed',
      backgroundAttachment: 'fixed',
      zIndex: -1,
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)'
    },
    catBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '173px',
      height: '3rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    examBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      height: '1.9rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    selectedBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      width: '173px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    btnSmallTypo: {
      whiteSpace: 'pre-wrap',
      width: '4rem',
      textAlign: 'center',
      fontSize: '9px',
      fontWeight: 100,
      lineHeight: '1'
    },
    catSmallBtn: {
      border: '1.5px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '4.5rem',
      height: '1.8rem',
      marginRight: '.2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      padding: '0.5rem !important'
    },
    selectedSmallBtn: {
      border: '1.5px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '.2rem',
      width: '4.5rem',
      height: '1.8rem',
      padding: '0.5rem !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    selectedExamBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    divider: {
      border: '1px solid black',
      marginTop: '.5rem',
      marginBottom: '1rem'
    },
    article: {
      marginLeft: '1rem'
    },
    list: {
      width: '100%',
      padding: '0px',
      marginTop: '-.5rem'
    },
    list2: {
      width: '100%',
    },
    listItem: {
      padding: '0px',
      wordBreak: 'break-word'
    },
    fvPlayer: {
      height: '16rem',
      display: 'block',
      width: '18rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
    fvSmallPlayer: {
      height: '12rem',
      display: 'block',
      width: '12rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed'
    },
    btnTypo: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflow: 'hidden'
    },
    paddingExamArVi: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.breakpoints.down("xs")]: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
      },
    },
    play: {
      position: 'absolute',
      marginTop: '6rem',
      height: '4rem',
      width: '4rem',
      marginLeft: '7.3rem',
      zIndex: 10000
    },
    smallPlay: {
      position: 'absolute',
      marginTop: '5rem',
      height: '2rem',
      width: '2rem',
      marginLeft: '4.3rem',
      zIndex: 10000
    },
    overlay: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      height: '100%',
      width: '100%',
    },
    examBox: {
      padding: '4px'
    },
    rootpodcast: {
      marginBottom: '.5rem',
    },
    categoryHight: {
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    smallText: {
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textAlign: 'center',
      lineHeight: '2rem'
    },
    quizBox: {
      width: '20rem',
      margin: '0 38px 0px 0',
      padding: '0 0 16px',
      borderRadius: 8,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.06)',
      border: 'solid 1px #e1e1e1',
      backgroundColor: '#fff',
      [theme.breakpoints.down("md")]: {
        width: '12rem',
        margin: '0 14px 14px 0',
        flexDirection: 'inherit',
      },
    },
    quizBox2: {
      width: '100%',
      padding: '11px 14px 13px 9px',
      opacity: 0.88,
      borderRadius: '8px 8px 0px 0px',
      backgroundColor: '#0f1c2b',
      backgroundImage: `url(${groupImg})`,
      backgroundSize: 'cover',
      flexWrap: 'nowrap',
      [theme.breakpoints.down("md")]: {
        padding: 4,
        marginTop: '-17px',
      },
    },
    quizBtn: {
      height: 35,
      margin: '17px auto 0px !important',
      padding: '6px 9px 6px 10px',
      borderRadius: 8,
      backgroundColor: '#ff9322',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("md")]: {
        margin: '17px auto 0px !important',
        height: 25,
      },
    },
    quizBtnTxt: {
      letterSpacing: 'normal',
      color: '#fff',
    },
    quizHeading: {
      fontWeight: 'bold',
      height: '45px',
      color: '#ff9322',
      fontSize: '0.9rem',
      whiteSpace: 'initial',
    },
    quizText: {
      color: '#fff',
      fontSize: '0.8rem',
    },
    cardItem: {
      marginTop: '0.5rem'
    },
    startassessment: {
      height: '25%',
      margin: 'auto'
    },
    Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    },
  })
)(ActivityFeedWebToast);
export default withTranslation()(ActivityFeedWebWithStyle);