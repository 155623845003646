//@ts-nocheck
import React from "react";
//@ts-ignore
import insane from 'insane';
import {
  withStyles,
  Grid,
  createStyles,
  Paper,
  Typography,
  Button,
  InputBase,
  List, ListItem, ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Card, CardActionArea, CardContent, IconButton
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import ArticaldetailsWebController, {
  Props,
} from "./ArticaldetailsController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import { withTranslation } from 'react-i18next';
import ImageIcon from '@material-ui/icons/Image';
import { play, linkedInImg, fbImg } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { FacebookShareButton, LinkedinShareButton } from "react-share";
//@ts-ignore
import * as AudioPlayer from 'react-responsive-audio-player';

const ContentAssest = ({ classes, showArticledetails }) => {
  return (
    <div className={classes.centerimage}>
      {showArticledetails?.attributes?.hasOwnProperty("content_type") ? (<>
        {/* news articles */}
        {showArticledetails?.attributes?.content_type?.id == 1 ? (<img className={classes.titleimg} src={showArticledetails?.attributes?.image} alt="centerimage" />) : ('')}
        {/* short vedios and full vedios */}
        {showArticledetails?.attributes?.content_type?.id == 3 || showArticledetails?.attributes?.content_type?.id == 4 ? (<video className={classes.titlevedio} style={{ margin: "auto" }} controls >
          <source src={showArticledetails?.attributes?.video} type="video/mp4" /></video>) : ('')}
        {/* live stream vedios */}
        <LiveStreamVedios {...{ classes, showArticledetails }} />
        {/* audio podcast */}
        < AudioPodcast {...{ classes, showArticledetails }} />
        {/* epubs */}
        {showArticledetails?.attributes?.content_type?.id == 9 ? (<iframe className={classes.titleiframe} src={`https://docs.google.com/gview?url=${showArticledetails?.attributes?.study_material}&embedded=true`}></iframe>) : ('')}
      </>) : (
        <EpubAudioPodcastContent {...{ classes, showArticledetails }} />
      )}
    </div>
  )
};

const EpubAudioPodcastContent = ({ classes, showArticledetails }) => {
  return (
    <>
      {showArticledetails?.type == 'audio_podcast' ? (<img className={classes.titleimg} src={showArticledetails?.attributes?.image} alt="centerimage" />) : ('')}
      {showArticledetails?.type == 'epub' ? (<iframe className={classes.titleiframe} src={`https://docs.google.com/gview?url=${showArticledetails?.attributes?.files[0]?.pdf?.url}&embedded=true`}></iframe>) : ('')}
    </>
  )
}

const AudioPodcast = ({ classes, showArticledetails }) => {
  return (
    <>
      {showArticledetails?.attributes?.content_type?.id == 6 ? (
        <Grid container direction="column" alignItems="center" justify="flex-start">
          <Grid item xs={12} sm={12} md={12} style={{ width: "100%" }}>
            <Grid container direction="row" alignItems="center" spacing={4}>
              <Grid item xs={12} sm={12}>
                <Card className={classes.rootpodcast}>
                  <CardActionArea>
                    <CardContent style={{ backgroundColor: '#060606' }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="h3" style={{ color: '#fff' }} >
                            {showArticledetails?.attributes?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ color: '#fff' }} >
                          {showArticledetails?.attributes?.audio ? (<AudioPlayer playlist={[{
                            url: showArticledetails?.attributes?.audio,
                            title: ''
                          }]} controls={['playpause', 'progressdisplay']} />) : (<AudioPlayer playlist={[{
                            url: 'https://parse-server-ff.s3.amazonaws.com/ae5992f0f5bb1f259bafa41b3771e3bb_call12565815456dwwwwww795896232www-01b59bd3.mp3',
                            title: ''
                          }]} controls={['playpause', 'progressdisplay']} />)}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : ('')}
    </>
  )
}

const LiveStreamVedios = ({ classes, showArticledetails }) => {
  return (
    <>
      {showArticledetails?.attributes?.content_type?.id == 5 ? (
        <Grid item >
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ margin: '1rem' }}>
              <div className="relative ">
                <video
                  autoPlay={true}
                  muted
                  loop
                  className={classes.fvPlayer}
                  key={showArticledetails?.attributes?.contentable?.data?.attributes?.url}
                >
                  <source src={showArticledetails?.attributes?.contentable?.data?.attributes?.url}
                  />
                </video>
                <div className={classes.overlay}>
                  <img alt="playvideo" style={{ width: '4rem' }} className={classes.play} src={play} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )
        :
        ('')}
    </>
  )
}

const CtaList = ({ classes }) => {
  return (
    <Grid item xs={12} md={4} className={classes.maincontentRight}>
      <div className={classes.maincontentleft} style={{ padding: '0 10px' }}>
        <Paper className={classes.paper}>
          <h1 className={classes.paperHeader}>MBA /B,Arch Admission 2021 Open</h1>
          <List className={classes.lists}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <p className={classes.listItemheading}>UPSE B.Tech Admissions <br />2021</p>
                  </React.Fragment>
                }
              />
              <Button variant="outlined">Apply</Button>
            </ListItem>
            <p className={classes.lineitemtext}>Registrations 2021 Open for M.Tech | Apply through VITMEE | QS Star Rated for Excellence</p>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <p className={classes.listItemheading}>UPSE B.Tech Admissions <br />2021</p>
                  </React.Fragment>
                }
              />
              <Button variant="outlined">Apply</Button>
            </ListItem>
            <p className={classes.lineitemtext}>Registrations 2021 Open for M.Tech | Apply through VITMEE | QS Star Rated for Excellence</p>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <p className={classes.listItemheading}>UPSE B.Tech Admissions <br />2021</p>
                  </React.Fragment>
                }
              />
              <Button variant="outlined">Apply</Button>
            </ListItem>
            <p className={classes.lineitemtext}>Registrations 2021 Open for M.Tech | Apply through VITMEE | QS Star Rated for Excellence</p>
            <Divider variant="inset" component="li" />
          </List>
        </Paper>
        <div className={classes.mailDiv}>
          <h1>STAY INFORMED</h1>
          <p>Join our newsletter and have sharp analysis delivered to your inbox every day.</p>
          <Paper component="form" className={classes.mailpaper}>
            <InputBase
              className={classes.malinput}
              placeholder="Your Mail"
              inputProps={{ 'aria-label': 'Your Mail' }}
            />
            <Button variant="outlined">SUBSCRIBE</Button>
          </Paper>
        </div>
        <Paper className={classes.ebpaper}>
          <h1 className={classes.ebpaperhead}>Jet Exam E-books And Last Year Papers
            Downlaod</h1>
          <List className={classes.lists}>
            {[1, 2, 3].map((ele: any) => <Cta key={ele} {...{ classes }} />)}
          </List>
        </Paper>
      </div>
    </Grid>
  )
}

const Cta = ({ classes }) => {
  return (
    <ListItem className={classes.ebpaperli} alignItems="flex-start">
      <ListItemAvatar className={classes.ebpaperimg}>
        <img alt="Remy Sharp" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAM1BMVEXp7vG6vsHs8fS3u77Fycy+wsXc4eTX3N/h5unT2NrHzM7N0tW1ubzu8/W7v8LBxcjl6uwx8f6JAAADy0lEQVR4nO2c23aDIBBFCQheUf//a6vRpEZuJgXj0LNXH7oaK3WXwXEQGAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwGnw9Hz7Et+Ds1ElpxoJaeGsHHqRHlkoKlJ0JbvbKQhRjCSs8FKcY+RuRVKQwqsTlUxShm9f8BGGU53cuvryHeXUyLnTj9++5hC8WJ2kv+2sTkR79Y4y9uuf2papKVYnxcWd8GpV0uj0aaxcnFx9lH04ESeMfLpZ2pLJW/obZzrhcGK2BSdmW3BitkXdyfxYz7mO2hZtJ7yqCznIoVUsXv8h7YSPzZJ2CtENZTQplJ1Mj0CbZ6CuiFUdI+yEt69PhUJGih+6Tni5L7qJJlJbZJ2MZu1A1FHuP2Sd7CPnTh+nLapOKtNIrIyOqhNe28puYvjXThp7KfKAE16FDqDqxF6x7sI1VK26wFCcmRMR6gOTEhG6P+XmJNRPtJrruqL0SSHrxD6ehJxwtZS6vVLIOrFP9wTuO1o95XnCh6qTj/ITrsSRQ8k6Ydbg8YYOV9tDhbO4QNaJbUrd301elXikkHUyZbLGc7F34m4bOI9z2ccUuk6Ybl+liMFXP9GGEme/IuxkfubZXKcofL+vVW8ocYUPZSfThRbdYkUIWftKj3YljjyFtBPGtWplL259UzJfZmoLHPeYQtvJMr0zjsxfnnYrsY4p1J0c+l1H4DzOaByfv5N9XhLsKfk7MfOSkJTsnYSVGANt7k50IHBsZ83ciSsv8faUjJxw821w303YLSUfJ7q+VbvPjit5eRs2Gyfzw0//usTkaODsz5yLk6mXTPTbnhLKS5xSMnGyKJnnMn4j4I3AWeie9e8cnGxmSh/h876S55CShZNtEX8Nn3eG1xyd6Nf59FnKsVQtXyf7qR5R6U96SU5OLG9dVB8pyceJbUJQvpOX5OdElx9dfs5OdMxVgnk4ibtwMgsnvI5oJA8nMceSTJxEHUvycBJ/ETZ5JwnWpZN3Yn1n+H874RJODr4LCidwAic74MQETkzgxAROTODEBE5MzDy2i763VEfcCVOlmr+UMr8J/8DxybpIjKyTlG3BidkWnJhtwYnZFpyYbcGJ2VZBwwkb18SqV6lb4usUyeX3NmTrJozzvy81j7S2Sd8l/4a27XeSFHH5jbqfG4OexvVDx7HjSTqu300Y+91p+BS6NuregKnQjn1gEiBCe6RcBl7K6AUCO0VFRMm89EK1RXKatoq4e+QJJN+N+r4jNQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzuIHpk8/wVCHmdcAAAAASUVORK5CYII=" />
      </ListItemAvatar>
      <ListItemText
        className={classes.ebpaperlitext}
        primary={
          <React.Fragment>
            <h1 className={classes.ebpaperhead} >FTII JET Sample Papers Acting 2017</h1>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Button className={classes.ebpaperbtn} variant="contained">Downlaod</Button>
          </React.Fragment>
        }
      />
    </ListItem>
  )
}

const ContentInfo = ({ classes, showArticledetails, publishDate }) => {
  return (
    <div className={classes.maincontentleft}>
      <div className={classes.headingdiv}>
        {showArticledetails?.type == 'content' && (<h1>{showArticledetails?.attributes?.name}</h1>)}
        {(showArticledetails?.type == 'epub' || showArticledetails?.type == 'audio_podcast') && (<h1>{showArticledetails?.attributes?.heading}</h1>)}
        <p>
          {showArticledetails?.attributes?.content_provider ? (<span className={classes.headtitle}>{showArticledetails?.attributes?.content_provider?.data?.attributes?.partner_name}</span>) : ('')}
          <span className={classes.headdate}>{publishDate(showArticledetails?.attributes)}</span>
        </p>
      </div>
      <p className={classes.paraMargin} dangerouslySetInnerHTML={{ __html: showArticledetails?.attributes?.description }}>
      </p>
    </div>
  )
}
const CtaInfo = ({ classes, ctalistData }) => {
  return (
    <div className={classes.registercard}>
      <List className={classes.lists}>
        <ListItem className={classes.regcardli} alignItems="flex-start">
          {ctalistData[0]?.attributes?.square_background_image && (
            <ListItemAvatar className={classes.nextarticleimg}>
              <img alt="Remy Sharp" className="rounded-lg h-40 w-48" src={ctalistData[0]?.attributes?.square_background_image} />
            </ListItemAvatar>
          )}
          <ListItemText
            className={classes.nextarticlelitext}
            style={ctalistData[0]?.attributes?.text_alignment == 'centre' ? { textAlign: 'center' } : { textAlign: 'left' }}
            primary={ctalistData[0]?.attributes?.headline ? ctalistData[0]?.attributes?.headline : ''}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className="inline"
                  color="textPrimary"
                >
                  {ctalistData[0]?.attributes?.description ? ctalistData[0]?.attributes?.description : ''}
                </Typography>
              </React.Fragment>
            }
          />
          {ctalistData[0]?.attributes?.has_button && (<Button variant="contained">{ctalistData[0]?.attributes?.button_text}</Button>)}
        </ListItem>
      </List>
    </div>
  )
}
const TagInfo = ({ classes, showArticledetails, showSharePopup }) => {
  return (
    <div className={classes.sharebtns}>
      {showArticledetails?.attributes?.tag_list.length > 0 &&
        (<React.Fragment>
          <Grid item md={10} xs={10} >
            {showArticledetails?.attributes?.tag_list.map(ele => <Button style={{ 'marginBottom': '.25rem' }} key={ele} variant="outlined">{'#' + ele}</Button>)}
          </Grid>
          <Grid item md={2} xs={2} className="text-right">
            <Button className={classes.sharebtn} variant="contained" onClick={() => showSharePopup(true)}>Share</Button>
          </Grid>
        </React.Fragment>)}
    </div>
  )
}

const ContentDetails = ({ classes, contents, props }) => {
  return (
    <Grid container direction="column" alignItems="center" justify="flex-start">
      <Grid container direction="row" alignItems="center" spacing={4}>
        <br />
        <br />
        {contents && contents.length > 0 ? contents.map((e: any) => (
          <React.Fragment key={'contents_' + e.id}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} >
              <Grid className={classes.categoryHight}>
                {e?.attributes?.hasOwnProperty('content_type') ? (
                  <ContentAsset {...{ e }} />
                ) :
                  (
                    <ContentEpubPodcastAsset {...{ e }} />
                  )}

              </Grid>
            </Grid>
            <ContentDescription {...{ classes, e, props }} />
          </React.Fragment>
        )) :
          <Grid item>
            <Typography variant="body2">Will Update You Soon</Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

const ContentEpubPodcastAsset = ({ e }) => {
  return (
    <>
      {(e?.type == "audio_podcast") &&
        (<>
          {e?.attributes?.image ? (<img alt="article" className="rounded-lg w-48 h-full" src={e?.attributes?.image} style={{ borderRadius: '20px' }} />) : (<ImageIcon className="rounded-lg h-full w-48" color="primary" />)}
        </>)}
      {e?.type == "epub" &&
        (<>
          {e?.attributes?.thumbnail ? (
            <img alt="article" className="rounded-lg w-48 h-full" src={e?.attributes?.thumbnail} style={{ borderRadius: '20px' }} />
          ) : (<ImageIcon className="rounded-lg w-48" color="primary" />)}
        </>)}
    </>
  )
}

const ContentAsset = ({ e }) => {
  return (
    <>
      {(e?.attributes?.content_type?.type == "Text" || e?.attributes?.content_type?.type == "AudioPodcast") &&
        (<>
          {e?.attributes?.image ? (<img alt="article" className="rounded-lg h-full w-48" src={e?.attributes?.image} />) : (<ImageIcon className="rounded-lg w-48" color="primary" />)}
        </>)}
      {e?.attributes?.content_type?.type == "Epub" &&
        (<>
          {e?.attributes?.study_material ? (<iframe className="rounded-lg w-48" src={`https://docs.google.com/gview?url=${e?.attributes?.study_material}&embedded=true`}></iframe>) : (<ImageIcon className="rounded-lg h-40 w-48" color="primary" />)}
        </>)}
      {e?.attributes?.content_type?.type == "Videos" && (<>{e?.attributes?.video ? (<video className="rounded-lg w-48" controls >
        <source src={e?.attributes?.video} type="video/mp4" /></video>)
        : (<ImageIcon className="rounded-lg w-48" color="primary" />)}</>)}
    </>
  )
}

const ContentDescription = ({ classes, e, props }) => {
  return (
    <Grid item xs={12} sm={12} md={9} lg={9} xl={10} >
      <Grid container direction="column" className={classes.categoryHight1}>
        <Grid item>
          <Typography variant="h3" className={classes.categoryHeading}>{e?.attributes?.name}</Typography>
        </Grid>
        <Grid item>
          {e?.attributes?.description?.length > 300 ?
            <div className="mt-1.5">
              <Typography variant="subtitle2" color="primary" >
                <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                  __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/" + props.match?.params?.type + '/' + e?.id + "'>[Read more]</a></button>")
                }}></span>
              </Typography></div>
            : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
              <span dangerouslySetInnerHTML={{
                __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/" + props.match?.params?.type + '/' + e?.id + "'>[Read more]</a></button>")
              }}></span>
            </Typography></div>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

const ShareOnSocialLogin = ({ classes, show, showArticledetails, props, hideDialog }) => {
  return (
    <Dialog
      open={show}
      // fullScreen={false}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Share this {showArticledetails?.attributes?.content_type?.type && showArticledetails?.attributes?.content_type?.type.toLowerCase() === 'text' ? 'content' : showArticledetails?.attributes?.content_type?.type.toLowerCase()}
        <IconButton
          className={classes.dailogTitleIconBtn}
          aria-label="close"
          onClick={() => { hideDialog(false) }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.sharecontent}>
        <Grid container >
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" justify="center" className={classes.shareCourse}>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item >
                    <FacebookShareButton
                      url={`http://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/blogsdetails/${props?.match?.params?.id}`}
                      quote={"Blog Views - These are the views got"}
                      hashtag="#CareerHunt"
                      className={classes.shareIcons}
                    >
                      <img src={fbImg} className={classes.socialMediaButton} />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item className={classes.socialBtn}  >
                    <LinkedinShareButton
                      url={`http://dailyhuntwip-54423-react-native.b54423.dev.centralindia.az.svc.builder.cafe/home/blogsdetails/${props?.match?.params?.id}`}
                      quote={"Blog Views - These are the views got"}
                      hashtag="#CareerHunt"
                      className={classes.shareIcons}
                    >
                      <img src={linkedInImg} className={classes.socialMediaButton} />
                    </LinkedinShareButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export class ArticaldetailsWeb extends ArticaldetailsWebController {
  activeStep: any = 0;
  constructor(props: Props) {
    super(props);
  }
  publishDate = (obj: any) => {
    let weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let months = ['January', 'February', 'March', 'April	', 'May', 'June', 'July', 'August', 'September', 'October	', 'November', 'December']
    let d = obj.publish_date ? obj.publish_date : obj.created_at
    if (d) {
      let date = new Date(d)
      let shortYear = date.getFullYear();
      return weeks[date.getDay()] + '  ' + months[date.getMonth()] + ' ' + date.getDate() + ',' + shortYear
    }
    else {
      return ''
    }
  }
  getCaption = () => {
    if (this.props.match?.params?.type == 'blogs' && this.state.showArticledetails?.attributes?.content_type?.type == "Text") {
      return 'Blogs';
    } else if (this.state.showArticledetails?.attributes?.content_type?.type == "Text") {
      return 'Articles';
    }
    return this.state.showArticledetails?.attributes?.content_type?.type;
  };
  showSharePopup = (flag: any) => {
    this.setState({ show: flag })
  }

  hideDialog = (flag: any) => {
    this.setState({ show: flag })
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    return (
      <>
        <div className="items-center flex-wrap mt-4">
          <ContentAssest data-testId="ContentAssest"
            {...{
              classes,
              showArticledetails: this.state.showArticledetails
            }}
          />
          <div className={classes.maindiv}>
            <div className={classes.maincontent}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={8}>
                  <ContentInfo data-testId="ContentInfo" {...{
                    classes,
                    showArticledetails: this.state.showArticledetails,
                    publishDate: this.publishDate
                  }}
                  />
                  {this.state.ctalistData.length > 0 &&
                    (
                      <CtaInfo data-testId="CtaInfo"
                        {...{
                          classes,
                          ctalistData: this.state.ctalistData
                        }}
                      />
                    )}
                  <TagInfo data-testId="TagInfo"
                    {...{
                      classes,
                      showArticledetails: this.state.showArticledetails,
                      showSharePopup: this.showSharePopup
                    }}
                  />
                  <div>
                    <h1 className={classes.articlehead}>Next {this.getCaption()}</h1>
                    <ContentDetails data-testId="ContentDetails"
                      {...{
                        classes,
                        contents: this.state.contents,
                        props: this.props
                      }} />
                  </div>
                </Grid>
                <CtaList data-testId="CtaList" {...{ classes }} />
              </Grid>
            </div>
          </div>
        </div>
        <ShareOnSocialLogin data-testId="ShareOnSocialLogin" {...{
          classes,
          show: this.state.show,
          showArticledetails: this.state.showArticledetails,
          props: this.state.props,
          hideDialog: this.hideDialog
        }} />
      </>
    );
  }
}
//@ts-ignore
const ArticaldetailsRouter = withRouter(ArticaldetailsWeb);
const ArticaldetailsLoader = withLoader(ArticaldetailsRouter);
const ArticaldetailsToast = withSnackbar(ArticaldetailsLoader);
const ArticaldetailsWebWithStyle = withStyles((theme) =>
  createStyles({
    centerimage: {
      maxWidth: "1131px !important",
      width: "100%",
      margin: "50px auto",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "800px !important",
      },
      [theme.breakpoints.down('md')]: {
        margin: "10px auto",
      },
      "& img": {
        width: "100%",
        maxHeight: "27rem",
        maxWidth: "70rem",
        objectFit: "cover",
        objectPosition: "top",
        [theme.breakpoints.down("lg")]: {
          maxHeight: "24rem",
          maxWidth: "50rem",
          [theme.breakpoints.down('md')]: {
            maxHeight: "18rem",
          },
        },
      },
      "& video": {
        width: "100%",
        maxHeight: "27rem",
        maxWidth: "70rem",
        [theme.breakpoints.down("lg")]: {
          maxHeight: "24rem",
          maxWidth: "50rem",
        },
      },
      "& iframe": {
        width: "100%",
        maxHeight: "27rem",
        maxWidth: "70rem",
        [theme.breakpoints.down("lg")]: {
          maxHeight: "24rem",
          maxWidth: "50rem",
        },
      },
    },
    paraMargin: {
      marginTop: "25px",
      marginBottom: "25px",
      "& p": {
        fontFamily: 'Montserrat !important'
      },
    },
    paper: {
      textAlign: "center",
      color: "gray",
      maxWidth: "304px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        marginTop: '15px',
        maxWidth: "100%",
      },
    },
    lists: {
      width: "100%",
      paddingRight: "10px",
      backgroundColor: "",
      "& li": {
        paddingRight: '6px !important'
      },
      " & button": {
        alignSelf: "center",
        borderColor: "#f9593a",
        padding: "0px 12px",
        borderRadius: "10px",
        fontSize: '14px',
        fontFamily: 'Montserrat-SemiBold !important',
        "&:hover": {
          backgroundColor: '#f9593a',
          color: '#fff',
        },
      },
    },
    inline: {
      display: "inline"
    },
    headingdiv: {
      "& h1": {
        fontSize: "36px",
        fontWeight: "bold",
        color: "#2d3748",
      },
    },
    lineitemtext: {
      fontSize: "12px",
      color: "#000",
      paddingLeft: "75px",
      textAlign: 'left',
      marginBottom: '10px'
    },
    paperHeader: {
      fontSize: "14px",
      color: "#5050ff",
      padding: "12px 14.5px 13px 18.5px",
      borderBottom: "1px solid #ecdbdb",
      fontFamily: 'Montserrat-SemiBold',
    },
    mailDiv: {
      padding: "10px",
      color: "gray",
      maxWidth: "304px",
      margin: "40px auto 56px",
      [theme.breakpoints.down("md")]: {
        padding: "0px",
        margin: '15px auto 15px',
      },
      "& h1": {
        color: "#1c1e21",
        fontSize: "16px",
        fontFamily: 'Montserrat-SemiBold',
        marginBottom: '5px',
      },
      "& p": {
        color: "#1c1e21",
        fontFamily: 'Montserrat-SemiBold',
      }
    },
    mailpaper: {
      padding: "0px 0px 0px 4px",
      display: "flex",
      alignItems: "center",
      borderRadius: "5px",
      boxShadow: "0 5px 8px 0 rgba(221, 221, 221, 0.25) !important",
      border: "solid 1px #e2e2e2 !important",
      backgroundColor: "#fff",
      marginTop: "14px",
      "& button": {
        borderLeft: '1px solid #e2e2e2 !important',
        border: 'none',
        padding: '7px 18px',
        fontFamily: 'Montserrat-SemiBold',
      },
    },
    malinput: {
      "& input": {
        fontSize: "18px",
        color: "#a89595 !important",
        paddingLeft: "10px",
      },
    },
    ebpaper: {
      maxWidth: "465px",
      padding: "22px 10px 20px 8px",
      boxShadow: "0 3px 12px 0 gba(0, 0, 0, 0.16)",
      backgroundColor: "#fff",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      "& li": {
        paddingLeft: "0px !important",
      },
    },
    ebpaperhead: {
      fontSize: "15px",
      color: "#1d1313",
      fontFamily: 'Montserrat-SemiBold',
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
    },
    ebpaperbtn: {
      alignSelf: "flex-end",
      fontSize: "16px !important",
      color: "#fff !important",
      padding: "2px 16px !important",
      textShadow: "0 3px 12px 0 gba(0, 0, 0, 0.16)",
      backgroundColor: "#f9593a !important",
      borderRadius: "5px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px !important",
      },
    },
    ebpaperimg: {
      width: "126px",
      height: "181px",
      marginRight: "16px",
      marginTop: "0px !important",
      [theme.breakpoints.down("md")]: {
        width: "105px",
        height: "auto",
        marginRight: "10px",
      },
      "& img": {
        maxWidth: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    ebpaperli: {
      display: "grid  !important",
      gridTemplateColumns: "auto auto"
    },
    ebpaperlitext: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: "1 1 auto",
      marginTop: "0px !important",
      marginBottom: "0px !important",
      height: "100%",
    },
    headtitle: {
      fontSize: "22px",
      color: "#2d3748",
      marginRight: "28px",
      fontWeight: "bold",
      fontFamily: 'Montserrat-bold'
    },
    headdate: {
      fontSize: "16px",
      color: "#1c1e21",
      fontFamily: 'Montserrat-semibold'
    },
    paraaccept: {
      display: "flex",
      justifyContent: "space-between",
      boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#fff",
      paddingLeft: "25px",
      paddingRight: "55px",
      width: "738px",
      margin: "0px auto",
      "& p": {
        fontSize: "20px",
        color: "#1d1313",
        alignSelf: "center",
      },
      "& button": {
        margin: "10px 0px",
        fontSize: "16px",
        color: "#fff",
        borderRadius: "10px",
        backgroundColor: "#f9593a",
      },
    },
    articlehead: {
      fontSize: "20px",
      color: "#292929",
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    sharebtns: {
      display: "flex",
      webkitJustifyContent: "space-between",
      justifyContent: "space-between",
      alignItems: "baseline",
      margin: "101px 0px 114px",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        margin: "25px 0px",
      },
      "& button": {
        marginRight: "15px",
        borderRadius: "10px",
        [theme.breakpoints.down("md")]: {
          marginRight: "5px",
          marginBottom: "5px",
        },
      },
    },
    sharebtn: {
      fontSize: "14px",
      color: "#fff",
      textAlign: "center",
      padding: "6px 17px 6px 18px",
      borderRadius: "10px",
      backgroundColor: "#f9593a",
      letterSpacing: '1px',
      boxShadow: 'none',
      '&:hover': {
        color: "#fff",
        backgroundColor: "#f9593a",
      }
    },
    registercard: {
      padding: "13px 37px 20px 24px",
      boxShadow: "0px 2px 1px -1px rgb(155 151 151 / 0%), 0px 1px 1px 0px rgb(141 136 136 / 18%), 0px 1px 3px 0px rgb(151 149 149 / 60%)",
      backgroundColor: "#fff",
    },
    regcardli: {
      paddingLeft: "0px !important",
      "& p": {
        fontSize: "18px",
        color: "#1d1313",
      },
      "& button": {
        color: "#fff",
        fontSize: "20px",
        padding: "7px 22px 7px 23px",
        borderRadius: "9px",
        backgroundColor: "#f9593a",
      },
      "& > span": {
        fontSize: "24px",
        color: "#1d1313",
      }
    },
    slidesection: {
      maxWidth: 400,
      flexGrow: 1,
      "& img": {
        height: "255",
        display: "block",
        maxWidth: 400,
        overflow: "hidden",
        width: "100%",
      },
    },
    nextarticleli: {
      width: "227px",
      height: "190px",
      margin: "45px auto",
      paddingLeft: "0px !important",
    },
    nextarticleimg: {
      marginTop: "0px!important",
      marginRight: "52px",
    },
    nextarticlelitext: {
      alignSelf: "center",
      "& p": {
        fontSize: "18px",
        color: "#201616",
      },
      "& >span": {
        fontSize: "24px",
        color: "#201616",
      },
    },
    categoryHight: {
      width: '9rem',
      height: '9rem',
      backgroundColor: '#383535',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
      },
    },
    categoryHight2: {
      width: '9rem',
      height: '9rem',
      backgroundColor: '#383535',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '12rem',
      },
    },
    categoryHight1: {
      minHeight: '9rem',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: 'auto',
      },
    },
    categoryHeading: {
      fontSize: '16px',
      fontFamily: 'Montserrat-Bold',
    },
    listItemheading: {
      fontSize: '16px',
      fontFamily: 'Montserrat-semibold',
      color: '#000'
    },
    fvPlayer: {
      height: '16rem',
      display: 'block',
      width: '18rem',
      opacity: '0.51',
      borderRadius: '10px',
      objectFit: 'fill',
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.77) -33%, rgba(57, 56, 56, 0.52) 49%, rgba(0, 0, 0, 0.94), #0b0b0b)',
      backgroundAttachment: 'fixed',
    },
    play: {
      position: 'absolute',
      height: '4rem',
      width: '4rem',
      top: '6rem',
      left: '14rem',
      zIndex: 10000,
    },
    overlay: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      height: '100%',
      width: '100%',
    },
    maindiv: {
      [theme.breakpoints.down("md")]: {
        margin: '8px 15px',
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(15),
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: theme.spacing(17),
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: theme.spacing(20),
        marginTop: theme.spacing(2),
      }
    },
    dailogTitleIconBtn: {
      position: 'absolute',
      right: 4,
      top: 8,
      color: theme.palette.grey[500],
    },
    sharecontent: {
      overflow: 'hidden'
    },
    socialBtn: {
      marginRight: '1rem'
    },
    shareCourse: {
      padding: '2rem',
      backgroundColor: '#f9f5f6'
    },
    socialMediaButton: {
      height: '30px',
      width: '30px',
      margin: 'auto'
    },
    shareIcons: {
      backgroundColor: '#ffffff',
      height: '60px',
      width: '60px',
      borderRadius: '50%'
    }
  })
)(ArticaldetailsToast);
export default withTranslation()(ArticaldetailsWebWithStyle);