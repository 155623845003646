import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  open: boolean;
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  companyDetails: any[];
  jobCategories: any[];
  bannerImage: any[];
  selectedJobType: any[];
  selectedJobExperience: any[];
  selectedLocation: any[];
  selectedDatePosted: any[];
  selectedCompanyName: any;
  selectedCategory: number;
  salaryRange: any[];
  cityState: string;
  sector: string;
  searchJobs: string;
  companies: any[];
  selectedSalaries: any[];
  filterData: any;
  trendingCompanyDetails: any[];
  pageHeading: string;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class JobsHomeWebController extends BlockComponent<Props, S, SS> {
  getCompaniesDetailApiCallId: string = "";
  getJobCategoriesApiCallId: string = "";
  getBannerImageApiCallId: string = "";
  showJobCategoriesApiCallId: string = "";
  showTrendingJobCategoriesApiCallId: string = "";
  updateResumeApiCallId: string = "";
  getProfileDetailApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      open: true,
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      companyDetails: [],
      jobCategories: [],
      bannerImage: [],
      selectedJobType: [],
      selectedJobExperience: [],
      selectedLocation: [],
      selectedDatePosted: [],
      selectedCompanyName: 0,
      selectedCategory: 0,
      salaryRange: [0, 0],
      cityState: "",
      sector: '',
      searchJobs: '',
      companies: [],
      selectedSalaries: [],
      filterData: {},
      trendingCompanyDetails: [],
      pageHeading: ""
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && !responseJson.errors && responseJson.data) {
        switch (apiRequestCallId) {
          case this.getCompaniesDetailApiCallId:
            this.setState({ companies: responseJson.data });
            break;
          case this.getJobCategoriesApiCallId:
            this.setState({ jobCategories: responseJson.data });
            break;
          case this.getBannerImageApiCallId:
            this.setState({ bannerImage: responseJson.data[0] });
            break;
          case this.showJobCategoriesApiCallId:
            this.setState({ companyDetails: responseJson?.data?.attributes?.companies });
            break;
          case this.showTrendingJobCategoriesApiCallId:
            this.setState({ trendingCompanyDetails: responseJson?.data?.attributes?.companies });
            break;
          case this.updateResumeApiCallId:
            this.props.openToastHandler("success", `Uploaded successfully`!);
            localStorage.setItem('cv', responseJson?.data?.attributes?.profile?.data?.attributes?.cv);
            break;
          case this.getProfileDetailApiCallId:
            localStorage.setItem('cv', responseJson?.data?.attributes?.profile?.data?.attributes?.cv);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors[0].token)
          this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  getCompaniesInfo = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompaniesDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCompanyIndexApiEndPoint}?popular=true`
    );
    const header = {
      "token": localStorage.getItem("token") || "",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  gotoJobs = (id: any) => {
    const { history } = this.props;
    history.push(`/home/jobs/all-jobs/company/${id}`);
  };
  getJobCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getJobCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobCategoriesApiEndPoint}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProfileDetail = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProfileDetailApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem('userId');
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileDetailsApiEndPoint}/${userId}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  uploadResume = (file: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.updateResumeApiCallId = requestMessage.messageId;
    const userId = localStorage.getItem("userId");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + userId);
    const token = localStorage.getItem('token');
    const header = { "token": token };
    let formdata = new FormData();
    formdata.append(`data[profile_attributes][cv]`, file?.target?.files[0]);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showJobCategories = (index: any, id: any) => {
    this.setState((state: any) => ({
      ...state,
      selectedCompanyName: index,
      selectedCategory: id
    }), () => { })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.showJobCategoriesApiCallId = requestMessage.messageId;
    const { selectedLocation, selectedJobExperience, selectedJobType, selectedDatePosted, searchJobs, selectedSalaries } = this.state;
    const location = selectedLocation.length > 0 ? selectedLocation.map(e => `&location[]=${e}`).join('') : '';
    const experience = selectedJobExperience.length > 0 ? selectedJobExperience.map(e => `&experience[]=${e}`).join('') : '';
    let datePosted = selectedDatePosted.length > 0 ? selectedDatePosted.map(e => `&date_posted[]=${e}`).join('') : '';
    const jobType = selectedJobType.length > 0 ? selectedJobType.map(e => `&job_type[]=${e}`).join('') : '';
    const salary = selectedSalaries.length > 0 ? selectedSalaries.map(e => `&salary_id[]=${e}`).join('') : '';
    const search = searchJobs ? `&tag=${searchJobs}` : '';
    if (datePosted === '&date_posted[]=any_time') {
      datePosted = ''
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobCategoriesApiEndPoint}/${id}?popular=true${location}${experience}${jobType}${datePosted}${salary}${search}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showTrendingJobCategories = (index: any, id: any) => {
    this.setState((state: any) => ({
      ...state,
      selectedCompanyName: index,
      selectedCategory: id
    }), () => { })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.showTrendingJobCategoriesApiCallId = requestMessage.messageId;
    const { selectedLocation, selectedJobExperience, selectedJobType, selectedDatePosted, searchJobs, selectedSalaries } = this.state;
    const location = selectedLocation.length > 0 ? selectedLocation.map(e => `&location[]=${e}`).join('') : '';
    const experience = selectedJobExperience.length > 0 ? selectedJobExperience.map(e => `&experience[]=${e}`).join('') : '';
    let datePosted = selectedDatePosted.length > 0 ? selectedDatePosted.map(e => `&date_posted[]=${e}`).join('') : '';
    const jobType = selectedJobType.length > 0 ? selectedJobType.map(e => `&job_type[]=${e}`).join('') : '';
    const salary = selectedSalaries.length > 0 ? selectedSalaries.map(e => `&salary_id[]=${e}`).join('') : '';
    const search = searchJobs ? `&tag=${searchJobs}` : '';
    if (datePosted === '&date_posted[]=any_time') {
      datePosted = ''
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobCategoriesApiEndPoint}/${id}?trending=true${location}${experience}${jobType}${datePosted}${salary}${search}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBannerImage = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getbannerImage}`
    );
    const header = {
      "token": localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
