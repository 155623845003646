//@ts-ignore
//@ts-nocheck

//import { StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { View, Text, ImageBackground, StyleSheet } from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { placeHolderImage, tag_yellow_color, semiBold } from '../../Utils';
import { translate } from "../../../../../components/src/i18n/translate";

interface Props {
    logo: any;
    types: any
    rating: any
}

export default class CardImage extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    image = this.props.logo;

    render() {
        return (
            <View style={{ justifyContent: 'center' }}>
                <ImageBackground
                    source={this.image ? { uri: this.image.url } : placeHolderImage}
                    resizeMode='cover'
                    borderRadius={4}
                    style={{
                        marginStart: 7, marginEnd: 7, marginTop: 7, borderRadius: 4,
                        marginBottom: 4, width: widthPercentageToDP(33), height: 64, alignSelf: 'center'
                    }}
                >
                    {this.props.types !== 'Features' ?
                        <View style={styles.tags}>
                            <Text style={styles.score}>{this.props.rating ? this.props.rating : 0}{translate("five")}</Text>
                        </View>
                        : null
                    }
                    <View style={{ backgroundColor: 'rgb(239, 239, 239,0.5)', width: '100%', height: 22, alignSelf: 'flex-end', marginTop: 42, borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
                        {/* {this.props.rating?
                  <View style={styles.tags}>
                       <Text style={styles.score}>{this.props.rank}/5</Text>
                   </View>
                   : null
                   } */}
                    </View>
                </ImageBackground>
            </View>
        );
    }
}


const styles = StyleSheet.create({
    tags: { backgroundColor: tag_yellow_color, width: 31, height: 13, borderRadius: 7, justifyContent: 'center', alignSelf: 'flex-end', marginTop: 5, marginRight: 5 },

    score: { fontSize: 8, fontFamily: semiBold, color: 'black', alignSelf: 'center' }
})
// 'rgba(255, 255, 255, 0.04)'