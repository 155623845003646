import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  searchJobs: any;
  companyDetails: any[];
  selectedJobType: any[];
  selectedJobExperience: any[];
  selectedLocation: any[];
  selectedDatePosted: any[];
  selectedCompanyName: any;
  selectedCategory: number;
  salaryRange: any[];
  cityState: string;
  sector: string;
  jobTitle: string;
  totalPages: number;
  selectedSalaries: any[];
  currentPage: number
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class JobsViewAllWebController extends BlockComponent<Props, S, SS> {
  showGetAllJobsApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },//@ts-ignore
      searchJobs: this.props.match?.params?.searchJob,
      companyDetails: [],
      selectedJobType: [],
      selectedJobExperience: [],
      selectedLocation: [],
      selectedDatePosted: [],
      selectedCompanyName: 0,
      selectedCategory: 0,
      salaryRange: [0, 0],
      cityState: "",
      sector: '',
      jobTitle: '',
      totalPages: 0,
      selectedSalaries: [],
      currentPage: 1
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.showGetAllJobsApiCallId) {
        if (responseJson && !responseJson.errors && responseJson?.data) {
          this.setState({ companyDetails: responseJson?.data, totalPages: responseJson.total_pages });
        } else if (responseJson && responseJson.errors) {
          if (responseJson.errors[0].token)
            this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getAllJobs = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.showGetAllJobsApiCallId = requestMessage.messageId;
    const { selectedLocation, selectedJobExperience, selectedJobType, selectedDatePosted, searchJobs, selectedSalaries, currentPage } = this.state;
    const location = selectedLocation.length > 0 ? selectedLocation?.map(e => `&location[]=${e}`).join('') : '';
    const experience = selectedJobExperience.length > 0 ? selectedJobExperience?.map(e => `&experience[]=${e}`).join('') : '';
    let datePosted = selectedDatePosted.length > 0 ? selectedDatePosted?.map(e => `&date_posted[]=${e}`).join('') : '';
    const jobType = selectedJobType.length > 0 ? selectedJobType?.map(e => `&job_type[]=${e}`).join('') : '';
    const salary = selectedSalaries.length > 0 ? selectedSalaries?.map(e => `&salary_id[]=${e}`).join('') : '';
    const search = searchJobs ? `&search=${searchJobs}` : '';
    if (datePosted === '&date_posted[]=any_time') {
      datePosted = ''
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllJobsApiEndPoint}?with_cta=true${location}${experience}${jobType}${datePosted}${salary}${search}&page=${currentPage}&per_page=10`
    );
    const header = {
      "token": localStorage.getItem("token") || ""
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
