import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";

export type Props = RouterProps &
  StyledProps & {
    // Customizable Area Start
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    ParentCallback: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  categories: any[];
  selectedChip: any;
  onGoingexamList: any[];
  categoryId: any;
  popularExamsList: any[];
  open: boolean;
  searchInfo: any;
}
interface SS {
  id: any;
}
export default class ExamlistWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: string = "";
  getOngoingExamsApiCallId: string = "";
  getPopularExamApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      categories: [],
      selectedChip: localStorage.getItem("selectedChip") !== null && localStorage.getItem("selectedChip") !== undefined ? localStorage.getItem("selectedChip") : 0,
      onGoingexamList: [],
      categoryId: localStorage.getItem("categoryId") !== null && localStorage.getItem("categoryId") !== undefined ? localStorage.getItem("categoryId") : 4,
      popularExamsList: [],
      open: false,
      searchInfo: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson?.data) {
        if (apiRequestCallId === this.getCategoriesApiCallId) {
          this.setState({ categories: responseJson.data });
        } else if (apiRequestCallId === this.getOngoingExamsApiCallId) {
          this.setState({ onGoingexamList: responseJson.data });
        } else if (apiRequestCallId === this.getPopularExamApiCallId) {
          this.setState({ popularExamsList: responseJson.data });
          this.props.hideLoader();
        }
      } else if (responseJson.errors) {
        if (responseJson.errors[0].token)
          this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      // Customizable Area End
    }
  }
  getCategories = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const apiEndPoint = `${configJSON.categoriesAPIEndPoint}?show_on_landing_page=true`;
    const methodType = configJSON.httpGetType;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleChipsClick = (index: any, catIndex: any) => {
    localStorage.setItem("categoryId", catIndex)
    localStorage.setItem("selectedChip", index)
    this.setState((state: any) => ({
      ...state,
      selectedChip: index,
      categoryId: catIndex
    }), () => {
      this.getOngoingExams();
      this.getPopularExams();
    });
  }

  getOngoingExams = () => {
    this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getOngoingExamsApiCallId = requestMessage.messageId;
    const search = this.state.searchInfo
      ? `&search=${this.state.searchInfo}`
      : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.examDetailsUrl + `?page=1&per_page=10&ongoing=true&category[]=${this.state.categoryId}${search}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPopularExams = () => {
    this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getPopularExamApiCallId = requestMessage.messageId;
    const search = this.state.searchInfo
      ? `&search=${this.state.searchInfo}`
      : "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.examDetailsUrl + `?page=1&per_page=10&is_popular=true&category[]=${this.state.categoryId}${search}`
    );
    const token = localStorage.getItem('token');
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
