import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
export const resuableFun = require("./resuableFunction")
import { sendAPIRequest } from "../../../components/src/Utility";

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    location: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  purchased: boolean;
  locationsList: any;
  educationlevesList: any;
  filterdata: any;
  totalAmount: any;
  collegesList: any;
  queryobj: any;
  queryFilterObj: any;
  searchText: any;
  collegesCtasList: any;
  selectedType: any;
  totalPages: any;
  currentPage: any


}
interface SS {
  id: any;
}

export default class CollegeSearchController extends BlockComponent<
  Props,
  S,
  SS
> {

  topLocationsApiCallId: string = "";
  schoolsBysearchInfoApiCallId: string = "";
  collegesCTAApiCallId: string = "";
  getFilterOptionsId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      purchased: false,
      locationsList: [],
      educationlevesList: [],
      collegesList: [],
      totalAmount: 0,
      filterdata: [
        {
          "type": "Location",
          "id": 0,
          "key": "location",
          "data": [],
          "value": [],
        },
        {
          "type": "Total Fees",
          "id": 1,
          "key": "total_fee",
          "data": [],
          "value": [],
        },
        {
          "type": "Courses",
          "id": 2,
          "key": "courses",
          "data": [],
          "value": [],
        },
        {
          "type": "Specialization",
          "id": 3,
          "key": "specialization",
          "data": [],
          "value": [],
        },

      ],
      queryobj: {
        '0': 'location[]',
        '1': 'total_fees',
        '2': 'courses[]',
        '3': 'specializations[]'
      },
      queryFilterObj: {
        '0': 'location[]',
        '1': 'total_fees',
        '2': 'course[]',
        '3': 'specialization[]'
      },
      searchText: '',
      collegesCtasList: [],
      selectedType: 0,
      totalPages: 0,
      currentPage: 1,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.topLocationsApiCallId:
            this.handleTopLocationsApiResponce(responseJson);
            break;
          case this.schoolsBysearchInfoApiCallId:
            this.handleAchoolsBySearchApiResponce(responseJson);
            break;
          case this.collegesCTAApiCallId:
            this.handleCollegeCTAApiResponce(responseJson);
            break;
          case this.getFilterOptionsId:
            this.handleFilterOptionsApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handleTopLocationsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      let filterInfo = this.state.filterdata
      filterInfo[0].data = responseJson.data.filter((ele: any) => ele.attributes.search_count > 0)
      this.setState({ locationsList: responseJson.data, filterdata: filterInfo });
    }
  };
  handleAchoolsBySearchApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({
        collegesList: responseJson.data, totalPages: responseJson?.meta?.pagination?.total_pages
      });
      let element: any = document.getElementById("box");
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }
  };
  handleCollegeCTAApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ collegesCtasList: responseJson.data });
      let element: any = document.getElementById("box");
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }
  };
  handleFilterOptionsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {

      let infoFilter = this.state.filterdata
      let filterKeys = Object.keys(responseJson.data).filter((e) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        infoFilter[this.state.selectedType].data = responseJson.data[filterKeys[0]].data
      }
      if (this.state.selectedType === 1) {
        infoFilter[this.state.selectedType].data = responseJson.data[filterKeys[0]]
      }
      this.setState({ filterdata: infoFilter }, () => {
      });

    }

  };
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };
  getsearchLocation = (e: any) => {
    this.getLocations(e.target.value)
  }
  getLocations = (name: any) => {
    let query = "?current_page=colleges"
    if (name) {
      query += '&name=' + name
    }
    const apiEndPoint = `${configJSON.getToplocations}${query}`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.topLocationsApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }

  getCollegesBySearchInfo = (quary: any) => {
    const apiEndPoint = quary ? `${configJSON.getPopularColleges}?page=${this.state.currentPage}&per_page=10&${quary}` : `${configJSON.getPopularColleges}?page=${this.state.currentPage}&per_page=10`;
    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType
    this.schoolsBysearchInfoApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }

  numFormatter = (num: any) => {
    return resuableFun.numFormatter(num)
  }
  gotoWebsite = (data: any) => {
    if (data.attributes.website_url) {
      window.open(
        data.attributes.website_url, "_blank");
    }
  }
  applyFilter = () => {
    this.setSearchQuaryparameters(this.state.filterdata)
  }
  selectSearchInfo = (info: any, index: any, subindex: any) => {
    let filterInfo = this.state.filterdata
    let id: any;
    if (index == 1) {
      id = subindex
    }
    else {
      id = info.id
    }
    if (filterInfo[index].value.indexOf(id) == -1) {
      filterInfo[index].value.push(id)
    }
    else {
      filterInfo[index].value = filterInfo[index].value.filter((item: any) => item !== id)
    }
    this.setState({ filterdata: filterInfo }, () => {
      // this.setSearchQuaryparameters(filterInfo)
    });
  }
  clearFilter = () => {
    let filterinfo = this.state.filterdata
    filterinfo = filterinfo.map((ele: any) => {
      if (ele.hasOwnProperty('value')) {
        ele.value = []
        ele.data = []
        return ele
      }
    })
    this.setState({ filterdata: filterinfo, searchText: '', selectedType: 0, currentPage: 1 }, () => {
      this.getFilterOptions('location', this.state.filterdata)
      this.setSearchQuaryparameters(this.state.filterdata)
    })
  }
  setSearchQuaryparameters = (filterdata: any) => {
    let query = ''
    filterdata.forEach((ele: any) => {
      if (ele.id != 1 && ele.value.length > 0) {
        ele.value.forEach((e: any, i: any) => {
          if (i == 0 && !query) {
            query += `${this.state.queryobj[ele.id]}=` + e
          }
          else {
            query += `&${this.state.queryobj[ele.id]}=` + e
          }
        })
      }
      if (ele.id == 1 && ele.value.length > 0) {
        query += this.feesFilter(ele, query)
      }
    })
    if (this.state.searchText) {
      query += 'search=' + this.state.searchText
    }
    if (this.props?.location?.state) {
      query += this.getLocationssearch();

    }
    this.getCollegesBySearchInfo(query)
  }
  feesFilter = (ele: any, query: any) => {
    let params = ''
    ele?.value?.forEach((e: any, i: any) => {
      if (i == 0 && !query && !params) {
        params += `${this.state.queryobj[ele.id]}[${i}][]=${ele.data[e].min}&${this.state.queryobj[ele.id]}[${i}][]=${ele.data[e].max}`
      }
      else {
        params += `&${this.state?.queryobj[ele?.id]}[${i}][]=${ele?.data[e]?.min}&${this.state?.queryobj[ele?.id]}[${i}][]=${ele?.data[e]?.max}`
      }
    })
    return params
  }
  getLocationssearch = () => {
    let query = ''
    query += query ? '&' : ''
    if (Object.keys(this.props?.location?.state)[0] == 'locations') {
      query += `${Object.keys(this.props?.location?.state)[0]}[]=` + this.props?.location?.state[Object.keys(this.props?.location?.state)[0]]
    }
    else {
      query += `${Object.keys(this.props?.location?.state)[0]}=` + this.props?.location?.state[Object.keys(this.props?.location?.state)[0]]
    }
    return query
  }
  searchData = (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      this.setSearchQuaryparameters(this.state.filterdata)
    })
  }
  getCollegeCtas = () => {

    const apiEndPoint = `${configJSON.getSchoolsCollegeCtas}?module[]=college`;

    const header = {
      "Content-Type": configJSON.validationApiContentType
    }
    const method = configJSON.validationApiMethodType;
    this.collegesCTAApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  navigateCta = (url: any) => {
    if (url) {
      window.open(
        url, "_blank");
    }
    else {
      this.props.openToastHandler("error", "Redirect URL is not available");
    }

  }
  getApiCallFilter = (ele: any) => {
    let query = ''
    if (ele.value.length > 0) {
      ele.value.forEach((e: any, i: any) => {
        if (ele.id == 1) {
          query += `&min[]=${ele?.data[e]?.min}&max[]=${ele?.data[e]?.max}`
        } else {
          query += `&${this.state.queryFilterObj[ele.id]}=` + e
        }
      })
    }
    return query
  }
  getFilterOptions = (filterby: any, filterinfo: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    let query = ''
    filterinfo.forEach((ele: any) => {
      query += this.getApiCallFilter(ele)
    })
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=college${query}`;
    const method = configJSON.validationApiMethodType;
    this.getFilterOptionsId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  showOption = (item: any, e: any) => {
    if (item?.id == 2) {
      return `${e?.attributes?.heading} (${e?.attributes?.count})`
    }
    else {
      return `${e?.attributes?.name} (${e?.attributes?.count == null ? e?.attributes?.search_count : e?.attributes?.count})`
    }
  }
}
// Customizable Area End 