import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import {daysObject,queryobj,filterdata,setDate,getTypeOfListQparam,setOptions,getTypeOfFilter} from "./QuizAssessmentCommonutility"

export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  quizList: [];
  selectedType: any;
  searchValue: any;
  currentPage: any
  totalPage: any;
  filterdata: any;
  queryobj: any;
  ratingValue: any;
  days: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class QuizCategoryViewAllWebController extends BlockComponent<
  Props,
  S,
  SS
> {


  getQuizApiCallId: string = "";
  getFilterOptionsId: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      quizList: [],
      selectedType: 0,
      searchValue: '',
      currentPage: 1,
      totalPage: 0,
      filterdata: filterdata,
      queryobj: queryobj,
      days: daysObject,
      ratingValue: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getFilterOptions('content_type', this.state.filterdata)
    this.loadData();
  }
  loadData = () => {
    this.getContentsBysearchCriteria(this.state.filterdata)
  }
  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getQuizApiCallId:
            this.handlegetQuizApiCallIdApiResponce(responseJson);
            break;
          case this.getFilterOptionsId:
            this.handlegetFilterOptionsIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handlegetQuizApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ quizList: responseJson.data, totalPage: responseJson?.total_pages || 1 });
    }
  }
  handlegetFilterOptionsIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        info[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: info });
    }
  }
  clearLocalstorage = (responseJson: any) => {
    if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
    }
  }

  getContentsBysearchCriteria = (filterdata: any) => {
    let query = ''
    filterdata.forEach((ele: any) => {
      if (ele.id != 3 && ele.value.length > 0) {
        query+=setOptions(ele,query,this.state.queryobj);
      }
      else if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.type == 'Date') {
        query += '&' + setDate(ele,this.state.days)
      }
    })
    if (this.state.ratingValue) {
      query += '&rating=' + this.state.ratingValue
    }
    this.getQuiz(query)
  }
  getQuiz = (query: any) => {
    const { searchValue,currentPage } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const heading = getTypeOfListQparam(this.props?.match?.params?.heading)
    const header: any = {
      "Content-Type":configJSON.validationApiContentType   
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
     }
    this.getQuizApiCallId = sendAPIRequest(`${configJSON.getQuizUrl}?page=${currentPage}&per_page=10${heading}${search}${query}`,
    { headers: header })
  }
  getFilterOptions = (filterby: any, filterinfo: any) => {
    const token = localStorage.getItem('token');

    let header:any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) {
      header.token = token;
     }
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.id != 3 && ele.value.length > 0) {
        query+=setOptions(ele,query,this.state.queryobj);
      }
      else if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.type == 'Date') {
        let param = '';
        param=setDate(ele,this.state.days)
        if (param) {
          query += '&' + param
        }
      }
    })
    const heading = getTypeOfFilter(this.props?.match?.params?.heading,true)
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=${heading}${query}`;
    this.getFilterOptionsId = sendAPIRequest(apiEndPoint,
    { headers: header })
  }
}
