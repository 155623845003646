import React from "react";
// Customizable Area Start
// Customizable Area End

import PushNotificationsWebController, {
  Props,
} from "./PushNotificationsController.web";

export default class PushNotifications extends PushNotificationsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <div>
        <button onClick={this.pushNotification} className="button">
          Send Notification
        </button>
      </div>
    );
  }
}
