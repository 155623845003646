import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  TextField,
  InputAdornment,
  Popper,
  Paper,
  IconButton,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//@ts-ignore
import { withRouter } from "react-router";
import SearchController, { Props } from "./SearchController.web";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

const isEmpty = (x: any) => {
  if (x === undefined) return true;
  if (typeof x === "object") return Object.keys(x).length === 0;
  if (typeof x === "string" || Array.isArray(x)) return x.length === 0;
  return !x;
};

const popperStyle = (theme: any) =>
  createStyles({
    popperNoBorder: {
      display: "none",
    },
    popper: {
      width: "44rem !important",
      margin: "-0.05rem 0 0 -1.96rem",
      webkitBackdropFilter: "blur(50px)",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      top: "0.87rem !important",
      border: "solid 1px #eddede",
      borderTop: "none",
      borderRadius: "0px 0px 20px 20px",
      [theme.breakpoints.down("xs")]: {
        width: "22rem !important",
        margin: "-0.1rem 0 0 -1.95rem",
      },
    },
    paper: {
      backgroundColor: "transparent",
      border: "none",
      margin: 0,
      borderRadius: 20,
      "& ul": {
        scrollbarWidth: "none",
        borderRadius: "0px 0px 20px 20px",
        "&::-webkit-scrollbar": {
          width: 10,
          webkitBackdropFilter: "blur(50px)",
          backdropFilter: "blur(50px)",
          backgroundColor: "rgba(207, 207, 207, 0.13)",
        },
      },
    },
  });

const MuiPopperComponent = (props: any) => {
  const isEmptyList = isEmpty(
    props.children.props.children.filter((x: any) => x)
  );
  return (
    <div>
      <Popper
        {...props}
        transition
        keepMounted
        className={
          isEmptyList ? props.classes.popperNoBorder : props.classes.popper
        }
        placement="bottom-start"
      />
    </div>
  );
};
const MuiPaperComponent = (props: any) => {
  return <Paper {...props} className={props.classes.paper} disablePortal />;
};

const MuiPopper = withStyles(popperStyle)(MuiPopperComponent);
const MuiPaper = withStyles(popperStyle)(MuiPaperComponent);
export class SearchWeb extends SearchController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { apiData } = this.state;
    const {
      showRecentiyData = [],
      open = false,
      searchValue = "",
    } = this.state;
    const getoptions = () => {
      const optionlist: any = [];
      if (isEmpty(apiData)) {
        optionlist.push({
          title: "Recent search",
          value: "recents",
          type: "recent",
        });
        showRecentiyData
          .map((x: any) => ({ title: x, value: x }))
          .forEach((x: any) => {
            optionlist.push({ ...x, type: "recent" });
          });
      } else {
        apiData
          .map((x: any) => ({ title: x, value: x }))
          .forEach((x: any) => {
            optionlist.push({ ...x, type: "api" });
          });
      }
      return optionlist;
    };
    const renderOption = (params: any) => {
      if (params.type === "recent" && params.value === "recents") {
        return (
          <div
            data-testId="renderOption2"
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className={classes.flexRow}
          >
            <div className={classes.flexRow2}>
              <div className={classes.hSpace} />
              <Typography className={classes.typo}>{params.title}</Typography>
            </div>
            <IconButton
              data-testId="clearRecentAll"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                this.clearRecentAll();
              }}
              style={{ padding: 0, fontSize: "small" }}
            >
              <DeleteOutlineIcon color="secondary" />
            </IconButton>
          </div>
        );
      } else if (params.type === "recent") {
        return (
          <div className={classes.flexRow}>
            <div className={classes.flexRow2}>
              <IconButton
                style={{ padding: 0, fontSize: "small" }}
                className={classes.hSpace}
                data-testId="renderOption1"
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <QueryBuilderIcon color="secondary" />
              </IconButton>
              <Typography className={classes.typo}>{params.title}</Typography>
            </div>
            <IconButton
              data-testId="clearRecent"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                this.clearRecent(params);
              }}
              style={{ padding: 0, fontSize: "small" }}
            >
              <HighlightOffIcon color="secondary" />
            </IconButton>
          </div>
        );
      }
      return (
        <div className={classes.flexRow}>
          <div className={classes.flexRow2}>
            <IconButton
              style={{ padding: 0, fontSize: "small" }}
              className={classes.hSpace}
              data-testId="renderOption3"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <SearchIcon color="secondary" />
            </IconButton>
            <Typography className={classes.typo}>{params.title}</Typography>
          </div>
          <div />
        </div>
      );
    };
    return (
      <>
        <Grid
          className={
            !isEmpty(getoptions()) && open ? classes.search1 : classes.search2
          }
          container
          direction="row"
          alignItems="center"
        >
          <div className="flex-grow">
            <Autocomplete
              //@ts-ignore
              PopperComponent={MuiPopper}
              //@ts-ignore
              PaperComponent={MuiPaper}
              openOnFocus
              open={open}
              onClose={() => this.setState({ open: false })}
              onOpen={() => {
                this.setState({ open: true });
              }}
              // freeSolo
              options={getoptions() || []}
              autoHighlight
              //@ts-ignore
              getOptionLabel={(option) => option.title}
              data-testId="onChange"
              onChange={this.onChange}
              inputValue={searchValue}
              noOptionsText={
                <div
                  style={{ justifyContent: "center" }}
                  className={classes.flexRow}
                >
                  <Typography className={classes.typo}>
                    {`No results for "${searchValue}"`}
                  </Typography>
                </div>
              }
              renderInput={(params) => (
                <TextField
                  className={classes.searchInput}
                  {...params}
                  onChange={this.onChangeText}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ marginLeft: "-15px" }}
                          color="secondary"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: isEmpty(searchValue) ? <div /> : (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={isEmpty(searchValue)}
                          className={classes.clearIcon}
                          onClick={() =>
                            this.onChangeText({ target: { value: "" } })
                          }
                        >
                          <HighlightOffIcon color="secondary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    placeholder: "Search Articles, Exam, Courses here",
                  }}
                />
              )}
              renderOption={renderOption}
            />
          </div>
        </Grid>
      </>
    );
  }
}
//@ts-ignore
const SearchWebWebWithRouter = withRouter(SearchWeb);
export default withStyles((theme) =>
  createStyles({
    search1: {
      width: "44rem",
      height: "3.5rem",
      webkitBackdropFilter: "blur(50px)",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      marginTop: "0rem",
      paddingLeft: "30px",
      border: "solid 1px #eddede",
      borderRadius: "20px 20px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        width: "22rem",
        height: "3rem",
      },
    },
    search2: {
      width: "44rem",
      height: "3.5rem",
      webkitBackdropFilter: "blur(50px)",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      marginTop: "0rem",
      paddingLeft: "30px",
      borderRadius: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "22rem",
        height: "3rem",
      },
    },
    searchInput: {
      "& input": {
        width: "22rem",
        fontSize: "18px",
        fontWeight: 700,
        color: "white",
        paddingLeft: "2rem !important",
        [theme.breakpoints.down("xs")]: {
          width: "10rem",
          fontSize: "13px",
          paddingLeft: "0.5rem !important",
        },
      },
    },
    flexRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flexRow2: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typo: {
      color: "white",
    },
    hSpace: {
      width: "5%",
      [theme.breakpoints.down("xs")]: {
        width: "10%",
      },
    },
    clearIcon: {
      marginRight: "-3.4rem",
    },
  })
)(SearchWebWebWithRouter);
