//@ts-nocheck
import React from "react";
import {
  withStyles,
  Container,
  Grid,
  createStyles,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import AssessmentWebController, {
  Props,
} from "./AssessmentController.web";
import withLoader from "../../../components/src/withLoader.web";
import { withTranslation } from 'react-i18next';
import { bannerInImg, groupImg } from "./assets";
import { withSnackbar } from "../../../components/src/toast.web";
//@ts-ignore
import { Title, CategorysBlock, QuizAssessmentSearchBlock, ScrollableQuiz } from './QuizAssesmentsUtility.web'
import { quizAssessmentcss } from './QuizAssessmentCommonutility'


export class AssessmentWeb extends AssessmentWebController {
  constructor(props: Props) {
    super(props);

  }

  handleChipsClick = (item: any) => {
    this.setState({ selectedChip: item.id }, () => {
      this.loadData();
    })
  }
  setSearcdata = (e: any) => {
    this.setState({ searchValue: e.target.value }, () => {
      this.loadData();
    })
  }

  handleViewAll = (type: any) => {
    this.props.history.push(`/home/assessmentsviewall/${type}/${this.state.selectedChip}`);
  }

  gotoAssessment = (sId: any) => {
    this.props.history.push(`/home/assessment/${sId}`);
  }

  render() {
    const { classes } = this.props;
    const { selectedChip, categoriesData, popularQuizList, trendingQuizList, recommendQuizList } = this.state;

    const { handleViewAll, gotoAssessment } = this;
    const isLogin = () => localStorage.getItem('token');
    return (
      <React.Fragment>
        <Container maxWidth="xl" disableGutters={true}>
          <Grid item xs={12} className={classes.root}>
            <img alt="" className="h-full" src={bannerInImg} />
          </Grid>
          <Container className={classes.videoCont}>
            <Grid container direction="row" alignItems="center" justify="center" className={classes.rootsearch} >
              <QuizAssessmentSearchBlock {...{
                classes,
                setSearcdata: this.setSearcdata,
                placeholderetext: "Search Assessment here"
              }}
              />
              <CategorysBlock {...{ classes, categoriesData, selectedChip, handleChipsClick: this.handleChipsClick }} />
            </Grid>
            <br />
            <br />
            {/* Popular quiz */}
            <Title {...{ type: 'popular', caption: 'Popular Assessments', classes, handleViewAll, quizList: popularQuizList }} />
            <br />
            <br />
            <br />
            <br />
            <ScrollableQuiz {...{ classes, gotoPage: gotoAssessment, quizList: popularQuizList, isLogin, isQuiz: false, textdata: "Assessment" }} />
            <br />
            <br />
            <br />
            <br />
            {/* Trending Quiz */}
            <Title {...{ type: 'trending', classes, caption: 'Trending Assessments', handleViewAll, quizList: trendingQuizList }} />
            <br />
            <br />
            <br />
            <br />
            <ScrollableQuiz {...{ classes, gotoPage: gotoAssessment, quizList: trendingQuizList, isLogin, isQuiz: false, textdata: "Assessment" }} />
            <br />
            <br />
            <br />
            <br />
            {/* Recommended for you */}
            {isLogin() &&
              <>
                <Title {...{ type: 'recommend', caption: 'Recommended For You', classes, handleViewAll, quizList: recommendQuizList }} />
                <br />
                <br />
                <br />
                <br />
                <ScrollableQuiz {...{ classes, gotoPage: gotoAssessment, quizList: recommendQuizList, isLogin, isQuiz: false, textdata: "Assessment" }} />
                <br />
                <br />
              </>}
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

// @ts-ignore
const AssessmentWebWithRouter = withRouter(AssessmentWeb);
const AssessmentWebWithLoader = withLoader(AssessmentWebWithRouter);
const AssessmentWebToast = withSnackbar(AssessmentWebWithLoader);
const AssessmentWebWithStyle = withStyles((theme) =>
  createStyles({
    ...quizAssessmentcss(theme, groupImg),
    startassessment: {
      height: '25%',
      margin: 'auto'
    },
    root: {
      marginTop: '-0.25rem'
    },
    rootsearch: {
      height: '100%',
      width: '100%'
    },
    cardItem: {
      marginTop: '0.5rem'
    },
  })
)((AssessmentWebToast))
export default withTranslation()(AssessmentWebWithStyle);