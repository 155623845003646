Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";
exports.formDataApiContentType = "multipart/form-data";
exports.endPointApiGetUserProfile = "profile/profile";
exports.endPointApiGetUserProfiledetails = "/bx_block_profile/profiles/id";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";
exports.methodTypeApiUpdateUserProfile="PUT";
exports.methodTypeDelete="DELETE"
exports.endPointofUpdateselectedSubCat="/bx_block_categories/sub_categories/id?language=en";
exports.endPointofUpdateselectedCat="/bx_block_categories/categories/id?language=en?show_on_landing_page=true"; 
exports.endPointboard="/bx_block_profile/boards";
exports.endPointcolleges="/bx_block_profile/colleges";
exports.endPointdegrees="/bx_block_profile/degrees";
exports.endPointeducation_levels="/bx_block_profile/education_levels";
exports.endPointspecializations="/bx_block_profile/specializations";
exports.endPointstandards="/bx_block_profile/standards";
exports.endPointcaste_category="/bx_block_profile/caste_category";
exports.endPointClass="/bx_block_profile/standards";
exports.endPointDomanWorkFunction="/bx_block_profile/domain_work_functions";
exports.endPointSubject="/bx_block_profile/subjects";
exports.endPointDegree="/bx_block_profile/degrees";
exports.endPointSpecializ="/bx_block_profile/specializations";
exports.endPointCourse="/bx_block_profile/educational_courses";
exports.endPointCollage="/bx_block_profile/colleges";
exports.endPointPassing="/bx_block_profile/subjects";
exports.deselectCategoriesApiEndPoint="/bx_block_profile/profiles/delete_profile_categories_data"
exports.endPointupdatek12="/bx_block_profile/profiles/id";
exports.endPointupdateHigherEdu="/bx_block_profile/education_levels";
exports.faqEndPoint="bx_block_dashboard/faqs";
exports.quizEndPoint="bx_block_contentmanagement/user_quizzes";
exports.assessmentEndPoint="bx_block_contentmanagement/user_assessments";
exports.contactUsEndPoint="bx_block_dashboard/contact_us";
exports.transactionEndPoint="bx_block_contentmanagement/course_orders";
exports.aboutEndPoint="/bx_block_profile/abouts";
exports.leaderEndPoint="/bx_block_profile/leaders";
exports.createOrderAPIEndPoint="bx_block_payments/razorpays";
exports.verifyPaymentAPIEndPoint="bx_block_payments/razorpays/varify_signature";
exports.achievementEndPoint="bx_block_contentmanagement/my_achievements";
exports.help_support = "bx_block_dashboard/help_and_supports";
exports.readLessonContents = "bx_block_contentmanagement/lession_contents/read_lession_contents"





// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "Profile";
exports.labelHeader2 =
  "Edit Profile";
exports.labelFirstName = "Name*";
exports.lastName = "Date of Birth (Optional)";
exports.labelArea = "Area";
exports.labelMobile = "Mobile Number*";
exports.labelCountry = "City (Optional)"
exports.labelEmail = "Email Address (Optional)";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";

exports.getUserDetailApiEndPoint = "bx_block_profile/profiles/";

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.apiContentType = "application/json";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_categories/categories?show_on_landing_page=true";
exports.subCategoryAPIEndPointMobile = "bx_block_categories/sub_categories?language=en";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.categoriesAPIEndPoint = "bx_block_categories/categories?show_on_landing_page=true";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";

exports.addToCart = "bx_block_contentmanagement/course_carts";
exports.removeCart = "bx_block_contentmanagement/course_carts";
exports.emptyCart = "bx_block_contentmanagement/course_carts/delete_cart";


exports.methodTypeApiDeleteUserDetail = "DELETE";

exports.putCategoriesApiEndPoint = "bx_block_categories/categories";
exports.putSubCategoriesApiEndPoint = "bx_block_categories/sub_categories";
exports.getBoardsApiEndPoint = "bx_block_profile/boards";
exports.getSubjectsApiEndPoint = "bx_block_profile/subjects";
exports.getStandardsApiEndPoint = "bx_block_profile/standards";
exports.getDegreesApiEndPoint = "bx_block_profile/degrees";
exports.getCollegesApiEndPoint = "bx_block_profile/colleges";
exports.getSpecializationApiEndPoint = "bx_block_profile/specializations";
exports.getCoursesApiEndPoint = "bx_block_profile/educational_courses";
exports.getEducationLevelApiEndPoint = "bx_block_profile/education_levels";
exports.getCasteCategoryApiEndPoint = "bx_block_profile/profiles/caste_category";
exports.getDomainApiEndPoint = "bx_block_profile/domain_work_functions";
exports.deleteCategoriesApiEndPoint = "bx_block_profile/profiles/delete_profile_categories_data";

exports.getRunningCourseApiEndPoint = "bx_block_contentmanagement/course_orders/my_courses";
exports.getShortlistedCoursesEndPoint = "bx_block_contentmanagement/courses/shotlist_courses";
exports.getRecommendedCoursesEndPoint = "bx_block_contentmanagement/courses/recommended_courses";

exports.getRunningTrialsEndPoint = "bx_block_contentmanagement/courses/free_trails";
exports.getRecommendedTrialsEndPoint = "bx_block_contentmanagement/courses/recommended_trails";
exports.privacyPolicyEndPoint = "bx_block_profile/term_policies";
exports.termsServicesEndPoint = "bx_block_profile/term_services";
// Customizable Area End

