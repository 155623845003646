import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export type Props = {
  id: string;
  navigation: any;
  openToastHandler: any;
  // Customizable Area Start
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  pin: string;
  otpAuthToken?: string;
  userDetails: any;
  showToast: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class OTPInputAuthControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  verifyOtpApiCallId: string = "";
  createAccountApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      pin: "",
      showToast: {
        show: false,
      },
      userDetails: {},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() { }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        // Verify OTP Response
        switch (apiRequestCallId) {
          case this.verifyOtpApiCallId:
            this.verifyOtpApi(responseJson);
            break;
          case this.createAccountApiCallId:
            this.createAccountApi(responseJson);
            break;
          default: if (!responseJson.errors) {
            console.log(responseJson.errors);
          } else {
            //Check Error Response
            console.log(errorReponse)
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    }
    // Customizable Area End
  }
  createAccountApi = (responseJson: any) => {
    this.props.openToastHandler("success", configJSON.successCreateAccount);
    setTimeout(() => {
      localStorage.removeItem("otp_auth_token");
      localStorage.removeItem("verify_otp_auth_token");
      localStorage.removeItem("user_details");
      // @ts-ignore
      this.props.history.push("/");
    }, 1000);
  }
  verifyOtpApi = (responseJson: any) => {
    localStorage.setItem(
      "verify_otp_auth_token",
      responseJson.meta.token
    );
    this.createAccount(localStorage.getItem("user_details"));
  }
  // Send OTP Handler
  verifyOtp = (values: any) => {
    const headers = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const data = {
      pin: values.pin,
      token: localStorage.getItem("otp_auth_token"),
    };
    const apiEndPoint = configJSON.apiVerifyOtpEndPoint;
    const methodType = configJSON.apiVerifyOtpMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Create Account Handler
  createAccount = (values: any): boolean => {
    const userValues = JSON.parse(values);
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };
    const attrs = {
      first_name: userValues.first_name,
      last_name: userValues.last_name,
      email: userValues.email,
      password: userValues.password,
      full_phone_number: userValues.full_phone_number,
    };
    const data = {
      type: "sms_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
      token: localStorage.getItem("verify_otp_auth_token"),
    };
    const apiEndPoint = configJSON.accountsAPiEndPoint;
    const methodType = configJSON.httpPostType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
