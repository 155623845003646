import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export type Props = {
  id: string;
  navigation: any;
  match: any;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  showContentData:any;
  showAllContentsData:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  showContentApiCallId: string = "";

  showContentsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      showContentData:[],
      showAllContentsData:[]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getAllContents();
  }
  //this.getContent(conId:any);

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    let responseJson = message.getData((MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson && !responseJson.errors) {
      switch (apiRequestCallId) {
        case this.showContentApiCallId:
          this.handleShowContentApiResponce(responseJson);
          break;
          case this.showContentsApiCallId:
          this.handleShowContentsApiResponce(responseJson);
          break;
        default:
          break;
      }
    } else if (responseJson && responseJson.errors) {
      this.clearLocalstorage(responseJson)
      // we use parseApiErrorResponse method to handle errors by default provide by builder
    }
    }
    // Customizable Area End
  }
  // Get Sub Categories of Category

  handleShowContentApiResponce = (responseJson: any) => {
    this.setState({
      showAllContentsData: responseJson.data
    });
  };
  handleShowContentsApiResponce = (responseJson: any) => {
    this.setState({
      showContentData: responseJson.data,
    });
  };
	    clearLocalstorage = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    }
  };

  getContent = (conId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const appLang = localStorage.getItem("lang")
    const apiEndPoint = `${configJSON.getContent}/${conId}/language${appLang}`;
    const methodType = configJSON.validationApiMethodType;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showContentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
   // Show Contents
   getAllContents = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const apiEndPoint = `${configJSON.getContents}`;
    const methodType = configJSON.validationApiMethodType;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showContentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Customizable Area End
}
