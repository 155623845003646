import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import moment from "moment";
import { sendAPIRequest } from "../../../components/src/Utility";

export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    openToastHandler: any;
    openDialog: any;
    showLoader: any;
    hideLoader: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface Categories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}
interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
}
interface Category {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategories[];
  };
}
interface SubCategory {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
  };
}
interface Contenttype {
  id: number,
  type: string;
  created_at: string;
  updated_at: string;
  rank: string
}
interface Article {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    description: string;
    image: string;
    video: string;
    audio: string;
    study_material: string;
    content_type: Contenttype;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
    tag_list: string[];
  };
}
interface S {
  // Customizable Area Start
  categoriesData: Category[];
  filterdata: any[];
  selectedType: any;
  selectedSubType: any;
  selectedChip: any;
  examsList: any[];
  sliderValue: any[];
  ratingValue: any;
  searchValue: any;
  totalPages: any;
  getAllBookmarked: any;
  pageNo: any;
  tagList: any;
  queryobj: any;
  days: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ExamViewallWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContentTypelistId: string = "";
  getProviderslistId: string = "";
  getCategorylistId: string = "";
  getExamsBySearch: string = "";
  getCourseBySearchId: string = "";
  getTagslistId: string = "";
  getSubCategorylistId: string = "";
  getFilterOptionsId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      categoriesData: [],
      filterdata: [
        {
          "type": "Category",
          "key": "category",
          "id": 0,
          "data": [],
          "value": []
        },
        {
          "type": "Sub Categories",
          "key": "sub_category",
          "id": 1,
          "data": [],
          "value": [],
        },
        {
          "type": "Content Provider",
          "key": "content_provider",
          "id": 2,
          "data": [],
          "value": [],
        },
        {
          "type": "Date",
          "key": "date",
          "id": 3,
          'fromDate': '',
          'toDate': '',
          "data": [{ "name": "Today", "id": 1 },
          { "name": "Yesterday", "id": 2 },
          { "name": "Last 7 days", "id": 3 },
          { "name": "Last 15 days", "id": 4 },
          { "name": "Last month", "id": 5 },
          { "name": "Custom", "id": 6 }],
          "value": [],
        },
        {
          "type": "Tags",
          "key": "tag",
          "id": 4,
          "data": [],
          "value": [],
          "names": [],
        },
      ],
      selectedType: 0,
      selectedSubType: 1,
      selectedChip: 0,
      examsList: [],
      sliderValue: [0, 0],
      ratingValue: null,
      searchValue: '',
      totalPages: 0,
      getAllBookmarked: [],
      pageNo: 0,
      tagList: [],
      queryobj: configJSON.queryobj,
      days: configJSON.daysinfo,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getFilterOptions('category', this.state.filterdata)
    this.iniateMethods()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors && responseJson !== undefined) {
        switch (apiRequestCallId) {
          case this.getFilterOptionsId:
            this.handlegetFilterOptionsIdApiResponce(responseJson);
            break;
          case this.getExamsBySearch:
            this.getExamsBySearchApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else {
        if (responseJson && !responseJson.errors) {
          console.log(responseJson.errors);
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }
  // set default chip
  iniateMethods = () => {
    if (this.props?.match?.params?.id) {
      let data = this.state.filterdata
      data[0].value.push(this.props?.match?.params?.id)
      this.setState({ selectedChip: this.props?.match?.params?.id, filterdata: data }, () => {
        this.getExamsBysearchCriteria(this.state.filterdata)
      })
    }
  }

  getExamsBySearchApiResponce = (responseJson: any) => {
    this.setState({ examsList: responseJson.data, totalPages: responseJson.total_pages });
    window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
    this.props.hideLoader();
  }

  getExamsBysearchCriteria = (data: any) => {
    this.props.showLoader();
    const header: any = {
      "Content-Type": configJSON.apiContentType,
    };
    const token = localStorage.getItem('token');
    if (token)
      header['token'] = token;
    let params = ''
    if (data && data.length > 0) {
      data.map((ele: any) => {
        if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.id != 3) {
          params += this.setOptions(ele, params, this.state.queryobj);
        }
        else if (ele.hasOwnProperty('value') && ele?.value?.length > 0 && ele?.type == 'Date') {
          let query = ''
          query = this.setDate(ele, this.state.days, 'start_date', 'end_date', "YYYY-MM-DD")
          if (query) {
            params += '&' + query
          }
        }
      })
    }
    if (this.state.searchValue) {
      params += `&search=${this.state.searchValue}`;
    }
    let apiEndPoint = '';
    apiEndPoint = this.apiEndPoint(params);
    this.getExamsBySearch = sendAPIRequest(apiEndPoint,
      { headers: header })
  }
  apiEndPoint = (params: any) => {
    let key = Object.keys(this.props.location.state)[0]
    let apiEndPoint = '';
    if (params) {
      apiEndPoint = `${configJSON.examDetailsUrl}?page=${this.state.pageNo}&per_page=10&${key}=${this.props.location.state[key]}${params}`;
    }
    else {
      apiEndPoint = configJSON.examDetailsUrl + `?page=${this.state.pageNo}&per_page=10&${key}=${this.props.location.state[key]}`;
    }
    return apiEndPoint;
  }
  getFilterOptions = (filterby: any, filterinfo: any) => {
    let header: any = {
      "Content-Type": configJSON.apiContentType,
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    let query = ''
    filterinfo.forEach((ele: any) => {
      if (ele.id != 3 && ele.value.length > 0) {
        query += this.setOptions(ele, query, this.state.queryobj);
      }
      else if (ele.hasOwnProperty('value') && ele.value.length > 0 && ele.type == 'Date') {
        let param = '';
        param = this.setDate(ele, this.state.days, 'from', 'to', "DD/MM/YYYY")
        if (param) {
          query += '&' + param
        }
      }
    })
    const { filterType } = this.props.location.state
    const apiEndPoint = `${configJSON.getFilterOptions}?current_page=${filterby}&filter_type=${filterType}${query}`;
    this.getFilterOptionsId = sendAPIRequest(apiEndPoint, { headers: header })
  }

  setOptions = (item: any, params: any, queryobj: any) => {
    let query = ""
    item.value.forEach((e: any, i: any) => {
      if (item.id == 4) {
        let data = item.data.filter((el: any) => el.id == e)
        e = data[0].attributes.name
      }
      query += `&${queryobj[item.id]}=` + e
    })
    return query;
  }

  setDate = (ele: any, days: any, fromDate: any, todate: any, formatype: any) => {
    let param = ''
    if (ele.value.indexOf(6) != -1) {
      param = `date[${fromDate}]=${moment(ele.fromDate).format(formatype)}&date[${todate}]=${moment(ele.toDate).format(formatype)}`
    }
    else if (ele.value.indexOf(1) != -1) {
      param = `date[${todate}]=${moment().format(formatype)}&date[${fromDate}]=${moment().format(formatype)}`
    }
    else if (ele.value.indexOf(5) != -1) {
      const lastmonthlastdate = moment().subtract(1, 'months').startOf('month').format(formatype)
      const lastmonthfirstdate = moment().subtract(1, 'months').endOf('month').format(formatype)
      param = `date[${todate}]=${lastmonthfirstdate}&date[${fromDate}]=${lastmonthlastdate}`
    }
    else {
      param = `date[${fromDate}]=${moment().subtract(days[ele.value[0]], 'days').format(formatype)}&date[${todate}]=${moment().format(formatype)}`
    }
    return param;
  }
  handlegetFilterOptionsIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      let info: any = this.state.filterdata
      let keys: any = Object.keys(responseJson.data).filter((e: any) => e != 'datefilters')
      if (this.state.selectedType != 'none') {
        info[this.state.selectedType].data = responseJson.data[keys[0]].data
      }
      this.setState({ filterdata: info });
    }
  }

}
