Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";

exports.successCreateAccount = "Account created successfully.";
exports.successOtpSent = "OTP Sent to your mobile no.";

exports.contentTypeApiAddDetail = "application/json";
exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.apiMethodTypeAddDetail = "POST";
exports.httpPostType = "POST";
exports.validationApiMethodType = "GET";
exports.sendOtpAPiEndPoint = "account/accounts/send_otp";
exports.urlGetValidations = "profile/validations";