const colors = {
  primary: {
    base: '#f55426'
  },
  transparent: {
    base: 'transparent'
  },
  light: {
    100: '#ececf2'
  },
  error: '#ff0000'
};

module.exports = colors;
