//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
//  import i18n from "../../../web/src/i18n";
//@ts-ignore
export const configJSON = require("./config");
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    openToastHandler: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  open: boolean;
  showTagSearch: boolean;
  openDiscussionDialog: boolean;
  tagVal: any[],
  question: any,
  dislikeActive: boolean,
  dislike: any,
  like: any,
  likeActive: boolean,
  getQuestions: any[],
  likeList: any[],
  count: any,
  start: any,
  heading: string,
  postDetails: {},
  commentSection: boolean,
  comment: string,
  openReply: boolean,
  reply: string,
  commentableIdForAns: number,
  currentReplyIndex: number,
  questionDetail: any[],
  replyList: any[],
  openSubReply: boolean,
  detailId: number,
  currentHideShowIndex: any,
  currentHideShowId: number,
  likeType: string,
  openSubComment: boolean,
  currentCommentIndex: number,
  anchorEl: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DFDetailViewController extends BlockComponent<Props, S, SS> {
  getTagListApiCallId: string = "";
  getLikeListApiCallId: string = "";
  getCategoriesApiCallId: string = "";
  postQuestionApiCallId: string = "";
  getQuestionsApiCallId: string = "";
  getUpdatedQuestionApiCallId: string = "";
  postLikeApiCallId: string = "";
  postCommentApiCallId: string = "";
  getReplyListApiCallId: string = "";
  postReplyApiCallId: string = "";
  getQuestionDetailApiCallId: string = "";
  getQuestionApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      open: false,
      showTagSearch: false,
      openDiscussionDialog: false,
      tagVal: [],
      question: {},
      dislikeActive: false,
      dislike: 0,
      like: 0,
      likeActive: false,
      getQuestions: [],
      likeList: [],
      count: 20,
      start: 1,
      heading: "",
      postDetails: {},
      commentSection: false,
      comment: "",
      openReply: false,
      reply: "",
      commentableIdForAns: 0,
      currentReplyIndex: 0,
      questionDetail: [],
      replyList: [],
      openSubReply: false,
      detailId: 0,
      currentHideShowIndex: '',
      currentHideShowId: 0,
      likeType: "",
      openSubComment: false,
      currentCommentIndex: 0,
      anchorEl: false
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  postLikeUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      const { postDetails } = this.state;
      this.getUpdatedQuestions(postDetails?.attributes?.id)
    } else if (responseJson?.errors) {
      this.toastHandler(responseJson);
    }
  }
  getUpdatedQuestionUi = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ postDetails: responseJson.data });
    }
    else if (responseJson?.errors) {
      this.toastHandler(responseJson);
    }
  }
  getLikeListUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ likeList: responseJson?.data });
    } else if (responseJson?.errors) {
      this.toastHandler(responseJson);
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push('/home');
      }, 2000)
    }
  }
  postCommentUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ commentSection: false });
      this.setState({ comment: '' });
      const { postDetails } = this.state;
      this.getUpdatedQuestions(postDetails?.attributes?.id)
    } else if (responseJson?.errors) {
      if (responseJson.errors[0].token)
        this.setState({ open: true });
    }
  }
  postReplyUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ openReply: true, openSubComment: false, reply: '' });
      const { postDetails } = this.state;
      this.getUpdatedQuestions(postDetails?.attributes?.id)
      this.showAllReplies(this.state.commentableIdForAns, this.state.currentHideShowIndex);
    } else if (responseJson?.errors) {
      console.log(responseJson.errors)
    }
    else if (responseJson?.meta[0]?.message[0]?.error) {
      this.props.openToastHandler("error", responseJson?.meta[0]?.message[0]?.error);
    }
  }
  questionDetailui = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({ questionDetail: responseJson });
    } else if (responseJson?.errors) {
      this.toastHandler(responseJson);
    }
  }
  replyListUi = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({
        replyList: responseJson?.data,
        openSubReply: !this.state.openSubReply
      });
    } else if (responseJson?.errors) {
      this.toastHandler(responseJson);
    }
  }
  toastHandler = (responseJson: any) => {
    if (responseJson.errors[0].token)
      this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch (apiRequestCallId) {
        case this.postLikeApiCallId:
          this.postLikeUi(responseJson);
          break;
        case this.getUpdatedQuestionApiCallId:
          this.getUpdatedQuestionUi(responseJson);
          break;
        case this.getLikeListApiCallId:
          this.getLikeListUi(responseJson);
          break;
        case this.postCommentApiCallId:
          this.postCommentUi(responseJson);
          break;
        case this.postReplyApiCallId:
          this.postReplyUi(responseJson);
          break;
        case this.getQuestionDetailApiCallId:
          this.questionDetailui(responseJson);
          break;
        case this.getReplyListApiCallId:
          this.replyListUi(responseJson);
          break;
      }
    }
  }
  // Customizable Area Start
  openPostDialog = () => {
    this.setState({ openDiscussionDialog: true });
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.openPostDialog();
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
      }
    }
  }
  getUpdatedQuestions = (id: any) => {
    this.props.showLoader();
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getUpdatedQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showQuestionApiEndPoint}/${id}`
    );
    const header = {
      "token": localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getLikes = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getLikeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLikePost
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDisLike = (id: any, unlike: any, type: any) => {
    this.handleLike(id, unlike, type);
  }
  handleLike = (id: any, like: any, type: any) => {
    this.setState({ likeType: type });
    const headers = {
      "token": localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    const apiEndPoint = configJSON.postLikesApiEndPoint;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postLikeApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    const data = {
      "like": {
        "likeable_type": type,
        "likeable_id": id,
        "is_like": like
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  commentOnQuestion = () => {
    const headers = {
      "token": localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    const apiEndPoint = configJSON.commentApiEndPoint;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postCommentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    const data = {
      "comment": {
        "commentable_type": "question",
        "commentable_id": this.state.postDetails?.attributes?.id,
        "description": this.state.comment
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  commentOnAnswer = () => {
    if (this.state.reply === "") { this.setState({ openSubComment: false, openReply: false }) } else {
      const headers = {
        "token": localStorage.getItem("token"),
        "Content-Type": configJSON.validationApiContentType
      };
      const apiEndPoint = configJSON.commentApiEndPoint;
      const methodType = configJSON.exampleAPiMethod;
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.postReplyApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      const data = {
        "comment": {
          "commentable_type": "comment",
          "commentable_id": this.state.commentableIdForAns,
          "description": this.state.reply
        }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
  showAllReplies = (id: any, i: any) => {
    let identify = false;
    if ((this.state.currentHideShowId == 0 && id > 0) || (this.state.currentHideShowId > 0 && id > 0)) {
      identify = true
    }
    let indexVal = false;
    if ((this.state.currentHideShowIndex === '' && i !== '') || (this.state.currentHideShowIndex !== '' && i !== '')) {
      indexVal = true
    }
    this.setState({ currentHideShowIndex: indexVal ? i : '', currentHideShowId: identify ? id : 0 });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getReplyListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentApiEndPoint}/${id}`
    );
    const header = {
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getQuestionDetail = (id: any) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getQuestionDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionDetailApiEndPoint}?account_id=${id}`
    );
    const header = {
      "token": localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}