import React from "react";
// import {getStorageData} from '../../../framework/src/Helpers';


let getThemeColor=()=>{
    let themeColor = 'dark';
    return themeColor;
}
 
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const ThemeColorContext = React.createContext({
    themeColor: getThemeColor(), changeThemeColor: (param:any) => {}
   
});
  
export default ThemeColorContext;
