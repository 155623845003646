import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum,
{
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
export const resuableFun = require("../../ContentManagement/src/resuableFunction")
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
  };
interface S {
  // Customizable Area Start
  categories: any;
  selectedChip: any;
  recommendedBlogs: any;
  searchInfo: any;
  categoryId: any;
  blogDetail: any;
  show: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class BlogDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoriesApiCallId: string = "";
  categoryData: any;
  getRecommendedBlogsApiCallId: string = "";
  getBlogssDetailApiCallId: string = "";
  followApiCallId: string = "";
  unBookNowApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];
    this.state = {
      categories: [],
      selectedChip: 0,
      recommendedBlogs: [],
      searchInfo: "",
      categoryId: 1,
      blogDetail: {},
      show: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getRecommendedBlogsApiCallId:
            this.handleRecommendedBlogsApiResponce(responseJson);
            break;
          case this.getBlogssDetailApiCallId:
            this.handleBlogssDetailApiResponce(responseJson);
            break;
          case this.followApiCallId:
            this.handleFollowApiCallIdApiResponce(responseJson);
            break;
          case this.unBookNowApiCallId:
            this.handleUnBookApiCallIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.props.hideLoader();
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleRecommendedBlogsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ recommendedBlogs: responseJson.data });
    }
  };
  handleBlogssDetailApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ blogDetail: responseJson.data });
    }
  };
  handleFollowApiCallIdApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.BlogDetails()
    }
  };
  handleUnBookApiCallIdApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson?.success) {
      this.BlogDetails()
    }
  };
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };
  recommendedBlogs = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecommendedBlogsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContentsBlogs + `&page=1&per_page=3&category[]=${this.state.categoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  BlogDetails = () => {
    const token = localStorage.getItem('token');
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) {
      headers.token = token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlogssDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContents}/${this.props.match?.params?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  follow = (id: any) => {
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      this.props.showLoader();
      headers['token'] = token
      const data = {
        "content_provider_id": id
      }
      const apiEndPoint = configJSON.blogFollow
      const methodType = configJSON.exampleAPiMethod;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.props.openToastHandler("error", "Please Login First!");
    }
    return true;
  }
  unfollow = (id: any) => {
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      this.props.showLoader();
      headers.token = token
      const data = {
        "content_provider_id": id
      }
      const apiEndPoint = configJSON.blogUnfollow
      const methodType = configJSON.exampleAPiMethod;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.unBookNowApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.props.openToastHandler("error", "Please Login First!");
    }

    return true;
  }
  views = (count: any) => {
    if (count) {
      if (count < 1000) {
        return count
      }
      else {
        return count / 1000 + 'k'
      }
    }
    else {
      return '0k'
    }
  }
}
  // Customizable Area End
