//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Button, Divider, createStyles, Container, Typography, CardMedia,
  Card, CardContent, InputBase, Box, Hidden, Collapse
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import SchoolsSearchController, { props } from "./SchoolsSearchController.web";
import SearchIcon from '@material-ui/icons/Search';
import { collegeImg, globalImg, schoolImg, amityImg, qsImg } from "./assets";
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slider from '@material-ui/core/Slider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';


const CheckBoxData = ({ classes, item, e, i }) => {
  const data = {
    "0": "id",
    "2": "id",
    "3": "id",
    "4": "school_type",
    "5": "lanuage",
    "7": "admission_process"
  }
  return (<Checkbox
    edge="start"
    color="default"
    checked={item.value.indexOf(e[data[item?.id]]) !== -1}
    tabIndex={-1}
    disableRipple
    inputProps={{ 'aria-labelledby': `checkbox-list-label-${e?.id}` }}
    className={classes.checkbox}
  />
  )
}

const FilterButton = ({ classes, applyFilter, clearFilter }) => {
  return (
    <Box component="div" ml={2} mt={2} mb={2}>
      <Button variant="contained" className={classes.applybtn} color="secondary" data-testId="applyFilter" onClick={() => applyFilter()}>
        Apply
      </Button>
      <Button variant="contained" className={classes.clearbtn} color="secondary" data-testId="clearFilter" onClick={() => clearFilter()} >Clear All</Button>
    </Box>
  )
}
const SchoolSearchFilterData = ({ classes, filterdata, handleClick, selectedType, distancerange, applyFilter, clearFilter, searchLocation, setSearchInfo, handleChange, rangeInfo, valuetext }) => {
  const itemype = { "4": "school_type", "5": "lanuage", "7": "admission_process" };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.listroot}
    >
      {filterdata && filterdata.length > 0 && filterdata.map((item: any, index: any) =>
        <React.Fragment key={item?.id}>
          <ListItem button data-testId="handleClick" onClick={() => handleClick(item)} >
            <ListItemText className={classes.filterItem} primary={<Typography variant="subtitle1" className={classes.checkgroupTxt}>{item?.type}</Typography>} />
            {selectedType == item?.id ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedType == item?.id} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                item?.id === 0 && <InputBase
                  data-testId="searchLocation"
                  onChange={(e) => searchLocation(e, index)}
                  placeholder="Search"
                  style={{ color: '#000', width: '100%' }}
                  inputProps={{ 'aria-label': 'search', className: classes.searchInput }}
                />
              }
              {item.data && item.data.length > 0 && item.data.map((e: any, i: any) => {
                return (
                  <ListItem key={e?.id} role={undefined} dense button data-testId="setSearchInfo" onClick={() => setSearchInfo(e, index, i)}>
                    {(item?.id != 6 && item?.id !== 1) &&
                      <CheckBoxData data-testId="checkBoxData" {...{ classes, item, e, i }} />
                    }
                    {item?.id === 1 &&
                      <Checkbox
                        edge="start"
                        color="default"
                        checked={item.value.indexOf(i) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${e?.id}` }}
                        className={classes.checkbox}
                      />
                    }

                    {
                      item?.id == 1 ? <ListItemText id={`checkbox-list-label-${e?.id}`} primary={<Typography variant="subtitle2" className={classes.btnTypo}>{`${e.range}Lakh (${e.count})`}</Typography>} /> :
                        <ListItemText id={`checkbox-list-label-${e?.id}`} primary={<Typography variant="subtitle2" className={classes.btnTypo}>
                          {(item?.id === 0 || item?.id === 2 || item?.id === 3) && `${e?.attributes?.name} (${(e?.attributes?.count == null ? e?.attributes?.search_count : e?.attributes?.count)})`}
                          {(item?.id === 4 || item?.id === 5 || item?.id === 7) && `${e[itemype[item?.id]]} (${(e?.count)})`}

                        </Typography>} />
                    }
                  </ListItem>
                );
              })}
              {item?.id == 6 &&
                <ListItem key={'range_' + item?.id} role={undefined} dense style={{ justifyContent: 'center' }} >
                  <Slider
                    max={10}
                    min={1}
                    scale={(x) => x}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    value={rangeInfo}
                    getAriaValueText={() => valuetext}
                    onChange={(e, newValue) => handleChange(e, newValue, index)}
                    step={1}
                    marks={distancerange}
                    className={classes.sliderColor}
                  />
                </ListItem>
              }
            </List>
          </Collapse>
        </React.Fragment>
      )}
      <FilterButton data-testId="filterButton" {...{ classes, applyFilter, clearFilter }} />
    </List>
  )
}
const SchoolsList = ({ classes, schoolsList, numFormatter, gotoWebsite }) => {
  return (
    <Grid container direction="row" alignItems="center" className={schoolsList.length == 0 ? classes.nodata : ''}>
      {schoolsList && schoolsList.length > 0 && schoolsList.map((s: any) =>
        <Grid item xs={12} sm={6} md={6}>
          <Card className={classes.popularSchoolCard}>
            <CardContent className={classes.popularSchoolContent} >
              <Grid item className={classes.popularcardImg}>
                {s?.attributes?.logo && s?.attributes?.logo?.url ? <img src={s?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}
                <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                  <Typography variant="h3" className={classes.popularUnivarsityTitle}>{s?.attributes?.name}</Typography>
                  <Typography variant="body" className={classes.location} >{s?.attributes?.location?.data?.attributes?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" className={classes.feefont} >Total fee</Typography>
                    <Typography variant="body2" className={classes.feefont} >{numFormatter(s?.attributes?.total_fees_min)} - {numFormatter(s?.attributes?.total_fees_max)}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" className={classes.feefont} >Median salary</Typography>
                    <Typography variant="body2" className={classes.feefont}>{numFormatter(s?.attributes?.median_salary)}</Typography>

                  </Grid>
                </Grid>
              </Grid>

              <hr className={classes.hrline} />
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" className={classes.feefont}>Course Rating</Typography>
                    <Typography variant="body2" className={classes.feefont}>{s?.attributes?.course_rating ? s?.attributes?.course_rating : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} >
                  <Grid container direction="column">
                    <Typography variant="body2" className={classes.feefont}>Ranked</Typography>
                    <Typography variant="body2" className={classes.feefont}>{s?.attributes?.rank} Ranked in {s?.attributes?.location?.data?.attributes?.name}</Typography>

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.actionBtns}  >
                <Button className={classes.visiteWebsiteBtn} disabled={!s?.attributes.website_url}>
                  <Typography variant="body2" className={classes.feefont} data-testId="gotoWebsite" onClick={(e) => gotoWebsite(s)} >Visite website</Typography>
                </Button>
                <a href={s?.attributes?.brochure} download={s?.attributes?.brochure} target="_blank" disabled={!s?.attributes.brochure}>	 <Button disabled={!s?.attributes.brochure} className={classes.downloadBrochureBtn}>
                  <Typography variant="body2" className={classes.feefont}  >Download Brochure</Typography>
                </Button></a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {schoolsList && schoolsList.length === 0 &&
        <Grid item xs={12} sm={6} md={6} className={classes.noSchools} >
          No schools to display
        </Grid>
      }
    </Grid>
  )
}
export class SchoolsSearch extends SchoolsSearchController {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.getFilterOptions('location', this.state.filterdata)
    this.getSchoolFeaturedCtas()
    this.getSchoolCtas()
    this.initiateMethod()
  }
  initiateMethod = () => {
    let quary = ''
    if (this.props?.location?.state) {
      let filterInfo = this.state.filterdata
      if (Object.keys(this.props?.location?.state)[0] == 'distance_range') {
        filterInfo[6].value.push(this.props?.location?.state?.distance_range)
        this.setState({ filterdata: filterInfo, rangeInfo: this.props?.location?.state?.distance_range })
      }
      if (Object.keys(this.props?.location?.state)[0] == 'locations') {
        filterInfo[0].value.push(this.props?.location?.state?.locations)
        this.setState({ filterdata: filterInfo })
        quary += `${Object.keys(this.props?.location?.state)[0]}[]=` + this.props?.location?.state[Object.keys(this.props?.location?.state)[0]]
      }
      else {
        quary += `${Object.keys(this.props?.location?.state)[0]}=` + this.props?.location?.state[Object.keys(this.props?.location?.state)[0]]

      }

    }
    this.getSchoolsBySearchInfo(quary)

  }
  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        if (data.id != 6) {
          this.getFilterOptions(data.key, this.state.filterdata)
        }
      })
    }
  }
  onPageChange = (e, page) => {
    this.setState({ currentPage: page }, () => {
      this.setSearchQuaryparameters(this.state.filterdata)
    });
  }
  render() {
    const self = this;
    const { classes } = this.props;
    const { numFormatter, gotoWebsite, handleClick, searchLocation, setSearchInfo, handleChange, valuetext, applyFilter, clearFilter } = self
    const { filterdata, schoolsList, distancerange, schoolsCtasList, totalPages, currentPage } = this.state;
    return (<>

      <Container maxWidth="xl" className={classes.bannercontainer}  >
        <Grid item xs={12} className={classes.categoryViewall}>
          <img alt="" src={collegeImg} className={classes.collegeImage} />
          <Grid container direction="column" item xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">
            <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
              {/* <SearchWeb /> */}
              <Grid item xs={2} sm={1}>
                <SearchIcon color="secondary" />
              </Grid>
              <Grid item xs={10} sm={11}>
                <InputBase
                  placeholder="Search schools here"
                  classes={{
                    input: classes.inputInput,
                  }}
                  value={this.state.searchText}
                  data-testId="searchData"
                  onChange={(e) => this.searchData(e)}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.featuredBlock} >
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2" className={classes.featuredText}>Featured</Typography>
          </Grid>
          <Grid item style={{ width: "calc(100% - 25px)" }}>
            <Grid container direction="row" alignItems="center" className={classes.ctalist} >
              {schoolsCtasList && schoolsCtasList.length > 0 && schoolsCtasList.map((ele: any) =>
                <Grid item xs={6} md={3} sm={4} lg={3} xl={2} className={classes.ctaCard}>
                  <Card className={classes.featuredCard} data-testId="navigateCta" onClick={(e) => this.navigateCta(ele?.attributes?.href_link)}>
                    <CardMedia
                      className={classes.cover}
                      component='img'
                      image={ele?.attributes?.square_background_image}
                      title="Live from space album cover"
                    />

                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br />
      <Container className={classes.schoolSearchBlock}>
        <Grid container direction="row">
          <Grid item xs={12} md={3} sm={12} className={classes.schoolSearchLeftBlock}>
            <Grid container direction="column" alignItems="left" justify="flex-start" className={classes.filterroot}  >
              <Grid item xs={12} >
                <Typography variant="h3" className={classes.headTxt}>Filter</Typography>
                <Divider variant="fullWidth" component="h3" />
                <SchoolSearchFilterData data-testId="schoolSearchFilterData" {...{ classes, filterdata, distancerange, handleClick, selectedType: this.state.selectedType, searchLocation, setSearchInfo, handleChange, rangeInfo: this.state.rangeInfo, valuetext, applyFilter, clearFilter }} />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12} md={6} sm={12} className={classes.schoolSearchMiddleBlock}>
            {this.props?.location?.state && (Object.keys(this.props?.location?.state)[0] == 'featured' || Object.keys(this.props?.location?.state)[0] == 'popular') &&
              <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                <Hidden smDown><Typography align="center" className={classes.title} variant="h4">{Object.keys(this.props?.location?.state)[0] == 'featured' ? 'Featured' : ''} {Object.keys(this.props?.location?.state)[0] == 'popular' ? 'Popular' : ''}{' '}Schools</Typography></Hidden>
                <Hidden mdUp><Typography align="center" className={classes.title} variant="h3">{Object.keys(this.props?.location?.state)[0] == 'featured' ? 'Featured' : ''} {Object.keys(this.props?.location?.state)[0] == 'popular' ? 'Popular' : ''}{' '} Schools</Typography></Hidden>
              </Grid>
            }
            <SchoolsList data-testId="schoolsList" {...{ classes, schoolsList, numFormatter, gotoWebsite }} />

          </Grid>

          <br />
          <Grid item xs={12} md={3} sm={12} className={classes.schoolSearchRightBlock}>
            {this.props?.location?.state && (Object.keys(this.props?.location?.state)[0] == 'featured' || Object.keys(this.props?.location?.state)[0] == 'popular') &&
              <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
                <br />
              </Grid>
            }
            <Grid item xs={12} className={classes.addsCardRoot} >
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid item className={classes.addsCardLeft}>
                  <Button className={classes.sessionBtn}>
                    <Typography variant="h3" className={classes.sessionbtnTypo} >2021 SESSION APPLY NOW</Typography>
                  </Button>
                </Grid>
                <Grid item className={classes.addsCardRight}>
                  <img src={amityImg} className={classes.amityLogo} />
                </Grid>
              </Grid>
              <Card className={classes.addsCard}>
                <img src={globalImg} className={classes.globalBg} />
                <CardContent className={classes.globalCardContent} >
                  <br />
                  <Grid container direction="column">
                    <Typography variant="h4" className={classes.qsRank} >RANKED AMONGST THE TOP 3% UNIVERSITIES GLOBALLY</Typography>
                    <img src={qsImg} className={classes.qsLogo} />
                    <br />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>  <br />
            <Grid item xs={12} className={classes.featuredroot} >
              <Card className={classes.featuredCard}>
                <CardContent className={classes.featuredContent}>
                  <Grid item className={classes.schoolSubCard}>
                    <img src={schoolImg} className={classes.reviewerImg} />
                    <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                      <Typography variant="body" className={classes.featuredBtn}>Featured</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" className={classes.reviewblock} >
                    <Typography variant="h4" className={classes.rightReviewTitle}>Dps Delhi</Typography>
                    <Typography variant="body2" className={classes.reviewFont} >Ranked 1 For IITs by The Week 2020</Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {totalPages > 1 && <Grid container direction="row" className={classes.paginator} alignItems="center" justify="space-between">
            <Pagination page={currentPage} data-testId="onPageChange" onChange={this.onPageChange} count={totalPages} className={classes.schoolPagination} variant="outlined" shape="rounded" />
          </Grid>}
        </Grid>
      </Container>
    </>
    );
  }
}
//@ts-ignore
const SchoolsSearchWithRouter = withRouter(SchoolsSearch);
const SchoolsSearchWithLoader = withLoader(SchoolsSearchWithRouter);
const SchoolsSearchToast = withSnackbar(SchoolsSearchWithLoader);
const SchoolsSearchWithStyle = withStyles((theme) =>
  createStyles({
    categoryViewall: {
      margin: '3rem 3rem 1rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      // height: '25rem',
      [theme.breakpoints.down('lg')]: {
        //height: '15rem',
        margin: '1.5rem 2rem 0rem',
      },
      [theme.breakpoints.down('md')]: {
        margin: '0.5rem 0rem 0rem',
      },
    },


    uParallax: {
      position: 'absolute',
      backgroundColor: 'rgb(6 55 106 / 53%)',
      width: '100%',
      height: '100%',
    },
    categorySearch: {
      width: '50%',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      borderRadius: '17px',
      padding: '12px 15px 8px',
      color: '#fff',
      position: 'absolute',
      left: '25%',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        left: '19%',
      },

    },
    featuredBtn: {
      fontSize: '0.5rem',
      color: '#fff',
      backgroundColor: 'rgb(203 199 199 / 54%)',
      padding: '0.2rem',
      borderRadius: '4px',
      width: 'fit-content',
    },

    featuredText: {
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      color: ' #222',
      fontSize: '1rem',
    },


    reviewerImg: {
      height: '80px',
      objectFit: 'cover',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      width: '100%',
    },


    popularSchoolCard: {
      margin: '0 0.75rem 1.5rem 0.75rem',
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 0.5rem 0',
      },
    },
    popularcardImg: {
      position: 'relative',
      marginBottom: "0.85rem"
    },
    popularSchoolImg: {
      height: '130px',
      objectFit: 'cover',
      width: '100%',
    },
    popularUnivarsityBlock: {
      position: 'absolute',
      top: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
      padding: '10px',
    },
    popularUnivarsityTitle: {
      fontSize: '1.2rem',
      color: '#fff',
    },
    visiteWebsiteBtn: {
      backgroundColor: '#7764db',
      // padding: '7px 22px',
      padding: '7px 14px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      '&:hover': {
        backgroundColor: '#5840d7 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.6,
      },

    },
    downloadBrochureBtn: {
      backgroundColor: '#fa6400',
      padding: '7px 4px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      '&:hover': {
        backgroundColor: '#dd5a03 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.7,
      },
    },
    activeCheck: {
      color: '#fa6400',
      position: 'absolute',
      top: '-8px',
      right: '-8px',
    },
    rightReviewTitle: {
      fontSize: '14px',
      color: '#7764db',
      marginBottom: '0.3rem'
    },
    schoolSearchBlock: {
      padding: '0 2rem',
      maxWidth: '1820px',
      margin: '0 0 0 auto',
      [theme.breakpoints.down('md')]: {
        padding: '0 1rem',
      },
    },
    schoolSearchMiddleBlock: {
      padding: '0',
    },
    schoolSearchLeftBlock: {
      paddingRight: '2rem',
      [theme.breakpoints.down('lg')]: {
        paddingRight: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: '0rem',
      },
    },
    schoolSearchRightBlock: {
      paddingLeft: '2rem',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '1rem',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0rem',
      },
    },
    headTxt: {
      color: '#000',
      margin: '0.6rem',
    },
    checkgroupTxt: {
      color: '#000',
      margin: '0rem',

    },
    searchInput: {
      border: '1px solid #eee',
      color: '#000',
      borderRadius: '4px',
      margin: '0 10px',
      padding: '5px',
    },
    qsLogo: {
      maxWidth: '100px !important',
    },
    globalBg: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      minHeight: '235px',
    },
    globalCardContent: {
      top: '0px',
      width: '100%',
      height: '100%',
      padding: '12px',
      position: 'absolute',
      backgroundColor: 'rgb(3 62 96 / 60%)',
    },
    sessionBtn: {
      width: '6.8rem',
      textAlign: 'left',
      padding: '2px',
      backgroundColor: '#f7a600',
      borderRadius: '0',
    },
    sliderColor: {
      width: '90%',
      "&>.MuiSlider-thumb": {
        color: "#fa6400 !important"
      },
      "&>.MuiSlider-rail": {
        color: "#7764db !important",
        opacity: 1,
      },
      "&>.MuiSlider-track": {
        color: "#fa6400 !important"
      }
    },

    applybtn: {
      padding: '6px 42px',
      marginRight: '10px',
      margin: '5px 0px',
      backgroundColor: '#5285f8 !important',
      borderRadius: '6px',
      fontSize: '13px',
      fontFamily: 'Montserrat',
      fontWeight: '500',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1a5df3 !important',
        color: '#fff'
      }
    },
    clearbtn: {
      margin: '5px 0px',
      backgroundColor: '#fff !important',
      boxShadow: 'none',
      borderRadius: '6px',
      fontSize: '13px',
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: '500',
      color: '#000',
      padding: '6px 8px',
    },
    inputInput: {
      '&::placeholder': {
        color: '#fff !important',
        opacity: '1 !important',
      }
    },
    viewmore: {
      textAlign: 'end',
      color: '#fa6400',
      cursor: 'pointer',
    },
    nodata: {
      justifyContent: 'center',
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
    },
    cover: {
      height: '6.1rem',
      cursor: 'pointer',
    },
    collegeImage: {
      width: "100%"
    },
    bannercontainer: {
      padding: "1rem"
    },
    featuredBlock: {
      padding: "0.8rem 0.5rem",
      backgroundColor: "#eeeeee"
    },
    ctalist: {
      flexWrap: "inherit",
      overflowX: "auto"
    },
    ctaCard: {
      padding: "0 0.25rem",
      maxWidth: "15rem",
      minWidth: "15rem"
    },
    filterroot: {
      border: '1px solid #eee',
      borderRadius: '4px'
    },
    listItem: {
      padding: '5px 10px'
    },
    title: {
      color: "#f48b1b",
      paddingLeft: '0.6rem'
    },
    popularSchoolContent: {
      padding: "10px"
    },
    location: {
      color: '#fff'
    },
    feefont: {
      fontSize: '0.73rem'
    },
    hrline: {
      margin: '0.5rem 0'
    },
    actionBtns: {
      marginTop: '0.5rem',
      display: 'flex'
    },
    addsCard: {
      position: 'relative',
      minHeight: '235px',
      borderRadius: '0'
    },
    amityLogo: {
      width: "6rem"
    },
    qsRank: {
      marginBottom: '0.5rem',
      color: '#fff',
      fontWeight: '500'
    },
    schoolSubCard: {
      position: "relative"
    },
    featuredroot: {
      padding: "0 0.25rem"
    },
    featuredContent: {
      padding: "0px"
    },
    reviewblock: {
      padding: "0.3rem"
    },
    reviewFont: {
      fontSize: '0.65rem'
    },
    addsCardRoot: {
      padding: "0 0.25rem"
    },
    noSchools: {
      marginLeft: '1.5rem'
    },
    searchbox: {
      color: '#000', width: '100%'
    },
    checkbox: {
      padding: '5px 10px 5px 5px'
    },
    schoolPagination: {
      margin: '4px auto 12px'
    }
  })
)(SchoolsSearchToast);
export default withTranslation()(SchoolsSearchWithStyle);
