import React from "react";
import {
  withStyles, Grid, createStyles, Container,Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LanguageOptionController, { Props } from './LanguageOptionController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
//@ts-ignore
class LanguageOptionWeb extends LanguageOptionController {
  constructor(props: Props) {
    super(props);
  }; 
  render() {
  //@ts-ignore
    const {  t } = this.props;
    return (
      <Container>
        <Grid container direction="column" xs={12} alignItems='flex-start'>
        <div>
      <h1>{t('key')}</h1>
    </div>
        </Grid>
        <Snackbar
          open={this.state.showToast.show}
          autoHideDuration={3000}
          onClose={this.closeToastHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeToastHandler}
            severity={this.state.showToast.type}
          >
            {this.state.showToast.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}
//@ts-ignore
const LanguageOptionWebWithRouter = withRouter(LanguageOptionWeb);
const LanguageOptionWithStyle =  withStyles((theme) =>
  createStyles({
profileBtn:{
  display: 'block',
  position: 'absolute',
  opacity: '0'
},
uploadedImage:{
  width: '135px',
  height: '120px'
}
  })
)(LanguageOptionWebWithRouter);
export default withTranslation()(LanguageOptionWithStyle);

