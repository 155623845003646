//@ts-nocheck
import React from "react";
import {
  withStyles, Box, Divider, Grid, Button, createStyles, Typography, TextField,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import {
  Props,
} from "./CategoriesViewallController.web";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import Rating from '@material-ui/lab/Rating';


const DateOptions = ({ classes, item, filterdata, index, handleDateChange }) => {
  return (
    <>
      {item?.type == 'Date' && item?.value && item?.value.length > 0 && item?.value.indexOf(6) !== -1 && (
        <React.Fragment>
          <ListItem key={item.id} role={undefined} dense >
            <ListItemIcon>
              <TextField
                id="fromDate"
                label="From Date"
                type="date"
                value={item.fromDate}
                className={classes.textField}
                InputProps={{ inputProps: { max: item.toDate } }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleDateChange(e, filterdata, index)}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem key={item.id + 1} role={undefined} dense >
            <ListItemIcon>
              <TextField
                id="toDate"
                label="To Date"
                type="date"
                value={item.toDate}
                className={classes.textField}
                InputProps={{ inputProps: { min: item.fromDate } }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleDateChange(e, filterdata, index)}
              />
            </ListItemIcon>
          </ListItem>
        </React.Fragment>
      )}
    </>
  )
}

const Priceoptions = ({ classes, item, filterdata, index, handlePriceChange }) => {
  return (
    <>
      {item?.type == 'Price' && (
        <React.Fragment>
          <ListItem key={item.id} role={undefined} dense >
            <TextField
              id="fromPrice"
              label="Min Price"
              type="number"
              variant="outlined"
              value={item.fromPrice}
              className={classes.textField}
              InputProps={{ inputProps: { min: "1" } }}
              onChange={(e) => handlePriceChange(e, filterdata, index)}
            />
          </ListItem>
          <ListItem key={item.id + 1} role={undefined} dense >
            <ListItemIcon>
              <TextField
                id="toPrice"
                label="Max Price"
                variant="outlined"
                type="number"
                value={item.toPrice}
                className={classes.textField}
                InputProps={{ inputProps: { min: "1" } }}
                error={item.fromPrice > item.toPrice}
                onChange={(e) => handlePriceChange(e, filterdata, index)}
              />
            </ListItemIcon>
          </ListItem>
        </React.Fragment>
      )}
    </>
  )
}


const RatingOption = ({ item, ratingValue, handleRatingChange }) => {
  return (
    <React.Fragment>
      {item?.type == 'Rating' && (
        <ListItem key={item.id} role={undefined} dense >
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Rating
              name="simple-controlled"
              value={ratingValue}
              onChange={handleRatingChange}
            />
          </Box>
        </ListItem>
      )}
    </React.Fragment>
  )
}

const CheckBox = ({ classes, ele, item, filterdata, index, handleToggle }) => {
  return (
    <ListItem key={ele.id} role={undefined} dense button data-testId="handleToggle" onClick={() => handleToggle(ele, filterdata, index, item?.id)}>
      <Checkbox
        edge="start"
        checked={item.value.indexOf(ele.id) !== -1}
        color="default"
        tabIndex={-1}
        disableRipple
        inputProps={{ 'aria-labelledby': `checkbox-list-label-${ele.id}` }}
      />
      {item.type == 'Date' ? <ListItemText id={`checkbox-list-label-${ele.id}`} primary={<Typography variant="subtitle2" className={classes.btnTypo}>{ele?.name}</Typography>} /> :
        <ListItemText id={`checkbox-list-label-${ele?.id}`} primary={<Typography variant="subtitle2" className={classes.btnTypo}>{ele?.attributes?.name ? `${ele?.attributes?.name} (${ele?.attributes?.count})` : `${ele?.attributes?.partner_name} (${ele?.attributes?.count})`}</Typography>} />}
    </ListItem>
  )
}

export class Categorysearch extends React.Component {
  constructor(props: Props) {
    super(props);
  }
  render() {
    //@ts-ignore
    const { classes, filterdata, ratingValue } = this.props;

    return (
      <Grid container direction="column" justifyContent="flex-start">
        <Grid item xs={12} >
          <Typography variant="h3" className={classes.headTxt}>Filter</Typography>
          <Divider variant="fullWidth" component="h3" />
          <br />
          {filterdata && filterdata.length > 0 ? (
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={classes.listroot}
            >
              {filterdata.map((item: any, index: any) => (
                <React.Fragment key={item?.id}>
                  <ListItem button data-testId="onclick" onClick={() => this.props.onclick(item)} >
                    <ListItemText className={classes.filterItem} primary={<Typography variant="subtitle1" className={classes.checkgroupTxt}>{item?.type}</Typography>} />
                    {this.props.selectedType == item?.id ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.props.selectedType == item?.id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.data && item.data.length > 0 && item.data.map((ele: any) => {
                        return (
                          <CheckBox data-testId="CheckBox" key={ele.id} {...{ classes, ele, item, filterdata, index, handleToggle: this.props.handleToggle }} />
                        );
                      })}
                      <DateOptions
                        data-testId="DateOptions"
                        {...{
                          classes,
                          item,
                          filterdata,
                          index,
                          handleDateChange: this.props.handleDateChange
                        }}
                      />
                      <RatingOption data-testId="RatingOption" {...{ item, ratingValue, handleRatingChange: this.props.handleRatingChange }} />
                      <Priceoptions data-testId="Priceoptions" {...{
                        classes,
                        item,
                        filterdata,
                        index,
                        handlePriceChange: this.props.handlePriceChange
                      }}
                      />
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
              <Box component="div" ml={2} mt={2} mb={2}>
                <Button variant="contained" className={classes.applybtn} color="secondary" data-testId="applyFilter" onClick={() => this.props.applyFilter(filterdata)}>
                  Apply
                </Button>
                <Button variant="contained" className={classes.clearbtn} color="secondary" data-testId="clearFilter" onClick={() => this.props.clearFilter(filterdata)} >Clear All</Button>
              </Box>
            </List>
          ) : (<Grid item>
            <Typography variant="body2">Will Update You Soon</Typography>
          </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
}
// export default Categorysearch
//@ts-ignore
const CategorySearchWebWithRouter = withRouter(Categorysearch);

export default withStyles((theme) =>
  createStyles({
    headTxt: {
      color: '#000',
      margin: '0.8rem',
      fontSize: '15px'
    },
    checkgroupTxt: {
      color: '#000',
      margin: '0rem',
      fontSize: '15px',
      fontFamily: 'Montserrat-SemiBold',
    },
    filterItem: {
      fontSize: '14px !important',
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: '400',
      lineHeight: '1.167',
      letterSpacing: '0em',
    },
    listroot: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    applybtn: {
      padding: '6px 42px',
      marginRight: '10px',
      margin: '5px 0px',
      backgroundColor: ' #ff9322 !important',
      borderRadius: '6px',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      fontWeight: '500',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#fff'
      }
    },
    clearbtn: {
      margin: '5px 0px',
      backgroundColor: '#fff !important',
      boxShadow: 'none',
      borderRadius: '6px',
      fontSize: '15px',
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: '500',
      color: '#000',
      padding: '6px 8px',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: 200,
      "& input": {
        color: '#2b2b2b !important',
      },
      "& label": {
        color: 'gray !important',
      },
    },
  })
)(CategorySearchWebWithRouter);