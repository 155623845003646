//@ts-nocheck
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Typography,
  Container,
  Grid,
  AppBar,
  Box,
  Toolbar,
  Drawer,
  Tabs,
  Tab,
  IconButton,
  createStyles,
  Menu,
  MenuItem,
  withStyles,
  Badge,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
//@ts-ignore
import { useTranslation, withTranslation, Trans } from "react-i18next";
import withLoader from "../withLoader.web";
import HeaderController, { Props } from "./HeaderController.web";
//@ts-ignore
import i18n from "i18next";
import Button from "@material-ui/core/Button";
import EmailAccountLoginBlock from "../../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import { female, logo, language } from "./assets";
import PersonIcon from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MenuIcon from "@material-ui/icons/Menu";
//@ts-ignore
import NestedMenuItem from "material-ui-nested-menu-item";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import PropTypes from "prop-types";
import CareerExpertDialogModelWeb from "../../../blocks/ContentManagement/src/CareerExpertDialogModel.web";
import ReactTimeAgo from "react-time-ago";
import en from 'javascript-time-ago/locale/en.json';
import TimeAgo from "javascript-time-ago";

const dataInfo = [
  { path: "/home/jobs", name: "Jobs" },
  { path: "/home/blogs", name: "Blogs" },
];

const CustomNavItems = () => {
  return (
    <div className="flex items-center">
      <Typography variant="subtitle1" color="secondary">
        <NavLink
          to={`/home/blogs`}
          activeStyle={{
            fontWeight: 700,
            color: "white",
          }}
          className={classes.hoverLink}
        >
          {t("Blogs")}
        </NavLink>
      </Typography>
    </div>
  );
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
const styles = {
  tab: {
    color: "#f48b1b",
    fontWeight: 500,
    fontFamily: "Montserrat-SemiBold",
    fontSize: "14px",
  },
  inActiveTab: {
    color: "white",
    fontWeight: 400,
    fontFamily: "Montserrat-Regular",
    fontSize: "13px",
  },
};
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#7764db",
    width: "15px",
    height: "15px",
  },
}))(Badge);
class HeaderWeb extends HeaderController {
  constructor(props: Props) {
    super(props);
  }
  setResponsiveness = () => {
    return window.innerWidth < 1100
      ? this.setState((prevState) => ({ ...prevState, mobileView: true }))
      : this.setState((prevState) => ({ ...prevState, mobileView: false }));
  };
  async componentDidMount() {
    TimeAgo.addDefaultLocale(en)
    TimeAgo.locale(en);
    this.getAllCategories();
    this.getAppLanguage();
    this.setResponsiveness();
    if (localStorage.getItem("token")) {
      this.getNotifications();
    }

    window.addEventListener("resize", () => this.setResponsiveness());
  }
  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleProfile = (event: any) => {
    this.setState({ anchorPEl: event.currentTarget });
  };
  handleNotifications = (event: any) => {
    this.setState({ anchorNEl: event.currentTarget });
  };
  handleLanguageClick = (event: any) => {
    this.setState({ anchorLangEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, anchorLangEl: null });
    this.handleTopDrawerClose();
  };
  handlePClose = () => {
    this.setState({ anchorPEl: null, anchorLangEl: null });
    this.handleTopDrawerClose();
  };
  handleNClose = () => {
    this.setState({ anchorNEl: null, anchorLangEl: null });
    this.handleTopDrawerClose();
  };
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
      localStorage.removeItem("expertForm");
    } else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  };
  handleTabChange = (event: any, newValue: any) => {
    this.setState({ tabValue: newValue });
  };
  handleSchoolCollegeClick = (event: any) => {
    this.setState({ anchorschoolCollegeEl: event.currentTarget });
  };
  handleSchoolCollegeClose = () => {
    this.setState({
      anchorschoolCollegeEl: null,
      anchorLangEl: null,
      anchorEl: null,
    });
    this.handleTopDrawerClose();
  };
  handleOpenDialog = () => {
    let expertFormStatus = localStorage.getItem("token");
    let expertFormGetitem = localStorage.getItem("expertForm");
    if (expertFormStatus && expertFormGetitem === null) {
      this.setState({ dialogOpen: true });
      localStorage.setItem("expertForm", false);
      this.getAllUserStatus();
      this.getAllExpertStatus();
      this.getAllUserSubject();
    }
  };
  renderDrawer = () => {
    const self = this;
    //@ts-ignore
    const { classes, t, i18n } = this.props;
    const { onSubmit } = self;
    const {
      tabValue,
      notifications,
      inboxNotifications,
      dialogOpen,
      allUserStatus,
      allExpertStatus,
      allUserSubject,
    } = this.state;
    const getInbox = notifications[1];
    return (
      <>
        <div className="flex items-center">
          <Typography variant="subtitle1" color="secondary">
            <NavLink
              to={`/home/activityfeed`}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
            >
              {t("Home")}
            </NavLink>
          </Typography>
        </div>
        <div className="flex items-center">
          <Typography
            variant="subtitle1"
            color="secondary"
            style={{ cursor: "pointer" }}
            tabIndex="0"
            onClick={this.handleClick}
          >
            {t("Category")}
          </Typography>
          {this.state.anchorEl && (
            <ArrowDropUpIcon
              className={classes.caret}
              color="primary"
              onClick={this.handleLanguageClick}
            />
          )}
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            {this.state.categoriesData &&
              this.state.categoriesData.map((category) =>
                category?.attributes?.sub_categories !== undefined &&
                  category?.attributes?.sub_categories.length > 0 ? (
                  <React.Fragment key={"category_" + category.id}>
                    <NestedMenuItem
                      label={category?.attributes?.name}
                      parentMenuOpen={true}
                      classes={{
                        root: classes.menuItemRoot,
                        selected: classes.menuItemSelected,
                      }}
                    >
                      {category?.attributes?.sub_categories.map((subCat) => (
                        <NavLink
                          key={"subCat" + subCat.id}
                          activeStyle={{
                            fontWeight: 700,
                            color: "white",
                          }}
                          className={classes.hoverLink}
                          to={`/home/articleview/articles/${category.id}/${subCat?.id}`}
                        >
                          <MenuItem
                            classes={{
                              root: classes.menuItemRoot,
                              selected: classes.menuItemSelected,
                            }}
                            onClick={this.handleClose}
                          >
                            {subCat?.name}
                          </MenuItem>
                        </NavLink>
                      ))}
                    </NestedMenuItem>
                  </React.Fragment>
                ) : (
                  <NavLink
                    key={"category_" + category.id}
                    activeStyle={{
                      fontWeight: 700,
                      color: "white",
                    }}
                    className={classes.hoverLink}
                    to={`/home/articleview/articles/${category.id}`}
                  >
                    {" "}
                    <MenuItem
                      classes={{
                        root: classes.menuItemRoot,
                        selected: classes.menuItemSelected,
                      }}
                      onClick={this.handleClose}
                    >
                      {category?.attributes?.name}
                    </MenuItem>
                  </NavLink>
                )
              )}
          </Menu>
        </div>
        <div className="flex items-center">
          <Typography variant="subtitle1" color="secondary">
            <NavLink
              to={`/home/courses`}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
            >
              {t("Courses")}
            </NavLink>
          </Typography>
        </div>
        <div className="flex items-center">
          <Typography variant="subtitle1" color="secondary">
            <NavLink
              to={`/dashboard/discussionforum`}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
            >
              {t("Discussion Forum")}
            </NavLink>
          </Typography>
        </div>
        <div className="flex items-center">
          <Typography
            variant="subtitle1"
            className={
              this.props.location.pathname == "/home/schools" ||
                this.props.location.pathname == "/home/college" ||
                this.props.location.pathname == "/home/collegesearch" ||
                this.props.location.pathname == "/home/schoolssearch"
                ? classes.menuactive
                : ""
            }
            color="secondary"
            style={{ cursor: "pointer" }}
            tabIndex="0"
            onClick={this.handleSchoolCollegeClick}
          >
            {t("Schools / Colleges")}
          </Typography>
          {this.state.anchorschoolCollegeEl && (
            <ArrowDropUpIcon
              className={classes.caret}
              color="primary"
              onClick={this.handleLanguageClick}
            />
          )}
          <Menu
            anchorEl={this.state.anchorschoolCollegeEl}
            open={Boolean(this.state.anchorschoolCollegeEl)}
            onClose={this.handleSchoolCollegeClose}
          >
            <NavLink
              key={"schools"}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
              to={`/home/schools`}
            >
              {" "}
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
                onClick={this.handleSchoolCollegeClose}
              >
                {t("Schools")}
              </MenuItem>
            </NavLink>
            <NavLink
              key={"colleges"}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
              to={`/home/college`}
            >
              {" "}
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
                onClick={this.handleSchoolCollegeClose}
              >
                {t("Colleges")}
              </MenuItem>
            </NavLink>
            <NavLink
              key={"colleges search"}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
              to={`/home/collegesearch`}
            >
              {" "}
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
                onClick={this.handleSchoolCollegeClose}
              >
                {t("Colleges Search")}
              </MenuItem>
            </NavLink>
            <NavLink
              key={"scool search"}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
              to={`/home/schoolssearch`}
            >
              {" "}
              <MenuItem
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.menuItemSelected,
                }}
                onClick={this.handleSchoolCollegeClose}
              >
                {t("Schools Search")}
              </MenuItem>
            </NavLink>
          </Menu>
        </div>
        {dataInfo.map((ele: any, idx: any) => (
          <div className="flex items-center" key={idx}>
            <Typography variant="subtitle1" color="secondary">
              <NavLink
                to={ele.path}
                activeStyle={{
                  fontWeight: 700,
                  color: "white",
                }}
                className={classes.hoverLink}
              >
                {t(`${ele.name}`)}
              </NavLink>
            </Typography>
          </div>
        ))}
        <div className="flex items-center">
          <Typography variant="subtitle1" color="secondary">
            <NavLink
              to={`/home/careerexpert`}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
              onClick={this.handleOpenDialog}
            >
              {t("Career Expert")}
            </NavLink>
          </Typography>
          {dialogOpen && (
            <CareerExpertDialogModelWeb
              open={dialogOpen}
              onClose={() => {
                this.setState({ dialogOpen: false });
              }}
              {...{ onSubmit, allUserStatus, allExpertStatus, allUserSubject }}
            />
          )}
        </div>
        <div className="flex items-center">
          <Typography variant="subtitle1" color="secondary">
            <NavLink
              to={`/home/contactus`}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
            >
              {t("Contact Us")}
            </NavLink>
          </Typography>
        </div>
        <div className="flex items-center">
          <Typography variant="subtitle1" color="secondary">
            <NavLink
              to={`/home/aboutus`}
              activeStyle={{
                fontWeight: 700,
                color: "white",
              }}
              className={classes.hoverLink}
            >
              {t("About Us")}
            </NavLink>
          </Typography>
        </div>
        {localStorage.getItem("isActive") === "true" ? (
          <>
            <div className="flex items-center">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={this.handleNotifications}
                color="inherit"
                style={{ padding: "0", marginRight: "0" }}
              >
                <NotificationsIcon color="secondary" />
              </IconButton>
              {this.state.anchorNEl && (
                <ArrowDropUpIcon
                  className={classes.caret}
                  color="primary"
                  onClick={this.handleLanguageClick}
                />
              )}
              <Menu
                anchorEl={this.state.anchorNEl}
                open={Boolean(this.state.anchorNEl)}
                onClose={this.handleNClose}
                classes={{ paper: classes.tabMenu }}
              >
                <MenuItem>
                  <AppBar position="relative" color="transparent">
                    <Tabs
                      value={tabValue}
                      onChange={this.handleTabChange}
                      aria-label="simple tabs example"
                      centered
                    >
                      <Tab
                        style={tabValue === 0 ? styles.tab : styles.inActiveTab}
                        label="Notifications"
                        {...a11yProps(0)}
                      />
                      <Tab
                        style={tabValue === 1 ? styles.tab : styles.inActiveTab}
                        label="Inbox"
                        {...a11yProps(1)}
                        onClick={() => {
                          this.getInboxNotifications(getInbox.id);
                        }}
                      />
                    </Tabs>
                  </AppBar>
                </MenuItem>
                <MenuItem>
                  <Grid
                    container
                    direction="row"
                    className={classes.notificationItem}
                  >
                    <Grid item></Grid>
                    <Grid item>
                      <>
                        <TabPanel value={tabValue} index={0}>
                          {notifications && notifications.length > 0 ? (
                            notifications.map((data: any, index: any) => (
                              <Grid
                                container
                                className={classes.notificationData}
                                key={index}
                              >
                                <Grid item xs={1} sm={1} md={1} lg={2} xl={2}>
                                  <img src={logo} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={10} lg={9} xl={9}>
                                  <Grid container direction="column">
                                    <Grid
                                      item
                                      xs={12}
                                      className={classes.notificationText}
                                    >
                                      <Typography
                                        color="secondary"
                                        variant="h3"
                                        className={classes.contentProviderTxt}
                                      >
                                        {data?.attributes?.title}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        className={classes.contentProviderGrid}
                                      >
                                        <Grid item xs={1}>
                                          <img src={logo} />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            color="secondary"
                                            variant="body1"
                                            className={classes.contentProviderTxt}
                                          >
                                            Content Provider
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Typography
                                            color="secondary"
                                            variant="body1"
                                            className={classes.notifTime}
                                          >
                                            <ReactTimeAgo date={data?.attributes?.updated_at} locale="en-US" />
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        className={classes.contentProviderGrid}
                                      >
                                        <Grid item xs={9}>
                                          <Typography
                                            color="secondary"
                                            variant="body1"
                                            className={classes.contentTitleTxt}
                                          >
                                            {data?.attributes?.message}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                          ) : (
                            <>
                              <Typography>Notifications Are Empty</Typography>
                            </>
                          )}
                        </TabPanel>
                      </>
                    </Grid>
                    <Grid item>
                      <>
                        <TabPanel value={tabValue} index={1}>
                          {inboxNotifications ? (
                            <Grid container direction="column">
                              <Grid item xs={12}>
                                <Typography color="secondary" variant="h3" style={{ paddingBottom: "10px" }}>
                                  <ReactTimeAgo date={inboxNotifications?.attributes?.created_at ?? "2022-11-25T12:29:09.630+05:30"} locale="en-US" />
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={3}
                              >
                                <Grid item xs={2}>
                                  <StyledBadge
                                    color="primary"
                                    badgeContent=""
                                    overlap="rectangle"
                                  >
                                    <img src={female} />
                                  </StyledBadge>
                                </Grid>
                                <Grid item xs={10}>
                                  <Grid container direcion="column">
                                    <Grid
                                      item
                                      xs={12}
                                      className={classes.notificationText}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                      >
                                        <Typography color="secondary" variant="h3">
                                          {inboxNotifications?.attributes?.title}
                                        </Typography>
                                        <Typography color="secondary" variant="h3">
                                          <ReactTimeAgo date={inboxNotifications?.attributes?.updated_at ?? "2022-11-25T12:29:09.630+05:30"} locale="en-US" />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Typography variant="subtitle">
                                      {inboxNotifications?.attributes?.message}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                            :
                            (<>
                              <Typography>Inbox Are Empty</Typography>
                            </>)}
                        </TabPanel>
                      </>
                    </Grid>
                  </Grid>
                </MenuItem>
              </Menu>
            </div >
            <div className="flex items-center">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={this.handleProfile}
                color="inherit"
                style={{ padding: "0", marginRight: "0" }}
              >
                <PersonIcon color="secondary" />
              </IconButton>
              {this.state.anchorPEl && (
                <ArrowDropUpIcon
                  className={classes.caret}
                  color="primary"
                  onClick={this.handleLanguageClick}
                />
              )}
              <Menu
                anchorEl={this.state.anchorPEl}
                open={Boolean(this.state.anchorPEl)}
                onClose={this.handlePClose}
              >
                <NavLink
                  to={`/dashboard`}
                  activeStyle={{
                    fontWeight: 700,
                    color: "white",
                  }}
                  className={classes.hoverLink}
                >
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    onClick={this.handlePClose}
                  >
                    <Typography variant="subtitle1"> Dashboard</Typography>
                  </MenuItem>
                </NavLink>
                <MenuItem
                  classes={{
                    root: classes.menuItemRoot,
                    selected: classes.menuItemSelected,
                  }}
                  onClick={() => {
                    this.props.showLoader();
                    localStorage.clear();
                    sessionStorage.clear();
                    localStorage.setItem("expertForm", false);
                    window.location.href = "/";
                  }}
                >
                  <Typography variant="subtitle1"> Logout</Typography>
                </MenuItem>
              </Menu>
            </div>
          </>
        ) : (
          <div className="flex items-center">
            <Button
              onClick={this.openDialog.bind(this)}
              color="secondary"
              style={{ backgroundColor: "transparent", padding: "0px" }}
            >
              <Typography variant="subtitle1">{t("SignIn")} </Typography>
            </Button>
          </div>
        )
        }
        <div className="flex items-center">
          <img
            onClick={this.handleLanguageClick}
            className={classes.language}
            src={language}
          />
          <Menu
            anchorEl={this.state.anchorLangEl}
            open={Boolean(this.state.anchorLangEl)}
            onClose={this.handleClose}
          >
            {" "}
            {this.state.appLangList &&
              this.state.appLangList.map((lang: any, index: any) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.menuItemRoot,
                    selected: classes.menuItemSelected,
                  }}
                  onClick={() => this.changeLanguage(lang)}
                >
                  {t(lang.attributes.language_code)}
                </MenuItem>
              ))}
          </Menu>
        </div>
      </>
    );
  };
  handleTopDrawerOpen = () =>
    this.setState((prevState) => ({ ...prevState, topDrawerOpen: true }));
  handleTopDrawerClose = () =>
    this.setState((prevState) => ({
      ...prevState,
      topDrawerOpen: !prevState.topDrawerOpen,
    }));
  render() {
    //@ts-ignore
    const { classes, t, i18n } = this.props;
    return (
      <>
        <Container
          maxWidth="xl"
          style={
            this.props.location.pathname === "/home/activityfeed"
              ? {}
              : { padding: "0px" }
          }
        >
          <AppBar
            position="static"
            color="transparent"
            style={
              this.props.location.pathname === "/home/activityfeed"
                ? { backgroundColor: "transparent", boxShadow: "none" }
                : {
                  backgroundColor: "#3D2F2F",
                  boxShadow: "none",
                  height: "6rem",
                }
            }
          >
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Grid item xs={2}>
                  <NavLink
                    to={`/home`}
                    activeStyle={{ fontWeight: 700, color: "white" }}
                    className={classes.hoverLink}
                  >
                    <img
                      height="55px"
                      className="m-auto relative top-4 "
                      width="68px"
                      src={logo}
                    />
                  </NavLink>
                </Grid>
                {this.state.mobileView ? (
                  <Grid item xs={1}>
                    <Grid container alignItems="flex-start">
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-haspopup={true}
                        onClick={this.handleTopDrawerOpen}
                        className="z-10"
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor="right"
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        open={this.state.topDrawerOpen}
                        onClose={this.handleTopDrawerClose}
                      >
                        {this.renderDrawer()}
                      </Drawer>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={10} className={classes.headerAlign}>
                    <div className="flex items-end justify-between w-full">
                      {this.renderDrawer()}
                    </div>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        </Container>
        {!localStorage.getItem("token") && (
          <EmailAccountLoginBlock
            ParentCallback={this.state.open}
            openDialog={this.openDialog}
          />
        )}
      </>
    );
  }
}
// @ts-ignore
const HeaderWebWithRouter = withRouter(HeaderWeb);
const HeaderWebWithLoader = withLoader(HeaderWebWithRouter);
const HeaderWebWithStyle = withStyles((theme) =>
  createStyles({
    uploadBtn: {
      textAlign: "center",
      height: "1rem",
      backgroundColor: "#f48b1b",
      borderRadius: "6px",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#f48b1b",
        color: "#ffffff",
        borderRadius: "6px",
      },
    },
    notificationText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "8rem",
    },
    tabMenu: {
      borderRadius: "20px !important",
      backdropFilter: "blur(50px) !important",
      marginTop: "3.7rem !important",
      width: "400px !important",
      overflowY: "hidden",
    },
    hoverLink: {
      "&:hover": {
        fontWeight: 700,
      },
    },
    drawerPaper: {
      width: "230px",
      opacity: "0.87",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#2b2b2b",
      padding: theme.spacing(1),
      justifyContent: "space-between",
    },
    menuItemRoot: {
      "&:hover": {
        color: "#ffffff",
        borderLeft: "2px solid #f9593a !important",
      },
      "&:focus": {
        color: "#ffffff",
        borderLeft: "2px solid #f9593a !important",
      },
    },
    menuTabItemRoot: {
      borderRadius: "20px",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      "&:hover": {
        color: "#ffffff",
      },
      "&:focus": {
        color: "#ffffff",
      },
    },
    menuItemSelected: {
      "&:hover": {
        color: "#ffffff",
        borderLeft: "2px solid #f9593a !important",
      },
      "&:focus": {
        color: "#ffffff",
        borderLeft: "2px solid #f9593a !important",
      },
    },
    toolbar: {
      minHeight: "62px",
    },
    caret: {
      [theme.breakpoints.down("sm")]: { top: "5.5rem" },
      top: "3.3rem",
      height: "40px",
      position: "absolute",
      width: "40px",
      color: "black",
      opacity: ".8",
    },
    language: {
      height: ".9rem",
      marginBottom: "0.2rem",
    },
    headrAlign: {
      position: "relative",
      top: "12px",
    },
    headerContainer: {
      padding: "0px",
    },
    menuactive: {
      fontWeight: 700,
      color: "white",
    },
    contentProviderGrid: {
      marginTop: "0.6rem",
      marginBottom: "0.6rem",
      color: "#000",
    },
    contentProviderTxt: {
      marginLeft: "0.5rem",
    },
    notificationItem: {
      overflow: "auto",
      height: "10rem",
    },
    notificationManage: {
      direction: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    contentTitleTxt: {
      fontSize: "0.8rem",
      fontWeight: "600",
    },
    notifTime: {
      textAlign: "end",
    },
    notificationData: {
      borderLeft: "0px",
      borderWidth: "2px",
      borderRight: "0px",
      borderColor: "#cdcdcd",
      borderTop: "0px",
      marginBottom: "1rem",
      direction: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
)(HeaderWebWithLoader);
export default withTranslation()(HeaderWebWithStyle);