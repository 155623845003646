const colors = require('./assets/styles/colors');
import MontserratBold from './fonts/Montserrat-Bold.ttf';
import MontserratRegular from './fonts/Montserrat-Bold.ttf';
import MontserratSemiBold from './fonts/Montserrat-SemiBold.ttf';
import MontserratLight from './fonts/Montserrat-Bold.ttf';
const Montserrat_Bold = {
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: '#000000',
  src: `
      local('Montserrat-Bold'),
      url(${MontserratBold}) format('ttf')
    `
};
const Montserrat_Regular = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#000000',
  src: `
      local('Montserrat-Regular'),
      url(${MontserratRegular}) format('ttf')
  `
};
const Montserrat_Light = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#000000',
  src: `
        local('Montserrat-Light'),
        url(${MontserratLight}) format('ttf')
    `
};
const Montserrat_SemiBold = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#000000',
  src: `
    local('Montserrat-SemiBold'),
    url(${MontserratSemiBold}) format('ttf')
`
};

import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2b2b2b',
      light:'#7764db',
      dark:'#f48b1b'
    },
    secondary:{
      main:'#ffffff'
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 700,
      color: '#ffffff',
      fontFamily: 'Montserrat-Bold'
    },
    h2: {
      fontSize: 30,
      fontWeight: 700,
      color: '#ffffff',
      fontFamily: 'Montserrat-Bold'
    },
    h3: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Montserrat-SemiBold'
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
      fontFamily: 'Montserrat-Bold'
    },
    h5: {
      fontSize: 37,
      fontWeight: 500,
      color: '#ffffff',
      fontFamily: 'Montserrat-Bold'
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Montserrat-Regular'
    },
    caption: {
      fontSize: 13,
      fontWeight: 100,
      fontFamily: 'Montserrat-Regular'
    },
    body1: {
      fontSize: 10,
      fontWeight: 100,
      fontFamily: 'Montserrat-Regular'
    },
    body2: {
      fontSize: 13,
      fontWeight: 300,
      fontFamily: 'Montserrat-SemiBold'
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 200,
      fontFamily: 'Montserrat-Regular'
    },
    allVariants: {
      // fontFamily: 'Montserrat Montserrat-Bold Montserrat-Regular',
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          Montserrat_Bold,
          Montserrat_Regular,
          Montserrat_Light,
          Montserrat_SemiBold
        ]
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#ffffff',
        fontFamily: 'Montserrat-Regular'
      },
      input:{
        color: '#ffffff',
        fontFamily: 'Montserrat-Regular'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '16px 10px',
        '&::placeholder': {
          color: '#000'
        }
      },
      notchedOutline: {
        borderColor: '#e1e1e1'
      }
    },
    MuiFormLabel: {
      root: {
        opacity: 1,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '10px',
        color: '#ffffff',
        fontFamily: 'Montserrat-Regular !important'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
        fontWeight: 400,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#e1e1e1',
        fontFamily: 'Montserrat-Regular'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#f44336 !important',
        fontFamily: 'Montserrat-Regular',
        textAlign: 'right'
      }
    },
    MuiChip: {
      root: {
        fontSize: '10px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
        fontFamily: 'Montserrat-Regular'
      }
    },
    MuiFormControl: {
      root: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#e1e1e1',
        fontFamily: 'Montserrat-Regular!important',
        width: '100%'
      }
    },
    MuiFormControlLabel: {
      root: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#e1e1e1',
        fontFamily: 'Montserrat-Regular!important'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'initial'
      },
      text: {
        '&:hover': {
          backgroundImage: 'none',
          backgroundColor:'white'
        }
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0
      }
    },
    MuiStepIcon: {
      root: {
          color: '#9d9d9d',
          height:'30px',
          width:'30px',
          '&$active': {
              color: '#7764db',
          },
          '&$completed': {
              color: '#7764db',
          },
      },
  },
  MuiStepConnector:{
    alternativeLabel:{
      left: "calc(-50% + 7px)",
      right: "calc(50% + 7px)"
    }
  },
  MuiStepConnector:{
    lineHorizontal:{
      borderTopWidth: '3px',
      borderColor:'#f48b1b'
    }
  }
  }
});
export default theme;
