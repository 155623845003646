//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, createStyles, Typography,
  Card, CardActionArea, CardContent
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import {
  Props,
} from "./CategoriesViewallController.web";
import ImageIcon from '@material-ui/icons/Image';
import Pagination from '@material-ui/lab/Pagination';
//@ts-ignore
import insane from 'insane';
//@ts-ignore
import * as AudioPlayer from 'react-responsive-audio-player'
import { epub } from "./assets";
import { categoryArticalList } from './CategoriesCommonutility'

const Title = ({ classes, featureArticle, match, contentTypeInfo }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi} style={{ marginTop: '8px', marginBottom: '15px' }}>
      {featureArticle && featureArticle.length > 0 &&
        <Typography align="center" className={classes.title} variant="h4" >
          {match.params.heading == 'featured' ? 'Featured' : ''}
          {match.params.heading == 'recommended' ? 'Recommended' : ''}
          {match.params.heading == 'trending' ? 'Trending' : ''}
          {' '} {contentTypeInfo[match?.params?.type]?.title == 'Article' ? 'Articles' : (contentTypeInfo[match?.params?.type]?.title)}
        </Typography>
      }
    </Grid>
  );
};
const PodcastContentType = ({ classes, match, e }) => {
  return (
    <Grid item xs={12}>
      <Card className={classes.rootpodcast}>
        <CardActionArea>
          <CardContent style={{ backgroundColor: '#060606' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ color: '#fff' }} >
                  {e.attributes.name}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ color: '#fff' }} >
                {e.attributes.audio ? (<AudioPlayer playlist={[{
                  url: e.attributes.audio,
                  title: ''
                }]} controls={['playpause', 'progressdisplay']} />) : (<AudioPlayer playlist={[{
                  url: 'https://parse-server-ff.s3.amazonaws.com/ae5992f0f5bb1f259bafa41b3771e3bb_call12565815456dwwwwww795896232www-01b59bd3.mp3',
                  title: ''
                }]} controls={['playpause', 'progressdisplay']} />)}

              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            {e.attributes.description.length > 300 ?
              <div className="mt-1.5">
                <Typography variant="subtitle2" color="primary" >
                  <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                    __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/" + match.params.type + "/" + e.id + "'>[Read more]</a></button>")
                  }}></span>
                </Typography></div>
              : (<div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                <span dangerouslySetInnerHTML={{
                  __html: insane(`${e.attributes.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 300).concat("...<button><a href='/home/articaldetails/" + match.params.type + "/" + e.id + "'>[Read more]</a></button>")
                }}></span>
              </Typography></div>)
            }
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const OtherContentType = ({ classes, match, e }) => {
  return (
    <React.Fragment>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={2} key={e.id}>
        <Grid className={classes.categoryHight}>
          {e?.attributes?.hasOwnProperty('content_type') ? (<>
            <TextAudiopodcastType {...{ e }} />
            <Epub {...{ e }} />
            <Videos {...{ e }} />
          </>
          ) :
            (<>
              <AudioPodCasts {...{ e }} />
              <Epubs {...{ e }} />
              <LiveStream {...{ classes, e }} />
            </>)}
        </Grid>
      </Grid>
      <ContentDetails {...{ classes, e, match }} />
    </React.Fragment>
  )
};

const AudioPodCasts = ({ e }) => {
  return (
    <>
      {(e?.type == "audio_podcast") && (<>
        {e?.attributes?.image ? (
          <img alt="article" className="rounded-lg w-48 h-full" src={e?.attributes?.image} style={{ borderRadius: '20px' }} />
        ) : (
          <ImageIcon className="rounded-lg h-full w-48" color="primary" />
        )}
      </>
      )}
    </>
  )
}
const Epubs = ({ e }) => {
  return (
    <>
      {e?.type == "epub" &&
        (<>
          {e?.attributes?.files && e?.attributes?.files?.length > 0 ? (
            <img alt="article" className="rounded-lg w-48 h-full" src={e?.attributes?.thumbnail} style={{ borderRadius: '20px' }} />
          ) : (<ImageIcon className="rounded-lg w-48" color="primary" />)}
        </>)}
    </>
  )
}

const ContentDetails = ({ classes, e, match }) => {
  return (
    <Grid item xs={6} sm={6} md={9} lg={9} xl={10}>
      <Grid container direction="column" className={classes.categoryHight1}>
        <Grid item>
          <Typography variant="h3" className={classes.categoryHeading}>{e.attributes.name}</Typography>
        </Grid>
        <Grid item>
          {e?.attributes?.description.length > 300 ?
            <div className="mt-1.5">
              <Typography variant="subtitle2" color="primary" >
                <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                  __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href= ${match?.params?.type == 'blogs' ? '/home/blogsdetails/' + e?.id : '/home/articaldetails/' + match?.params?.type + '/' + e?.id}>[Read more1]</a></button>`)
                }}></span>
              </Typography></div>
            : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
              <span dangerouslySetInnerHTML={{
                __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href= ${match?.params?.type == 'blogs' ? '/home/blogsdetails/' + e?.id : '/home/articaldetails/' + match?.params?.type + '/' + e?.id}>[Read more1]</a></button>`)
              }}></span>
            </Typography></div>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

const TextAudiopodcastType = ({ e }) => {
  return (<>
    {(e?.attributes?.content_type?.type == "Text" || e?.attributes?.content_type?.type == "AudioPodcast") &&
      (<>
        {e?.attributes?.image ? (
          <img alt="article" className="rounded-lg w-48 h-full" src={e?.attributes?.image} style={{ borderRadius: '20px' }} />
        ) : (
          <ImageIcon className="rounded-lg h-full w-48" color="primary" />
        )}
      </>)}
  </>
  )
}
const Epub = ({ e }) => {
  return (
    <>
      {e?.attributes?.content_type?.type == "Epub" &&
        (<>
          <img alt="article" className="rounded-lg w-48 h-full" src={epub} style={{ borderRadius: '20px' }} />
        </>)}
    </>)
}
const Videos = ({ e }) => {
  return (
    <>
      {e?.attributes?.content_type.type == "Videos" && (<>
        {e?.attributes?.video ? (
          <video className="rounded-lg w-48 h-full" controls >
            <source src={e?.attributes?.video} type="video/mp4" /></video>
        ) : (
          <ImageIcon className="rounded-lg w-48" color="primary" />
        )}
      </>
      )}
    </>

  )
}

export class Category extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, featureArticle, match, totalPages, pageNo, contentTypeInfo } = this.props;
    return (
      <Grid container direction="column" alignItems="center" justify="flex-start">
        <Grid container direction="row" alignItems="center" spacing={4}>
          <Title data-testId="Title" {...{ classes, featureArticle, match, contentTypeInfo }} />
          <br />
          <br />
          {featureArticle && featureArticle.length > 0 ? featureArticle.map((e: any) => (
            <React.Fragment key={'f_' + e.id}>
              {match.params.type == 'podcast' ? (
                <React.Fragment>
                  <PodcastContentType data-testId="PodcastContentType" {...{ classes, match, e }} />
                </React.Fragment>) :
                (<OtherContentType data-testId="OtherContentType"  {...{ classes, match, e }} />
                )}
            </React.Fragment>
          )) : null
          }
        </Grid>
        {
          featureArticle && featureArticle.length == 0 &&
          <Grid item>
            <Typography variant="body2">No results to display, Refine your search</Typography>
          </Grid>
        }
        {featureArticle && featureArticle.length > 0 && totalPages > 1 &&
          <Grid container direction="row" className={classes.paginator} alignItems="center" spacing={4}>
            <Pagination count={totalPages} page={pageNo} variant="outlined" data-testId="setPage" onChange={(event, val) => this.props.setPage(val)} shape="rounded" />
          </Grid>
        }
      </Grid>
    )
  }
}
//@ts-ignore
const CategoryWebWithRouter = withRouter(Category);
export default withStyles((theme) =>
  createStyles({
    ...categoryArticalList(theme),
    title: {
      paddingLeft: "16px",
      color: '#f48b1b',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    }

  })
)(CategoryWebWithRouter);