//@ts-nocheck
//@ts-ignore
import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  View
} from "react-native";
import Banner from "../../../components/src/Banner";
import CustomLoader from "../../../components/src/CustomLoader";
import StickyHeaderForExplores from "../../../components/src/StickyHeaderForExplores";
import ThemeColorContext from "../../../components/src/themeColorManagement/themeColorContext";

// Customizable Area End

import CommunityForumController, {
  Props} from "./CommunityForumController";
import SchoolsTab from "./schools/Schools";
import Colleges from "./colleges/Colleges";
import Tabs from "./Tabs/Tabs";
import { Schools } from "./constants";
import LocationController from "./components/Helper/LocationController";
import GlobalTags from "./components/globalTag/GlobalTags";
import { Nerolightcolor } from "./components/Helper/common";





export default class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getProfilePic = ()  => {
    if(this.state.userProfilePic){ //Istanbul ignore next
      return this.state.userProfilePic 
    }else { //Istanbul ignore next
      return this.state.profilePicFromGmail 
    }
  }

  getSchoolCollegeType = () => {
    if(this.state.activeTab === "Schools") //Istanbul ignore next
    {
      return "School"
    }else //Istanbul ignore next
    {
      return "College"
    }
  }

  getFilterHighlighter =  () =>{
    if(this.state.globalTag?.length > 0)//Istanbul ignore next
    {
      return true
    }else //Istanbul ignore next
    {
      return false
    }
  }

  // Customizable Area Start
  // Customizable Area End

scrollView=(themeColorContext)=>{
  return(
    <>
     <StickyHeaderForExplores
     testID="myprofileList"
                paddingForSearch={5}
                title={"Schools & Colleges"}
                dofilter
                isFromExplore={false}
                gobackArrow={false}
                searchText={(text) => { }}
                userProfilePic={this.getProfilePic()}
                NavigateToMyprofileList={() =>
                  this.props.navigation.navigate("MyProfileList")

                }
                navigateToFilter={() => {
                  this.setGlobalFilterData();
                  this.state.activeTab === Schools ?
                    this.props.navigation.navigate("FilterSchoolsAndColleges") :
                    this.props.navigation.navigate("CollegeFilters")

                }}
                NavigateToSearchScreen={() => {    
                  this.props.navigation.navigate("SchoolsCollegesSearch",{type:this.getSchoolCollegeType()})
                }}

                tab={this.state.activeTab}
                filterHighligter={this.getFilterHighlighter()}
                navigateFrombackArrow={() => { }}
                isFromFilterScreen={this.getFilterHighlighter()}
              />
              <TouchableWithoutFeedback
                onPress={() => {
                  this.hideKeyboard();
                }}
              >
                {/* Customizable Area Start */}
                {/* Merge Engine UI Engine Code */}
                <>
                  {this.state.bannerData?.length > 0 &&
                    <Banner bannerData={this.state.bannerData} />
                  }

                  <View style={{marginTop:-20}}>
                    <Tabs
                      activeTheme={themeColorContext.themeColor}
                      activeTab={this.state.activeTab}
                      onTabChanged={(changedTabValue: any) => {
                        this.setState({ activeTab: changedTabValue },()=>{
                          let gtag = new GlobalTags();
                          if(changedTabValue === Schools){
                            
                            this.updateSchoolsTab(gtag);
                          
                          }else{
                          this.updateCollegesTab(gtag)
                          }
                        })
                      }}
                    />
                  </View>
                  {
                    this.state.activeTab == Schools ?
                      <SchoolsTab
                        featuredSchools={this.state.featuredSchools}       //featured schools
                        popularSchools={this.state.popularSchools}
                        searchSchools={this.state.searchSchools}
                        activeTab={this.state.activeTab}
                        globalTag={this.state.globalTag}
                        locations={this.state.location}                   // Top 10 location from API
                        selectedLocation={this.state.selectedLocations}   // Selected Locations by User
                        onLocationChanged={(id,type, name) => {               //on Top 10 location changed callback
                          
                          let controller = new LocationController();
                          if(type === 'location'){
                            this.addSelectedLocation(controller.addSelectedLocation(id, this.state.selectedLocations));
                            
                        
                          }
                          const globalTag = new GlobalTags();
                        
                          let theGlobalTag = globalTag.setLocationTags(this.state.globalTag, { id: id, name: name, type: type });
                          this.setState({ globalTag: theGlobalTag }, () => {
                            console.log('GLOBALTAG:', this.state.globalTag);
                            this.setGlobalFilterData();
                          });
                          this.addSelectedTags(controller.handleSelectionMultiple(id, name, this.state.tagsList));
                        }}
                        navigation={(type) => {
                          this.setGlobalFilterData();
                          this.props.navigation.navigate('ByLocationPage', { tagsList: this.state.globalTag, type: type, activeTab: this.state.activeTab })
                        }}
                      />
                      :
                      <Colleges
                        featuredColleges={this.state.featuredColleges}
                        popularColleges={this.state.popularColleges}
                        searchCollage={this.state.searchCollage}
                        activeTab={this.state.activeTab}
                        CategoriesData={this.state.categories}
                        globalTag={this.state.globalTag}
                        selectedCategories={this.state.selectedCategories}   // Selected Locations by User
                        onCategoriesChanged={(id, name) => {                   //on Categories changed callback
                          this.addSelectedCategory(id)
                          const globalTag = new GlobalTags();
                          let theGlobalTag = globalTag.setCategoryTag(this.state.globalTag, { id: id, name: name, type: 'specialization' });
                          this.setState({ globalTag: theGlobalTag }, () => {
                            this.setGlobalFilterData();
                          });
                        }}
                        navigation={(type) => {
                          console.log('GLOBALTAGLIST', this.state.globalTag);

                          this.setGlobalFilterData();
                          this.props.navigation.navigate('ByLocationPage', { tagsList: this.state.globalTag, type: type, activeTab: this.state.activeTab })
                        }}
                      />
                  }

                
                </>
                {/* Merge Engine UI Engine Code */}
                {/* Customizable Area End */}
              </TouchableWithoutFeedback >
  </>
  )
}

  render() {
    return (

      //Merge Engine DefaultContainer
      <ThemeColorContext.Consumer>
        {(themeColorContext) => (
          <>
            {this.state.isLoading ? <CustomLoader /> : null}
            <ScrollView
              contentContainerStyle={{ paddingBottom: 80, }}
              keyboardShouldPersistTaps="always"
              style={[
                styles.container, {
                  backgroundColor: Nerolightcolor(themeColorContext)
                }
              ]}
            >
             {this.scrollView(themeColorContext)}
            </ScrollView >
          </>
        )}
      </ThemeColorContext.Consumer >

      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
