import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
//@ts-ignore
import i18n from "i18next";
//@ts-ignore
import { Trans } from "react-i18next";
export type Props = {
  id: string;
  navigation: any;
  match: any;
  ParentCallback: any;
  openDialog: any;
  label: string;
  data: SubCategories[];
  // Customizable Area Start
  // Customizable Area End
};
interface Categories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}
interface SubCategories {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string;
}
interface Category {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    sub_categories: SubCategories[];
  };
}
interface SubCategory {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    categories: Categories[];
    sub_categories: SubCategories[];
  };
}
interface S {
  // Customizable Area Start
  categoriesData: Category[];
  subCategoriesData: SubCategory[];
  selectedCategory: any;
  showCategoryData: Category;
  showSubCategoryData: Category;
  anchorEl: any;
  appLangList: [];
  anchorLangEl: any;
  open: boolean;
  mobileView: boolean;
  topDrawerOpen: boolean;
  subMenuOpen: boolean;
  anchorPEl: boolean;
  anchorNEl: boolean;
  nestedMenuIndex: number;
  tabValue: number;
  anchorschoolCollegeEl: any;
  notifications: any;
  inboxNotifications: any;
  allUserStatus: any;
  allExpertStatus: any;
  allUserSubject: any;
  dialogOpen: boolean;
  anchorSubEl: boolean;
  currentCategory: null;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class HeaderWebController extends BlockComponent<Props, S, SS> {
  getCategoriesApiCallId: string = "";
  getSubCategoriesApiCallId: string = "";
  showCategoryApiCallId: string = "";
  showSubCategoryApiCallId: string = "";
  selectLangugeApiCallId: string = "";
  getNotificationsApiCallId: string = "";
  getInboxNotificationsApiCallId: string = "";
  getAllUserStatusApiCallId: string = "";
  getAllExpertStatusApiCallId: string = "";
  getAllUserSubjectApiCallId: string = "";
  onSubmitApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      categoriesData: [],
      subCategoriesData: [],
      selectedCategory: null,
      showCategoryData: {
        id: "",
        type: "",
        attributes: {
          id: "",
          name: "",
          created_at: "",
          updated_at: "",
          sub_categories: [],
        },
      },
      showSubCategoryData: {
        id: "",
        type: "",
        attributes: {
          id: "",
          name: "",
          created_at: "",
          updated_at: "",
          sub_categories: [],
        },
      },
      anchorEl: null,
      anchorLangEl: null,
      appLangList: [],
      open: false,
      mobileView: false,
      topDrawerOpen: false,
      subMenuOpen: false,
      anchorPEl: false,
      anchorNEl: false,
      nestedMenuIndex: 0,
      tabValue: 0,
      anchorschoolCollegeEl: null,
      notifications: [],
      inboxNotifications: [],
      allUserStatus: [],
      allExpertStatus: [],
      allUserSubject: [],
      dialogOpen: false,
      anchorSubEl: false,
      currentCategory: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        if (responseJson?.data && responseJson !== undefined) {
          if (apiRequestCallId === this.getCategoriesApiCallId) {
            this.setState({ categoriesData: responseJson.data });
            localStorage.setItem(
              "categories",
              JSON.stringify(this.state.categoriesData)
            );
          } else if (apiRequestCallId === this.selectLangugeApiCallId) {
            this.setState({ appLangList: responseJson?.data });
          } else if (apiRequestCallId === this.getNotificationsApiCallId) {
            this.setState({ notifications: responseJson?.data });
          } else if (apiRequestCallId === this.getInboxNotificationsApiCallId) {
            this.setState({ inboxNotifications: responseJson?.data });
          } else if (apiRequestCallId === this.getAllUserSubjectApiCallId) {
            this.setState({ allUserSubject: responseJson?.data });
          } else if (apiRequestCallId === this.getAllExpertStatusApiCallId) {
            this.setState({ allExpertStatus: responseJson?.data });
          } else if (apiRequestCallId === this.getAllUserStatusApiCallId) {
            this.setState({ allUserStatus: responseJson?.data });
          }
        } else if (responseJson.errors && responseJson.errors[0].token) {
          console.log(responseJson);
        }
      } else {
        if (responseJson) {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }
  parseCategoryData = (data: any) => {
    const newCategory: any[] = [];
    for (let i = 0; i < data.length; i++) {
      const category = data[i];
      for (let j = 0; j < category?.attributes?.sub_categories.length; j++) {
        if (category.attributes.sub_categories) {
          category.attributes.sub_categories[j].parent_id = category.id;
        }
      }
      newCategory.push(category);
    }
    return newCategory;
  };
  //get App language
  getAppLanguage = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.selectLangugeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectLanguage
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  changeLanguage = (code: any) => {
    localStorage.setItem("lang", code.attributes.language_code);
    i18n.changeLanguage(code.attributes.language_code);
  };
  // Get All Categories
  getAllCategories = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const apiEndPoint = `${configJSON.categoriesAPIEndPoint}`;
    const methodType = configJSON.httpGetType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Desktop Notifications
  getNotifications = async () => {
    const token = localStorage.getItem("token");
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Notifications
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getInboxNotifications = async (id: any) => {
    const token = localStorage.getItem("token");
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInboxNotificationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.Notifications}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // carrer expert forum APis
  getAllUserSubject = () => {
    const token = localStorage.getItem("token");
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUserSubjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestExperts}/all_user_subject`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onSubmit = (payloaddata: any) => {
    const token = localStorage.getItem("token");
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) {
      headers["token"] = token;
    }
    const data = {
      ...payloaddata,
    };
    const apiEndPoint = `${configJSON.getRequestExperts}`;
    const methodType = configJSON.httpPostType;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onSubmitApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getAllUserStatus = () => {
    const token = localStorage.getItem("token");
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUserStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestExperts}/all_user_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllExpertStatus = () => {
    const token = localStorage.getItem("token");
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllExpertStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestExperts}/all_expert_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
