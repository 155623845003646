//@ts-nocheck
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import VerifyOtp from "../../otp-input-confirmation/src/OTPInputAuth.web";
import ConfirmOtp from "../../otp-input-confirmation/src/ConfirmOtp.web";
import Categoriessubcategories from "../../categoriessubcategories/src/Categoriessubcategories.web";
import CategoryList from "../../categoriessubcategories/src/CategoryList.web";
import SubCategoryList from "../../categoriessubcategories/src/SubCategoryList.web";
import LanguageOptionWeb from "../../LanguageOptions/src/LanguageOption.web";
import FooterWeb from "../../../components/src/Footer/Footer.web";
import JobsWeb from "../../Reviews/src/JobsHome.web";
import ActivityFeedWeb from "./ActivityFeed.web";
import PushNotifications from "../../PushNotifications/src/PushNotifications.web";
import CategoriesViewallWeb from "../../categoriessubcategories/src/CategoriesViewall.web";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Cart from "../../categoriessubcategories/src/Cart.web";
import ArticaldetailsWeb from "../../categoriessubcategories/src/Articaldetails.web";
import CourseViewallWeb from "../../categoriessubcategories/src/CourseViewAll.web";
import SearchWeb from "../../Search/src/Search.web";
import HeaderWeb from "../../../components/src/Header/Header.web";

import ShowSearchdata from "../../Search/src/ShowSearchdata.web";
import CoursesDetailPage from "../../categoriessubcategories/src/CoursesDetailPage.web";
import ArticleviewWeb from "../../categoriessubcategories/src/Articleview.web";
import ExamWeb from "../../Referrals/src/Exam.web";
import BlogPostsManagementWeb from "../../BlogPostsManagement/src/BlogPostsManagement.web";
import CoursesPageWeb from "../../categoriessubcategories/src/CoursesPage.web";
import Schools from "../../ContentManagement/src/Schools.web";
import SchoolsSearch from "../../ContentManagement/src/Schoolsearch.web";
import College from "../../ContentManagement/src/College.web";
import CollegeSearch from "../../ContentManagement/src/CollegeSearch.web";
import QuizWeb from "./Quiz.web";
import QuizViewAllWeb from "./QuizViewAll.web";
import QuizCategoryViewAllWeb from "./QuizCategoryViewAll.web";
import CareerExpertWeb from "../../ContentManagement/src/CareerExpert.web";
import CareerExpertViewAllWeb from "../../ContentManagement/src/CareerExpertViewAll.web";
import CareerExpertProfileWeb from "../../ContentManagement/src/CareerExpertProfile.web";
import CareerExpertArticleWeb from "../../ContentManagement/src/CareerExpertArticle.web";
import JobsViewAll from "../../Reviews/src/JobsViewAll.web";
import CompaniesViewAll from "../../Reviews/src/CompaniesViewAll.web";
import JobsDetailsViewAllWeb from "../../Reviews/src/JobsDetailsViewAll.web";
import JobDetailsViewWeb from "../../Reviews/src/JobDetailsView.web";
import ExamlistWeb from "../../Referrals/src/Examlist.web";
import ExamViewallWeb from "../../Referrals/src/ExamViewall.web";
import BlogDetails from "../../BlogPostsManagement/src/BlogDetails.web";
import BlogProfileWeb from "../../BlogPostsManagement/src/BlogProfile.web";
import AssessmentWeb from "./Assessment.web";
import AssessmentViewAllWeb from "./AssessmentViewAll.web";
import AssessmentTestWeb from "./AssessmentTest.web";
import ContactUs from "../../Referrals/src/Contactus.web";
import AboutUsWeb from "../../Reviews/src/AboutUs.web";
import BookNowWeb from "../../ContentManagement/src/BookNow.web";
import CarrerExpertRatingWeb from "../../ContentManagement/src/CarrerExpertRating.web";
import CourseRatingReview from "../../categoriessubcategories/src/CourseRatingReview.web";
import ReferEarn from "../../Referrals/src/ReferEarn.web";
import CarrerExpertArticleViewAllWeb from "../../ContentManagement/src/CarrerExpertArticleViewAll.web";
import CarrerExpertVideosViewAllWeb from "../../ContentManagement/src/CarrerExpertVideosViewAll.web";
export class HomePageContainer extends Component {
  render() {
    return (
      <>
        <HeaderWeb />
        <Switch>
          <Redirect exact to="/home/activityfeed" from="/home" />
          <Route path="/home/activityfeed" exact>
            <ActivityFeedWeb />
          </Route>
          <Route path="/home/verifyOTP" exact>
            <VerifyOtp />
          </Route>
          <Route path="/home/PushNotifications" exact>
            <PushNotifications />
          </Route>
          <Route path="/home/confirmOTP" exact>
            <ConfirmOtp />
          </Route>
          <Route path="/home/categories-subcategories" exact>
            <>
              <Categoriessubcategories />
            </>
          </Route>
          <Route path="/home/jobs" exact>
            <JobsWeb />
          </Route>
          <Route path="/home/jobs/all-jobs/:searchJobs" exact>
            <JobsViewAll />
          </Route>
          <Route path="/home/jobs/all-companies" exact>
            <CompaniesViewAll />
          </Route>
          <Route path="/home/jobs/all-jobs/company/:companyId" exact>
            <JobsDetailsViewAllWeb />
          </Route>
          <Route path="/home/jobs/all-jobs/company/:companyId/job/:jobId" exact>
            <JobDetailsViewWeb />
          </Route>
          <Route path="/home/categories/:id" exact>
            <CategoryList />
          </Route>
          <Route path="/home/categories/:id/sub-categories/:id" exact>
            <SubCategoryList />
          </Route>
          <Route path="/home/selectLanguage" exact>
            <LanguageOptionWeb />
          </Route>
          <Route path="/home/activityfeed/linkedin" component={LinkedInPopUp} />
          <Route path="/home/articaldetails/:type/:id" exact>
            <ArticaldetailsWeb />
          </Route>
          <Route path="/home/categoryviewall/:type/:heading/:categoryId" exact>
            <CategoriesViewallWeb />
          </Route>
          <Route path="/home/courseviewall/:categoryId">
            <CourseViewallWeb />
          </Route>
          <Route path="/home/coursedetail">
            <CoursesDetailPage />
          </Route>
          <Route path="/home/checkout">
            <Cart />
          </Route>
          <Route path="/home/courseratereview">
            <CourseRatingReview />
          </Route>
          <Route path="/home/search" exact>
            <SearchWeb />
          </Route>
          <Route path="/home/searchdata/:searchinfo" exact>
            <ShowSearchdata />
          </Route>
          <Route path="/home/articleview/:type/:categoryId" exact>
            <ArticleviewWeb />
          </Route>
          <Route path="/home/articleview/:type/:categoryId/:subCategoryId" exact>
            <ArticleviewWeb />
          </Route>
          <Route path="/home/exam/:id" exact>
            <ExamWeb />
          </Route>
          <Route path="/home/examlist" exact>
            <ExamlistWeb />
          </Route>
          <Route path="/home/examviewall/:id" exact>
            <ExamViewallWeb />
          </Route>
          <Route path="/home/blogs" exact>
            <BlogPostsManagementWeb />
          </Route>
          <Route path="/home/blogsdetails/:id" exact>
            <BlogDetails />
          </Route>
          <Route path="/home/blogprofile/:id/:blogId" exact>
            <BlogProfileWeb />
          </Route>
          <Route path="/home/courses" exact>
            <CoursesPageWeb />
          </Route>
          <Route path="/home/quiz/:quizId" exact>
            <QuizWeb />
          </Route>
          <Route path="/home/quizviewall/:categoryId" exact>
            <QuizViewAllWeb />
          </Route>
          <Route path="/home/quizcategoryviewall/:heading/:categoryId" exact>
            <QuizCategoryViewAllWeb />
          </Route>
          <Route path="/home/careerexpert" exact>
            <CareerExpertWeb />
          </Route>
          <Route path="/home/careerexpert/viewall" exact>
            <CareerExpertViewAllWeb />
          </Route>
          <Route path="/home/careerexpert/:expertId" exact>
            <CareerExpertProfileWeb />
          </Route>
          <Route
            path="/home/careerexpert/expert/:expertId/article/:articleId"
            exact
          >
            <CareerExpertArticleWeb />
          </Route>
          <Route path="/home/schools">
            <Schools />
          </Route>
          <Route path="/home/schoolssearch">
            <SchoolsSearch />
          </Route>
          <Route path="/home/college">
            <College />
          </Route>
          <Route path="/home/collegesearch">
            <CollegeSearch />
          </Route>
          <Route path="/home/assessments/:categoryId">
            <AssessmentWeb />
          </Route>
          <Route path="/home/assessmentsviewall/:heading/:categoryId">
            <AssessmentViewAllWeb />
          </Route>
          <Route path="/home/assessment/:assessmentId">
            <AssessmentTestWeb />
          </Route>
          <Route path="/home/contactus">
            <ContactUs />
          </Route>
          <Route path="/home/aboutus">
            <AboutUsWeb />
          </Route>
          <Route path="/home/booknow">
            <BookNowWeb />
          </Route>
          <Route path="/home/carrerexpert/rating_review">
            <CarrerExpertRatingWeb />
          </Route>
          <Route path="/home/refer">
            <ReferEarn />
          </Route>
          {/* carer expert view All Routing section start */}
          <Route path="/home/carrerexpert/articlesviewall">
            <CarrerExpertArticleViewAllWeb />
          </Route>
          <Route path="/home/carrerexpert/expertArticlesviewall">
            <CarrerExpertArticleViewAllWeb />
          </Route>
          <Route path="/home/carrerexpert/blogsviewall">
            <CarrerExpertArticleViewAllWeb />
          </Route>
          <Route path="/home/carrerexpert/expertBlogsviewall">
            <CarrerExpertArticleViewAllWeb />
          </Route>
          <Route path="/home/carrerexpert/expertpopularBlogsviewall">
            <CarrerExpertArticleViewAllWeb />
          </Route>
          <Route path="/home/carrerexpert/videosviewAll">
            <CarrerExpertVideosViewAllWeb />
          </Route>
          <Route path="/home/carrerexpert/expertVideosviewall">
            <CarrerExpertVideosViewAllWeb />
          </Route>
          {/* carer expert view All Routing section end*/}
        </Switch>
        <Hidden smDown>
          {" "}
          <FooterWeb />
        </Hidden>
      </>
    );
  }
}
export default HomePageContainer;
