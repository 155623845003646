import React from "react";
import {
    Grid,
    Card,
    CardMedia,
    CardActionArea,
    CardContent,
    IconButton,
    Typography
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import StarBorderIcon from "@material-ui/icons/StarBorder";

type shortlistedCourse = {
    classes: any,
    props: any,
    _data: any,
    bookmarkButton: any
}

const showTime = (time: any) => {
    let n: any = parseInt(time);
    return `0${n / 60 ^ 0}`.slice(-2) + 'H :' + ('0' + n % 60).slice(-2) + 'M';
}

export const CourseListCardComp: React.FC<any> = ({ classes, props, _data, bookmarkButton }) => {
    return (
            <Grid item xs={12} md={4}>
                <Card className={classes.root} >
                    <CardActionArea>
                        <CardMedia data-testid="cardmedia"  onClick={() => props.history.push("/home/coursedetail", { courseDetail: _data })}
                            component={_data?.attributes?.thumbnail.toString().endsWith(".jpg") ||
                                _data?.attributes?.thumbnail.toString().endsWith(".png") ||
                                _data?.attributes?.thumbnail.toString().endsWith(".jpeg") ||
                                _data?.attributes?.thumbnail.toString().endsWith(".svg") ||
                                _data?.attributes?.thumbnail.toString().endsWith(".gif") ? "img" : "video"}
                            className={classes.suggested}
                            src={_data?.attributes?.thumbnail}
                            title={_data?.attributes?.heading}
                        />
                        <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                            <Grid item xs={4}>
                                <Typography data-testid="suggestedCardEnrolledTime" variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                                    {_data?.attributes?.enrolled_users}{""} Enrolled
                                </Typography>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
                                    {showTime(_data?.attributes?.total_time)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography
                                    variant="body2"
                                    className={classes.runningCoursesCardTitle}
                                >
                                    {_data?.attributes?.heading} {':'} </Typography>
                                <Typography variant="body2" className={classes.suggestedDetail}>{_data?.attributes?.description}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {_data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((rating: any) => {
                                    return (_data?.attributes?.user_rating >= rating ? <StarIcon key={rating} className={classes.Icons} /> : <StarBorderIcon key={rating} className={classes.Icon} />);
                                })}
                                {!_data?.attributes?.user_rating && [1, 2, 3, 4, 5].map((ratings: any) => {
                                    return (<StarBorderIcon key={ratings} className={classes.Icon} />);
                                })}
                            </Grid>
                            <Grid item xs={12}>
                                {bookmarkButton ? <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography data-testid="shortlistCardSubTitle"
                                            variant="caption"
                                            className={classes.shortlistCardSubTitle}>
                                            {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton data-testid="removebookmark" aria-label="bookmark" onClick={() => props.removeBookmark(_data?.attributes?.id)}>
                                            <BookmarkIcon color="primary" />
                                        </IconButton>
                                    </Grid>
                                </Grid> :
                                    <Typography
                                        variant="caption"
                                        className={classes.shortlistCardSubTitle}
                                    >
                                        {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                                    </Typography>}
                            </Grid>
                        </Grid>
                        <CardContent />
                    </CardActionArea>
                </Card>
            </Grid>
    )
}