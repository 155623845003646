import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { displayTimeInHoursminutes } from "./CommonFunctions"
import {addActivityFeedLog} from "../../ActivityFeed/src/ActivityFeedLogController.web";

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  loading: boolean;
  getAllBookmarked: any;
  openDeleteDialog: boolean;
  open: boolean;
}
interface SS {
  id: any;
}

export default class CourseCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  BookmarkedCourseApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,
      getAllBookmarked: [],
      openDeleteDialog: false,
      open: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let respJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (respJson && !respJson.errors) {
        switch (apiRequestCallId) {
          case this.addBookmarkApiCallId:
            this.addBookmarkApiResponce(respJson);
            break;
          case this.BookmarkedCourseApiCallId:
            this.handleBookmarkedCourseApiResponce(respJson);
            break;
          case this.removeBookmarkApiCallId:
            this.handleRemoveBookmarkApiResponce(respJson);
            break;
          default:
            break;
        }
      }
      else if (respJson && respJson?.errors) {
        this.handleApiErrorResponce(respJson);
      }
    }
  }
  addBookmarkApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.getAllBookmarkedCourses()
      this.props.openToastHandler("success", 'Bookmarked'!);
    }
    if (responseJson && responseJson?.data && responseJson?.data?.attributes?.errors) {
      this.props.openToastHandler("error", responseJson?.data?.attributes?.errors?.account_id[0]);
    }
  };

  handleRemoveBookmarkApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.success) {
      this.getAllBookmarkedCourses();
    }
  };
  handleBookmarkedCourseApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ getAllBookmarked: responseJson?.data });
    }
  };

  handleApiErrorResponce = (responseJson: any) => {
    if (responseJson && responseJson?.errors) {
      if (Array.isArray(responseJson?.errors) && responseJson?.errors[0]?.token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      else if (typeof responseJson?.errors === 'object' && responseJson?.errors?.order_courses[0]) {
        this.props.openToastHandler("error", responseJson.errors.order_courses[0]);
      }
      else {
        this.props.openToastHandler("error", responseJson.errors);
      }
    }
  }

  handleClose = () => {
    this.setState({ openDeleteDialog: false })
  }

  getAllBookmarkedCourses = () => {
    const token = localStorage.getItem('token');
    this.BookmarkedCourseApiCallId = sendAPIRequest(configJSON.getBookmarkCourseUrl,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  addBookmark = (id: any, event: any) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    if (token) {
      const headers = {
        "token": token,
        "Content-Type": configJSON.apiContentType
      }
      const data = {
        "bookmarkable_id": id,
        "bookmarkable_type": "course"
      }
      const apiEndPoint = configJSON.addBookmarkUrl;
      const method = configJSON.httpPostType;
      this.addBookmarkApiCallId = sendAPIRequest(
        apiEndPoint,
        {
          method,
          body: { ...data },
          headers: headers
        },
      );
    }
    else {
      this.props.openToastHandler("warning", 'Please login to mark thic course as bookmark'!);
    }
    addActivityFeedLog("course", "Add Bookmark", id);
  }
  
  removeBookmark = (id: any, event: any) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.apiContentType
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "course"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const method = configJSON.httpPostType;
    addActivityFeedLog("course", "Remove Bookmark", id);

    this.removeBookmarkApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...data },
        headers: headers
      },
    );
  }

  showTime = (time: any) => displayTimeInHoursminutes(time);
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }

}
