import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { displayTimeInHoursminutes } from "./CommonFunctions"

export type Props = RouterProps &
  // Customizable Area Start
  StyledProps & {
    id: string;
    navigation: any;
    match: any;
    openToastHandler: any;
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  purchased: boolean;
  boughtCourseList: any;
  courseId: any;
  courseDetail: any;
  getAllBookmarked: any;
  userDetail: any;
  openDeleteDialog: boolean;
}
interface SS {
  id: any;
}

export default class CourseRatingReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSuggestedCourseApiCallId: string = "";
  addRateReviewApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      purchased: false,
      boughtCourseList: [],
      courseId: [],
      courseDetail: {},
      getAllBookmarked: [],
      userDetail: [],
      openDeleteDialog: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let respJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (respJson && !respJson.errors) {
        switch (apiRequestCallId) {
          case this.getSuggestedCourseApiCallId:
            this.suggestedCourseApiResponce(respJson);
            break;
          case this.addRateReviewApiCallId:
            this.handleaddRateReviewApiResponce(respJson);
            break;
          default:
            break;
        }
      }
      else if (respJson && respJson?.errors) {
        this.handleApiErrorResponce(respJson);
      }
    }
  }
  suggestedCourseApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ boughtCourseList: responseJson.data });
    }
  };

  handleaddRateReviewApiResponce = (responseJson: any) => {
    if (responseJson && responseJson?.data && !responseJson.errors) {
      this.props.openToastHandler("success", "Successfully submitted rating & review");
      this.props.history.push("/dashboard/mysubscription/courses");
    }
    else if (responseJson && !responseJson.errors && responseJson.message) {
      this.props.openToastHandler("error", responseJson.message);
    } else if (responseJson.errors) {
      this.props.openToastHandler("error", "arlready submitted");
    }
  };

  handleApiErrorResponce = (responseJson: any) => {
    if (responseJson && responseJson?.errors) {
      if (responseJson?.errors[0]?.token) {
        this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
      else if (responseJson?.errors?.order_courses[0]) {
        this.props.openToastHandler("error", responseJson.errors.order_courses[0]);
      }
    }
  }

  handleClose = () => {
    this.setState({ openDeleteDialog: false })
  }

  getBoughtCourses = () => {
    const token = localStorage.getItem('token');
    this.getSuggestedCourseApiCallId = sendAPIRequest(configJSON.getBoughtCourseUrl + `/bought_courses_list`,
      { headers: { "Content-Type": configJSON.apiContentType, "token": token } })
  }
  createReview = (value: any) => {
    const token = localStorage.getItem('token');
    const headers = {
      "token": token,
      "Content-Type": configJSON.apiContentType
    }
    const data = {
      "data": {
        "rating": value.rating,
        "review": value.review
      }
    }
    const apiEndPoint = configJSON.courselistAPIEndPoint + '/' + this.state.courseDetail.id + '/ratings';
    const method = configJSON.httpPostType;
    this.addRateReviewApiCallId = sendAPIRequest(
      apiEndPoint,
      {
        method,
        body: { ...data },
        headers: headers
      },
    );
  }

  showTime = (time: any) => displayTimeInHoursminutes(time)
}
