export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const play = require("../assets/play.png");
export const courseImg = require("../assets/cousres-all.png");
export const dummyImg = require("../assets/students.jpeg");
export const fbImg = require("../assets/facebook.png");
export const linkedInImg = require("../assets/Linkedin.png");
export const next = require("../assets/next.png");
export const titleImg = require("../assets/titleImg.png");
export const certified = require("../assets/certified.png");
export const prev = require("../assets/prev.png");
export const coursesbottom = require("../assets/coursesbottom.png");
export const visaImg = require("../assets/visa-logo.png");
export const hdfcImg = require("../assets/hdfc-logo.png");
export const epub = require("../assets/epub_logo_color.jpg");
export const googlePlay = require("../assets/google-play.jpeg");
export const appStore = require("../assets/app-store.jpeg");
export const collegeImg = require("../assets/college.jpeg");
export const schoolImg = require("../assets/school.png");
export const dummyImage = require("../assets/image.png");
export const logo = require("../assets/logo.png");
export const courseLandingPageimg = require("../assets/Image_Header.png");


