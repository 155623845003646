// index.js - WEB
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';
import './i18n';
import ReactGA from 'react-ga'

ReactGA.initialize('YourAnalyticsID')
ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </ThemeProvider>
  </Router>
  ,
  document.getElementById('root')
);
registerServiceWorker();
