import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");
export const isEmpty = (x: any) => {
  if (x === undefined) return true;
  if (x === null) return true;
  if (typeof x === "object") return Object.keys(x).length === 0;
  if (typeof x === "string" || Array.isArray(x)) return x.length === 0;
  return !x;
};
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  companyDetails: any[];
  filterData: any;
  open: boolean;
  companyId: number,
  similarJobs: any[]
}
interface SS {
  id: any;
}
export default class JobsDetailsViewAllController extends BlockComponent<Props, S, SS> {
  showJobCategoriesApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      companyDetails: [],
      filterData: {},
      open: false,
      companyId: this.props?.match?.params?.companyId,
      similarJobs: []
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.showJobCategoriesApiCallId) {
        if (responseJson && !responseJson.errors && responseJson?.data && responseJson?.similar_jobs) {
          this.setState({ companyDetails: responseJson?.data, similarJobs: responseJson?.similar_jobs });
        } else if (responseJson && responseJson.errors) {
          if (responseJson.errors[0].token)
            this.props.openToastHandler("error", responseJson.errors[0].token + `, please login again`!);
        }
      }

    }
  }
  // Customizable Area Start
  showJobCategories = () => {
    let { location, experience, datePosted, jobType, salary }: any = { ...this.state.filterData };
    location = isEmpty(location) ? '' : `&location[]=${location}`;
    experience = isEmpty(experience) ? '' : `&experience[]=${experience}`;
    datePosted = isEmpty(datePosted) ? '' : `&date_posted[]=${datePosted}`;
    if (datePosted === '&date_posted[]=any_time') {
      datePosted = ''
    }
    jobType = isEmpty(jobType) ? '' : `&job_type[]=${jobType}`;
    salary = isEmpty(salary) ? '' : `&salary_id[]=${salary}`;
    const addFilter = `${location}${experience}${datePosted}${jobType}${salary}`
    this.setState({ companyId: this.props?.match?.params?.companyId });
    const { companyId } = this.state;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.showJobCategoriesApiCallId = requestMessage.messageId;
    const addFilterId = addFilter.length > 0 ? `?${addFilter.substring(1)}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCompanyIndexApiEndPoint}/${companyId}${addFilterId}`
    );
    const token = localStorage.getItem("token") || "";
    const header: any = {
    };
    if (token) {
      header['token'] = token;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
