//@ts-nocheck
import React from "react";
import {
	withStyles, Grid, Button, createStyles, Dialog, DialogContent, DialogActions, Container, Typography,
	Card, CardContent, CardHeader
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import CartController, { props } from "./CartController.web";
import DeleteIcon from '@material-ui/icons/Delete';
import { Ratings } from "./Utility.web"
import CoursesCard from "./CourseCard.web"

const CortCourses = ({ classes, cartCourses, purchased, showTime, deleteCourseFromCart, handleClose, checkOut, totalAmount, openDeleteDialog, setDeleteCourseId, courseId }) => {
	return (
		<Grid container direction="row" justify="space-between">
			{cartCourses?.attributes?.courses?.data?.length > 0 ?
				<>
					<Grid xs={12} md={7}>
						<br />
						<Card variant="outlined" className={classes.shortlistCard}>
							<CardHeader style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} subheader={
								<Typography variant="h3">My Cart({cartCourses?.attributes?.courses?.data?.length})</Typography>
							}>
							</CardHeader>
							<CardContent>
								{cartCourses?.attributes?.courses?.data.map((ele: any, idx: any) =>
									<>
										<Grid container direction="row" spacing={2} className={classes.courseoutlined}   >
											<Grid item xs={12} md={5}>
												<img src={ele?.attributes?.thumbnail}
													className={classes.shortThumb} />
											</Grid>
											<Grid item xs={6} md={5}>
												<Grid container spacing={2} >
													<Grid item xs={4}>
														<Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
															{ele?.attributes?.enrolled_users}{""} Enrolled
														</Typography>
													</Grid>
													<Grid item xs={4} />
													<Grid item xs={4}>
														<Typography variant="subtitle1" className={classes.suggestedCardEnrolledTime}>
															{showTime(ele?.attributes?.total_time)}
														</Typography>
													</Grid>
													<Grid item xs={12} >
														<Typography
															variant="body2"
															className={classes.runningCoursesCardTitle}
														>
															{ele?.attributes?.heading} {':'} </Typography>
														<Typography variant="body2" className={classes.suggestedDetail}>{ele?.attributes?.description}</Typography>

													</Grid>
													<Grid item xs={12}>
														<Ratings {...{ classes, user_rating: ele?.attributes?.user_rating, idx }} />
													</Grid>
													<Grid item xs={12}>
														<Grid container direction="row" justify="space-between" alignItems="center">
															<Grid item xs={6}>
																<Typography
																	variant="caption"
																	className={classes.shortlistCardSubTitle}
																>
																	{ele?.attributes?.instructors?.data[0]?.attributes?.name}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid container direction="column" justify="space-between" item xs={6} md={2}>
												<Grid item>
													<Typography variant="subtitle2" style={{ color: '#f8593b' }} className="text-right">
														<span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>₹</span> {ele?.attributes?.price}
													</Typography>
												</Grid>
												<Grid item className='text-right'>
													<DeleteIcon data-testId="setDeleteCourseId" onClick={() => setDeleteCourseId(ele?.id, true)} className={classes.dfIcon} />
												</Grid>
												{localStorage.getItem("token") &&
													<>
														<Dialog
															PaperProps={{
																style: { boxShadow: 'none' }
															}}
															BackdropProps={{ style: { background: 'rgb(0,0,0,.15)' } }}
															open={openDeleteDialog}
															onClose={() => handleClose()}>
															<DialogContent>
																Do you want remove course from the cart?
															</DialogContent>
															<DialogActions>
																<Button className={classes.cancelBtn} data-testId="deleteCourseFromCart" onClick={() => deleteCourseFromCart(cartCourses?.attributes?.id, courseId)}>Yes</Button>
																<Button className={classes.publishBtn} data-testId="handleClose" onClick={() => handleClose()} autoFocus>No</Button>
															</DialogActions>
														</Dialog>
													</>}
											</Grid>
										</Grid>
									</>
								)}
							</CardContent>
							<Grid container direction="row" className={classes.coursefooter} alignItems="center" justify="flex-end" >
								{!purchased ?
									<Button contained className={classes.checkout} data-testId="checkOut" onClick={() => checkOut(cartCourses)}>
										<Typography variant="subtitle1">Checkout</Typography>
									</Button>
									:
									<Button contained className={classes.checkout} disabled="true">
										<Typography variant="subtitle1">pending...</Typography>
									</Button>
								}
							</Grid>
						</Card>
					</Grid>
					<Grid item xs={12} md={5} style={{ paddingLeft: '.5rem', paddingTop: '1rem' }}>
						<Card variant="outlined" >
							<CardHeader style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} subheader={
								<Typography variant="h3">Price details</Typography>
							}>
							</CardHeader>
							<CardContent>
								{cartCourses?.attributes?.courses?.data.map((ele: any) =>
									<Grid container direction="row" spacing={2}    >
										<Grid item xs={8}>
											<Typography variant="subtitle2" style={{ color: '#f8593b' }} className="text-left">
												{ele?.attributes?.heading}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant="subtitle2" style={{ color: '#0091ff' }} className="text-right">
												{ele?.attributes?.price}
											</Typography>
										</Grid>
									</Grid>
								)}
								<Grid container direction="row" spacing={2} className={classes.totalAmount}   >
									<Grid item xs={8}>
										<Typography variant="subtitle2" style={{ color: '#f8593b' }} className="text-left">
											Total amount
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" className="text-right">
											{totalAmount(cartCourses?.attributes?.courses?.data)}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</>
				:
				<Grid item>
					{cartCourses?.length <= 0 && <Typography className="text-center" variant="h3">
						No courses in the cart !
					</Typography>}</Grid>
			}
		</Grid>
	)
}

const StudentAlsoBought = ({ classes, boughtCourseList, props, getAllBookmarked, removeBookmark, addBookmark, showTime }) => {
	return (
		<Grid container >
			<Grid item xs={12} className="w-full">
				<CoursesCard
					isSmUp={false}
					courseList={boughtCourseList}
				/>
			</Grid>
			<Grid item xs={1} />
		</Grid>
	)
}

export class Cart extends CartController {
	componentDidMount = () => {
		window.scrollTo(0, 0);
		if (localStorage.getItem('token')) {
			this.getAllCartCourses();
			this.getBoughtCourses();
			this.userDetail();
		}
	}
	clickSeeMore = () => {
		this.setState(prevState => ({ seeMore: !prevState.seeMore }));
	}
	setDeleteCourseId = (courseId: any, openDeleteDialog: any) => {
		this.setState({ courseId: courseId, openDeleteDialog: openDeleteDialog });
	}
	render() {
		const { classes } = this.props; //these  needed for locallization t, i18n, location 
		const { boughtCourseList, cartCourses } = this.state;
		return (
			<Container maxWidth="xl" style={{ padding: "2rem" }}>
				<CortCourses data-testId="CortCourses" {...{
					classes, cartCourses, purchased: this.state.purchased,
					showTime: this.showTime,
					deleteCourseFromCart: this.deleteCourseFromCart,
					handleClose: this.handleClose,
					checkOut: this.checkOut,
					totalAmount: this.totalAmount,
					openDeleteDialog: this.state.openDeleteDialog,
					setDeleteCourseId: this.setDeleteCourseId,
					courseId: this.state.courseId
				}} />
				<br />
				<br />
				<br />
				<br />
				<Grid container direction="column" alignItems="flex-start" justify="center">
					<Grid item xs={12} className={classes.containerSpacing}>
						<Typography variant="h4">Students Also Bought</Typography>
					</Grid>
				</Grid>
				<StudentAlsoBought data-testId="StudentAlsoBought"
					{...{
						classes,
						boughtCourseList,
						props: this.props,
						getAllBookmarked: this.state.getAllBookmarked,
						removeBookmark: this.removeBookmark,
						addBookmark: this.addBookmark,
						showTime: this.showTime
					}} />
			</Container>
		);
	}
}
//@ts-ignore
const CartWithRouter = withRouter(Cart);
const CartWithLoader = withLoader(CartWithRouter);
const CartToast = withSnackbar(CartWithLoader);
const CartWithStyle = withStyles((theme) =>
	createStyles({
		dfIcon: {
			cursor: 'pointer',
			height: '1.2rem',
			fill: '#f48b1b'
		},
		cancelBtn: {
			backgroundColor: '#b5b5b5',
			height: '30px',
			color: '#ffffff',
			'&:hover': {
				backgroundColor: '#b5b5b5',
				color: '#ffffff'
			}
		},
		publishBtn: {
			backgroundColor: '#fa6400',
			height: '30px',
			color: '#ffffff',
			'&:hover': {
				backgroundColor: '#fa6400',
				color: '#ffffff'
			}
		},
		shortlistCard: {
			borderRadius: "0.5rem",
		},
		shortThumb: {
			backgroundColor: "#f6efef",
			border: "1px solid #979797",
			borderRadius: "12px",
			height: '11rem'
		},
		shortlistCardTitle: {
			color: "#3b302e",
			whiteSpace: 'nowrap',
			overflow: "hidden",
			textOverflow: "ellipsis",
			cursor: 'pointer'
		},
		shortlistCardSubTitle: {
			color: "#4a6bd8",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "3rem",
		},
		root: {
			borderRadius: "8px",
			width: '20rem',
			margin: '1rem'
		},
		suggested: {
			backgroundColor: "#bdb4b4",
			height: "10rem",
			objectFit: 'fill'
		},
		suggestedDetail: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			color: "#3b302e",
			textOverflow: "ellipsis",
			width: "18rem"
		},
		suggestedCardEnrolledTime: {
			color: "#1c1c1c",
			paddingTop: "20px",
		},
		runningCoursesCardTitle: {
			color: "#1c1c1c",
			whiteSpace: 'nowrap'
		},
		checkout: {
			height: '2rem',
			width: '7rem',
			borderRadius: '9px',
			color: '#ffffff',
			backgroundColor: '#f8593b',
			'&:hover': {
				backgroundColor: '#f8593b !important',
				color: '#ffffff !important'
			}
		},
		courseoutlined: {
			border: "1px solid rgba(0, 0, 0, 0.12)",
			borderRadius: "0.75rem",
			marginBottom: "2rem"
		},
		coursefooter: {
			borderTop: "1px solid rgba(0, 0, 0, 0.12)",
			padding: '1rem 1rem 1rem 0rem'
		},
		totalAmount: {
			borderTop: "1px solid rgba(0, 0, 0, 0.12)",
			borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		},
		Icons: {
			color: "#f48b1b !important",
		},
		Icon: {
			color: "#f48b1b !important",
		},
		courseHeading: {
			display: 'flex'
		}

	})
)(CartToast);
export default withTranslation()(CartWithStyle);
