//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  IconButton,
  Button,
  createStyles,
  Typography,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import {
  k12_grey,
  higherEdu_grey,
  govt_grey,
  upskilling_grey,
  k12_orange,
  higherEdu_orange,
  govt_orange,
  upskilling_orange,
} from "./assets";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withTranslation } from 'react-i18next';
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
export class Explore extends React.Component {
  async componentDidMount() {
    this.renderGreySvg("k12");
    this.renderOrangeSvg("k12");
    this.props.getAllCategories();
  }
  renderGreySvg = (name: any) => {
    switch (name.toLowerCase()) {
      case "k12":
        return k12_grey;
      case "higher education":
        return higherEdu_grey;
      case "govt job":
        return govt_grey;
      case "upskilling":
        return upskilling_grey;
      case "competitive exams":
        return upskilling_grey;
      default:
        return k12_grey;
    }
  };
  renderOrangeSvg = (name: any) => {
    switch (name.toLowerCase()) {
      case "k12":
        return k12_orange;
      case "higher education":
        return higherEdu_orange;
      case "govt job":
        return govt_orange;
      case "upskilling":
        return upskilling_orange;
      case "competitive exams":
        return upskilling_orange;
      default:
        return k12_orange;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justify="center"
        spacing={5}
      >
        <Grid item xs={11}>
          <Typography variant="h4" className={classes.headTxt}>
            I'm Looking To Explore
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-evenly">
            {this.props.categories.length > 0 &&
              this.props.categories.map((item: any, index: any) => (
                <div className="relative" key={index}>
                  <Button
                    key={index}
                    data-testId="onBtnClick"
                    onClick={() => this.props.onBtnClick(item)}
                    className={this.props.selectedCategory === item.id
                      ? `${classes.selectedCategory}`
                      : `${classes.catBtn}`
                    }
                  >
                    <Grid container direction="column" alignItems="center">
                      {/* <Grid item xs={12}> */}
                      {this.props.selectedCategory === item.id ? (
                        <img
                          src={this.renderOrangeSvg(item.attributes.name)}
                          className={classes.svg}
                        />
                      ) : (
                        <img
                          src={this.renderGreySvg(item.attributes.name)}
                          className={classes.svg}
                        />
                      )
                      }
                      {/* </Grid>
​
                      <Grid item xs={12}> */}
                      {this.props.selectedCategory === item.id ? (
                        <Typography variant="h3">
                          {item.attributes.name}
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2">
                          {item.attributes.name}
                        </Typography>
                      )
                      }
                      {/* </Grid> */}
                    </Grid>
                  </Button>
                  {this.props.selectedSubCategory.find(e => { return e.cat_parent_id == item.id })
                    &&
                    <div className={classes.overlay}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-haspopup={true}
                      >
                        <CheckCircleIcon className={classes.icon} />
                      </IconButton>
                    </div>}
                </div>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Grid
            item
            container
            xs={11}
            direction="row"
            alignItems="center"
            justify="start"
          >
            {this.props.showSubCatArray.map((subCat: any, i: number) => (
              <Button
                key={subCat.id}
                className={
                  this.props.selectedSubCategory.findIndex((temp) => { return temp.id === subCat.id }) > -1
                    ? `${classes.selectedSubCategory}`
                    : `${classes.subBtn}`
                }
                color="primary"
                onClick={() => this.props.handleSubCategorySelection(subCat)}
                variant="outlined"
              >
                <Typography variant="subtitle1" className={classes.btnTypo}>
                  {subCat.name}
                </Typography>
              </Button>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" alignItems="center" justify="space-evenly">
            <Button className={classes.nxt} disabled={(this.props.selectedSubCategory.length <= 0)}
              data-testId="clickNext"
              onClick={this.props.clickNext}>
              <Typography variant="h3">Next</Typography>
            </Button>
            <Button
              onClick={this.props.handleReset} className={classes.cancelBtn}>
              <Typography variant="h3">Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    );
  }
}
//@ts-ignore
//@ts-ignore
const ExploreWebWithRouter = withRouter(Explore);
const ExploreWebWithLoader = withLoader(ExploreWebWithRouter);
const ExploreWebToast = withSnackbar(ExploreWebWithLoader);
const ExploreWebWithStyle = withStyles((theme) =>
  createStyles({
    icon: {
      height: "1.25rem",
    },
    overlay: {
      position: "absolute",
      top: "-21%",
      right: "-17%",
      opacity: "1",
      color: theme.palette.primary.light
    },
    headTxt: {
      color: "#f48b1b",
      marginTop: "2rem",
    },
    personInfo: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    nxt: {
      height: "2.5rem",
      width: "10rem",
      backgroundColor: "#f48b1b",
      borderRadius: "27px",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#f48b1b !important",
        color: "#ffffff !important",
      },
    },
    cancelBtn: {
      height: "2.5rem",
      width: "10rem",
      backgroundColor: "#b5b5b5",
      borderRadius: "27px",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#b5b5b5 !important",
        color: "#000000 !important",
      },
    },
    catBtn: {
      height: "6.125rem",
      width: "6.75rem",
      boxShadow: "0 3px 9px 0 rgba(167, 167, 167, 0.13)",
      border: "1px solid rgba(112, 112, 112, 0.13)",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#ffffff !important",
        border: "solid 1px #7764db",
        color: "#000000 !important",
      },
    },
    selectedCategory: {
      height: "6.125rem",
      width: "6.75rem",
      border: "solid 1px #7764db",
      boxShadow: "0 8px 12px 0 rgba(213, 210, 210, 0.16)",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
      color: "#7764db !important",
      "&:hover": {
        backgroundColor: "#ffffff !important",
        border: "solid 1px #7764db",
        color: "#7764db !important",
      },
    },
    selectedSubCategory: {
      border: "1px solid #a3a3a3 !important",
      backgroundColor: "#7764db",
      paddingLeft: "32px !important",
      paddingRight: "32px !important",
      borderRadius: "12px !important",
      color: "#ffffff !important",
      marginRight: "15px",
      height: "3rem",
      marginBottom: ".5rem",
      // width: '145px',
      "&:hover": {
        backgroundColor: "#7764db !important",
        color: "#ffffff !important",
      },
      "&:selected": {
        backgroundColor: "#ffffff !important",
        color: "#2222222 !important",
      },
    },
    subBtn: {
      border: "1px solid #a3a3a3 !important",
      backgroundColor: "#ffffff",
      paddingLeft: "32px !important",
      paddingRight: "32px !important",
      borderRadius: "12px !important",
      marginRight: "15px",
      height: "3rem",
      marginBottom: ".5rem",
      color: "#222222",
      "&:hover": {
        backgroundColor: "#7764db !important",
        color: "#ffffff !important",
      },
    },
    svg: {
      width: "2.8rem",
      height: "2.6rem",
    }
  })
)(ExploreWebToast);
export default withTranslation()(ExploreWebWithStyle);