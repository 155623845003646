Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.httpPostType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Search";
exports.labelBodyText = "Search Body";
exports.followContent = "bx_block_contentmanagement/follows";
exports.unfollowContent = "bx_block_contentmanagement/follows/unfollow";
exports.btnExampleTitle = "CLICK ME";
exports.getContents = "bx_block_contentmanagement/contents";
exports.contentTypelistAPIEndPoint = "bx_block_contentmanagement/content_types";
// Customizable Area End