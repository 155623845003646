// App.js - WEB
import React, { Component } from 'react';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { Notifications } from 'react-push-notification';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import BlogPostsManagement from '../../blocks/BlogPostsManagement/src/BlogPostsManagement';
// import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Referrals from '../../blocks/Referrals/src/Referrals';
import Reviews from '../../blocks/Reviews/src/Reviews';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import ApiIntegrationActivityFeedFromDifferentStrategticContentPartner from '../../blocks/ApiIntegrationActivityFeedFromDifferentStrategticContentPartner/src/ApiIntegrationActivityFeedFromDifferentStrategticContentPartner';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Videos from '../../blocks/Videos/src/Videos';
import IntegrationWithDailyHuntAiMlApIsForContentSuggestion from '../../blocks/IntegrationWithDailyHuntAiMlApIsForContentSuggestion/src/IntegrationWithDailyHuntAiMlApIsForContentSuggestion';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import ActivityFeed from '../../blocks/DynamicContent/src/ActivityFeed';
import ActivityFeedWeb from '../../blocks/DynamicContent/src/ActivityFeed.web';
import UsageInsights from '../../blocks/UsageInsights/src/UsageInsights';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
// import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum';
import PromoteContent from '../../blocks/PromoteContent/src/PromoteContent';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import GoogleAnalyticsAppsFlyerMmpTrackingCodeIntegration from '../../blocks/GoogleAnalyticsAppsFlyerMmpTrackingCodeIntegration/src/GoogleAnalyticsAppsFlyerMmpTrackingCodeIntegration';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import LanguageOptionWeb from '../../blocks/LanguageOptions/src/LanguageOption.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import Share from '../../blocks/Share/src/Share';
import DesktopNotifications from '../../blocks/DesktopNotifications/src/DesktopNotifications';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import ProductRecommendationEngine from '../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import PushNotifications from '../../blocks/PushNotifications/src/PushNotifications';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import DynamicContent from '../../blocks/DynamicContent/src/DynamicContent';
import IntegrationWithExistingDhUserManagement from '../../blocks/IntegrationWithExistingDhUserManagement/src/IntegrationWithExistingDhUserManagement';
import Gamification from '../../blocks/Gamification/src/Gamification';
import LiveStreaming from '../../blocks/LiveStreaming/src/LiveStreaming';
import Payments from '../../blocks/Payments/src/Payments';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import Search from '../../blocks/Search/src/Search';
import '../src/assets/styles/style.css';
import '../src/assets/styles/index.css';
import VerifyOtp from '../../blocks/otp-input-confirmation/src/OTPInputAuth.web';
import ConfirmOtp from '../../blocks/otp-input-confirmation/src/ConfirmOtp.web';
import ContentManagmentWeb from '../../blocks/ContentManagement/src/ContentManagement.web';
import CategoryList from '../../blocks/categoriessubcategories/src/CategoryList.web';
import SubCategoryList from '../../blocks/categoriessubcategories/src/SubCategoryList.web';
import ArticaldetailsWeb from '../../blocks/categoriessubcategories/src/Articaldetails.web';
// web routing
import RouteConfig from './route.config';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import DashboardWeb from '../../blocks/dashboard/src/Dashboard.web';

const routeMap = {
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: '/BlogPostsManagement'
  },
  // SocialMediaAccountRegistrationScreen: {
  //   component: SocialMediaAccountRegistrationScreen,
  //   path: '/SocialMediaAccountRegistrationScreen'
  // // },
  // AdminConsole: {
  //   component: AdminConsole,
  //   path: '/AdminConsole'
  // },
  // Referrals: {
  //   component: Referrals,
  //   path: '/Referrals'
  // },
  // Reviews: {
  //   component: Reviews,
  //   path: '/Reviews'
  // },
  // UserProfileBasicBlock: {
  //   component: UserProfileBasicBlock,
  //   path: '/UserProfileBasicBlock'
  // },
  // ApiIntegrationActivityFeedFromDifferentStrategticContentPartner: {
  //   component: ApiIntegrationActivityFeedFromDifferentStrategticContentPartner,
  //   path: '/ApiIntegrationActivityFeedFromDifferentStrategticContentPartner'
  // },
  // EmailAccountRegistration: {
  //   component: EmailAccountRegistration,
  //   path: '/EmailAccountRegistration'
  // },
  // Videos: {
  //   component: Videos,
  //   path: '/Videos'
  // },
  // IntegrationWithDailyHuntAiMlApIsForContentSuggestion: {
  //   component: IntegrationWithDailyHuntAiMlApIsForContentSuggestion,
  //   path: '/IntegrationWithDailyHuntAiMlApIsForContentSuggestion'
  // },
  // ForgotPassword: {
  //   component: ForgotPassword,
  //   path: '/ForgotPassword'
  // },
  // ForgotPasswordOTP: {
  //   component: ForgotPasswordOTP,
  //   path: '/ForgotPasswordOTP'
  // },
  // NewPassword: {
  //   component: NewPassword,
  //   path: '/NewPassword'
  // },
  // ApiIntegration: {
  //   component: ApiIntegration,
  //   path: '/ApiIntegration'
  // },
  // RolesPermissions: {
  //   component: RolesPermissions,
  //   path: '/RolesPermissions'
  // },
  ActivityFeedWeb: {
    component: ActivityFeedWeb,
    path: '/'
  },
  linkedin: {
    component: LinkedInPopUp,
    path: '/linkedin'
  },
  // UsageInsights: {
  //   component: UsageInsights,
  //   path: '/UsageInsights'
  // },
  // Filteritems: {
  //   component: Filteritems,
  //   path: '/Filteritems'
  // },
  // Filteroptions: {
  //   component: Filteroptions,
  //   path: '/Filteroptions'
  // },
  // SocialMediaAccountLoginScreen: {
  //   component: SocialMediaAccountLoginScreen,
  //   path: '/SocialMediaAccountLoginScreen'
  // },
  // CommunityForum: {
  //   component: CommunityForum,
  //   path: '/CommunityForum'
  // },
  // PromoteContent: {
  //   component: PromoteContent,
  //   path: '/PromoteContent'
  // },
  // DataImportexportcsv: {
  //   component: DataImportexportcsv,
  //   path: '/DataImportexportcsv'
  // },
  // GoogleAnalyticsAppsFlyerMmpTrackingCodeIntegration: {
  //   component: GoogleAnalyticsAppsFlyerMmpTrackingCodeIntegration,
  //   path: '/GoogleAnalyticsAppsFlyerMmpTrackingCodeIntegration'
  // },
  // BulkUploading: {
  //   component: BulkUploading,
  //   path: '/BulkUploading'
  // },
  LanguageOptionWeb: {
    component: LanguageOptionWeb,
    path: '/selectLanguage'
  },
  // Catalogue: {
  //   component: Catalogue,
  //   path: '/Catalogue'
  // },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/categories-subcategories'
  },
  CategoryList: {
    component: CategoryList,
    path: '/categories/:id'
  },
  SubCategoryList: {
    component: SubCategoryList,
    path: '/categories/:id/sub-categories/:id'
  },

  // PaymentAdmin: {
  //   component: PaymentAdmin,
  //   path: '/PaymentAdmin'
  // },
  // Share: {
  //   component: Share,
  //   path: '/Share'
  // },
  // DesktopNotifications: {
  //   component: DesktopNotifications,
  //   path: '/DesktopNotifications'
  // },
  VerifyOtp: {
    component: VerifyOtp,
    path: '/verifyOTP'
  },
  ConfirmOtp: {
    component: ConfirmOtp,
    path: '/confirmOTP'
  },
  // EmailNotifications: {
  //   component: EmailNotifications,
  //   path: '/EmailNotifications'
  // },
  // PushNotifications: {
  //   component: PushNotifications,
  //   path: '/PushNotifications'
  // },
  ContentManagmentWeb: {
    component: ContentManagmentWeb,
    path: '/contents'
  },
  // DynamicContent: {
  //   component: DynamicContent,
  //   path: '/DynamicContent'
  // },
  // IntegrationWithExistingDhUserManagement: {
  //   component: IntegrationWithExistingDhUserManagement,
  //   path: '/IntegrationWithExistingDhUserManagement'
  // },
  // Gamification: {
  //   component: Gamification,
  //   path: '/Gamification'
  // },
  LiveStreaming: {
    component: LiveStreaming,
    path: '/LiveStreaming'
  },
  // Payments: {
  //   component: Payments,
  //   path: '/Payments'
  // },
  // CountryCodeSelector: {
  //   component: CountryCodeSelector,
  //   path: '/CountryCodeSelector'
  // },
  DashboardWeb: {
    component: DashboardWeb,
    path: '/dashboard'
  }
  // EmailAccountLoginBlock: {
  //   component: EmailAccountLoginBlock,
  //   path: '/EmailAccountLoginBlock'
  // },
  // AdHocReporting: {
  //   component: AdHocReporting,
  //   path: '/AdHocReporting'
  // },
  // Search: {
  //   component: Search,
  //   path: '/Search'
  // },
  // Home: {
  //   component: Categoriessubcategories,
  //   path: '/',
  //   exact: true
  // },
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage'
  // },
  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true
  // }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
//   authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
//   databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
//   projectId: 'rnmasterapp-c11e9',
//   storageBucket: 'rnmasterapp-c11e9.appspot.com',
//   messagingSenderId: '649592030497',
//   appId: '1:649592030497:web:7728bee3f2baef208daa60',
//   measurementId: 'G-FYBCF3Z2W3'
// });
const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDv9UgjcCEPpta85yPpzz-_LzzMRD0VVcc',
  authDomain: 'career-hunt.firebaseapp.com',
  projectId: 'career-hunt',
  storageBucket: 'career-hunt.appspot.com',
  messagingSenderId: '727976808474',
  appId: '1:727976808474:web:bac8c8ee3d98b74a0f81a3',
  measurementId: 'G-2EZNVQNMV9'
});
class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <div>
        <Notifications />
        <RouteConfig />
      </div>
    );
  }
}

export default App;
