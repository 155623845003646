//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  Container,
  Typography,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReactTimeAgo from "react-time-ago";
import en from 'javascript-time-ago/locale/en.json';
import TimeAgo from "javascript-time-ago";

export class Transaction extends React.Component {

  componentDidMount = () => {
    TimeAgo.addDefaultLocale(en)
    TimeAgo.locale(en);
  }

  render() {
    TimeAgo.addDefaultLocale(en)
    TimeAgo.locale(en);
    const { classes, transactionHistory } = this.props;

    return (
      <Container maxWidth="xl">
        <Grid container direction="row" justify="space-evenly">
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Grid container spacing={2}>
              {transactionHistory && transactionHistory.length > 0 ? transactionHistory.map((items, index) => {
                return (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card variant="outlined" className={classes.resultSmallCard}>
                        <Accordion elevation={0}>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon className={classes.expandIconColor} />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header">
                            <Grid container direction="row" alignItems="center" justify="space-between">
                              <Grid item xs={12} md={9} className={classes.broder}>
                                <Grid container direction="row" alignItems="center" justify="space-between">
                                  <Grid item xs={12} md={10}>
                                    <Typography className={classes.titleName}>{items?.attributes?.courses?.data[0]?.attributes?.heading}</Typography>
                                    <Typography variant="MuiFormLabel">Content Provider: {items?.attributes?.courses?.data[0]?.attributes?.content_provider?.data?.attributes?.partner_name}</Typography>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    {items.attributes && items.attributes.created_at && <Typography variant="subtitle2"><ReactTimeAgo date={items.attributes.created_at} locale="en-US" /></Typography>}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                {items.attributes && items.attributes.price && <Typography variant={'h2'} className={classes.rscolor} >Rs:{items.attributes.price}</Typography>}
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails className={classes.sprate}>
                            {items.attributes && items.attributes.courses && items.attributes.courses.data && items.attributes.courses.data.length > 0 && items.attributes.courses.data.map((e , i) => {
                              return (
                                <Grid key={i} item xs={12} md={10}>
                                  {e.attributes && e.attributes.heading && <Typography className={classes.titleName}>{e.attributes.heading}</Typography>}
                                  <Typography variant="MuiFormLabel">Content Provider: {e.attributes?.content_provider?.data?.attributes?.partner_name}</Typography>
                                </Grid>
                              )
                            } )}
                            <Typography variant="body">Payment Via Wallet </Typography>
                            {items.attributes && items.attributes.razorpay_payment_id && <Typography variant="body">Transaction Id : {items.attributes.razorpay_payment_id}</Typography>}
                            <Typography variant="body"> Status:<Typography className={items?.attributes?.status == 'pending' ? classes.statusFailed : classes.statusPaid} display="inline" variant="body2"> {items?.attributes?.status}</Typography></Typography>

                          </AccordionDetails>
                        </Accordion>
                      </Card>
                    </Grid>
                  </>
                )
              }) : <Grid item xs={12} >
                <Typography variant="body2" align="center">No transaction found</Typography>
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
            <Card className={classes.resultAdvertisementCard}>
              <Typography variant="MuiFormLabel">Cta Advertisement Purpose</Typography>
            </Card>
            <Card variant="outlined" className={`${classes.resultAdvertisementCard} ${classes.margin}`}>
              <Typography className={classes.offerColor}>Offers</Typography>
              <Typography variant="MuiFormLabel" className={classes.promocode}>User promo code NEWUSER2021 Get uptown 200 Off On first Buy</Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
const TransactionWithRouter = withRouter(Transaction);
const TransactionWithLoader = withLoader(TransactionWithRouter);
const TransactionToast = withSnackbar(TransactionWithLoader);
const TransactionWithStyle = withStyles((theme) =>
  createStyles({
    broder: {
      borderRight: "1px",
      width: "1rem",
      borderColor: "black",
    },
    rscolor: {
      color: "#000",
      textAlign: "center",
      fontSize: 21
    },
    resultAdvertisementCard: {
      padding: '1rem',
      borderRadius: '10px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: '#fff',
      height: "20rem",
      // textAlign: "center",
      // alignItems: "center",
      display: "flex"
    },
    resultSmallCard: {
      padding: '1rem',
      //   height: '7rem',
      borderRadius: '10px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: '#fff'
    },
    margin: {
      marginTop: "2rem",
      textAlign: "left",
      flexDirection: "column",
      // justifyContent: "center",
    },
    offerColor: {
      color: "#f48b1b",
      fontSize: "14px",
      marginBottom: "1rem"

    },
    titleName: {
      fontFamily: 'Montserrat-Bold',
      fontSize: 20
    },
    promocode: {
      fontSize: 11
    },
    sprate: {
      flexDirection: "column",
    },
    statusPaid: {
      color: 'green'
    },
    statusFailed: {
      color: "red"
    }
  })
)(TransactionToast);
export default withTranslation()(TransactionWithStyle);