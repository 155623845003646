export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const play = require("../assets/play.png");
export const next = require("../assets/next.png");
export const prev = require("../assets/prev.png");
export const epub = require("../assets/epub_logo_color.jpg");
export const dummyImage = require("../assets/image.png");
export const courseImg = require("../assets/cousres-all.png");
export const fbImg = require("../assets/facebook.png");
export const linkedInImg = require("../assets/Linkedin.png");
export const refer = require("../assets/refer.png");
export const copy = require("../assets/copy.png");
export const fimage = require("../assets/facebook-logo-2019.png");
export const wimage = require("../assets/whatsapp.png");
export const limage = require("../assets/linkedinicon.png");
export const simage = require("../assets/share.png");
export const timage = require("../assets/twitter.png");
export const userImage = require("../assets/eye.png");