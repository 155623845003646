//@ts-nocheck
//@ts-ignore
import React from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { windowWidth } from "../../../../framework/src/Helpers";
import { Colleges, Schools } from "../constants";
import {
  blue,
  grey,
  lineHeight_20,
  semiBold,
  size_16pt,
  white,
} from "../Utils";

interface Props {
  activeTab: any;
  onTabChanged: (changedTab: any) => void;
  activeTheme?: any;
}

interface S { }

interface SS { }

export default class Tabs extends React.Component<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  //array of Tab Types:
  tabTypes = [Schools, Colleges];

  activeTabColor(activeTab: any, title: any) {
    if (this.props.activeTheme == "dark") {
      if (activeTab == title) {
        return { color: white }
      }else{
        return { color: grey }
      }
    } else if (activeTab == title) {
      return { color: blue }
    } else {
      return { color: grey }
    }

  }
  //   checkCondition(){
  //     return condition? true: false
  // }

  tabContainer(title: string, activeTab: any,index:any) {

    return (
      <TouchableOpacity
       key={index}
        onPress={() => {
          this.props.onTabChanged(title);
        }}
        style={[
          styles.tabContainer,
          {
            borderBottomColor: activeTab == title ? blue : grey,
            backgroundColor:
              this.props.activeTheme == "dark" ? "rgb(34, 34, 34)" : "#FCFCFF",
          },
        ]}
      >
        <Text
          style={[
            styles.tabText,
            this.activeTabColor(activeTab, title),
          ]}
        >
          {title}
        </Text>
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor:
              this.props.activeTheme == "dark" ? "rgb(34, 34, 34)" : "#FCFCFF",
          },
        ]}
      >
        {this.tabTypes.map((item: any,index:any) => {
          return this.tabContainer(item, this.props.activeTab,index);
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: windowWidth,
    flexDirection: "row",
    // marginEnd:17,
    marginTop: 10,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  tabContainer: {
    width: windowWidth / 2.2,
    backgroundColor: "#FCFCFF",
    height: 30,
    justifyContent: "center",
    borderBottomWidth: 2,
  },
  tabText: {
    alignSelf: "center",
    fontSize: size_16pt,
    lineHeight: lineHeight_20,
    fontFamily: semiBold,
  },
});
