import React from "react";
import {
  withStyles, Grid, createStyles, Container, Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LiveStreamingWebController, { Props } from './LiveStreamingController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import ReactHlsPlayer from 'react-hls-player';
//@ts-ignore
class LiveStreamingWeb extends LiveStreamingWebController {
  //@ts-ignore
  myRef: RefObject<HTMLVideoElement>;
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
  };

  render() {
    //@ts-ignore
    return (
      <Container>
        <Grid container direction="column" xs={12} alignItems='flex-start'>
          <ReactHlsPlayer
            playerRef={this.myRef}
            src="https://cdna.streamgates.net/Ashams/Live/playlist.m3u8"
            hlsConfig={{
              maxLoadingDelay: 4,
              minAutoBitrate: 0,
              lowLatencyMode: true,
            }}
            autoPlay={false}
            controls={true}
            width="100%"
            height="auto"
            onPlay={this.playVideo}
          />
        </Grid>
        <Snackbar
          open={this.state.showToast.show}
          autoHideDuration={3000}
          onClose={this.closeToastHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeToastHandler}
            severity={this.state.showToast.type}
          >
            {this.state.showToast.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}
//@ts-ignore
const LiveStreamingWebWithRouter = withRouter(LiveStreamingWeb);
const LiveStreamingWebWithStyle = withStyles((theme) =>
  createStyles({
  })
)(LiveStreamingWebWithRouter);
export default withTranslation()(LiveStreamingWebWithStyle);

