import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  expertsProfile: any;
  featureArticle: any;
  featureVideo: any;
  popularBlogs: any;
  tabName: string;
  allUserStatus: any
  allExpertStatus: any
  allUserSubject: any
  dialogOpen: boolean;
  categories: any;
  tabCategoryId: any;
  activeCategory: any;
  activeCategoryData: any;
  currentTab: any;
  subTabCategoryId: any
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class CareerExpertController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getExpertsProfilesApiCallId: string = "";
  getFeatureArticleApiCallId: string = "";
  getFeatureVideoApiCallId: string = "";
  getPopularBlogsApiCallId: string = "";
  getAllUserStatusApiCallId: string = "";
  getAllExpertStatusApiCallId: string = "";
  getAllUserSubjectApiCallId: string = "";
  onSubmitApiCallId: string = "";
  getAllUserCategoriesApiCallId: string = ""
  getCategroyApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      expertsProfile: [],
      featureArticle: [],
      featureVideo: [],
      popularBlogs: [],
      tabName: 'For Student',
      allUserStatus: [],
      allExpertStatus: [],
      allUserSubject: [],
      dialogOpen: false,
      categories: [],
      tabCategoryId: "",
      activeCategory: {},
      activeCategoryData: {},
      currentTab: '',
      subTabCategoryId: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  loadData = (activeCatId: any, searchInfo?: any) => {
    const subcategory = this.state.subTabCategoryId
    this.getExpertsProfiles(activeCatId, subcategory, searchInfo);
    this.getFeatureArticle(activeCatId, subcategory, searchInfo);
    this.getFeatureVideo(activeCatId, subcategory, searchInfo);
    this.getPopularBlogs(activeCatId, subcategory, searchInfo);
  }
  loadInitialData = async (activeCategory?: any) => {
    let activeCatId = activeCategory?.id
    await Promise.all([
      this.getCategoryContent(activeCategory?.attributes?.name),
      this.getExpertsProfiles(activeCatId),
      this.getFeatureArticle(activeCatId),
      this.getFeatureVideo(activeCatId),
      this.getPopularBlogs(activeCatId)
    ])
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getFeatureArticleApiCallId:
            this.handleFeatureArticleApiResponce(responseJson);
            break;
          case this.getExpertsProfilesApiCallId:
            this.handleExpertsProfilesApiResponce(responseJson);
            break;
          case this.getFeatureVideoApiCallId:
            this.handleFeatureVideoApiResponce(responseJson);
            break;
          case this.getPopularBlogsApiCallId:
            this.handlePopularBlogsApiResponce(responseJson);
            break;
          case this.getCategroyApiCallId:
            this.handlegetCategroyApiResponce(responseJson);
            break;
          case this.onSubmitApiCallId:
            this.handleonSubmitApiResponce(responseJson);
            break;
          case this.getAllUserStatusApiCallId:
            this.handleAllUserStatusApiResponce(responseJson);
            break;
          case this.getAllExpertStatusApiCallId:
            this.handleAllExpertStatusApiResponce(responseJson);
            break;
          case this.getAllUserSubjectApiCallId:
            this.handleAllUserSubjectApiResponce(responseJson);
            break;
          case this.getAllUserCategoriesApiCallId:
            this.handleAllUserCategoriesApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }
  handleFeatureArticleApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ featureArticle: responseJson.data });
    }
  }
  handleExpertsProfilesApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ expertsProfile: responseJson.data });
    }
  }
  handleFeatureVideoApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ featureVideo: responseJson.data });
    }
  }
  handlePopularBlogsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ popularBlogs: responseJson.data });
    }
  }
  handlegetCategroyApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ activeCategoryData: responseJson.data });
    }
  }
  handleonSubmitApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ dialogOpen: false });
      this.props.openToastHandler("success", 'Submitted'!);
    }
  }
  handleAllUserStatusApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ allUserStatus: responseJson.data });
    }
  }
  handleAllExpertStatusApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ allExpertStatus: responseJson.data });
    }
  }
  handleAllUserSubjectApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ allUserSubject: responseJson.data });
    }
  }
  handleAllUserCategoriesApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      const categories = responseJson.data;
      if (categories.length > 0) {
        let activeCategory = categories.length > 0 ? categories[0] : null;
        this.setState({ currentTab: 0, categories }, () => { this.loadInitialData(activeCategory) });
      } else {
        this.setState({ categories });
      }
    }
  }
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)
  };

  gotoExpertProfile = (id: any) => {

    const { history } = this.props;
    history.push(`/home/careerexpert/${id}`);

  }
  handleprofile = () => {
    this.props.openToastHandler("error", 'Please Login'!);
  }
  getExpertsProfiles = (activeCatId: string = '', subCategoryId: number | null = null, searchInfo: any = '') => {
    this.props.showLoader();
    const search = searchInfo ? `&search=${searchInfo}` : "";
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getExpertsProfilesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let url = `${configJSON.getExperts}?page=1&per_page=10${search}`;
    if (activeCatId) {
      url = `${configJSON.getExperts}?page=1&per_page=10&category[]=${activeCatId}${search}`;
    }
    if (subCategoryId !== null) {
      url = `${configJSON.getExperts}?page=1&per_page=10&category[]=${activeCatId}&sub_category[]=${subCategoryId}${search}`;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFeatureArticle = (activeCatId: string = '', subCategoryId: number | null = null, searchInfo: any = '') => {
    const search = searchInfo ? `&search=${searchInfo}` : '';
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getFeatureArticleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let url = `${configJSON.expertArticles}?page=1&per_page=10${search}`;
    if (activeCatId) {
      url = `${configJSON.expertArticles}?page=1&per_page=10&category[]=${activeCatId}${search}`;
    }
    if (subCategoryId !== null) {
      url = `${configJSON.expertArticles}?page=1&per_page=10&category[]=${activeCatId}&sub_category[]=${subCategoryId}${search}`;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFeatureVideo = (activeCatId: string = '', subCategoryId: number | null = null, searchInfo: any = '') => {
    const search = searchInfo ? `&search=${searchInfo}` : '';

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getFeatureVideoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let url = `${configJSON.expertVideos}?page=1&per_page=10${search}`;
    if (activeCatId) {
      url = `${configJSON.expertVideos}?page=1&per_page=10&category[]=${activeCatId}${search}`;
    }
    if (subCategoryId !== null) {
      url = `${configJSON.expertVideos}?page=1&per_page=10&category[]=${activeCatId}&sub_category[]=${subCategoryId}${search}`;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPopularBlogs = (activeCatId: string = '', subCategoryId: number | null = null, searchInfo: any = '') => {
    const search = searchInfo ? `&search=${searchInfo}` : '';
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPopularBlogsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    let url = `${configJSON.expertBlogs}?page=1&per_page=10${search}`;
    if (activeCatId) {
      url = `${configJSON.expertBlogs}?page=1&per_page=10&category[]=${activeCatId}${search}`;
    }
    if (subCategoryId !== null) {
      url = `${configJSON.expertBlogs}?page=1&per_page=10&category[]=${activeCatId}&sub_category[]=${subCategoryId}${search}`;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategoryContent = (categoryName: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategroyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApi + categoryName
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onSubmit = (payloaddata: any) => {
    const token = localStorage.getItem('token');
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const data = {
      ...payloaddata
    };
    const apiEndPoint = `${configJSON.getRequestExperts}`;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onSubmitApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getAllUserStatus = () => {
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getAllUserStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestExperts}/all_user_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllExpertStatus = () => {
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getAllExpertStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestExperts}/all_expert_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllUserSubject = () => {
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getAllUserSubjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestExperts}/all_user_subject`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategories = () => {
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    this.getAllUserCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.expertCategories}?cat_name[]=Institution&cat_name[]=Students&cat_name[]=Career Professional`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
