import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { StyledProps } from "@material-ui/core/styles";
import { RouterProps } from "react-router";
import { sendAPIRequest } from "../../../components/src/Utility";
import { Category } from "./QuizAssessmentCommonutility";

export type Props = RouterProps &
  StyledProps & {
    id: string;
    navigation: any;
    location: any;
    match: any;
    openToastHandler: any;
    openDialog: any;

    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  categoriesData: Category[];
  popularQuizList: [];
  trendingQuizList: [];
  recommendQuizList: [];
  selectedType: any;
  selectedChip: any;
  searchValue: any;
  // Customizable Area End
}
interface SS {
  id: any;
}


export default class AssessmentWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: string = "";
  getPopularAssessmentApiCallId: string = "";
  getTrendingAssessmentApiCallId: string = "";
  getRecommendAssessmentApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    this.state = {
      categoriesData: [],
      popularQuizList: [],
      trendingQuizList: [],
      recommendQuizList: [],
      selectedType: 0,
      searchValue: '',
      selectedChip: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getAssessmentCategories();
    this.setState({ selectedChip: this.props?.match?.params?.categoryId }, () => {
      this.loadData();
    })
  }

  loadData = () => {
    this.getPopularAssessments();
    this.getTrendingAssessments();
    if (localStorage.getItem('token')) {
      this.getRecommendAssessments()
    }
  };

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCategoriesApiCallId:
            this.handleCategoriesApiCallIdApiResponce(responseJson);
            break;
          case this.getPopularAssessmentApiCallId:
            this.handlePopularAssessmentApiCallIdApiResponce(responseJson);
            break;
          case this.getTrendingAssessmentApiCallId:
            this.handleTrendingAssessmentApiCallIdApiResponce(responseJson);
            break;
          case this.getRecommendAssessmentApiCallId:
            this.handleRecommendAssessmentApiCallIdApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearAssessmentLocalStorage(responseJson)
      }
    }
  }

  handleCategoriesApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ categoriesData: responseJson.data });
    }
  };
  handlePopularAssessmentApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ popularQuizList: responseJson.data });
    }
  };
  handleTrendingAssessmentApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ trendingQuizList: responseJson.data });
    }
  };
  handleRecommendAssessmentApiCallIdApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson?.data) {
      this.setState({ recommendQuizList: responseJson.data });
    }
  };
  clearAssessmentLocalStorage = (responseJson: any) => {
    if (responseJson?.errors) {
      if (responseJson?.errors[0]?.token)
        this.props.openToastHandler("error", responseJson.errors[0].token + `. please login again`!);
    }
  };

  getAssessmentCategories = () => {
    const header: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getCategoriesApiCallId = sendAPIRequest(`${configJSON.categoriesAPIEndPoint}?show_on_landing_page=true`,
      { headers: header })
  }
  getPopularAssessments = () => {
    const { searchValue, selectedChip } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const header: any = {
      "Content-Type": configJSON.apiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getPopularAssessmentApiCallId = sendAPIRequest(`${configJSON.userAssessmentUrl}?page=1&per_page=10&is_popular=true&category[]=${selectedChip}${search}`,
      { headers: header })
  }
  getTrendingAssessments = () => {
    const { searchValue, selectedChip } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const header: any = {
      "Content-Type": configJSON.apiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getTrendingAssessmentApiCallId = sendAPIRequest(`${configJSON.userAssessmentUrl}?page=1&per_page=10&is_trending=true&category[]=${selectedChip}${search}`,
      { headers: header })
  }

  getRecommendAssessments = () => {
    const { searchValue, selectedChip } = this.state;
    const search = searchValue ? `&search=${searchValue}` : '';
    const header: any = {
      "Content-Type": configJSON.apiContentType
    };
    const token = localStorage.getItem('token');
    if (token) {
      header.token = token;
    }
    this.getRecommendAssessmentApiCallId = sendAPIRequest(`${configJSON.userAssessmentUrl}?page=1&per_page=10&is_recommended=true&category[]=${selectedChip}${search}`,
      { headers: header })
  }
}
