//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, createStyles, Typography,
  CardMedia, Card, CardActionArea, IconButton
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import {
  Props,
} from "./CategoriesViewallController.web";
//@ts-ignore
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { Ratings } from "./Utility.web"
export class CoursesList extends React.Component {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, courseList, getAllBookmarked } = this.props;

    return (
      <Grid direction="column" alignItems="center" justify="center">
        <Grid item xs={12} className={classes.containerSpacing}>
          <Grid container>
            <Grid item md={11} xs={12}>
              <Grid container spacing={2}>
                {courseList && courseList.length ? courseList.map((_data: any, idx: any) => (
                  <Grid item xs={12} md={6} sm={6} lg={5} key={_data.id}>
                    <Card className={classes.root} data-testId="onClick" onClick={() => this.props.history.push("/home/coursedetail", { courseDetail: _data })}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          className={classes.suggested}
                          src={_data.attributes.thumbnail}
                          title="suggested Card title"
                        />
                        <Grid container spacing={2} style={{ paddingLeft: '1rem', paddingRight: '.5rem' }}>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                              {_data?.attributes?.enrolled_users}{""} Enrolled
                            </Typography>
                          </Grid>
                          <Grid item xs={4} />
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.suggestedCardEnrolledTime}>
                              {this.props.setDateTime(_data?.attributes?.total_time)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.courseHeading}>
                            <Typography
                              variant="body2"
                              className={classes.runningCoursesCardTitle}
                            >
                              {_data.attributes.heading} {':'} </Typography>
                            <Typography variant="body2" className={classes.suggestedDetail}>{_data.attributes.description}</Typography>

                          </Grid>
                          <Grid item xs={12}>
                            <Ratings {...{ classes, user_rating: _data?.attributes?.user_rating, idx }} />

                          </Grid>
                          <Grid item xs={12} container direction="row" alignItems="center" justify="space-between" >
                            <Grid item xs={6}>
                              <Typography
                                variant="caption"
                                className={classes.shortlistCardSubTitle}
                              >
                                {_data?.attributes?.instructors?.data[0]?.attributes?.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              {localStorage.getItem('token') && <>
                                {getAllBookmarked &&
                                  getAllBookmarked.findIndex((ele) => { return ele.attributes.content.id == _data.id }) > -1 ?
                                  (<IconButton aria-label="bookmark" data-testId="removeBookmark" onClick={(event) => { event.stopPropagation(); this.props.removeBookmark(_data?.attributes?.id) }}>
                                    <BookmarkIcon color="primary" />
                                  </IconButton>)
                                  :
                                  (<IconButton aria-label="bookmark" data-testId="addBookmark" onClick={(event) => { event.stopPropagation(); this.props.addBookmark(_data?.attributes?.id) }}>
                                    <BookmarkBorderIcon color="primary" />
                                  </IconButton>)
                                  // ) 
                                }
                              </>}
                              {!localStorage.getItem('token') && getAllBookmarked &&
                                (<IconButton aria-label="bookmark" onClick={(event) => { event.stopPropagation(); this.props.addBookmark(_data?.attributes?.id) }}>
                                  <BookmarkBorderIcon color="primary" />
                                </IconButton>)
                              }
                            </Grid>
                          </Grid>

                        </Grid>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
                  :
                  <Grid item>
                    <Typography variant="body2">No results to display, Refine your search</Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
//@ts-ignore
const CoursesListWebWithRouter = withRouter(CoursesList);
export default withStyles((theme) =>
  createStyles({
    root: {
      borderRadius: "8px",
      height: "100%",
      margin: '1rem'
    },
    suggested: {
      backgroundColor: "#bdb4b4",
      height: "10rem",
      objectFit: 'fill'
    },
    suggestedCardEnrolledTime: {
      color: "#3b302e",
      paddingTop: "12px",
      fontWeight: '500',
      fontFamily: 'Montserrat',
    },
    suggestedDetail: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#3b302e",
      textOverflow: "ellipsis",
      width: "100%"
    },
    runningCoursesCardTitle: {
      color: "#1c1c1c",
      whiteSpace: 'nowrap'
    },
    shortlistCardSubTitle: {
      color: "#4a6bd8",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "3rem",
      fontWeight: '500',
      fontFamily: 'Montserrat',
    },

    courseHeading: {
      display: 'flex'
    },
    Icons: {
      color: "#f48b1b !important",
    },
    Icon: {
      color: "#f48b1b !important",
    },
  })
)(CoursesListWebWithRouter);