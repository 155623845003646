import * as Yup from "yup";
import { string } from "yup";
//validation start
export const loginSchema = Yup.object({
  email: Yup.string()
    // .email("Enter a valid email")
    .required("Email / Phone Number is required")
    .test('test-name', 'Enter Valid Email / Phone',
      function (value) {
        const emailRegex = /^(([^<>#()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }
    )
});
  //validations end