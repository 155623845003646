
import React from "react";
export const categoryArticalExamViewAllSearch=(theme:any)=>
    ({
      paper: {
        borderRadius: '0rem'
    },
    paperserch: {
        borderRadius: '0rem',
        boxShadow: 'none',
    },
    searchTab: {
        paddingLeft: '0.8rem',
    },
    selectedBtn: {
        border: '2px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        borderRadius: '20px !important',
        color: '#ffffff !important',
        marginRight: '15px',
        width: '173px',
        height: '3rem',
        '&:hover': {
            backgroundColor: '#f48b1b !important',
            color: '#ffffff !important'
        }
    },
    btnTypo: {
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        overflow: 'hidden'
    },
    catBtn: {
        border: '2px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        borderRadius: '20px !important',
        width: '173px',
        height: '3rem',
        marginRight: '15px',
        '&:hover': {
            backgroundColor: '#f48b1b !important',
            color: '#ffffff !important'
        }
    }
})