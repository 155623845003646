import { CommonActions } from '@react-navigation/native'
import { Alert, PixelRatio, Platform } from 'react-native'
import { windowHeight, windowWidth } from '../../../../../framework/src/Helpers'

export default function LocationFun (propsdata: any): any {
  let locationArry = [
    {
      attributes: {
        cities: [Object],
        count: null,
        created_at: '2022-01-19T13:21:38.909+05:30',
        id: 0,
        is_top_location: false,
        latitude: 13.2,
        logo: null,
        longitude: 12.3,
        name: 'All',
        updated_at: '2022-01-19T13:21:38.909+05:30'
      },
      id: 0,
      type: 'location'
    }
  ]
  for (let i = 0; i <= propsdata.length; i++) {
    locationArry.push(propsdata[i])
  }
  return locationArry
}

export const GetDateFilterOptions = [
  { name: 'Today', id: 0 },
  { name: 'Yesterday', id: 1 },
  { name: 'Last 7 days', id: 2 },
  { name: 'Last 15 days', id: 3 },
  { name: 'Last Month', id: 4 },
  { name: 'Custom', id: 5 }
]

export const IS_IOS = Platform.OS === 'ios'
export const IS_ANDROID = Platform.OS === 'android'

let artBoardHeightOrg = 667
let artBoardWidthOrg = 375

// To check if Artboard and Device screen has same ratio
export function isSameRatio (): boolean {
  return (
    artBoardWidthOrg / artBoardHeightOrg < 1 && windowWidth / windowHeight < 1
  )
}

//Re calculated Artboard Dimension
export const artBoardWidth = isSameRatio() ? artBoardWidthOrg : windowWidth
export const artBoardHeight = isSameRatio() ? artBoardHeightOrg : windowHeight

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch
let extraSpace = 0
export function deviceBasedDynamicDimension (
  originalDimen: number,
  compareWithWidth: boolean,
  resizeFactor: number
): number | undefined | any {
  if (originalDimen != null) {
    if (resizeFactor != null) {
      originalDimen *= resizeFactor
    }
    const compareArtBoardDimenValue = compareWithWidth
      ? artBoardWidth
      : artBoardHeight
    const artBoardScreenDimenRatio =
      (originalDimen * 100) / compareArtBoardDimenValue
    let compareCurrentScreenDimenValue = compareWithWidth
      ? windowWidth
      : windowHeight - extraSpace
    return PixelRatio.roundToNearestPixel(
      (artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100
    )
  }
  return null
}

export const loginAlert = (navigation: any, message?: string) => {
  Alert.alert('', message ?? 'Request you to login first', [
    {
      text: 'Cancel',
      onPress: () => {
        return
      },
      style: 'cancel'
    },
    {
      text: 'OK',
      onPress: () => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'EmailAccountLoginBlock' }]
          })
        )
      }
    }
  ])
}

export const getImageURI = (URI: any) => {
  if (URI) return URI
  else
    return 'https://listimg.pinclipart.com/picdir/s/74-748363_open-unavailable-icon-clipart.png'
}

export const getcategoryImageURI =(item: any, index: any, catState: any, highLightColor: any)=>{
  if (catState.includes(index))
    return item.attributes.dark_icon_active
  else if (highLightColor === index)
    return item.attributes.dark_icon
  else if (item.attributes.dark_icon_inactive) {
    if (catState.includes(index))
      return item.attributes.dark_icon_active
    else if (highLightColor === index)
      return item.attributes.dark_icon
    else return item.attributes.dark_icon_inactive
  } 
  else return ''
}

export const getCategoryNameColor = (item: any, index: any, themeColorContext: any, categoriesSelected: any, highLightColor: any) => {
  if (item.id == -1 && categoriesSelected.length == 0) return '#8a2ade'
  else if(themeColorContext.themeColor == 'dark' && categoriesSelected.includes(index))
    return "black"
  else if (themeColorContext.themeColor == 'light' && categoriesSelected.includes(index))
    return'#8a2ade'
  else if (highLightColor === index && themeColorContext.themeColor == 'light')
    return "#8a2ade"
  else if (highLightColor === index && themeColorContext.themeColor == 'dark')
    return'#fff'
  else if (themeColorContext.themeColor == 'light')
    return '#444444'
  else 
    return 'rgb(181,181,181)'
}

export const keyboardTypeValue = () => {
  if (IS_ANDROID) return 'visible-password'
  else return 'ascii-capable'
}

export const marginVertical15 = () => {
  if (IS_IOS) return 15
  else return 0
}

export const padding21 = () => {
  if (IS_IOS) return 21
  else return 0
}

export const shadowColor = () => {
  if (IS_ANDROID) return '#000'
  else return '#292929'
}

// Quiz/assessment
export const questionCircleBGColor = (item: any, index: any, state: any) => {
  if (item?.actualAnswer?.is_right) return 'rgb(75,181,67)'
  else if (item?.actualAnswer?.is_right === false) return 'rgb(255,88,88)'
  else if (state === index) return 'rgb(244,139,27)'
  else return '#DFDFE0'
}

export const tabBorderBottomWidth = (stateTabIndex: any, tabIndex: any) => {
  if (stateTabIndex === tabIndex) return 2
  else return 0.5
}

export const tabBorderBottomColor112 = (
  stateTabIndex: any,
  tabIndex: any,
  themeColorContext: any
) => {
  if (stateTabIndex === tabIndex) return PaleGreyPurple(themeColorContext)
  else return 'rgb(112,112,112)'
}

export const tabBorderBottomColor181 = (
  stateTabIndex: any,
  tabIndex: any,
  themeColorContext: any
) => {
  if (stateTabIndex === tabIndex) return PaleGreyPurple(themeColorContext)
  else return 'rgb(181,181,181)'
}

export const expertTabBorderBottomColor112 = (
  stateTabIndex: any,
  tabIndex: any
) => {
  if (stateTabIndex === tabIndex) return 'rgb(119,100,219)'
  else return 'rgb(112,112,112)'
}

export const expertTabBorderBottomColor181 = (
  stateTabIndex: any,
  tabIndex: any
) => {
  if (stateTabIndex === tabIndex) return 'rgb(119,100,219)'
  else return 'rgb(181,181,181)'
}

export const tabFontFamily = (stateTabIndex: any, tabIndex: any) => {
  if (stateTabIndex === tabIndex) return 'NunitoSans-Bold'
  else return 'Roboto'
}

export const theme = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'dark'
  else return 'light'
}

export const isDarkTheme = (themeColorContext: any) => {
  if (themeColorContext.themeColor == 'dark') return true
  else return false
}

export const blackWhiteColor = (themeColorContext: any) => {
  if (themeColorContext.themeColor == 'dark') return '#000'
  else return '#fff'
}

export const whiteIrishColor = (themeColorContext: any) => {
  if (themeColorContext.themeColor == 'dark') return '#fff'
  else return '#6c59d6' // rgb(108, 89, 214)
}

export const whiteDarkBlackColor = (themeColorContext: any) => {
  if (themeColorContext.themeColor == 'dark') return '#fff'
  else return '#222222' // rgb(34, 34, 34)
}

export const whiteBlackColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#FFFFFF'
  else return '#000000'
}

export const opacityDimGreyColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgba(112,112,112,0.2)'
  else return 'rgb(112,112,112)'
}

export const balticSeaBlackWhiteColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#242424'
  else return '#fff'
}

export const purpuleWhite = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(255,255,255)'
  else return 'rgb(119,100,219)'
}

export const darkBlackMintCreamColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return 'rgb(34, 34, 34)' //  #222222
  else return 'rgb(252,252,255)' //  #fcfcff
}

export const darkBlackOrangeColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return 'rgb(34, 34, 34)' //  #222222
  else return 'rgb(244, 139, 27)' //  #F48B1B
}

export const OrangeVampireGreyColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return 'rgb(244, 139, 27)' //  #F48B1B
  else return 'rgb(79,79,79)' //  #4F4F4F
}

export const amourWhiteSmokeyBlackColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#EEE'
  else return 'rgb(18,18,18)' //  #121212
}

export const Nerolightcolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#242424'
  else return 'rgb(252, 252, 255)' //  #121212
}

export const blackOut_Whitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#222' // rgb(34, 34, 34)
  else return '#fff'
}

export const white_BlackOutcolor = (themeColorcontext: any) => {
  if (themeColorcontext?.themeColor == 'dark') return '#fff'
  else return '#222' // rgb(34, 34, 34)
}

export const white_C64Purplecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#7764db' // rgb(119, 100, 219)
}

export const verifiedBlack_Whitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#242424' // rgb(36, 36, 36)
  else return '#fff'
}

export const white_black_5Opacity_color = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgba(255,255,255,0.5)'
  else return 'rgba(0,0,0,0.5)'
}

export const white_moonLandingBlackcolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return 'rgb(167,167,167)' // #a7a7a7
}

export const white_Pinballcolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return 'rgb(211,211,211)' // #d3d3d3
}

export const white_lightBlackcolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#707070' // rgb(112, 112, 112)
}

export const chromaPhobicBlack_brainStemGreycolor = (
  themeColorcontext: any
) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#292929' // rgb(41, 41, 41)
  else return '#b5b5b533' // rgba(181,181,181,0.2)
}

export const white_lightGreycolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#9B9B9B' // rgb(155, 155, 155)
}

export const white_dhusarGreycolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return 'rgba(255,255,255,0.38)' // #ffffff61
  else return '#aaa9ab' // rgb(170, 169, 171)
}

export const blackOakDesiredDawnColor = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return 'rgb(79, 79, 79)'
  else return 'rgb(216, 216, 216)'
}

export const whiteNatoBlue = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return 'fff'
  else return 'rgb(23,49,71)'
}

export const BrainstemGreyBlack = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return 'rgb(181, 181, 181)'
  else return '#000'
}

export const WhiteSnowFlake = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#fff'
  else return '#f0f0f0'
}

export const BlackEerieBlack = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#000'
  else return '#1a1a1a'
}

export const BrainstemGreyDugong = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return 'rgb(181, 181, 181)'
  else return 'rgb(112, 112, 112)' // #707070
}

export const White_umbraBlack = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#fff'
  else return '#1f1f1f' //rgb(31, 31, 31)
}

export const umbraBlack_white = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#1f1f1f' //rgb(31, 31, 31)
  else return '#fff'
}

export const WhiteBlack = (themeColorcontext: any) => {
  console.warn("#####",themeColorcontext)
  return themeColorcontext.themeColor === 'dark' ? '#fff':'#000'}

export const BlackPantherPaleGrey = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return 'rgb(67,66,66)'
  else return 'rgb(252, 252, 255)'
}

export const grey_lilyLavenderColor = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#707070'
  // rgb(112, 112, 112)
  else return '#e6e6e8' // rgb(230, 230, 232)
}

export const lead_lilyLavenderColor = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#202020'
  // rgb(32, 32, 32)
  else return '#e6e6e8' // rgb(230, 230, 232)
}

export const WhiteShishaCoal = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#fff'
  else return 'rgb(60,60,60)'
}

export const WhiteSilver = (themeColorcontext: any) => {
  if (themeColorcontext === 'dark') return '#fff'
  else return 'rgb(192,192,192)'
}

export const chromaPhobicBlack_whitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#292929' // rgb(41, 41, 41)
  else return '#fff'
}

export const chromaPhobicBlack_bluecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#292929' // rgb(41, 41, 41)
  else return 'rgb(4, 0, 57)' // #040039
}

export const chromaPhobicBlack_transparentcolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#292929' // rgb(41, 41, 41)
  else return 'transparent'
}

export const white_liqGreyColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor === 'dark') return '#fff'
  else return '#383838' // rgb(56,56,56)
}
export const lightGrey_liqGreyColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor === 'dark') return '#707070'   // 'rgb(112, 112, 112)'
  else return '#383838' // rgb(56,56,56)
}

export const ChromaPhobicBlackErrigalWhite = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#292929' // rgb(41, 41, 41)
  else return '#f1f1f3' // rgb(241, 241, 243)
}

export const PaleGreyPurple = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#fcfcff' // rgb(252, 252, 255)
  else return '#7764db' // rgb(119, 100, 219)
}

export const GloriousSunsetPurple = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#f48b1a' // rgb(244, 139, 26)
  else return '#7764db' // rgb(119, 100, 219)
}

export const nero_whiteColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#252525' // rgb(37, 37, 37)
  else return '#fff'
}

export const orange_whiteColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return 'rgb(244, 139, 27)' // #F48B1B
  else return '#fff'
}

export const white_SmokeyBlackColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return 'rgb(18, 18, 18)' //  #121212
}

export const WhiteBlackOak = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(255,255,255)'
  else return 'rgb(79,79,79)'
}

export const HydrargyrumAzuremystIsle = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(155,155,155)'
  else return '#C188F0'
}

export const ArchitectireGreyPinBall = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#6a6a6a'
  else return '#d3d3d3'
}

export const WhiteChromaphobicBlack = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#292929'
}

export const WhiteGloriousSunset = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return 'rgb(244, 139, 27)' // #F48B1B
}

export const purpule_Purplecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#5d4ad3' // rgb(93, 74, 211)
  else return '#7764db' // rgb(119, 100, 219)
}

export const blackPanther_whitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#434242' // rgb(67, 66, 66)
  else return '#fff'
}

export const VerifiedBlackEmptiness = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return "#242424"
  else return '#fcfcfc' // rgb(252,252,252)
}

export const WhiteCavernous = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return "#fff"
  else return 'rgb(82, 82, 82)' // #525252
}

export const carbonFiber_gramWhiteColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return "#2E2E2E"  // rgb(46, 46, 46)
  else return '#F6F6F7' // rgb(246, 246, 247)
}

export const stoneCold_whitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#565555' // rgb(86, 85, 85)
  else return '#fff'
}

export const stoneCold_blackcolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#565555' // rgb(86, 85, 85)
  else return '#000'
}

export const white_mercuryColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#fff'
  else return '#ebebeb' // rgb(235,235,235)
}

export const white_boatAnchorGreyColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#fff'
  else return '#6b6b6b' // rgb(107,107,107)
}

export const darkBlack_vempireGreyColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')
    return '#222' // rgb(34,34,34)
  else return '#4F4F4F' // rgb(79,79,79)
}

export const goldenYellow_whitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#ffda46' // rgb(255,218,70)
  else return '#fff'
}

export const shadowGrey_amourWhitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#585858' // rgb(88, 88, 88)
  else return '#EEE'  // rgb(238,238,238)
}

export const white_perryWinklecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#998be4'  // rgb(153, 139, 228)
}

export const white_dimGreycolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#696969'  // rgb(105, 105, 105)
}

export const chromaPhobicBlack_snowFlake = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark')  return '#292929' // rgb(41, 41, 41)
  else return '#efeff1' // rgb(239, 239, 241)
}

export const grey_BrainstemGrey = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor === 'dark') return '#505050' // 'rgb(80, 80, 80)'
  else return '#b5b5b5' // 'rgb(181, 181, 181)'
}

export const hardCoal_white = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor === 'dark') return '#656565' // rgb(101, 101, 101)
  else return '#fff'
}

export const BlackOakWhite = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(79,79,79)'
  else return 'rgb(255,255,255)'
}

export const WhiteGrey = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return 'rgb(128,128,128)'  
}

export const darkGray_white = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor === 'dark') return '#fff' // rgb(101, 101, 101)
  else return "#222"
}

export const BrainstemGreyamourWhitecolor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(181, 181, 181)' 
  else return '#eee'  // rgb(238,238,238)
}

export const GloriousSunsetC64Purple = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(244, 139, 27)'
  else return 'rgb(119, 100, 219)'
}

export const PaleGreyGrey = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return 'rgb(252, 252, 255)'
  else return '#808080'
}

export const whiteGreyColor = (themeColorContext: any) => {
  if (themeColorContext.themeColor == 'dark') return '#fff'
  else return '#787878' // rgb(120, 120, 120)
}

export const grey_lightGrey = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor === 'dark') return '#505050' // 'rgb(80, 80, 80)'
  else return '#D4D4D4' // 'rgb(212, 212, 212)'
}

export const white_liqBlackColor = (themeColorcontext: any) => {
  if (themeColorcontext.themeColor == 'dark') return '#fff'
  else return '#1A1A1A' // rgb(26, 26, 26)
}