//@ts-nocheck
//@ts-ignore
import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { blue, Bold, size_10pt, white } from '../../Utils';
import { translate } from "../../../../../components/src/i18n/translate";

interface Props {
    exploreButton?: boolean;
    onPress: () => void;
    types: any
}

export default class Button extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <TouchableOpacity 
            testID='buttonfeatured'
            style={{ backgroundColor: this.props.exploreButton ? 'rgb(232, 232, 232)' : blue, width: 99, alignSelf: 'center', marginTop: 8, borderRadius: 10, height: 25, justifyContent: 'center', marginBottom: 10 }}
                onPress={() => this.props.onPress()}  >
                <Text style={{ fontSize: size_10pt, color: this.props.exploreButton ? 'rgb(79, 79, 79)' : white, alignSelf: 'center', fontFamily: Bold }}>{this.props.types === 'Features' ? translate("VisitWebsite") : translate("ExploreMore")}</Text>
            </TouchableOpacity>
        );
    }
}