
//@ts-nocheck
//@ts-ignore
import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import { bg_white } from '../../Utils';


interface Props{
    borderEnabled?:boolean,
    activeTheme?:any,
    onPress:()=>void;
}

export default class LocationCard extends React.Component<Props> {
    constructor(props:Props){
        super(props);
    }

    render(){
        return(
           
            <TouchableOpacity style={[styles.cardContainer,{
               // borderWidth: this.props.borderEnabled ? 0.5 : 0 ,
                borderRadius:10,
               // borderColor:this.props.activeTheme =='dark'? grey_location_color : blue,
              //  backgroundColor:this.props.activeTheme == 'dark'?themeBlack : white,
              //  borderLeftWidth:this.props.activeTheme == 'dark'? 0 : 0.5,
                //borderBottomWidth:this.props.activeTheme == 'dark'? 0 : 0.5
                }]}
                 onPress={()=>this.props.onPress()}
                >
              {this.props.children}
          </TouchableOpacity>
         
        );
    }
}

const styles = StyleSheet.create({
   cardContainer: {
        width:78,
        height:78,
       borderRadius:6,
       backgroundColor:bg_white,
       elevation: 5,
       shadowColor: '#000',
       shadowOffset: { width: 0, height: 1 },
       shadowOpacity: 0.8,
       shadowRadius: 1,  
       marginTop:9,
       marginEnd:10,marginStart:2,marginBottom:17,
       justifyContent:'center'
    }
})