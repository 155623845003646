const { frontEndBaseUrl } = require("../../../framework/src/config");

Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.linkedinApiContentType = "application/x-www-form-urlencoded";
exports.validationApiMethodType = "GET";
exports.loginApiEndPoint = "account/accounts/send_otp?";
exports.loginApiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.facebookLoginApiEndPoint = "bx_block_login/social_login";
exports.loginWithPhone = "login/login?language=en";
exports.appleLogin = "bx_block_login/social_login/apple_login_callback";
exports.getLinkedinToken = "https://www.linkedin.com/oauth/v2/accessToken";
exports.googleClientID =
  "727976808474-27jh75f4msv68mqranjofmiin7idbri9.apps.googleusercontent.com";
exports.appleClientID = "ai.builder.careerhunt.apple.login";
exports.linkedClientID = "868rsofbg8o0zw";
exports.linkedSecretID = "EtV1VTNgE7SZfDWz";
exports.RedirectURI = frontEndBaseUrl + `/home/activityfeed/linkedin`;
exports.appleRedirectURI = frontEndBaseUrl + `/home/activityfeed/callback`;
// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.successLogin = "Logged in successfully.";

exports.getProfile = "bx_block_profile/profiles/id?language=en";
// Customizable Area End
