//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, createStyles, Container
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import UserProfileBlockWebController, { Props } from './UserProfileBasicBlockController.web';
import StepperWeb from "./Stepper.web";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import _ from "lodash";
import { withTranslation } from 'react-i18next';
export class UserProfileBlockWeb extends UserProfileBlockWebController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const token = await localStorage.getItem("token");
    if ((token)) {
      this.setState({
        token: token
      }, () => {
        this.getBoards();
        this.getStandards();
        this.getSubjects();
        this.getUserDetails();
        this.getDegree();
        this.getCourse();
        this.getSpecialization();
        this.getCollege();
        this.getEducationLevel();
        this.getCasteCategory();
        this.getDomainWork();
      })
    }
  }
  handleReset = () => {
    this.setState({ activeLabel: 'personal information', activeStep: 0, completed: 0, isFinishStep: false });
  };
  setOtherCollege = () => {
    this.setState({ openNewField: true })
  }
  setCollege = () => {
    this.setState({ openNewField: false })
  }
  setOtherComCollege = () => {
    this.setState({ openNewComField: true })
  }
  setComCollege = () => {
    this.setState({ openNewComField: false })
  }
  setOtherCollegeInUpskilling = () => {
    this.setState({ openNewFieldInUpskilling: true })
  }
  setCollegeInUpskilling = () => {
    this.setState({ openNewFieldInUpskilling: false })
  }
  handleCertiChange = (event, val, i, label) => {
    this.setState({ currentCertificateIndex: i });
    const { value } = event.target;
    let tempCertificate = val.attributes;
    for (let x in tempCertificate) {
      if (x === label) {
        tempCertificate[label] = value
      }
    }
    if (label == 'certification_course_name') {
      if (value !== "") {
        this.setState({ isDisabled: false })
      } else {
        this.setState({ isDisabled: true })
      }
    }
    let temp = this.state.upskillingCertificateValues.map((certificate: any, index: any) => {
      if (certificate.id == val.id) {
        return Object.assign(certificate, {
          attributes: tempCertificate,
          isEdited: true,
        })
      }
      return certificate;
    });
    this.setState({ upskillingCertificateValues: temp });
  }
  handleEmploymentChange = (event, val, i, label) => {
    this.setState({ currentEmploymentIndex: i });
    const { value } = event.target;
    let tempEmploymentDetails = val.attributes;
    let domainObj = {};
    for (let domain of this.state.domainWork) {
      if (domain?.id == value) {
        domainObj = domain;
      }
    }
    this.setTempEmploymentDetails(tempEmploymentDetails, label, domainObj, value);

    let temp = this.state.upskillingEmployementValues.map((emplymentDetails: any, index: any) => {
      if (emplymentDetails.id == val.id) {
        return Object.assign(emplymentDetails, {
          attributes: tempEmploymentDetails,
          isEdited: true,
        })
      }
      return emplymentDetails
    });
    if (label == 'last_employer') {
      if (value !== "" && value !== "none") {
        this.setState({ isDisabled: false });
      } else {
        this.setState({ isDisabled: true })
      }
    }
    this.setState({ employmentDetails: temp });
    this.setState({ upskillingEmployementValues: temp });
  }
  setTempEmploymentDetails = (tempEmploymentDetails, label, domainObj, value) => {
    for (let x in tempEmploymentDetails) {
      if (x === label) {
        if (label === 'domain_work_function') {
          if (tempEmploymentDetails[label] != null && tempEmploymentDetails[label] != "none") {
            tempEmploymentDetails[label] = Object.assign(tempEmploymentDetails[label], {
              id: value,
              name: domainObj?.attributes?.name,
              rank: domainObj?.attributes?.rank
            })
          } else {
            tempEmploymentDetails[label] = Object.assign({
              id: value,
              name: domainObj?.attributes?.name,
              rank: domainObj?.attributes?.rank
            })
          }
        }
        else {
          tempEmploymentDetails[label] = value
        }
      }
    }
  }
  setCertificateAccIndex = (e, el, i) => {
    this.setState(prevState => ({ currentCertIndex: i, isCerExpand: !prevState.isCerExpand }))
  }
  setEmploymentAccIndex = (e, el, i) => {
    this.setState(prevState => ({ currentEmpIndex: i, isEmpExpand: !prevState.isEmpExpand }))
  }
  setAccIndex = (e, el, i) => {
    this.setState(prevState => ({ currentEduIndex: i, isExpand: !prevState.isExpand }))
    for (let edu of this.state.educationLevel) {
      if (el && el.attributes && el.attributes.education_level &&
        el.attributes.education_level.id &&
        el.attributes.education_level.id !== undefined &&
        el.attributes.education_level.id !== "" && el.attributes.education_level.id !== "none") {
        if (edu?.id == el.attributes.education_level.id) {
          const eduObj = edu;
          if (eduObj.attributes.above_12) {
            this.setState({ selectedEducationLevel: true });
          } else {
            this.setState({ selectedEducationLevel: false })
          }
        }
      }
      else {
        this.setState({ selectedEducationLevel: null })
      }
      this.setCollegeUpskilling(el)
    }
  }
  setCollegeUpskilling = (el) => {
    if (el && el.attributes && el.attributes.college &&
      el.attributes.college.name &&
      el.attributes.college.name === 'Other' &&
      el.attributes.college_name && el.attributes.college_name !== "") {
      this.setOtherCollegeInUpskilling()
    } else {
      this.setCollegeInUpskilling()
    }
  }
  handleELChange = (event, val, i, label) => {
    if (this.state.currentEduIndex !== i) {
      this.setState({ expand: false })
    }
    const { value } = event.target;
    let eduObj = {};
    let boardsObj = {};
    let stdObj = {};
    let degreeObj = {};
    let courseObj = {};
    let collegeObj = {};
    let spzObj = {};
    for (let edu of this.state.educationLevel) {
      if (edu?.id == value) {
        eduObj = edu;
      }
    }
    for (let board of this.state.boards) {
      if (board?.id == value) {
        boardsObj = board;
      }
    }
    for (let course of this.state.courses) {
      if (course?.id == value) {
        courseObj = course;
      }
    }
    for (let spec of this.state.specialization) {
      if (spec?.id == value) {
        spzObj = spec;
      }
    }

    stdObj = this.stdObject(value)
    degreeObj = this.degreeObject(value)
    collegeObj = this.collegeObject(value)

    this.setState({ currentEduIndex: i });
    let objList = { eduObj, boardsObj, stdObj, degreeObj, courseObj, collegeObj, spzObj }
    this.tempAddEdu(val, value, label, objList);
  }
  stdObject = (value) => {
    for (let standard of this.state.standards) {
      if (standard?.id == value) {
        return standard;
      }
    }
  }
  degreeObject = (value) => {
    for (let deg of this.state.degree) {
      if (deg?.id == value) {
        return deg;
      }
    }
  }
  collegeObject = (value) => {
    for (let col of this.state.colleges) {
      if (col?.id == value) {
        return col;
      }
    }
  }
  tempAddEdu = (val: any, value: any, label: any, objList: any) => {
    let tempaddEdu = val.attributes;
    for (let x in tempaddEdu) {
      if (x === label) {
        this.labelCheck(tempaddEdu, value, label, objList);
      }
    }
    if (tempaddEdu?.board?.id !== "none" && tempaddEdu?.standard?.id !== "none" &&
      tempaddEdu?.board?.id !== "" && tempaddEdu?.standard?.id !== "") {
      this.setState({ isDisabled: false })
    }
    if (tempaddEdu?.degree?.id !== "none" && tempaddEdu?.educational_course?.id !== "none" &&
      tempaddEdu?.degree?.id !== "" && tempaddEdu?.educational_course?.id !== "") {
      this.setState({ isDisabled: false })
    }
    let temp = this.state.upskillingELValues.map((addeducation: any, index: any) => {
      if (addeducation.id == val.id) {
        return Object.assign(addeducation, {
          attributes: tempaddEdu,
          isEdited: true,
        })
      }
      return addeducation;
    });
    this.setState({ upskillingELValues: temp });
  }
  labelCheck = (tempaddEdu: any, value: any, label: any, objList: any) => {
    let { eduObj, boardsObj, stdObj, degreeObj, courseObj, collegeObj, spzObj } = objList;
    if (label == 'education_level') {
      this.educationLevel(tempaddEdu, value, eduObj, label);
    } else if (label === 'board') {
      this.board(tempaddEdu, boardsObj, value, label);
    } else if (label === 'standard') {
      this.standard(tempaddEdu, stdObj, value, label);
    } else if (label === 'subjects') {
      this.subjects(tempaddEdu, value, label);
    } else if (label === 'degree') {
      this.degree(tempaddEdu, label, value, degreeObj);
    } else if (label === 'educational_course') {
      this.educationalCourse(tempaddEdu, label, value, courseObj);
    } else if (label === 'specialization') {
      this.specialization(tempaddEdu, label, value, spzObj);
    } else if (label === 'college') {
      this.college(tempaddEdu, label, value, collegeObj);
    } else {
      tempaddEdu[label] = value
    }
  }
  educationLevel = (tempaddEdu: any, value: any, eduObj: any, label: any) => {
    for (let educationLevel of this.state.educationLevel) {
      if (educationLevel.id == value) {
        const eduObj = educationLevel;
        if (eduObj.attributes.above_12) {
          this.setState({ selectedEducationLevel: true })
        } else {
          this.setState({ selectedEducationLevel: false })
        }
      }
    }
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: eduObj?.attributes?.name,
        rank: eduObj?.attributes?.rank,
        above_12: this.state.selectedEducationLevel
      })
    } else {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: eduObj?.attributes?.name,
        rank: eduObj?.attributes?.rank,
        above_12: this.state.selectedEducationLevel
      })
    }
  }
  board = (tempaddEdu: any, boardsObj: any, value: any, label: any) => {
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: boardsObj?.attributes?.name,
        rank: boardsObj?.attributes?.rank
      })
    } else {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: boardsObj?.attributes?.name,
        rank: boardsObj?.attributes?.rank
      })
    }
  }
  standard = (tempaddEdu: any, stdObj: any, value: any, label: any) => {
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: stdObj?.attributes?.name,
        rank: stdObj?.attributes?.rank
      })
    } else {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: stdObj?.attributes?.name,
        rank: stdObj?.attributes?.rank
      })
    }
  }
  subjects = (tempaddEdu: any, value: any, label: any) => {
    let tempArr = this.state.upskillingELValues;
    for (let val of value) {
      if (val !== undefined) {
        const element = val;
        for (let sub of this.state.subjects) {
          const item = sub;
          if (element == item.id) {
            tempArr[this.state.currentEduIndex].attributes.subjects.push(item);
          }
        }
      }
    }
    this.setState({ upskillingELValues: tempArr })
    tempaddEdu[label] = _.uniq([...tempArr[this.state.currentEduIndex]?.attributes?.subjects]);
  }
  degree = (tempaddEdu: any, label: any, value: any, degreeObj: any) => {
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: degreeObj?.attributes?.name,
        rank: degreeObj?.attributes?.rank
      })
    } else {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: degreeObj?.attributes?.name,
        rank: degreeObj?.attributes?.rank
      })
    }
  }
  educationalCourse = (tempaddEdu: any, label: any, value: any, courseObj: any) => {
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: courseObj?.attributes?.name,
        rank: courseObj?.attributes?.rank
      })
    } else {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: courseObj?.attributes?.name,
        rank: courseObj?.attributes?.rank
      })
    }
  }
  specialization = (tempaddEdu: any, label: any, value: any, spzObj: any) => {
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: spzObj?.attributes?.name,
        rank: spzObj?.attributes?.rank
      })
    }
    else {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: spzObj?.attributes?.name,
        rank: spzObj?.attributes?.rank
      })
    }
  }
  college = (tempaddEdu: any, label: any, value: any, collegeObj: any) => {
    if (collegeObj?.attributes?.name === "Other") {
      this.setOtherCollegeInUpskilling();
    }
    else { this.setCollegeInUpskilling(); }
    if (tempaddEdu[label] != null && tempaddEdu[label] != "none") {
      tempaddEdu[label] = Object.assign(tempaddEdu[label], {
        id: value,
        name: collegeObj?.attributes?.name,
        rank: collegeObj?.attributes?.rank
      })
    } else {
      tempaddEdu[label] = Object.assign({
        id: value,
        name: collegeObj?.attributes?.name,
        rank: collegeObj?.attributes?.rank
      })
    }
  }
  addEducations = () => {
    let education = {
      type: "education_level_profile",
      isEdited: true,
      isNewRecord: true,
      id: this.state.currentMaxIdOfAddEducation + 1,
      attributes: {
        id: '',
        above_12: null,
        name: '',
        education_level: {
          id: 'none',
          name: '',
          rank: '',
          above_12: null
        },
        board: {
          id: 'none',
          name: '',
          rank: ''
        },
        standard: {
          id: 'none',
          name: '',
          rank: ''
        },
        degree: {
          id: 'none',
          name: '',
          rank: ''
        },
        college: {
          id: 'none',
          name: '',
          rank: ''
        },
        specialization: {
          id: 'none',
          name: '',
          rank: ''
        },
        educational_course: {
          id: 'none',
          name: '',
          rank: ''
        },
        passing_year: '',
        college_name: '',
        school_name: '',
        subjects: []
      }
    };
    if (this.state.upskillingELValues.length !== 0) {
      let index = this.state.upskillingELValues.length == this.state.currentEduIndex ?
        this.state.currentEduIndex : this.state.upskillingELValues.length - 1;
      if (this.state.upskillingELValues[index]?.attributes?.education_level?.id !== "none" &&
        this.state.upskillingELValues[index]?.attributes?.education_level?.id !== "") {
        this.setEducationLevel(education, index)
      } else {
        window.alert("please fill the mandatory fields")
      }
    } else {
      this.setState({
        isDisabled: true,
        upskillingELValues: [...this.state.upskillingELValues, education],
        currentMaxIdOfAddEducation: this.state.currentMaxIdOfAddEducation + 1
      });
    }
  }
  setEducationLevel = (education, index) => {
    if (this.state.selectedEducationLevel) {
      if (this.state.upskillingELValues[index]?.attributes.degree.id !== "none" &&
        this.state.upskillingELValues[index].attributes?.educational_course.id !== "none" &&
        this.state.upskillingELValues[index]?.attributes?.degree.id !== "" &&
        this.state.upskillingELValues[index].attributes?.educational_course.id !== "") {
        this.setState({
          isDisabled: true, upskillingELValues: [...this.state.upskillingELValues, education],
          currentMaxIdOfAddEducation: this.state.currentMaxIdOfAddEducation + 1
        })
      } else {
        window.alert("please fill the mandatory fields")
        this.setState({ isDisabled: true })
      }
    } else {
      if (this.state.upskillingELValues[index]?.attributes?.board?.id !== "none" &&
        this.state.upskillingELValues[index]?.attributes?.standard?.id !== "none" &&
        this.state.upskillingELValues[index]?.attributes?.board?.id !== "" &&
        this.state.upskillingELValues[index]?.attributes?.standard?.id !== "") {
        this.setState({
          isDisabled: true, upskillingELValues: [...this.state.upskillingELValues, education],
          currentMaxIdOfAddEducation: this.state.currentMaxIdOfAddEducation + 1
        })
      } else {
        window.alert("please fill the mandatory fields")
        this.setState({ isDisabled: true })
      }
    }
  }
  addCertificates = () => {
    // this.setState({isAddEducatiolnDetails:true})
    let certificate = {
      attributes: {
        id: '',
        certification_course_name: "",
        provided_by: "",
        duration: "",
        completion_year: ""
      },
      isEdited: true,
      isNewRecord: true,
      id: this.state.currentMaxIdOfCertificate + 1,
      type: "certification"
    };
    if (this.state.upskillingCertificateValues.length !== 0) {
      let index = this.state.currentCertificateIndex;
      if (this.state.upskillingCertificateValues[index]?.attributes?.certification_course_name !== "") {
        this.setState({
          isDisabled: true,
          upskillingCertificateValues: [...this.state.upskillingCertificateValues, certificate],
          currentMaxIdOfCertificate: this.state.currentMaxIdOfCertificate + 1
        })
      } else {
        this.setState({ isDisabled: true })
        window.alert("please fill the mandatory fields")
      }
    } else {
      this.setState({
        isDisabled: true,
        upskillingCertificateValues: [...this.state.upskillingCertificateValues, certificate],
        currentMaxIdOfCertificate: this.state.currentMaxIdOfCertificate + 1
      })
    }
  }
  addEmployments = () => {
    // this.setState({isAddEducatiolnDetails:true})
    let employment = {
      attributes: {
        designation: "",
        domain_work_function: { id: "none", name: "", rank: "" },
        name: "",
        rank: "",
        id: "",
        last_employer: "",
      },
      isNewRecord: true,
      isEdited: true,
      id: this.state.currentMaxIdOfAddEmployment + 1,
      type: "employment_detail"
    };
    if (this.state.upskillingEmployementValues.length !== 0) {
      let index = this.state.currentEmploymentIndex;
      if (this.state.upskillingEmployementValues[index]?.attributes?.last_employer !== "") {
        this.setState({
          isDisabled: true,
          upskillingEmployementValues: [...this.state.upskillingEmployementValues, employment],
          currentMaxIdOfAddEmployment: this.state.currentMaxIdOfAddEmployment + 1
        })
      } else {
        window.alert("please fill the mandatory fields")
      }
    } else {
      this.setState({
        isDisabled: true,
        upskillingEmployementValues: [...this.state.upskillingEmployementValues, employment],
        currentMaxIdOfAddEmployment: this.state.currentMaxIdOfAddEmployment + 1
      })
    }
  }
  render() {
    const { completed, categories, activeStep, selectedSubCategory,
      selectedCategory, profileDetail, profileImgBase64, showSubCatArray, getSteps,
      userDetailList, activeLabel, profileImgBase64Obj, casteCategory } = this.state;
    return (
      <Container>
        <Grid container direction="row" alignItems="center" >
          <StepperWeb
            addEducations={this.addEducations}
            addCertificates={this.addCertificates}
            addEmployments={this.addEmployments}
            handleELChange={this.handleELChange}
            handleCertiChange={this.handleCertiChange}
            handleEmploymentChange={this.handleEmploymentChange}
            upskillingELValues={this.state.upskillingELValues}
            selectedEducationLevel={this.state.selectedEducationLevel}
            upskillingCertificateValues={this.state.upskillingCertificateValues}
            upskillingEmployementValues={this.state.upskillingEmployementValues}
            updateUpskilling={this.updateUpskilling}
            currentMaxIdOfAddEducation={this.state.currentMaxIdOfAddEducation}
            currentEduIndex={this.state.currentEduIndex}
            currentCertIndex={this.state.currentCertIndex}
            currentEmpIndex={this.state.currentEmpIndex}
            setAccIndex={this.setAccIndex}
            setCertificateAccIndex={this.setCertificateAccIndex}
            setEmploymentAccIndex={this.setEmploymentAccIndex}
            isExpand={this.state.isExpand}
            isCerExpand={this.state.isCerExpand}
            isEmpExpand={this.state.isEmpExpand}
            domainWork={this.state.domainWork}
            removeItem={this.removeItem}
            isDisabled={this.state.isDisabled}
            openNewFieldInUpskilling={this.state.openNewFieldInUpskilling}
            upskillSubject={this.state.upskillSubject}
            updateUserDetail={this.updateUserDetail}
            isFinishStep={this.state.isFinishStep}
            updateK12Detail={this.updateK12Detail}
            boards={this.state.boards}
            subjects={this.state.subjects}
            standards={this.state.standards}
            k12Subject={this.state.k12Subject}
            updateHigherEducationDetail={this.updateHigherEducationDetail}
            colleges={this.state.colleges}
            degree={this.state.degree}
            courses={this.state.courses}
            passingYear={this.state.passingYear}
            specialization={this.state.specialization}
            setOtherCollege={this.setOtherCollege}
            openNewField={this.state.openNewField}
            setCollege={this.setCollege}
            subject={this.state.subject}
            openNewComField={this.state.openNewComField}
            setComCollege={this.setComCollege}
            setOtherComCollege={this.setOtherComCollege}
            updateCompetitiveExam={this.updateCompetitiveExam}
            profileImageHandler={this.profileImageHandler}
            educationLevel={this.state.educationLevel}
            selectEducationLevel={this.state.selectEducationLevel}
            casteCategory={casteCategory}
            updateGovtDetail={this.updateGovtDetail}
            activeStep={activeStep}
            selectedCategory={selectedCategory}
            profileImgBase64Obj={profileImgBase64Obj}
            profileImgBase64={profileImgBase64}
            userDetailList={userDetailList}
            selectedSubCategory={selectedSubCategory}
            categories={categories}
            showSubCatArray={showSubCatArray}
            getSteps={getSteps}
            activeLabel={activeLabel}
            profileDetail={profileDetail}
            completed={completed}
            handleReset={this.handleReset}
            handleNext={this.handleNext}
            getAllCategories={this.getAllCategories}
            onBtnClick={this.onBtnClick}
            handleSubCategorySelection={this.handleSubCategorySelection}
            clickNext={this.clickNext}
            handleStep={this.handleStep}
          />
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const UserProfileBlockWebWithRouter = withRouter(UserProfileBlockWeb);
const UserProfileBlockWebWithLoader = withLoader(UserProfileBlockWebWithRouter);
const UserProfileBlockWebToast = withSnackbar(UserProfileBlockWebWithLoader);
const UserProfileBlockWebWithStyle = withStyles((theme) =>
  createStyles({
  })
)(UserProfileBlockWebToast);
export default withTranslation()(UserProfileBlockWebWithStyle);
