//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Divider,
  createStyles,
  Container,
  Typography
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export class Support extends React.Component {
  render() {
    const { classes, supportList } = this.props;
    return (
      <Container maxWidth="xl" style={{ padding: "0px", paddingLeft: "5rem" }}>
        <Grid container direction="column" alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <Typography variant="h4" >Help and support<Divider className={classes.underLineTxt}></Divider></Typography>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" style={{ marginTop: '1rem' }}>
            {supportList && this.props.supportList.map((e, i) => (
              <Grid key={`${i}_support`} item xs={10}>
                <Accordion
                  className={classes.custAccordion} elevation={0}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon className={classes.expandIconColor} />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header">
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item className={classes.txtHead1}>
                      <Typography variant="h3" className={classes.addTxt}>{e?.attributes?.question}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">{e?.attributes?.answer}</Typography>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>
              </Grid>))}
          </Grid>
        </Grid>
      </Container>
    );
  }
}
//@ts-ignore
const SupportWithRouter = withRouter(Support);
const SupportWithLoader = withLoader(SupportWithRouter);
const SupportToast = withSnackbar(SupportWithLoader);
const SupportWithStyle = withStyles((theme) =>
  createStyles({
    expandIconColor: {
      color: '#ffdddd',
      height: '2rem',
      width: '3rem'
    },
    underLineTxt: {
      backgroundColor: '#7764db',
      textDecoration: 'underline',
      height: '4px',
      width: '15rem'
    },
    custAccordion: {
      borderBottom: 'solid 2px #d3d3d3',
    }
  })
)(SupportToast);
export default withTranslation()(SupportWithStyle);
