//@ts-nocheck
import React from "react";
import {
  Avatar,
  Button, createStyles,
  Container,
  Grid,
  Hidden,
  InputBase,
  Typography, withStyles,
  IconButton,
  Paper,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from "@material-ui/icons/Search";
import insane from 'insane';
import { withTranslation } from "react-i18next";
//@ts-ignore
import { withRouter } from "react-router";
import { withSnackbar } from "../../../components/src/toast.web";
import withLoader from "../../../components/src/withLoader.web";
import { courseImg } from "./assets";
import CareerExpertViewAllController, { Props } from "./CareerExpertViewAllController.web";
import CategorysearchWeb from "../../categoriessubcategories/src/Categorysearch.web";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;
const imgUrl = "https://minio.b54423.dev.centralindia.az.svc.builder.cafe/sbucket/uploads/bx_block_contentmanagement/course/thumbnail/4/Thumbnail1.jpg";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const Title = ({ caption, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
      <Hidden smDown> <Typography align="center" variant="h4" className={classes.titleColor}>{caption}</Typography></Hidden>
      <Hidden mdUp> <Typography align="center" variant="h4">{caption}</Typography></Hidden>
    </Grid>);
};
const BookMarkList = ({ classes, e, bookNowPage, allBookmarklist, addBookmark, removeBookmark, rateReviewPage }) => {
  return (
    <Grid xs={12} item container direction="row" justify="space-between" alignItems="center" className={classes.askinfo}>
      <Grid xs={12} sm={9} item container direction="row" justify="flex-start" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={'auto'} align="center"  >
          <Button
            disabled={e?.attributes?.is_purchased}
            className={classes.askQBtn}
            color="primary"
            variant="outlined"
            data-testId="bookNowPage"
            onClick={() => bookNowPage(e)}>
            <Typography variant="body2" className="font-bold"> {e?.attributes?.is_purchased ? "Booked" : "Book now"}</Typography>
          </Button>
          {e?.attributes?.is_purchased ?
            <Button
              disabled={e?.attributes?.is_rating}
              variant="outlined"
              data-testId="rateReviewPage"
              onClick={() => { !e?.attributes?.is_rating && rateReviewPage(e?.attributes) }}>
              <Typography variant="body2" className="font-bold">{e?.attributes?.is_rating ? "Rating submitted" : "Rate & Review"}</Typography>
            </Button>
            : null
          }
          {localStorage.getItem('token') && <>
            {allBookmarklist && allBookmarklist.length > 0 && allBookmarklist.findIndex((ele) => { return ele.attributes.content.id == e.id }) > -1 ?
              <IconButton aria-label="bookmark" data-testId="removeBookmark" onClick={(event) => removeBookmark(e?.attributes?.id, event)}>
                <BookmarkIcon color="primary" />
              </IconButton>
              :
              <IconButton aria-label="bookmark" data-testId="addBookmark" onClick={(event) => addBookmark(e?.attributes?.id, event)}>
                <BookmarkBorderIcon color="primary" />
              </IconButton>
            }
          </>}
        </Grid>
      </Grid>
      <Grid xs={12} sm={3} item   >
        <Typography className={classes.profileamount} variant="subtitle2">
          <span>� </span><u>{e?.attributes?.price || 0} Onwards</u>
        </Typography>
      </Grid>
    </Grid>
  )
}
const RenderExpertsProfile = ({ classes, loader, profileList, gotoProfile, unfollow, follow, allBookmarklist, addBookmark, removeBookmark, history, bookNowPage, props, rateReviewPage }) => {
  const getTags = e => e?.attributes?.tags.map(x => x.name).filter(onlyUnique);
  return (
    <>
      <Grid container direction="column" justify="flex-start" className={classes.expertlist}>
        {profileList && profileList.length > 0 ? profileList.map((e: any) => {
          return (
            <Grid container direction="row" xs={12} sm={12} md={12} lg={10} xl={10} alignItems="center" spacing={2} className={classes.papaercontaier}>
              <Paper className={classes.paperHeight}>
                <Grid container direction="column" >
                  <Grid item container direction="row" justify="space-between" className={classes.profileroot} spacing={3}>
                    <Grid className={classes.profileImg} container alignItems="center" justify="center" item>
                      <Avatar alt="Remy Sharp" src={e?.attributes?.image ? e?.attributes?.image : imgUrl} className={classes.profilePic} />
                    </Grid>
                    <Grid item className={classes.profileContent} >
                      <Grid className={classes.profileC} item container direction="row" justify="space-between" alignItems="center">
                        <Grid container direction="column" justify="space-between">
                          <Grid item>
                            <Typography variant="h3">{e?.attributes?.name}</Typography>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" alignItems="flex-start" justify="space-between">
                              <Grid item xs={9}>
                                <Grid container direction="row" alignItems="flex-start" justify="space-between">
                                  <Grid item>
                                    <Typography>
                                      {e?.attributes?.status}
                                      {" "}{[1, 2, 3, 4, 5].map((_data: any) => {
                                        return (e?.attributes?.avg_rating >= _data ? <StarIcon className={classes.Icons} /> : <StarBorderIcon className={classes.Icons} />)
                                      })}
                                    </Typography>
                                  </Grid>
                                  {/* <Grid item><Typography>{" "}</Typography></Grid> */}
                                  {/* <Grid item xs={4}>
                               
                              </Grid> */}
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container alignItems="center" justify="flex-end" direction='row'>
                                  <Button
                                    className={e?.attributes?.followed ? classes.unFollowBtn : classes.followBtn}
                                    color="primary"
                                    data-testId="follow"
                                    onClick={() => e?.attributes?.followed ? unfollow(e?.attributes?.id) : follow(e?.attributes?.id)}
                                    variant="outlined">
                                    <Typography variant="body2" className="font-bold" >{e?.attributes?.followed ? 'Followed' : 'Follow'}</Typography>
                                  </Button>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          {e?.attributes?.description?.length > 150 ?
                            <div className="mt-1.5">
                              <Typography variant="subtitle2" color="primary" >
                                <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                                  __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 60).concat(`...<button><a href='/home/careerexpert/${e?.attributes?.id}'>[Read more]</a></button>`)
                                }}></span>
                              </Typography></div>
                            : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                              <span dangerouslySetInnerHTML={{
                                __html: insane(`${e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] })
                              }}></span>
                            </Typography></div>
                          }
                        </Grid>
                        <Grid className={classes.taginfo} item xs={12}>
                          <Grid container direction="row">
                            {getTags(e).slice(0, 8).map(tag => (<Grid item>
                              <Button className={classes.proTag} disabled color="primary" >
                                <Typography variant="caption">{tag}</Typography>
                              </Button>
                            </Grid>))}
                            {getTags(e).length > 8 &&
                              <Grid item>
                                <Button className={classes.proTag} color="primary" >
                                  <Typography onClick={() => gotoProfile(e?.attributes?.id)} variant="caption">{`+${getTags(e).length - 8} more`}</Typography>
                                </Button>
                              </Grid>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <BookMarkList data-testId="bookMarkList" {...{ classes, e, allBookmarklist, addBookmark, removeBookmark, bookNowPage, rateReviewPage }} />
                </Grid>
              </Paper>
            </Grid>
          )
        }) :
          <Grid item>
            {!loader && profileList.length <= 0 && <Typography variant="body2">No Results Found</Typography>}
          </Grid>
        }
      </Grid>

      <div className={classes.spacer} />
    </>
  )
};
export class CareerExpertViewAllWeb extends CareerExpertViewAllController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.loadData();
    if (localStorage.getItem('token')) {
      this.getAllBookmarkedExperts();
    }
  }
  loadData = () => {
    let data = this.state.filterdata;
    if (this.props.history?.location?.state?.catId) {
      data[1].value.push(JSON.stringify(this.props.history?.location?.state?.catId))
    }
    this.setState({ filterdata: data }, () => {
      this.getFilterOptions(this.state?.filterdata[1]?.key, this.state.filterdata);
      this.getContentsBysearchCriteria(this.state.filterdata);
    })
  }
  setSearchdata = (e: any) => {
    this.setState({ searchinfo: e.target?.value }, () => {
      this.getContentsBysearchCriteria(this.state.filterdata);
    })
  }
  handleClick = (data: any) => {
    if (this.state.selectedType == data.id) {
      this.setState({ selectedType: 'none' })
    }
    else {
      this.setState({ selectedType: data.id }, () => {
        if (data.id == 0 || data.id == 1) {
          this.getFilterOptions(data.key, this.state.filterdata)
        }
      })
    }
  }
  handleToggle = (ele: any, filterinfo: any, index: any, id: any) => {
    if (filterinfo[index].value.indexOf(ele.id) == -1) {
      if (id == 0 || id == 1) {
        filterinfo[index].value.push(ele.id)
      }
      else if (id == 4) {
        filterinfo[index].value = [ele.id]
      }
    } else {
      filterinfo[index].value.splice(filterinfo[index].value.indexOf(ele.id), 1)
    }
    this.setState({ filterdata: filterinfo })
  }
  applyFilter = (data: any) => {
    this.setState({ pageNo: 0 }, () => {
      this.getContentsBysearchCriteria(data)
    })
  }
  clearFilter = (filterinfo: any) => {
    filterinfo = filterinfo?.map((ele: any) => {
      if (ele.hasOwnProperty('value')) {
        ele.value = []
        if (ele.hasOwnProperty('fromDate') && ele.hasOwnProperty('toDate')) {
          ele.fromDate = ''
          ele.toDate = ''
        }
        if (ele.hasOwnProperty('fromPrice') && ele.hasOwnProperty('toPrice')) {
          ele.fromPrice = ''
          ele.toPrice = ''
          ele.data = []
        }
        else {
          ele.data = []
        }
        return ele
      }
    })
    // filterinfo[1].value.push(this.props.history?.location?.state?.catId)
    this.setState({ filterdata: filterinfo, pageNo: 0, ratingValue: null, selectedType: 1, sliderValue: [0, 0] }, () => {
      this.getFilterOptions('category', this.state.filterdata)
      this.getContentsBysearchCriteria(this.state.filterdata)
    })
  }
  handlePriceChange = (e: any, filterinfo: any, index: any) => {
    filterinfo[index][e?.target?.id] = e.target?.value
    this.setState({ filterdata: filterinfo })
  }
  onPageChange = (e, page) => {
    this.setState({ currentPage: page }, () => {
      this.loadData();
    });
  };
  gotoProfile = id => {
    this.props.history.push(`/home/careerexpert/${id}`)
  }
  handleSliderChange = (e: any, newValue: any) => {
    this.setState({ sliderValue: newValue })
  }
  valuetext = (value) => {
    return `${value}`;
  }
  handleRatingChange = (e: any, newValue: any) => {
    this.setState({ ratingValue: newValue })
  }
  rateReviewPage = (careerExpertData: any) => {
    this.props.history.push({ pathname: '/home/carrerexpert/rating_review', state: { careerExpertData } })
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  // payment BookNow
  bookNowPage = (profile: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      this.setState({ open: false });
      this.props.history.push({ pathname: '/home/booknow', state: { profile } })
    } else {
      this.openDialog(true)
    }
  }
  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { setSearchdata, onPageChange, handleOpenDialog, gotoProfile, unfollow, follow, addBookmark, removeBookmark, bookNowPage } = self;
    const { currentPage = 1, totalPage = 1, expertProfile, allBookmarklist, loader } = this.state;
    return (
      <>
        <div
          item
          className={classes.imgBg}
          xs={12}
          style={{ marginTop: "-0.25rem" }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.search}
          >
            <Grid item xs={1}>
              <SearchIcon color="secondary" />
            </Grid>
            <Grid item xs={11}>
              <InputBase
                placeholder="Search Here Experts, Articles, Exam, Courses"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={this.state.searchinfo}
                data-testId="setSearchdata"
                onChange={setSearchdata}
              />
            </Grid>
          </Grid>
        </div>
        <Container className={classes.videoCont} >
          <Grid container direction="row" >
            <Grid className={classes.lhsPanel} item >
              <Paper>
                {/* @ts-ignore */}
                <CategorysearchWeb
                  data-testId="categorySearchWeb"
                  onclick={this.handleClick}
                  handleToggle={this.handleToggle}
                  applyFilter={this.applyFilter}
                  clearFilter={this.clearFilter}
                  selectedType={this.state.selectedType}
                  filterdata={this.state.filterdata}
                  handleSliderChange={this.handleSliderChange}
                  sliderValue={this.state.sliderValue}
                  valuetext={this.valuetext}
                  ratingValue={this.state.ratingValue}
                  handleRatingChange={this.handleRatingChange}
                  handlePriceChange={this.handlePriceChange}
                />
              </Paper>
            </Grid>
            <Grid className={classes.rhsPanel} item >
              <Title data-testId="titleViewAll" {...{ caption: 'Available Experts', classes }} />
              <RenderExpertsProfile data-testId="renderExpertsProfile" {...{ classes, loader, profileList: expertProfile, handleOpenDialog, gotoProfile, unfollow, follow, allBookmarklist, addBookmark, removeBookmark, bookNowPage, props: this.props, rateReviewPage: this.rateReviewPage }} />
              {totalPage > 1 &&
                <Grid  >
                  <Grid xs={10} container className={classes.paginator} direction="row" alignItems="center">
                    <Pagination page={currentPage} data-testId="onPageChange" onChange={onPageChange} count={totalPage} variant="outlined" shape="rounded" />
                  </Grid>
                </Grid>}
            </Grid>
          </Grid>
          <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />
        </Container>
      </>
    );
  }
}
//@ts-ignore
const CareerExpertViewAllWebWithRouter = withRouter(CareerExpertViewAllWeb);
const CareerExpertViewAllWebWithLoader = withLoader(CareerExpertViewAllWebWithRouter);
const CareerExpertViewAllWebToast = withSnackbar(CareerExpertViewAllWebWithLoader);
const CareerExpertViewAllWebWithStyle = withStyles((theme) =>
  createStyles({
    search: {
      maxWidth: "44rem",
      width: '100%',
      height: "3.5rem",
      borderRadius: "1.5rem",
      webkitBackdropFilter: "blur(50px)",
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(207, 207, 207, 0.13)",
      marginTop: "0rem",
      paddingLeft: "1rem",
    },
    inputRoot: {
      width: "100%",
    },
    imgBg: {
      backgroundImage: `url(${courseImg})`,
      width: "100%",
      height: "505px",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      backgroundSize: "cover",
      justifyContent: "center",
      backgroundPosition: "center",
    },
    videoCont: {
      marginTop: '2rem',
      maxWidth: '100%'
    },
    paddingExamArVi: {
      marginBottom: '2rem'
    },
    titleColor: {
      color: 'black'
    },
    lhsPanel: {
      width: '25%',
      padding: '1rem',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    rhsPanel: {
      width: '75%',
      padding: '1rem',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    profileroot: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'column'
      }
    },
    profileImg: {
      width: '20%',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    profileContent: {
      width: '80%',
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    },
    profileamount: {
      color: '#f48b1b',
      marginLeft: '1.5rem',
      textAlign: 'end',
      [theme.breakpoints.down("xs")]: {
        textAlign: 'center',
      }
    },
    profilePic: {
      width: '9rem',
      height: '9rem',
      cursor: 'pointer',
      [theme.breakpoints.down("md")]: {
        display: "block",
        width: "8rem",
        height: "8rem",
        marginRight: "0.5rem"
      }
    },
    paginator: {
      marginTop: '2rem',
      marginBottom: '4rem',
      justifyContent: 'center',
    },
    paperHeight: {
      minHeight: '8rem',
      padding: '1.5rem',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      border: '1px solid #eaeaea',
      marginBottom: '2rem'
    },
    proTag: {
      color: 'black !important',
      background: 'lightgray',
      margin: '0.2rem'
    },
    followBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    unFollowBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      color: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    askQBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '10px !important',
      width: '11rem',
      height: '2rem',
      marginRight: '1rem',
      color: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        height: '1rem',
        padding: '.7rem !important',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    demoBtn: {
      border: '2px solid lightgray !important',
      marginLeft: '1rem',
      borderRadius: '10px !important',
      width: '10rem',
      height: '2rem',
      color: 'black !important',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'lightgray !important',
        color: 'black !important',
      },
    },
    papaercontaier: {
      display: 'block'
    },
    Icon: {
      color: "#f48b1b",
      fontSize: "1.2rem",
      marginBottom: '3px'
    },
    expertlist: {
      marginLeft: '0.6rem'
    },
    profileC: {
      padding: '1rem 0rem 0rem'
    },
    taginfo: {
      padding: '1rem 0rem'
    },
    askinfo: {
      marginTop: '0.5rem'
    },
    Icons: {
      color: "#f77c19"
    }
  })
)(CareerExpertViewAllWebToast);
export default withTranslation()(CareerExpertViewAllWebWithStyle);