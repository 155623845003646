//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, Button, createStyles, Container, Typography, CardMedia,
  Card, CardContent, InputBase, Hidden, Tooltip,
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import SchoolsController from "./SchoolsController.web";
import SearchIcon from '@material-ui/icons/Search';
import { collegeImg, schoolImg, prev, next } from "./assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScrollMenu from "react-horizontal-scrolling-menu";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export class Schools extends SchoolsController {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.getTopLocations()
    this.getFeatureSchools()
    this.getPopularSchools();
    this.getStandards()
    this.getEducationLevels()
    this.getSchoolsByLocation('Near You', {})
    this.getSchoolFeaturedCtas()
    this.getSchoolCtas()
  }

  render() {
    const { classes } = this.props;
    const { featureSchools, topLocations, selectedLocation, schoolsListByLocation, standardsList, selectedStanard, schoolsCtasList } = this.state;

    return (<>

      <Container maxWidth="xl" className={classes.bannercontainer} >
        <Grid item xs={12} className={classes.categoryViewall}>
          <img alt="" src={collegeImg} className={classes.collegeImage} />
          <Grid container direction="column" xs={12} lg={12} xl={12} alignItems='center' className={classes.uParallax} justify="center">

            <Grid container direction="row" alignItems='center' className={classes.categorySearch}>
              {/* <SearchWeb /> */}
              <Grid item xs={2} sm={1}>
                <SearchIcon color="secondary" />
              </Grid>
              <Grid item xs={10} sm={11} >
                <InputBase
                  placeholder="Search schools here"
                  classes={{
                    input: classes.inputInput,
                  }}
                  data-testId="searchData"
                  onChange={(e) => this.searchData(e)}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.featuredBlock}>
        <Grid container Direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2" className={classes.featuredText}>Featured</Typography>
          </Grid>
          <Grid item style={{ width: "calc(100% - 25px)" }}>
            <Grid container Direction="row" alignItems="center" className={classes.ctalist} >
              {schoolsCtasList && schoolsCtasList.length > 0 && schoolsCtasList.map((fc: any) =>
                <>
                  <Grid item xs={6} md={3} sm={4} lg={3} xl={2} key={fc.id} className={classes.ctaCard}>
                    <Card className={classes.featuredCard} data-testId="navigateCta" onClick={(e) => this.navigateCta(fc?.attributes?.href_link)}>
                      <CardMedia
                        className={classes.cover}
                        component='img'
                        image={fc?.attributes?.square_background_image}
                        title="Live from space album cover"
                      />

                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <br /><br />
      <Container className={classes.featuredBtns}>
        <Grid container direction="column" alignItems="flex-start" justify="flex-start" className={classes.paddingExam}>
          <Grid container direction="row" alignItems="center"  >
            <Grid item xs={12} className="w-full">
              <ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item ><ArrowBackIcon className={classes.arrowicon} />
                  {/* <img className="w-12 arrow-prev" src={prev} /> */}
                </Grid>}
                arrowRight={<Grid item><ArrowForwardIcon className={classes.arrowicon} />
                  {/* <img className="w-12 arrow-next" src={next} /> */}
                </Grid>}
                //@ts-ignore
                data={standardsList && standardsList.length > 0 && standardsList.map((ele: any) =>

                  <React.Fragment key={ele?.id}>
                    <Button key={ele?.id}
                      className={selectedStanard == ele.id ? `${classes.selectedBtn}` : `${classes.catBtn}`}
                      color="primary"
                      onClick={() => this.searchByStandard(ele)}
                      variant="outlined"
                    >
                      <Typography variant="subtitle2" className={classes.btnTypo}>{ele?.attributes?.name}</Typography>
                    </Button>

                  </React.Fragment>
                )}
              />

            </Grid>
          </Grid>
        </Grid>
        <br /><br /><br />
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.paddingExamArVi}>
            <Hidden smDown><Typography align="center" style={{ color: "#f48b1b" }} variant="h4">Featured Schools</Typography></Hidden>
            <Hidden mdUp><Typography align="center" style={{ color: "#f48b1b" }} variant="h3">Featured Schools</Typography></Hidden>
            {this.state.featureSchools && this.state.featureSchools.length > 4 &&
              <Button
                className={classes.showAllBtn}
                color="primary"
                onClick={() => this.props.history.push(
                  {
                    pathname: `/home/schoolssearch`,
                    state: {
                      featured: true
                    }
                  })}
                variant="outlined">
                <Hidden smDown><Typography variant="body2" className="font-bold">Show all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption">Show all</Typography></Hidden>
              </Button>
            }
          </Grid>
          <br />
          <Grid container Direction="row" alignItems="center">
            {featureSchools && featureSchools.map((ele: any) =>
              <Grid key={'Feature_' + ele.id} item xs={12} sm={4} md={3} className={classes.faturedschoolBlk}>
                <Card className={classes.featuredSchoolCard} onClick={(e) => this.navigateCta(ele?.attributes?.website_url)}>
                  <CardContent className={classes.schoolCardContent} >
                    <Grid item className={classes.faturedleftReview}>
                      {ele?.attributes?.logo && ele?.attributes?.logo?.url ? <img src={ele?.attributes?.logo?.url} className={classes.schoolreviewerImg} /> : <img src={schoolImg} className={classes.schoolreviewerImg} />}
                    </Grid>
                    <Grid item className={classes.schoolcard} >
                      <Grid container direction="column">
                        <Typography variant="h4" className={classes.rightReviewTitle}>{ele.attributes.name}</Typography>
                        <Typography variant="body2">Ranked 1 For IITs by The Week 2020</Typography>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {featureSchools && featureSchools.length == 0 &&
              <Grid item xs={12} sm={4} md={3} className={classes.faturedschoolBlk}>
                No schools to display
              </Grid>
            }

          </Grid>
        </Grid>
      </Container>
      <br />
      <Container className={classes.popularSchoolBlock}>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.popularCollageText}>
            <Grid item> <Hidden smDown><Typography align="center" style={{ color: "#f48b1b" }} variant="h4">Popular Schools</Typography></Hidden>
              <Hidden mdUp><Typography align="center" style={{ color: "#f48b1b" }} variant="h3">Popular Schools</Typography></Hidden>
            </Grid>
            {this.state.popularschools && this.state.popularschools.length > 4 &&
              <Grid item> <Button
                className={classes.showAllBtn}
                color="primary"
                onClick={() => this.props.history.push(
                  {
                    pathname: `/home/schoolssearch`,
                    state: {
                      popular: true
                    }
                  })}
                variant="outlined">
                <Hidden smDown><Typography variant="body2" className="font-bold">Show all</Typography></Hidden>
                <Hidden mdUp><Typography variant="caption">Show all</Typography></Hidden>
              </Button></Grid>
            }
          </Grid>
          <br />
          <Grid container className={classes.paddingExamAr}>
            <Grid item xs={12} className="w-full">
              <Hidden smDown>
                <ScrollMenu
                  clickWhenDrag={true}
                  dragging={true}
                  hideArrows={true}
                  hideSingleArrow={true}
                  scrollToSelected={true}
                  alignCenter={false}
                  transition={0.3}
                  translate={0}
                  wheel={false}
                  arrowLeft={<Grid item ><img className="w-12 arrow-prev" src={prev} /></Grid>}
                  arrowRight={<Grid item><img className="w-12 arrow-next" src={next} /></Grid>}
                  //@ts-ignore
                  data={this.state.popularschools && this.state.popularschools.length > 0 &&
                    this.state.popularschools.map((_data: any, idx: any) => (
                      <Grid item key={idx}>
                        <Card className={classes.popularSchoolCardGrid}>
                          <CardContent >
                            <Grid item className={classes.popularcardImg} >
                              {_data?.attributes?.logo && _data?.attributes?.logo?.url ? <img src={_data?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}

                              <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                                <Typography variant="h3" className={classes.popularUnivarsityTitle}>{_data?.attributes?.name}</Typography>
                                <Typography variant="body" className={classes.location} >{_data?.attributes?.location?.data?.attributes?.name}</Typography>

                              </Grid>
                            </Grid>

                            <Grid container direction="row" alignItems="flex-start">
                              <Grid item xs={6} sm={6} >
                                <Grid container direction="column">
                                  <Typography variant="body2">Total fee</Typography>
                                  <Typography variant="body2">{this.numFormatter(_data?.attributes?.total_fees_min)} - {this.numFormatter(_data?.attributes?.total_fees_max)}</Typography>

                                </Grid>
                              </Grid>
                              <Grid item xs={6} sm={6} >
                                <Grid container direction="column">
                                  <Typography variant="body2">Median salary</Typography>
                                  <Typography variant="body2">{this.numFormatter(_data?.attributes?.median_salary)}</Typography>

                                </Grid>
                              </Grid>
                            </Grid>

                            <hr className={classes.hrline} />
                            <Grid container direction="row" alignItems="flex-start">
                              <Grid item xs={6} sm={6} >
                                <Grid container direction="column">
                                  <Typography variant="body2">Course Rating</Typography>
                                  <Typography variant="body2">{_data?.attributes?.course_rating ? _data?.attributes?.course_rating : '-'}</Typography>

                                </Grid>
                              </Grid>
                              <Grid item xs={6} sm={6} >
                                <Grid container direction="column">
                                  <Typography variant="body2">Ranked</Typography>
                                  <Tooltip title={_data?.attributes?.location?.data?.attributes?.name}>
                                    <Typography className={classes.cardranknfo} variant="body2">{_data?.attributes?.rank} Ranked in {_data?.attributes?.location?.data?.attributes?.name}</Typography>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.mt} >
                              <Button className={classes.visiteWebsiteBtn} disabled={!_data?.attributes?.website_url}>
                                <Typography variant="body2" className={classes.btnTypo} onClick={(e) => this.gotoWebsite(_data)}>Visite website</Typography>
                              </Button>
                              <a href={_data?.attributes?.brochure} target="_blank" download disabled={!_data?.attributes?.brochure}>	<Button disabled={!_data?.attributes?.brochure} className={classes.downloadBrochureBtn}>
                                <Typography variant="body2" className={classes.btnTypo}>Download Brochure</Typography>
                              </Button></a>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  } />
              </Hidden>
              <Hidden mdUp> <ScrollMenu
                clickWhenDrag={true}
                dragging={true}
                hideArrows={true}
                hideSingleArrow={true}
                scrollToSelected={true}
                alignCenter={false}
                transition={0.3}
                translate={0}
                wheel={false}
                arrowLeft={<Grid item><img className="w-32 arrow-prev" src={prev} /></Grid>}
                arrowRight={<Grid item><img className="w-32 arrow-next" src={next} /></Grid>}
                data={this.state.popularschools &&
                  this.state.popularschools.map((_data: any, index: number) => (
                    <Card className={classes.popularSchoolCardGrid}>
                      <CardContent className={classes.popularSchoolContent}>
                        <Grid item className={classes.popularcardImg}>
                          {_data?.attributes?.logo && _data?.attributes?.logo?.url ? <img src={_data?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}
                          <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                            <Typography variant="h3" className={classes.popularUnivarsityTitle}>{_data?.attributes?.name}</Typography>
                            <Typography variant="body" className={classes.location} >{_data?.attributes?.location?.data?.attributes?.name}</Typography>

                          </Grid>
                        </Grid>

                        <Grid container direction="row" alignItems="flex-start">
                          <Grid item xs={6} sm={6} >
                            <Grid container direction="column">
                              <Typography variant="body2">Total fee</Typography>
                              <Typography variant="body2">{this.numFormatter(_data?.attributes?.total_fees_min)} - {this.numFormatter(_data?.attributes?.total_fees_min)}</Typography>

                            </Grid>
                          </Grid>
                          <Grid item xs={6} sm={6} >
                            <Grid container direction="column">
                              <Typography variant="body2">Median salary</Typography>
                              <Typography variant="body2">{this.numFormatter(_data?.attributes?.median_salary)}</Typography>

                            </Grid>
                          </Grid>
                        </Grid>

                        <hr className={classes.hrline} />
                        <Grid container direction="row" alignItems="flex-start">
                          <Grid item xs={6} sm={6} >
                            <Grid container direction="column">
                              <Typography variant="body2">Course Rating</Typography>
                              <Typography variant="body2">{_data?.attributes?.course_rating ? _data?.attributes?.course_rating : '-'}</Typography>

                            </Grid>
                          </Grid>
                          <Grid item xs={6} sm={6} >
                            <Grid container direction="column">
                              <Typography variant="body2">Ranked</Typography>
                              <Tooltip title={_data?.attributes?.location?.data?.attributes?.name}>
                                <Typography className={classes.cardranknfo} variant="body2">{_data?.attributes?.rank} Ranked in {_data?.attributes?.location?.data?.attributes?.name}</Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.mt}>
                          <Button className={classes.visiteWebsiteBtn} disabled={!_data?.attributes?.website_url}>
                            <Typography variant="body2" className={classes.btnTypo} onClick={(e) => this.gotoWebsite(_data)} >Visite website</Typography>
                          </Button>
                          <a href={_data?.attributes?.brochure} target="_blank" download disabled={!_data?.attributes?.brochure}>  <Button disabled={!_data?.attributes?.brochure} className={classes.downloadBrochureBtn}>
                            <Typography variant="body2" className={classes.btnTypo}>Download Brochure</Typography>
                          </Button></a>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))
                } />
              </Hidden>
            </Grid>
            <Grid item xs={1} />

          </Grid>
          {this.state.popularschools && this.state.popularschools.length == 0 &&
            <Grid item xs={12} sm={4} md={3} className={classes.faturedschoolBlk}>
              No schools to display
            </Grid>
          }
        </Grid>
      </Container>
      <br />
      <Container className={classes.featuredBtns}>
        <Grid container direction="column" alignItems="flex-start" spacing={2} className={classes.paddingExamArVi}>
          <Grid item>
            <Typography variant="h4" className={classes.arrowicon} >Top 10 Locations</Typography>
          </Grid>
          <Grid item container direction="row" alignItems="center"  >
            <Grid item xs={12} className="w-full">
              <Button className={classes.customBtn}
                variant="outlined" onClick={() => this.setSelectLocation("Near You", {})}
              >
                <LocationOnOutlinedIcon className={classes.locationicon} />
                <Typography variant="body2" className={classes.btnTypo}>Near You </Typography>
                {selectedLocation && selectedLocation == 'Near You' && <CheckCircleIcon className={classes.activeCheck} />}

              </Button>
              {topLocations && topLocations.length > 0 && topLocations.map((ele: any) =>
                <Button className={classes.customBtn} key={ele.id}
                  variant="outlined" onClick={() => this.setSelectLocation(ele?.attributes?.name, ele)}
                >
                  <img src={ele?.attributes?.logo} className={classes.locationimg} />
                  <Typography variant="body2" className={classes.btnTypo}>
                    {/* Gujarat (21051) */}{ele?.attributes?.name}
                  </Typography>
                  {selectedLocation && selectedLocation == ele?.attributes?.name && <CheckCircleIcon className={classes.activeCheck} />}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <br /><br />
        <Grid item xs={12}>

          <Grid container Direction="row" alignItems="center">
            {schoolsListByLocation && schoolsListByLocation.length > 0 && schoolsListByLocation.map((ele: any) =>
              <Grid item xs={12} sm={6} md={4} key={'ocation_' + ele.id}>
                <Card className={classes.popularSchoolCardGrid}>
                  <CardContent className={classes.popularSchoolContent}>
                    <Grid item className={classes.popularcardImg} >
                      {ele?.attributes?.logo && ele?.attributes?.logo?.url ? <img src={ele?.attributes?.logo?.url} className={classes.popularSchoolImg} /> : <img src={schoolImg} className={classes.popularSchoolImg} />}
                      <Grid container direction="column" className={classes.popularUnivarsityBlock}>
                        <Typography variant="h3" className={classes.popularUnivarsityTitle}>{ele?.attributes?.name}</Typography>
                        <Typography variant="body2" className={classes.location} >{ele?.attributes?.location?.data?.attributes?.name}</Typography>

                      </Grid>
                    </Grid>

                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Total fee</Typography>
                          <Typography variant="body2">{this.numFormatter(ele?.attributes?.total_fees_min)} - {this.numFormatter(ele?.attributes?.total_fees_min)}</Typography>

                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Median salary</Typography>
                          <Typography variant="body2">{this.numFormatter(ele?.attributes?.median_salary)}</Typography>

                        </Grid>
                      </Grid>
                    </Grid>

                    <hr className={classes.hrline} />
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="body2">Course Rating</Typography>
                          <Typography variant="body2">{ele?.attributes?.course_rating ? ele?.attributes?.course_rating : '-'}</Typography>

                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={6} >
                        <Grid container direction="column">
                          <Typography variant="caption">Ranked</Typography>
                          <Tooltip title={ele?.attributes?.location?.data?.attributes?.name}>
                            <Typography variant="caption" className={classes.cardranknfo}>{ele?.attributes?.rank} Ranked in {ele?.attributes?.location?.data?.attributes?.name}</Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.mt}>
                      <Button className={classes.visiteWebsiteBtn} disabled={!ele?.attributes?.website_url}>
                        <Typography variant="body2" className={classes.btnTypo} onClick={(e) => this.gotoWebsite(ele)}>Visite website</Typography>
                      </Button>
                      <a href={ele?.attributes?.brochure} target="_blank" download disabled={!ele?.attributes?.brochure}> <Button disabled={!ele?.attributes?.brochure} className={classes.downloadBrochureBtn}>
                        <Typography variant="body2" className={classes.btnTypo}>Download Brochure</Typography>
                      </Button></a>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {schoolsListByLocation && schoolsListByLocation.length == 0 && <Grid item xs={12} sm={6} md={4} >No schools to display</Grid>}
          </Grid>
        </Grid>
      </Container>
    </>

    );
  }
}
//@ts-ignore
const SchoolsWithRouter = withRouter(Schools);
const SchoolsWithLoader = withLoader(SchoolsWithRouter);
const SchoolsToast = withSnackbar(SchoolsWithLoader);
const SchoolsWithStyle = withStyles((theme) =>
  createStyles({
    categoryViewall: {
      margin: '3rem 3rem 1rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      // height: '25rem',
      [theme.breakpoints.down('lg')]: {
        //height: '15rem',
        margin: '1.5rem 2rem 0rem',
      },
      [theme.breakpoints.down('md')]: {
        margin: '0.5rem 0rem 0rem',
      },
    },
    popularSchoolBlock: {
      // padding:'0rem'
    },
    paddingExam: {
      marginLeft: '-3rem'
    },
    uParallax: {
      position: 'absolute',
      backgroundColor: 'rgb(6 55 106 / 53%)',
      width: '100%',
      height: '100%',
    },
    categorySearch: {
      width: '50%',
      backgroundColor: 'rgba(207, 207, 207, 0.13)',
      borderRadius: '17px',
      padding: '12px 15px 8px',
      color: '#fff',
      position: 'absolute',
      left: '25%',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        left: '19%',
      },


    },
    faturedschoolBlk: {
      padding: '0.5rem 2rem 0.5rem 0',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5rem',
      },
    },
    featuredText: {
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      color: ' #222',
      fontSize: '1rem',
    },

    leftReview: {
      width: '80px',
      minWidth: '80px',
      height: '70px',
      marginRight: '10px',
    },
    rightReview: {
      width: 'calc(100% - 90px)',
      minWidth: 'calc(100% - 90px)',
    },
    rightReviewTitle: {
      fontSize: '18px',
      marginBottom: '0.3rem',
      color: '#7764db'
    },
    reviewerImg: {
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    schoolreviewerImg: {
      height: '100px',
      objectFit: 'cover',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      width: '100%',
    },
    customBtn: {
      height: '90px',
      width: '90px',
      borderColor: 'rgba(112, 112, 112, 0.13) !important',
      boxShadow: '0 3px 9px 0 rgb(167 167 167 / 13%)',
      padding: '5px !important',
      marginBottom: '0.5rem',
      whiteSpace: 'initial',
      marginRight: "1rem",
      '&:hover': {
        borderColor: '#f48b1b !important',
        color: '#f48b1b !important',
        backgroundColor: '#fff !important',
      },
      [theme.breakpoints.down('lg')]: {
        marginRight: '2.9rem',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '2.9rem',
      },
      '& > span': {
        display: 'flex',
        flexDirection: 'column'
      },

    },
    activeCustomBtn: {
      height: '90px',
      width: '90px',
      boxShadow: '0 3px 9px 0 rgb(167 167 167 / 13%)',
      padding: '5px !important',
      marginRight: '2.5rem',
      marginBottom: '0.5rem',
      borderColor: '#f48b1b !important',
      color: '#f48b1b !important',
      backgroundColor: '#fff !important',
      whiteSpace: 'initial',
      [theme.breakpoints.down('lg')]: {
        marginRight: '1.5rem',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '1rem',
      },
      '& > span': {
        display: 'flex',
        flexDirection: 'column'
      },

    },
    locationimg: {
      width: '3rem',
      height: '3rem',
      objectFit: 'contain'
    },
    popularSchoolCardGrid: {
      boxShadow: '0 3px 6px 0 rgba(131, 124, 124, 0.6)',
      border: 'solid 1px #bfb4b4',
      backgroundColor: '#fff',
      marginRight: '1.5rem',
      marginBottom: '1.5rem',
      width: '20rem',
      [theme.breakpoints.down('xs')]: {
        marginRight: '0rem',
        marginBottom: '0.5rem',
      },
    },
    popularcardImg: {
      position: 'relative',
      marginBottom: "0.85rem"
    },
    popularSchoolImg: {
      height: '130px',
      objectFit: 'cover',
      width: '100%',
    },
    popularUnivarsityBlock: {
      position: 'absolute',
      top: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
      padding: '10px',
    },
    popularUnivarsityTitle: {
      fontSize: '1.2rem',
      color: '#fff',
    },
    visiteWebsiteBtn: {
      backgroundColor: '#7764db',
      padding: '7px 22px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      '&:hover': {
        backgroundColor: '#5840d7 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.6,

      },
    },
    downloadBrochureBtn: {
      backgroundColor: '#fa6400',
      padding: '7px 4px',
      color: '#fff !important',
      marginBottom: '0.5rem',
      '&:hover': {
        backgroundColor: '#dd5a03 !important',
        color: '#fff !important'
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.7,

      },
    },
    activeCheck: {
      color: '#fa6400',
      position: 'absolute',
      top: '-8px',
      right: '-8px',
    },
    cardranknfo: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: 'inherit'
    },
    selectedBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      color: '#ffffff !important',
      marginRight: '15px',
      width: '173px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    btnTypo: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      overflow: 'hidden',
      fontSize: '10px',
    },
    catBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '173px',
      height: '3rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      }
    },
    inputInput: {
      '&::placeholder': {
        color: '#fff !important',
        opacity: '1 !important',

      }
    },
    cover: {
      height: '6.1rem',
      cursor: 'pointer',
    },
    collegeImage: {
      width: "100%"
    },
    bannercontainer: {
      padding: "1rem"
    },
    featuredBlock: {
      padding: "0.8rem 0.5rem",
      backgroundColor: "#eeeeee"
    },
    arrowicon: {
      color: '#f48b1b'
    },
    location: {
      color: '#fff'
    },
    hrline: {
      margin: '0.5rem 0'
    },
    locationicon: {
      fontSize: '2rem',
      color: '#7764db'
    },
    mt: {
      marginTop: '0.5rem'
    },
    ctalist: {
      flexWrap: "inherit",
      overflowX: "auto"
    },
    ctaCard: {
      padding: "0 0.15rem",
      minWidth: "20rem"
    },
    schoolCardContent: {
      padding: "0px"
    },
    schoolcard: {
      padding: '8px'
    },
    popularSchoolContent: {
      padding: "10px"
    },
    showAllBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '8px !important',
      color: '#ffffff !important',
      width: '150px',
      height: '3rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        color: '#ffffff !important',
        height: '1rem',
        padding: '.7rem !important',
        width: '5.5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    popularCollageText: {
      justifyContent: 'space-between'
    },
  })
)(SchoolsToast);
export default withTranslation()(SchoolsWithStyle);
