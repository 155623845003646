import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  isNotification: boolean;
  isInbox: boolean;
  data: any;
  box: any;
  box1: any;
  isLoading: boolean,
  bottomTab: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesktopNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  followbookmarkCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.followbookmarkCallId = "";
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isNotification: true,
      isInbox: false,
      box: false,
      box1: false,
      isLoading: false,
      data: [],
      bottomTab: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));



      this.setState({ txtSavedValue: value });

      if (responseJson) {

        console.log("responseJson", responseJson)
        if (apiRequestCallId === this.followbookmarkCallId) {

          if (responseJson?.data) {
            this.setState({ data: responseJson.data })
            console.log("responseJson.data", responseJson.data)
          } else if (responseJson?.errors) {

            Alert.alert('Oops! Something went wrong', '', [
              {
                text: 'Cancel',
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
              },
              { text: 'OK', onPress: () => this.props.navigation.goBack() },
            ]);

          }
        }
        this.setState({ isLoading: false })
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.check();
  }

  async check() {
    this.setState({ isLoading: true })
    const token = await getStorageData("token");

    if (!token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followbookmarkCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followBookmark
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    console.log("-------------", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  toggle = (index: any) => {
    if (index === 0) {
      this.setState({ isNotification: true, isInbox: false });
    }
    if (index === 1) {
      this.setState({ isNotification: false, isInbox: true });
    }
  };
  // Customizable Area End
}
