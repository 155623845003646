//@ts-nocheck
import React, { useState } from "react";
import {
  withStyles,
  Grid,
  Button,
  Divider,
  createStyles,
  Container,
  Typography,
  Card,
  CardContent,
  AccordionSummary, Accordion, AccordionDetails, IconButton
} from "@material-ui/core";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import JobDetailsViewController, { Props } from './JobDetailsViewController.web';
//@ts-ignore
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withLoader from "../../../components/src/withLoader.web";
import { withSnackbar } from "../../../components/src/toast.web";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

const onlyUnique = (el: any, inx: number, arr: any) => arr.indexOf(el) === inx;

const RenderLocationView = props => {
  const { classes, locations = [] } = props;
  return (<Grid item>
    <Typography variant="body2" className={classes.location}>
      {locations.filter(onlyUnique).join(', ') || 'testlocation'}
    </Typography>
  </Grid>)
};
const RenderTagView = ({ tags = [] }) => {
  return (
    <Grid container direction="row" alignItems="center" justify="flex-start">
      {tags && tags.length > 0 && [...tags].sort((a, b) => a.id - b.id).map((e, index) =>
        <Grid style={{ marginRight: '0.5rem' }} item>
          <Button style={{ height: '1.5rem', color: 'black' }} disabled variant="contained" key={index + 1} >
            {e.name}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
const RenderTagListView = ({ tags = [], classes }) => {
  return (
    <Grid container item direction="row" alignItems="center" justify="space-between">
      {tags && tags.length > 0 && [...tags].sort((a, b) => a.id - b.id).map((e, index) => {
        if (index < 3) {
          return (
            <Grid item>
              <div className={classes.tagBtn} key={index + 1} >
                <Typography variant="body2"> {e}</Typography>
              </div>
            </Grid>)
        }
      }
      )}<br />
    </Grid>
  );
};
const RenderSimilarLocationView = props => {
  const { locations } = props;
  const location = [];
  locations?.forEach(x => {
    if (x?.attributes?.city) { location.push(x?.attributes?.city) }
  });
  return (<Grid item>
    <Typography variant="caption">
      {location.filter(onlyUnique).join(', ')}
    </Typography>
  </Grid>)
};
const RenderSimilarJobView = ({ classes, jobs, gotoSimilarJobDetails, openDialog }) => {
  return (
    jobs?.attributes?.companies && jobs?.attributes?.companies.map((c, index) => {
      if (index < 5) {
        return (
          <>
            <Grid container direction="row" alignItems="center" justify="space-between" item
              className={classes.pointer}
              onClick={evt => {
                if (localStorage.getItem('token')) {
                  evt.stopPropagation();
                  gotoSimilarJobDetails(c?.id, jobs?.attributes?.id)
                }
                else { openDialog(true) }
              }}>
              <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                <div className={classes.similarLogoPaper}>
                  <img src={c?.logo?.url} className={classes.similarCompanyLogo} alt="job-Image" />
                </div>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography style={{ color: 'black' }} variant="h3">{jobs?.attributes?.name}</Typography></Grid>
                  <Grid item> <Typography className={classes.orangeText} variant="h3">{c?.name}</Typography>
                  </Grid>
                  <RenderTagListView data-testId="RenderTagListView" {...{ tags: c?.tag_list || [], classes }} />
                  {jobs?.attributes?.locations && jobs?.attributes?.locations.length > 0 && <Grid container direction="row" alignItems='center'>
                    <Grid item>
                      <LocationOnOutlinedIcon className={classes.smalllocationIcon} />
                    </Grid>
                    <RenderSimilarLocationView data-testId="RenderSimilarLocationView" {...{ locations: jobs?.attributes?.locations, classes }} />
                  </Grid>}
                  <br />
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    })
  )
};
const JobDetailsAccordion = ({ isLogin, classes, attributes = {}, removeBookmark, addBookmark, applyJob, openDialog }) => {
  const [open, setOpen] = useState(false);
  const { name, id, bookmark, description, experience, locations, requirement, applied_job } = attributes;
  const uniqLocations = [];
  locations?.forEach(x => {
    if (x?.attributes?.city) { uniqLocations.push(x?.attributes?.city) }
  });
  const rx = new RegExp(/[\n\r]/);
  const requirements = (requirement || '').split(rx).filter(a => a);
  const locationC = (
    <Grid container direction="row" alignItems="flex-start" justify="space-between">
      <Grid item>
        <Grid container direction="row" alignItems='center'>
          <Grid item>
            <LocationOnOutlinedIcon className={classes.locationIcon} />
          </Grid>
          <RenderLocationView {...{ locations: uniqLocations.filter(onlyUnique), classes }} />
        </Grid>
      </Grid>
      {isLogin && <Grid item>
        <Grid item>
          <IconButton aria-label="bookmark" data-testId='bookmarkBtn' onClick={e => {
            e.stopPropagation()
            return bookmark ? removeBookmark(id) : addBookmark(id)
          }}>
            {bookmark ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon color="primary" />}
          </IconButton>
        </Grid>
      </Grid>}
    </Grid>
  );
  return (
    <>
      <Accordion style={{ width: '100%' }} expanded={true} data-testId='accordionOpen' onChange={() => setOpen(!open)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Grid item xs={12}>
            <Grid container direction="column" spacing={1}>
              <Grid container direction="row" alignItems="center" justify="space-between" >
                <Typography variant="h4">{name}</Typography>
                {applied_job ?
                  <Grid item container justify="center" alignItems="center" className={classes.appliedBtn}>
                    <Typography variant="body2">
                      Applied
                    </Typography>
                  </Grid>
                  : <Button
                    data-testId='applyBtn'
                    onClick={(e) => {
                      if (localStorage.getItem('token')) {
                        e.stopPropagation()
                        applyJob(id)
                      }
                      else { openDialog(true) }
                    }}
                    variant="contained"
                    type="button"
                    className={classes.viewBtn}>
                    Apply now
                  </Button>}
              </Grid>
              <Grid item container direction="row">
                <Grid item>
                  <Grid container direction="row" alignItems='center'>
                    <Grid item>
                      <BusinessCenterOutlinedIcon className={classes.locationIcon} />
                    </Grid>
                    <Grid item>
                      <Typography style={{ marginLeft: '0.2rem' }} variant="subtitle2" className={classes.orangeText}>{experience}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {attributes?.min_salary && attributes?.max_salary &&
                <Grid item container direction="row" spacing={1}>
                  <Grid item>
                    <AccountBalanceWalletIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{attributes?.min_salary}{"-"}{attributes?.max_salary}</Typography>
                  </Grid>
                </Grid>}
              <Grid item container direction="row">
                <Grid item>
                  <Typography variant="body2">{attributes?.job_type}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="row">
                <Grid item>
                  <Typography variant="subtitle2">{description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            <Grid item>
              <Typography style={{ color: '#4f4f4f' }} variant="body2">Requirements</Typography>
            </Grid>
            <Grid item>
              <ul style={{ padding: 'revert', listStyle: 'disc' }}>
                {requirements.map(req => <li style={{ fontSize: '1.2rem' }}><Typography variant="subtitle2">{req}</Typography></li>)}
              </ul>
            </Grid>
            <Grid xs={12}>
              <br />
              <br />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid style={{ marginTop: '-4rem', zIndex: 1, marginLeft: '1rem' }} xs={12}>
        {locationC}
      </Grid>
    </>
  );
};
const RecommandedJobs = ({ classes, applyJob, recommandedJobs, openDialog }) => {
  return (
    recommandedJobs && recommandedJobs?.length > 0 ? recommandedJobs.map((e, i) => {
      return (
        <Grid item xs={12} key={i}>
          <Card className={classes.jobcontent}>
            <CardContent>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Typography variant="h3">{e?.job?.attributes?.name}</Typography>
                {e?.job?.attributes?.applied_job ?
                  <Grid container justify="center" alignItems="center" className={classes.appliedBtn}>
                    <Typography variant="body2">
                      Applied
                    </Typography>
                  </Grid>
                  : <Button
                    data-testId="applyNowBtn"
                    onClick={(evt) => {
                      if (localStorage.getItem('token')) {
                        evt.stopPropagation()
                        applyJob(e?.job?.attributes?.id)
                      }
                      else { openDialog(true) }
                    }}
                    variant="contained"
                    type="button"
                    className={classes.viewBtn}>
                    Apply now
                  </Button>}
              </Grid>
              <Grid container direction="row">
                <Grid item>
                  <Grid container direction="row" alignItems='center'>
                    <Grid item>
                      <BusinessCenterOutlinedIcon className={classes.locationIcon} />
                    </Grid>
                    <Grid item>
                      <Typography style={{ marginLeft: '0.2rem' }} variant="subtitle2" className={classes.orangeText}>{e?.job?.attributes?.experience}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {e?.job?.attributes?.positions?.attributes?.min_salary && e?.job?.attributes?.positions?.attributes?.max_salary &&
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <AccountBalanceWalletIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{e?.job?.attributes?.positions?.attributes?.min_salary}{"-"}{e?.job?.attributes?.positions?.attributes?.max_salary}</Typography>
                  </Grid>
                </Grid>}
              <br />
              <Grid container direction="row">
                <Grid item>
                  <Typography variant="subtitle2">{e?.job?.attributes?.description}</Typography>
                </Grid>
              </Grid>
              <br />
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid item>
                  <Grid container direction="row" alignItems='center'>
                    {e?.job?.attributes?.locations.length > 0 &&
                      <>
                        <Grid item>
                          <LocationOnOutlinedIcon className={classes.locationIcon} />
                        </Grid>
                        <RenderLocationView data-testId="RenderLocationView" {...{ locations: e?.job?.attributes?.locations.map(x => x.attributes.city).filter(y => y).filter(onlyUnique), classes }} />
                      </>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )
    }) : <Grid item xs={12}><Typography variant="body2">No Jobs Available</Typography></Grid>
  )
};

//@ts-ignore
export class JobDetailsViewWeb extends JobDetailsViewController {
  constructor(props: Props) {
    super(props);
  }
  openDialog = (val: any) => {
    if (val) {
      this.setState({ open: true });
    }
    else {
      if (localStorage.getItem("confirmDialog") === "true") {
        this.setState({ open: val });
      } else {
        this.setState({ open: val });
        localStorage.clear();
      }
    }
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.showJobDetails();
    if (localStorage.getItem("token")) { this.recommandedJobs() }
  }
  gotoSimilarJobDetails = (companyId: any, jobId: any) => {
    const { history } = this.props;
    history.push(`/home/jobs/all-jobs/company/${companyId}/job/${jobId}`);
  };
  render() {
    //@ts-ignore
    const { classes } = this.props;
    const { removeBookmark, addBookmark, applyJob, openDialog, gotoSimilarJobDetails } = this;
    const { jobDetails, recommandedJobs, similarJobs } = this.state;
    const { attributes = {} } = jobDetails;
    const isLogin = localStorage.getItem('token');
    return (
      <>
        <Grid container direction="row" alignItems="center" className={classes.overlay}>
          <Grid item xs={12} className={classes.imgTag}>
            <img src={attributes?.logo} className={classes.jobHeaderImg} alt="job-Image" />
          </Grid>
          <Grid container direction="row" alignItems="flex-start" spacing={4} item xs={12} >
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid container direction="row" alignItems="center" item xs={12} spacing={2}>
                <Grid item>
                  <div className={classes.logoPaper}>
                    <img src={attributes?.logo} className={classes.companyLogo} alt="job-Image" />
                  </div>
                </Grid>
                <Grid item>
                  <Grid item container direction="column" spacing={1}>
                    <Grid item><Typography variant="h4" className={classes.orangeText}>{attributes.name}</Typography></Grid>
                    {attributes.company_addresses && attributes?.company_addresses.length > 0 &&
                      <Grid item container direction="row" alignItems="center" justify="space-evenly">
                        <Grid item><LocationOnOutlinedIcon className={classes.locationIcon} /></Grid >
                        <Grid item><Typography variant="body1">{attributes?.company_addresses[0]?.attributes?.address}</Typography></Grid></Grid>}
                    <Grid item><RenderTagView data-testId="RenderTagView" {...{ tags: attributes.tags || [] }} /></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Container maxWidth="xl">
          <Grid container direction="row" alignItems="center" spacing={1} justify="flex-end">
            <Grid item md={1} lg={1} xl={1}>
            </Grid>
            <Grid item md={10} lg={10} xl={10}>
              <br />
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Grid container direction="row" spacing={1} >
                  {/* <JobsFilter parentCallback={this.filterValue} showJobCategories={() => {}}/> */}
                </Grid>
              </Grid><br /><br />
              <Grid container direction="row" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                  <Grid container direction="row">
                    <div>
                      <Typography variant="h3" className={`${classes.jobHeading} ${classes.underLine}`}>Jobs</Typography>
                    </div>
                    <Divider style={{ width: '100%', color: 'grey' }} />
                  </Grid>
                  <br />
                  <Grid container direction="row">
                    <Typography variant="h4" className={classes.jobHeading}>{`About ${attributes.name}`}</Typography>
                  </Grid>
                  <br />
                  <Grid container direction="row">
                    <Typography variant="body2">{attributes.about}</Typography>
                  </Grid>
                  <br />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-start" spacing={5}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Grid container direction="row">
                    <Typography style={{ color: '#4f4f4f' }} variant="body2">{`Available Positions(${attributes.vacant_position_count || 0})`}</Typography>
                  </Grid>
                  <br />
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    {attributes?.positions ? <JobDetailsAccordion data-testId="JobDetailsAccordion" {...{ ...attributes?.positions, isLogin, classes, removeBookmark, addBookmark, applyJob, openDialog }} /> : null}
                    {/* Recommended for you */}
                    {isLogin && <Grid style={{ marginTop: '1rem' }} container direction="row">
                      <Typography style={{ textDecoration: 'underline' }} variant="h4" className={classes.jobHeading}>Recommended for you</Typography>
                    </Grid>}
                    {isLogin &&
                      (<>
                        <RecommandedJobs data-testId="RecommandedJobs" {...{ classes, applyJob, recommandedJobs, openDialog }} />
                      </>)}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                  <Grid direction="column" alignItems="center" className={classes.btnBox}>
                    <Grid item>
                      <Typography className={classes.personalisedTxt} variant="subtitle1">Jobs From Similar companies</Typography>
                    </Grid>
                    {similarJobs.map((jobs, i) => (
                      <>
                        {i > 0 && <Divider style={{ width: '100%', color: '#e6d3d3' }} />}
                        <br />
                        <RenderSimilarJobView data-testId="RenderSimilarJobView" {...{ classes, jobs, gotoSimilarJobDetails, openDialog }} />
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
              </Grid>
            </Grid>
          </Grid>
          {!localStorage.getItem("token") && <EmailAccountLoginBlock ParentCallback={this.state.open} openDialog={this.openDialog} />}
        </Container>
        <br />
        <br />
      </>
    );
  }
}
//@ts-ignore
const JobDetailsViewWebWithRouter = withRouter(JobDetailsViewWeb);
const JobDetailsViewWebWithLoader = withLoader(JobDetailsViewWebWithRouter);
const JobDetailsViewWebToast = withSnackbar(JobDetailsViewWebWithLoader);
const JobDetailsViewWebWithStyle = withStyles((theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer'
    },
    tagBtn: {
      borderRadius: "5px",
      padding: "3px 5px",
      backgroundColor: '#b5b5b54a',
    },
    smalllocationIcon: {
      color: '#707070',
      height: '1rem'
    },
    underLine: {
      textDecoration: 'underline',
      textDecorationThickness: '.2rem'
    },
    divider: {
      width: '100%',
      backgroundColor: '#000000'
    },
    personalisedTxt: {
      fontSize: '16px',
      fontWeight: 700,
      textAlign: 'center',
      [theme.breakpoints.down("lg")]: {
        fontSize: '12px',
      }
    },
    btnBox: {
      padding: '1rem',
      border: '1px solid #e1e1e1',
      boxShadow: '0 3px 6px 0 rgba(232, 226, 226, 0.42)'
    },
    locationIcon: {
      color: '#707070'
    },
    accordion: {
      borderRadius: '4px',
      border: 'solid 1px #dec9c9',
      boxShadow: 'none'
    },
    location: {
      color: '#707070'
    },
    jobcontent: {
      borderRadius: '2px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.14)'
    },
    jobHeading: {
      color: '#f48b1b',
    },
    logo: {
      height: '7rem',
      width: '8rem'
    },
    viewBtn: {
      backgroundColor: '#f48b1b',
      height: '2rem',
      width: '7rem',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#f48b1b',
        color: '#ffffff'
      }
    },
    appliedBtn: {
      borderRadius: 5,
      backgroundColor: '#7764db',
      height: '2rem',
      width: '7rem',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#7764db',
        color: '#ffffff'
      }
    },
    overlay: {
      position: 'relative'
    },
    jobHeaderImg: {
      backgroundImage: 'linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 117%)',
      height: '15rem',
      objectFit: 'contain',
      width: '100%'
    },
    orangeText: {
      color: '#F48B1B',
    },
    companyLogo: {
      height: '9rem',
      width: '9rem',
      borderRadius: 10,
      border: 'solid 1px rgba(112, 112, 112, 0.27)',
    },
    similarCompanyLogo: {
      height: '3rem',
      width: '3rem',
      objectFit: 'contain',
      [theme.breakpoints.down("sm")]: {
        width: '3.5rem',
        height: '3rem',
      }
    },
    logoPaper: {
      width: '100%',
      height: '100%',
      marginTop: '-2rem',
    },
    similarLogoPaper: {
      width: '4rem',
      height: '4rem',
      marginTop: '-2rem',
      padding: '0.5rem',
      borderRadius: 4,
      border: 'solid 1px rgba(112, 112, 112, 0.27)',
      backgroundColor: '#fff',
      [theme.breakpoints.down("md")]: {
        width: '3.5rem',
        height: '4rem',
      }
    },
  })
)(JobDetailsViewWebToast);
export default withTranslation()(JobDetailsViewWebWithStyle);