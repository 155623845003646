Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.apiContentType = "application/json";
exports.categoryApiContentType = "application/json";
// exports.subCategoryAPIEndPoint = "categories/sub_categories";
// exports.categoryAPIEndPoint = "categories/categories";
exports.categoriesAPIEndPoint = "bx_block_categories/categories?show_on_landing_page=true";
exports.subCategoriesAPIEndPoint = "bx_block_categories/sub_categories";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";

exports.selectLanguage = "bx_block_languageoptions/languages?type=app_languages";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.Notifications = "account_block/notification_messages";
exports.getRequestExperts = "bx_block_experts/request_career_experts"
exports.getUserDetailApiEndPoint = "bx_block_profile/profiles/id";
// Customizable Area End
