export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const home = require("../assets/home.png");
export const udemy = require("../assets/udemy.png");
export const aboutUs1 = require("../assets/aboutUs1.png");
export const aboutUs2 = require("../assets/aboutUs2.png");
export const aboutUs3 = require("../assets/aboutUs3.png");
export const aboutUs1dark = require("../assets/aboutUs1dark.png");
// export const UserGrey = require("../assets/user-grey.svg");
export const finish = require("../assets/finish.png");
export const govt_grey = require("../assets/govt_grey.png");
export const govt_orange = require("../assets/govt_orange.png");
export const higherEdu_grey = require("../assets/higheredu_grey.png");
export const higherEdu_orange = require("../assets/higheredu_orange.png");
export const k12_grey = require("../assets/k12_grey.png");
export const k12_orange = require("../assets/k12_orange.png");
export const upskilling_grey = require("../assets/upskilling_grey.png");
export const upskilling_orange = require("../assets/upskilling_orange.png");
export const UserGrey = ''
