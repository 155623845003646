//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, MenuItem, Button, createStyles, Typography, TextField
} from "@material-ui/core";
//@ts-ignore
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import { withTranslation } from 'react-i18next';
import withLoader from "../../../../components/src/withLoader.web";
import { withSnackbar } from "../../../../components/src/toast.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HigherEducationvalidationSchema } from "../UserBasicProfile.web.validation";
const PinkTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd0d0"
    },
    "& .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#7764db"
    },
    "& .MuiInputLabel-outlined": {
      color: "#7764db"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#ffd0d0"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ffd0d0"
    }
  },
})(TextField);
const MyMenuItem = withStyles({
  root: { color: '#7764db !important' },
  MuiMenuItem: {
    root: { color: '#7764db !important', }
  },
})(MenuItem);
export class HigherEducation extends React.Component {
  courseField = (classes, handleChange, handleBlur, values, courses, touched, errors) => {
    return (<>
      <PinkTextField
        variant="outlined"
        name="courses"
        style={{ width: '20rem' }}
        placeholder="Select Course"
        onChange={handleChange}
        SelectProps={{
          MenuProps: { classes: { paper: classes.selectBoard } }
        }}
        FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
        onBlur={handleBlur}
        error={touched.courses && errors.courses ? true : false}
        InputProps={{ classes: { input: classes.txtProps } }}
        helperText={
          touched.courses && errors.courses ? errors.courses : ""
        }
        value={values.courses}
        select
      >
        <MyMenuItem key={""} value="none" disabled>
          <Typography variant="subtitle1" className={classes.selectDisColor}>
            Choose Course*
          </Typography>
        </MyMenuItem>
        {courses && courses.map((option) => (
          <MyMenuItem key={option.id} value={option.id}>
            {option.attributes.name}
          </MyMenuItem>
        ))}
      </PinkTextField></>)
  }
  courseTextField = (handleChange, handleBlur, touched, errors, values, setFieldValue, funcObj) => {
    const { classes, courses, colleges, setOtherCollege, setCollege } = funcObj
    return (<>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start" justify="space-around">
          <Grid item className={classes.gridHe}>
            <Grid container direction="column" alignItems="flex-start" justify="center">
              <Grid item className={classes.txtHead}>
                <Typography variant="caption">Course*</Typography>
              </Grid>
              <Grid item>
                {this.courseField(classes, handleChange, handleBlur, values, courses, touched, errors)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridHe}>
            <Grid container direction="column" alignItems="flex-start" justify="center">
              <Grid item className={classes.txtHead}>
                <Typography variant="caption">College / University</Typography>
              </Grid>
              <Grid item>
                <PinkTextField
                  variant="outlined"
                  name="colleges"
                  style={{ width: '20rem' }}
                  placeholder="Select College"
                  data-testId="colleges"
                  onChange={(val: any) => {
                    colleges && colleges.map((option) => {
                      if (val.target.value === option.id && option.attributes.name === "Other") {
                        setOtherCollege();
                      } else {
                        setCollege();
                        setFieldValue("colleges", val.target.value);
                        setFieldValue("otherCollege", "");
                      }
                    })
                  }}
                  SelectProps={{
                    MenuProps: { classes: { paper: classes.selectBoard } }
                  }}
                  FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                  onBlur={handleBlur}
                  error={touched.colleges && errors.colleges ? true : false}
                  InputProps={{ classes: { input: classes.txtProps } }}
                  helperText={
                    touched.colleges && errors.colleges ? errors.colleges : ""
                  }
                  value={values.colleges}
                  select
                >
                  <MyMenuItem key={""} value="none" disabled>
                    <Typography variant="subtitle1" className={classes.selectDisColor}>
                      Choose College
                    </Typography>
                  </MyMenuItem>
                  {colleges && colleges.map((option) => (
                    <MyMenuItem key={option.id} value={option.id}>
                      {option.attributes.name}
                    </MyMenuItem>
                  ))}
                </PinkTextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>)
  }
  render() {
    const { classes, activeStep, getSteps, setOtherCollege, setCollege, openNewField, degree, colleges, courses, specialization } = this.props;
    const funcObj = { classes, courses, colleges, setOtherCollege, setCollege }
    return (
      <Grid item xs={12} >
        <Grid container direction="row" alignItems="center" justify="flex-start" className={classes.heading}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.headTxt}>Higher Education</Typography>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            colleges: this.props.profileDetail?.college?.data?.id || "none",
            courses: this.props.profileDetail?.educational_course?.data?.id || "none",
            degree: this.props.profileDetail?.degree?.data?.id || "none",
            specialization: this.props.profileDetail?.specialization?.data?.id || "none",
            passingYear: this.props.profileDetail?.passing_year && (JSON.stringify(this.props.profileDetail?.passing_year)) || "",
            otherCollege: this.props?.profileDetail?.college_name || ""
          }}
          validationSchema={HigherEducationvalidationSchema}
          // @ts-ignore
          onSubmit={(values: any, { setSubmitting }) => {
            this.props.updateHigherEducationDetail(values);
            setSubmitting(false)
          }}
        >
          {(formikProps) => {
            const {
              values,
              isValid,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              isSubmitting,
            } = formikProps;
            return (
              // @ts-ignore
              <Form autoComplete='off' onSubmit={handleSubmit}>
                <Grid container className={classes.persInfo} direction="row" justify="center" spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Degree*</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="degree"
                              style={{ width: '20rem' }}
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.degree && errors.degree ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={touched.degree && errors.degree ? errors.degree : ""}
                              value={values.degree}
                              select
                            >
                              <MyMenuItem value="none" disabled>
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Choose degree
                                </Typography>
                              </MyMenuItem>
                              {degree && degree.map((option) => (
                                <MyMenuItem key={option.id} value={option.id}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Specialization</Typography>
                          </Grid>
                          <Grid item>
                            <PinkTextField
                              variant="outlined"
                              name="specialization"
                              style={{ width: '20rem' }}
                              // placeholder="Select Specialization"
                              onChange={handleChange}
                              SelectProps={{
                                MenuProps: { classes: { paper: classes.selectBoard } }
                              }}
                              FormHelperTextProps={{ classes: { root: classes.helperTxt } }}
                              onBlur={handleBlur}
                              error={touched.specialization && errors.specialization ? true : false}
                              InputProps={{ classes: { input: classes.txtProps } }}
                              helperText={
                                touched.specialization && errors.specialization ? errors.specialization : ""
                              }
                              value={values.specialization}
                              select
                            >
                              <MyMenuItem key={""} value="none" disabled>
                                <Typography variant="subtitle1" className={classes.selectDisColor}>
                                  Choose Specialization
                                </Typography>
                              </MyMenuItem>
                              {specialization && specialization.map((option) => (
                                <MyMenuItem key={option.id} value={option.id}>
                                  {option.attributes.name}
                                </MyMenuItem>
                              ))}
                            </PinkTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.courseTextField(handleChange, handleBlur, touched, errors, values, setFieldValue, funcObj)}
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="flex-start" justify="space-around">
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          <Grid item className={classes.txtHead}>
                            <Typography variant="caption">Passing Year</Typography>
                          </Grid>
                          <Grid item>
                            <DatePicker
                              name="passingYear"
                              dateFormat="yyyy"
                              className={classes.datePicker}
                              onBlur={handleBlur}
                              showYearPicker
                              selected={(values.passingYear && new Date(values.passingYear))}
                              minDate={new Date("1900")}
                              maxDate={new Date()}
                              onChange={(val: any) => {
                                setFieldValue("passingYear", val);
                              }}
                              error={
                                touched.passingYear && errors.passingYear
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.passingYear && errors.passingYear
                                  ? errors.passingYear
                                  : ""
                              }
                              placeholderText="YYYY"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridHe}>
                        <Grid container direction="column" alignItems="flex-start" justify="center">
                          {openNewField ? <>
                            <Grid item className={classes.txtHead}>
                              <Typography variant="caption">College / University Name</Typography>
                            </Grid>
                            <Grid item>
                              <PinkTextField
                                variant="outlined"
                                name="otherCollege"
                                placeholder="Enter College"
                                onChange={handleChange}
                                InputProps={{ classes: { input: classes.txtProps } }}
                                value={values.otherCollege}
                              >
                              </PinkTextField>
                            </Grid></> : <Grid item style={{ width: '20rem' }}></Grid>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="row" alignItems="center" justify="space-evenly">
                      <Button type="submit" disabled={isSubmitting || !isValid || values.degree === "none" || values.courses === "none"}
                        className={classes.nextBtn}>
                        {activeStep === getSteps.length - 1 ? <Typography variant="h3">Finish</Typography> : <Typography variant="h3">Next</Typography>}
                      </Button>
                      <Button
                        onClick={this.props.handleReset} className={classes.cancelBtn}>
                        <Typography variant="h3">Cancel</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    )
  }
}
//@ts-ignore
const HigherEducationWithRouter = withRouter(HigherEducation);
const HigherEducationWebWithLoader = withLoader(HigherEducationWithRouter);
const HigherEducationWebToast = withSnackbar(HigherEducationWebWithLoader);
const HigherEducationWebWebWithStyle = withStyles((theme) =>
  createStyles({
    selectDisColor: { color: 'grey' },
    heading: {
      boxShadow: '0 8px 12px 0 rgb(213 210 210 / 16%)',
      border: 'solid 1px #e8e2e2',
      borderRadius: '7px',
      height: '5rem'
    },
    selectBoard: {
      backgroundColor: "#ffffff !important",
      marginTop: '0px !important',
      color: '#7764db !important',
      opacity: '10 !important'
    },
    txtHead: {
      color: '#4f4f4f',
      paddingBottom: '.5rem'
    },
    headTxt: {
      color: '#f48b1b',
      marginLeft: '1.5rem'
    },
    nextBtn: {
      backgroundColor: '#fa6400',
      height: '30px',
      width: '120px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#fa6400',
        color: '#ffffff'
      }
    },
    cancelBtn: {
      backgroundColor: '#b5b5b5',
      height: '30px',
      width: '120px',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#b5b5b5',
        color: '#000000'
      }
    },
    txtProps: {
      width: '18.5rem',
      color: '#7764db',
      fontWeight: 600
    },
    helperTxt: {
      margin: '0px',
      textAlign: 'left',
      color: '#f9593a',
      fontWeight: 'bold',
      marginTop: '.5rem'
    },
    persInfo: {
      marginTop: '20px'
    },
    datePicker: {
      border: '1px solid #ffd0d0',
      height: '3rem',
      width: '20rem',
      borderRadius: '4px',
      color: '#7764db',
      fontWeight: 600,
      paddingLeft: '10px',
      ' &:focus': {
        borderColor: '#ffd0d0'
      }
    }
  })
)(HigherEducationWebToast);
export default withTranslation()(HigherEducationWebWebWithStyle);