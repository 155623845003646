//@ts-nocheck
import React from "react";
import {
  Avatar,
  Button, createStyles,
  Container,
  Grid,
  Hidden,
  Typography, withStyles,
  Divider
} from "@material-ui/core";
import insane from 'insane';
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json';
//@ts-ignore
import { withRouter } from "react-router";
import { withSnackbar } from "../../../components/src/toast.web";
import withLoader from "../../../components/src/withLoader.web";
import { dummyImage, } from "./assets";
import BlogProfileController, { Props } from "./BlogProfileController.web";
import { withTranslation } from 'react-i18next';

const RenderBlogAndArticals = ({ classes, listItems }) => {
  return (
    <>
      <Grid container direction="column" alignItems="center" justify="flex-start">
        {listItems && listItems.length > 0 ? listItems.map((e: any) => (
          <Grid container direction="row" key={e.id} alignItems="flex-start" spacing={4} className={classes.blogContentGrid}>
            <Grid item xs={4} sm={4} md={3} lg={2} xl={2} className={classes.categoryHight}>
              {e.attributes.image ?
                <img alt="" className="rounded-lg h-40 w-48" src={e.attributes.image} />
                :
                <img alt="" className="border border-black border-solid rounded-lg h-40 w-48" src={dummyImage} />
              }
            </Grid>
            <Grid item xs={8} sm={8} md={9} lg={10} xl={10}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h3">{e.attributes.name || e.attributes.headline}</Typography>
                </Grid>
                <Grid item>
                  {(e?.attributes?.content || e?.attributes?.description)?.length > 300 ?
                    <div className="mt-1.5">
                      <Typography variant="subtitle2" color="primary" >
                        <span style={{ display: 'inline' }} dangerouslySetInnerHTML={{
                          __html: insane(`${e?.attributes?.content || e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', '&nbsp', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/blogsdetails/${e.id}'>[Read more]</a></button>`)
                        }}></span>
                      </Typography></div>
                    : <div className="mt-1.5"><Typography variant="subtitle2" color="primary" style={{ display: 'inline' }}>
                      <span dangerouslySetInnerHTML={{
                        __html: insane(`${e?.attributes?.content || e?.attributes?.description}`, { allowedTags: ['p', 'b', 'i', 'span', 'div', 'br', 'u', 'img', 'strong', 'em', 'a', 'ul', 'li'] }).replace('&nbsp', '').substring(0, 300).concat(`...<button><a href='/home/blogsdetails/${e.id}'>[Read more]</a></button>`)
                      }}></span>
                    </Typography></div>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )) :
          <Grid item>
            <Typography variant="body2">Will Update You Soon</Typography>
          </Grid>
        }
      </Grid>
      <div className={classes.spacer} />
    </>
  )
};
export class BlogProfileWeb extends BlogProfileController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.loadData();
    //@ts-ignore
    TimeAgo.addDefaultLocale(en);
    TimeAgo.locale(en);
  }
  render() {
    //@ts-ignore
    const self = this;
    const { classes } = this.props;
    const { follow, unfollow } = self;
    const { featureBlogs, authorDetails } = this.state;
    return (
      <>
        <div
          item
          className={classes.imgBg}
          xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            className={classes.profileContainer}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center">
              <Avatar alt="Remy Sharp" src={authorDetails?.attributes?.image} className={classes.profilePic} />
              <Typography variant="subtitle1" className={classes.authorname}>{authorDetails?.attributes?.name}</Typography>
              <Typography variant="subtitle2">{authorDetails?.attributes?.designation}</Typography>
              <Grid item xs={7}>
                <Typography variant="caption">{authorDetails?.attributes?.bio}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Grid className={classes.bookGrid} direction="row" container alignItems="center" >
                  <Button
                    // className={classes.bookBtn}
                    className={authorDetails?.attributes?.bookmark ? classes.unFollowBtn : classes.followBtn}
                    color="primary"
                    data-testId="blogFollowUnfollowBtn"
                    onClick={() => authorDetails?.attributes?.bookmark ? unfollow(authorDetails?.id) : follow(authorDetails?.id)}
                    variant="outlined">
                    <Typography variant="caption">{authorDetails?.attributes?.bookmark ? 'Followed' : 'Follow'}</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item className={classes.lineGrid}>
                <Divider className={classes.underline} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Container className={classes.videoCont}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={10}>
              <Grid container direction="row" alignItems="center" justify="space-between" className={classes.blogTitle} >
                <Hidden smDown> <Typography align="center" variant="h4" className={classes.blogTitleColor}>Recently Added Blogs</Typography></Hidden>
                <Hidden mdUp> <Typography align="center" variant="h3" className={classes.blogTitleColor}>Recently Added Blogs</Typography></Hidden>
              </Grid>
              <RenderBlogAndArticals data-testId="RenderBlogAndArticals" {...{ classes, listItems: featureBlogs }} />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
//@ts-ignore
const BlogProfileWebWithRouter = withRouter(BlogProfileWeb);
const BlogProfileWebWithLoader = withLoader(BlogProfileWebWithRouter);
const BlogProfileWebToast = withSnackbar(BlogProfileWebWithLoader);
export const BlogProfileWebWithStyle = withStyles((theme) =>
  createStyles({
    container: {
      // marginTop: "-66px",
    },
    inputRoot: {
      width: "100%",
    },
    viewAll: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      marginRight: '15px',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    paddingExamArVi: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.breakpoints.down("xs")]: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
      },
    },
    profileContainer: {
      height: '100%',
      backdropFilter: 'blur(11.2px)',
      marginTop: '10px',
    },
    imgBg: {
      // backgroundImage: `url(${courseImg})`,
      width: "100%",
      // height: "505px",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      backgroundSize: "cover",
      justifyContent: "center",
      backgroundPosition: "center",
    },
    videoCont: {
      // marginTop: '-66px',
      // padding: '0px',
      maxWidth: '100%'
    },
    profilePic: {
      width: '9rem',
      height: '9rem',
      border: '6px solid #fff',
    },
    bookBtn: {
      border: "2px solid #f48b1b !important",
      backgroundColor: "#f48b1b",
      borderRadius: "20px !important",
      color: "#ffffff !important",
      marginRight: "15px",
      height: "2rem",
      "&:hover": {
        backgroundColor: "#f48b1b !important",
        color: "#ffffff !important",
      },
    },
    unBookBtn: {
      border: "2px solid #7764db !important",
      backgroundColor: "#7764db",
      borderRadius: "20px !important",
      color: "#ffffff !important",
      marginRight: "15px",
      height: "2rem",
      "&:hover": {
        backgroundColor: "#7764db !important",
        color: "#ffffff !important",
      },
    },
    divider: {
      margin: '1rem 0rem',
      border: '1px solid #bca5a5',
    },
    paperHeight: {
      minHeight: '8rem',
      padding: '1.5rem',
      boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
      border: '1px solid #eaeaea',
      width: '100%',
    },
    proTag: {
      color: 'black !important',
      background: 'lightgray',
      margin: '0.2rem'
    },
    papercont: {
      padding: '1rem 0rem',
    },
    paperwi: {
      width: '90%',
    },
    contentpage: {
      marginLeft: '1rem'
    },
    contentpageinfo: {
      fontWeight: "bold"
    },
    taginfo: {
      padding: '1rem 0rem',
    },
    authourna: {
      color: '#f48b1b',
    },
    aname: {
      width: '18rem'
    },
    authorname: {
      fontWeight: 'bold',
      color: "#000000"
    },
    blogTitle: {
      paddingBottom: '3rem'
    },
    blogTitleColor: {
      color: '#f48b1b'
    },
    blogContentGrid: {
      paddingBottom: '3rem'
    },
    bookGrid: {
      marginTop: "2rem"
    },
    gridIconBtn: {
      width: '5%'
    },
    followBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#ffffff',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#ffffff',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    unFollowBtn: {
      border: '2px solid #f48b1b !important',
      backgroundColor: '#f48b1b',
      borderRadius: '20px !important',
      width: '7rem',
      height: '2rem',
      color: '#ffffff !important',
      '&:hover': {
        backgroundColor: '#f48b1b !important',
        color: '#ffffff !important'
      },
      [theme.breakpoints.down("xs")]: {
        border: '1px solid #f48b1b !important',
        backgroundColor: '#f48b1b',
        height: '1rem',
        padding: '.7rem !important',
        width: '5rem',
        '&:hover': {
          backgroundColor: '#f48b1b !important',
          color: '#ffffff !important'
        }
      }
    },
    lineGrid: {
      width: '100%',
      marginTop: '2rem',
      marginBottom: '2rem',
      [theme.breakpoints.down("xs")]: {
        marginTop: '1rem',
        marginBottom: '1rem',
      },
    },
    underline: {
      width: '80%',
      margin: 'auto',
      [theme.breakpoints.down("xs")]: {
        width: '80%',
        margin: 'auto'
      },
    },
  })
)(BlogProfileWebToast);
export default withTranslation()(BlogProfileWebWithStyle);
