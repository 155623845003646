import React from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Container,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik, Form, FormikHelpers, Field } from "formik";
//@ts-ignore
import { withRouter } from "react-router";
import OTPInputAuthControllerWeb, { Props } from "./OTPInputAuthController.web";
import { validationSchema } from "./OTPInputAuth.web.validation";
import { otpImg } from "./assets";
import { withSnackbar } from "../../../components/src/toast.web";
interface FormValues {
  pin: string | null | undefined;
}
class OTPInputAuthWeb extends OTPInputAuthControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="h-screen"
        >
          <Grid item xs={12} sm={7} md={7} className="hidden sm:block">
            <img src={otpImg} alt="" className="w-1/2 mx-auto" />
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Grid container direction="column" alignItems="center">
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={validationSchema}
                // @ts-ignore
                onSubmit={(
                  values: FormValues,
                  { setSubmitting }: FormikHelpers<FormValues>
                ) => {
                  console.log("values", values);
                  setSubmitting(true);
                  this.verifyOtp(values);
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 500);
                }}
                enableReinitialize
              >
                {(formikProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                  } = formikProps;
                  return (
                    // @ts-ignore
                    <Form>
                      <div className="mb-8 text-center">
                        <Typography variant="h1">
                          Sign Up to CareerHunt
                        </Typography>
                      </div>
                      <div className="mb-8">
                        <p className="text-lg text-center">
                          Entre OTP Sent On Your <br />
                          Mobile Number
                        </p>
                      </div>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid container item xs={12}>
                          <TextField
                            name="pin"
                            value={values.pin}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.pin && errors.pin ? true : false}
                            helperText={
                              touched.pin && errors.pin ? errors.pin : ""
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isSubmitting}
                          >
                            Sign Up
                          </Button>
                        </Grid>
                        <Grid item>
                          <span className="text-sm">
                            Already have an account?
                          </span>
                          <Button
                            type="button"
                            color="primary"
                            // @ts-ignore
                            onClick={() => this.props.history.push("/")}
                          >
                            Sign in
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
// @ts-ignore
const OTPInputAuthWebWithRouter = withRouter(OTPInputAuthWeb);
export default withSnackbar(OTPInputAuthWebWithRouter);
