import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";

export const configJSON = require("./config");

export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    ParentCallback: any;
    showToast: any;
    dashboardCallback: any;
    handleSideDrawerOpen: any;
    mobileView: boolean;
    openToastHandler: any;
    match: any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  left: boolean;
  loading: boolean;
  showToast: {
    show: boolean;
    message?: string;
    type?: any;
  };
  jobDetails: any[];
  recommandedJobs: any[];
  filterData: any;
  open: boolean;
  similarJobs: any[]
}
interface SS {
  id: any;
}
export default class JobDetailsViewController extends BlockComponent<Props, S, SS> {
  showJobDetailsApiCallId: string = "";
  recommandedJobsApiCallId: string = "";
  applyJobApiCallId: string = "";
  addBookmarkApiCallId: string = "";
  removeBookmarkApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      left: true,
      loading: false,
      showToast: {
        show: false,
        message: '',
        type: "success" || "info" || "warning" || "error"
      },
      jobDetails: [],
      recommandedJobs: [],
      filterData: {},
      open: false,
      similarJobs: []
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson?.errors) {
        switch (apiRequestCallId) {
          case this.showJobDetailsApiCallId:
            this.showJobDetailsApiResponse(responseJson);
            break;
          case this.recommandedJobsApiCallId:
            this.recommandedJobsApiResponse(responseJson);
            break;
          case this.applyJobApiCallId:
            this.applyJobApiResponse(responseJson);
            break;
          case this.addBookmarkApiCallId:
            this.addBookmarkApiResponse(responseJson);
            break;
          case this.removeBookmarkApiCallId:
            this.removeBookmarkApiResponse(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson?.errors) {
        if (responseJson.errors[0].token)
          this.props.openToastHandler("error", responseJson.errors[0].token + `please login again`!);
      }
    }
  }
  // Customizable Area Start
  showJobDetailsApiResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ jobDetails: responseJson?.data, similarJobs: responseJson?.similar_jobs });
    }
  }
  recommandedJobsApiResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ recommandedJobs: responseJson?.data });
    }
  }
  applyJobApiResponse = (responseJson: any) => {
    if (responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.showJobDetails();
      this.recommandedJobs();
      this.props.openToastHandler("success", 'Applied'!);
    }
  }
  addBookmarkApiResponse = (responseJson: any) => {
    if (responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.showJobDetails();
      this.props.openToastHandler("success", 'Bookmarked'!);
    }
  }
  removeBookmarkApiResponse = (responseJson: any) => {
    if (responseJson?.success) {
      this.showJobDetails();
    }
  }

  showJobDetails = () => {
    const jobId = this.props?.match?.params?.jobId;
    const companyId = this.props?.match?.params?.companyId;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.showJobDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCompanyIndexApiEndPoint}/${companyId}/jobs/${jobId}`
    );
    const header = {
      "token": localStorage.getItem("token") || ""
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  recommandedJobs = () => {
    const token = localStorage.getItem('token') || "";
    if (!token) return;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.recommandedJobsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllJobsApiEndPoint}?is_recommended=true`
    );
    const header = {
      "token": token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  applyJob = (jobId: any) => {
    const token = localStorage.getItem('token') || "";
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const companyId = this.props?.match?.params?.companyId;
    const data = {
      "company_id": companyId
    }
    const apiEndPoint = `${configJSON.getAllJobsApiEndPoint}/${jobId}/apply_job`;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyJobApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addBookmark = (id: any) => {
    const token = localStorage.getItem('token') || "";
    let headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) {
      headers['token'] = token
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "job"
    }
    const apiEndPoint = configJSON.addBookmarkUrl;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  removeBookmark = (id: any) => {
    const token = localStorage.getItem('token') || "";
    let headers: any = {
      "Content-Type": configJSON.exampleApiContentType
    }
    if (token) {
      headers['token'] = token
    }
    const data = {
      "bookmarkable_id": id,
      "bookmarkable_type": "job"
    }
    const apiEndPoint = configJSON.removeBookmarkUrl;
    const methodType = configJSON.exampleAPiMethod;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBookmarkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
