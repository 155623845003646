import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
//@ts-ignore
import i18n from "i18next";
//@ts-ignore
import { Trans } from "react-i18next";
export type Props = {
  id: string;
  navigation: any;
  match: any;
  // Customizable Area Start
  // Customizable Area End
};
interface S {
  // Customizable Area Start
  open: boolean;
  subMenuOpen: boolean;
  anchorPEl: boolean;
  userDetails: any;
  NotificationData: any;
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class DashboardHeaderWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserDetailsApiCallId: string = "";
  getNotificationApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    this.state = {
      open: false,
      // mobileView:false,
      // topDrawerOpen:false,
      subMenuOpen: false,
      anchorPEl: false,
      userDetails: [],
      NotificationData: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        if (responseJson !== undefined) {
          if (responseJson?.data) {
            if (apiRequestCallId === this.getUserDetailsApiCallId) {
              this.setState({ userDetails: responseJson?.data });
            } else if (apiRequestCallId === this.getNotificationApiCallId) {
              this.setState({ NotificationData: responseJson?.data });
            }
          } else if (responseJson.errors && responseJson.errors[0].token) {
            console.log(responseJson);
          }
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  userDetails = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const lang = localStorage.getItem("lang") || "en";
    this.getUserDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailApiEndPoint + `?language=${lang} `
    );
    const token = await localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // notification api dashborad====
  getNotificationData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationApiCallId = requestMessage.messageId;
    const lang = localStorage.getItem("lang") || "en";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Notifications + `?language=${lang} `
    );
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
