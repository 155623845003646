import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouterProps } from "react-router";
import { StyledProps } from "@material-ui/core/styles";
// Customizable Area End
import { logo } from "./assets";

export const configJSON = require("./config");
export const resuableFun = require("./resuableFunction")
import { sendAPIRequest } from "../../../components/src/Utility";
export type Props = RouterProps &
  StyledProps & {
    id: string;
    showAlert: any;
    showLoader: any;
    hideLoader: any;
    openToastHandler: any;
    match: any;
    // showToast : any;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  url: string;
  mimeType: string;
  myProfile: any;
  expertProfilePopular: any;
  expertProfileFeatured: any;
  anchorEl: any;
  problemType: any[];
  problemBlock: boolean,
  selectedProblem: any,
  openReportDialog: boolean;
  userDetails: any;
  payment: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CareerExpertProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMyProfileApiCallId: string = "";
  getExpertProfileApiCallId: string = "";
  getExpertProfileFeaturedApiCallId: string = "";
  bookNowApiCallId: string = "";
  unBookNowApiCallId: string = "";
  expertProfile: any;
  myProfile: any;
  popularBlogs: any;
  featuredBlogs: any;
  getPopularQuestionsApiCallId: string = "";
  postReportApiCallId: string = "";
  OrderExpertApiCallId: string = "";
  expertPaymentApiCallId: string = "";
  getUserDetailApiCallId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.props.hideLoader();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];
    this.state = {
      url: "",
      mimeType: "",
      myProfile: {},
      expertProfilePopular: {},
      expertProfileFeatured: {},
      anchorEl: null,
      problemType: ['Voilence', 'Harrasment', 'Self injury sucide', 'False imformation', 'spam', 'Hate speech', 'Sales and advertisment', 'terrorism', 'Something else'],
      problemBlock: false,
      selectedProblem: '',
      openReportDialog: false,
      userDetails: [],
      payment: []





    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  loadData = () => {
    this.getPopularProfile();
    this.getExpertProfileWithFeatured();
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getExpertProfileApiCallId:
            this.handleExpertProfilePopularApiResponce(responseJson);
            break;
          case this.getExpertProfileFeaturedApiCallId:
            this.handleExpertProfileFeaturedApiResponce(responseJson);
            break;
          case this.postReportApiCallId:
            this.handlePostReportApiResponce(responseJson);
            break;
          case this.OrderExpertApiCallId:
            this.handleOrderExpertApiResponce(responseJson);
            break;
          case this.getUserDetailApiCallId:
            this.handleUserDetailApiResponce(responseJson);
            break;
          case this.expertPaymentApiCallId:
            this.handlePaymentExpertApiResponce(responseJson);
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        this.clearLocalstorage(responseJson)
        // we use parseApiErrorResponse method to handle errors by default provide by builder
      }
    }
  }

  handleExpertProfilePopularApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ expertProfilePopular: responseJson.data });

    }
  }
  handleExpertProfileFeaturedApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ expertProfileFeatured: responseJson.data });

    }
  }
  handlePostReportApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ openReportDialog: false });
      this.props.openToastHandler("success", 'Report Submitted Successfully'!);
    }
  }
  clearLocalstorage = (responseJson: any) => {
    return resuableFun.errorHandling(responseJson)

  };
  handleUserDetailApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ userDetails: responseJson.data.attributes });
    }
  };
  handleOrderExpertApiResponce = (responseJson: any) => {
    this.props.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      const options = {
        key: 'rzp_test_avu7SyAGQdRNDC',
        amount: responseJson?.data?.attributes?.price,
        currency: "INR",
        name: "Career Hunt",
        description: responseJson?.data?.type,
        image: logo,
        order_id: responseJson?.data?.attributes?.razorpay_order_id,
        handler: async (res: any) => {
          this.paymentExpert(res, responseJson)
        },
        prefill: {
          name: this.state.userDetails?.first_name,
          email: this.state.userDetails?.email,
          contact: this.state.userDetails?.phone_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      //@ts-ignore
      const rzp1 = new window.Razorpay(options);
      rzp1.open();

    }
  };
  handlePaymentExpertApiResponce = (responseJson: any) => {
    if (responseJson) {
      this.setState({ payment: responseJson?.order })
      if (this.state.payment?.status === "paid") {
        this.componentDidMount();
        this.props.history.push(`/home/careerexpert/${this.state.payment?.career_expert_id}`);
      }
    }
  };
  userProfileDetails = async () => {
    const lang = localStorage.getItem("lang") || "en";
    const apiEndPoint = configJSON.getUserDetailApiEndPoint + `?language=${lang} `
    const token = await localStorage.getItem("token");
    const method = configJSON.validationApiMethodType
    const header = {
      "token": token
    };
    this.getUserDetailApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    })
  }
  getPopularProfile = () => {
    const expertId = this.props?.match?.params?.expertId;
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getExpertProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}/${expertId}?is_populat=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExpertProfileWithFeatured = () => {
    const expertId = this.props?.match?.params?.expertId;
    const token = localStorage.getItem("token")
    const headers: any = {
      "Content-Type": configJSON.validationApiContentType
    };
    if (token) headers.token = token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getExpertProfileFeaturedApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getExperts}/${expertId}&is_featured=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  continueExpertPay = async (order: any) => {
    this.props.showLoader();
    this.userProfileDetails()
    const data = {
      "amount": order?.attributes?.price,
      "currency": "INR",
      "receipt": order?.id,
      "notes": {
        "notes_key_1": "Tea, Earl Grey, Hot",
        "notes_key_2": "Tea, Earl Grey… decaf."
      }
    }

    const apiEndPoint = `${configJSON.getExpertOrderIdUrl}=${order?.id}`;
    const method = configJSON.exampleAPiMethod;
    const token = localStorage.getItem('token');
    const headers = { "token": token };
    this.OrderExpertApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      body: { ...data },
      headers: headers
    })

  }
  paymentExpert = (response: any, orderDetail: any) => {
    try {
      const paymentId = response.razorpay_payment_id;
      const apiEndPoint = `${configJSON.getExpertPaymentIdUrl}?razorpay_order_id=${orderDetail?.data?.attributes?.razorpay_order_id}&razorpay_payment_id=${paymentId}&razorpay_signature=${response.razorpay_signature}`;
      const method = configJSON.exampleAPiMethod;
      const token = localStorage.getItem('token');
      const headers = { "token": token };
      this.expertPaymentApiCallId = sendAPIRequest(apiEndPoint, {
        method,
        headers: headers
      })
    } catch (err) {
      console.log(err);
    }
  }

  selectProblemType = (prob: any) => {
    this.setState({ problemBlock: true, selectedProblem: prob })

  }
  createReport = (value: any) => {
    const headers = { "token": localStorage.getItem("token") || "" };
    const apiEndPoint = configJSON.createReport;
    const methodType = configJSON.exampleAPiMethod;

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postReportApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    let formdata = new FormData();

    value?.title && formdata.append(`data[title]`, value?.title);
    value?.categories && formdata.append(`data[type_problem]`, value?.categories);
    value?.description && formdata.append(`data[description]`, value?.description);
    value?.tagVal?.forEach((ele: any) => {
      formdata.append(`data[images][]`, ele[0]);
    })

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}


